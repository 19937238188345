<div class="overlay"></div>
<div class="cover"></div>
<div class="ibox login-content">
    <div class="text-center">
        <span class="auth-head-icon"><i class="ti ti-lock"></i></span>
    </div>
    <form class="ibox-body pt-0" id="lock-form" action="javascript:;" method="POST">
        <h4 class="font-strong text-center mb-4">STELLA OBRIEN</h4>
        <div class="row pt-3">
            <div class="col-4">
                <img class="img-circle" src="./assets/img/users/u10.jpg" alt="image" width="110" />
            </div>
            <div class="col-8">
                <p class="font-13">Your are in lock screen. Enter your password to retrieve your session</p>
                <div class="form-group">
                    <input class="form-control" type="password" name="password" placeholder="******">
                </div>
                <div class="form-group">
                    <button class="btn btn-primary btn-block" type="submit">
                        <span class="btn-icon"><i class="ti ti-lock"></i>UNLOCK</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<style>
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./assets/img/blog/17.jpeg');
    }
    .cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(117, 54, 230, .1);
    }

    .login-content {
        max-width: 450px;
        margin: 100px auto 50px;
    }

    .auth-head-icon {
        position: relative;
        height: 60px;
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        background-color: #fff;
        color: #5c6bc0;
        box-shadow: 0 5px 20px #d6dee4;
        border-radius: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
    body.empty-layout .theme-config, body.empty-layout .to-top{
        display: none !important;
    }
</style>
