<div class="m-3">
    <h4>Chọn lịch bán trú cho {{data.data.length}} lớp đã chọn</h4>
    <div>
        <div *ngIf="isLoading" class="text-center">
            <span class="spinner-border spinner-lg"></span>

        </div>
        <div *ngIf="dataList.length == 0" class="text-center">
            <app-nodata msg="'Chưa khởi tạo khung giờ. Vào menu Khung giờ điểm danh để thêm mới"></app-nodata>

        </div>
        <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Tên lịch</th>
                        <th class="text-center">Giờ bán trú</th>

                        <!-- <th class="text-center">Phút tính sơm</th>
                        <th class="text-center">Phút tính muộn</th> -->
                        <th class="no-sort"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="img-circle mt-1" src="./assets/img/icons/checkin-time.png" alt="image" width="30" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.name}}</div>
                            <div style="font-size: small; margin-top: 3px;font-weight: bold;">{{item.checkin_weeks_name}}</div>
                        </td>
                        <td class="text-center">{{item.checkin_start}} - {{item.checkin_end }}</td>
                        <!-- <td class="text-center">{{item.checkin_early}}</td>
                        <td class="text-center">{{item.checkin_late}}</td> -->
                        <td>
                            <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp">
                                <span class="input-span"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Xác nhận gán
            </button>
            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
            </button>
        </div>
    </div>
</div>