<div class="page-content fade-in-up">
    <h3>Danh sách camera AI</h3>
    <div class="row lop-cover">
        <div *ngFor="let item of cameras; let i = index" (click)="openCamera(item)" class="card text-center card-air  m-1 lop-item">
            <div class="card-body">
                <div class="card-avatar mt-2 mb-2">
                    <img src="./assets/img/icons/camera.png" alt="Avatar">
                </div>
                <h4 class="card-title text-primary mb-1">{{item.deviceName}}</h4>
                <div class="" style="white-space: nowrap;
            overflow: hidden !important; height: 20px;
            text-overflow: ellipsis; width: 120px;">{{item.teacher_name}}</div>
            </div>
        </div>
    </div>
</div>