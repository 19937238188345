<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">

        <div class="d-flex justify-content-between align-items-center">
            <div>
                <div style="font-size: x-large;">Thông báo kết quả học tập</div>
                <div class="mb-2">Thầy cô chọn lớp để gửi thông báo</div>

            </div>
            <div class="btn-group">
                <div class="btn" *ngFor="let item of appService.hockys; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.hockySelected, 'btn-outline-primary': item.code != this.appService.hockySelected}" (click)="hocky_change(item.code)">{{item.value}}</div>
            </div>
        </div>

        <div class="cont-checkbox">
            <input type="checkbox" [id]="'myCheckbox-'" [name]="'myCheckbox-'" [(ngModel)]="checkboxAllButton" (change)="checkboxAll($event)" />
            <label [for]="'myCheckbox-'">
                      <!-- <img [src]="car.img" /> -->
                   
                      <div class="info">Chọn lớp</div>
                      <div class="body text-center font-13">
                        Tất cả 
                        
                       </div>
                    </label>
        </div>
        <div class="cont-checkbox" *ngFor="let item of categorys; index as i">
            <input type="checkbox" [id]="'myCheckbox-' + i" [name]="'myCheckbox-' + i" [(ngModel)]="item.temp_checked" />
            <label [for]="'myCheckbox-' + i">
                      <!-- <img [src]="car.img" /> -->
                      <span class="cover-checkbox">
                        <svg viewBox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <div class="info">{{ item.name }}</div>
                      <div class="body text-center  font-13">
                        <!-- {{item.student_count == null ? 0: item.student_count}} 
                        <span class="font-15 text-primary material-icons">people</span> -->

                        <span><i class="fa fa-address-book-o text-primary mb-0 mr-2"></i><span >{{item.student_count == null ? 0: item.student_count}}</span></span>

                       </div>
                    </label>
        </div>

        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussion.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Tiêu đề</mat-label>
            <input matInput [(ngModel)]="discussion.title" name="title" maxlength="100" readonly placeholder="Tiêu đề">
        </mat-form-field>

        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Nội dung</mat-label>
            <textarea matInput [(ngModel)]="discussion.message" rows="5" name="messenger" readonly maxlength="1000" minlength="10" placeholder="Nội dung"></textarea>
        </mat-form-field>

        <div class="filettach-upload mb-3">
            Đính kèm file:
            <input type="file" accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
        </div>

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-between ">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.sendMessengerForm.invalid" (click)="confirmSendMessenger()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                        send
                        </span></span>
                    Gửi thông báo
                </button>
            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>