<div class="">
    <!-- (ngSubmit)="insertteacher()" -->
    <form class="form-horizontal" #teacherUpdateForm="ngForm" id="form-update-teacherSelected">
        <div class="modal-header">
            <div class="modal-title">Cập nhật cán bộ giáo viên</div>
        </div>

        <div class="modal-body p-3">
            <div class="row">
                <div class="col-sm-9">

                    <mat-form-field class="example-full-width  mb-3" appearance="fill">
                        <mat-label>Họ và tên</mat-label>
                        <input matInput [(ngModel)]="teacherSelected.name" placeholder="Tên cán bộ giáo viên" name="name" required maxlength="100">
                    </mat-form-field>

                    <div class="form-group row">
                        <mat-form-field appearance="fill">
                            <mat-label>Mã</mat-label>
                            <input matInput placeholder="Mã " type="text" [(ngModel)]="teacherSelected.code" [readonly]="true" name="code" type="text">
                        </mat-form-field>
                    </div>

                </div>
                <div class="col-sm-3 d-flex justify-content-center">
                    <div class="text-center">
                        <div class="image-upload">
                            <div class="file-input" [style.background-image]="'url('+teacherSelected_imageUrl+')'">
                            </div>
                            <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File" (change)="avatarChangeListener($event)">
                        </div>
                        <div class="text-info" style="font-size: 10px; text-align: center;">Bấm vào ảnh đại diện để thay ảnh</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">

                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                        <mat-label>Số điện thoại</mat-label>
                        <input matInput [(ngModel)]="teacherSelected.phone" placeholder="Số điện thoại" name="phone" maxlength="10">
                    </mat-form-field>
                </div>
                <div class="col">

                    <mat-form-field class=" mb-3" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="teacherSelected.email" placeholder="Email (dùng để đăng nhập)" name="email" required maxlength="200" (focusout)="focusOutCheckEmail(teacherSelected.email)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                        <mat-label>Ngày sinh</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="birthday_temp" name="birthdayU">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>

                </div>
                <div class="col">
                    <section class="qbs-radio-cover">
                        <label class="lable">Giới tính</label>
                        <mat-radio-group [(ngModel)]="teacherSelected.gender" name="gendergender">
                            <mat-radio-button class="example-margin" [value]="1">Nam</mat-radio-button>
                            <mat-radio-button class="example-margin" [value]="0">Nữ</mat-radio-button>
                        </mat-radio-group>
                    </section>
                </div>
            </div>
            <div class="row  mb-3">
                <div class="col">
                    <mat-form-field appearance="fill" class="">
                        <mat-label>Chức vụ</mat-label>
                        <mat-select [disabled]="!appService.checkFullPermission()" [(ngModel)]="teacherSelected.positions" name="category">
                            <mat-option *ngFor="let item of  appService.positions" [value]="item.code">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col">

                    <mat-form-field appearance="fill" class="">
                        <mat-label>Tổ bộ môn</mat-label>
                        <mat-select [(ngModel)]="teacherSelected.faculty_code" name="faculty_code">
                            <mat-option *ngFor="let item of this.facultys" [value]="item.code">
                                {{item.name}} ({{item.teacherSum}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <ng_container *ngIf="teacherSelected.positions == 'ketoan'">
                <div class="row mb-3">
                    <div class="col">
                        <mat-form-field appearance="fill">
                            <mat-label>Cấp học</mat-label>
                            <mat-select [(ngModel)]="teacherSelected.name_alias" name="name_alias" [disabled]="!appService.checkFullPermission()">
                                <mat-option *ngFor="let item of this.appService.currentUser.caps" [value]="item.code">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                    </div>
                </div>
            </ng_container>

            <div class="row mb-3">
                <div class="col">
                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                        <mat-label>Ghi chú</mat-label>
                        <input matInput [(ngModel)]="teacherSelected.description" placeholder="Ghi chú" name="description" maxlength="200">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label">Đổi mật khẩu</label>
                <div class="col-sm-9 d-flex align-items-end">
                    <div class="col text-right">
                        <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isRessetPass" name="isRessetPass">
                                <span></span>
                            </label>
                    </div>
                </div>
            </div>
            <div *ngIf="isRessetPass" class="row mb-3">
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Mật khẩu</mat-label>
                    <input matInput [(ngModel)]="password" placeholder="Mật khẩu" name="pass" [type]="hide ? 'password' : 'text'" required [disabled]="!isRessetPass" maxlength="100" autocomplete="on">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Nhập lại mật khẩu</mat-label>
                    <input matInput [(ngModel)]="repassword" placeholder="Nhập lại mật khẩu" name="repasss" [type]="hide ? 'password' : 'text'" required [disabled]="!isRessetPass" maxlength="100" autocomplete="on">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label">Dừng hoạt động</label>
                <div class="col-sm-9 d-flex align-items-end">
                    <div class="col text-right">
                        <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted">
                                <span></span>
                            </label>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal-footer justify-content-around bg-primary-50">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.teacherUpdateForm.invalid" (click)="updateTeacher()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                        save
                        </span></span>
                    Cập nhật
                </button>


            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>