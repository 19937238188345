import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { AssessmentService } from 'src/app/_services/assessment.service';

@Component({
  selector: 'app-assessment-detail',
  templateUrl: './assessment-detail.component.html',
  styleUrls: ['./assessment-detail.component.css']
})
export class AssessmentDetailComponent implements OnInit {
  data_list = [];
  student : any;
  assessment : any;
  constructor(
    public appService: AppService,
    private assessmentService: AssessmentService,
    private dialogRef: MatDialogRef<AssessmentDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: any
  ) { 
    console.log(data);
    this.student = data;
    this.loadDetail(this.student.code);
  }

  ngOnInit(): void {
  }
  onCancelClick(){
    this.dialogRef.close();
  }
  loadDetail(student_code){
    let data = {
      hocky: this.appService.hockySelected,
      student_code: student_code,
    }
   
    this.assessmentService.loadAssessmentStudentDetail(data).subscribe(
      async response => {
        
        this.assessment = response;
        console.log(this.assessment);
      },
      error => {
       
      })
  }
}
