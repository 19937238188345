<div class="m-3">
    <h4>{{data.title}} {{data.dateSelected}} </h4>
    <div>
        <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Lịch</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="img-circle mt-1" src="./assets/img/icons/calendar.png" alt="image" width="30" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;" class="pr-3">{{item}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">

            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
            </button>
        </div>
    </div>
</div>