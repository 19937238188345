<div class="">
    <form class=" form-horizontal" #teacherAddForm="ngForm" id="form-add-teacher">
        <div class="modal-header">
            <div class="modal-title">Thêm mới cán bộ giáo viên</div>
        </div>
        <div class="modal-body p-3">
            <div class="row">
                <div class="col-sm-9">

                    <mat-form-field class="example-full-width  mb-3" appearance="fill">
                        <mat-label>Họ và tên</mat-label>
                        <input matInput [(ngModel)]="teacher.name" placeholder="Tên cán bộ giáo viên" name="name" required maxlength="100">
                    </mat-form-field>

                    <div class="form-group row">
                        <mat-form-field appearance="fill">
                            <mat-label>Mã</mat-label>
                            <input matInput placeholder="Mã " type="text" [(ngModel)]="teacher.code" minlength="3" maxlength="20" [readonly]="'mts' === codeType || 'qmv' === codeType" name="code" type="text" (ngModelChange)="teacher.code = $event.toUpperCase()" (focusout)="focusOutCheckCode()"
                                (keydown.space)="$event.preventDefault();">
                        </mat-form-field>
                    </div>
                    <div class="form-group row" style="position: relative; height: 20px;">
                        <div style="position: absolute; top: -7px;">
                            <div class="">
                                <label class="radio radio-grey radio-primary radio-inline">
                                    <input type="radio" name="b" [checked]="'mts' === codeType"
                                        (click)="teacherCodeTypeClick('mts');">
                                    <span class="input-span"></span>Mã tự sinh</label>
                                <label class="radio radio-grey radio-primary radio-inline">
                                    <input type="radio" name="b" [checked]="'tn' === codeType"
                                        (click)="teacherCodeTypeClick('tn');">
                                    <span class="input-span"></span>Tự nhập</label>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col-sm-3 d-flex justify-content-center">
                    <div class="text-center">
                        <div class="image-upload">
                            <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                            </div>
                            <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File" (change)="avatarChangeListener($event)">
                        </div>
                        <div class="text-info" style="font-size: 10px; text-align: center;">Bấm vào ảnh đại diện để thay ảnh</div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col">

                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Số điện thoại</mat-label>
                        <input matInput [(ngModel)]="teacher.phone" placeholder="Số điện thoại" name="phone" maxlength="10">
                    </mat-form-field>
                </div>
                <div class="col">

                    <mat-form-field class="" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="teacher.email" placeholder="Email (dùng để đăng nhập)" name="email" required maxlength="200" (focusout)="focusOutCheckEmail(teacher.email)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row  mb-3">
                <div class="col">


                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Ngày sinh</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="birthday_temp" name="birthday">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>

                </div>
                <div class="col">

                    <section class="qbs-radio-cover">
                        <label class="lable">Giới tính</label>
                        <mat-radio-group [(ngModel)]="teacher.gender" name="gendergender">
                            <mat-radio-button class="example-margin" [value]="1">Nam</mat-radio-button>
                            <mat-radio-button class="example-margin" [value]="0">Nữ</mat-radio-button>
                        </mat-radio-group>
                    </section>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Chức vụ</mat-label>
                        <mat-select [(ngModel)]="teacher.positions" name="positions" [disabled]="!appService.checkFullPermission()">
                            <mat-option *ngFor="let item of  appService.positions" [value]="item.code">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">

                    <mat-form-field appearance="fill">
                        <mat-label>Tổ bộ môn</mat-label>
                        <mat-select [(ngModel)]="teacher.faculty_code" name="faculty_code">
                            <mat-option *ngFor="let item of this.facultys" [value]="item.code">
                                {{item.name}} ({{item.teacherSum}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <ng_container *ngIf="teacher.positions == 'ketoan'">
                <div class="row mb-3">
                    <div class="col">
                        <mat-form-field appearance="fill">
                            <mat-label>Cấp học</mat-label>
                            <mat-select [disabled]="false" [(ngModel)]="teacher.name_alias" name="name_alias" [disabled]="!appService.checkFullPermission()">
                                <mat-option *ngFor="let item of this.appService.currentUser.caps" [value]="item.code">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col">
                    </div>
                </div>
            </ng_container>

            <div class="row mb-3">
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Ghi chú</mat-label>
                        <input matInput [(ngModel)]="teacher.description" placeholder="Ghi chú" name="description" maxlength="200">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="modal-footer justify-content-around bg-primary-50">
            <button class="btn btn-primary  btn-labeled btn-labeled-left " [disabled]="isLoading || this.teacherAddForm.invalid" (click)="insertTeacher()">
                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                        save
                    </span></span>
                Thêm mới
            </button>
            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>