<!-- <div class="modal-dialog" role="document"> -->
<form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
    <div class="modal-header p-4">
        <h5 class="modal-title">Thêm mới lịch dạy Online</h5>
        <a data-dismiss="modal" (click)="cancelClick()"><i class="ti ti-close"></i></a>
    </div>
    <div class="modal-body p-4">

        <mat-form-field class="mb-3" appearance="fill">
            <mat-label>Tên lịch dạy Online</mat-label>
            <input matInput [(ngModel)]="cate.name" placeholder="Nhập tên ca" name="name" required minlength="2" maxlength="30">
        </mat-form-field>
        <div class="row">
            <div class="col">
                <!-- <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.hockyI_startTime" placeholder="Bắt đầu" name="hockyI_startTime" required minlength="10" maxlength="10">
                        </mat-form-field> -->
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Ngày bắt đầu</mat-label>
                    <input matInput [matDatepicker]="picker_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_startTime" name="hockyI_startTime" required>
                    <mat-datepicker-toggle matSuffix [for]="picker_startTime"></mat-datepicker-toggle>
                    <mat-datepicker #picker_startTime></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <!-- <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết thúc</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.hockyI_endTime" placeholder="Kết thúc" name="hockyI_endTime" required minlength="10" maxlength="10">
                        </mat-form-field> -->

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Ngày kết thúc</mat-label>
                    <input matInput [matDatepicker]="picker_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_endTime" name="hockyI_endTime" required>
                    <mat-datepicker-toggle matSuffix [for]="picker_endTime"></mat-datepicker-toggle>
                    <mat-datepicker #picker_endTime></mat-datepicker>
                </mat-form-field>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Giờ vào</mat-label>
                    <input matInput type="time" [(ngModel)]="cate.checkin_start" placeholder="Giờ vào" name="checkin_start" required minlength="5" maxlength="5">
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Giờ ra</mat-label>
                    <input matInput type="time" [(ngModel)]="cate.checkin_end" placeholder="Giờ ra" name="checkin_end" required minlength="5" maxlength="5">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Số phút tính sớm</mat-label>
                    <input matInput type="number" [(ngModel)]="cate.checkin_early" placeholder="Số phút tính sớm" name="checkin_early" required min="0" max="120">
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Số phút tính muộn</mat-label>
                    <input matInput type="number" [(ngModel)]="cate.checkin_late" placeholder="Số phút tính muộn" name="checkin_late" required min="0" max="120">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Số phút bắt đầu tính</mat-label>
                    <input matInput type="number" [(ngModel)]="cate.checkin_effective" placeholder="Số phút bắt đầu tính" name="checkin_effective" required min="0" max="120">
                </mat-form-field>

            </div>
            <div class="col">

            </div>
        </div>
        <div class="row p-2">
            <section class="mt-1 mb-3 weeks-checkbox">
                <div class="mb-1">Ngày áp dụng</div>
                <mat-checkbox *ngFor="let item of appService.weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
            </section>
        </div>


    </div>
    <div class="modal-footer justify-content-around bg-primary-50">

        <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>
        <button (click)="cancelClick()" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
        </button>
    </div>
</form>
<!-- </div> -->