<div class="page-heading">
    <h1 class="page-title">Morris Charts</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html"><i class="la la-home font-20"></i></a>
        </li>
        <li class="breadcrumb-item">Charts</li>
        <li class="breadcrumb-item">Morris Charts</li>
    </ol>
</div>
<div class="page-content fade-in-up">
    <div class="alert alert-info alert-bordered">
        <p>Good-looking charts shouldn't be difficult.</p>
        <p>
            <a class="btn btn-info" href="http://morrisjs.github.io/morris.js/" target="_blank">Official Website</a>
        </p>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Line Chart example</div>
                </div>
                <div class="ibox-body">
                    <div id="morris_line_chart" style="height:280px;"></div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Bar Chart example</div>
                </div>
                <div class="ibox-body">
                    <div id="morris_bar_chart" style="height:280px;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Area Chart example</div>
                </div>
                <div class="ibox-body">
                    <div id="morris_area_chart" style="height:280px;"></div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Donut Chart example</div>
                </div>
                <div class="ibox-body">
                    <div id="morris_donut_chart" style="height:280px;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Line Chart example</div>
                </div>
                <div class="ibox-body">
                    <div id="morris_line_chart_2" style="height:280px;"></div>
                </div>
            </div>
        </div>
    </div>
</div>
