import { ProductService } from './../../_services/product.service';
import { Membership } from './../../model/membership';
import { UserService } from './../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { SaleService } from '../../_services/sale.service';
import { CartObj } from '../../model/cart-obj';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-report2',
  templateUrl: './report2.component.html',
  styleUrls: ['./report2.component.css']
})
export class Report2Component implements OnInit {
  isLoading = false;

  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;

  // user selected
  userNvbhSelected = "";
  userNvbhList = [];

  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDateStruct | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private _script: ScriptLoaderService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    private calendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    config: NgbModalConfig,
  ) {

    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = calendar.getToday();
    this.fromDateStr = this.utilityService.getFullDateStrFromNgbDate(this.fromDate);
    this.selectedDateStr = this.fromDateStr 
    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }


  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromNgbDate(this.fromDate);
    this.getSalesBydays(); //list sale day
    this.loadSales(this.fromDateStr); // sale detail
  }

  ngOnInit() {

    this.getSalesBydays();
    // this.loadMembership();
    this.loadMembershipVNBH();
  }

  ngAfterViewInit() {
    this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    this.getSalesBydays();
  }
  sale_status_change() {
    this.getSalesBydays();
    this.loadSales(this.selectedDateStr);
  }
  userSelected_change() {
    console.log(this.userNvbhSelected);
    this.getSalesBydays();
    this.loadSales(this.selectedDateStr);
  }
  getSalesBydays() {

    let param = {
      type: environment.SALES_TYPE,
      user_id: this.userNvbhSelected,
      day_num: this.day_num,
      status: this.sale_status,
      dateSelected: this.fromDateStr,
    }
    this.saleService.getSalesTitleByBays(param).subscribe(
      async response => {
        console.log('getSalesBydays');
        console.log(response);
        if (response) {
          this.salesTitleByday_result = response;
          this.sum_prices = 0;
          for (let i = 0; i < this.salesTitleByday_result.length; i++) {
            this.sum_prices = this.sum_prices + parseInt(this.salesTitleByday_result[i].sum_price);
          }
        }
      },
      error => {

        console.error(error);
      });

  }
  loadSales(dateSeleted) {

    let filter = {
      type: environment.SALES_TYPE,
      status: this.sale_status,
      day_num: 0, // số ngày bao cáo, 0 : tất cả
      fromDate: dateSeleted,
      toDate: dateSeleted,
      code: this.code_filter,
      user_id: this.userNvbhSelected,
    }
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.saleService.getSalesBydays(filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.sales = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.sales.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;
        }
      },
      error => {
        console.error(error);
      });

  }
  popupSaleDetail(item) {
    $("#sale-detail-modal").modal('show');
    this.saleSelectedItem = item;
    try {
      this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);
      
    } catch (error) {
      this.saleSelectedItem.service_details = [];
      console.error(error);
    }
    this.loadCartDetail(this.saleSelectedItem.id);
  }
  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadSales(this.selectedDateStr);
  }
  loadCartDetail(saleID) {
    this.saleService.loadCartDetail(saleID).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.cartDetail = response;
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })
  }

  // doPayment() {
  //   console.log(this.saleSelectedItem);
  //   this.saleService.updateSaleStatus(this.saleSelectedItem.id, environment.SALE_STATUS_DONE,"").subscribe(
  //     async response => {
  //       $("#sale-detail-modal").modal('hide');
  //       console.log(response);
  //       if (response.code =="ok" && response.affectedRows > 0) {
  //         this.getSalesBydays();
  //         this.loadSales(this.selectedDateStr);
  //         this.toastr.success("", "Thanh toán thành công");
  //       }
  //     },
  //     error => {
  //       $("#sale-detail-modal").modal('hide');
  //       console.error(error);
  //     })
  // }


  // openDeleteSale(confirmDelete) {
  //   this.modalService.open(confirmDelete, { size: 'sm' });
  // }
  // confirmDeleteSale() {
  //   if(this.cancel_reason.trim() ==""){
  //     this.toastr.warning("", "Vui lòng nhập lý do hủy");
  //     return;
  //   }

  //   this.isLoading = true;
  //   this.saleService.updateSaleStatus(this.saleSelectedItem.id, environment.SALE_STATUS_DELETED, this.cancel_reason).subscribe( // 3 là hủy đơn
  //     async response => {
  //       this.isLoading = false;
  //       if (response.code =="ok" && response.affectedRows > 0) {
  //         this.toastr.success("", "Hủy đơn thành công");
  //         this.getSalesBydays();
  //         this.loadSales(this.selectedDateStr);
  //         this.modalService.dismissAll();
  //         $("#sale-detail-modal").modal('hide');
  //         this.cancel_reason = "";
  //       }
  //     },
  //     error => {
  //       this.isLoading = false;
  //       console.error(error);
  //     })
  // }
  // updateStockProduct() {
  //   let product_codes = "";
  //   let quantity_changes = "";
  //   for (let i = 0; i < this.cartDetail.length; i++) {
  //     if (this.cartDetail.length == i + 1) {
  //       quantity_changes += this.cartDetail[i].quantity.toString();
  //       product_codes += this.cartDetail[i].product_code;
  //     } else {
  //       quantity_changes += this.cartDetail[i].quantity.toString() + ",";
  //       product_codes += this.cartDetail[i].product_code + ",";
  //     }
  //   }

  //   let updateStockList = {
  //     product_codes: product_codes,
  //     quantity_changes: quantity_changes,
  //     type: 1 // 0 is sale, 1 is import
  //   }
  //   this.productService.updateStockProduct(updateStockList).subscribe(
  //     async result => {
  //       console.log(result);
  //       if (result > 0) {
  //         this.toastr.success("", "Đã hủy đơn hàng thành công");
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     });
  // }

  // async loadMembership() {
  //   let filter = {
  //     role: "nvgh"
  //   };
  //   this.userService.loadMembership(filter).subscribe(
  //     async response => {
  //       if (response) {
  //         this.memberships = response;
  //         this.memberships_backup = response;
  //         if (this.memberships.length > 0) {
  //           this.nvbhSelected = this.memberships[0];
  //         }
  //         console.log(this.memberships);
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  async loadMembershipVNBH() {
    let filter = {
      role: "nvbh,admin"
    };
    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.userNvbhList = response;
        }
      },
      error => {
        console.error(error);
      })
  }

  // popupSelectNvgh() {
  //   $("#select-nvgh-modal").modal('show');
  // }

  // getTypingSearchNvgh(ev: any) {
  //   // Reset items back to all of the items
  //   this.memberships = this.memberships_backup;

  //   // set val to the value of the searchbar
  //   const val = ev.target.value.trim();
  //   console.log(ev.target.value.trim());
  //   // if the value is an empty string don't filter the items
  //   if (val && val.length > 0) {
  //     this.memberships = this.memberships.filter((item) => {
  //       return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  //     })
  //   }
  // }
  // doShipping() {
  //   console.log(this.nvbhSelected);
  //   // status 4 Đang xử lý

  //   console.log(this.saleSelectedItem);
  //   this.saleService.updateSaleStatusNVGH(this.saleSelectedItem.id, environment.SALE_STATUS_TAKING, this.nvbhSelected.id, this.nvbhSelected.name).subscribe(
  //     async response => {
  //       // this.loading.dismiss();
  //       console.log(response);
  //       if (response) {
  //         $("#select-nvgh-modal").modal('hide');
  //         $("#sale-detail-modal").modal('hide');
  //         this.getSalesBydays();
  //         this.loadSales(this.fromDateStr);
  //         this.toastr.success("Giáo shipper thành công", "");
  //       } else {
  //         this.toastr.error("", "Có lỗi xảy ra");
  //       }
  //     },
  //     error => {
  //       // this.loading.dismiss();
  //       this.toastr.error("", "Có lỗi xảy ra");
  //       console.error(error);
  //     })
  // }
  // for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadSales(this.selectedDateStr); // sale detail
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadSales(this.selectedDateStr); // sale detail
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadSales(this.selectedDateStr); // sale detail
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadSales(this.selectedDateStr); // sale detail
    }
  };
}

