<div class="d-flex justify-content-between align-items-center category-cover">


    <div>

        <div ngbDropdown class="d-inline-block">
            <button *ngIf="meal_schedules.length == 0" (click)="createMealSchedule()" class="blurButton btn btn-lg btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> add </span></span>
                    <div>Thêm TBK</div>
            </button>
            <button *ngIf="meal_schedules.length > 0" class="blurButton btn btn-lg btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle [ngClass]="ms_selected_index > -1 ? 'btn-primary':'btn-default'">
                    <span class="btn-label"><span class="material-icons"> done </span></span>
                    <ng-container *ngIf="ms_selected_index > -1">
                        <div>{{meal_schedules[ms_selected_index].name}}</div>
                        <div style="font-size: small;">{{meal_schedules[ms_selected_index].startTime_str}} - {{meal_schedules[ms_selected_index].endTime_str}} </div>
                    </ng-container>
                    <ng-container *ngIf="ms_selected_index > -1">
                        <div>Chọn Tuần</div>
                        <div style="font-size: small;"> - </div>
                    </ng-container>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button *ngFor="let item of meal_schedules; let i = index" ngbDropdownItem (click)="selectCate(i)" [ngClass]="ms_selected_index == i ? 'btn-primary':''">
                    <span class="material-icons mr-2" *ngIf="item.status == 0">
                        edit_calendar
                    </span>
                    <span class="material-icons mr-2" *ngIf="item.status == 1">
                        verified
                    </span>
                        {{item.name}} ({{item.startTime_str}} - {{item.endTime_str}})
                </button>
                <!-- <button ngbDropdownItem (click)="saveMealSchedule(i)" class="text-primary"><span class="material-icons mr-2">
                            save
                        </span>
                        Lưu TKB
                </button>
                <button ngbDropdownItem>
                    <span class="material-icons mr-2 text-warning">
                        verified
                        </span>
                        Duyệt TKB
                </button> -->
            </div>
        </div>
        <button (click)="copyUpdateMealSchedule('update')" class="btn btn-lg btn-default btn-icon" placement="top" ngbTooltip="Cập nhật">
            <span class="btn-label">
                <span class="material-icons">
                    settings
                </span>
            </span>
        </button>
        <button (click)="copyUpdateMealSchedule('copy')" class="btn btn-lg btn-default btn-icon" placement="top" ngbTooltip="Copy TKB">
            <span class="btn-label">
                <span class="material-icons">
                    content_copy
                </span>
            </span>
        </button>
        <button (click)="deleteMealSchedule()" class="btn btn-lg btn-default btn-icon" placement="top" ngbTooltip="Xóa lịch TKB">
            <span class="btn-label">
                <span class="material-icons">
                    delete_outline
                </span>
            </span>
        </button>

    </div>
    <div>
        <!-- <button (click)="copyMealSchedule()" class="btn btn-default btn-icon" placement="top" ngbTooltip="Copy sang tuần cuối">
            <span class="btn-label">
                <span class="material-icons">
                    content_copy
                </span>
            </span>
        </button> -->
        <button *ngIf="meal_schedules.length > 0 &&  meal_schedules[ms_selected_index].status == 1" (click)="verifiedMealSchedule(ms_selected_index, 0)" placement="top" ngbTooltip="Bấm đề thu hồi" class="btn btn-info btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label">
                <span class="material-icons">
                    verified
                </span>
            </span>
            Đã gửi
        </button>
        <button *ngIf="meal_schedules.length > 0 && meal_schedules[ms_selected_index].status == 0" (click)="verifiedMealSchedule(ms_selected_index, 1)" placement="top" ngbTooltip="Bấm đề gửi PHHS" class="btn btn-warning btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label">
                <span class="material-icons">
                    send
                </span>
            </span>
            Duyệt gửi
        </button>
        <button (click)="saveMealSchedule(ms_selected_index)" class="btn btn-primary btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label">
                <span class="material-icons">
                    save
                </span>
            </span>
            Lưu TKB
        </button>
    </div>
</div>
<table class="table table-head-purple table-hover meal-table">
    <tr>
        <td *ngFor="let day of MENUd">
            <h4 class="text-center title-1">{{ day.day }}</h4>
            <ng-container *ngIf="day.checked != undefined && day.checked">
                <div *ngFor="let data of day.datas; let m = index">
                    <div class="title-2 d-flex justify-content-between align-items-center">
                        <span>{{ data.name }}</span>
                        <a *ngIf="0 == data.items.length" (click)="addNew(data.items)" class="text-primary">
                            <span class="material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <div cdkDropList (cdkDropListDropped)="drop($event, day.meals)" [style.background-color]="meal_color[m]">
                        <div *ngFor="let item of data.items; let i = index" class="item-cover" cdkDrag>

                            <div>
                                <div>
                                    <span *ngIf="m == 0">{{ i+1 }}. </span>
                                    <span *ngIf="m > 0">{{day.datas[0].items.length + i+1 }}.</span>
                                    <input type="text" [(ngModel)]="item.value" />
                                </div>
                                <div>
                                    <span class="material-icons font-18"> assignment_ind </span><input type="text" [(ngModel)]="item.teacher_name" />
                                </div>

                                <div class="item-action">
                                    <button *ngIf="i == data.items.length-1" (click)="addNew(data.items)" class=" btn btn-sm btn-white text-primary btn-icon-only btn-circle  btn-thick">
                                        <span class="material-icons">
                                            add_circle_outline
                                        </span>
                                    </button>
                                    <button (click)="deleteMeal(data.items, i)" class=" btn btn-sm btn-white text-danger btn-icon-only btn-circle  btn-thick">
                                        <span class="material-icons">
                                            remove_circle
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </td>
    </tr>
</table>


<div *ngIf="MENUd ==  undefined || MENUd.length == 0" class="text-center m-5 p-5">
    <img style="width: 80px;" src="assets\img\icons\calendar.png" alt="">
    <div>Chưa có thời khóa biểu. Vui lòng bấm thêm mới ở trên để tạo.</div>
</div>


<!-- <div class="fab-cover">
    <button (click)="saveMealSchedule(ms_selected_index)" class="btn btn-primary btn-labeled btn-labeled-left btn-icon">
        <span class="btn-label">
            <span class="material-icons">
                save
            </span>
        </span>
        Lưu TKB
    </button>
</div> -->