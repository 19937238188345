import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {
  cameras = [] // danh sách camera
  constructor(
    private toastr: ToastrService,
    public appService: AppService,
    private schoolService: SchoolService,
  ) {
 
    this.appService.page_title = "Camera AI";
    this.getListDevice();
  }

  ngOnInit(): void {

  }
  getListDevice() {
    let data = {
     
    };
    this.schoolService.getListDevice(data).subscribe(
      response => {
        this.cameras = response.data
        console.log(this.cameras);
      },
      error => {
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      });

  }
  openCamera(item){

  }

}
