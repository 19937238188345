import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-assessment',
  templateUrl: './student-assessment.component.html',
  styleUrls: ['./student-assessment.component.css']
})
export class StudentAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
