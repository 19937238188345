
import { Component, OnInit } from '@angular/core';
import { NgbDate,  NgbDateParserFormatter, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentService } from 'src/app/_services/student.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CartObj } from 'src/app/model/cart-obj';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/_services/app.service';
import { ProductService } from 'src/app/_services/product.service';
import { SaleService } from 'src/app/_services/sale.service';
import { UserService } from 'src/app/_services/user.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { Membership } from 'src/app/model/membership';
import { HealthService } from 'src/app/_services/health.service';
import { HealthClassDetailComponent } from './health-class-detail/health-class-detail.component';
declare var $: any;

@Component({
  selector: 'app-health-report',
  templateUrl: './health-report.component.html',
  styleUrls: ['./health-report.component.css']
})
export class HealthReportComponent implements OnInit {
  isLoading = false;

  allSchools = {
    student_count: 0,
    student_inputted: 0,
    student_sent: 0,
    percent: 0,
  }

  //for data

  classs: any
  filter = {
    khoi_code: "",
    class_code: ""
  }
  dateRange = [];
  curentMonday: Date;

  classData: any;
  khoiSelected = -1;

  ///////////////
  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;


  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    private healthService: HealthService,
    private schoolService: SchoolService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    public userService: UserService,
    public productService: ProductService,
    public formatter: NgbDateParserFormatter,
    public studentService: StudentService,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Báo cáo sổ sức khỏe";
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadClasss();
    this.loadReportHealthClass();
    this.loadRangeMonth(new Date);
    this.loadReportHealthSchool();
  }

  category_khoi_change(event) {
    this.loadReportHealthClass();
  }
  category_change(event) {
    this.loadReportHealthClass();
  }
  async loadClasss() {
    let filter = {
      khoi: this.filter.khoi_code,
      type: environment.cateType_class
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  // reportAllSchool() {

  //   this.schoolService.reportAllSchool().subscribe(
  //     async response => {
  //       if (response) {
  //         console.log(response);
  //         this.allSchools = response;
  //         console.log(this.allSchools);
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  loadReportHealthClass() {

    let data = {
      filter: this.filter,
      period_time: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.isLoading = true;
    this.healthService.reportHealthClass(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          response.forEach(element => {
            element.percent = element.student_count == 0 ? 100 : Math.round((parseInt(element.health_inputted) / element.student_count) * 100);
          });
          console.log(response);
          this.classData = response;
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  loadReportHealthSchool() {
    let data = {
      filter: this.filter,
      period_time: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.healthService.reportHealthSchool(data).subscribe(
      async response => {
        if (response) {
          response.percent = Math.round(((response.student_inputted) / response.student_count) * 100);
          this.allSchools = response;
          console.log(this.allSchools);
        }
      },
      error => {
        console.error(error);
      })
  }
  selectKhoi(i, khoi_name) {
    if (this.khoiSelected != i) {
      this.khoiSelected = i;
      this.filter.khoi_code = khoi_name;
      this.loadReportHealthClass();
    }

  }
  popup_export_excel(){

  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
    this.loadRangeMonth(this.fromDate);
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    this.selectedDateStr = dateStr;
    this.loadReportHealthClass();
    this.loadReportHealthSchool();
  }
  

  loadRangeMonth(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week

    this.dateRange = [];
    for (let i = 6; i >= 0; i--) {
      var temp = new Date(curr);
      temp.setMonth(curr.getMonth()-i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
          mmyyyy: this.utilityService.getMonthStrFromDate(temp),
        }
      );
    }
    
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }


  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }



  openClassDetail(item) {
    // if(item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(HealthClassDetailComponent, {
      position: {
      },
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { class: item, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }
  
  popup_student_export() {
    // let filter = {};
    console.log(this.filter);
    this.studentService.exportStudentInattention(this.filter.khoi_code,this.selectedDateStr);
  }

  confirmDownloadExcel(cap_name) {
    let data = {
      title: 'Tải danh sách học sinh vắng',
      msg: 'Xác nhận tải.',
      ok : 'Tải xuống'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(cap_name);
        // console.log(this.filter);
        this.studentService.exportStudentInattention(cap_name,this.selectedDateStr);

      }
    });
  }

}

