<div class="row">
    <div class="col-6">
        <h1 mat-dialog-title>Thanh toán nợ</h1>
    </div>
    <div class="col-6 text-right">
        <button *ngIf="step == 0" (click)="processDebtSale()" class="blurButton btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label"><i class="ti ti ti-money"></i></span>Thanh toán
        </button>
        <button *ngIf="step == 1" (click)="doDebtSale()" class="blurButton btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label"><i class="ti ti ti-check-box"></i></span>Xác nhận
        </button>
    </div>
</div>
<div class="container">
    <div class="row info-row">
        <div class="col">
            Khách hàng:
        </div>
        <div class="col text-right">
            {{data.customer_name}}
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Tổn nợ:
        </div>
        <div class="col text-right">
            {{data.debt | number}}
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Khách trả:
        </div>
        <div class="col text-right">
            <input [(ngModel)]="customer_pay" 
                class="form-control form-control-custom1" placeholder="Khách trả" currencyMask type="text">
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Tiền thừa:
        </div>
        <div *ngIf="data.debt - customer_pay < 0" class="col text-right">
            {{data.debt - customer_pay | number }}
        </div>
    </div>
    <div class="d-flex flex-wrap mb-2">

        <div *ngFor="let item of SaleDebtDatas; let i = index" class="ibox qbs-ibox m-2">
            <div class="ibox-body">
                <div class="mb-1 font-13">{{item.price_after_promotion|number}}</div>
                <div class="text-danger">{{item.price_after_promotion - item.payment_total|number}}</div>
                <span class="material-icons widget-stat-icon " [ngClass]="{'text-primary' : (i >= index_pays && step==1) }"  > price_change </span>
                
                <div *ngIf="step==0" class="mt-3  mb-3 text-center ">
                    <span class="text-danger"> Nợ </span> 
                    <span class="font-weight-bold">
                        {{item.price_after_promotion - item.payment_total|number}}</span>
                </div>
                <div *ngIf="step==1" class="mt-3  mb-3 text-center ">
                    <span *ngIf="i >= index_pays" class="text-danger"> Trả </span> 
                    <span *ngIf="i <index_pays" class="text-danger"> Nợ </span> 
                    <span class="font-weight-bold" [ngClass]="{'qbs-text-line-through' : (i >= index_pays && step==1)}"  >
                        {{sale_debt_pays_temp[i] |number}}</span>
                </div>

                <div class="font-15 mt-1 text-right" style="color: gray;">
                    <span><i class="ti ti-timer"></i></span>
                    <span class="ml-1 mr-1"> {{item.created_date.substr(11, 5)}}</span>
                    <span> {{item.day_month }}</span>
                </div>
            </div>
        </div>

    </div>
</div>