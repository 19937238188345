import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { SchoolService } from 'src/app/_services/school.service';
import { StudentService } from 'src/app/_services/student.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { UserService } from 'src/app/_services/user.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { TeacherUpdateComponent } from '../../teacher/teacher-update/teacher-update.component';
import { StudentResetComponent } from '../student-reset/student-reset.component';

@Component({
  selector: 'app-student-update',
  templateUrl: './student-update.component.html',
  styleUrls: ['./student-update.component.css']
})
export class StudentUpdateComponent implements OnInit {
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  // for update
  studentSelected: any;
  tab = "student" //tab mặc dịnh sẽ mở
  isDeleted: boolean;
  studentSelected_imageUrl: any = environment.student_avatar;
  fileteacherImg: File;
  classs: any
  birthday_temp: Date;
  isLoading = false;
  filter = {
    khoi_code: "",
  }
  // for user ( mother father guadiand)
  users = JSON.parse(JSON.stringify(this.appService.parent_list));
  constructor(
    public appService: AppService,
    public userService: UserService,
    public utilityService: UtilityService,
    public schoolService: SchoolService,
    public studentService: StudentService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private ng2ImgMax: Ng2ImgMaxService,
    public ioService: IoService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TeacherUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.studentSelected = data.item;
    if(data.tab !='') this.tab = data.tab; // nếu truyền vào khác ""
    if(this.tab == 'parent')this.loadUsers();
    this.birthday_temp = this.utilityService.getDateFromStr(this.studentSelected.birthday_str);
    if (this.studentSelected.image_url != "") {
      this.studentSelected_imageUrl = this.appService.getUrlImage(this.studentSelected.image_url);
    } else {
      this.studentSelected_imageUrl = environment.student_avatar;
    }
    this.isDeleted = this.studentSelected.deleted == 0 ? false : true;
    this.loadClasss();
  }

  ngOnInit(): void {
  }
  updateStudent() {

    if (this.studentSelected.full_name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.teacherUpdateForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.studentSelected.class_code == "") {
      this.toastr.error("", "Bạn chưa chọn lớp");
      return;
    }

    // xưu lý name
    this.studentSelected.full_name = this.studentSelected.full_name.trim();
    var temp = this.studentSelected.full_name.split(" ");
    this.studentSelected.name = temp[temp.length - 1];
    this.studentSelected.khoi = this.appService.getKhoiByClassCode(this.studentSelected.class_code, this.classs);

    if (this.fileteacherImg !== null && typeof (this.fileteacherImg) !== "undefined") {
      this.studentSelected.image_url = environment.student + "/" + this.studentSelected.code + "." + this.utility.getExtensionFile(this.fileteacherImg);
      console.log("1111");
    } else {
      console.log("2222");
    }
    // xử lý birthday 
    this.studentSelected.birthday = this.utility.getFullDateStrFromDate(this.birthday_temp);

    if (!this.teacherUpdateForm.invalid) {
      this.studentSelected.deleted = this.isDeleted ? 1 : 0;
      // xưu lý name
      this.studentSelected.full_name = this.studentSelected.full_name.trim();
      var temp = this.studentSelected.full_name.split(" ");
      this.studentSelected.name = temp[temp.length - 1];

      this.isLoading = true;

      this.studentService.update(this.studentSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            let result = {
              code: "ok",
              data: {}
            }
            this.dialogRef.close(result);
          }
        },
        error => {
          this.isLoading = false;
          console.error("error:");
          console.error(error);
        })
      this.uploadAvatar(this.fileteacherImg, this.studentSelected.code);
    }
  }
  focusOutCheckPhone(i) {
      if(this.users[i].phone != "")
      this.users.forEach((item, index)  => {
          if(i != index){ //kiểm tra i so với index
              if(this.users[i].phone == item.phone){
                 this.users[i].phone = "";
                 this.toastr.error("Số điện thoại đã tồn tại với học sinh này", "");
              }
          }
      });
  }
  updateUser() {
    console.log(this.users);
    let temp_check = true;
    this.users.forEach(element => {
        if(element.phone == "" && element.name == "" ){
          // không nhập gì thì thôi
        }else{
          // nếu phone không hợp lệ hoặc name không nhập thì lỗi
          if(element.phone.length != 10 || element.name == "")
          temp_check = false;
        }
    });
    if(!temp_check){
      this.toastr.error("", "Vui lòng kiểm tra lại tên hoặc số điện thoại");
      return;
    }

    let data = {
      student_code : this.studentSelected.code,
      data: this.users
    }
    this.isLoading = true;
      this.userService.updateOrCreateUser(data).subscribe(
        async response => {
          this.isLoading = false;
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        },
        error => {
          this.isLoading = false;
          console.error("error:");
          console.error(error);
        })
  }
  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async loadUsers() {
    let filter = {
      student_code: this.studentSelected.code,
    };
    this.userService.getUsersByStudent(filter).subscribe(
      async response => {
        console.log(response);
        if (response) {
          // this.users = response;
          // thực hiện lấy dữ liệu và gán vào giao diện
          response.forEach(item => {
              if(item.role == 'mother'){
                this.users[0].code = item.code;
                this.users[0].name = item.name;
                this.users[0].phone = item.phone;
              }else if(item.role == 'father'){
                this.users[1].code = item.code;
                this.users[1].name = item.name;
                this.users[1].phone = item.phone;
              }else if(item.role == 'guardian'){
                this.users[2].code = item.code;
                this.users[2].name = item.name;
                this.users[2].phone = item.phone;
              }
          });
        }
      },
      error => {
        console.error(error);
      })
  }
  async avatarChangeListener($event): Promise<void> {
    this.fileteacherImg = $event.target.files[0];
    if (this.fileteacherImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
        this.fileteacherImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileteacherImg);
        reader.onload = (_event) => {
          this.studentSelected_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.student,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  popupResetPass(item){
    console.log(item);
    if(item.phone.length != 10){
      this.toastr.warning("", "Số điện thoại không hợp lệ");
        return;
    }
    // mở popup
    const dialogRef = this.dialog.open(StudentResetComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Đóng");
    });
  }
}
