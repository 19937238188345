import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { TeacherService } from 'src/app/_services/teacher.service';
import { Teacher } from 'src/app/model/teacher';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { PopupGenSchdeduleDetailComponent } from '../teaching-schedules/popup-gen-schdedule-detail/popup-gen-schdedule-detail.component';
import { SchedulesDetailComponent } from '../teaching-schedules/schedules-detail/schedules-detail.component';

@Component({
  selector: 'app-teaching-assignment',
  templateUrl: './teaching-assignment.component.html',
  styleUrls: ['./teaching-assignment.component.css']
})
export class TeachingAssignmentComponent implements OnInit {
  @ViewChild('teacherAddForm') teacherAddForm: HTMLFormElement;
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  isLoading = false;
  isHighlight = false;
  // tempForIsHighlight = []; // chỉ để cho isHightLine
  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

  //for color
  // colorList = [{ code: "black" }, { code: "white" }, { code: "gray" }, { code: "red" }, { code: "green" }, { code: "blue" }, { code: "yellow" }];



  //for data
  teachers = [];
  teachers_backup: any
  categorys_subject: any
  categorys_class: any
  checkin_time_teacher: any // danh sách lịch dạy  theo giáo viên
  facultys: any
  filter = {
    name: "",
    positions: "",
    faculty_code: "",
    deleted: 0,
  }


  // for add
  teacher = new Teacher("");
  fileteacherImg: File;
  imageUrl: any = environment.teacher_avatar;

  //for paging
  viewNumsObj = 200;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  // for nghiệp vụ //for teaching assignment
  teacherIndexSelected = -1;
  teacherSelected = [];
  subjectIndexSelected = -1;
  subjectSelected = [];
  classIndexSelected = -1;
  checkinTimeIndexSelected = -1;
  classSelected = [];
  // checkinTimeIndexSelected = -1;
  checkinTimeSelected = [];
  hockySelectedList = [];
  hockyIIIndexSelected = -1;
  teachingAssignmentData = [];


  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    // private utility: UtilityService,
  ) {
    this.appService.page_title = "Phân công giảng dạy";
    this.sorter.sortStr = this.appService.sort_teachers[0].code;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_giao_vien);

  }




  ngOnInit() {
    this.loadTeacher();

    this.loadFaculty();
    this.loadCategorySubjects();

  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  clearSearch() {
    this.filter.name = '';
    this.loadTeacher();
  }

  caphoc_change(code) {
    if(this.appService.capSelected == code){
      this.appService.capSelected = '';
    }else{
      this.appService.capSelected = code;
    }
    this.teacherIndexSelected = -1;
    this.subjectIndexSelected = -1;
    this.teacherSelected = [];
    this.hockySelectedList = [];
    this.loadCategorySubjects();
    console.log(code);
  }
  clickTeacher(i) {

    if (this.appService.checkFullPermission() || this.teachers[i].code == this.appService.currentUser.code) {
      this.teacherIndexSelected = i;
      this.subjectIndexSelected = -1;
      this.classIndexSelected = -1;
      this.checkinTimeIndexSelected = -1;
      this.hockySelectedList = [];
      this.checkin_time_teacher = [];
      // if (this.teachers[i].teachingAssignment != ""){
      //   this.teacherSelected = this.teachers[i].teachingAssignment;
      // }
      // else{
      //   this.teacherSelected = [];
      // }
      // load dữ liệu lịch dạy liên quan đến giáo viên được chọn
      console.log(this.teachers[i].code,this.teachers[i].name);
      // loadAssignmentBySome
      // xửu lý hiển thị môn
      let filter = {
        teacher_code : this.teachers[i].code
      }
      this.teacherService.loadAssignmentBySome(filter, 2).subscribe(
        async response => {
          if (response) {
            this.categorys_subject.forEach(s => {
              s.temp_checked = 0;
              response.forEach(a => {
                  if(s.code == a.subjects_code){
                    s.temp_checked = 1;
                    return;
                  }
              });
            });
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", "Đã có lỗi xảy ra");
          console.error('error', error);
        })

    } else {
      this.toastr.warning("", "Thầy cô chỉ có thể phân công cho chính mình");
    }
  }
  hocky_change(item) {
    this.appService.hockySelected = item;
    this.teacherIndexSelected = -1;
    this.subjectIndexSelected = -1;
    this.teacherSelected = [];
    this.hockySelectedList = [];
    this.loadTeacher();
    this.loadCategorySubjects();
  }
  
  
  clickSubjects(i) {
    if (this.teacherIndexSelected < 0) {
      this.toastr.warning("", "Vui lòng chọn giáo viên");
    } else {
      if (this.subjectIndexSelected == i) {
        // this.subjectIndexSelected = -1;
      }
      else {
        this.subjectIndexSelected = i;
      }
      this.classIndexSelected = -1;
      this.checkinTimeIndexSelected = -1;
      this.checkin_time_teacher = [];
      // đây là object của teaching assignment
      // var classs = {
      //   teacher_code: this.teachers[this.teacherIndexSelected].code,
      //   hocky: this.appService.hockySelected,
      //   subjects_code: this.categorys_subject[i].code,
      //   subjects_name: this.categorys_subject[i].name,
      //   class: [] // xóa hoặc thêm
      // };

      // this.addSubjectsToTeacher(classs);
      this.loadCategoryClassBySubject(this.categorys_subject[i].code);

      // this.hockySelectedList = this.teachers[this.teacherIndexSelected].teachingAssignment[this.getIndexSubjectInTeaAss(this.subjectIndexSelected)];
    }


    
  }
  selectClass(evt, i) {
    this.checkinTimeIndexSelected = -1;
    if (this.subjectIndexSelected < 0) {
      evt.preventDefault();
      this.toastr.warning("", "Thầy cô chưa chọn môn");
      return;
    } 

    if (this.classIndexSelected == i) {
      
    }
    else {
      this.classIndexSelected = i;
    }

    // if(!evt.target.checked){ // nếu bỏ chọn
    //   this.classIndexSelected = -1;
    // }

    // var classs = {
    //   class_code: this.categorys_class[i].code,
    //   class_name: this.categorys_class[i].name,
    //   actions: evt.target.checked  // xóa hoặc thêm
    // };
    // this.addClassToSubjects(classs);
    //load checkinTime
    this.loadCheckinTimeByTeaSubCla(this.teachers[this.teacherIndexSelected].code, this.categorys_subject[this.subjectIndexSelected].code , this.categorys_class[i].code);
  }
  selectCheckinTime(evt, i) {

    
    if (this.subjectIndexSelected < 0 || this.classIndexSelected < 0) {
      evt.preventDefault();
      this.toastr.warning("", "Thầy cô chưa chọn môn hoặc lớp");
      return;
    } 
    
    // this.checkin_time_teacher[i].temp_checked = evt.target.checked;
    
    if (this.checkinTimeIndexSelected == i) {
      
    }
    else {
      this.checkinTimeIndexSelected = i;
    }

    console.log(this.checkin_time_teacher[i]);

  }

  addSubjectsToTeacher(subjects) {
    if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment == "undefined" || this.teachers[this.teacherIndexSelected].teachingAssignment == "")
      this.teachers[this.teacherIndexSelected].teachingAssignment = [];

    //kiểm tra đã tồn tại chưa và tra về vị trí tồn tại
    var existingIndex = -1;
    for (let i = 0; i < this.teachers[this.teacherIndexSelected].teachingAssignment.length; i++) {
      if (subjects.subjects_code == this.teachers[this.teacherIndexSelected].teachingAssignment[i].subjects_code) {
        existingIndex = i;
        break;
      }
    }
    if (existingIndex == -1)// chưa có
      this.teachers[this.teacherIndexSelected].teachingAssignment.push(subjects);
    else {
      // this.teachers[this.teacherIndexSelected].teachingAssignment.splice(existingIndex, 1);
    }
    this.teacherSelected = this.teachers[this.teacherIndexSelected].teachingAssignment;
    // console.log(this.teachers[this.teacherIndexSelected].teachingAssignment);
  }
  addClassToSubjects(classs) {
    console.log(classs);
    // tìm index subject trong teachingAssignment
    var existingSubjectIndex = this.getIndexSubjectInTeaAss(this.subjectIndexSelected);

    var subClassTeaAssObj = this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubjectIndex];
    //kiểm tra đã tồn tại chưa và tra về vị trí tồn tại
    var existingIndex = -1;
    for (let i = 0; i < subClassTeaAssObj.class.length; i++) {
      if (classs.class_code == subClassTeaAssObj.class[i].class_code) {
        existingIndex = i;
        break;
      }
    }
    if (existingIndex == -1)// chưa có thì kiểm tra tiếp để thêm
      // if(classs.actions) // tích chọn thì thêm mới
      subClassTeaAssObj.class.push(classs);
    else {
      //nếu đã tồn tại thì thay hoặc xóa
      if (classs.actions) // tích chọn thì thêm mới
        subClassTeaAssObj.class[existingIndex] = classs;
      else
        subClassTeaAssObj.class.splice(existingIndex, 1);
    }
    this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubjectIndex] = subClassTeaAssObj
  }
  addCheckinTimeToClass(checkinTime) {
    console.log(checkinTime);

    // return;
    // tìm index subject trong teachingAssignment
    var existingSubjectIndex = this.getIndexSubjectInTeaAss(this.subjectIndexSelected);

    // tìm index class trong teachingAssignment
    var existingClassIndex = this.getIndexClassInTeaAss(existingSubjectIndex, this.classIndexSelected);

    var subClassTeaAssObj = this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubjectIndex].class[existingClassIndex];

    console.log(subClassTeaAssObj);
   
    let data = [];
    this.checkin_time_teacher.forEach(element => {
      console.log(element.temp_checked);
        data.push({
          teacher_code : this.teachers[this.teacherIndexSelected].code,
          subject_code : this.categorys_subject[this.subjectIndexSelected].code,
          class_code : this.categorys_class[this.classIndexSelected].code,
          checkinTime_id : element.id,
          checkinTime_status : element.temp_checked
        });
    });
    console.log("data", data);
    return;


  }


  getIndexSubjectInTeaAss(j): number {
    var existingSubIndex = -1;
    if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment != "undefined") {

      for (let i = 0; i < this.teachers[this.teacherIndexSelected].teachingAssignment.length; i++) {
        if (this.teachers[this.teacherIndexSelected].teachingAssignment[i].subjects_code == this.categorys_subject[j].code) {
          existingSubIndex = i;
          break;
        }
      }
    }
    console.log(existingSubIndex);
    return existingSubIndex;
  }
  getIndexClassInTeaAss(j,k): number {
    //k : class selected index
    //j: subject selected index select
    var existingClassIndex = -1;
    if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment != "undefined") {

      if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment[j].class  != "undefined") {

        for (let i = 0; i < this.teachers[this.teacherIndexSelected].teachingAssignment[j].class.length; i++) {
          var temp = this.teachers[this.teacherIndexSelected].teachingAssignment[j].class[i];
          if (temp.class_code == this.categorys_class[k].code) {
            existingClassIndex = i;
            break;
          }
        }
      }
    }
    console.log(existingClassIndex);
    return existingClassIndex;
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadTeacher();
  }


  updateTeachingAssignment() {

 

    if (this.teacherIndexSelected < 0 || this.subjectIndexSelected< 0 || this.classIndexSelected< 0 ) {
      this.toastr.warning("", "Thầy cô chưa chọn Giáo viên hoặc Môn học hoặc Lớp");
      return;
    } 


    let element = this.checkin_time_teacher[this.checkinTimeIndexSelected];
    var data = {
      teacher_code : this.teachers[this.teacherIndexSelected].code,
      subjects_code : this.categorys_subject[this.subjectIndexSelected].code,
      class_code : this.categorys_class[this.classIndexSelected].code,
      checkinTime_code : '',
      type : 1 // phân công giảng dạy; 2 lịch dạy
    }
    this.isLoading = true;
    this.teacherService.updateTeachingAssignment_v2(data).subscribe(
      async response => {
        this.isLoading = false;
        this.isHighlight = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("", "Cập nhật phân công giảng dạy thành công");
          this.categorys_class[this.classIndexSelected].temp_checked = 1;
          this.loadTeacher();
        }else{
          this.toastr.warning(response.msg, "Cập nhật không thành công");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("", "Đã có lỗi xảy ra");
        console.error('error', error);
      })


    console.log(this.teachers);
    // var teachingAssignmentObj = [];
    // lặp danh sách giáo viên
    // this.teachers.forEach(element => {
    //   if (typeof element.teachingAssignment != "undefined" && element.teachingAssignment != "") {
    //     // lặp danh sách lớp
    //     if (element.teachingAssignment.length > 0) {
    //       element.teachingAssignment.forEach(subjectsItem => {
    //         // nếu subjects không có lớp
    //         if (subjectsItem.class.length > 0) {
    //           subjectsItem.class.forEach(classItem => {
    //             var temp = {
    //               teacher_code: subjectsItem.teacher_code,
    //               hocky: subjectsItem.hocky,
    //               subjects_code: subjectsItem.subjects_code,
    //               subjects_name: subjectsItem.subjects_name,
    //               class_code: classItem.class_code,
    //               class_name: classItem.class_name,
    //             };
    //             teachingAssignmentObj.push(temp);
    //           });
    //         } else {
    //           var temp = {
    //             teacher_code: subjectsItem.teacher_code,
    //             hocky: subjectsItem.hocky,
    //             subjects_code: subjectsItem.subjects_code,
    //             subjects_name: subjectsItem.subjects_name,
    //             class_code: "",
    //             class_name: "",
    //           };
    //           teachingAssignmentObj.push(temp);
    //         }
    //       });
    //     } else {

    //     }
     
    //   }
    // });
    

  }

  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadTeacher();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadTeacher();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadTeacher();
    }
  };

  loadCategorySubjects() {

    let pagingObj = {
      viewNumsObj: 100,
      pageObj: 1
    };
    let filter = {
      type: environment.cateType_subjects,
      subject_cap: this.appService.capSelected
    };
    let sorter = {
      sortStr: "od",
    }

    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys_subject = response.data;
          console.log("this.categorys_subject:", this.categorys_subject);
        }
      },
      error => {
        console.error(error);
      })
  }
  loadCategoryClassBySubject(subject_code) {

    let data = {
      teacher_code: this.teachers[this.teacherIndexSelected].code,
      subject_code: subject_code,
      type: 2
    }
    this.categorys_class = [];
    this.teacherService.loadAssignmentClassBySubject(data).subscribe(
      async response => {
        if (response) {
          this.categorys_class = response;
          // console.log(this.categorys_class);
        }
      },
      error => {
        console.error(error);
      })
  }
  // loadCheckinTimeByTeacher(teacher_code) {

  //   let data = {
  //     teacher_code: teacher_code,
  //   }
  //   this.checkin_time_teacher = [];
  //   this.teacherService.loadCheckinTimeByTeacher(data).subscribe(
  //     async response => {
  //       if (response) {
  //         this.checkin_time_teacher = response;
  //         console.log(this.checkin_time_teacher);
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  loadCheckinTimeByTeaSubCla(teacher_code, subject_code, class_code) {

    let data = {
      teacher_code: teacher_code,
      subject_code: subject_code,
      class_code: class_code,
      type: 2,
    }
    this.checkin_time_teacher = [];
    this.teacherService.loadCheckinTimeByTeaSubCla(data).subscribe(
      async response => {
        if (response) {
          this.checkin_time_teacher = response;
          console.log(this.checkin_time_teacher);
        }
      },
      error => {
        console.error(error);
      })
  }

  onInputChange() {
    this.isHighlight = true;
    // Thực hiện các xử lý khác ở đây nếu cần
  }

  async loadTeacher() {
      this.teacherService.loadTeachingAssignment_v1(this.filter, this.sorter).subscribe(
        async response => {
          // console.log(response);
          if (response) {
            this.teachers = response;
            this.searching = false;
          }
        },
        error => {

        }
      )
  }
  

  loadTeachingAssignmentToTeacher(teacher, assignment) {
    teacher.forEach((item, i) => {

      item.teachingAssignment = []

      var countSubjects = 0; //
      assignment.forEach((assignmentItem, i) => {
        if (item.code == assignmentItem.teacher_code) {
          countSubjects++; // 
          item.teachingAssignment.push(assignmentItem);
        }
      });

    });

  }

  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        // console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }







  category_change(event) {
    this.loadTeacher();
  }
  sort_change(event) {
    this.loadTeacher();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.teachers = this.teachers_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.teachers = this.teachers.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  openScheduleDetail(ktem){
    
  }
  popupDelete(index, list){
    // popup confirm
    let tempdata = {
      title: 'Xác nhận',
      msg: 'Xóa phân công giảng dạy và các lịch chi tiết liên quan ?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {

        let data = {
          'ass_code' : list[index].ass_code,
          'type' : 1,
        }
        this.teacherService.deleteAssignment(data).subscribe(
          async response => {
        
            console.log(response);
            if (response.code == 'ok') {
              this.toastr.success("Xóa phân công giảng dạy thành công");
              console.log(list);
              console.log(index);
              // Xóa phần tử tại index 
              list.splice(index, 1);
            }
          },
          error => {
            this.isLoading = false;
            // this.isLoading.dismiss();
            console.error(error);
          });
      }
    });
  }
  loadAndSaveTeachingAssignmentDetail(ktem){

    const dialogRef = this.dialog.open(PopupGenSchdeduleDetailComponent, {
      data: ktem
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.code == 'ok') {

        let data = {
          ass_code : ktem.ass_code,
          checkin_start: result.checkin_start,
          checkin_end: result.checkin_end,
          checkin_weeks: result.checkin_weeks
        }
        console.log(data); 
        this.teacherService.loadAndSaveTeachingAssignmentDetail(data).subscribe(
          async response => {
            console.log(response);
            if (response.code == 'ok') {
              this.loadTeacher();
              this.toastr.success("Tạo lịch chi tiết thành công");
            }else{
              this.toastr.error(response.code,"Có lỗi xảy ra");
            }
          },
          error => {
            this.isLoading = false;
            // this.isLoading.dismiss();
            console.error(error);
          });
      }
    });
  }
  popupDetail(item){
    // mở popup
    const dialogRef = this.dialog.open(SchedulesDetailComponent, {
      position: {
         top: '0px',
        right: '0px'
      },
      width: '65%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {
        'teacher_code': item.code,
        'type': 2
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        
      }
    });
  }
  

  teacherCodeTypeClick(type) {
    this.teacherCodeType = type;
    if (this.teacherCodeType == "mts") {
      this.teacher.code = this.teacherCodeTemp;
    } else if (this.teacherCodeType == "tn" || this.teacherCodeType == "qmv") {
      this.teacher.code = "";
    }
  }

  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.teacher_avatar + ')';
  // }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadTeacher();
      }),
      map(term => term === '' ? [] : this.teachers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    console.log("Tìm kiếm: ", this.filter);
    this.filter.name = "";
  }
}


