import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { ApplicationComposeComponent } from './application-compose/application-compose.component';
import { SchoolService } from 'src/app/_services/school.service';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  categorys : any; // lớp
  checkboxAllButton = true; // mặc định chọn all
  discussion ={
    recipient:'',
    title:'',
    message: '',
  };
  isLoading = false;

  //for data
  messengers = [];
  filter = {
    title: "",
    content: "",
    user_code: "",
    user_type: "phhs",
    name: "",
    khoi_code: "",
    class_code: "",
  }
  classs: any
  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public studentService: StudentService,
    public schoolService: SchoolService,
    public messengerService: MessengerService,
    private toastr: ToastrService,
    public appService: AppService,
    public dialog: MatDialog,

  ) {
    // this.discussion.message = this.appService.currentUser.school_name ;
    this.appService.page_title = "Giấy nghỉ phép";
    this.loadDatas();
    this.loadClasss();
  }

  ngOnInit(): void {
  }

  loadDatas() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
     
      this.schoolService.applicationLoad(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
    
            this.messengers = response.data;
          
            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.messengers.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
            
            
          }
        },
        error => {

        })
    }
  }
  category_change(event) {
    console.log(this.filter);
    this.loadDatas();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
    }
    this.loadStudent();
  };
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadDatas();
  }
  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  popup_compose(){
    // mở popup
    const dialogRef = this.dialog.open(ApplicationComposeComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '585px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {} 
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadDatas();
        this.toastr.success("", "Gửi tin thành công");
      }
    });
  }
  popupDetail(item){
      console.log(item);
        // mở popup
      const dialogRef = this.dialog.open(ApplicationDetailComponent, {
        position: {
          // top: '0px',
          // left: '0px'
        },
        width: '600px',
        // height: '90%',
        panelClass: 'full-screen-modal',
        data: item
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("Đóng");
      });
  }
  clearSearch(){
    this.filter.name='';
    this.loadDatas();
  }
  
}
