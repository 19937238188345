
export class User {
    id: number = 0;
    name = "";
    shop_code = "";
    shop_name = "";
    email = "";
    phone = "";
    gender = 0;
    shop_address = "";
    shop_email = "";
    shop_phone = "";
    shop_name2 = "";
    shop_type = 0;
    shop_unit = "";
    meal_time = "";
    shop_service_prices = "";
    desk_number = 0;
    created_date : Date;
    role = 0;
    image_url = '';
    isLoggedIn = false;
    isResset =0;
    type_camera =0;
    password = "";
    token = "ttttttttttt";
    name_alias = ''; // CBGV gán cấp học
    //
    code = "";
    school_code = "";
    school_name = "";
    school_address = "";
    scholastic = "";
    headmaster = "";
    positions = "";
    user_role = "";
    login_role = "";
    class_code = "";
    khois = [];
    caps = [];
    checkin_status = 0; // camera
    access_token = ""; // camera
    refresh_token = "";
    bank_name = "";
    bank_account_name = "";
    bank_account = "";
    telegram_chat_id = "";
    bidv_merchantId = "";
    bidv_service_id = "";
    hanet_placeID = 0;

    scholastics_list = [];
    scholastics_selected = '';

    constructor() {
       
    }
}
