<div class="page-content fade-in-up">


  <div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
      <div class=" p-2 input-group-icon input-group-icon-left mr-3">
        <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
        <input class="form-control form-control-rounded form-control-solid" id="key-search"
          (keydown)="getTypingSearch($event)" type="text" placeholder="Tìm kiếm ...">
      </div>
    </div>
    <div class="col">
      <div class="d-flex justify-content-end">
        <div class="p-2"> <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" (click)="popupCreate()">
            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="table-responsive row">
    <table class="table table-head-purple table-hover" id="datatable">
      <thead class="thead-default thead-lg">
        <tr>
          <th style="width: 50px;">#</th>
          <th>Thành viên</th>
          <th>Email</th>
          <th>Điện thoại</th>
          <th>Vài trò</th>
          <th>Note</th>
          <th class="text-center">Trạng thái</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of memberships; let i = index">
          <td>
            <span style="position: absolute; left: 42px;
            line-height: 20px;">{{i+1}}</span>
            <a class="media-img" href="javascript:;">
              <i class="ti ti-user font-20"></i>
            </a>
          </td>
          <td>
            <div style="font-size: 18px;">{{item.name}}</div>
          </td>
          <td>{{item.email }}</td>
          <!-- <td>{{item.created_date | date :'dd/MM/yyyy'}}</td> -->
          <td>{{item.phone}}</td>
          <td>{{utilityService.getNameOfRoleUser(item.role)}}</td>
          <td>{{item.description}}</td>
          <td class="text-center" >
            <span *ngIf="item.deleted != 1"  class="badge badge-primary">Hoạt động</span>
            <span  *ngIf="item.deleted == 1" class="badge badge-danger">Ngừng</span>
          </td>

          <td>
            <button class="btn-adjust btn  btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><i
                class="ti ti-pencil-alt"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>



<div class="modal fade" id="add-membership-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" #addForm="ngForm"  id="form-add-membership" (ngSubmit)="insertMembership()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Thêm mới thành viên</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Họ và tên</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.name" class="form-control" name="name" required placeholder="Họ và tên" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Điện thoại</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.phone" class="form-control" name="phone" required minlength="10" maxlength="10" placeholder="Số điện thoại"
              type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.email" class="form-control" name="email" placeholder="Email" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Vai trò</label>
          <div class="col-sm-9">

            <!-- <div *ngFor="let item of appService.memberships" style="display: inline-block; padding: 5px; width: 50%;">
              <label  class="checkbox checkbox-primary" [ngClass]="{'disabled': item.code =='admin'}" >
                <input type="checkbox" [value]="item.code" [disabled]="item.code =='admin'"  [checked]="item.code == membership.role"  (change)="selectRole($event)"/>
                <span class="input-span"></span>{{item.name}}</label>
            </div>
             -->
            <select class="show-tick form-control" [(ngModel)]="membership.role" name="roleSelect" class="qbs-select">
              <option [ngValue]="item.code" *ngFor="let item of appService.memberships">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Mật khẩu</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.password" class="form-control" type="password" name="password"
              placeholder="Mật khẩu" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Nhập lại MK</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.repassword" class="form-control" type="password" name="repassword"
              placeholder="Nhập lại Mật khẩu" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Ghi chú</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membership.description" class="form-control" name="description" placeholder="Mô tả"
              type="text">
          </div>
        </div>

      </div>
      <div class="modal-footer justify-content-center bg-primary-50">

        <div class="row align-items-center">
          <div class="col text-center">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
              <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
              <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
              Thêm mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>





<div class="modal fade" id="update-membership-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal"  #updateForm="ngForm"  id="form-update-membership" (ngSubmit)="update()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Cập nhật thành viên</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Họ và tên</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membershipSelected.name" class="form-control" required name="name" placeholder="Họ và tên" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membershipSelected.email" class="form-control" name="email" placeholder="Email"
              type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Điện thoại</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membershipSelected.phone" class="form-control" required minlength="10" maxlength="10" name="phone" placeholder="Số điện thoại"
              type="text">
          </div>
        </div>
        
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Vai trò</label>
          <div class="col-sm-9">
            <select class="show-tick form-control" [(ngModel)]="membershipSelected.role" name="roleSelect" class="qbs-select">
              <option [ngValue]="item.code" *ngFor="let item of appService.memberships">{{item.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Reset mật khẩu</label>
          <div class="col-sm-7 text-right">
            <label class="ui-switch switch-icon">
              <input type="checkbox" [(ngModel)]="isResset"  name="isResset">
              <span></span>
            </label>
          </div>
        </div>

        <div *ngIf="isResset" >
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Mật khẩu</label>
            <div class="col-sm-9">
              <input [(ngModel)]="membershipSelected.password" class="form-control" type="password" name="password"
                placeholder="Mật khẩu" type="text">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Nhập lại MK</label>
            <div class="col-sm-9">
              <input [(ngModel)]="membershipSelected.repassword" class="form-control" type="password" name="repassword"
                placeholder="Nhập lại Mật khẩu" type="text">
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Ghi chú</label>
          <div class="col-sm-9">
            <input [(ngModel)]="membershipSelected.description" class="form-control" name="description" placeholder="Mô tả"
              type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Vô hiệu hóa</label>
          <div class="col-sm-9 text-right">
              <label class="ui-switch switch-icon">
                <input type="checkbox" [(ngModel)]="isDeleted" [disabled]="membershipSelected.role=='admin'" name="deleted">
                <span></span>
              </label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-around bg-primary-50">
        <button class="btn btn-primary btn-sm  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
          <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
          <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
          Cập nhật
        </button>
      </div>
    </form>
  </div>
</div>
