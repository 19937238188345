import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finance-print-preview',
  templateUrl: './finance-print-preview.component.html',
  styleUrls: ['./finance-print-preview.component.css']
})
export class FinancePrintPreviewComponent implements OnInit {

  constructor(    
    public dialogRef: MatDialogRef<FinancePrintPreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
    ) {
      console.log(data);
     }

  ngOnInit(): void {
  }
  click_select(){
    
  }
}
