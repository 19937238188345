<div class="row mb-2 mt-2 ">
    <div class="col-8 p-0 ">
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-2 btn-primary btn-labeled btn-labeled-left btn-icon  mr-2" ngbDropdownToggle>
                <span class="btn-label"><img class="img-circle" src="./assets/img/icons/chart.png" alt="image"
                        width="35" /></span>
                {{type_report}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="selectReport(appService.type_report_day)" class="text-primary">
                    <img class="icon-image-btn  mr-2" src="./assets/img/icons/excel.png" alt="image" width="20" />
                    {{appService.type_report_day}}
                    <span class="my-block-20"></span>
                </button>
                <button ngbDropdownItem (click)="selectReport(appService.type_report_detail_week)" class="text-primary">
                    <img class="icon-image-btn  mr-2" src="./assets/img/icons/excel.png" alt="image" width="20" />
                    {{appService.type_report_detail_week}}
                    <span class="my-block-20"></span>
                </button>
                <button ngbDropdownItem (click)="selectReport(appService.type_report_detail_month)" class="text-primary">
                    <img class="icon-image-btn  mr-2" src="./assets/img/icons/excel.png" alt="image" width="20" />
                    {{appService.type_report_detail_month}}
                    <span class="my-block-20"></span>
                </button>
            </div>
        </div>

    </div>

    <div class="col-4  p-0 d-flex justify-content-end ">

        <mat-form-field appearance="fill" style="width: 200px;" class="mr-3">
            <mat-label>Tổ</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill" *ngIf="type_report == appService.type_report_day">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>




<div *ngIf="type_report == appService.type_report_day">

    <div class="row">
        <div class="col-lg-4 col-md-6 p-0">
            <div class="ibox">
                <div class="p-2">
                    <div class="row text-center">
                        <div class="col">
                            <div class="">Tổng số</div>
                            <h2 class="mb-1">{{allSchools.teacher_count}}</h2>
                        </div>
                        <div class="col " [ngClass]="{'text-info': true }">
                            <div class="">Điểm danh</div>
                            <h2 class="mb-1">{{allSchools.teacher_count - allSchools.teacher_inattention}}</h2>
                        </div>
                        <div class="col " [ngClass]="{'text-danger': allSchools.teacher_inattention >0 }">
                            <div class="">Vắng</div>
                            <h2 class="mb-1">{{allSchools.teacher_inattention}}</h2>
                        </div>
                    </div>
                    <div class="progress mb-1">
                        <div class="progress-bar bg-primary" role="progressbar" [style.width.%]="allSchools.percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                            {{ allSchools.percent }}%
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>So với hôm qua **?</span>
                        <!-- <span class="text-primary font-12"><i class="fa fa-level-up"></i> +24%</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>





    <div class="row lop-cover">
        <div *ngFor="let item of reportcheckins; let i = index" (click)="openCheckinClass(item)" class="card text-center card-air  m-1 lop-item">
            <div class="card-body" style="min-width: 150px;">

                <span *ngIf="item.isAvailable == 0" class="material-icons font-18 disableView">visibility_off</span>

                <div class="card-avatar mt-2 mb-2" style="background-position: center; height: 50px; background-size: 50px;
            background-repeat: no-repeat;">
                    <img [src]="appService.showImageTeacher2(item.image_url)" alt="Avatar">
                </div>
                <h4 class="card-title text-primary mb-1">{{item.name}}</h4>
                <div class="" style="white-space: nowrap;
            overflow: hidden !important; height: 20px;
            text-overflow: ellipsis; ">{{item.teacher_name}}</div>
                <div class="mb-2 mt-2 text-center row align-items-center justify-content-center">
                    <span class="font-18 font-bold mr-3">
                        <i class="fa fa-address-book-o mb-0 mr-1"></i>
                        <span>{{item.teacher_count}}</span>
                    </span>


                    <span style=" display: inline-block;">
                        <span class="font-18 font-bold  align-items-center"
                            [ngClass]="{'text-success' : item.teacher_attention > 0}" style="display: flex;
                flex-wrap: wrap;">
                            <span class="material-icons font-18">description</span>
                    <span class="">{{item.teacher_attention}}</span>
                    </span>
                    </span>
                    <span style=" display: inline-block;">
                        <span class="font-18 font-bold ml-3  align-items-center"
                            [ngClass]="{'text-danger' : item.teacher_inattention > 0}" style="display: flex;
                flex-wrap: wrap;">
                            <span class="material-icons font-18">group_off</span>
                    <span class="">{{item.teacher_inattention}}</span>
                    </span>
                    </span>
                </div>
                <div class="">
                    <div *ngIf="item.teacher_inattention > 0" class="progress my-danger mb-2">
                        <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                            {{ item.percent }}%
                        </div>
                    </div>
                    <div *ngIf="item.teacher_inattention == 0" class="progress my-primary mb-2">
                        <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                            {{ item.percent }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type_report == appService.type_report_detail_week || type_report == appService.type_report_detail_month">
    <div class="row mb-2">
        <!-- <div class="col-2 d-flex justify-content-end">






        </div> -->
        <div class="col-12 d-flex justify-content-between">
            <div>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-primary"> check_circle </span>
                    </span>
                    Đúng giờ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-warning"> report </span>
                    </span>
                    Muộn/trễ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-danger"> report </span>
                    </span>
                    Vắng
                </button>
            </div>

            <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon">
                <span class="btn-label"><img src="./assets/img/icons/excel.png" alt="image" width="15" /></span>
                Tải xuống
            </button>
        </div>
    </div>
    <div class="qbs_scroll" [style.width.px]="screenWidth - 240">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th class="headcol" style="text-align: center;">
                        Cán bộ giáo viên
                        <div style="font-size: small;">
                            Chi tiết điểm danh
                        </div>
                    </th>

                    <td class="long" *ngFor="let item of dateRange; let i = index">
                        <div class="text-center" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': (item.day == 0 || item.day == 6) }" style="min-width: 60px;">
                            <div style="font-size: small;">{{item.dayOfWeek}}</div>
                            <div>{{item.ddmm}}</div>
                        </div>
                    </td>
                </tr>
            </thead>
            <!-- <tbody> -->
            <tr *ngFor="let item of this.teachers; let i = index" [ngClass]="{'bg-primary-100': false }">
                <th class="headcol">
                    <div class="d-flex justify-content-between align-items-center">
                        <a class="d-flex justify-content-start align-items-center">
                            <span class="mr-2"> <span class="badge badge-default ">{{i+1}}</span></span>
                            <div>
                                <div>
                                    {{item.name}}
                                </div>
                                <div class="text-secondary" style="font-size: 11px;">
                                    {{item.code}}
                                    <span class="badge-secondary badge-point"></span>
                                    <!-- <span class="badge badge-secondary badge-pill" style="padding: 2px 8px;">{{item.cate_checkin_time_names}}</span> -->
                                    {{item.phone}}

                                </div>
                            </div>
                        </a>
                        <div>
                            {{item.datas_sum.attention}}/ {{item.datas_sum.late}}/ {{item.datas_sum.total}}
                        </div>
                    </div>
                </th>


                <td *ngFor="let item_datas of item.datas; let j = index" class="long">
                    <!-- tooltip -->
                    <div *ngIf="item_datas.checkin_times.length > 0" class="datas-tooltip">
                        <span *ngFor="let item3 of item_datas.checkin_times; let k = index" class="badge badge-primary badge-pill" style="font-size: unset;
                        margin: 3px;
                        font-weight: normal;">
                            {{item3}}
                        </span>
                    </div>
                    <!-- Danh sách khung giờ -->
                    <div class="d-flex justify-content-between w_t_btn">
                        <div *ngFor="let item_cate_checkin of item_datas.cate_checkins; let j = index">


                            <button *ngIf="item_cate_checkin.status != -1" class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2" (click)="popupTooltip(item_datas)">
                                <span class="btn-label">
                                    <span *ngIf="item_cate_checkin.status == 1" class="material-icons text-primary">
                                        check_circle </span>
                                    <span *ngIf="item_cate_checkin.status == 2" class="material-icons text-warning">
                                        report </span>
                                    <span *ngIf="item_cate_checkin.status == 3" class="material-icons text-danger">
                                        report </span>
                                    <!-- hiển thị số lượng giờ -->
                                    <span *ngIf="item_datas.checkin_times.length > 0" class="bag_num_checkin">
                                        {{item_datas.checkin_times.length}}
                                    </span>
                                </span>
                                {{item_cate_checkin.cate_checkin_time_name}}
                            </button>
                            <!-- <button *ngFor="let item_time_names of item.cate_checkin_time_names; let j = index" class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2" (click)="popupTooltip(item_datas)">
                            <span class="btn-label">
                                <span *ngIf="item_datas.checkin_times.length > 0" class="material-icons text-primary"> done </span>
                                <span *ngIf="item_datas.checkin_times.length == 0" class="material-icons text-danger"> report </span>
                                <span *ngIf="item_datas.checkin_times.length > 0" class="bag_num_checkin">
                                    {{item_datas.checkin_times.length}}
                                </span>
                            </span>
                            {{item_time_names}}
                        </button> -->
                        </div>
                    </div>
                </td>


            </tr>
            <!-- </tbody> -->
        </table>
    </div>
</div>
<div *ngIf="type_report == 'Báo cáo chi tiết tháng'">
    Báo cáo chi tiết tuần
</div>

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>


<div class="modal fade" id="popupTooltip-modal">
    <div class="modal-dialog modal-dialog-full" role="document">
        <form class="modal-content form-horizontal">
            <div class="modal-header p-4">
                <h5 class="modal-title">Chi tiết</h5>
            </div>
            <div class="modal-body p-4">
                <table>
                    <tr *ngFor="let item3 of item_datas.checkin_times; let k = index" class="row-items-checkin">
                        <td class="timeline-box-left">
                            {{item3}}
                            <span></span>
                        </td>
                        <td class="timeline-box-right">
                            <div class="content-item">
                                <img [src]="appService.showCheckinImage(item_datas.detected_image_urls[k])" style="max-height: 290px;
                                border-radius: 5px;" />
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </form>
    </div>
</div>