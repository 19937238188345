import { Component, OnInit } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, tap, map } from 'rxjs/operators';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FileViewerComponent } from 'src/app/components/file-viewer/file-viewer.component';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-teacher-document',
  templateUrl: './teacher-document.component.html',
  styleUrls: ['./teacher-document.component.css']
})
export class TeacherDocumentComponent implements OnInit {
  searching : boolean = false;
  isLoading : boolean = false;
  isLoading_upload : boolean = false;
  isLoading_delete : boolean = false;
  categorys = [];
  cateType = environment.cateType_teacher_document;
  filter = {
    type: this.cateType,
    name: "",
    code_parent: "",
    value_date: ""
  }
  fileUpload: File;
  myFileName = "";

  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  // for sorter
  sorter = {
    sortStr: this.appService.sort_category[3].code,
  }
  pagingObj = {
    viewNumsObj: this.viewNumsObj,
    pageObj: 1
  };
  constructor(
    private categoryeduService : CategoryEduService,
    public dialog: MatDialog,
    public utilityService: UtilityService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
  ) 
  {   
    this.appService.page_title = "Tài liệu của CBGV";
  }

  ngOnInit(): void {
    this.loadCategory();
  }
  changeListener($event): void {
    this.fileUpload = $event.target.files[0];
    this.myFileName = this.utilityService.getNameByFileName(this.fileUpload.name);
    console.log(this.fileUpload);
  }
  deleteFile(item){
    // popup confirm
    let tempdata = {
      title: 'Xác nhận xóa tài liệu  ?',
      msg: 'Xác nhận',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        var data = {
          type: this.cateType,
          id: item.id,
          file_url: item.file_url,
          deleted: 1,
        }
        this.isLoading_delete = true;
        this.ioService.deleteFile(data).subscribe(
          async response => {
            this.isLoading_delete = false;
            console.log(response);
            if (response.code == "ok") {
              this.loadCategory();
              this.toastr.success("", "Xóa tài liệu thành công");
            }else{
              this.toastr.warning(response.msg, "Xóa tài liệu không thành công");
            }
          },
          error => {
            this.isLoading_delete = false;
            console.error(error);
          });
      }
    });

  }
  uploadFile(){
    if(this.fileUpload == null || typeof (this.fileUpload) == "undefined"){
      this.toastr.warning("Vui lòng chọn file upload");
      return;
    }
    if(this.myFileName == ""){
      this.toastr.warning("Vui lòng nhập tên file");
      return;
    }
    console.log(this.fileUpload);
  
  
    const formData = new FormData();
    formData.append('uploadFile', this.fileUpload, this.fileUpload.name);
    formData.append('action', "UPLOAD_FILE_GOOGLE");
    formData.append('des', `${this.cateType}/${this.appService.currentUser.code}`);
    formData.append('name', this.myFileName);
    formData.append('type', this.cateType);
    formData.append('teacher_code', this.appService.currentUser.code);
    this.isLoading_upload = true;
    this.ioService.uploadFile(formData).subscribe(
      async response => {
        this.isLoading_upload = false;
        if(response.code == 'ok'){
          this.toastr.success("Upload thành công");
          this.myFileName = '';
          this.fileUpload = null;
          this.loadCategory();
        }
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading_upload = false;
        console.error(error);
      })
  }
  loadCategory() {
    
    let sorter = {
      sortStr: `edu_category.${this.sorter.sortStr}`,
    }
    this.pagingObj.pageObj = this.curentPage;

    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, sorter, this.pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
            this.categorys = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.categorys.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    this.pagingObj.viewNumsObj = this.viewNumsObj;
    this.pagingObj.pageObj = this.curentPage;
    console.log(this.pagingObj);
    this.loadCategory();
  }
  pageChange(page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      console.log(page);
      console.log(this.curentPage);
      this.loadCategory();
    }
  };

  openFileViewer(item){
    const dialogRef = this.dialog.open(FileViewerComponent, {
      data: {
        file_url: item.file_url
      }
    });
  }

  popup_category_add() {
    // $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    $("#import-category-modal").modal('show');
  }
//for search
formatter = (x: { name: string }) => x.name;
clearSearch() {
  this.filter.name = '';
  this.loadCategory();
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(400),
    tap(() => {
      this.searching = true;
      console.log("11111");
      // this.filter.name = this.searchStr;
      // console.log(this.filter.name);
      this.loadCategory();
    }),
    map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
    tap(() => { }),
  );
onClick(r) {
  this.filter.name = "";
  // this.popupUpdate(r);
}
}
