import { ProductService } from './../../../_services/product.service';
import { UserService } from './../../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../environments/environment';
import { UtilityService } from './../../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../_services/app.service';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherService } from 'src/app/_services/teacher.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TeacherPicker2Component } from 'src/app/components/teacher-picker2/teacher-picker2.component';
import { CheckinTimelineComponent } from 'src/app/components/checkin-timeline/checkin-timeline.component';
import { PopupEditScheduleDetailComponent } from '../popup-edit-schedule-detail/popup-edit-schedule-detail.component';
import { PopupCopyNextScheduleDetailComponent } from '../popup-copy-next-schedule-detail/popup-copy-next-schedule-detail.component';
import { ClassDocumentComponent } from '../../classs/class-document/class-document.component';
import { SchedulesDocumentComponent } from '../schedules-document/schedules-document.component';
declare var $: any;

@Component({
  selector: 'app-schedules-daily',
  templateUrl: './schedules-daily.component.html',
  styleUrls: ['./schedules-daily.component.css'],
})
export class SchedulesDailyComponent implements OnInit {
  isLoading = false;

  facultys: any; // danh sách tổ bộ môn
  teacher_list: any; // danh sách giáo viên

  type_report = 1;

  isLoading_note = false;
  //for data

  classs: any;
  filter = {
    code: '',
    khoi_code: '',
    class_code: '',
    subject_code: '',
    teacher_code: '',
    faculty_code: '',
  };
  dateRange = [];
  curentMonday: Date;

  dataListDetail = [];
  dataListTeacher = [];
  khoiSelected = -1;

  
  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;
  type_range = 'week'; // day week month

  //for search
  searching = false;
  code_filter = '';
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  };
  //
  cancel_reason = '';
  // For import
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  constructor(
    private teacherService: TeacherService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public schoolService: SchoolService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    public formatter: NgbDateParserFormatter,
    public dialog: MatDialog,
    config: NgbModalConfig
  ) {
    this.appService.page_title = 'Lịch ngày';
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(
      this.fromDate
    );

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadTeacher();
    this.loadClasss();
    this.loadFaculty();
    this.loadDatas();
    this.loadCurrenMonday(new Date());
  }
  selectReport(value: number){
    this.type_report = value;
  }
  editScheduleDetail(ktem, action){

    const dialogRef = this.dialog.open(PopupEditScheduleDetailComponent , {
      data: {data: ktem, action : action}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.code == 'ok') {
        this.loadTeachingAssignmentScheduleDetail();
      }
    });
  }
  prepareCopyToNext(dateSelected,item, num){
    let temp = [];
    this.dataListDetail.forEach(element => {
        if(item.checkin_date == element.checkin_date && element.id != undefined){
          temp.push(element);
        }
    });
    this.copyToNext(dateSelected,temp, num);
  }
  copyToNext(dateSelected, items, num_w){
    if(items.length == 0){
      this.toastr.warning("Không có dữ liệu để copy");
      return;
    }
    //num_w : -1 : tháng này; 0 : tháng sau; >=1 là tuần tiếp theo
    const dialogRef = this.dialog.open(PopupCopyNextScheduleDetailComponent , {
      data: {data: items, dateSelected : dateSelected, num_w : num_w}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.code == 'ok') {
        this.loadTeachingAssignmentScheduleDetail();
      }
    });
  }
  // copyScheduleDetail(ktem){

  //   const dialogRef = this.dialog.open(PopupEditScheduleDetailComponent , {
  //     data: {data: ktem, type : 'copy'}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result !== undefined && result.code == 'ok') {
  //       this.loadTeachingAssignmentScheduleDetail();
  //       this.toastr.success("Copy lịch dạy thành công");
  //     }
  //   });
  // }
  onValChange(item) {
    this.type_range = item;
    this.loadDatas();
    console.log(this.type_range);
  }
  category_change(event) {
    this.loadDatas();
  }
  loadFaculty() {
    let filter = {
      type: environment.cateType_faculty,
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      (error) => {
        // this.isLoading.dismiss();
        console.error(error);
      }
    );
  }
  loadTeacher() {
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1,
    };
    let filter = {};
    let sorter = {
      sortStr: 'name ASC',
    };
    this.teacherService.loadTeacher(filter, sorter, pagingObj).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.teacher_list = response.data;
          // $('#category').selectpicker();
        }
      },
      (error) => {
        // this.isLoading.dismiss();
        console.error(error);
      }
    );
  }
  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected,
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      (error) => {
        // this.isLoading.dismiss();
        console.error(error);
      }
    );
  }
  openSelectTeacher(item, index) {
    if(!this.appService.checkFullPermission()){
      this.toastr.warning('', 'Bạn không có quyền thay đổi giáo viên');
      return;
    }
    const dialogRef = this.dialog.open(TeacherPicker2Component, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: { classSelected: {} },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('close');

      if (typeof result != 'undefined' && result.code == 'ok') {
        console.log('result.data', result.data);
        // load lại dữ liệu
        // Cập nhật giáo viên
        let data = {
          id: item.id,
          teacher_code: result.data.code,
        };
        this.teacherService.updateScheduleDetailByID(data).subscribe(
          async (response) => {
            console.log(response);
            if (response.code == 'ok') {
              this.toastr.success('', 'Cập nhật giáo viên thành công');
              this.loadDatas();
            } else {
              this.toastr.warning('', 'Có lỗi xảy ra');
            }
          },
          (error) => {
            console.error('error:');
            console.error(error);
          }
        );
      }
    });
  }
  saveNoteOne(item) {
    if (item.checkin_review_id == null || item.checkin_review_id == '') {
      if (item.note == null || item.note.trim() == '') {
        this.toastr.warning('Thầy cô chưa nhập nhận xét');
        return;
      }
    }

    // Cập nhật giáo viên
    let data = {
      id: item.id,
      note: item.note,
    };
    this.isLoading_note = true;
    this.teacherService.updateScheduleDetailByID(data).subscribe(
      async (response) => {
        this.isLoading_note = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success('', 'Cập nhật giáo viên thành công');
          // this.loadTeachingAssignmentScheduleDetail();
        } else {
          this.toastr.warning('', 'Có lỗi xảy ra');
        }
      },
      (error) => {
        this.isLoading_note = false;
      }
    );
  }

  confirmDownloadExcel() {
    let data = {
      title: 'Tải lịch dạy',
      msg: 'Xác nhận tải.',
      ok: 'Tải xuống',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(this.type_range, this.selectedDateStr, this.filter.code);
        this.popup_export();
      }
    });
  }
  loadDatas() {
      if(this.type_report == 1){
        this.loadTeachingAssignmentScheduleDetail();
      }else if(this.type_report == 2){
      }
  }
  loadTeachingAssignmentScheduleDetail() {
    this.isLoading = true;
    // this.dataListDetail = [];
    this.teacherService
      .loadTeachingAssignmentScheduleDetail(
        this.type_range,
        this.selectedDateStr,
        this.filter
      )
      .subscribe(
        async (response) => {
          this.isLoading = false;
          if (response) {
            // // Tạo một Set để chứa các ngày duy nhất từ mảng A
            // let existingDates = new Set();

            // // Duyệt qua mảng A và thêm các ngày đã có vào Set
            // response.datas.forEach(item => {
            //   existingDates.add(item.checkin_day);
            // });

            // this.dataListDetail = [];

            // // Duyệt qua mảng B để sắp xếp mảng A theo thứ tự ngày trong B
            // response.dateRange.forEach(day => {
            //   this.dataListDetail.push({checkin_day: day.dayOfWeek, checkin_date: day.date});
            //   // Kiểm tra nếu ngày đã có trong A, thì thêm các phần tử tương ứng vào mảng kết quả
            //   let matchingItems = response.datas.filter(item => item.checkin_date === day.date);
              
            //   if (matchingItems.length > 0) {
            //     this.dataListDetail.push(...matchingItems);  // Thêm tất cả các mục khớp vào kết quả
            //   } else {
            //     // Nếu ngày chưa có trong A, thêm đối tượng mới với giá trị mặc định
            //   }
            // });
            this.dataListDetail = response.datas;
            console.log(this.dataListDetail);
          }
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        }
      );
  }
  
  popupDelete(item, index){
      // popup confirm
    let tempdata = {
      title: 'Xác nhận',
      msg: 'Xóa lịch dạy ?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {

        let data = {
          'id' : item.id,
          'teacher_code' : item.teacher_code,
        }
        this.teacherService.deleteScheduleDetailByIdAndTeacher(data).subscribe(
          async response => {
        
            console.log(response);
            if (response.code == 'ok') {
              this.toastr.success("Xóa lịch thành công");
              console.log(index); 
              // Xóa phần tử tại index 
              this.dataListDetail.splice(index, 1);
            }
          },
          error => {
            this.isLoading = false;
            // this.isLoading.dismiss();
            console.error(error);
          });
      }
    });
  }
  uploadDocument(item){
    // var data = {
    //   student_code_list : student_code_list,
    //   finance_cate_list : [this.selected_finance_cate],
    // }
    console.log(item);
    const dialogRef = this.dialog.open(SchedulesDocumentComponent, {
      disableClose: true,
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (typeof result != "undefined" && result.code == "ok") {
        item.document_count = result.value;
      }
    });
  }
  
  popup_export_excel() {}
  onDateSelect($event) {
    (this.selectedDateStr = this.utilityService.getFullDateStrFromDate(
      this.fromDate
    )),
      this.loadCurrenMonday(this.fromDate);
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadDatas();
    console.log(this.selectedDateStr);
  }

  loadCurrenMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if (curr.getDate() == first)
      first = first - 6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push({
        dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
        ddmm: this.utilityService.getDDMMStrFromDate(temp),
        ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
      });
    }
    console.log(this.dateRange);
  }

  showCheckinDetail(item){
    if(item.checkinList_times.length == 0) return;
    
    const dialogRef = this.dialog.open(CheckinTimelineComponent, {
      position: {
        // top: '0px',
        right: '0px'
      },
      // disableClose : true,
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {data: item.checkinList_times, checkin_date: item.checkin_date},
    });
  }

  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }

  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }
  popup_export() {
    // let filter = {};
    this.teacherService.downloadTeachingAssignmentDaily(
      this.type_range,
      this.selectedDateStr,
      this.filter.code
    );
  }
  openImport() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    $("#import-schedule-detail").modal('show');
  }
  uploadExcel() {
    // uploadFinanceAssignment
    if (this.file == null || typeof (this.file) == "undefined") {
      this.toastr.warning("Thầy cô chưa chọn file", "Vui lòng chọn file");
      return;
    }
    this.isLoading = true;
    this.teacherService.uploadScheduleDetail(this.file, {}).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.code == "ok") {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.import_result.fail = response.num_fail;
          this.loadTeachingAssignmentScheduleDetail();
        } else {
          this.import_result.result = false;
          this.import_result.msg = response.msg;
        }

      },
      error => {
        this.isLoading = false;
        this.import_result.result = false;
        this.import_result.msg = 'Đã có lỗi xảy ra khi đọc file Excel';
        console.error(error);
      })
  }
  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
}
