import { UtilityService } from './../../_services/utility.service';
import { AppService } from './../../_services/app.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {
  @Input() data: any;
  dateTimeStr : string
  constructor(public appService: AppService,public utilityService: UtilityService) { 
    this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
  }

  ngOnInit(): void {
  }

}
