<div class="page-header">
    <div class="ibox flex-1">
        <div class="mt-2 pt-2">
            <div class="flexbox">
                <div class="flexbox-b">
                    <div class="ml-5 mr-5">
                        <img style="    height: 100px;
                        border: 1px solid white;
                        border-radius: 10%;" [src]="appService.showImageTeacher2(appService.currentUser.image_url)" alt="Ảnh cá nhân">
                        <!-- <img class="img-circle" src="./assets/img/icons/person.svg" alt="image" width="110" /> -->
                    </div>
                    <div>
                        <h4>{{appService.currentUser.name}}</h4>
                        <div class="text-muted mb-1">
                            <span class="mr-3"><span class="material-icons"> email </span>{{appService.currentUser.email}}</span>
                            <span><span class="material-icons"> phone </span>{{appService.currentUser.phone}}</span>
                        </div>
                        <div>
                            <span class="mr-3">
                                <button  (click)="openProfileUploadImg()" class="btn btn-sm btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Cập nhật ảnh đại diện" >
                                    <span class="material-icons">
                                        photo_camera
                                        </span>
                            </button>{{appService.getValueByCode(appService.currentUser.positions,appService.positions)}}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-head">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="#tab-school-setting" data-toggle="tab" aria-expanded="false">Thông tin nhà trường</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" href="#tab-3-3" data-toggle="tab" aria-expanded="true">Thông tin cá
                        nhân</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-desk-price" data-toggle="tab" aria-expanded="false">Cấp học</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-bank" data-toggle="tab" aria-expanded="false">Ngân hàng</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-token" data-toggle="tab" aria-expanded="false">Hệ thống</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="page-content fade-in-up">
    <div class="row">
        <div class="ibox" style="width:100%">
            <!-- <div class="ibox-head">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" href="#tab-3-1" data-toggle="tab" aria-expanded="false">Active</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#tab-3-2" data-toggle="tab" aria-expanded="false">Second</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#tab-3-3" data-toggle="tab" aria-expanded="true">Third</a>
          </li>
        </ul>
      </div> -->
            <div class="">
                <div class="tab-content">


                    <div class="tab-pane fade text-center" id="tab-desk-price" aria-expanded="false">
                        <div class="row p-3 ml-5 mt-2">
                            Danh sách cấp học
                        </div>
                        <div class="row">
                            <div class="col">
                                <form class="form-horizontal" id="khoisForm" #khoisForm="ngForm" (ngSubmit)="updateKhois()">
                                    <div class="ibox-body">
                                        <div class="" *ngFor="let item of this.appService.currentUser.khois; let i = index">
                                            <div class="row">
                                                <div class="col">
                                                    <mat-form-field class="w-50" appearance="fill">
                                                        <mat-label>Tên</mat-label>
                                                        <input matInput [(ngModel)]="item.value" placeholder="Tên" (focusout)="focusOutKhoiName(i)" [name]="'name'+i" maxlength="200">
                                                    </mat-form-field>

                                                    <mat-form-field class="w-50" appearance="fill">
                                                        <mat-label>Mã</mat-label>
                                                        <input matInput [(ngModel)]="item.code" placeholder="Mã" disabled="true" [name]="'code'+i" maxlength="200">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col text-left">
                                                    <button *ngIf="i != 0" class="btn btn-outline-secondary btn-icon-only btn-circle " (click)="removeMoreKhoi(i)">
                                                        <span class="btn-label"><span class="material-icons"> clear </span></span>
                                                    </button>
                                                    <button *ngIf="i+1 == this.appService.currentUser.khois.length && this.appService.currentUser.khois.length < 9 " class="btn btn-outline-primary btn-icon-only btn-circle " (click)="addMoreKhoi(i+2)">
                                                        <span class="btn-label"><span class="material-icons"> add </span></span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row justify-content-center pt-3 pb-3">
                                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.khoisForm.invalid">
                                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                                            Cập nhật
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center" id="tab-school-setting" aria-expanded="false">
                        <div class="row p-3">
                            <div class="col">
                                <form class="form-horizontal" id="schoolProfileForm" #schoolProfileForm="ngForm" (ngSubmit)="updateSchoolProfile()">
                                    <div class="ibox-body">


                                        <mat-form-field class="example-full-width mb-4" appearance="fill">
                                            <mat-label>Mã Trường</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.school_code" placeholder="Mã trường" name="school_code" readonly>
                                            <mat-hint>(Mã trường chuẩn trong danh mục CSDL ngành)</mat-hint>
                                        </mat-form-field>


                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Tên trường</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.school_name" required maxlength="100" placeholder="Mã trường" name="school_name" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Hiệu trưởng</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.headmaster" placeholder="Tên Trường" name="headmaster" required maxlength="100">
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Địa chỉ</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.school_address" placeholder="Địa chỉ" name="school_address" maxlength="200">
                                        </mat-form-field>


                                    </div>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.schoolProfileForm.invalid">
                                            <span class="btn-label" *ngIf="isLoading"><span
                                                    class="spinner-border"></span></span>
                                            <span class="btn-label" *ngIf="!isLoading"><i
                                                    class="ti ti-check"></i></span>
                                            Cập nhật
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center" id="tab-bank" aria-expanded="false">
                        <div class="row p-3">
                            <div class="col">
                                <form class="form-horizontal" id="schoolBank" #schoolBank="ngForm" (ngSubmit)="updateBankProfile()">
                                    <div class="ibox-body">

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Số tài khoản</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.bank_account" placeholder="Số tài khoản" name="bank_account">
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Chủ tài khoản</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.bank_account_name" placeholder="Chủ tài khoản" name="bank_account_name">
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Ngân hàng</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.bank_name" placeholder="Ngân hàng" name="bank_name">
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>BIDV Merchant ID</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.bidv_merchantId" placeholder="BIDV Merchant ID" name="bidv_merchantId">
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>BIDV Service ID</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.bidv_service_id" placeholder="BIDV Servive ID" name="bidv_service_id">
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.schoolBank.invalid">
                                            <span class="btn-label" *ngIf="isLoading"><span
                                                    class="spinner-border"></span></span>
                                            <span class="btn-label" *ngIf="!isLoading"><i
                                                    class="ti ti-check"></i></span>
                                            Cập nhật
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade text-center" id="tab-token" aria-expanded="false">
                        <div class="row p-3">
                            <div class="col">
                                <form class="form-horizontal" id="schoolTokenForm" #schoolTokenForm="ngForm" (ngSubmit)="updateTokenProfile()">
                                    <div class="ibox-body">
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Place ID</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.hanet_placeID" placeholder="Camera Place ID" name="hanet_placeID">
                                        </mat-form-field>

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Camera Token</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.access_token" placeholder="Camera Token" name="access_token">
                                        </mat-form-field>

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Camera refresh Token</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.refresh_token" placeholder="Camera refresh token" name="refresh_token">
                                        </mat-form-field>

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Giờ CBGV cập nhật bán trú</mat-label>
                                            <input matInput type="time" [(ngModel)]="appService.currentUser.meal_time" placeholder="Giờ cập nhật bán trú" name="meal_time">
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="example-full-width mb-3">
                                            <mat-label>Loại Camera tích hợp</mat-label>
                                            <mat-select [disabled]="false" [(ngModel)]="appService.currentUser.type_camera" name="school_camera_type">
                                                <mat-option *ngFor="let item of appService.camera_type" [value]="item.code">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>



                                    </div>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.schoolTokenForm.invalid">
                                            <span class="btn-label" *ngIf="isLoading"><span
                                                    class="spinner-border"></span></span>
                                            <span class="btn-label" *ngIf="!isLoading"><i
                                                    class="ti ti-check"></i></span>
                                            Cập nhật
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center active show" id="tab-3-3" aria-expanded="true">
                        <div class="row p-3">
                            <!-- <div class="ibox-head">
                <div class="ibox-title">Thông tin cá nhân</div>
              </div> -->
                            <div class="col">
                                <form class="form-horizontal" id="updateUserForm" #userProfileForm="ngForm" (ngSubmit)="updateUserProfile()">
                                    <div class="ibox-body">


                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Họ và tên</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.name" placeholder="Tên cán bộ giáo viên" name="name" required maxlength="100">
                                        </mat-form-field>

                                        <mat-form-field class="example-full-width  mb-3" appearance="fill">
                                            <mat-label>Số điện thoại</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.phone" placeholder="Số điện thoại" name="phone" maxlength="10">
                                        </mat-form-field>

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Email/Tài khoản</mat-label>
                                            <input matInput [(ngModel)]="appService.currentUser.email" [disabled]="appService.currentUser.positions == 'admin'" placeholder="Email (dùng để đăng nhập)" name="email" required maxlength="100">
                                        </mat-form-field>


                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-left">Đổi mật khẩu</label>
                                            <div class="col-sm-8 d-flex align-items-center">
                                                <label class="ui-switch switch-icon">
                                                    <input type="checkbox" [(ngModel)]="isRessetPass" name="deleted">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Mật khẩu</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="password" [(ngModel)]="password" [disabled]="!isRessetPass" placeholder="Mật khẩu" name="pass">
                                            </div>
                                        </div> -->

                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Mật khẩu</mat-label>
                                            <input matInput [(ngModel)]="password" placeholder="Mật khẩu" name="pass" [type]="hide ? 'password' : 'text'" required [disabled]="!isRessetPass" maxlength="100">
                                            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </a>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width mb-3" appearance="fill">
                                            <mat-label>Nhập lại mật khẩu</mat-label>
                                            <input matInput [(ngModel)]="repassword" placeholder="Nhập lại mật khẩu" name="repasss" [type]="hide ? 'password' : 'text'" required [disabled]="!isRessetPass" maxlength="100">
                                            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </a>
                                        </mat-form-field>
                                        <!-- 
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Nhập lại mk</label>
                                            <div class="col-sm-8 ">
                                                <input class="form-control" type="password" [(ngModel)]="repassword" [disabled]="!isRessetPass" placeholder="Nhập lại mật khẩu" name="repasss">
                                            </div>
                                        </div> -->

                                    </div>
                                    <div class="row justify-content-center">

                                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.userProfileForm.invalid">
                                            <span class="btn-label" *ngIf="isLoading"><span
                                                    class="spinner-border"></span></span>
                                            <span class="btn-label" *ngIf="!isLoading"><i
                                                    class="ti ti-check"></i></span>
                                            Cập nhật
                                        </button>

                                        <!-- <button class="btn btn-primary mr-2">Cập nhật</button> -->
                                    </div>
                                </form>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>