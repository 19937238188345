<div class="m-3">
    <h4>Chọn lớp cho {{data.length}} môn đã chọn</h4>
    <div>
        <div *ngIf="isLoading" class="text-center">
            <span class="spinner-border spinner-lg"></span>

        </div>
        <div *ngIf="dataList.length == 0" class="text-center">
            <app-nodata msg="Chưa khai báo lớp học. Vào menu lớp học để thêm mới"></app-nodata>

        </div>
        <div *ngIf="dataList.length > 0" style="overflow: auto; min-width: 600px;
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Khối</th>
                        <th>Lớp</th>
                        <th class="text-center">Học sinh</th>

                        <th class="text-center">Chủ nhiệm</th>
                        <th class="text-center" style="width: 90px;">
                            <label class="checkbox myHeader checkbox-warning check-single">
                                    <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                                    <span class="input-span"></span>
                                </label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="img-circle mt-1" src="./assets/img/icons/file-and-folder.png" alt="image" width="30" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.khoi_name}}</div>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.name}}</div>
                        </td>
                        <td class="text-center">
                            {{item.student_count == null ? 0: item.student_count}}
                        </td>
                        <td>
                            <span *ngIf="item.chunhiem_code != ''">{{item.name_teacher}}</span>
                        </td>
                        <td class="text-center">
                            <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp">
                                <span class="input-span"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Lưu gán môn cho lớp
            </button>
            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
            </button>
        </div>
    </div>
</div>