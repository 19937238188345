<div class="p-1">
    <div class="page-heading">
        <h1 class="page-title">Chọn giáo viên cho : <span class="text"> {{classSelected.name}}</span></h1>
    </div>

    <button class="btn btn-sm btn-gradient-peach btn-icon-only btn-circle " style="position: absolute;
    right: 41px;
    top: 29px;" (click)="onCancelClick()">
        <i class="ti ti-close"></i>
    </button>
    <!-- <div class="">
        <div class="ml-2 select_category" (click)="select_category('')" [ngClass]="{'selected_category' : '' == this.filter.category_code}">
            <div>
                Tất cả
            </div>
        </div>
        <div class="select_category" *ngFor="let item of categories" (click)="select_category(item.code)" [ngClass]="{'selected_category' : item.code == this.filter.category_code}">
            <div>
                {{item.name}}
            </div>
        </div>
    </div> -->

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col-4">

            <div class=" p-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-templates" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm giáo viên" [resultTemplate]="rt" [inputFormatter]="formatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <div class="img-product-search" [style.background-image]="this.appService.showImageProduct(r['image_url'])">
                        </div>
                    </div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">{{ ' Kho:' + r['stock']}}</div>
                    <div class="col"> - {{ (r['price'] | number )}} Đ</div>
                </div>
            </ng-template>

        </div>

        <div class="col-8">
            <div class="d-flex justify-content-end">

                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.positions" (change)="category_change($event)" name="positions" id="positions">
                        <option value="">Chức vụ</option>
                        <option [ngValue]="item.code" *ngFor="let item of appService.positions">{{item.value}}</option>
                    </select>
                </div>
                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.faculty_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Tổ</option>
                        <option [ngValue]="item.code" *ngFor="let item of facultys">{{item.name}}</option>
                    </select>
                </div>


                <div class="p-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}
                        </option>
                    </select>
                </div>
                <div class="p-2">

                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-sm btn-icon" ngbDropdownToggle>
                            <span class="btn-label">
                                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
                            </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng
                                lưới</button>
                            <button ngbDropdownItem (click)="changeViewType(1)"><i class="ti ti-align-justify"></i> Dạng
                                danh
                                sách</button>
                        </div>
                    </div>

                    <!-- <div class="ibox-tools">
                <button class="btn btn-outline-primary btn-icon-only" data-toggle="dropdown"
                  style="height: 36px; width: 36px;">
                  <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                  <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" (click)="changeViewType(0)"> <i class="ti ti-layout-grid2"></i>Dạng lưới</a>
                  <a class="dropdown-item" (click)="changeViewType(1)"> <i class="ti ti-align-justify"></i>Dạng danh sách</a>
                </div>
              </div> -->
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="display_type ==1" class="table-responsive row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Giáo viên</th>
                    <th>Email/TK</th>
                    <th>Tổ bộ môn</th>

                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.teachers; let i = index" (click)="popupAddToCart(item)" class="h-row-item">
                    <td>
                        <!-- <a class="media-img" href="javascript:;">
                  <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
                </a> -->
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;"> {{i+1}} {{item.name}}

                        </div>
                        <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
                            {{item.code}}
                        </div>
                    </td>
                    <td>{{item.email}}</td>
                    <td>{{item.faculty_name}}</td>
                    <td></td>


                </tr>

            </tbody>
        </table>
    </div>
    <div *ngIf="display_type ==0">
        <div class="d-flex flex-wrap mb-2">
            <!-- <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of this.teachers; let i = index"
            (click)="popupAddToCart(item)"> -->
            <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of this.teachers; let i = index" (click)="popupAddToCart(item)">
                <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">
                    <span class=".card-img-overlay">
                        <span *ngIf="item.stock < 1" class="badge-blue badge-point"></span>
                    </span>
                </div>
                <div class="card-body">
                    <p class="text-center" style="max-height: 37px; overflow: hidden;">{{item.name}}</p>

                    <div class="text-center">
                        <div style="font-weight: bold;" [ngClass]="{'text-success': 0 < 1}">
                            {{appService.getValueByCode(item.positions,appService.positions)}}</div>
                        <div class="text-muted font-11">{{item.faculty_code}}</div>
                    </div>

                    <!-- <div class="d-flex align-items-center justify-content-between mt-2">
              <div class="text-center">
                <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</div>
                <div class="text-muted font-11">{{item.unit}}</div>
              </div>
              <div class="text-center">
                <div style="font-weight: bold;">{{item.price | number}}</div>
                <div class="text-muted font-11">VNĐ</div>
              </div>
            </div> -->
                </div>
            </div>
        </div>
    </div>



</div>

<div class="modal fade" id="confirm-gvcn-modal">
    <div class="modal-dialog" role="document">
        <div class="modal-content form-horizontal" id="form-add-product">
            <div class="modal-header p-4">
                <h5 class="modal-title">Xác nhận chọn giáo viên
                    <!-- <img style="width: 20px;" src="./assets/img/icons/person.svg"
              alt="image" /> -->
                </h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4 mb-3">
                <div style="text-align: center; padding: 25px 0px;">
                    <h2 style="font-weight: bold;">{{teacherSelected.name}}</h2>
                    <div> Giáo viên sẽ được gán cho {{classSelected.name}}</div>
                </div>
            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <!-- <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openCheckout(this.teacherSelected,this.txt_quantity.toString())">
            <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
          </button> -->
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="ganGiaoVienChuNhiem()">
                    <span class="btn-label"><i class="ti ti-check"></i></span>Xác nhận
                </button>

            </div>
        </div>
    </div>
</div>