<div class="m-5">
    <h4>Chọn lớp chuyển đến cho {{data}} học sinh</h4>
    <div>
        <div *ngIf="classList.length == 0" class="text-center">
            <span class="spinner-border spinner-lg"></span>
        </div>
        <div *ngIf="classList.length > 0">
            <div *ngFor="let item of classList; let i = index" class="item-card" (click)="indexSelected =i" [ngClass]="{'classSelected' : indexSelected == i}">
                <img class="img-circle" src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <div>{{item.code}}</div>
            </div>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Xác nhận Chuyển lớp
            </button>
            <button class="ml-2 blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
            </button>
        </div>
    </div>
</div>