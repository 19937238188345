<div class="page-heading">
    <h1 class="page-title">Sparkline Charts</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html"><i class="la la-home font-20"></i></a>
        </li>
        <li class="breadcrumb-item">Charts</li>
        <li class="breadcrumb-item">Sparkline Charts</li>
    </ol>
</div>
<div class="page-content fade-in-up">
    <div class="alert alert-info alert-bordered">
        <p>A plugin for the jQuery javascript library to generate small sparkline charts directly in the browser</p>
        <p>
            <a class="btn btn-info" href="http://omnipotent.net/jquery.sparkline/" target="_blank">Official Website</a>
        </p>
    </div>
    <div class="ibox">
        <div class="ibox-head">
            <div class="ibox-title">Sparkline Charts</div>
        </div>
        <div class="ibox-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Graph</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span class="spark_pie">2,1,2</span>
                        </td>
                        <td>Pie chart</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="spark_bar">10,8,9,3,5,8,5</span>
                        </td>
                        <td>Bar chart</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="spark_bar">-3,1,2,0,3,-1</span>
                        </td>
                        <td>Bar negative values</td>
                    </tr>
                    <tr>
                        <td>
                            <span id="spark_tristate">1,1,0,1,-1,-1,1,-1,0,0,1,1</span>
                        </td>
                        <td>Tristate chart</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="spark_line">34, 43, 43, 35, 44, 32, 15, 22, 46, 33, 86, 54, 73, 53, 12, 53, 23, 65, 23, 63, 53, 42, 34, 56, 76, 15, 54, 23, 44</span>
                        </td>
                        <td>Line chart</td>
                    </tr>
                    <tr>
                        <td>
                            <span id="spark_compositeline">8,4,0,3,0,3,1,4,4,10,4,10,0,1,4,6,5,9,10</span>
                        </td>
                        <td>Composite lines</td>
                    </tr>
                    <tr>
                        <td>
                            <span id="spark_compositebar">4,6,7,7,4,3,2,1,4</span>
                        </td>
                        <td>Composite bar</td>
                    </tr>
                    <tr>
                        <td>
                            <span id="spark_mousespeed">Loading...</span>
                        </td>
                        <td>Mouse speed</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
