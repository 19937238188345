import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { environment } from 'src/environments/environment';
import { ListViewComponent } from 'src/app/components/list-view/list-view.component';
declare var $: any;
@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css']
})
export class HolidayComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;

  categorys: any;
  categorys_backup: any;

  cateType = environment.cateType_holiday;
  // for add
  cate = new Category(this.cateType);
  // for update
  categoryselected: any;
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;
  temp_weeks_update = []; //clone
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: 'value_date ASC',
  }

  filter = {
    name: "",
    deleted: 0,
    type: this.cateType
  }
  // cho ngày nghỉ
  value_date_temp : Date | null;

  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Ngày nghỉ trong năm";
    this.categoryselected = this.cate;
    // this.countAllCategory();
    
  }

  ngOnInit() {
    this.loadCategory()
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {

  }
  clearSearch() {
    this.filter.name = '';
    this.loadCategory();
  }
  changeCheckbox(event) {
    console.log(event);
    console.log(this.appService.weeks);
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar;
  }
  category_change(event) {
    this.loadCategory();
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {

          response.data.forEach(element => {
            element.checkin_weeks_name =  this.utility.getWeekNameByWeekCode(element.checkin_weeks);
          });

          this.categorys = response.data;
          this.categorys_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

          console.log(this.categorys);
        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập ngày và ghi chú ");
      return false;
    }

    this.cate.name = this.cate.name.trim();
    this.cate.code = 'HLD' + Math.floor(Math.random()*1000);

    if (this.file !== null) {
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }
    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.appService.weeks.forEach(element => {
        if(element.checked){
          temp_weeks.push(element.code);
        }
    });
    this.cate.checkin_weeks = temp_weeks.join("w");

    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }

  countAllCategory() {
    this.categoryeduService.countAllCategory(this.cateType).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {

    this.resetCategory();
    this.countAllCategory();

    this.value_date_temp = new Date();
    this.cate.value_date = this.utility.getFullDateStrFromDate(this.value_date_temp);

    $("#add-category-modal").modal('show');

  }

  
  popupUpdate(item) {
    this.categoryselected = item;
    // ngày nghỉ
    this.value_date_temp = this.utility.getDateFromStr(this.categoryselected.value_date_str);

    this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;

    // Xử lý danh sách week
    this.temp_weeks_update = [];
    var temp_weeks = this.categoryselected.checkin_weeks.split("w");
    this.appService.weeks.forEach((element,i) => {
        this.temp_weeks_update.push({ 'code': element.code, 'value': element.value,'checked':false },);//clone

        temp_weeks.forEach(element_w => {
          if(element_w == element.code){
            this.temp_weeks_update[i].checked = true;//clone and false default
          }
       });
    });

    console.log(this.categoryselected);
    console.log(this.temp_weeks_update);

    $("#update-category-modal").modal('show');
  }

  updateCategory() {
    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.categoryselected_file !== null) {
      this.categoryselected.image_url = this.cateType + "/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }

    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    console.log(this.categoryselected);

    //xử lý danh sách ngày 
    this.categoryselected.value_date = this.utility.getFullDateStrFromDate(this.value_date_temp);

    this.isLoading = true;
    var data = {
      id: this.categoryselected.id,
      name: this.categoryselected.name,
      value_date: this.categoryselected.value_date,
      deleted: this.categoryselected.deleted,
    }
    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  openListView(dateSelected, item){
    if(item == null || item == ''){
      return
    }
    let datas = item.split(",")
    // mở popup
    const dialogRef = this.dialog.open(ListViewComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {title:"Lịch áp dụng ngày nghỉ: ", data: datas, dateSelected : dateSelected}  
    });
  }
  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if (this.file == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async changeImageUpdate($event): Promise<void> {
    this.categoryselected_file = $event.target.files[0];
    if (this.categoryselected_file == null) {
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
        this.categoryselected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.categoryselected_file);
        reader.onload = (_event) => {
          this.categoryselected_imageUrl = reader.result;
          console.log(this.categoryselected_imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }

 
  
  onDateSelect($event) {
    this.cate.value_date = this.utility.getFullDateStrFromDate(this.value_date_temp);
  }

  
}
