import { Ng2ImgMaxService } from 'ng2-img-max';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { StudentService } from 'src/app/_services/student.service';
import { Student } from 'src/app/model/student';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { ClassPickerComponent } from 'src/app/components/class-picker/class-picker.component';
import { ArrangeDataComponent } from 'src/app/components/arrange-data/arrange-data.component';
import { StudentAddComponent } from './student-add/student-add.component';
import { StudentUpdateComponent } from './student-update/student-update.component';
import { StudentRegisterImageCameraComponent } from './student-register-image-camera/student-register-image-camera.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
declare var $: any;
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {
  @ViewChild('studentAddForm') studentAddForm: HTMLFormElement;
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  isLoading = false;
  khoicosos = [];
  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

   //for checkbox
   checkboxs_changeClass = [];
   checkboxAllButton = false;

  // for action
  action_mode = "";
 

  //for data
  students = [];
  students_backup: any
  categorys: any
  facultys: any
  classs: any
  filter = {
    name: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    status: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  
 
  // for add
  student = new Student();
  fileteacherImg: File;
  imageUrl: any = environment.student_avatar;
  // for update
  studentSelected: any;
  isDeleted: boolean;
  studentSelected_imageUrl: any = environment.student_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public studentService: StudentService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public schoolService: SchoolService,
    public ioService: IoService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Danh sách học sinh";
    this.sorter.sortStr = this.appService.sort_students[0].code;
    this.studentSelected = this.student;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);
  }




  ngOnInit() {
    // this.loadStudent();
    // this.loadFaculty();
    this.loadClasss();
    this.loadKhoicoso();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadStudent();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  };
  loadKhoicoso() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let filter = {
        type : environment.cateType_khoicoso
    }
    let sorter = {
      sortStr: 'od'
    }
    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
            this.khoicosos = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  popup_mode(mode){
    if(this.filter.class_code == ""){
      this.toastr.warning("Thầy cô chọn lớp để thao tác");
      return;
    }
    
    this.action_mode = mode;
    if(this.action_mode == 'camera'){
      this.loadStudent();
    }else if(this.action_mode == 'changeClass'){
      this.loadStudent();
    }
  }
  popup_arrange(){
    if(this.filter.class_code == ""){
      this.toastr.warning("Thầy cô chọn lớp để thao tác");
      return;
    }
    const dialogRef = this.dialog.open(ArrangeDataComponent, {
      data: this.students
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {          
        this.loadStudent();
      }
    });
  }
  popupRegisterCameraImage(item){

    const dialogRef = this.dialog.open(StudentRegisterImageCameraComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {          
        this.loadStudent();
      }
    });
  }
  changeClass(class_code, students){
    
    let data = {
      class_code : class_code,
      students : students
    }
    this.studentService.changeClass(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success("", "Chuyển lớp thành công");
          this.loadStudent();
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })
  }


  resetteacher() {

    this.student = new Student();
    this.imageUrl = environment.student_avatar;
    this.fileteacherImg = null;
    // this.student.category_code = "DM";
    // this.student.category_code_brand = "BRAND";

    console.log(this.student);
  }
  loadDeleted(){
    if(!this.appService.checkFullPermission()){
      return;
    }
    this.filter.deleted = 1;
    this.loadStudent();
    this.filter.deleted = 0;
  }
  loadStudent() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // trim name
    this.filter.name = this.filter.name.trim();
    // kiểm tra quyền khi load class
    if(this.filter.class_code == ''){
      if(!this.appService.checkFullPermission()){// nếu không có quyền thì không được load
        return;
      }
    }

    if (true) {
      this.checkboxs_changeClass = [];
      this.checkboxAllButton = false;
      this.studentService.loadStudent(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {

            response.data.forEach(element => {
              // Camera
              if (element.checkin_type_status == 0) { // điểm danh thủ công
                element.checkin_status_temp = false
              } else if (element.checkin_type_status > 0) {// điểm danh camera AI
                element.checkin_status_temp = true
              } else {
                element.checkin_status_temp = false;
              }
              // change class
              element.changeClass_temp = false;

              //meal
              if (element.meal == 1) {// đăng ký meal
                element.meal_temp = true
              } else {
                element.meal_temp = false;
              }
              // xử lý Parents
              if(element.parents == null || element.parents == ""){
                element.parents = [];
              }else{
                // let temp = element.parents.split(",");
                element.parents = element.parents.split(', ').map(entry => {
                  const loggedIn = entry.includes('^');
                  const [role, namePhone] = entry.replace('^', '').split(': ');
                  const name = namePhone.split(' [')[0];
                  const phone = namePhone.match(/\[(.*?)\]/)[1];
                  return { "role": role.trim(), "name": name.trim(), "phone": phone.trim(), "loggedIn": loggedIn };
              });

              }
            });
            console.log(this.students);
            // // đăng ký điểm danh camera
            // if(this.action_mode == "camera"){
              
            // }

            this.students = response.data;
            this.students_backup = response.data;
            this.checkboxs_changeClass = new Array(this.students.length);
            // this.appService.students = response.data;
            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.students.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
            
            
          }
        },
        error => {

        })
    }
  }
  checkboxAll(evt) {
    // for (let index = 0; index < this.checkboxs_changeClass.length; index++) {
    //   this.checkboxs_changeClass[index] = evt.target.checked;
    // }
    
    this.students.forEach(element => {
      if(this.action_mode =='changeClass')
      element.changeClass_temp = evt.target.checked;
      if(this.action_mode =='meal')
      element.meal_temp = evt.target.checked;
      if(this.action_mode =='camera')
      element.checkin_status_temp = evt.target.checked;
    });

  }
  registerMeal(){
    let data = [];
    this.students.forEach(element => {
      data.push({
        id:element.id, 
        meal : element.meal_temp, 
      });
    });

  
    console.log(data);
    this.studentService.saveRegisterMeal(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Dăng ký ăn bán trú thành công");
          this.loadStudent();
          this.action_mode = "";
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })
  }
  processChangeClass(){
    let data = [];
    this.students.forEach(element => {
      if(element.changeClass_temp){
        data.push({
          code : element.code
        });
      }
    });
    console.log(data);
    if(data.length == 0){
      this.toastr.warning("", "Thầy cô chưa chọn Học sinh để chuyển lớp");
      return;
    }
    // mở popup
    const dialogRef = this.dialog.open(ClassPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: data.length 
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("class_code", result);
        console.log("students", data);
        this.changeClass(result.class_code, data)
      }
    });
  }
  changeDateMeal(event){
    console.log(this.utility.getFullDateStrFromDate(event.value));
    
    let data = [];
    this.students.forEach(element => {
      if(element.meal_temp){
        data.push({
          id:element.id, 
          meal : element.meal_temp, 
          meal_start : this.utility.getFullDateStrFromDate(event.value), 
        });
      }
    });

    // confirm 
    // popup confirm
    let tempdata = {
      title: `Xác nhận ngày bắt đầu bán trú cho [${data.length}] Học sinh đã chọn ?`,
      msg: 'Ngày bắt đầu ' + this.utility.getFullDateStrFromDate(event.value) + ''
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        this.studentService.updateMealStart(data).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok") {
              this.toastr.success("", "Dăng ký ăn bán trú thành công");
              this.loadStudent();
              this.action_mode = "";
            }
          },
          error => {
            console.error("error:");
            console.error(error);
          })
      }
    });
 

    

  }
 

  saveRegisterCamera(){
    let data = [];
    this.students.forEach(element => {
      if(element.checkin_type_status == 0 ){ // trạng thái gốc không đăng ký
        if(element.checkin_status_temp){ // nếu có đăng ký
          data.push({
            id:element.id, 
            checkin_type_status : 1, 
          });
        }
      }else{// trạng thái gốc đã đăng ký
        if(!element.checkin_status_temp){ // nếu không đăng ký
          data.push({
            id:element.id, 
            checkin_type_status : 0, 
          });
        }else{
          data.push({
            id:element.id, 
            checkin_type_status : element.checkin_type_status, // giữ nguyên trạng thái
          });
        }
      }
    });
    console.log(data);
    this.studentService.saveRegisterCamera(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Lưu đăng ký điểm danh Camera thành công");
          this.loadStudent();
          this.action_mode = "";
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })

  }

  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

  popup_teacher_add() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    // mở popup
    const dialogRef = this.dialog.open(StudentAddComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {
        khoi_code : this.filter.khoi_code
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
        this.toastr.success("", "Thêm mới thành công");
      }
    });
  }

  popupUpdate(item, tab) {
        // mở popup
        const dialogRef = this.dialog.open(StudentUpdateComponent, {
          position: {
            // top: '0px',
            // left: '0px'
          },
          width: '600px',
          // height: '90%',
          panelClass: 'full-screen-modal',
          data: {item, tab}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (typeof result != "undefined" && result.code == "ok") {
            this.toastr.success("", "Cập nhật thành công");
            this.loadStudent();
          }
        });
  }

  popup_student_import() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    $("#import-student-modal").modal('show');
  }

  category_khoi_change(event) {
    this.loadClasss();
    this.loadStudent();
  }
  category_change(event) {
    console.log(this.filter);
    this.loadStudent();
  }
  sort_change(event) {
    this.loadStudent();
  }
  

  // uploadAvatar(fileUpload: File, name: string) {
  //   console.log(fileUpload);
  //   if (fileUpload == null || typeof(fileUpload) == "undefined") {
  //     return;
  //   }
  //   let param = {
  //     des: environment.student,
  //     name: name
  //   }
  //   this.ioService.uploadImage(fileUpload, param).subscribe(
  //     async response => {
  //       this.isLoading = false;
  //       console.log('upload');
  //       console.log(response);
  //     },
  //     error => {
  //       this.isLoading = false;
  //       console.error(error);
  //     })
  // }

  // closePopup(){
  //   console.log("closePopup");
  //   $("#add-student-modal").modal('hide');
  // }
  

  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  uploadStudentExcel() {
    

    if (this.file == null || typeof(this.file) == "undefined") {
      this.toastr.warning("", "Vui lòng chọn file");
      return;
    } 
    
    this.isLoading = true;
    console.log(this.file);
    this.studentService.importStudent(this.file).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.value > 0) {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadStudent();
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  popup_student_export() {
    // let filter = {};
    console.log(this.filter);
    this.studentService.exportStudent(this.filter);
  }
  clearSearch(){
    this.filter.name='';
    this.loadStudent();
  }
  // teacherCodeTypeClick(type) {
  //   this.teacherCodeType = type;
  //   if (this.teacherCodeType == "mts") {
  //     this.student.code = environment.STUDENT_CODE_PREFIX + Date.now() ;
  //   } else if (this.teacherCodeType == "tn" || this.teacherCodeType == "qmv") {
  //     this.student.code = "";
  //   }
  // }
  // focusOutCheckCode() {

  //   let filter = {
  //     code: this.student.code,
  //   }
  //   console.log(filter);
  //   this.studentService.checkCode(filter).subscribe(
  //     response => {
  //       if (response) {
  //         if(response.code == 0){
  //           this.studentAddForm.controls.code.setErrors("Đã tồn tại code");
  //           this.toastr.error("Đã tồn tại code","Lỗi");
  //         }
  //       }
  //       console.log(response);
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  // async avatarChangeListener($event): Promise<void> {
  //   this.fileteacherImg = $event.target.files[0];
  //   if (this.fileteacherImg == null) {
  //     return;
  //   }
  //   console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
  //   console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
  //   try {
  //     this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
  //       this.fileteacherImg = new File([result], result.name);
  //       // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

  //       var reader = new FileReader();
  //       reader.readAsDataURL(this.fileteacherImg);
  //       reader.onload = (_event) => {
  //         this.imageUrl = reader.result;
  //       }
  //     },
  //       error => {
  //         console.log('error', error);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // async avatarChangeUpdateListener($event): Promise<void> {
  //   this.fileteacherImg = $event.target.files[0];
  //   if (this.fileteacherImg == null) {
  //     return;
  //   }
  //   console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
  //   console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
  //   try {
  //     this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
  //       this.fileteacherImg = new File([result], result.name);
  //       // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

  //       var reader = new FileReader();
  //       reader.readAsDataURL(this.fileteacherImg);
  //       reader.onload = (_event) => {
  //         this.studentSelected_imageUrl = reader.result;
  //       }
  //     },
  //       error => {
  //         console.log('error', error);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.student_avatar + ')';
  // }
  //for search
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.students = this.students_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.students = this.students.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }


  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      console.log(event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
        );
        console.log(event.previousIndex, event.currentIndex);
        console.log(event.previousIndex, event.currentIndex);
    }
  }
  /** Predicate function that only allows even numbers to be dropped into a list. */
  evenPredicate(item: CdkDrag<number>) {
    return item.data % 2 === 0;
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }



  
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadStudent();
      }),
      map(term => term === '' ? [] : this.students.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupUpdate(r,"");
    }
}



