<div>
    <table class="table table-head-purple table-hover mb-0" id="datatable" style="height: 42px;">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 50px; text-align: center;">
                    <button (click)="this.dialogRef.close();" class="btn btn-sm btn-warning btn-icon-only btn-circle btn-thick"><span class="material-icons "> close </span>
                            </button>
                </th>
                <th>Họ và tên</th>

                <th>Phản hồi của PH</th>

            </tr>
        </thead>

    </table>
    <div style="overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 42px);" class="qbs-scroll">
        <table class="table table-head-purple table-hover" id="datatable">

            <tbody>
                <tr *ngFor="let item of this.students; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem 0.2rem 0.5rem 1rem; width: 50px;">
                        <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                        <a class="avatar-img-list" style="width: 34px;  height: 34px; display: inline-block;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                    </td>
                    <td style="width: 180px; border-right: 1px solid #0996ca24;">
                        <div>
                            {{item.full_name}}
                        </div>
                        <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                            <span *ngIf="item.gender == 1" class="material-icons text-blue" style="font-size: 14px;">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink" style="font-size: 14px;">girl</span> {{item.code}}
                        </div>
                    </td>
                    <td>
                        {{item.period_ykien_ph}}
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</div>