import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HolidayPickerComponent } from '../holiday-picker/holiday-picker.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit {
  dataList: any = [];
  constructor(
    public dialogRef: MatDialogRef<HolidayPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.dataList = data.data;
  }

  ngOnInit(): void {
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
