import { environment } from './../../../environments/environment';
import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeacherService } from 'src/app/_services/teacher.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
declare var $: any;

@Component({
  selector: 'app-teacher-picker',
  templateUrl: './teacher-picker.component.html',
  styleUrls: ['./teacher-picker.component.css']
})
export class TeacherPickerComponent implements OnInit {

  loading = false;
  display_type = 0;
  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  // searchStr: string;
  searchFailed = false;

  //for data
  classSelected: { code: "" , name: "" };
  teachers = [];
  teachers_backup: any;
  categorys: any;
  facultys: any;
  categorys_brand: any;
  filter = {
    name: "",
    positions: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }


  //for paging
  viewNumsObj = this.appService.viewNums[5].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;



  // products = [];
  products_backup: any
  categories: any
  sorter = {
    sortStr: "",
  }

  // for add product
  product = new Product("");
  // for update product
  teacherSelected: any;
  isDeleted: boolean;

  //for cart
  productCart = {
    name: "",
    code: "",
    price: 0,
    price_in: 0,
    description: "",
  };
  txt_quantity = 1;


  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    private toastr: ToastrService,
    public productService: ProductService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<TeacherPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.teacherSelected = this.product;
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    // if(this.appService.products.length == 0){
    this.loadTeacher();
    // this.loadCategoryBrand();
    // this.loadCategory();

    this.sharedService.getClickEvent().subscribe((type) => {
      console.log("Sale component:", type);
      this.loadTeacher();
    })

  }



  ngOnInit(): void {
    this.classSelected = this.data.classSelected;
    this.loadFaculty();
    console.log(this.classSelected);
  }

  loadTeacher() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.teacherService.loadTeacher(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.teachers = response.data;
            console.log(this.teachers);
            // this.teachers_backup = response.data;
            // this.appService.teachers = response.data;
            // this.appService.teachers_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.teachers.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onOkClick(): void {

    let temp = {}



    let result = {
      code: "ok",
      data: temp
    }
    this.dialogRef.close(result);
  }

  ngAfterViewInit() {
    // dropdown danh mục

  }



  resetProduct() {
    let temp_unit = "";
    if (this.appService.units.length > 0)
      temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);

  }
  // loadTeacher() {
  //   let pagingObj = {
  //     viewNumsObj: this.viewNumsObj,
  //     pageObj: this.curentPage
  //   };
  //   // this.loadTotalPage();
  //   if (true) {
  //     this.productService.loadTeacher(this.filter, this.sorter, pagingObj).subscribe(
  //       async response => {
  //         console.log(response);
  //         if (response) {
  //           this.appService.products = response.data;
  //           this.appService.products_backup = response.data;
  //           this.totalItems = response.totalItems;
  //           this.totalItemOnPage = this.appService.products.length;
  //           //for select page
  //           let pages_temp = response.totalPage;
  //           this.listPage = [];
  //           for (let i = 1; i <= pages_temp; i++) {
  //             this.listPage.push({ i: i });
  //           }
  //           this.searching = false;

  //         }
  //       },
  //       error => {

  //       })
  //   }
  // }
  loadTotalPage() {
    // request to server
    this.productService.loadItemSum(this.filter)
      .subscribe(
        response => {
          console.log('ItemListComponent load Item');
          console.log(response);
          this.totalItems = response[0].NumberOfItems;
          let pages_temp = Math.ceil(this.totalItems / this.appService.viewNumsObj);
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          console.log(pages_temp);
          console.log(this.listPage);
        },
        error => {
          console.error(error);
        });
  };

  async loadCategory() {

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }


  category_change(event) {
    this.loadTeacher();
  }
  select_category(code) {
    console.log(code);
    this.filter.category_code = code;
    this.loadTeacher();
  }
  sort_change(event) {
    this.loadTeacher();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  // insertProduct() {

  //   if (this.product.name.trim() == "") {
  //     this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
  //     return;
  //   } else if (this.product.category_code.trim() == "") {
  //     this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
  //     return;
  //   }

  //   this.loading = true;
  //   this.productService.insert(this.product).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Thêm mới hàng hóa thành công");
  //         $("#add-product-modal").modal('hide');
  //         this.resetProduct();
  //         this.loadTeacher();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })


  // }
  // updateProduct() {

  //   this.teacherSelected.deleted = this.isDeleted ? 1 : 0;
  //   this.loading = true;
  //   this.productService.update(this.teacherSelected).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Cập nhật hàng hóa thành công");
  //         $("#update-product-modal").modal('hide');
  //         this.loadTeacher();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })

  // }
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    if (item) {
      this.txt_quantity = 1;
      this.teacherSelected = item;

      if (!$('#confirm-gvcn-modal').hasClass('show')) {
        $("#confirm-gvcn-modal").modal('show');
      }
    }
  }

  isLoading_ggvcn = false;
  ganGiaoVienChuNhiem() {

    let temp = {
      code: this.classSelected.code,
      chunhiem_code: this.teacherSelected.code,
    }
    this.isLoading_ggvcn = true;
    this.categoryeduService.ganGiaoVienChuNhiem(temp).subscribe(
      async response => {
        this.isLoading_ggvcn = false;
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Gán giáo viên chủ nhiệm thành công");
          $("#confirm-gvcn-modal").modal('hide'); // tắt popup xác nhận
          // tắt popup chọn giáo viên
          let result = {
            code: "ok",
            data: temp
          }
          this.dialogRef.close(result);

        }else{
          this.toastr.success("", "Đã có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading_ggvcn = false;
        console.error(error);
      })

  }
  // addToCart(product) {
  //   console.log(this.appService.listCart);
  //   console.log(this.appService.cartIndex);
  //   console.log("--------------------------------------------");
  //   let cart = new Cart();
  //   cart.product = product;
  //   // cart.quantity = this.txt_quantity;
  //   cart.quantity = parseInt(this.txt_quantity.toString());
  //   //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
  //   if (this.appService.listCart[this.appService.cartIndex].cart.length > 0) {
  //     if (this.utilityService.checkToAddCart(this.appService.listCart[this.appService.cartIndex].cart, cart)) {
  //       // nếu có thì thêm luôn ở trong hàm check
  //     }
  //     else {
  //       this.appService.listCart[this.appService.cartIndex].cart.push(cart);
  //     }
  //   } else {
  //     this.appService.listCart[this.appService.cartIndex].cart.push(cart);
  //   }

  //   this.appService.listCart[this.appService.cartIndex].total_quantity = this.appService.listCart[this.appService.cartIndex].total_quantity + cart.quantity;
  //   this.appService.listCart[this.appService.cartIndex].price_totol = this.appService.listCart[this.appService.cartIndex].price_totol + (cart.quantity * product.price);
  //   this.appService.listCart[this.appService.cartIndex].customer_pay = this.appService.listCart[this.appService.cartIndex].price_totol;
  //   // this.storage.set('cartList',this.cartList);
  //   localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
  //   product.temp_quantity = 1; // reset to default
  //   $("#confirm-gvcn-modal").modal('hide');
  // }
  addToCartDesk(teacher, quantity) {

    console.log("teacher:", teacher);
    console.log("quantity:", quantity);
  }

  openCheckout(product, quantity) {
    this.addToCartDesk(product, quantity);
    this.appService.openCheckout();
  }
  openCheckout2(product, quantity) {
    this.addToCartDesk(product, quantity);
    this.appService.openCheckout();
    // product.temp_quantity = 1;
  }

  popupPayment(item) {
    console.log("xacd");
  }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }


  //for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadTeacher();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadTeacher();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadTeacher();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadTeacher();
    }
  };






  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // this.filter.code = this.filter.name;
        console.log(this.filter.name);
        this.loadTeacher();
      }),
      map(term => term === '' ? []
        : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )

  onClick(r) {
    console.log(r);
    this.filter.name = "";
    console.log("this.filter.name", this.filter.name);
    this.popupAddToCart(r);
  }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  statesWithFlags: { name: string, flag: string }[] = [
    { 'name': 'Alabama', 'flag': '5/5c/Flag_of_Alabama.svg/45px-Flag_of_Alabama.svg.png' },
    { 'name': 'Alaska', 'flag': 'e/e6/Flag_of_Alaska.svg/43px-Flag_of_Alaska.svg.png' },
  ];

  loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  mouseover(item) {
    console.log("mouseover", item)
  }
  mouseout(item) {
    console.log("mouseout", item)
  }
}

