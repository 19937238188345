<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start align-items-center ">



                <mat-form-field appearance="fill" style="max-width: 350px;" class="mr-4 select-highline">
                    <mat-label>Khoản thu</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="selected_finance_cate" (selectionChange)="change_finance_cate($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of categorys" [value]="item.id">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>{{item.id}} - {{item.name}} </div>
                                <div> {{ item.sum_finance_cate_value| qbscurrency}} </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="example-full-width" appearance="fill" style="width: 350px;">
                    <mat-label>Tìm kiếm mã thanh toán</mat-label>
                    <input matInput [(ngModel)]="filter.name" placeholder="Nhập mã TT hoặc Mã HS hoặc Tên HS" name="search" maxlength="200">
                    <button matSuffix class="btn btn-sm btn-primary btn-icon-only btn-circle btn-thick" (click)="search()" style="margin-top: -16px;">
                        <!-- <mat-icon>search</mat-icon> -->
                        <!-- <span class="btn-label"><span class="spinner-border"></span></span> -->
                        <span class="btn-label">
                            <span *ngIf="!isLoadingSearch" class="material-icons"> search </span>
                            <span *ngIf="isLoadingSearch" class="spinner-border">  </span>
                        </span>
                      </button>
                </mat-form-field>


                <!-- <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Khối</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.currentUser.khois" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field appearance="fill" style="width: 125px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                        <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFinancePermission()">
                            {{item.name}} - ({{item.student_count}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Cấp học</mat-label>
                    <mat-select [disabled]="!appService.checkFullPermission()" [(ngModel)]="kt_filter.code_parent" (selectionChange)="change_caphoc($event)">
                        <mat-option [disabled]="!appService.checkFullPermission()" [value]="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of this.appService.currentUser.caps" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
        </div>
        <div class="col-4 p-0 d-flex justify-content-end  align-items-center ">

            <div class="">
                <div ngbDropdown class="d-inline-block">

                    <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                        <span class="btn-label"><img class="" src="./assets/img/icons/excel.png" alt="image" width="20" /></span>
                        Tải file Excel
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="confirmDownloadExcel('')" [disabled]="!appService.checkFullPermission()" class="text-primary">
                            <span class="material-icons mr-2"> file_download </span>
                            Tải báo cáo toàn trường
                        </button>
                        <button ngbDropdownItem *ngFor="let item of this.appService.currentUser.caps; let i = index" [disabled]="!(appService.checkFullPermission() || (item.code == appService.currentUser.name_alias))" (click)="confirmDownloadExcel(item.code)" class="text-primary">
                            <span class="material-icons mr-2"> file_download </span>
                            Tải báo cáo {{item.name}}
                        </button>
                    </div>
                </div>

            </div>
        </div>

    </div>


    <div class="row mb-3">
        <div class="col p-0 d-flex">

            <div class="d-flex box-school" style="background-color: #227d3ae3; color: white;">
                <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.count_finance_cate | qbscurrency}}</span></div>
                <div class="ml-3">
                    <div class="name">Tổng lỹ kế</div>
                    <div class="value">{{allSchools.sum_finance_value | qbscurrency}}</div>
                </div>
            </div>
            <div class="d-flex box-school" style="background-color: #2CC4CB; color: white;">
                <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.count_finance_cate_done | qbscurrency}}</span></div>
                <div class="ml-3">
                    <div class="name">Đã thu</div>
                    <div class="value">{{allSchools.sum_finance_value_done | qbscurrency}}</div>
                </div>
            </div>
            <div class="d-flex box-school" style="    background-color: #ff9800; color: black;">
                <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.count_finance_cate_doing | qbscurrency}}</span></div>
                <div class="ml-3">
                    <div class="name">Đang thu</div>
                    <div class="value">{{allSchools.sum_finance_value_doing | qbscurrency}}</div>
                </div>
            </div>
        </div>
        <div class=" d-flex justify-content-end align-items-end">
            <!-- <button (click)="printMulti()" placement="top" ngbTooltip="Chọn HS và khoản thu để In" class="btn btn-secondary btn-labeled btn-labeled-left btn-icon" style="margin: 0px 10px; ">
                <span class="btn-label"><span class="material-icons"> print </span></span>
                In phiếu thu
            </button> -->

            <div class="btn-group">
                <div class="btn" [ngClass]="{'btn-primary': '' == filter.status_text, 'btn-outline-primary': '' != filter.status_text}" (click)="filter_change('')">Tất cả</div>
                <div class="btn" [ngClass]="{'btn-primary': 'done' == filter.status_text, 'btn-outline-primary': 'done' != filter.status_text}" (click)="filter_change('done')"><span class="material-icons font-15 mr-2">
                    done_outline
                    </span>Đã thu</div>
                <div class="btn" [ngClass]="{'btn-primary': 'doing' == filter.status_text, 'btn-outline-primary': 'doing' != filter.status_text}" (click)="filter_change('doing')">
                    <span class="material-icons font-15 mr-2">access_time</span>Đang thu</div>
            </div>

        </div>
    </div>


    <div class="row">
        <!--START Chỉ để lấy kích thước chiều cao -->
        <div style="width: 56px; visibility: hidden;">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr style="height: 44px;">
                        <th style="width: 55px;">T</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.classList; let i = index" class="row-class">
                        <td class="text-right">
                            <div class="btn-group" placement="top" ngbTooltip="Chi tiết khoản đang thu">
                                <button class="btn btn-warning">
                                    <span class="btn-icon">T</span>
                                </button>
                                <span class="qbs-money-right btn-label-out btn-label-out-right btn-label-out-warning pointing" style="color: black;">T</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--END Chỉ để lấy kích thước chiều cao -->
        <div class="side-left" style="width: 690px; border-right: 1px dashed gray; padding-left: 0px;">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr style="height: 44px;">
                        <th style="width: 50px; text-align: center;">
                            <div class="my-stt"></div>
                        </th>
                        <th>Chủ nhiệm</th>
                        <th>HS</th>
                        <th class="text-right">Đã thu</th>
                        <th class="text-right">Đang thu</th>
                        <th style="width: 55px;">Lớp</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.classList; let i = index" class="row-class" (click)="selectClass(item)" [ngClass]="{'class-selected':  filter.class_code == item.code}">
                        <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                            <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>

                            <a class="avatar-img-list" style="width: 32px;  height: 32px;" href="javascript:;" [style.background-image]="appService.showImage(item.image_url, category_avatar)"></a>
                        </td>

                        <td>
                            {{item.teacher_name}}
                        </td>
                        <td class="text-center">
                            {{item.student_count}}
                        </td>

                        <!-- <td>
                            <div class="btn-group" placement="top" ngbTooltip="Chi tiết khoản thu">
                                <button class="btn ">
                                    <span class="btn-icon">{{item.count_finance_cate | qbscurrency}}</span>
                                </button>
                                <span class="btn-label-out btn-label-out-right  pointing">{{item.sum_finance_value | qbscurrency}}</span>
                            </div>
                        </td> -->
                        <td class="text-right">
                            <div class="btn-group" placement="top" ngbTooltip="Chi tiết khoản đã thu">
                                <button class="btn btn-info">
                                    <span class="btn-icon">{{item.count_finance_cate_done | qbscurrency}}</span>
                                </button>
                                <span class="qbs-money-right btn-label-out btn-label-out-right btn-label-out-info pointing">{{item.sum_finance_value_done
                                    | qbscurrency}}</span>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="btn-group" placement="top" ngbTooltip="Chi tiết khoản đang thu">
                                <button class="btn btn-warning">
                                    <span class="btn-icon">{{item.count_finance_cate_doing | qbscurrency}}</span>
                                </button>
                                <span class="qbs-money-right btn-label-out btn-label-out-right btn-label-out-warning pointing" style="color: black;">{{item.sum_finance_value_doing
                                    | qbscurrency}}</span>
                            </div>
                        </td>
                        <td class="font-bold text-center">
                            {{item.name}}
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

        <div class="col">

            <div class="d-flex justify-content-between align-items-center row-header bg-primary">
                <div style="font-size: large;">Hoc sinh lớp {{filter.class_code}}</div>

                <div class="d-flex justify-content-between ">
                    <button class="btn btn-white btn-labeled btn-labeled-left btn-icon" (click)="paymentRemind()" placement="top" ngbTooltip="Thông báo nhắc thanh toán" style=" margin: 7px 9px; ">
                        <span class="btn-label"><span class="material-icons text-warning"> notifications_active </span></span>
                        Nhắc thanh toán
                    </button>
                    <div ngbDropdown class="d-inline-block">
                        <button ngbDropdownToggle class="btn btn-white btn-labeled btn-labeled-left btn-icon" placement="top" ngbTooltip="Thanh toán cho các HS đã chọn" style=" margin: 7px 9px; ">
                            <span class="btn-label"><img class="" src="./assets/img/icons/finance.png" alt="image" width="24" /></span>
                            Thanh toán
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                            <button *ngFor="let item of categorys" (click)="paymentMulti(item)" ngbDropdownItem class="">
                                <img class="" src="./assets/img/icons/finance.png" alt="image" width="24" />
                                {{item.name}}
                                <span style="width: 130px; display: inline-block;"></span>
                                <span class="font-bold mr-3" style="position: absolute; right: 0;">{{item.finance_value
                                    | qbscurrency }}</span>
                            </button>

                        </div>
                    </div>
                    <label class="checkbox myHeader checkbox-warning check-single" style="margin-bottom: 6px;">
                        <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                        <span class="input-span"></span>
                    </label>
                </div>
            </div>

            <table class="table  table-hover" id="datatable">
                <thead class="thead-lg" style="background: #0996ca38;">
                    <tr>
                        <th>Học sinh</th>
                        <ng-container *ngIf="selected_finance_content_json.length > 0">
                            <th style="text-align: center; color: #ff9800;">T-1 Thiếu</th>
                            <th style="text-align: center; color: #ff9800;">T-1 Dư</th>
                        </ng-container>

                        <!-- <ng-container *ngIf="this.students.length > 0"> -->
                        <ng-container *ngFor="let jtem of selected_finance_content_json; let i = index">
                            <th class="text-center">
                                <span style="max-width: 95px; display: inline-block;">{{jtem.label}}</span>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="selected_finance_content_json.length == 0">
                            <th class="text-center">
                                <span>Khoản thu</span>
                            </th>
                        </ng-container>
                        <!-- </ng-container> -->
                        <th style="text-align: center">Đã thu / Đang thu</th>
                        <th style="text-align: center"> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.students; let i = index" class="student-item" [ngClass]="{'student-selected':  student_selected == item.code}">
                        <td>
                            <div>
                                <div style="font-size: 18px;">
                                    {{i+1}}.{{item.full_name}}
                                </div>
                                <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                                    {{item.code}} <span *ngIf="item.gender == 1" class="material-icons text-blue icon-gender">boy</span>
                                    <span *ngIf="item.gender == 0" class="material-icons text-pink icon-gender">girl</span> {{item.birthday_str}}
                                </div>
                            </div>
                        </td>
                        <ng-container *ngIf="selected_finance_content_json.length > 0">
                            <td class="text-right" style="color: #ee8700;">
                                <span>{{item.pri_debt | qbscurrency}}</span>
                            </td>
                            <td class="text-right" style="color: #ee8700;">
                                <span>{{item.pri_credit | qbscurrency}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="item.finance_content_json.length == selected_finance_content_json.length">
                            <ng-container *ngFor="let jtem of item.finance_content_json; let i = index">
                                <td class="text-right">
                                    <span>{{jtem.value | qbscurrency}}</span>
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.finance_content_json.length != selected_finance_content_json.length">
                            <ng-container *ngFor="let jtem of selected_finance_content_json; let i = index">
                                <td>
                                    <span></span>
                                </td>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selected_finance_content_json.length == 0">
                            <th class="text-center">
                                <span>{{selected_finance_cate_name == '' ? 'Tất cả':selected_finance_cate_name}}</span>
                            </th>
                        </ng-container>

                        <td style="width: 290px;">
                            <div class="d-flex justify-content-between align-items-center">

                                <button style="min-width: 130px; text-align: right;" class="btn btn-outline-info btn-labeled btn-labeled-left btn-icon" placement="top" ngbTooltip="Đã thu" (click)="openDetail(item)">
                                    <span class="btn-label">{{item.count_finance_cate_done}}</span>
                                    {{item.sum_finance_value_done | qbscurrency}}
                                </button>
                                <button style="min-width: 130px; text-align: right; color: black;" class="btn btn-outline-warning btn-labeled btn-labeled-left btn-icon" placement="top" ngbTooltip="Đang thu" (click)="openDetail(item)">
                                    <span class="btn-label">{{item.count_finance_cate_doing}}</span>
                                    {{item.sum_finance_value_doing | qbscurrency}}
                                </button>

                            </div>
                        </td>
                        <td style="width: 35px;">
                            <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox" [(ngModel)]="item.temp_checked">
                                <span class="input-span"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>


            <div *ngIf="students ==  undefined || students.length == 0" class="text-center text-warning mt-5 mb-5 p-2">
                <img style="width: 60px;" src="assets\img\icons\finder.png" alt="">
                <div>Chọn lớp bên trái để tải danh sách học sinh</div>
            </div>
        </div>

        <div *ngIf="isLoading" class="spinner-loadClass">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>