import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from 'src/app/_services/app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-servicedeskprice',
  templateUrl: './servicedeskprice.component.html',
  styleUrls: ['./servicedeskprice.component.css']
})
export class ServicedeskpriceComponent implements OnInit {


  deskPriceIdSelected = 1;


  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<ServicedeskpriceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {

    //chọn giá theo giờ.
    this.deskPriceIdSelected = this.utilityService.getCurrentTimeService();
    console.log("this.deskPriceIdSelected",this.deskPriceIdSelected);

  
  }

  ngOnInit(): void {
  }
   


  onCancelClick(): void {
    this.dialogRef.close();
  }
  onOkClick(): void {

    let temp = {}

    // this.appService.deskListPrice.forEach(function (item, index) {
    //   // if (item.id == this.deskPriceIdSelected) {
    //     console.log(index,item);
    //     console.log(this.deskPriceIdSelected);
    //   // }
    // }); 

    for (let i = 0; i < this.appService.deskListPrice.length; i++) {
      if (this.appService.deskListPrice[i].id == this.deskPriceIdSelected) {
        temp = this.appService.deskListPrice[i];
      }
    }

    let result = {
      code: "ok",
      data: temp
    }
    this.dialogRef.close(result);
  }

}
