<div class="m-2">
    <!-- <h4 mat-dialog-title>Install Angular</h4> -->
    <h4>Danh sách tài liệu <strong>{{item.name}}</strong></h4>
</div>
<mat-dialog-content>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="isLoading" class="text-center m-2">
        <span class="spinner-border spinner-lg"></span>
    </div>
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 50px; text-align: center;">#</th>
                <th>File tài liệu</th>
                <th>Dung lượng</th>
                <th>Người tải</th>
                <th>Ngày tải</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of list_document; let i = index">
                <td class="text-center">{{i+1}}</td>
                <td>

                    <a class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick  mr-1" placement="top" ngbTooltip="Xem file" (click)="openFileViewer(item)">
                        <span class="material-icons "> visibility </span>
                    </a>

                    <span>{{item.name}}</span>
                </td>
                <td class="text-right">{{item.size}} MB
                    <a [href]="item.file_url" class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick  mr-1" placement="top" ngbTooltip="download file" (click)="uploadFile()">
                        <span class="material-icons "> download </span>
                    </a>
                </td>
                <td>{{item.teacher_name}}</td>
                <td>{{item.created_date_str}}</td>
                <td class="text-center">
                    <button *ngIf="!isLoading_delete" class="btn btn-sm btn-white text-danger btn-icon-only btn-circle btn-thick" placement="top" ngbTooltip="Xóa" (click)="deleteFile(item)">
                        <span class="material-icons"> remove_circle_outline </span>
                    </button>
                    <span *ngIf="isLoading_delete" class="btn-label"><span class="spinner-border"></span></span>
                </td>
            </tr>
            <tr class="add_row">
                <td colspan="5">
                    <div class="upload-cover">
                        <div class="row">
                            <div class="d-flex align-items-center">
                                <span class="btn-icon file-upload">
                                <span class="material-icons">
                                    attach_file
                                    </span> Chọn file upload
                                <input type="file" accept="image/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                                </span>
                            </div>
                            <div class="col"><input [(ngModel)]="myFileName" name="myFileName" style="width: 100%;
                                border-radius: 5px;
                                padding: 5px;"></div>
                        </div>


                    </div>
                </td>
                <td class="text-center">
                    <button *ngIf="!isLoading_upload" class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="upload file" (click)="uploadFile()">
                        <span class="material-icons"> upload </span>
                    </button>
                    <span *ngIf="isLoading_upload" class="btn-label"><span class="spinner-border"></span></span>
                </td>
            </tr>
        </tbody>
    </table>


</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">print</span></span>In phiếu thu
    </button> -->
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>