import { environment } from './../../../environments/environment';
import { Component, OnInit,  } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ToastrService } from 'ngx-toastr';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { TeacherService } from 'src/app/_services/teacher.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherAddComponent } from './teacher-add/teacher-add.component';
import { TeacherUpdateComponent } from './teacher-update/teacher-update.component';
import { WorkingTimePickerComponent } from 'src/app/components/working-time-picker/working-time-picker.component';
declare var $: any;
@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit {

  
  isLoading = false;

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // countteacher = 0;

  // for data
  teaching_assignment_list = [];

  //for color
  colorList = [{ code: "black" }, { code: "white" }, { code: "gray" }, { code: "red" }, { code: "green" }, { code: "blue" }, { code: "yellow" }];



  //for data
  teachers = [];
  teachers_backup: any
  categorys: any
  facultys: any
  filter = {
    name: "",
    positions: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  
 
  // // for add
  // teacher = new Teacher("");
  // fileteacherImg: File;
  // imageUrl: any = environment.teacher_avatar;
  // // for update
  // teacherSelected: any;
  // isDeleted: boolean;
  // teacherSelected_imageUrl: any = environment.teacher_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[5].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  // for action
  action_mode = "";
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Danh sách giáo viên";
    this.sorter.sortStr = this.appService.sort_teachers[0].code;
    // this.teacherSelected = this.teacher;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_giao_vien);
  }




  ngOnInit() {
    this.loadTeacher();
    this.loadFaculty();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadTeacher();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadTeacher();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadTeacher();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadTeacher();
    }
  };
  clearSearch(){
    this.filter.name='';
    this.loadTeacher();
  }
  popup_mode(mode){

    this.action_mode = mode;
    if(this.action_mode == 'camera'){
      
    }else if(this.action_mode == 'changeClass'){
      // this.loadStudent();
    }
  }
  checkboxAll(evt) {
    // for (let index = 0; index < this.checkboxs_changeClass.length; index++) {
    //   this.checkboxs_changeClass[index] = evt.target.checked;
    // }
    
    this.teachers.forEach(element => {
      element.selected_temp = evt.target.checked;
    });

  }
  processChangeWorking_time(){
    let data = [];
    this.teachers.forEach(element => {
      if(element.selected_temp){
        data.push({
          code : element.code,
          checkin_time_ids : element.checkin_time_ids
        });
      }
    });
    console.log(data);
    if(data.length == 0){
      this.toastr.warning("", "Thầy cô chưa chọn CBGV để gán khung giờ làm việc");
      return;
    }
    // mở popup
    const dialogRef = this.dialog.open(WorkingTimePickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: data 
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("class_code", result);
        console.log("students", data);
        this.toastr.success("","Gán khung giờ điểm danh thành công");
        this.loadTeacher(); 
      }
    });
  }

  loadTeacher() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.filter.name = this.filter.name.trim();
    // this.loadTotalPage();
    if (true) {
      this.teacherService.loadTeacher(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.teachers = response.data;
            //thêm selected_temp để cho action
            this.teachers.forEach(element => {
              element.selected_temp = false;
              // chuyển danh sách khung giờ điểm danh sang array
              element.checkin_time_ids = element.checkin_time_ids != null ? element.checkin_time_ids.split(",") : [];
              element.checkin_time_names = element.checkin_time_names != null ? element.checkin_time_names.split(",") : [];
              element.checkin_times = element.checkin_times != null ? element.checkin_times.split(",") : [];

            });

            console.log(this.teachers);
            // this.teachers_backup = response.data;
            // this.appService.teachers = response.data;
            // this.appService.teachers_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.teachers.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
            
          }
        },
        error => {

        })
    }
  }

  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

  popup_teacher_add() {
    $(".blurButton").trigger("blur");
    const dialogRef = this.dialog.open(TeacherAddComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '600px',
      // height: '95%',
      disableClose : true,
      // panelClass: 'full-screen-modal',
      data: {'facultys':this.facultys}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
        // load lại dữ liệu
        this.loadTeacher();
      }
    });
  }


  popup_teacher_import() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    $("#import-teacher-modal").modal('show');
  }
  popup_teaching_assignment_list(data) {
    if(data != null && data != '')
    this.teaching_assignment_list = data.split(",");
    else{
      this.teaching_assignment_list=[]
    }
    $("#teaching-assignment-list").modal('show');
  }

  popupUpdate(item) {
    const dialogRef = this.dialog.open(TeacherUpdateComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '600px',
      // height: '95%',
      disableClose : true,
      // panelClass: 'full-screen-modal',
      data: {'teacherSelected': item,
            'facultys': this.facultys
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        this.toastr.success("","Cập nhật thông tin CBGV thành công");
        // load lại dữ liệu
        this.loadTeacher();
      }
    });

  }

  category_change(event) {
    this.loadTeacher();
  }
  sort_change(event) {
    this.loadTeacher();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.teachers = this.teachers_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.teachers = this.teachers.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  



  // closePopup(){
  //   console.log("closePopup");
  //   $("#add-teacher-modal").modal('hide');
  // }
  

  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  upload() {
    

    if (this.file == null || typeof(this.file) == "undefined") {
      this.toastr.warning("", "Vui lòng chọn file");
      return;
    } 
    
    this.isLoading = true;
    console.log(this.file);
    this.teacherService.importTeacher(this.file).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.value > 0) {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadTeacher();
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  popup_teacher_export() {
    // let filter = {};
    this.teacherService.exportteacher(this.filter);
  }


  
 
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.teacher_avatar + ')';
  // }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadTeacher();
      }),
      map(term => term === '' ? [] : this.teachers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupUpdate(r);
    }
}


