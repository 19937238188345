import { ProductService } from './../../_services/product.service';
import { CategoryService } from './../../_services/category.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/_services/app.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  category: any
  sumInfoCategorys : any;
  sumShopInfo ={
    total : 0,
    category_shop : 0,
    product_quantity_shop : 0,
    product_shop : 0,
  };
  constructor(
    public categoryService: CategoryService,
    public productService: ProductService,
    public appService: AppService,
  ) { 
    this.getTotalInfoShop();
    // this.getInfoCategorys();
  }

  ngOnInit() {
  }

  async getTotalInfoShop() {

 
    let filter = {};

    this.productService.getTotalInfoShop(filter).subscribe(
      async response => {
  
        console.log(response);
        if (response) {
          this.sumShopInfo = response
        }
      },
      error => {
     
        console.error(error);
      })

  }
  // async getInfoCategorys() {
  //   // this.loading = await this.loadingCtrl.create({
  //   //   message: 'Đang xử lý...',
  //   // });
  //   // await this.loading.present();
  //   let filter = {
  //     type: environment.cate_type
  //   }
  //   this.categoryService.getInfoCategorys(filter).subscribe(
  //     async response => {
  //       // this.loading.dismiss();
  //       console.log("getInfoCategorys",response);
  //       if (response) {
  //         this.sumInfoCategorys = response;
  //       }
  //     },
  //     error => {
  //       // this.loading.dismiss();
  //       console.error(error);
  //     })
  // }
}
