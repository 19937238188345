<div>
    <!-- (ngSubmit)="insertStudent()" -->
    <form class="modal-content modal-content-lg-2 form-horizontal" #registerImageForm="ngForm" id="form-update-student">
        <div class="modal-header p-4">
            <h5 class="modal-title">Tải ảnh Học Sinh để đăng ký điểm danh</h5>
        </div>
        <div class="modal-body p-4">



            <div class="row">
                <div class="col-sm-9">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Họ và tên</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="studentSelected.full_name" class="form-control" name="full_name" readonly placeholder="Họ và tên" type="text">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Ngày sinh</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="studentSelected.birthday_str" class="form-control" name="birthday_update" readonly>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="flexbox-b">
                        <div class="image-upload">
                            <div class="img-avatar" [style.background-image]="'url('+studentSelected_imageUrl+')'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="tab-content">
                <div class="tab-pane fade nav-link active show" id="tab-update-coban" aria-expanded="true">
                    <div class="row">
                        <div class="col">
                            <div class="text-center font-weight-bold mb-2">Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào máy ảnh, không đội mũ, đeo khẩu trang</div>
                            <div class="form-group text-center">
                                <div class="image-upload">
                                    <div class="camera-student" [style.background-image]="'url('+studentSelected_takeaphoto_imageUrl+')'">
                                    </div>
                                    <input *ngIf="!isLoading" type="file" accept="image/gif, image/jpeg, image/png" id="camera-student" name="upload2File" (change)="takeaphotoChange($event)">
                                    <div *ngIf="isLoading" class="row align-items-center justify-content-center" style="position: absolute; top: 0; margin: 0px; height: 288px; width: 100%;background: #0d12149c; color: white;
                                        font-size: xxx-large;">
                                        <span class="spinner-border"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center font-14 ">Thời gian đăng ký ảnh điểm danh sẽ lâu hơn so với tháo tác bình thường khác</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer justify-content-around bg-primary-50">

            <button *ngIf="studentSelected.checkin_type_status == 1" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.registerImageForm.invalid" (click)="uploadImageAndRegisterChecIn()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Đăng ký ảnh điểm danh
                </button>
            <button *ngIf="studentSelected.checkin_type_status == 2" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.registerImageForm.invalid" (click)="uploadImageAndRegisterChecIn()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Cập nhật ảnh điểm danh
                </button>


            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>