import { ProductService } from './../../_services/product.service';
import { Membership } from './../../model/membership';
import { UserService } from './../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { SaleService } from '../../_services/sale.service';
import { CartObj } from '../../model/cart-obj';
import {  NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter,  NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckinFacultyDetailComponent } from './checkin-faculty-detail/checkin-faculty-detail.component';
import { TeachingAssignment } from 'src/app/model/teachingAssignment';
declare var $: any;

@Component({
  selector: 'app-checkin-teacher-report',
  templateUrl: './checkin-teacher-report.component.html',
  styleUrls: ['./checkin-teacher-report.component.css']
})
export class CheckinTeacherReportComponent implements OnInit {
  isLoading = false;
  //type report
  type_report = this.appService.type_report_day;
  allSchools = {
    teacher_count: 0,
    teacher_inattention: 0,
    percent: 0,
  }

  //for data

  classs: any
  filter = {
    khoi_code: "",
    class_code: "",
    faculty_code: ''
  }
  dateRange = [];
  curentMonday: Date;

  reportcheckins: any;
  khoiSelected = -1;

  ///////////////
  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;




  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""

  public screenWidth: any;

  constructor(
    private schoolService: SchoolService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public userService: UserService,
    public productService: ProductService,
    public formatter: NgbDateParserFormatter,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.screenWidth = window.innerWidth;
    this.appService.page_title = "Báo cáo điểm danh Giáo viên";
    config.backdrop = 'static';
    config.keyboard = false;
    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadFaculty();
    this.loadReportCheckin();
    this.loadCurrentMonday(new Date);
    this.loadReportCheckinTeacherSchool();
  }

  category_khoi_change(event) {
    this.loadReportCheckin();
  }
  category_change(event) {
    // this.loadReportCheckin();
    if(this.type_report == this.appService.type_report_detail_week || this.type_report == this.appService.type_report_detail_month){
      this.loadReportCheckinTeacherByRange()
    }
  }
  // async loadClasss() {
  //   let filter = {
  //     khoi: this.filter.khoi_code,
  //     type: environment.cateType_class
  //   };
  //   this.categoryeduService.loadAllCategory(filter).subscribe(
  //     async response => {
  //       // this.isLoading.dismiss();
  //       console.log(response);
  //       if (response) {
  //         this.classs = response;
  //         // $('#category').selectpicker();
  //       }
  //     },
  //     error => {
  //       // this.isLoading.dismiss();
  //       console.error(error);
  //     })
  // }
  // reportAllSchool() {

  //   this.schoolService.reportAllSchool().subscribe(
  //     async response => {
  //       if (response) {
  //         console.log(response);
  //         this.allSchools = response;
  //         console.log(this.allSchools);
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  loadReportCheckin() {

    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.isLoading = true;
    this.reportcheckins = [];
    this.schoolService.reportCheckinFaculty(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          response.forEach(element => {
            if(element.teacher_count == null || element.teacher_count == 0){
              element.teacher_count = 0;
              element.teacher_inattention = 0;
              element.percent = 0;
            }else{
              element.teacher_inattention = parseInt(element.teacher_count)  -   parseInt(element.teacher_attention);
              element.percent = element.teacher_count ==0 ? 100 : Math.round(((element.teacher_attention) / element.teacher_count) * 100);
            }
          });
          this.reportcheckins = response;
          console.log(this.reportcheckins);
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  loadReportCheckinTeacherSchool() {
    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.schoolService.reportCheckinTeacherSchool(data).subscribe(
      async response => {
        if (response) {
          response.teacher_inattention = parseInt(response.teacher_type0_inattention) + parseInt(response.teacher_type1_inattention);
          response.percent = Math.round(((response.teacher_count - response.teacher_inattention) / response.teacher_count) * 100);
          this.allSchools = response;
          console.log(this.allSchools);
        }
      },
      error => {
        console.error(error);
      })
  }
  selectKhoi(i, khoi_name) {
    if (this.khoiSelected != i) {
      this.khoiSelected = i;
      this.filter.khoi_code = khoi_name;
      this.reportcheckins();
    }

  }
  popup_export_excel(){

  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    if(this.type_report == this.appService.type_report_day || this.type_report == this.appService.type_report_detail_week){
      this.loadCurrentMonday(this.fromDate);
    }else if(this.type_report == this.appService.type_report_detail_month){
      this.loadCurrentMonth(this.fromDate);
    }
    
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    this.selectedDateStr = dateStr;
    if(this.type_report == this.appService.type_report_day){
      this.loadReportCheckin();
      this.loadReportCheckinTeacherSchool();
    } else if(this.type_report == this.appService.type_report_detail_week || this.type_report == this.appService.type_report_detail_month){
      this.loadFaculty();
      this.loadReportCheckinTeacherByRange();
    }
  }
  

  loadCurrentMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if(curr.getDate() == first) first = first-6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadCurrentMonth(curr: Date) { // load tháng theo ngày được chọn
    // var dateStrStart = this.utilityService.getFirstFullDateStrFromDate(curr);
    // var dateStrEnd = this.utilityService.getLastFullDateStrFromDate(curr);
    var dateStart = this.utilityService.getFirstDateFromDate(curr);
    var numDay = this.utilityService.getLastDateFromDate(curr).getDate();
    console.log('dateStart',dateStart);
    console.log('numDay',numDay);
    this.loadDateRange(dateStart, numDay);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyyy: this.utilityService.getFullDateStrFromDate(temp),
          yyyymmdd: this.utilityService.getFullDate2StrFromDate(temp),
          day: temp.getDay().toString(),
        }
      );
    }
    console.log(this.dateRange);
  }

  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }


  // popupSaleDetail(item) {
  //   $("#sale-detail-modal").modal('show');
  //   this.saleSelectedItem = item;
  //   try {
  //     this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);

  //   } catch (error) {
  //     this.saleSelectedItem.service_details = [];
  //     console.error(error);
  //   }
  //   this.loadCartDetail(this.saleSelectedItem.id);
  // }
  selectReport(item){
    this.type_report = item;
    if(this.type_report == this.appService.type_report_detail_week || this.type_report == this.appService.type_report_detail_month){
      this.loadFaculty();
      this.loadReportCheckinTeacherByRange();
    }

    if(this.type_report == this.appService.type_report_day || this.type_report == this.appService.type_report_detail_week){
      this.loadCurrentMonday(this.fromDate);
    }else if(this.type_report == this.appService.type_report_detail_month){
      this.loadCurrentMonth(this.fromDate);
    }


  }
  openCheckinClass(item) {
    if(item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(CheckinFacultyDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { faculty_checkin: item, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }

  // for báo cáo chi tiết tuần
  teachers = [];
  attention_count = 0;
  facultys: any

  loadReportCheckinTeacherByRange() {
    let data = {
      faculty_code: this.filter.faculty_code != undefined ? this.filter.faculty_code : '',
      dateSelected: this.selectedDateStr,
      type_range: this.type_report == this.appService.type_report_detail_week ? "week" : this.type_report == this.appService.type_report_detail_month ? "month" : ''
    };
    this.isLoading = true;
    this.teachers = [];
    this.schoolService.loadReportCheckinTeacherByRange(data).subscribe(
      async response => {
        
        if (response) {
          this.attention_count = 0;
          response.forEach(element => {
            // chuyển thành mảng các ngày
            element.cate_checkin_time_names = element.cate_checkin_time_names != null ? element.cate_checkin_time_names.split(",") : [];
            element.cate_checkin_times = element.cate_checkin_times != null ? element.cate_checkin_times.split(",") : [];
            element.checkinDateStrs = element.checkinDateStrs != null ? element.checkinDateStrs.split("_") : [];
            element.checkin_timess = element.checkin_timess != null ? element.checkin_timess.split("_") : [];
            element.detected_image_urlss= element.detected_image_urlss != null ? element.detected_image_urlss.split("_") : [];
            element.image_counts = element.image_counts != null ? element.image_counts.split("_") : [];
            
            // đưa các ngày vào range
            let data_temp = [];
            let temp_sum = { // dữ liệu tổng điểm danh
              "total" : 0,
              'attention' : 0,
              'late' : 0,
              'inattention' : 0,
           }
            this.dateRange.forEach((dateRange_item,r) => {
              var temp_data = { // dữ liệu điểm danh
                'dayOfWeek': dateRange_item.dayOfWeek,
                'yyyymmdd': dateRange_item.yyyymmdd,
                'checkinDateStr': '',
                'checkin_times': [],
                'detected_image_urls': [],
                'image_count': 0,
                'cate_checkins': [],
              }
              
              // đưa cate_checkin vào cate_checkins
              let cate_checkin_temp = [];
              element.cate_checkin_time_names.forEach((element2,k) => {
                var temp2 = {
                  'cate_checkin_time_name': element.cate_checkin_time_names[k],
                  'cate_checkin_time': element.cate_checkin_times[k],
                  'cate_checkin_weeks': element.cate_checkin_times[k].split("-")[5].split("w"),
                  'cate_checkin_holidays': element.cate_checkin_times[k].split("-")[6].split(":"),
                  'status': -1 // 1: ok; 2 waring; 3; vắng; -1 không cần điểm danh
                }
                cate_checkin_temp.push(temp2);
              });
              temp_data.cate_checkins = cate_checkin_temp;
              // đưa cate_checkin vào datas

              // kiểm tra ngày và đưa vào range
              

              // đưa cate_checkin vào datas
              temp_data.cate_checkins.forEach((element_cate_checkins,k) => {
                // console.log("dateRange_item ddmmyyyy :",dateRange_item);
                // console.log("element_cate_checkins.cate_checkin_holidays:",element_cate_checkins.cate_checkin_holidays);
                // kiểm tra range date có nằm trong ngày nghỉ hay không
                if(!element_cate_checkins.cate_checkin_holidays.includes(dateRange_item.ddmmyyyy)){ // Không nằm trong ngày nghỉ
                  // kiểm tra range date có nằm trong ngày áp dụng không
                  if(element_cate_checkins.cate_checkin_weeks.includes(dateRange_item.day) && this.utilityService.checkSmallThanToDay(dateRange_item.ddmmyyyy)){
                    // console.log("dateRange_item.ddmmyyyy:",dateRange_item.ddmmyyyy);
                    // console.log("dateRange_item.day:",dateRange_item.day);
                    // console.log("element_cate_checkins.cate_checkin_weeks:",element_cate_checkins.cate_checkin_weeks);

                    if(element.checkinDateStrs.length > 0){ // có dữ liệu điểm danh
                      element.checkinDateStrs.forEach((element2,i) => {
                        if(element.checkinDateStrs[i] == dateRange_item.yyyymmdd){  // ngày = với ngày trong range thì add vào range

                          temp_data.checkinDateStr = element.checkinDateStrs[i];
                          temp_data.checkin_times = element.checkin_timess[i] != null ? element.checkin_timess[i].split(",") : [];  
                          temp_data.detected_image_urls = element.detected_image_urlss[i] != null ? element.detected_image_urlss[i].split(",") : [];  
                          temp_data.image_count = element.image_counts[i];
                          // gán trạng thái cho cate check in
                          // console.log("element_cate_checkins......:",element_cate_checkins);
                          element_cate_checkins.status = this.utilityService.getStatusCheckinReport(element.cate_checkin_times[k], temp_data.checkin_times); // 1: ok; 2 waring; 3; vắng
                          // chỉ đếm 1/2
                          temp_sum.total++;
                          temp_sum.attention = element_cate_checkins.status == 1 ? temp_sum.attention + 1 : temp_sum.attention + 0;
                          temp_sum.late = element_cate_checkins.status == 2 ? temp_sum.late + 1 : temp_sum.late + 0;
                          temp_sum.inattention = element_cate_checkins.status == 3 ? temp_sum.inattention + 1 : temp_sum.inattention + 0;
                        }
                      });
                    } else{ // không có dữ liêu điểm danh thì vắng
                      element_cate_checkins.status = 3;
                      // chỉ đếm 2/2
                      temp_sum.total++;
                      temp_sum.inattention++;
                    }

                    // console.log("element_cate_checkins......:",element_cate_checkins);
                  }
                }
              });
              data_temp.push(temp_data);
            });
            element.datas = data_temp;
            element.datas_sum = temp_sum;
          });
          this.isLoading = false;
          this.teachers = response;
          console.log(this.teachers);
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  item_datas : any = {};
  popupTooltip(item) {
    if(item.checkin_times.length > 0){

      this.item_datas = item;
      console.log(this.item_datas);
      $("#popupTooltip-modal").modal('show');
    }
  }
}

