import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../_services/app.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ShopService } from 'src/app/_services/shop.service';
import { CdkDrag, DragRef, CdkDragEnd } from '@angular/cdk/drag-drop';
import { DeskPrice } from 'src/app/model/deskPrice';
import { CartObj } from 'src/app/model/cart-obj';
import { SchoolService } from 'src/app/_services/school.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ProfileUploadImgComponent } from 'src/app/components/profile-upload-img/profile-upload-img.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  @ViewChild('userProfileForm') userProfileForm: HTMLFormElement;
  @ViewChild('schoolProfileForm') schoolProfileForm: HTMLFormElement;

  @ViewChild('addDeskForm') addDeskForm: HTMLFormElement;

  @ViewChild(CdkDrag) cdkDrag: CdkDrag;
  reset() {
    this.cdkDrag._dragRef['_previewRect'] = undefined;
    this.cdkDrag._dragRef['_boundaryRect'] = undefined;
  }



  is_time_service: boolean = false;
 

  isRessetPass: boolean = false;
  isLoading = false;

  password: string = "";
  repassword: string = "";

  // for desk
  deskAddList = [new CartObj()];
  deskSelected = new CartObj();
  // deskList = [];
  // đổi mật khẩu
  hide = true;// for password
  
  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    public userService: UserService,
    public schoolService: SchoolService,
    private toastr: ToastrService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Cài đặt";
   }

  ngOnInit() {
  }


  updateUserProfile() {
    console.log(this.isRessetPass);
    console.log(this.userProfileForm);
    console.log(this.userProfileForm.invalid);
    console.log(this.appService.currentUser);

    if (this.appService.currentUser.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên thành viên");
      this.userProfileForm.controls.name.setErrors("");
      return false;
    }
    if (this.appService.currentUser.phone.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập số điện thoại");
      this.userProfileForm.controls.phone.setErrors("");
      return false;
    }

    if (this.isRessetPass) {
      if (this.password.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập mật khẩu");
        this.userProfileForm.controls.pass.setErrors("Vui lòng nhập mật khẩu");
        return false;
      } else if (this.password != this.repassword) {
        this.toastr.warning("", "Mật khẩu chưa trùng khớp");
        this.userProfileForm.controls.repasss.setErrors("Mật khẩu chưa trùng khớp");
        return false;
      }
      this.appService.currentUser.isResset = 1
      this.appService.currentUser.password = this.password;
    }


    // return;!this.addForm.invalid
    if (!this.userProfileForm.invalid) {
      this.isLoading = true;
      this.userService.updateUser(this.appService.currentUser).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            this.appService.currentUser.password = "";
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
            this.toastr.success("Cập nhật thông tin thành công", "");
          }
          else {
            this.toastr.error("", "Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ hỗ trợ");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })
    }

  }
  openProfileUploadImg(){
    // mở popup
    const dialogRef = this.dialog.open(ProfileUploadImgComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {'teacherSelected': this.appService.currentUser,
              'facultys': []
              }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("openProfileUploadImg: ", result);
        
      }
    });
  }
  updateSchoolProfile() {
    console.log(this.schoolProfileForm);
    console.log(this.schoolProfileForm.invalid);
    console.log(this.appService.currentUser);
    if (this.appService.currentUser.school_name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên Trường");
      this.schoolProfileForm.controls.shop_name.setErrors("");
      return false;
    }
  
    if (true) {
      this.isLoading = true;
      let data = {
        "name":this.appService.currentUser.school_name,
        "address":this.appService.currentUser.school_address,
        "service_prices":this.appService.currentUser.shop_service_prices,
        "headmaster":this.appService.currentUser.headmaster,
      }
      this.schoolService.updateSchoolSomeInfo(data).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
            console.log(this.appService.currentUser);
            this.toastr.success("Cập nhật thông tin thành công", );
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu không đúng");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })

    }

  }
  updateBankProfile(){
    if (true) {
      this.isLoading = true;
      let data = {
        "bank_name":this.appService.currentUser.bank_name,
        "bank_account":this.appService.currentUser.bank_account,
        "bank_account_name":this.appService.currentUser.bank_account_name,
        "bidv_merchantId":this.appService.currentUser.bidv_merchantId,
        "bidv_service_id":this.appService.currentUser.bidv_service_id,
      }
      this.schoolService.updateSchoolSomeInfo(data).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
            console.log(this.appService.currentUser);
            this.toastr.success("Cập nhật thông tin thành công", );
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu không đúng");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })

    }
  }
  updateTokenProfile(){
    if (true) {
      this.isLoading = true;
      let data = {
        "access_token":this.appService.currentUser.access_token,
        "refresh_token":this.appService.currentUser.refresh_token,
        "meal_time":this.appService.currentUser.meal_time,
        "type_camera":this.appService.currentUser.type_camera,
        "hanet_placeID":this.appService.currentUser.hanet_placeID,
      }
      this.schoolService.updateSchoolSomeInfo(data).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            // localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
            console.log(this.appService.currentUser);
            this.toastr.success("Cập nhật thông tin thành công", );
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu không đúng");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })

    }
  }

  // for desk

  // addMoreDesk() {
  //   this.deskAddList.push(new Desk(0, ""));
  // }
  // removeMoreDesk(i) {
  //   this.deskAddList.splice(i, 1);
  // }
  removeMoreKhoi(i) {
    this.appService.currentUser.khois.splice(i, 1);
  }
  addMoreKhoi(i) {
    let  temp = { 'code': '', 'value': '', 'tx_num': 4 }
    this.appService.currentUser.khois.push(temp);
  }
  focusOutKhoiName(i){
    if(this.appService.currentUser.khois[i].code == ''){
      this.appService.currentUser.khois[i].code = this.utilityService.removeVietnameseTones(this.appService.currentUser.khois[i].value);
    }
  }
  updateKhois(){
    this.isLoading = true;
    this.schoolService.updateSchoolSomeInfo({ "khois": JSON.stringify(this.appService.currentUser.khois) }).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          // lưu vào storage
          localStorage.setItem(this.appService.currentUser.id.toString(), JSON.stringify(this.appService.currentUser));
          this.toastr.success("Cập nhật thông tin thành công",);
        }
        else {
          this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }
  
 







}
