import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class ProductService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('product/checkCode'), filter,).map(data => { return data; });
  }

  loadProduct(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('product/get'), data,).map(data => { return data; });
  }
  insert(product) {
    return this.http.post<any>(this.appService.getApiEndpoint('product'), product,).map(data => { return data; });
  }
  update(product) {
    delete product["modal"];
    return this.http.put<any>(this.appService.getApiEndpoint('product/code/'+product.code), product,).map(data => { return data; });
  }
  exportProduct(filter) {
    console.log(filter);
    window.open(this.appService.getApiEndpoint('io/downloadProduct/'+this.appService.currentUser.shop_code) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadProduct'), filter,).map(data => { return data; });
  }
  ///////////////////////////
   WIKI_URL = 'https://en.wikipedia.org/w/api.php';
   PARAMS = new HttpParams({
    fromObject: {
      action: 'opensearch',
      format: 'json',
      origin: '*'
    }
  });
  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(this.WIKI_URL, {params: this.PARAMS.set('search', term)}).pipe(
        map(response => response[1])
      );
  }

  searchProduct(filter, sorter, pagingObj) {
    filter.shop_code = this.appService.currentUser.shop_code;

    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    console.log(data);
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=Search'), data,).map(data => { return data; });
  }

  loadItemSum(filter) {
    filter.shop_code = this.appService.currentUser.shop_code;
    let data = {
      filter: filter,
    };
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=getItemSum'), data).map(data => { return data; });
  }
  countAllProduct() {
    return this.http.get<any>(this.appService.getApiEndpoint('product/count'), {},).map(data => { return data; });
  }
  
  getTotalInfoShop(filter) {
    return this.http.get<any>(this.appService.getApiEndpoint('product/warehouse'), {},).map(data => { return data; });
  }
  
  
  updateStockProduct(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    console.log(data);
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=UPDATESTOCK'), data,).map(data => { return data; });
  }
  
  importProduct(file, count_product) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_PRODUCT");
    formData.append('count_product', count_product);
    formData.append('shop_code', this.appService.currentUser.shop_code);
    formData.append('user_id', this.appService.currentUser.id.toString());
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadExcel'), formData).map(data => { return data; });
  }
  
  

}
