import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/_services/app.service';
import { version } from '../../../../package.json';
@Component({
  selector: '[app-sidebar]',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebar {
  public version: string = version;
  constructor(
    public appService: AppService,
    private router: Router,
  ){
    
  }
  ngOnInit() {}

  logout() {


  }
}
