<div class="page-content fade-in-up">


  <div class="row mb-2 mt-2 justify-content-between">
    <div class="col">

      <div class=" p-2 input-group-icon input-group-icon-left mr-3">
        <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
        <span *ngIf="!searching"class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
        <input id="typeahead-template" class="form-control input-lg" [(ngModel)]="filter.name" [ngbTypeahead]="search" 
          type="text" placeholder="Tìm nhóm KH" [resultTemplate]="rt"
          [inputFormatter]="formatter">
      </div>
      <ng-template #rt let-r="result" let-t="term" >
        <div class="row" (click)="onClick(r)" style="width: 450px;">
          <div class="img-product-search ml-1"  [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
          <div class="col"> #{{ r['code'] }}</div>
          <div class="col">
            <ngb-highlight [result]="r.name" [term]="t" >
            </ngb-highlight>
          </div>
          <div class="col">Kho: {{  r['stock'] == null ? 0: r['stock'] }}</div>
          <div class="col">SP: {{  r['products'] == null ? 0: r['products'] }}</div>
          <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
        </div>
      </ng-template>

    </div>
    <div class="col-3">
      <div class="p-2">
        <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.loadCategory();"
          name="sorter" id="sorter">
          <option [ngValue]="item.code" *ngFor="let item of appService.sort_category">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex justify-content-end">
        <div class="p-2"> <button class="btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal"
            data-target="#add-customer-modal" (click)="popup_category_add()">
            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
          </button>
        </div>
      </div>
    </div>
  </div>




  <div class="row align-items-center mb-2">
    <div class="col-4">
      <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
      <ul class="pagination p-1" style="margin-bottom: 0px;">
        <li>
          <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
            (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
            <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
          </a>
        </li>
        <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
          [ngClass]="{'active' : item.i == curentPage}">
          <a class="page-link" href="javascript:;">{{item.i}}</a>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
            <i class="ti ti-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="table-responsive row">
    <table class="table table-head-purple table-hover" id="datatable">
      <thead class="thead-default thead-lg">
        <tr>
          <th style="width: 50px; text-align: center;">#</th>
          <th>Tên nhóm KH</th>
          <th>Mặt hàng</th>
          <th>Tồn kho</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of categorys; let i = index">
          <td>
            <!-- <span style="position: absolute; left: 30px;
              line-height: 20px;">{{app.getUrlImage(item.image_url)}}</span> -->
            <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageCustomer(item.image_url)">
              <!-- <i class="ti ti-folder font-20"></i> -->
              <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
            </a>
          </td>
          <td>
            <div style="font-size: 18px;">{{item.name}}</div>
            <span>Mã: {{item.code}}</span>
          </td>
          <td>{{item.products == null ? 0: item.products}}</td>
          <td>{{item.stock == null ? 0: item.stock}}</td>
          <td>
            <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick"
              (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row align-items-center mb-2 paging-cover">
    <div class="col-4">
      <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
      <ul class="pagination p-1" style="margin-bottom: 0px;">
        <li>
          <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
            (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
            <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
          </a>
        </li>
        <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
          [ngClass]="{'active' : item.i == curentPage}">
          <a class="page-link" href="javascript:;">{{item.i}}</a>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
            <i class="ti ti-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

</div>




<div class="modal fade" id="add-category-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertCategory()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Thêm mới nhóm KH</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">

        <div class="form-group text-center">
          <div class="image-upload">
            <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
              <!-- <img [src]="imageUrl"> -->
            </div>
            <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile" name="uploadFile"
              (change)="changeListener($event)">
          </div>
          <div class="text-info">Vui lòng upload hình vuông</div>
        </div>
        <div class="form-group has-validation">
          <label class="col-form-label">Mã</label>
          <div class="">
            <input [(ngModel)]="cate.code" class="form-control" minlength="3" maxlength="10"
              (focusout)="focusOutCheckCode()" [readonly]="'mts' === CodeType" name="code" type="text"
              (ngModelChange)="cate.code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();">
            <span class="help-block">
              <div class="col-sm-10 d-flex align-items-center">
                <label class="radio radio-grey radio-primary radio-inline">
                  <input type="radio" name="b" [checked]="'mts' === CodeType" (click)="codeTypeClick('mts');">
                  <span class="input-span"></span>Mã tự sinh</label>
                <label class="radio radio-grey radio-primary radio-inline">
                  <input type="radio" name="b" [checked]="'tn' === CodeType" (click)="codeTypeClick('tn');">
                  <span class="input-span"></span>tự nhập</label>
              </div>
            </span>

          </div>
        </div>
        <div class="form-group ow">
          <label class="col-form-label">Tên</label>
          <div class="">
            <input [(ngModel)]="cate.name" class="form-control" name="name" required maxlength="30"
              placeholder="Tên nhóm KH" type="text">
          </div>
        </div>


      </div>
      <div class="modal-footer justify-content-around bg-primary-50">
        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid">
          <span class="btn-label" *ngIf="isLoading" ><span class="spinner-border"></span></span>
          <span class="btn-label" *ngIf="!isLoading" ><i class="ti ti-plus"></i></span>
            Thêm mới
        </button>
      </div>
    </form>
  </div>
</div>



<div class="modal fade" id="update-category-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category"
      (ngSubmit)="updateCategory()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Cập nhật nhóm KH</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">

        <div class="form-group text-center">
          <div class="image-upload">
            <div class="file-input" [style.background-image]="categoryselected_imageUrl">
              <!-- <img [src]="imageUrl"> -->
            </div>
            <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile_update" name="uploadFile"
              (change)="changeImageUpdate($event)">
          </div>
          <div class="text-info">Vui lòng upload hình vuông</div>
        </div>
        <div class="form-group has-validation">
          <label class="col-form-label">Mã</label>
          <div class="">
            <input [(ngModel)]="categoryselected.code" class="form-control" [readonly]="true" name="code" type="text">
          </div>
        </div>
        <div class="form-group ow">
          <label class="col-form-label">Tên</label>
          <div class="">
            <input [(ngModel)]="categoryselected.name" class="form-control" name="name" maxlength="30"
              placeholder="Tên nhóm KH" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Xóa</label>
          <div class="col-sm-9 d-flex align-items-end">
            <div class="col text-right">
              <label class="ui-switch switch-icon">
                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                  [disabled]="categoryselected.code == 'NKH'">
                <span></span>
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer justify-content-around bg-primary-50">
     

          <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
            <span class="btn-label" *ngIf="isLoading" ><span class="spinner-border"></span></span>
            <span class="btn-label" *ngIf="!isLoading" ><i class="ti ti-check"></i></span>
            Lưu
          </button>

      </div>
    </form>
  </div>
</div>

<!-- <div class="modal fade" id="update-category-modal-----------">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" id="form-update-category" (ngSubmit)="updateCategory()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Cập nhật nhóm KH</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Mã nhóm KH</label>
          <div class="col-sm-9">
            <input [(ngModel)]="categoryselected.code" class="form-control" readonly name="code" type="text">
            <span class="help-block">Mã tự sinh</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Tên nhóm KH</label>
          <div class="col-sm-9">
            <input [(ngModel)]="categoryselected.name" class="form-control" name="name" placeholder="Tên nhóm KH"
              type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Xóa</label>
          <div class="col-sm-9 d-flex align-items-end">
            <div class="col text-right">
              <label class="ui-switch switch-icon">
                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                  [disabled]="categoryselected.code == 'DM'">
                <span></span>
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer justify-content-around bg-primary-50">
        <button class="btn btn-primary " [disabled]="loading">Lưu</button>
      </div>
    </form>
  </div>
</div> -->