export class Cart {
    product_code : string = "";
    product_name : string = "";
    price : number = 0;
    quantity : number = 0;
    shop_code : string = "";
    customer_code: string = "";
    customer_name: string = "";

    product: any;

    constructor(){

    }
}
