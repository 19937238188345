import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.css']
})
export class MonthPickerComponent implements OnInit, AfterViewInit {
  @Input() months_selected : string = ''; // decorate the property with @Input()
  @Input() years_selected : string = ''; // decorate the property with @Input()
  @Output("change") newItemEvent = new EventEmitter<any>();

  public months = [];
  public years = [];
  public d = new Date();

  constructor() {

    var n = this.d.getFullYear();
    for (let index = 0; index < 5; index++) {
      this.years[index] = (n-- ) + "";
    }
    for (let index = 0; index < 12; index++) {
      this.months[index] = (index + 1) + "";
    }

  }



  ngOnInit(): void {
    if (this.months_selected == "") {
      this.months_selected = (this.d.getMonth() + 1).toString();
    }
    if (this.years_selected == "") {
      this.years_selected = (this.d.getFullYear()).toString();
    }
    console.log(this.months_selected);
    console.log(this.years_selected);

  }

  async ngAfterViewInit() {
    

  }

  monthYearSelected_change() {
    let result = {
      data: this.months_selected + "/" + this.years_selected
    }
    this.newItemEvent.emit(result);
  }

}