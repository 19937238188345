import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MealStudentDetailMonthComponent } from '../meal-student-detail-month/meal-student-detail-month.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { UtilityService } from 'src/app/_services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { MealService } from 'src/app/_services/meal.service';
import { FinanceQrcodePreviewComponent } from 'src/app/components/finance/finance-qrcode-preview/finance-qrcode-preview.component';
import { MealQrcodePreviewComponent } from '../meal-qrcode-preview/meal-qrcode-preview.component';

@Component({
  selector: 'app-meal-class-detail-month',
  templateUrl: './meal-class-detail-month.component.html',
  styleUrls: ['./meal-class-detail-month.component.css']
})
export class MealClassDetailMonthComponent implements OnInit {
  students = [];
  attention_count = 0;
  isLoading = false;

  status = "";
  monthStr='';
  constructor(
    public appService: AppService,
    private mealService: MealService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MealClassDetailMonthComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.monthStr = this.utilityService.dayStrToMonth(data.dateSelected);
    this.loadReportMealClassDetailMonth();
  }

  ngOnInit(): void {

  }
  chotthangn1(item){
      console.log(item);
  }
  chottamthu(item){
      console.log(item);
  }
  loadReportMealClassDetailMonth() {

    let data = {
      class_code: this.data.data.class_code,
      dateSelected: this.data.dateSelected,
      search: this.status,
    };
    this.isLoading = true;
    this.students = [];
    this.mealService.loadReportMealClassDetailMonth(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          this.attention_count = 0;
          response.forEach(element => {
              //meal
              if (element.meal == 1 && element.meal_id == 0) {// đăng ký meal
                element.meal_temp = true
              } else {
                element.meal_temp = false;
              }

          });
          this.students = response;
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });
  }
  openMealStudentMonth(item) { 
    if(item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(MealStudentDetailMonthComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      disableClose : true,
      width: '550px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: { data: item, dateSelected: this.data.dateSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }
  checkboxAll(evt) {
 
    this.students.forEach(element => {
      if(element.paid == false)
      element.selected_temp = evt.target.checked;
    });

  }
  confirmPayment(type, item){
    let student_codes = [];

    if(item == null){// bấm từ checkbox
      this.students.forEach(element => {
        if(element.selected_temp){
          student_codes.push({
            id : element.payment_id,
            month_total_paid : element.tamthu_total_amount,
            month : this.utilityService.dayStrToMonth(this.data.dateSelected),
            student_code : element.code
          });
        }
      });
    }else{ //bấm từ nút
      student_codes.push({
        id : item.payment_id,
        month_total_paid : item.tamthu_total_amount,
        month : this.utilityService.dayStrToMonth(this.data.dateSelected),
        student_code : item.code
      });
    }


    console.log(student_codes);
    if(student_codes.length == 0){
      this.toastr.warning("Chưa có học sinh", "Vui lòng chọn học sinh để thanh toán");
      return;
    }

    // this.scrollToLeff();
    let data = {
      title : `Xác nhận thanh toán cho ${student_codes.length} Học sinh đã chọn`,
      msg : 'Nhấn xác nhận để thanh toán.',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
  
      if(result == 'ok'){
        // close hoặc không close
        // let filter = {};
        this.isLoading = true;
        
        console.log('Chốt thu');
        let data = {
          month: this.utilityService.dayStrToMonth(this.data.dateSelected),
          type: type,
          student_codes: student_codes
        };
        // gọi API
        this.mealService.payment(data).subscribe(
          async response => {
            this.isLoading = false;
            if (response.code == 'ok') {
                this.loadReportMealClassDetailMonth();
            }else{
              this.toastr.warning("", "Có lỗi xảy ra khi thực hiện thanh toán");
            }
          },
          error => {
            this.isLoading = false;
            console.error(error);
          });
      }
    });
  }
  showQRcode(item){
    const dialogRef = this.dialog.open(MealQrcodePreviewComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {

      }
    });
  }
  search(){
    console.log(this.status);
    this.loadReportMealClassDetailMonth();
  }
}
