<div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start">

        <!-- <div class="p-2">
            <select class="custom-select" [(ngModel)]="filter.khoi" (change)="category_khoi_change($event)" name="khoi" id="khoi">
                <option value="">Khối</option>
                <option [ngValue]="item.code" *ngFor="let item of appService.currentUser.khois">{{item.value}}</option>
            </select>
        </div> -->

        <mat-form-field appearance="fill" class="mr-3" style="width: 150px">
            <mat-label>Khối</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill" class="mr-3" style="width: 150px">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                    {{item.name}} - ({{item.student_count}})
                </mat-option>
            </mat-select>
        </mat-form-field>


    </div>

    <div class="col-4 p-0 d-flex justify-content-end">

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày điểm danh</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>



<div>
    <div class="row align-items-center mb-2">
        <div class="col justify-content-end">
            <button class="mr-2 btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top">
                <img  src="./assets/img/icons/meal.png" width="22px" />
            </button> Đã đăng ký ăn bán trú
            <button class="mr-2 btn btn-sm  btn-icon-only btn-circle  btn-thick" placement="top">
                <img class="img-disable" src="./assets/img/icons/meal.png" width="22px" />
            </button> Không đăng ký ăn bán trú
        </div>
        <div class="col d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">

        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Họ tên</th>
                    <!-- <th>Tên</th> -->
                    <th>Ngày sinh</th>
                    <th>Lớp</th>
                    <th class="text-center">Dịch vụ</th>
                    <th class="text-center" style="width:140px ;">

                        <button class="btn btn-secondary" (click)="confirmDoCheckIn()">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-pencil-alt"></i></span>

                                Báo ăn {{meal_count}}/{{this.students.length}}
                        </button>


                    </th>
                    <th class="text-center">
                        Ghi chú
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.students; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                        <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                    </td>
                    <td>
                        <div class="d-flex justify-content-start align-items-center">
                            <div>
                                <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                                <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                            </div>
                            <div>
                                <div style="font-size: 18px;">
                                    {{item.full_name}}
                                </div>
                                <div class="text-muted">
                                    {{item.code}}
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- <td>
                        <div style="font-size: 18px;">{{item.name}} </div>
                    </td> -->
                    <td>{{item.birthday_str}}</td>
                    <td>{{item.class_code}}</td>
                    <td class="text-center">
                        <!-- <img *ngIf="item.meal == 1 " style="width: 30px;" src="./assets/img/icons/meal.png" />
                        <img *ngIf="item.meal == 0" style="width: 30px; filter: grayscale(1.0); opacity: 0.2;" src="./assets/img/icons/meal.png" /> -->

                        <button [ngClass]="{'btn-primary' : item.meal == 1}" class="mr-2 btn btn-sm  btn-icon-only btn-circle  btn-thick" placement="top">
                            <img [ngClass]="{'img-disable' : item.meal == 0}" src="./assets/img/icons/meal.png" width="22px" />
                        </button>
                    </td>
                    <td class="text-center">
                        <label *ngIf="item.meal == 1 " class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox" [(ngModel)]="item.meal_temp" (change)="updateAttention_count()">
                            <span class="input-span"></span>
                        </label>
                        <label *ngIf="item.meal == 0 " class="checkbox checkbox-primary check-single" style="opacity: 0.2;">
                            <input class="mail-check" type="checkbox" disabled="true" >
                            <span class="input-span"></span>
                        </label>



                    </td>
                    <td class="action_col">
                        <ng-container *ngIf="item.meal == 1">
                            <button *ngIf="!item.temp_showNote" class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Ghi chú" (click)="ShowNote(item)">
                                <span  class="material-icons"> edit_note </span>
                            </button>
                            <div *ngIf="item.temp_showNote" class="reason-cover">
                                <input type="text" [(ngModel)]="item.meal_note" [name]="'reason'+i" class="reason-input" maxlength="300">
                                <button class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Lưu ghi chú note" (click)="saveNote(item)">
                                    <span *ngIf="!isLoading" class="material-icons"> done </span>
                                    <span *ngIf="isLoading" class="spinner-border"></span>
                                
                                </button>
                                <button class="btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Đóng" (click)="ShowNote(item)">
                                    <span  class="material-icons"> close </span>
                                </button>
                            </div>
                            <div *ngIf="!item.temp_showNote"> {{item.meal_note}}</div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <app-nodata *ngIf="this.students.length ==0" msg="Thầy/Cô chọn lớp để quản lý báo ăn"></app-nodata>
</div>