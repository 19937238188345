<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>


            <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option value="value_date ASC">
                        Theo ngày nghỉ
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button [disabled]="!appService.checkFullPermission()" class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class=" row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Ngày nghỉ</th>
                    <th>Tên ngày nghỉ</th>
                    <th>Lịch làm việc</th>
                    <th>Lịch học</th>
                    <th>Lịch bán trú</th>
                    <th class="text-right"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <a class="avatar-img-list" href="javascript:;">
                            <img class="img-circle mt-1" src="./assets/img/icons/calendar.png" alt="image" width="30" />
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.value_date_str}}</div>
                    </td>
                    <td>{{item.name}}</td>
                    <td>
                        <button [disabled]="!appService.checkFullPermission()" (click)='openListView(item.value_date_str, item.list_checkin_time)' class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                            <span class="btn-label"><span class="material-icons text-primary font-18"> info </span></span>
                            {{item.count_checkin_time}}
                        </button>
                    </td>
                    <td>
                        <button [disabled]="!appService.checkFullPermission()" (click)='openListView(item.value_date_str, item.list_class_time)' class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                            <span class="btn-label"><span class="material-icons text-primary font-18"> info </span></span>
                            {{item.count_class_time}}
                        </button>
                    </td>
                    <td>
                        <button [disabled]="!appService.checkFullPermission()" (click)='openListView(item.value_date_str, item.list_meal_time)' class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                            <span class="btn-label"><span class="material-icons text-primary font-18"> info </span></span>
                            {{item.count_meal_time}}
                        </button>
                    </td>

                    <td class="text-right">
                        <button [disabled]="!appService.checkFullPermission()" class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Chỉnh sửa" (click)='popupUpdate(item)'>
                            <span class="material-icons">
                                settings
                                </span>
                        </button>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>


</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới ngày nghỉ</h5>
                <button data-dismiss="modal" class="btn btn-sm btn-icon-only btn-circle  btn-thick">
                    <span class="material-icons">
                        close
                        </span>
                </button>

            </div>
            <div class="modal-body p-4">


                <div class="row">
                    <div class="col p-3">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Ngày nghỉ</mat-label>
                            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="value_date_temp" name="fromDateStr" (dateChange)="onDateSelect($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col p-3">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ghi chú</mat-label>
                            <input matInput [(ngModel)]="cate.name" placeholder="Ghi chú" name="name" minlength="2" maxlength="100">
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật ngày nghỉ</h5>
                <button data-dismiss="modal" class="btn btn-sm btn-icon-only btn-circle  btn-thick">
                    <span class="material-icons">
                        close
                        </span>
                </button>
            </div>
            <div class="modal-body p-4">

                <div class="row">
                    <div class="col">

                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Ngày nghỉ</mat-label>
                            <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" [(ngModel)]="value_date_temp" name="fromDateStr2">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col">

                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ghi chú</mat-label>
                            <input matInput [(ngModel)]="categoryselected.name" placeholder="Ghi chú" name="u_name" minlength="2" maxlength="100">
                        </mat-form-field>


                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <!-- <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'"> -->
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>