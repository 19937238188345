<div class="page-brand">
    <a routerLink="/">
        <span class="brand"><img style="width: 35px; padding-right: 10px;"
                src="./assets/img/icons/favicon.png" />{{appService.currentUser.shop_name2}}</span>
        <span class="brand-mini">QBS</span>
    </a>
</div>
<div class="flexbox flex-1">
    <!-- START TOP-LEFT TOOLBAR-->

    <ul class="nav navbar-toolbar">
        <li *ngIf="appService.currentUser.login_role == 'admin' || appService.currentUser.login_role == 'cbgv'">
            <a class="nav-link sidebar-toggler js-sidebar-toggler" style="padding: 15px;" href="javascript:;">
                <!-- <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span> -->
                <span class="material-icons">
                    grid_view
                </span>
            </a>

        </li>
        <li *ngIf="appService.currentUser.login_role == 'phhd'">
            <a class="nav-link sidebar-toggler js-sidebar-toggler" style="padding: 15px;" href="javascript:;">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>
        </li>

        <li class="font-weight-bold">
            {{appService.page_title}}

        </li>

    </ul>

    <div class="text-center">
        <div class="page-title">{{appService.currentUser.school_name}}</div>
        <div class="font-13">Hello!</div>
        <!-- <div class="font-13">Hiệu trưởng: {{appService.currentUser.headmaster}}</div> -->
    </div>

    <!-- END TOP-LEFT TOOLBAR-->
    <!-- START TOP-RIGHT TOOLBAR-->
    <ul class="nav navbar-toolbar">

        <!-- <li *ngIf="appService.currentUser.isLoggedIn == true" class="dropdown dropdown-notification">
            <a class="nav-link dropdown-toggle toolbar-icon" data-toggle="dropdown" href="javascript:;"><i
                    class="ti-bell rel"><span class="notify-signal"></span></i></a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-media">
                <div class="dropdown-arrow"></div>
                <div class="dropdown-header text-center">
                    <div>
                        <span class="font-18"><strong>14 New</strong> Notifications</span>
                    </div>
                    <a class="text-muted font-13" href="javascript:;">view all</a>
                </div>
                <div class="p-3">
                    <ul class="timeline scroller" data-height="320px">
                        <li class="timeline-item"><i class="ti-check timeline-icon"></i>2 Issue fixed<small class="float-right text-muted ml-2 nowrap">Just now</small></li>
                        <li class="timeline-item"><i class="ti-announcement timeline-icon"></i>
                            <span>7 new feedback
                                <span class="badge badge-warning badge-pill ml-2">important</span>
                            </span><small class="float-right text-muted">5 mins</small></li>
                        <li class="timeline-item"><i class="ti ti-truck timeline-icon"></i>25 new orders sent<small class="float-right text-muted ml-2 nowrap">24 mins</small></li>
                        <li class="timeline-item"><i class="ti ti-shopping-cart timeline-icon"></i>12 New orders<small class="float-right text-muted ml-2 nowrap">45 mins</small></li>
                        <li class="timeline-item"><i class="ti ti-user timeline-icon"></i>18 new users registered<small class="float-right text-muted ml-2 nowrap">1 hrs</small></li>
                        <li class="timeline-item"><i class="ti-harddrives timeline-icon"></i>
                            <span>Server Error
                                <span class="badge badge-success badge-pill ml-2">resolved</span>
                            </span><small class="float-right text-muted">2 hrs</small></li>
                        <li class="timeline-item"><i class="ti-info-alt timeline-icon"></i>
                            <span>System Warning
                                <a class="text-purple ml-2">Check</a>
                            </span><small class="float-right text-muted ml-2 nowrap">12:07</small></li>
                        <li class="timeline-item"><i class="fa fa-file-excel-o timeline-icon"></i>The invoice is ready
                            <small class="float-right text-muted ml-2 nowrap">12:30</small></li>
                        <li class="timeline-item"><i class="ti ti-shopping-cart timeline-icon"></i>5 New Orders<small class="float-right text-muted ml-2 nowrap">13:45</small></li>
                        <li class="timeline-item"><i class="ti-arrow-circle-up timeline-icon"></i>Production server up
                            <small class="float-right text-muted ml-2 nowrap">1 days ago</small></li>
                        <li class="timeline-item"><i class="ti-harddrives timeline-icon"></i>Server overloaded 91%<small class="float-right text-muted ml-2 nowrap">2 days ago</small></li>
                        <li class="timeline-item"><i class="ti-info-alt timeline-icon"></i>Server error<small class="float-right text-muted ml-2 nowrap">2 days ago</small></li>
                    </ul>
                </div>
            </div>
        </li> -->
        <select class="custom-select" style="width: 120px;" [(ngModel)]="appService.currentUser.scholastic" (change)="selectScholastic()" name="scholastics">
            <option [ngValue]="item.code"  *ngFor="let item of appService.currentUser.scholastics_list">{{item.name}}</option>
        </select>
        <li *ngIf="appService.currentUser.isLoggedIn == false" class="dropdown">
            <a class="nav-link  link" routerLink="/login">
                <span>Đăng nhập</span>
                <img class="ml-2" style="width: 35px;" src="./assets/img/icons/userlock.svg" alt="image" />
                <!-- <img class="ml-2" style="width: 35px;" [src]="appService.showImageTeacher2(appService.currentUser.image_url)" alt="Ảnh cá nhân"> -->
            </a>
        </li>
        <li *ngIf="appService.currentUser.isLoggedIn == true" class="dropdown dropdown-user">
            <a class="nav-link dropdown-toggle link" data-toggle="dropdown">
                <div *ngIf="appService.currentUser.login_role == 'cbgv'">
                    <div>{{appService.currentUser.name}}</div>
                    <div class="font-13 text-right">{{appService.getValueByCode(appService.currentUser.positions,appService.positions)}}</div>
                </div>
                <div *ngIf="appService.currentUser.login_role == 'phhs'">
                    <div>{{appService.currentUser.full_name}}</div>
                    <div class="font-13 text-right">Học sinh</div>
                </div>
                <div *ngIf="appService.currentUser.login_role == 'admin'">
                    <div>{{appService.currentUser.name}}</div>
                    <div class="font-13 text-right">Admin</div>
                </div>
                <!-- <img src="./assets/img/users/admin-image.png" alt="image" /> -->
                <!-- <img style="width: 40px;" src="./assets/img/icons/person.svg" alt="image" /> -->
                <img style="width: 40px; height: 40px;" [src]="appService.showImageTeacher2(appService.currentUser.image_url)" alt="Ảnh cá nhân">
            </a>
            <div class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu">
                <div class="dropdown-arrow"></div>
                <div class="dropdown-header">
                    <div style="padding-right: 10px;">
                        <!-- <img style="width: 58px;" src="./assets/img/icons/person.svg" alt="image" /> -->
                        <img style="width: 58px; border: 1px solid white;
                        border-radius: 50%;" [src]="appService.showImageTeacher2(appService.currentUser.image_url)" alt="Ảnh đại diện">
                    </div>
                    <div>
                        <h5 class="font-strong text-white">{{appService.currentUser.name}}</h5>
                        <div>
                            <!-- <span class="admin-badge mr-3"><i class="ti-alarm-clock mr-2"></i>30m.</span> -->
                            <span class="admin-badge">
                                <button style="width: 25px; height: 25px;" (click)="openProfileUploadImg()" class="btn btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Cập nhật ảnh đại diện" >
                                    <span class="material-icons font-18">
                                        photo_camera
                                        </span>
                            </button>


                            {{appService.getValueByCode(appService.currentUser.positions,appService.positions)}}
                            </span>
                        </div>

                    </div>
                </div>
                <div class="admin-menu-features">
                    <a class="admin-features-item" routerLink="/document"><span class="material-icons" style="display: block;
                        font-size: 35px;
                        margin-bottom: 10px;">
                            inventory
                            </span>
                        <span>Tài liệu</span>
                    </a>

                    <a class="admin-features-item" routerLink="/setting"><span class="material-icons" style="display: block;
                        font-size: 35px;
                        margin-bottom: 10px;">
                            manage_accounts
                            </span>
                        <span>Cá nhân</span>
                    </a>
                    <a *ngIf="appService.checkFinancePermission()" class="admin-features-item" routerLink="/setting">
                        <span class="material-icons" style="display: block;
                        font-size: 35px;
                        margin-bottom: 10px;">
                            settings
                            </span>
                        <span>Cài đặt</span>
                    </a>
                </div>
                <div class="admin-menu-content">
                    <!-- <div class="text-muted mb-2">Doanh thu</div>
                    <div>
                        <i class="ti-wallet h1 mr-3 text-light"></i>
                        <span class="h1 text-success">..........</span>
                    </div> -->
                    <div class="d-flex justify-content-end mt-2">
                        <a class="d-flex align-items-center" (click)="logout()">
                            <span class="material-icons"> logout </span> Đăng xuất</a>
                    </div>
                </div>
            </div>
        </li>
        <!-- <li>
            <a class="nav-link quick-sidebar-toggler">
                <span class="ti-align-right"></span>
            </a>
        </li> -->
    </ul>
    <!-- END TOP-RIGHT TOOLBAR-->
</div>



<div class="checkout-widget" style="overflow: auto;">
    <div id="print-section" style="display: none;">
        <div style=" font-family: Tahoma; font-size: 14px;">
            <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
            <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
            <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
            <div style="text-align: right">{{dateTimeStr}}</div>
            <table style="font-size: 13px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Khách hàng: </td>
                    <td style="text-align: right;">{{appService.cartObj.customer_name}}</td>
                </tr>
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Tổng hóa đơn: </td>
                    <td style="text-align: right;">{{appService.cartObj.price_totol | number}}</td>
                </tr>
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Khuyến mãi: {{appService.cartObj.promotion_promotion}}{{appService.cartObj.promotion_type}} </td>
                    <td style="text-align: right;">{{appService.cartObj.price_promotion | number}}</td>
                </tr>
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Tổng thanh toán: </td>
                    <td style="text-align: right;"> {{appService.cartObj.price_after_promotion | number}} </td>
                </tr>
            </table>
            <!-- Chi tiết sử dụng dịch vụ -->
            <table *ngIf="appService.cartObj.is_time_service == 1" style="width: 100%; padding-top: 10px; font-size: 12px; ">
                <tr *ngFor="let item of appService.cartObj.cart_service; let i = index">
                    <td style="border-top: 1px dashed gray; padding: 5px 0px;">
                        <div> Tiền giờ: {{item.price | number}} x {{item.quantityLable}}</div>
                        <div> {{item.serviceStartTime | date :'HH:mm' }} -> {{item.serviceEndTime | date :'HH:mm' }}</div>
                    </td>
                    <td style="text-align: right; vertical-align: middle; border-top: 1px dashed gray; padding: 5px 0px;">
                        {{item.amount | number}}
                    </td>
                </tr>
            </table>

            <!-- END Chi tiết sử dụng dịch vụ -->
            <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
                <table style="font-size: 12px; width: 100%;">
                    <tr style="text-align: center; font-weight: bold; ">
                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;">Tên</td>
                        <td style="text-align: right;vertical-align: middle;">ĐG</td>
                        <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                        <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                    </tr>
                    <!-- giá dịch vụ -->
                    <tr *ngIf="appService.cartObj.is_time_service == 1" class="item-row-service">

                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;" colspan="3">Giá dịch vụ</td>
                        <td style="text-align: right; vertical-align: middle;">{{appService.cartObj.service_price | number}}</td>

                    </tr>
                    <!-- Hết giá dịch vụ -->
                    <tr *ngFor="let item of appService.cartObj.cart; let i = index">
                        <td style="vertical-align: middle;">{{i+1}}</td>
                        <td style="vertical-align: middle;">{{item.product.name}}</td>
                        <td style="text-align: right; vertical-align: middle;">{{item.product.price| number}}</td>
                        <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.product.unit}}</span></td>
                        <td style="text-align: right; vertical-align: middle;">{{item.quantity * item.product.price | number:0}}
                        </td>
                    </tr>
                    <tr style="font-weight: bold;">
                        <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                        <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                        <td style="border-top: 1px dashed gray;padding-top: 5px;">{{appService.cartObj.price_totol | number}}
                        </td>
                    </tr>
                </table>
            </div>
            <div style="text-align: center;">Chúc quý khách vui vẻ, hẹn gặp lại</div>
            <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
        </div>
    </div>

    <ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">
        <li class="nav-item">
            <a class="nav-link active" href="#tab-checkout" data-toggle="tab" style="font-size: 20;">
                <img style="width: 24px" src="./assets/img/icons/cart.svg" alt="image" /> Thanh toán
            </a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" href="#tab-22" data-toggle="tab" style="font-size: 20;">
                <img style="width: 24px" src="./assets/img/icons/cart.svg" alt="image" />
                Giỏ hàng
            </a>
        </li> -->
    </ul>
    <div class="tab-content">
        <div class="tab-pane chat-panel active" id="tab-checkout">
            <div id="cart-invoice">
                <div class="scroller">
                    <div class="container">
                        <div class="row info-row">
                            <div class="col">
                                Khách hàng:
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="popupSelectCustomer()">
                                    <span class="btn-label"><i
                                            class="ti ti-reload"></i></span>{{appService.cartObj.customer_name}}</button>
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Tổng đơn:
                            </div>
                            <div class="col text-right">
                                {{appService.cartObj.price_totol | number}}
                            </div>
                        </div>

                        <div class="row info-row">
                            <div class="col-6">
                                <div class="row ">
                                    <div class="d-flex align-items-center">
                                        <span style="padding-left: 17px; padding-right: 10px;">Khuyến mãi: </span>
                                        <label class="radio radio-grey radio-primary radio-inline">
                                            <input type="radio" name="b"
                                                [checked]="'%' == appService.cartObj.promotion_type"
                                                (click)="SelectkieuKhuyenMai('%')">
                                            <span class="input-span"></span>%</label>
                                        <label class="radio radio-grey radio-primary radio-inline">
                                            <input type="radio" name="b"
                                                [checked]="'đ' == appService.cartObj.promotion_type"
                                                (click)="SelectkieuKhuyenMai('đ')">
                                            <span class="input-span"></span>tiền</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col text-right">
                                <div class="row">
                                    <div class="col">
                                        <input class="form-control form-control-custom1" type="text" (focusout)="focusOutPromotion()" [(ngModel)]="appService.cartObj.promotion_promotion" name="promotion" currencyMask placeholder="Giá trị">
                                        <span style="position: absolute; top: 1px; right: 1px;">{{appService.cartObj.promotion_type}}</span>
                                    </div>
                                    <div class="col">
                                        {{appService.cartObj.price_promotion | number}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Tổng thanh toán:
                            </div>
                            <div class="col text-right">
                                {{appService.cartObj.price_after_promotion | number}}
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Khách trả:
                            </div>
                            <div class="col text-right">
                                <input [(ngModel)]="appService.cartObj.customer_pay" name="customer_pay" class="form-control form-control-custom1" placeholder="Khách trả" currencyMask type="text">
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Tiền thừa:
                            </div>
                            <div class="col text-right">
                                {{appService.cartObj.customer_pay - appService.cartObj.price_after_promotion | number}}
                            </div>
                        </div>
                        <div *ngIf="appService.cartObj.is_time_service == 1" style="padding-top: 10px">
                            <div *ngFor="let item of appService.cartObj.cart_service; let i = index" style="border-bottom: 1px dashed gray; padding: 10px 0px;">
                                <div class="row">
                                    <div class="ml-3">
                                        <a (click)="changeIsActive(item)" *ngIf="item.isEnable == 1">
                                            <img *ngIf="item.isActive == 1" class="img-circle" src="./assets/img/icons/clock-spinner.gif" alt="image" width="35px" />
                                            <img *ngIf="item.isActive == 0" class="img-circle" src="./assets/img/icons/clock-spinner.png" alt="image" width="35px" />
                                        </a>
                                        <a *ngIf="item.isEnable == 0">
                                            <img class="img-circle" src="./assets/img/icons/clock-spinner-done.png" alt="image" width="35px" />
                                        </a>
                                    </div>
                                    <div class="col">
                                        <div> Tiền giờ: {{item.price | number}} x {{item.quantityLable}}</div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                                    <span><i class="ti ti-control-play"></i></span>
                                                    <span>: {{item.serviceStartTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                                    <span><i class="ti ti-control-pause"></i></span>
                                                    <span>: {{item.serviceEndTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mr-3 text-right">
                                        {{item.amount | number}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table style="width: 100%; margin-top: 10px;">
                            <tr *ngIf="appService.cartObj.is_time_service == 1" class="item-row-service">
                                <td style="width: 25px; text-align: center;">
                                    <i class="ti ti-time"></i>
                                    <span class="lbl-stt"></span>
                                </td>
                                <td style="width: 55%;">
                                    <div style="line-height: 16px;">
                                        Giá dịch vụ
                                    </div>
                                </td>

                                <td style="text-align: center;">

                                </td>
                                <td style="text-align: right; color: black;">
                                    {{appService.cartObj.service_price | number}}
                                </td>
                            </tr>
                            <tr *ngFor="let item of appService.cartObj.cart; let i = index" class="item-row">
                                <td style="width: 25px; text-align: center;">
                                    <button class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick" (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                                    <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                    <span class="lbl-stt">{{i+1}}</span>
                                </td>
                                <td style="width: 55%;">
                                    <div style="line-height: 16px;">
                                        {{item.product.name}} - <span style="color: gray; font-size: small;">{{item.product.price|
                                            number}}</span>
                                    </div>
                                </td>
                                <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| number}}</span> </td> -->
                                <td style="text-align: center;">
                                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button> x <span style="color: black;"> {{item.quantity}} </span>
                                    <span style="font-size: small;"> {{item.product.unit}}</span>
                                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                                </td>
                                <td style="text-align: right; color: black;">
                                    {{item.quantity * item.product.price | number:0}}</td>
                            </tr>
                            <tr style="background: lightgray;line-height: 35px;">
                                <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                                <td style="text-align: center;font-weight: bold;">{{appService.cartObj.total_quantity}}
                                </td>
                                <td style="text-align: right; color: black; font-weight: bold;">
                                    {{appService.cartObj.price_totol | number}}</td>
                            </tr>
                        </table>
                        <div style="height: 40px;">

                        </div>
                        <!-- <div>
                            <ng-multiselect-dropdown [placeholder]="'Nhân viên kỹ thuật'" [data]="VNKTList"
                            [settings]="dropdownSettings" >
                            </ng-multiselect-dropdown>
                        </div> -->
                        <div class="container" style="position: fixed;bottom: 0;width: 490px;margin: 0 auto;">
                            <div class="row m-2 justify-content-between">
                                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="doPayment()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0">
                                    <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                                </button>

                                <button class="btn btn-outline-primary btn-icon-only btn-sm " (click)="print()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0"><i
                                        class="ti ti-printer"></i></button>


                                <button class="btn btn-outline-primary btn-icon-only btn-sm " (click)="popupDeleteCart()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0"><i class="ti ti-trash"></i></button>

                                <button class="btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="doOrder()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0">
                                    <span class="btn-label"><i class="ti ti-check-box"></i></span>Đặt hàng
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane" id="tab-22">

            <div class="logs">
                <div class="scroller">
                    <ul class="logs-list">
                        <li class="log-item" data-type="app"><i class="ti-check log-icon text-success"></i>
                            <div><a>2 Issue fixed</a><small class="float-right text-muted">Just now</small></div>
                        </li>
                        <li class="log-item" data-type="app"><i class="ti-announcement log-icon"></i>
                            <div><a>7 new feedback</a><span class="badge badge-warning badge-pill ml-1">important</span><small class="float-right text-muted">5 mins</small></div>
                        </li>
                        <li class="log-item" data-type="server"><i class="ti-check log-icon text-success"></i>
                            <div><a>Production server up</a><small class="float-right text-muted">24 mins</small></div>
                        </li>
                        <li class="log-item" data-type="app"><i class="ti ti-shopping-cart log-icon"></i>
                            <div><a>12 New orders</a><small class="float-right text-muted">45 mins</small></div>
                        </li>
                        <li class="log-item" data-type="server"><i class="ti-info-alt log-icon text-danger"></i>
                            <div><a>Server error</a><span class="badge badge-primary badge-pill ml-1">resolved</span><small class="float-right text-muted">1 hrs</small></div>
                        </li>
                        <li class="log-item" data-type="server"><i class="ti-harddrives log-icon text-danger"></i>
                            <div><a>Server overloaded 91%</a><small class="float-right text-muted">2 hrs</small></div>
                        </li>
                        <li class="log-item" data-type="app"><i class="ti ti-user log-icon"></i>
                            <div><a>18 new users registered</a><small class="float-right text-muted">12.07</small></div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="delete-cart-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal">
            <div class="modal-header p-4">
                <h5 class="modal-title">Xác nhận xóa giỏ hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                Bạn có chắn chắn muốn xóa giỏ hàng này ?

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button (click)="deleteCart()" class="btn btn-primary ">
                Đồng ý xóa
          </button>
                <button (click)="cancelDeleteCart()" class="btn">
                Bỏ qua
          </button>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="select-customer-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content modal-content-lg-2 form-horizontal" id="select-customer-form">
            <div class="modal-header p-4">
                <h5 class="modal-title">Chọn khách hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="row mb-2" *ngIf="!isAddingCustomer">
                    <div class="col-sm-8">
                        <div class="input-group-icon input-group-icon-left mr-3">
                            <span *ngIf="searching" class="input-icon input-icon-right font-16"><i
                                    class="ti ti-reload"></i></span>
                            <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i
                                    class="ti ti-search"></i></span>
                            <input id="cus-head-typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" name="search" placeholder="Tìm khách hàng" [resultTemplate]="rt" [inputFormatter]="formatter">
                        </div>
                        <ng-template #rt let-r="result" let-t="term">
                            <div class="row" (click)="onClick(r)" style="width: 450px;">
                                <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageCustomer(r['image_url'])"></div>
                                <div class="col"> #{{ r['code'] }}</div>
                                <div class="col">
                                    <ngb-highlight [result]="r.name" [term]="t">
                                    </ngb-highlight>
                                </div>
                                <div class="col">Phone: {{ r['phone'] }}</div>
                                <div class="col">Nhóm: {{ r['category_code'] }}</div>
                                <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-center">
                        <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="customer_add()">
                            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                        </button>
                    </div>
                </div>
                <form (ngSubmit)="customer_save()" id="customer_add" *ngIf="isAddingCustomer">
                    <div class="row mb-2">
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-sm-6">
                                    <input [(ngModel)]="customerObj.name" name="name" maxlength="50" class="form-control" type="text" placeholder="Tên">
                                </div>
                                <div class="col-sm-6">
                                    <input [(ngModel)]="customerObj.phone" name="phone" maxlength="10" min="10" class="form-control" type="text" placeholder="Số điện thoại">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex justify-content-center">
                            <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
                                <span class="btn-label " [ngClass]="{'spinner-border': isLoading}"><i
                                        class="ti ti-save"></i></span>Lưu và chọn
                            </button>
                            <!-- <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon"
                                [disabled]="isLoading">
                                <span class="btn-label " [ngClass]="{'spinner-border': isLoading}"><i
                                        class="ti ti-save"></i></span>Lưu và chọn
                            </button> -->
                            <button class="btn  btn-icon" (click)="isAddingCustomer = false">
                                <span class="btn-label">
                                    <i class="ti ti-back-left"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center mb-2">
                    <div class="col-4">
                        <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                            {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
                    </div>
                    <div class="col-8 d-flex justify-content-end">
                        <ul class="pagination p-1" style="margin-bottom: 0px;">
                            <li>
                                <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                                    <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">
                                        {{item.Value}}</option>
                                </select>
                            </li>
                            <li class="page-item">
                                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                                    <span aria-hidden="true">
                                        <i class="ti ti-angle-left"></i>
                                    </span>
                                </a>
                            </li>
                            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                                <a class="page-link" href="javascript:;">{{item.i}}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                                    <i class="ti ti-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>


                <div *ngFor="let item of appService.customers; let i = index" class="item-customer" (click)="selectCustomer(item)">
                    {{item.name}}
                    <div style="font-size: small;">{{item.phone}}</div>
                </div>
            </div>
        </form>
    </div>
</div>