
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinStudentDetailComponent } from 'src/app/components/checkin-sutdent-detail/checkin-student-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { StudentService } from 'src/app/_services/student.service';
import { HealthStudentDetailComponent } from 'src/app/components/health-student-detail/health-student-detail.component';

@Component({
  selector: 'app-health-class-detail',
  templateUrl: './health-class-detail.component.html',
  styleUrls: ['./health-class-detail.component.css']
})
export class HealthClassDetailComponent implements OnInit {
  students = [];
  attention_count = 0;
  constructor(
    public appService: AppService,
    private studentService: StudentService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HealthClassDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportHealthClassDetail();
  }

  ngOnInit(): void {

  }

  loadReportHealthClassDetail() {

    let filter = {
      class_code: this.data.class.class_code,
      period_time: this.data.dateSelected,
    };
    let sorter = {
      sortStr: "name ASC",
    }
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1
    };
    this.studentService.loadStudentHealth(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          console.log(response);
          response.data.forEach(element => {
            element.parents_name = element.mother_name != '' ? element.mother_name : element.father_name;
            element.parents_phone = element.mother_phone != '' ? element.mother_phone : element.father_phone;
          })

          this.students = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  open_student_detail(item){
    // const dialogRef = this.dialog.open(HealthStudentDetailComponent, {
    //   position: {
    //     // top: '0px',
    //     // left: '0px'
    //   },
    //   // disableClose : true,
    //   width: '90%',
    //   height: '90%',
    //   panelClass: 'full-screen-modal',
    //   data: { data: item, dateSelected: this.data.dateSelected }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (typeof result != "undefined" && result.code == "ok") {
    //     console.log("result", result);

    //   }
    // });
  }
}
