import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { BrowserModule } from '@angular/platform-browser'
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { CategoryEduService } from 'src/app/_services/category-edu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  isLoading_storage = false;
  cloudStorage = {
    fileCount: 0,
    totalSizes: "**",
  }
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { 
      display: true ,
      labels:{
        fontSize: 20,
        fontColor: 'red',
      }
    },
    plugins: {
      legend: {
        display: false,
        // position: 'top',
      },
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    }
  };
  // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartType: ChartType = 'pie';
  public pieChartLabels_teacher: Label[] = ['Nam', 'Nữ'];
  public pieChartData_teacher: SingleDataSet = [0, 0];
  public pieChartLabels_student: Label[] = ['Nam', 'Nữ'];
  public pieChartData_student: SingleDataSet = [0, 0];
  // public pieChartLegend = true;
  public pieChartPlugins = [];
  isLoading = false;
  allSchools = {
    "teacher": {
      teacher_count: 0,
      male_count: 0,
      female_count: 0,
      other_gender_count: 0,
    },
    "student": {
      student_count: 0,
      male_count: 0,
      female_count: 0,
      other_gender_count: 0,
    },
    "classfaculty": {
      class_count: 0,
      faculty_count: 0,
    },
    "cameraRegister": {
      student_count: 32,
      class_count: 0,
    }
  }
  reportClasss: any;
  khoiSelected = -1;
  // for filter
  filter = {
    khoi: "",
  }
  khoicosos = [];
  constructor(
    private categoryeduService: CategoryEduService,
    private schoolService: SchoolService,
    public appService: AppService,
    public utility: UtilityService,
  ) {
    this.appService.page_title = "Tổng quan";
  }

  ngOnInit() {
    this.reportAllSchool();
    this.reportClass();
    this.loadKhoicoso();
  }

  ngAfterViewInit() {
    // this._script.load('./assets/js/scripts/dashboard_visitors.js');
  }
  
  loadCloudStorage(){
    this.isLoading_storage = true;
    this.schoolService.loadCloudStorage().subscribe(
      async response => {
        this.isLoading_storage = false;
        if (response) {
          console.log(response);
          this.cloudStorage.fileCount = response.fileCount;
          this.cloudStorage.totalSizes = response.totalSizes;
      
        }
      },
      error => {
        this.isLoading_storage = false;
        console.error(error);
      }); 
  }
  reportAllSchool() {

    this.schoolService.reportAllSchool().subscribe(
      async response => {
        if (response) {
          console.log(response);
          // response.cameraRegister =  {
          //   student_count: 32,
          //   class_count: 0,
          // }
          this.allSchools = response;
          this.loadChart();
          console.log(this.allSchools);
        }
      },
      error => {
        console.error(error);
      })
  }
  loadKhoicoso() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let filter = {
        type : environment.cateType_khoicoso
    }
    let sorter = {
      sortStr: 'od'
    }
    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
            this.khoicosos = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  loadChart() {
    this.pieChartData_teacher = [this.allSchools.teacher.male_count, this.allSchools.teacher.female_count];
    this.pieChartData_student = [this.allSchools.student.male_count, this.allSchools.student.female_count];
  }
  reportClass() {

    let data = {
      filter: this.filter,
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.isLoading = true;
    this.schoolService.reportClass(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          this.reportClasss = response;
          console.log(this.reportClasss);
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  selectKhoi(i, khoi_name) {
    if (this.khoiSelected != i) {
      this.khoiSelected = i;
      this.filter.khoi = khoi_name;
      this.reportClass();
    }

  }

}
