import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HealthService } from 'src/app/_services/health.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { HealthRecord } from 'src/app/model/healthRecord';
import { HealthDoctorComponent } from '../../../components/health-doctor/health-doctor.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { HealthInputClassComponent } from 'src/app/pages/health/health-input-class/health-input-class.component';
declare var $: any;
@Component({
  selector: 'app-health-input',
  templateUrl: './health-input.component.html',
  styleUrls: ['./health-input.component.css']
})
export class HealthInputComponent implements OnInit {
  @Input() selectedDateStr: string;
  @Input() student_code: string;
  @Input() class_code: string;
  @Input() student_full_name: string;
  @Input() healthRecord_type: number;
  @Output() studentChange = new EventEmitter();

  @ViewChild('myInput', {static: false}) myInputVariable: ElementRef;

  healthRecord = new HealthRecord();
  isLoading = false;
  type_temp = 0;


  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // upload
  fileAttach: File;
  constructor(
    private ioService: IoService,
    private appService: AppService,
    private healthService: HealthService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private categoryeduService: CategoryEduService,
  ) {
    // console.log(this.selectedDateStr);
    // console.log(this.student_code);
    // console.log(this.student_full_name);
    // this.healthRecord.student_code = this.student_code;
    // this.healthRecord.period_time = this.selectedDateStr;
    // if (localStorage.getItem(environment.health_type)) {
    //   this.type_temp = parseInt(localStorage.getItem(environment.health_type));
    //   this.healthRecord.type = this.type_temp;
    // }
    this.healthRecord.type = this.healthRecord_type;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);
  }

  ngOnInit(): void {
    this.loadCategory_doctor();
  }
  ngOnChanges(): void {
    console.log("ngOnChanges", this.selectedDateStr);
    console.log("ngOnChanges", this.student_code);
    console.log("ngOnChanges", this.class_code);
    console.log("ngOnChanges", this.student_full_name);
    console.log("ngOnChanges healthRecord_type", this.healthRecord_type);
    console.log("ngOnChanges  this.healthRecord.type",  this.healthRecord.type);
    this.healthRecord.student_code = this.student_code;
    this.healthRecord.period_time = this.utilityService.dayStrToMonth(this.selectedDateStr);
    this.healthRecord.type = this.healthRecord_type;
    this.fileAttach = null; // đặt lại file
    if(this.myInputVariable != undefined)
    this.myInputVariable.nativeElement.value = ""; // đặt lại tag input file
    // Load health data
    if(this.healthRecord.student_code != ''){
      this.findData();
    }
  }

  findData() {
    let data = {
      'student_code': this.healthRecord.student_code,
      'period_time': this.healthRecord.period_time,
      'padded_rank': 0 // 1 và 0 để lấy thêm cột rank
    }
    // reset healthRecord - start
    this.healthRecord = new HealthRecord();
    this.healthRecord.student_code = this.student_code;
    this.healthRecord.period_time = this.utilityService.dayStrToMonth(this.selectedDateStr);
    // if (localStorage.getItem(environment.health_type)) {
    //   this.type_temp = parseInt(localStorage.getItem(environment.health_type));
    //   this.healthRecord.type = this.type_temp;
    // }
    this.healthRecord.type = this.healthRecord_type;
    console.log("bbbbbbbbbbb", this.healthRecord.type);
    // reset healthRecord - end
    this.healthService.findHealthRecord(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.length > 0) {
          console.log("Có dữ liệu");
          response[0].period_time = this.utilityService.dateStrToMonthStr(response[0].period_time);
          response[0].type = this.healthRecord_type; // chổ này hơi chuối
          this.healthRecord = response[0];
        }
        else {
          console.log("Không Có dữ liệu");
        }

        // gán staff vào health
        this.bindStaff();
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      })
  }
  popup_import() {
    $("#import-modal").modal('show');
  }
  uploadExcel() {

  }
  // toggleType(type) {
  //   // lưu vào storage
  //   localStorage.setItem(environment.health_type, type);
  //   this.healthRecord.type = type;
  // }
  save() {
    console.log(this.healthRecord);
    if (this.healthRecord.student_code == "") {
      this.toastr.error("", "Vui lòng chọn học sinh");
      return;
    }
    if (this.healthRecord.period_time == "") {
      this.toastr.error("", "Vui lòng chọn thời gian");
      return;
    }
    // xửu lý chỉ lấy tháng năm
    this.healthRecord.period_time = this.utilityService.dayStrToMonthFull(this.selectedDateStr);

    // xử lý việc gán bác sĩ
    this.bindStaff();

    // file đính kèm
    let filename = '';
    let filepath = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.healthRecord.student_code + "." + this.fileAttach.name.split('.').pop();
      filepath = environment.cateType_health + "/" + this.utilityService.dayStrToMonth(this.healthRecord.period_time);
      this.healthRecord.attached_file = filepath + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.isLoading = true;
    this.healthService.saveHealthRecord(this.healthRecord).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.healthRecord.id = response.value;
          console.log(this.healthRecord.id);

          // nếu có file đính kèm
          if(filename != '')
          this.uploadFileAttach(this.fileAttach, filepath, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm

          this.toastr.success("", "Lưu hô sơ thành công");
          // cập nhật status ở health record  
          this.studentChange.emit({ 'student_code': this.student_code, 'status': 0, 'health_attached_file': filepath + filename  });
        }
        else {
          this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      })
  }
  update() {
    console.log(this.healthRecord);
    if (this.healthRecord.student_code == "") {
      this.toastr.error("", "Vui lòng chọn học sinh");
      return;
    }
    if (this.healthRecord.period_time == "") {
      this.toastr.error("", "Vui lòng chọn thời gian");
      return;
    }
    // xửu lý chỉ lấy tháng năm
    this.healthRecord.period_time = this.utilityService.dayStrToMonthFull(this.selectedDateStr);

    // xử lý việc gán bác sĩ
    this.bindStaff();

    // file đính kèm
    let filename = '';
    let filepath = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.healthRecord.student_code + "." + this.fileAttach.name.split('.').pop();
      filepath = environment.cateType_health + "/" + this.utilityService.dayStrToMonth(this.healthRecord.period_time);
      this.healthRecord.attached_file = filepath + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.isLoading = true;
    this.healthService.update(this.healthRecord).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {

          // nếu có file đính kèm
          if(filename != '')
          this.uploadFileAttach(this.fileAttach, filepath, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm


          this.toastr.success("", "Lưu hô sơ thành công");
          
          // cập nhật status ở health record  
          this.studentChange.emit({ 'student_code': this.student_code, 'status': 0, 'health_attached_file': filepath + filename });
        }
        else {
          this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      })
  }
  toggle_cxk(type, $event, item) {
    console.log($event.target.value);
    if ($event.target.value == item) {
      if (type == 'cxk_bt') this.healthRecord.cxk_isbt = '';
      else if (type == 'cxk_ccs') this.healthRecord.cxk_isccs = '';
      else if (type == 'cxk_vcs') this.healthRecord.cxk_isvcs = '';
    }
    console.log(item);
  }
  /////////////////
  openDoctorManager() {

    const dialogRef = this.dialog.open(HealthDoctorComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      // width: '95%',
      // height: '95%',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");

      this.loadCategory_doctor();
    });


  }
  // openNhapNhanhKetQua(){
  //       if(this.class_code == ''){
  //         this.toastr.warning("","Thầy cô vui lòng chọn lớp để nhập nhanh");
  //         return;
  //       } 
  //       const dialogRef = this.dialog.open(HealthInputClassComponent, {
  //         disableClose: true,
  //         position: {
  //         },
  //         width: '90%',
  //         height: '90%',
  //         panelClass: 'full-screen-modal',
  //         data: { class_code: this.class_code, dateSelected: this.selectedDateStr }
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         if (typeof result != "undefined" && result.code == "ok") {
  //           console.log("result", result);
  //           //load lại health record
  //           this.studentChange.emit({ 'reload': true });
  //         }
  //       });
  // }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode == 44 || charCode == 46 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;

  }
  focusoutFunction(item){
    if(item.tl_cannang != null && typeof item.tl_cannang === "string")
    item.tl_cannang = item.tl_cannang.replace(",",".");
    if(item.tl_chieucao != null &&  typeof item.tl_chieucao === "string")
    item.tl_chieucao = item.tl_chieucao.replace(",",".");

    if(item.tl_cannang > 100){
      item.tl_cannang = 0;
      item.tl_chisobmi = 0;
      this.toastr.warning("Cân nặng không hợp lệ", "Lưu ý đơn vị là kg");
      return;
    }
    if(item.tl_chieucao > 2){
      item.tl_chieucao = 0;
      item.tl_chisobmi = 0;
      this.toastr.warning("Chiều cao không hợp lệ", "Lưu ý đơn vị là (m)");
      return;
    }

    if(item.tl_cannang != null && item.tl_chieucao != null && item.tl_cannang > 0 && item.tl_chieucao > 0 ){
      item.tl_chisobmi = (item.tl_cannang / (item.tl_chieucao * item.tl_chieucao)).toFixed(2);
    }else{
      item.tl_chisobmi = 0;
    }


  }
  // upload file
  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
  }
  uploadFileAttach(fileUpload: File, des: string, name: string) {
    
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  // load doctor
  categorys_doctor: any = [];
  loadCategory_doctor() {
    let filter = {
      name: "",
      type: environment.cateType_health_doctor
    }
    let sorter = {
      sortStr: 'od ASC',
    }
    let pagingObj = {
      viewNumsObj: 100,
      pageObj: 1
    };

    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          console.log("load doctor");
          this.categorys_doctor = response.data;

          // gán staff vào health
          this.bindStaff();

        }
      },
      error => {
        console.error(error);
      })
  }




  private bindStaff() {
    this.categorys_doctor.forEach((element, i) => {
      if (i == 0) {
        if (this.healthRecord.tl_staff == '')
          this.healthRecord.tl_staff = element.subject_type + ": " + element.name;
      } else if (i == 1) {
        if (this.healthRecord.nk_staff == '')
          this.healthRecord.nk_staff = element.subject_type + ": " + element.name;
      } else if (i == 2) {
        if (this.healthRecord.m_staff == '')
          this.healthRecord.m_staff = element.subject_type + ": " + element.name;
      } else if (i == 3) {
        if (this.healthRecord.tmh_staff == '')
          this.healthRecord.tmh_staff = element.subject_type + ": " + element.name;
      } else if (i == 4) {
        if (this.healthRecord.rhm_staff == '')
          this.healthRecord.rhm_staff = element.subject_type + ": " + element.name;
      } else if (i == 5) {
        if (this.healthRecord.cxk_staff == '')
          this.healthRecord.cxk_staff = element.subject_type + ": " + element.name;
      } else if (i == 6) {
        if (this.healthRecord.staff == '')
          this.healthRecord.staff = element.subject_type + ": " + element.name;
      } else if (i == 7) {
        if (this.healthRecord.donvi == '')
          this.healthRecord.donvi = element.name;
      }
    });
  }
}
