import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-data-picker',
  templateUrl: './data-picker.component.html',
  styleUrls: ['./data-picker.component.css']
})
export class DataPickerComponent implements OnInit {
  datas = [];// kiểu cho list để chọn
  data_input: string;// kiểu nhập input
  indexSelected = -1;
  constructor(
    public dialogRef: MatDialogRef<DataPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.datas = data.datas;
  }

  ngOnInit(): void {

  }

  click_select(): void {
    if(this.datas.length > 0){// kiểu cho list để chọn
      if(this.indexSelected > -1){
        let result = {
          data: this.datas[this.indexSelected],
          code: 'ok',
        }
        this.dialogRef.close(result);
      }
    }else{// kiểu nhập input
      let result = {
        data: this.data_input,
        code: 'ok',
      }
      this.dialogRef.close(result);
    }
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

}

