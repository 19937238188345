import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FileViewerComponent } from 'src/app/components/file-viewer/file-viewer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-class-document',
  templateUrl: './class-document.component.html',
  styleUrls: ['./class-document.component.css']
})
export class ClassDocumentComponent implements OnInit {
  isLoading : boolean = false;
  isLoading_upload : boolean = false;
  isLoading_delete : boolean = false;
  list_document = [];
  cateType = environment.cateType_class_document;
  filter = {
    code_parent: "",
    type: this.cateType
  }
  fileUpload: File;
  myFileName = "";

  sorter = {
    sortStr: this.appService.sort_category[3].code,
  }
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  pagingObj = {
    viewNumsObj: this.viewNumsObj,
    pageObj: 1
  };


  constructor(
    public appService : AppService,
    private categoryeduService : CategoryEduService,
    public dialog: MatDialog,
    public utilityService: UtilityService,
    public ioService: IoService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ClassDocumentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public item: any) 
  { 
      this.filter.code_parent = item.code;
  }

  ngOnInit(): void {
    this.loadCategory();
  }
  changeListener($event): void {
    this.fileUpload = $event.target.files[0];
    this.myFileName = this.utilityService.getNameByFileName(this.fileUpload.name);
    console.log(this.fileUpload);
  }
  deleteFile(item){
    // popup confirm
    let tempdata = {
      title: 'Xác nhận xóa tài liệu  ?',
      msg: 'Xác nhận',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        var data = {
          type: this.cateType,
          id: item.id,
          file_url: item.file_url,
          deleted: 1,
        }
        this.isLoading_delete = true;
        this.ioService.deleteFile(data).subscribe(
          async response => {
            this.isLoading_delete = false;
            console.log(response);
            if (response.code == "ok") {
              this.loadCategory();
              this.toastr.success("", "Xóa tài liệu thành công");
            }else{
              this.toastr.warning(response.msg, "Xóa tài liệu không thành công");
            }
          },
          error => {
            this.isLoading_delete = false;
            console.error(error);
          });
      }
    });

  }
  uploadFile(){
    if(this.fileUpload == null || typeof (this.fileUpload) == "undefined"){
      this.toastr.warning("Vui lòng chọn file upload");
      return;
    }
    if(this.myFileName == ""){
      this.toastr.warning("Vui lòng nhập tên file");
      return;
    }
    console.log(this.fileUpload);
  
 
    const formData = new FormData();
    formData.append('uploadFile', this.fileUpload, this.fileUpload.name);
    formData.append('action', "UPLOAD_FILE_GOOGLE");
    formData.append('des', `${this.cateType}/${this.item.code}`);
    formData.append('name', this.myFileName);
    formData.append('type', this.cateType);
    formData.append('class_code', this.item.code);
    this.isLoading_upload = true;
    this.ioService.uploadFile(formData).subscribe(
      async response => {
        this.isLoading_upload = false;
        if(response.code == 'ok'){
          this.toastr.success("Upload thành công");
          this.myFileName = '';
          this.fileUpload = null;
          this.loadCategory();
        }
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading_upload = false;
        console.error(error);
      })
  }
  openFileViewer(item){
    const dialogRef = this.dialog.open(FileViewerComponent, {
      data: {
        file_url: item.file_url
      }
    });
  }
  loadCategory() {
    this.pagingObj.viewNumsObj = this.viewNumsObj;
    this.pagingObj.pageObj = this.curentPage;
    let sorter = {
      sortStr: `edu_category.${this.sorter.sortStr}`,
    }
    
    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, sorter, this.pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
            this.list_document = response.data;

            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.list_document.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };
}
