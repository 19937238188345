

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
@Injectable()
export class CategoryCustomerService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  // loadCategoryCustomer(filter) {
  //   filter.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('category_customer.php?action=GET_LIST_ACTION'), filter,).map(data => { return data; });
  // }
  // insertCategoryCustomer(cate) {
  //   cate.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('category_customer.php?action=INSERT'), cate,).map(data => { return data; });
  // }
  // getInfoCategoryCustomer(cate) {
  //   cate.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('category_customer.php?action=GET_LIST_ACTION'), cate,).map(data => { return data; });
  // }
  // update(cate) {
  //   delete cate["modal"];
  //   cate.shop_code = this.appService.currentUser.shop_code;

  //   let data = {
  //     // filter: filter,
  //     param: cate
  //   };
  //   return this.http.post<any>(this.appService.getApiEndpoint('category_customer.php?action=UPDATE'), data,).map(data => { return data; });
  // }
  // countAllCategoryCustomer(filter) {
  //   filter.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('category_customer.php?action=countAllCategory'), filter,).map(data => { return data; });
  // }

}




