import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-checkin-timeline',
  templateUrl: './checkin-timeline.component.html',
  styleUrls: ['./checkin-timeline.component.css']
})
export class CheckinTimelineComponent implements OnInit {
  dataList: any = [];
  checkin_date;
  constructor(
    public dialogRef: MatDialogRef<CheckinTimelineComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.dataList = data.data;
    this.checkin_date = data.checkin_date;
  }

  ngOnInit(): void {
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}