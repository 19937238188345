<div class="page-content fade-in-up">
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm file</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm tên file" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-upload"></i></span>Upload file
                    </button>
                </div>
                <!-- <div class="p-2">
                    <div ngbDropdown class="d-inline-block">
               
                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                                <span class="btn-label"><span class="material-icons"> menu </span></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="popup_mode('class_time')" class="text-primary"><span class="material-icons mr-2">
                                calendar_month
                            </span>
                            Gán lịch học</button>
                            <button ngbDropdownItem (click)="popup_mode('meal_time')" class="text-primary"><span class="material-icons mr-2">
                                calendar_month
                            </span>
                            Gán lịch bán trú</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="openSaveArrange()" class="text-primary"><span
                                class="material-icons mr-2">
                                sort_by_alpha
                            </span>
                            Sắp xếp thứ tự</button>

                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>


    <div *ngIf="isLoading" class="text-center m-2">
        <span class="spinner-border spinner-lg"></span>
    </div>
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 50px; text-align: center;">#</th>
                <th>File tài liệu</th>
                <th>Dung lượng</th>
                <th>Người tải</th>
                <th>Thời gian</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of categorys; let i = index">
                <td class="text-center">{{i+1}}</td>
                <td>
                    <a class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick  mr-1" placement="top" ngbTooltip="Xem file" (click)="openFileViewer(item)">
                        <span class="material-icons "> visibility </span>
                    </a>
                    <span>{{item.name}}</span>

                </td>
                <td class="text-right">{{item.size}} MB
                    <a [href]="item.file_url" class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick  mr-1" placement="top" ngbTooltip="download file" (click)="uploadFile()">
                        <span class="material-icons "> download </span>
                    </a>
                </td>
                <td>{{item.teacher_name}}</td>
                <td>{{item.created_date_str}}</td>
                <td class="text-center">
                    <button *ngIf="!isLoading_delete" class="btn btn-sm btn-white text-danger btn-icon-only btn-circle btn-thick" placement="top" ngbTooltip="Xóa" (click)="deleteFile(item)">
                        <span class="material-icons"> remove_circle_outline </span>
                    </button>
                    <span *ngIf="isLoading_delete" class="btn-label"><span class="spinner-border"></span></span>
                </td>
            </tr>
            <!-- <tr class="add_row">
                <td colspan="5">
                    <div class="upload-cover">
                        <div class="row">
                            <div class="d-flex align-items-center">
                                <span class="btn-icon file-upload">
                                <span class="material-icons">
                                    attach_file
                                    </span> Chọn file upload
                                <input type="file" accept="image/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                                </span>
                            </div>
                            <div class="col"><input [(ngModel)]="myFileName" name="myFileName" style="width: 100%;
                                border-radius: 5px;
                                padding: 5px;"></div>
                        </div>


                    </div>
                </td>
                <td class="text-center">
                    <button *ngIf="!isLoading_upload" class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="upload file" (click)="uploadFile()">
                        <span class="material-icons"> upload </span>
                    </button>
                    <span *ngIf="isLoading_upload" class="btn-label"><span class="spinner-border"></span></span>
                </td>
            </tr> -->
        </tbody>
    </table>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="modal fade" id="import-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập Học sinh từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="upload-cover">
                    <div class="row">
                        <div class="d-flex align-items-center">
                            <span class="btn-icon file-upload">
                            <span class="material-icons">
                                attach_file
                                </span> Chọn file upload
                            <input type="file" accept="image/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                            </span>
                        </div>
                        <div class="col"><input [(ngModel)]="myFileName" name="myFileName" style="width: 100%;
                            border-radius: 5px;
                            padding: 5px;"></div>
                    </div>


                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col">

                        <button *ngIf="!isLoading_upload" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadFile()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            upload</button>
                        <span *ngIf="isLoading_upload" class="btn-label"><span class="spinner-border"></span></span>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>