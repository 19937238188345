
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.css']
})
export class ComposeComponent implements OnInit {
  categorys = []; // lớp
  checkboxAllButton = false; // mặc định chọn all
  discussion ={
    recipient:'',
    title:'',
    message: '',
    file_url: '',
    type: environment.message_type_msg,
  };
  isLoading = false;

  fileAttach: File;
  imageUrl;
  constructor(
    public categoryeduService: CategoryEduService,
    public studentService: StudentService,
    public messengerService: MessengerService,
    public dialogRef: MatDialogRef<ComposeComponent>,
    public appService: AppService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public ioService: IoService,
  ) {
    this.discussion.message = this.appService.currentUser.school_name ;
    // this.appService.page_title = "Liên lạc, thông báo";
    this.loadCategory();
  
  }

  ngOnInit(): void {
  }

  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
    
  }
  updateDiscussionRecipient(){
    this.discussion.recipient = '';
    this.categorys.forEach(item => {
      if(item.temp_checked == 1){
        this.discussion.recipient += item.code + ",";
      }
    });
   
  }
  confirmSendMessenger(){
    // cập nhật danh sách lớp cho discussion.recipient
    this.updateDiscussionRecipient();

    if(this.discussion.recipient.trim() == ""){
      this.toastr.warning("", "Thầy cô chưa chọn lớp để gửi thông báo");
      return;
    }

    let data = {
      title : 'Xác nhận gửi thông báo',
      msg : 'Đồng ý đề bắt đầu gửi thông báo.',
    }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          // close hoặc không close
          this.send();
      
        }
      });
  }
  send(){
    // cập nhật danh sách lớp cho discussion.recipient
    // this.updateDiscussionRecipient();
    
    // xử lý file đính kèm
    let filename = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.appService.currentUser.code + "_" + Date.now() + "_"+ this.fileAttach.name;
      this.discussion.file_url = environment.message + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.messengerService.sendMessenger(this.discussion).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          // nếu có file đính kèm
          if(filename != '')
          this.uploadFileAttach(this.fileAttach, environment.message, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm

          // đóng diaglog
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })
    console.log(this.discussion);
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: 50,
      pageObj: 1
    };

    let filter = {
      name: "",
      khoi_code: "",
      deleted: 0,
      type: environment.cateType_class
    }
    let sorter = {
      sortStr: this.appService.sort_category[0].code,
    }
    this.categoryeduService.getClassByGVCNCode(filter).subscribe(
      async response => {
        if (response) {
          this.categorys = response;
          // this.updateDiscussionRecipient();
        }
      },
      error => {
        console.error(error);
      })
  }

  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
    var reader = new FileReader();
        reader.readAsDataURL(this.fileAttach);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }

  }
  uploadFileAttach(fileUpload: File, des: string, name: string) {
    
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  
}
