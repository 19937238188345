<div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start">

        <!-- <div class="p-2">
            <select class="custom-select" [(ngModel)]="filter.khoi" (change)="category_khoi_change($event)" name="khoi" id="khoi">
                <option value="">Khối</option>
                <option [ngValue]="item.code" *ngFor="let item of appService.currentUser.khois">{{item.value}}</option>
            </select>
        </div> -->

        <mat-form-field appearance="fill" style="max-width: 200px;" class="">
            <mat-label>Khối</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of appService.currentUser.khois" [value]="item.code">
                    {{item.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- 
        <mat-form-field appearance="fill" style="" class="ml-2">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

    </div>

    <div class="col-4  p-0 d-flex justify-content-end align-items-center">


        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Tháng báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>






    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr}" (click)="selectDate(item.ddmmyyy)">
            <div>Tháng</div>
            <div style="font-size: large;">{{item.mmyyyy}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>




<div class="tab-content">

</div>


<!-- <div style="padding: 10px;"></div> -->

<div class="row">
    <div class="col p-0">
        <div class="ibox" style="border-radius: 10px;">
            <div class="p-3">
                <div class="row text-center">
                    <div class="col d-flex" style="background: var(--primary); padding: 10px; border-radius: 10px;">
                        <div class="d-flex align-items-center"><img style="width: 35px; margin: 0 10px;" src="assets\img\icons\students.png" alt=""> </div>
                        <div class="ml-3" style="color: white;">
                            <div class="font-18">Tổng số</div>
                            <div class="font-30">{{allSchools.student_count}}</div>
                        </div>
                    </div>
                    <div class="col  d-flex" style="background: #2196F3; padding: 10px; border-radius: 10px; margin: 0 20px;">
                        <div class="d-flex align-items-center"><img style="width: 30px; margin: 0 10px;" src="assets\img\icons\check-list-mark.png" alt=""></div>
                        <div class="ml-3" style="color: white;">
                            <div class="font-18">Đã nhập</div>
                            <div class="font-30">{{allSchools.student_inputted}}</div>
                        </div>
                    </div>
                    <div class="col d-flex" style="background: var(--orange); padding: 10px; border-radius: 10px;">
                        <div class="d-flex align-items-center"><img style="width: 30px; margin: 0 10px;" src="assets\img\icons\check-list-send.png" alt=""></div>
                        <div class="ml-3" style="color: white;">
                            <div class="font-18">Đã gửi</div>
                            <div class="font-30">{{allSchools.student_sent}}</div>
                        </div>
                    </div>
                </div>
                <div class="progress mt-2">
                    <div class="progress-bar bg-primary" role="progressbar" [style.width.%]="allSchools.percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        {{ allSchools.percent }}%
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-between">
                    <span>So với hôm qua</span>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col d-flex justify-content-end">


    </div>
</div>


<!-- <div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
    <span color="primary">Tổng sỹ số: </span> {{allSchools.student.student_count}}
    <span color="primary">Vắng: </span> 5
    <span color="primary">Tỷ lệ: </span> 97%
</div> -->

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>


<div class="row lop-cover">
    <div *ngFor="let item of classData; let i = index" (click)="openClassDetail(item)" class="card text-center card-air  m-1 lop-item">
        <div class="card-body">

            <!-- <span *ngIf="item.isAvailable == 0" class="material-icons font-18 disableView">visibility_off</span> -->

            <div class="card-avatar mt-2 mb-2" style="background-position: center; height: 35px; background-size: 35px;
            background-repeat: no-repeat;">
                <img src="assets\img\icons\cate-health.png" alt="Avatar">
            </div>
            <h4 class="card-title text-primary mb-1">Lớp {{item.class_code}}</h4>
            <!-- <div class="" style="white-space: nowrap;
            overflow: hidden !important; height: 20px;
            text-overflow: ellipsis; width: 140px;">{{item.teacher_name}}</div> -->
            <div class="m-2 text-center row align-items-center justify-content-between">
                <div class="font-18 font-bold text-black-2d">
                    <span class="material-icons">people</span>
                    <div>{{item.student_count}}</div>
                </div>
                <div>
                    <span class="font-18 font-bold  align-items-center " [ngClass]="{'text-warning' : item.health_inputted < item.student_count, 'text-primary' : item.student_count == item.health_inputted }">
                        <span class="material-icons">description</span>
                    <div class="">{{item.health_inputted}}</div>
                    </span>
                </div>
                <div>
                    <span class="font-18 font-bold align-items-center" [ngClass]="{'text-warning' : item.health_sent < item.health_inputted, 'text-primary' : item.student_count == item.health_sent }">
                        <span class="material-icons">send</span>
                    <div class="">{{item.health_sent}}</div>
                    </span>
                </div>
            </div>
            <div class="">
                <div *ngIf="item.health_inputted < item.student_count" class="progress my-danger mb-1">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
                <div *ngIf="item.health_inputted == item.student_count" class="progress my-primary mb-1">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>