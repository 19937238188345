<div *ngIf="type_report == appService.type_report_detail_week || type_report == appService.type_report_detail_month">

    <div class="qbs_scroll" [style.width.px]="screenWidth - 240">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th class="headcol" style="text-align: center;">
                        Cán bộ giáo viên
                        <div style="font-size: small;">
                            Chi tiết điểm danh
                        </div>
                    </th>

                    <td class="headrow" *ngFor="let item of dateRange; let i = index">
                        <div class="text-center" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': (item.day == 0 || item.day == 6) }" style="min-width: 60px;">
                            <div style="font-size: small;">{{item.dayOfWeek}}</div>
                            <div>{{item.ddmm}}</div>
                        </div>
                    </td>
                </tr>
            </thead>
            <!-- <tbody> -->
            <tr *ngFor="let item of this.teachers; let i = index" [ngClass]="{'bg-primary-100': false }">
                <th class="headcol">
                    <div class="d-flex justify-content-between align-items-center">
                        <a class="d-flex justify-content-start align-items-center">
                            <span class="mr-2"> <span class="badge badge-default ">{{i+1}}</span></span>
                            <div>
                                <div placement="top" [ngbTooltip]="item.code">
                                    {{item.name}}
                                </div>
                                <div class="text-secondary" style="font-size: 11px;">
                                    {{item.datas_sum.attention}} <span class="badge-secondary badge-point"></span> {{item.datas_sum.late}} <span class="badge-secondary badge-point"></span> {{item.datas_sum.total}}
                                </div>
                            </div>
                        </a>
                        <div>
                            <!-- {{item.datas_sum.attention}}/ {{item.datas_sum.late}}/ {{item.datas_sum.total}} -->
                            <button class="btn btn-secondary btn-fix btn-animated from-left">
                                <span class="visible-content">{{item.datas_sum.attention_duration}} giờ</span>
                                <span class="hidden-content">
                                    <span class="btn-icon"> Tổng {{item.datas_sum.total_duration}} vắng {{item.datas_sum.total_duration - item.datas_sum.attention_duration}}</span>
                                </span>
                            </button>
                        </div>

                    </div>
                </th>


                <td *ngFor="let item_datas of item.datas; let j = index" class="long">
                    <!-- tooltip -->

                    <div *ngIf="item_datas.checkin_times.length > 0" class="datas-tooltip">
                        <span *ngFor="let item3 of item_datas.checkin_times; let k = index" class="badge badge-primary badge-pill" style="font-size: unset;
                        margin: 3px;
                        font-weight: normal;">
                            {{item3}}
                        </span>
                    </div>
                    <!-- Danh sách khung giờ -->

                    <div class="w_t_btn">
                        <div *ngFor="let item_cate_checkin of item_datas.cate_checkins; let j = index">


                            <div *ngIf="item_cate_checkin.status > 0" class="btn-group mb-1 mr-2 ml-2">
                                <button class="btn btn-secondary btn-icon-only btn-sm" data-toggle="dropdown" style="width: 35px;">
                                    <span class="btn-label">
                                        <span *ngIf="item_cate_checkin.status == 1" class="material-icons text-primary">
                                            check_circle </span>
                                        <span *ngIf="item_cate_checkin.status == 2" class="material-icons text-warning">
                                            report </span>
                                        <span *ngIf="item_cate_checkin.status == 3" class="material-icons text-danger">
                                            report </span>
                                        <!-- hiển thị số lượng giờ -->
                                        <span *ngIf="item_datas.checkin_times.length > 0" class="bag_num_checkin">
                                            {{item_datas.checkin_times.length}}
                                        </span>
                                    </span>
                                </button>
                                <div class="btn-full dropdown-menu dropdown-menu-right" x-placement="bottom-end" style="position: absolute; transform: translate3d(157px, 30px, 0px); top: 0px; left: 0px; will-change: transform;">
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.code, item.name, 'one')" [disabled]="!(item_cate_checkin.status == 3)">Điểm danh</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.code, item.name, 'all')">Điểm danh tất cả</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="popupTooltip(item_datas)" [disabled]="!(item_datas.checkin_times.length > 0)">Xem ảnh điểm danh</button>
                                </div>
                                <button class="btn btn-secondary" (click)="popupTooltip(item_datas)">{{item_cate_checkin.cate_checkin_time_name}}</button>
                            </div>

                        </div>
                    </div>
                </td>


            </tr>
            <!-- </tbody> -->
        </table>
    </div>
</div>





<div class="modal fade" id="popupTooltip-modal">
    <div class="modal-dialog modal-dialog-full" role="document">
        <form class="modal-content form-horizontal">
            <div class="modal-header p-4">
                <h5 class="modal-title">Chi tiết</h5>
            </div>
            <div class="modal-body p-4">
                <table>
                    <tr *ngFor="let item3 of item_datas.checkin_times; let k = index" class="row-items-checkin">
                        <td class="timeline-box-left">
                            {{item3}}
                            <span></span>
                        </td>
                        <td class="timeline-box-right">
                            <div class="content-item">
                                <img [src]="appService.showCheckinImage(item_datas.detected_image_urls[k])" style="max-height: 200px;
                                border-radius: 5px;" />
                            </div>
                        </td>
                    </tr>
                </table>
                <div *ngIf="item_datas.checkin_times != undefined  && item_datas.checkin_times.length == 0">
                    Không có dữ liệu điểm danh
                </div>
            </div>
        </form>
    </div>
</div>