<div class="" role="document">
    <form class=" form-horizontal" #teacherUpdateForm="ngForm" id="form-update-studentSelected">
        <div class="modal-header">
            <div class="modal-title">Cập nhật Học sinh</div>
        </div>
        <div class="modal-body p-3">
            <div class="row">
                <div class="col-sm-7">

                    <mat-form-field class="example-full-width mb-2" appearance="fill">
                        <mat-label>Họ và tên</mat-label>
                        <input matInput [(ngModel)]="studentSelected.full_name" placeholder="Họ và tên học sinh" name="name" required maxlength="100">
                    </mat-form-field>

                    <div class="form-group row">
                        <mat-form-field appearance="fill">
                            <mat-label>Mã học sinh</mat-label>
                            <input matInput placeholder="Mã học sinh" type="text" [(ngModel)]="studentSelected.code" name="code" [readonly]="true">
                        </mat-form-field>
                    </div>

                </div>

                <div class="col  d-flex justify-content-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+studentSelected_imageUrl+')'">
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File" (change)="avatarChangeListener($event)">
                    </div>
                    <div class="image-avatar_camera_url">
                        <div class="file-input" [style.background-image]="'url('+studentSelected.avatar_camera_url+')'">
                        </div>
                    </div>
                </div>
            </div>


            <ul class="nav nav-tabs tabs-line">
                <li class="nav-item"><a href="#tab-info-coban" data-toggle="tab" aria-expanded="true" class="nav-link" [ngClass]="{'active' : this.tab == 'student'}">Thông tin chung</a></li>
                <li class="nav-item"><a href="#tab-info-phuhuynh" data-toggle="tab" aria-expanded="true" class="nav-link" [ngClass]="{'active' : this.tab == 'parent'}" class="nav-link" (click)="loadUsers()">Phụ huynh</a></li>
                <li class="nav-item"><a href="#tab-info-suckhoe" data-toggle="tab" aria-expanded="true" class="nav-link">Sức khỏe</a></li>
                <li class="nav-item"><a href="#tab-info-khac" data-toggle="tab" aria-expanded="false" class="nav-link">khác</a></li>
            </ul>
            <div class="tab-content">

                <div class="tab-pane fade" [ngClass]="{'active show' : this.tab == 'student'}" id="tab-info-coban" aria-expanded="true">
                    <div class="row mb-3">
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <mat-label>Lớp</mat-label>
                                <mat-select [(ngModel)]="studentSelected.class_code" name="class_code">
                                    <mat-option *ngFor="let item of classs" [value]="item.code">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <mat-label>Dân tộc</mat-label>
                                <mat-select [(ngModel)]="studentSelected.dan_toc" name="dan_toc">
                                    <mat-option *ngFor="let item of appService.dan_tocs" [value]="item.code">
                                        {{item.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">


                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Ngày sinh</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="birthday_temp" name="birthday">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker></mat-datepicker>
                            </mat-form-field>


                        </div>
                        <div class="col">
                            <section class="qbs-radio-cover">
                                <label class="lable">Giới tính</label>
                                <mat-radio-group [(ngModel)]="studentSelected.gender" name="gendergender">
                                    <mat-radio-button class="example-margin" [value]="1">Nam</mat-radio-button>
                                    <mat-radio-button class="example-margin" [value]="0">Nữ</mat-radio-button>
                                </mat-radio-group>
                            </section>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Địa chỉ</mat-label>
                                <input matInput [(ngModel)]="studentSelected.address" placeholder="Địa chỉ" name="address" maxlength="200">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <section class="qbs-radio-cover">
                                <label class="lable">Trạng thái</label>
                                <mat-radio-group [(ngModel)]="studentSelected.status" name="ustatusStatus">
                                    <mat-radio-button class="example-margin" [value]="0">Chính thức</mat-radio-button>
                                    <mat-radio-button class="example-margin" [value]="1">Trải nghiệm</mat-radio-button>
                                </mat-radio-group>
                            </section>
                        </div>
                    </div>


                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label">Xóa</label>
                        <div class="col-sm-9 d-flex align-items-end">
                            <div class="col text-right">
                                <label class="ui-switch switch-icon">
                                            <input type="checkbox" [(ngModel)]="isDeleted" name="deleted">
                                            <span></span>
                                        </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-around">
                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.teacherUpdateForm.invalid" (click)="updateStudent()">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                                    task_alt
                                    </span></span>
                                Cập nhật
                            </button>


                        <button class="btn btn-secondary " (click)="this.dialogRef.close();">Đóng</button>
                    </div>

                </div>
                <div class="tab-pane fade" [ngClass]="{'active show' : this.tab == 'parent'}" id="tab-info-phuhuynh" aria-expanded="false">
                    <div class="row mb-3" *ngFor="let item of this.users; let i = index">
                        <div class="col">
                            <mat-form-field class="example-full-width mb-2" appearance="fill">
                                <mat-label>{{item.label}}</mat-label>
                                <input matInput [(ngModel)]="item.name" placeholder="Họ và tên" [name]="'parent_name'+i" maxlength="200">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="example-full-width mb-2" appearance="fill">
                                <mat-label>Số điện thoại</mat-label>
                                <input matInput [(ngModel)]="item.phone" [name]="'parent_phone'+i" (focusout)="focusOutCheckPhone(i)" numbersOnly maxlength="10" minlength="10" placeholder="dùng để đăng nhập">
                            </mat-form-field>
                        </div>
                        <div class="col-1">
                            <button *ngIf="item.code !='' && item.phone !=''" data-toggle="tooltip" placement="top" ngbTooltip="Đặt lại mật khẩu" class="btn btn-sm btn-secondary btn-icon-only btn-circle mt-2 " (click)='popupResetPass(item)'>
                                <span class="material-icons"> lock_reset </span>
                            </button>


                        </div>
                    </div>

                    <div class="modal-footer justify-content-around">
                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.teacherUpdateForm.invalid" (click)="updateUser()">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                                    task_alt
                                    </span></span>
                                Cập nhật tài khoản phụ huynh
                            </button>


                        <button class="btn btn-secondary " (click)="this.dialogRef.close();">Đóng</button>
                    </div>
                </div>
                <div class="tab-pane fade text-center" id="tab-info-suckhoe" aria-expanded="false">
                    Thông tin Sức khỏe của học sinh
                </div>
                <div class="tab-pane fade text-center" id="tab-info-khac" aria-expanded="false">
                    Thông tin khác
                </div>
            </div>
        </div>

    </form>
</div>