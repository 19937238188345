import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  
  reportStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/reportStudent'), data,).map(data => { return data; });
  }
  saveHealthStudents(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/saveHealthStudents'), data,).map(data => { return data; });
  }
  findHealthRecord(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/find'), data,).map(data => { return data; });
  }
  saveHealthRecord(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/save'), data,).map(data => { return data; });
  }
  update(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/update'), data,).map(data => { return data; });
  }
  sendNotification(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/sendNotification'), data,).map(data => { return data; });
  }
  reportHealthSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/reportSchool'), data,).map(data => { return data; });
  }
  reportHealthClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('health/reportClass'), data,).map(data => { return data; });
  }
}




