<div class="m-2">
    <h4>Preview File</h4>
</div>
<mat-dialog-content>

    <div *ngIf="fileType === 'image'">
        <img [src]="fileUrl" alt="Image Preview" style="max-width: 100%; height: auto;">
    </div>

    <div *ngIf="fileType === 'pdf'" class="pdf-container">
        <iframe [src]="fileUrl | safe: 'resourceUrl'" width="100%" height="100%"></iframe>
    </div>

    <div *ngIf="fileType === 'document'" class="pdf-container">
        <iframe [src]="fileUrl | safe: 'resourceUrl'" width="100%" height="100%"></iframe>
        <p>
            Nếu tài liệu không hiển thị, <a [href]="fileUrl" target="_blank">bấm vào đây để tải về</a>.
        </p>
    </div>

    <div *ngIf="fileType === 'unsupported'">
        <p>Định dạng file không được hỗ trợ. <a [href]="fileUrl" target="_blank">Tải về tại đây</a>.</p>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="this.dialogRef.close();">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>