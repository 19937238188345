import { environment } from 'src/environments/environment';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { UserService } from 'src/app/_services/user.service';
import { SaleService } from './../../_services/sale.service';


import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../_services/app.service';
import { UtilityService } from '../../_services/utility.service';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  loading: any;

  chart1 = [];
  colorArray: any;

  dateSelected: Date;
  dateStrSelected: String;
  salesTitleByday_result = [];
  day_num = environment.DAY_REPORT_DEFAULT;
  sum_prices = 0;



  // user selected
  userNvbhSelected = "";
  userNvbhList = [];

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }

  //for chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    // { data: [], label: 'Series A' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    public saleService: SaleService,
    // private _script: ScriptLoaderService,
    public formatter: NgbDateParserFormatter,
    // private calendar: NgbCalendar,
    public userService: UserService,
  ) {
    this.dateSelected = new Date();
    this.dateStrSelected = this.utilityService.getFullDateStrFromDate(this.dateSelected);


  }
  ngOnInit(): void {
    this.loadMembershipVNBH();
  }

  async loadMembershipVNBH() {
    let filter = {
      role: "nvbh,admin"
    };
    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.userNvbhList = response;
        }
      },
      error => {
        console.error(error);
      })
  }
  userSelected_change() {
    console.log(this.userNvbhSelected);
    this.getSalesTitleByBays();
    // this.loadSales(this.selectedDateStr);
  }
  ngAfterViewInit() {

    this.getSalesTitleByBays();
  }
  async getSalesTitleByBays() {

    let param = {
      type: environment.SALES_TYPE,
      user_id: this.userNvbhSelected,
      day_num: this.day_num,
      status: environment.SALE_STATUS_DONE,
      dateSelected: this.dateStrSelected
    }
    this.saleService.getSalesTitleByBays(param).subscribe(
      async response => {
        console.log('getSalesTitleByBays');
        console.log(response);
        if (response) {
          this.salesTitleByday_result = response;

          let temp=[]
          let temp2=[]
          this.sum_prices = 0;
          for (let i = 0; i < this.salesTitleByday_result.length; i++) {
            this.sum_prices = this.sum_prices + parseInt(this.salesTitleByday_result[i].sum_price);
            temp.push(this.salesTitleByday_result[i].sum_payment);
            temp2.push(this.salesTitleByday_result[i].day_month);
          }
          
          //load data
          this.barChartData = [{ data: temp, label: 'Doanh thu' }];
          this.barChartLabels = temp2;
        }
      },
      error => {

        console.error(error);
      });
  }
  // onChangeDate(data): void {
  //   this.dateStrSelected = this.utilityService.formatDateStr(data);
  //   this.getSalesTitleByBays();
  //   console.log("onChangeTime to time: " + this.dateStrSelected + ". Event data: " + data);
  // }
  day_change() {
    this.getSalesTitleByBays();
  }

  onDateSelect($event) {
    console.log($event);
    console.log(this.dateStrSelected);
    this.getSalesTitleByBays();
    // this.loadSales(this.dateStrSelected);
  }



  ///dateTime
  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  //   // check if date valid
  //   if (this.fromDate !== null && this.toDate !== null) {
  //     console.log(this.fromDate, this.toDate);
  //     this.getSalesTitleByBays();
  //   }

  // }

  // isHovered(date: NgbDate) {
  //   return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  // }

  // isInside(date: NgbDate) {
  //   return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  //   return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  // }

  // validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  //   const parsed = this.formatter.parse(input);
  //   return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  // }

    // events
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
      console.log(event, active);
    }
  
    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
      console.log(event, active);
    }
  

}
