import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-returns-product',
  templateUrl: './returns-product.component.html',
  styleUrls: ['./returns-product.component.css']
})
export class ReturnsProductComponent implements OnInit {

  public saleSelectedItem : any; // giỏ hàng
  public cartDetail : any;  // chi tiết giỏ hàng
  
  constructor(
    public dialogRef: MatDialogRef<ReturnsProductComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.saleSelectedItem = this.data.saleSelectedItem;
    this.saleSelectedItem.returnProduct = 0
    this.saleSelectedItem.returnProduct_price = 0

    this.cartDetail = this.data.cartDetail.map(function(item, index) {
      let item_temp = item;
      item_temp.quantity_temp = item.quantity;
      return item_temp;
    })
    
    console.log(this.cartDetail);
   }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }


  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity--;
      this.calculateTotalCart();
      // this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity - 1;
      // this.appService.cartObj.price_totol = this.appService.cartObj.price_totol - parseInt(item.product.price);
      // this.appService.preparePromotion(this.appService.cartObj);
    }
    // if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  quantity_add(item) {
    if(item.quantity < item.quantity_temp) // nếu số lượng trả nhở hơn số lượng mua
    item.quantity++;
    this.calculateTotalCart();
    // this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity + 1;
    // this.appService.cartObj.price_totol = this.appService.cartObj.price_totol + parseInt(item.product.price);
    // this.appService.preparePromotion(this.appService.cartObj);
    // if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  removeProduct(i, item) {
    item.quantity = 0;
    this.calculateTotalCart();
    // this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity - item.quantity;
    // this.appService.cartObj.price_totol = this.appService.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    // this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;
    // // this.customer_pay = this.appService.cartObj.price_totol;
    // // cập nhật tổng giá trị của Desk
    // if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
    // console.log("item2", this.appService.cartObj.cart);
  }
  calculateTotalCart(){
    this.saleSelectedItem.returnProduct = 0
    this.saleSelectedItem.returnProduct_price = 0
    this.cartDetail.forEach(element => {
      this.saleSelectedItem.returnProduct += element.quantity_temp - element.quantity;
      this.saleSelectedItem.returnProduct_price += (element.quantity_temp - element.quantity) * element.price;
    });
    
  }

}
