<form class="">
    <div class="p-2">
        <div class="modal-header p-2">
            <h5 class="modal-title">{{data.title}}</h5>
        </div>
        <div class="modal-body p-2 mt-3 mb-3">

            {{data.msg}}
            <div *ngIf="data.img != undefined && data.img != ''">
                <img [src]="data.img" alt="">
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer justify-content-around bg-primary-50">
        <button (click)="okClick()" class="btn btn-primary ">
            {{data.ok != null ? data.ok : 'Xác nhận' }}
        </button>
        <button (click)="cancelClick()" class="btn">
            Bỏ qua
        </button>
    </div> -->
</form>
<mat-dialog-actions align="end">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="okClick()">
        <span class="btn-label"><span class="material-icons">done</span></span>{{data.ok != null ? data.ok : 'Xác nhận' }}
    </button>
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="cancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>