import { Ng2ImgMaxService } from 'ng2-img-max';
import { CustomerService } from './../../_services/customer.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Customer } from '../../model/customer';
import { CategoryCustomerService } from '../../_services/category-customer.service';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';

declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;


  
  isLoading = false;
  // customers = [];
  customers_backup: any;

  // for add
  customer = new Customer(environment.customer_type);
  // for update
  categorys: any
  customerSelected: any;
  isDeleted: boolean;
  customerSelected_file: File = null;
  customerSelected_imageUrl: any = environment.customer_avatar;

  // for create
  CodeType = 'mts';
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.customer_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;


  //for paging
  filter = {
    type: environment.customer_type,
    deleted: "0",
    name: "",
    category_code: "",
  }

  sorter = {
    sortStr: this.appService.sort_customer[0].code,
  }
  
  constructor(
    public customerService: CustomerService,
    public categoryCustomerService: CategoryCustomerService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public ioService: IoService,
  ) {
    this.customerSelected = this.customer;


  }

  ngOnInit() {
    this.loadCustomer();
    this.loadCategory();
  }
  ngAfterViewInit() {

    // $("#form-add-customer").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
  }

  async loadCustomer() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.customerService.loadCustomer(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.searching = false;
        if (response) {
          this.appService.customers = response.data;
          // this.customers_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.appService.customers.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        this.searching = false;
        console.error(error);
      })


  }
  loadCategory() {
    let filter = {
      type: environment.customer_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })

  }
  insertCustomer() {

    if (this.customer.name.trim() == "") {
      this.toastr.error("", "Vui lòng nhập tên");
      return false;
    }
    if (this.file !== null) {
      this.customer.image_url = environment.customer_type + "/" + this.customer.code + "." + this.utility.getExtensionFile(this.file);
    }

    console.log(this.customer);
    if (true) {
      this.isLoading = true;
      this.customerService.insertCustomer(this.customer).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.loadCustomer();

            this.customer = new Customer(environment.customer_type);
            $("#add-customer-modal").modal('hide');

          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
      this.uploadAvatar(this.file, this.customer.code);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null) {
      return;
    }
    let param = {
      des: environment.customer_type,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);

      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  popup_customer_add(event) {
    console.log(event);
    console.log(event.target.id);
    $("#" + event.target.id).trigger("blur");
    $("#add-customer-modal").modal('show');
    this.countAllcustomergory();
  }
  countAllcustomergory() {
    this.customerService.countAllCustomer(environment.customer_type).subscribe(
      response => {
        this.customer.code = environment.CUSTOMER_CODE_PREFIX + (parseInt(response.CountAllCustomer) + 1).toString();
        this.CodeTemp = environment.CUSTOMER_CODE_PREFIX + (parseInt(response.CountAllCustomer) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }
  // getTypingSearch(ev: any) {

  //   // Reset items back to all of the items
  //   this.customers = this.customers_backup;

  //   // set val to the value of the searchbar
  //   const val = ev.target.value.trim();
  //   console.log(ev.target.value.trim());
  //   // if the value is an empty string don't filter the items
  //   if (val && val.length > 2) {
  //     this.customers = this.customers.filter((item) => {
  //       return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  //     })
  //   }
  // }

  popupUpdate(item) {
    this.customerSelected = item;
    this.isDeleted = this.customerSelected.deleted == 0 ? false : true;

    this.customerSelected_imageUrl = this.appService.showImageCustomer(item.image_url);
    $("#update-customer-modal").modal('show');
  }
  update() {

    this.isLoading = true;

    this.customerSelected.deleted = this.isDeleted ? 1 : 0;

    if (this.customerSelected_file !== null) {
      this.customerSelected.image_url = environment.customer_type + "/" + this.customerSelected.code + "." + this.utility.getExtensionFile(this.customerSelected_file);
    }

    this.categorys.find(item => {
      if (this.customerSelected.category_code == item.code) {
        this.customerSelected.category_name = item.name;
        return;
      }
    });
    console.log("----customerSelected---", this.customerSelected);

    this.customerService.update(this.customerSelected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.loadCustomer();
          $("#update-customer-modal").modal('hide');
          this.toastr.success("Thành công", "Cập nhật khách hàng");
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
    this.uploadAvatar(this.customerSelected_file, this.customerSelected.code);

  }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.customer_avatar + ')';
  // }

  
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCustomer();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCustomer();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCustomer();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCustomer();
    }
  };
  // loadTotalPage() {
  //   // request to server
  //   this.customerService.loadItemSum(this.filter)
  //     .subscribe(
  //       response => {
  //         console.log('ItemListComponent load Item');
  //         console.log(response);
  //         this.totalItems = response[0].NumberOfItems;
  //         let pages_temp = Math.ceil(this.totalItems / this.viewNumsObj);
  //         this.listPage = [];
  //         for (let i = 1; i <= pages_temp; i++) {
  //           this.listPage.push({ i: i });
  //         }
  //         console.log(pages_temp);
  //         console.log(this.listPage);
  //       },
  //       error => {
  //         console.error(error);
  //       });
  // };
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCustomer();
      }),
      map(term => term === '' ? [] : this.appService.customers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }


  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.customer.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.customer.code = "";
    }
  }
  focusOutCheckCode() {
    let data = {
      code: this.customer.code,
      // type: this.type
    }
    this.customerService.checkCode(data).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if (this.file == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  async changeImageUpdate($event): Promise<void> {
    this.customerSelected_file = $event.target.files[0];
    if (this.customerSelected_file == null) {
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.customerSelected_file, 300, 300).subscribe(result => {
        this.customerSelected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.customerSelected_file);
        reader.onload = (_event) => {
          this.customerSelected_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
}
