import { CategoryCustomerService } from './_services/category-customer.service';
import { UtilityService } from './_services/utility.service';
import { SaleService } from './_services/sale.service';
import { NumberDirective } from './number-directive';
import { CustomerService } from './_services/customer.service';
import { AppService } from './_services/app.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './_services/user.service';
import { NgModule } from '@angular/core';

import { AppComponent } from './/app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LayoutModule } from './/layouts/layout.module';
import { ScriptLoaderService } from './_services/script-loader.service';
import { SaleComponent } from './pages/sale/sale.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerComponent } from './pages/customer/customer.component';
import { ToastrModule } from 'ngx-toastr';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { ProductComponent } from './pages/product/product.component';
import { CategoryService } from './_services/category.service';
import { ProductService } from './_services/product.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoryComponent } from './pages/category/category.component';
import { ReportComponent } from './pages/report/report.component';
import { Report2Component } from './pages/report2/report2.component';
import { StockinComponent } from './pages/stockin/stockin.component';
import { StockinCartComponent } from './pages/stockin/stockin-cart/stockin-cart.component';
import { Login3Component } from './pages/login-3/login-3.component';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { MembershipComponent } from './pages/membership/membership.component';
import { OrderComponent } from './pages/order/order.component';
import { SettingComponent } from './pages/setting/setting.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { CustomerCateComponent } from './pages/customer-cate/customer-cate.component';
import { PromotionService } from './_services/promotion.service';
import { PromotionAddComponent } from './pages/promotion/promotion-add/promotion-add.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModule, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MetismenuAngularModule } from "@metismenu/angular";
import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from './_services/datepicker-adapter.service';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PrinterComponent } from './components/printer/printer.component';
import { InterceptorService } from './_services/interceptor.service';
import { ErrorInterceptorService } from './_services/error-interceptor.service';
import { BrandComponent } from './pages/brand/brand.component';
import { ChartsModule } from 'ng2-charts';
import { DebtComponent } from './pages/debt/debt.component';
import { ServicedeskComponent } from './pages/servicedesk/servicedesk.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DesksaleComponent } from './components/desksale/desksale.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule, } from '@angular/material/icon';
import { DebtCustomerComponent } from './pages/debt-customer/debt-customer.component';
import { ServicedeskpriceComponent } from './components/servicedeskprice/servicedeskprice.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { UnitComponent } from './components/unit/unit.component';
import { CategorylistComponent } from './components/categorylist/categorylist.component';
import { ReportMonthComponent } from './pages/report-month/report-month.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { EmployeePickerComponent } from './components/employee-picker/employee-picker.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { ReturnsProductComponent } from './components/returns-product/returns-product.component';
import { DeskChangeComponent } from './components/desk-change/desk-change.component';
import { ProductPickerComponent } from './components/product-picker/product-picker.component';
import { DebtService } from './_services/debt.service';
import { DebtCustomerDetailComponent } from './components/debt-customer-detail/debt-customer-detail.component';
import { StudentComponent } from './pages/student/student.component';
import { TeacherComponent } from './pages/teacher/teacher.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ClasssComponent } from './pages/classs/classs.component';
import { FacultyComponent } from './pages/category/faculty/faculty.component';
import { TeacherPickerComponent } from './components/teacher-picker/teacher-picker.component';
import { SubjectsComponent } from './pages/subjects/subjects.component';
import { ScholasticComponent } from './pages/scholastic/scholastic.component';
import { TeachingAssignmentComponent } from './pages/teaching-assignment/teaching-assignment.component';
import { HomeComponent } from './pages/home/home.component';
import { CheckinComponent } from './pages/checkin/checkin.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { AssessmentSettingComponent } from './pages/assessment/assessment-setting/assessment-setting.component';
import { SchedulesComponent } from './pages/schedules/schedules.component';
import { TeachingSchedulesComponent } from './pages/teaching-schedules/teaching-schedules.component';
import { DiscussionComponent } from './pages/discussion/discussion.component';
import { CameraComponent } from './pages/camera/camera.component';
import { CheckinClassDetailComponent } from './pages/checkin-report/checkin-class-detail/checkin-class-detail.component';
import { CameraStudentComponent } from './pages/camera-student/camera-student.component';
import { CheckinReportComponent } from './pages/checkin-report/checkin-report.component';
import { NodataComponent } from './components/nodata/nodata.component';
import { ClassPickerComponent } from './components/class-picker/class-picker.component';
import { TeacherAddComponent } from './pages/teacher/teacher-add/teacher-add.component';
import { TeacherUpdateComponent } from './pages/teacher/teacher-update/teacher-update.component';
import { ArrangeDataComponent } from './components/arrange-data/arrange-data.component';
import { StudentUpdateComponent } from './pages/student/student-update/student-update.component';
import { StudentAddComponent } from './pages/student/student-add/student-add.component';
import { CheckinStudentDetailComponent } from './components/checkin-sutdent-detail/checkin-student-detail.component';
import { TeacherPicker2Component } from './components/teacher-picker2/teacher-picker2.component';
import { StudentHomeComponent } from './student/student-home/student-home.component';
import { StudentMessengerComponent } from './student/student-messenger/student-messenger.component';
import { StudentAssessmentComponent } from './student/student-assessment/student-assessment.component';
import { ComposeComponent } from './pages/discussion/compose/compose.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessengerDetailComponent } from './student/student-messenger/messenger-detail/messenger-detail.component';
import { DiscussionDetailComponent } from './pages/discussion/discussion-detail/discussion-detail.component';
import { StudentResetComponent } from './pages/student/student-reset/student-reset.component';
import { StudentRegisterImageCameraComponent } from './pages/student/student-register-image-camera/student-register-image-camera.component';
import { FinanceCategoryComponent } from './pages/finance/finance-category/finance-category.component';
import { PipeCurrencyPipe } from './components/pipe-currency.pipe';
import { FinanceAssignmentComponent } from './pages/finance/finance-assignment/finance-assignment.component';
import { FinanceReportComponent } from './pages/finance/finance-report/finance-report.component';
import { CheckinUnknownComponent } from './pages/checkin-unknown/checkin-unknown.component';
import { CheckinTeacherReportComponent } from './pages/checkin-teacher-report/checkin-teacher-report.component';
import { CheckinFacultyDetailComponent } from './pages/checkin-teacher-report/checkin-faculty-detail/checkin-faculty-detail.component';
import { CheckinUnknownDetailComponent } from './pages/checkin-unknown/checkin-unknown-detail/checkin-unknown-detail.component';
import { ApplicationComponent } from './pages/application/application.component';
import { ApplicationComposeComponent } from './pages/application/application-compose/application-compose.component';
import { ApplicationDetailComponent } from './pages/application/application-detail/application-detail.component';
import { ClassHistoryComponent } from './pages/class-history/class-history.component';
import { WorkingTimePickerComponent } from './components/working-time-picker/working-time-picker.component';
import { ClassHistoryComposeComponent } from './pages/class-history/class-history-compose/class-history-compose.component';
import { ClassHistoryDetailComponent } from './pages/class-history/class-history-detail/class-history-detail.component';
import { HealthCateComponent } from './pages/health/health-cate/health-cate.component';
import { HealthAttributeComponent } from './pages/health/health-attribute/health-attribute.component';
import { HealthRecordComponent } from './pages/health/health-record/health-record.component';
import { HealthInputComponent } from './pages/health/health-input/health-input.component';
import { HealthDoctorComponent } from './components/health-doctor/health-doctor.component';
import { HealthReportComponent } from './pages/health/health-report/health-report.component';
import { HealthClassDetailComponent } from './pages/health/health-report/health-class-detail/health-class-detail.component';
import { HealthStudentDetailComponent } from './components/health-student-detail/health-student-detail.component';
import { HealthInputClassComponent } from './pages/health/health-input-class/health-input-class.component';
import { AssessmentReportComponent } from './pages/assessment/assessment-report/assessment-report.component';
import { AssessmentStudentComponent } from './pages/assessment/assessment-student/assessment-student.component';
import { DataPickerComponent } from './components/data-picker/data-picker.component';
import { AssignmentClassPickerComponent } from './components/assignment-class-picker/assignment-class-picker.component';
import { ArrangeCategoryComponent } from './components/arrange-category/arrange-category.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AssessmentDetailComponent } from './pages/assessment/assessment-student/assessment-detail/assessment-detail.component';
import { ComposeAssessmentComponent } from './pages/discussion/compose-assessment/compose-assessment.component';
import { AssessmentPeriodComponent } from './pages/assessment/assessment-period/assessment-period.component';
import { AssessmentPeriodInputComponent } from './pages/assessment/assessment-period-input/assessment-period-input.component';
import { MealComponent } from './pages/meal/meal.component';
import { MealReportComponent } from './pages/meal/meal-report/meal-report.component';
import { MealClassDetailComponent } from './pages/meal/meal-class-detail/meal-class-detail.component';
import { MealTimeComponent } from './pages/meal/meal-time/meal-time.component';
import { MealTimePickerComponent } from './components/meal-time-picker/meal-time-picker.component';
import { HolidayPickerComponent } from './components/holiday-picker/holiday-picker.component';
import { ClassTimeComponent } from './pages/classs/class-time/class-time.component';
import { AssessmentPeriodViewComponent } from './pages/assessment/assessment-period-view/assessment-period-view.component';
import { CategorySloganComponent } from './pages/category/category-slogan/category-slogan.component';
import { ProfileUploadImgComponent } from './components/profile-upload-img/profile-upload-img.component';
import { QbscurrencyPipe } from './pipe/qbscurrency.pipe';
import { HolidayComponent } from './pages/category/holiday/holiday.component';
import { MealReportMonthComponent } from './pages/meal/meal-report-month/meal-report-month.component';
import { MealClassDetailMonthComponent } from './pages/meal/meal-class-detail-month/meal-class-detail-month.component';
import { MealStudentDetailMonthComponent } from './pages/meal/meal-student-detail-month/meal-student-detail-month.component';
import { FinanceStudentDetailComponent } from './components/finance/finance-student-detail/finance-student-detail.component';
import { FinanceStudentReportComponent } from './components/finance/finance-student-report/finance-student-report.component';
import { FinanceStudentPaymentComponent } from './components/finance/finance-student-payment/finance-student-payment.component';
import { FinancePrintInvoiceComponent } from './components/finance/finance-print-invoice/finance-print-invoice.component';
import { FinancePrintPreviewComponent } from './components/finance/finance-print-preview/finance-print-preview.component';
import { FinanceBankingComponent } from './pages/finance/finance-banking/finance-banking.component';
import { FinanceQrcodePreviewComponent } from './components/finance/finance-qrcode-preview/finance-qrcode-preview.component';
import { FinanceStudentSearchComponent } from './components/finance/finance-student-search/finance-student-search.component';
import { MealQrcodePreviewComponent } from './pages/meal/meal-qrcode-preview/meal-qrcode-preview.component';
import { EquipmentCateComponent } from './pages/equipment/equipment-cate/equipment-cate.component';
import { EquipmentComponent } from './pages/equipment/equipment/equipment.component';
import { TeacherPickerMultiComponent } from './pages/teacher/teacher-picker-multi/teacher-picker-multi.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { SchedulesDetailComponent } from './pages/teaching-schedules/schedules-detail/schedules-detail.component';
import { SchedulesDailyComponent } from './pages/teaching-schedules/schedules-daily/schedules-daily.component';
import { SchedulesReportComponent } from './pages/teaching-schedules/schedules-report/schedules-report.component';
import { SchedulesReportUpdateComponent } from './pages/teaching-schedules/schedules-report-update/schedules-report-update.component';
import { KhoicosoComponent } from './pages/category/khoicoso/khoicoso.component';
import { OnlineSchduleComponent } from './pages/category/online-schdule/online-schdule.component';
import { CategoryCheckinTimeComponent } from './pages/category/category-checkin-time/category-checkin-time.component';
import { OnlineAddComponent } from './pages/category/online-add/online-add.component';
import { OnlineUpdateComponent } from './pages/category/online-update/online-update.component';
import { TaskComponent } from './pages/task/task.component';
import { MatChipsModule } from '@angular/material/chips';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CheckinTimelineComponent } from './components/checkin-timeline/checkin-timeline.component';
import { PopupGenSchdeduleDetailComponent } from './pages/teaching-schedules/popup-gen-schdedule-detail/popup-gen-schdedule-detail.component';
import { CompScheduleTeacherComponent } from './pages/teaching-schedules/comp-schedule-teacher/comp-schedule-teacher.component';
import { PopupEditScheduleDetailComponent } from './pages/teaching-schedules/popup-edit-schedule-detail/popup-edit-schedule-detail.component';
import { PopupCopyNextScheduleDetailComponent } from './pages/teaching-schedules/popup-copy-next-schedule-detail/popup-copy-next-schedule-detail.component';
import { SchedulesMealComponent } from './pages/schedules/schedules-meal/schedules-meal.component';
import { SchedulesMealAddComponent } from './pages/schedules/schedules-meal-add/schedules-meal-add.component';
import { ClassDocumentComponent } from './pages/classs/class-document/class-document.component';
import { SchedulesDocumentComponent } from './pages/teaching-schedules/schedules-document/schedules-document.component';
import { TeacherDocumentComponent } from './pages/teacher/teacher-document/teacher-document.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { SafePipe } from './components/safe.pipe';
import { SchedulesStaticComponent } from './pages/schedules/schedules-static/schedules-static.component';
import { SchedulesStaticAddComponent } from './pages/schedules/schedules-static-add/schedules-static-add.component';
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    SaleComponent,
    CustomerComponent,
    WarehouseComponent,
    ProductComponent,
    NumberDirective,
    CategoryComponent,
    ReportComponent,
    Report2Component,
    StockinComponent,
    StockinCartComponent,
    Login3Component,
    MembershipComponent,
    OrderComponent,
    SettingComponent,
    PromotionComponent,
    CustomerCateComponent,
    PromotionAddComponent,
    PrinterComponent,
    BrandComponent,
    DebtComponent,
    ServicedeskComponent,
    DesksaleComponent,
    DebtCustomerComponent,
    ServicedeskpriceComponent,
    UnitComponent,
    CategorylistComponent,
    ReportMonthComponent,
    MonthPickerComponent,
    InventoryComponent,
    EmployeePickerComponent,
    InventoryListComponent,
    ReturnsProductComponent,
    DeskChangeComponent,
    ProductPickerComponent,
    DebtCustomerDetailComponent,
    StudentComponent,
    TeacherComponent,
    SettingsComponent,
    ClasssComponent,
    FacultyComponent,
    TeacherPickerComponent,
    SubjectsComponent,
    ScholasticComponent,
    TeachingAssignmentComponent,
    HomeComponent,
    CheckinComponent,
    AssessmentComponent,
    AssessmentSettingComponent,
    SchedulesComponent,
    TeachingSchedulesComponent,
    DiscussionComponent,
    CameraComponent,
    CheckinClassDetailComponent,
    CameraStudentComponent,
    CheckinReportComponent,
    NodataComponent,
    ClassPickerComponent,
    TeacherAddComponent,
    TeacherUpdateComponent,
    ArrangeDataComponent,
    StudentAddComponent,
    StudentUpdateComponent,
    CheckinStudentDetailComponent,
    TeacherPicker2Component,
    StudentHomeComponent,
    StudentMessengerComponent,
    StudentAssessmentComponent,
    ComposeComponent,
    ConfirmDialogComponent,
    MessengerDetailComponent,
    DiscussionDetailComponent,
    StudentResetComponent,
    StudentRegisterImageCameraComponent,
    FinanceCategoryComponent,
    PipeCurrencyPipe,
    FinanceAssignmentComponent,
    FinanceReportComponent,
    CheckinUnknownComponent,
    CheckinTeacherReportComponent,
    CheckinFacultyDetailComponent,
    CheckinUnknownDetailComponent,
    ApplicationComponent,
    ApplicationComposeComponent,
    ApplicationDetailComponent,
    ClassHistoryComponent,
    CategoryCheckinTimeComponent,
    WorkingTimePickerComponent,
    ClassHistoryComposeComponent,
    ClassHistoryDetailComponent,
    HealthCateComponent,
    HealthAttributeComponent,
    HealthRecordComponent,
    HealthInputComponent,
    HealthDoctorComponent,
    HealthReportComponent,
    HealthClassDetailComponent,
    HealthStudentDetailComponent,
    HealthInputClassComponent,
    AssessmentReportComponent,
    AssessmentStudentComponent,
    DataPickerComponent,
    AssignmentClassPickerComponent,
    ArrangeCategoryComponent,
    AssessmentDetailComponent,
    ComposeAssessmentComponent,
    AssessmentPeriodComponent,
    AssessmentPeriodInputComponent,
    MealComponent,
    MealReportComponent,
    MealClassDetailComponent,
    MealTimeComponent,
    MealTimePickerComponent,
    ClassTimeComponent,
    AssessmentPeriodViewComponent,
    CategorySloganComponent,
    ProfileUploadImgComponent,
    QbscurrencyPipe,
    HolidayComponent,
    HolidayPickerComponent,
    MealReportMonthComponent,
    MealClassDetailMonthComponent,
    MealStudentDetailMonthComponent,
    FinanceStudentDetailComponent,
    FinanceStudentReportComponent,
    FinanceStudentPaymentComponent,
    FinancePrintInvoiceComponent,
    FinancePrintPreviewComponent,
    FinanceBankingComponent,
    FinanceQrcodePreviewComponent,
    FinanceStudentSearchComponent,
    MealQrcodePreviewComponent,
    EquipmentCateComponent,
    EquipmentComponent,
    TeacherPickerMultiComponent,
    ListViewComponent,
    SchedulesDetailComponent,
    SchedulesDailyComponent,
    SchedulesReportComponent,
    SchedulesReportUpdateComponent,
    KhoicosoComponent,
    OnlineSchduleComponent,
    OnlineAddComponent,
    OnlineUpdateComponent,
    TaskComponent,
    SearchableSelectComponent,
    ProgressBarComponent,
    CheckinTimelineComponent,
    PopupGenSchdeduleDetailComponent,
    CompScheduleTeacherComponent,
    PopupEditScheduleDetailComponent,
    PopupCopyNextScheduleDetailComponent,
    SchedulesMealComponent,
    SchedulesMealAddComponent,
    ClassDocumentComponent,
    SchedulesDocumentComponent,
    TeacherDocumentComponent,
    FileViewerComponent,
    SafePipe,
    SchedulesStaticComponent,
    SchedulesStaticAddComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgbModule,
    // NgbModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // 3. Initialize
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule, // firestore
    // AngularFireAuthModule, // auth
    // AngularFireStorageModule // storage
    MetismenuAngularModule,
    Ng2ImgMaxModule,
    ChartsModule,
    DragDropModule,
    MatBadgeModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule
  ],
  providers: [
    ScriptLoaderService,
    UserService,
    CustomerService,
    PromotionService,
    ProductService,
    CategoryService,
    CategoryCustomerService,
    SaleService,
    DebtService,
    UtilityService,
    HttpClient,
    AppService,
    NgbActiveModal,
    // SharedService,
    // NgbModalConfig, NgbModal,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
