import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';
import { ComposeComponent } from './compose/compose.component';
import { DiscussionDetailComponent } from './discussion-detail/discussion-detail.component';
import { ComposeAssessmentComponent } from './compose-assessment/compose-assessment.component';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.css']
})
export class DiscussionComponent implements OnInit {
  categorys: any; // lớp
  checkboxAllButton = true; // mặc định chọn all
  discussion = {
    recipient: '',
    title: '',
    message: '',
  };
  isLoading = false;

  //for data
  messengers = [];
  filter = {
    title: "",
    date_from: "01/01/2023",
    date_to: "31/12/2025",
    message: "",
    user_code: "",
    status: "",
    type: environment.message_type_msg,
  }
  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "edu_messenger.created_date DESC",
  }
  //for search
  searching = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public studentService: StudentService,
    public messengerService: MessengerService,
    private toastr: ToastrService,
    public appService: AppService,
    public dialog: MatDialog,

  ) {
    this.discussion.message = this.appService.currentUser.school_name;
    this.appService.page_title = "Liên lạc, thông báo";
    this.loadMessengers();
  }

  ngOnInit(): void {
  }

  loadMessengers() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {

      this.messengerService.getMessenger(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {

            response.data.forEach(element => {
              // Camera
              if (element.checkin_type_status == 0) { // điểm danh thủ công
                element.checkin_status_temp = false
              } else if (element.checkin_type_status > 0) {// điểm danh camera AI
                element.checkin_status_temp = true
              } else {
                element.checkin_status_temp = false;
              }
              // change class
              element.changeClass_temp = false;
            });

            // // đăng ký điểm danh camera
            // if(this.action_mode == "camera"){

            // }

            this.messengers = response.data;

            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.messengers.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;


          }
        },
        error => {

        })
    }
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
    }
    this.loadStudent();
  };
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadMessengers();
  }

  popup_compose() {
    // mở popup
    const dialogRef = this.dialog.open(ComposeComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadMessengers();
        this.toastr.success("", "Gửi tin thành công");
      }
    });
  }
  popup_compose_assessment() {
    // mở popup
    const dialogRef = this.dialog.open(ComposeAssessmentComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadMessengers();
        this.toastr.success("", "Gửi tin thành công");
      }
    });
  }
  popupDetail(item) {
    console.log(item);
    // mở popup
    const dialogRef = this.dialog.open(DiscussionDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(typeof result != "undefined" && result.msg != '')
      this.toastr.success("",result.msg);
      if(typeof result != "undefined" && result.code != 'ok'){
        //reload
        this.loadMessengers();
      }
      console.log("Đóng");
    });
  }


}
