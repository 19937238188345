// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.qbs.edu.vn:3000",
  // apiUrl: "http://localhost:3000",
  apiEndpoint: "/v1/", 
  apiEndpoint_upload: "/upload/",
  currentUser: "curerntfsds",
  scholasticSelected: "scholasticSelected4244",
  hockySelected: "hockySelected1923",
  //
  teacher_avatar: "./assets/img/person.svg",
  student_avatar: "./assets/img/icons/student.png",
  image_frame: "./assets/img/icons/image.png",
  student_girl_avatar: "./assets/img/icons/student-girl.png",
  take_a_photo_avatar: "./assets/img/icons/take-a-photo.png",
  TEACHER_CODE_PREFIX: "GV",
  STUDENT_CODE_PREFIX: "HS",
  TKB_CODE_PREFIX: "TKB",

  listCart: "listCartdss",
  deskList: "deskListdsrr",
  deskListPrice: "deskListPricessrr",
  cartIndex: "cartIndexfgfg",
  deskIndex: "deskIndexlge",
  importCart: "imapdortCsrtdss",
  inventoryCart: "inventoryCarttCsrtdsss",
  display_type: "display_typexfgfg",
  CATEGORY_CODE_PREFIX: "DM",
  BRAND_CODE_PREFIX: "BRAND",
  CUSTOMER_CODE_PREFIX: "KH",
  CUSTOMER_NAME_PREFIX: "Khách lẻ",
  CUSTOMER_CATE_CODE_PREFIX: "NKH",
  CUSTOMER_CATE_NAME_PREFIX: "Thường",
  product_avatar: "./assets/img/picture-product.svg",
  customer_avatar: "./assets/img/person.svg",
  category_avatar: "./assets/img/icons/file-and-folder.png",
  attribute_avatar: "./assets/img/icons/tag.png",
  health_type: "huyhx2Pdro",
  assessment_priod_type_thcs_name: "huyhx2assessment_priod_type_thcs_namePdro",
  assessment_priod_type_thcs_code: "huyhxcode2assessment_priod_type_thcs_namePdro",
  assessment_priod_type_th_name: "huyhx2assessment_priod_type_thcs_namePdroth",
  assessment_priod_type_th_code: "huyhxcode2assessment_priod_type_thcs_namePdroth",
  DAY_REPORT_DEFAULT: 15,
  MONTH_REPORT_DEFAULT: 12,
  DAY_NUM_DEFAULT: 7,
  SALE_STATUS_ALL: -1, // tất cả đơn hàng
  SALE_STATUS_DONE: 1, // hoàn thành đơn hàng
  SALE_STATUS_ORDERING: 0, // đặt hàng đơn hàng
  SALE_STATUS_DELETED: 3, // đã hủy đơn hàng
  SALE_STATUS_TAKING: 4, // đang xử lý

  SALES_TYPE : 0,
  SALES_IMPORT_TYPE : 1,
  SALES_INVENTORY_TYPE : 2,

  mau_nhap_hoc_sinh: "io/downloadStudentTemplate",
  mau_nhap_giao_vien: "io/downloadTeacherTemplate",
  // brand: "brand",
  product: "product",
  teacher: "teacher",
  student: "student",
  temp_gg: "tempgg",
  message: "message",
  class_history: "class_history",
  message_type_msg: "msg",
  message_type_class_history: "class_history",
  // cate: "cate",
  brand_type: "brand",
  customer_type: "customer",

  NKH_CATEGORY_CODE_PREFIX : 'NKH',
  DEFAULT_CUS_CATEGORY : {
    'name': "Khách lẻ",
    'code': 'NKH'
  },

  cate_type: "cate",// qbss
  cateType_class: "class", //qbsedu
  cateType_health: "health", //qbsedu
  cateType_equipment: "equipment", //qbsedu
  cateType_equipment_cate: "equipment_cate", //qbsedu
  cateType_assessment_period: "assessment_period", //qbsedu
  cateType_health_attribute: "health_attribute", //qbsedu
  cateType_health_doctor: "health_doctor", //qbsedu
  cateType_faculty: "faculty", //qbsedu
  cateType_checkin_time: "checkin_time", //lịch làm việc
  cateType_meal_schedule: "meal_schedule", //lịch làm việc
  cateType_task: "task", //lịch làm việc
  cateType_task_result: "task_result", //lịch làm việc
  cateType_online_schudule: "online_schudule", //lịch làm việc
  cateType_checkin_time_holiday: "checkin_time_holiday", // ngày nghỉ của lịch làm việc
  cateType_online_holiday: "online_holiday", // ngày nghỉ của lịch làm việc
  cateType_online_class: "online_class", //qbsedu
  cateType_meal_time: "meal_time", //qbsedu
  cateType_holiday: "holiday", //qbsedu
  cateType_meal_holiday: "meal_holiday", //qbsedu
  cateType_class_time: "class_time", //qbsedu
  cateType_class_document: "class_document", //qbsedu
  cateType_class_time_holiday: "class_time_holiday", //qbsedu
  cateType_class_gvcn_more: "class_gvcn", //qbsedu
  cateType_assignment_class_subject: "class_subject", //qbsedu
  cateType_subjects: "subjects", //qbsedu
  cateType_scholastic: "scholastic", //năm học
  cateType_schedules: "schedules", //năm học
  cateType_schedules_document: "schedules_document", //năm học
  cateType_schedules_static: "schedules_static", //lịch học cố định
  cateType_teacher_document: "teacher_document", //năm học
  cateType_finance: "finance", //danh muc thu chi
  cateType_slogan: "slogan", //qbsedu
  cateType_khoicoso: "khoicoso", //qbsedu
  


  firebaseConfig: {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_SENDER_ID",
    appId: "<YOUR_APP_ID>"
  }
};



// justify-content-between align-items-center

