<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0 d-flex justify-content-start align-items-center">

            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập tên" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 150px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Xếp theo</mat-label>
                <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="loadCategory()" name="sorter">

                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="appService.currentUser.caps.length > 1" appearance="fill" style="width: 150px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Cấp học</mat-label>
                <mat-select [(ngModel)]="filter.subject_cap" (selectionChange)="loadCategory()" name="sorter">
                    <mat-option [value]="">
                        Cấp học
                    </mat-option>
                    <mat-option *ngFor="let item of appService.currentUser.caps" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>


        <div class="col p-0">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_mode('assignment_class')">
                        <span class="btn-label"><span class="material-icons">
                                class
                            </span></span>Gán môn học cho lớp
                    </button>
                </div>
                <div class="p-2">
                    <div ngbDropdown class="d-inline-block">
                        <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Thêm</button> -->

                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><span class="material-icons"> menu </span></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="openSaveArrange()" class="text-primary"><span
                                    class="material-icons mr-2">
                                    sort_by_alpha
                                </span>
                                Sắp xếp thứ tự</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="action_mode =='assignment_class'" class=" align-items-center  d-flex justify-content-between ">
        <div>
            <span class=" text-primary font-weight-bold ml-2">Gán/Khai báo môn học cho lớp</span>

        </div>
        <div>
            <div class="d-flex align-items-center  p-2">
                <span class="mr-2">Chọn môn sau đó chọn lớp</span>
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="processChangeAssignment_class()">
                    <span class="btn-label"><span class="material-icons">fact_check</span></span>Chọn lớp
                </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                    <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                </button>
            </div>

        </div>
    </div>


    <div class="table-responsive row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th style="width: 100px;text-align: center;">Cấp học</th>
                    <th style="width: 200px;text-align: center;">Tên</th>
                    <th *ngIf="action_mode ==''">Loại môn</th>
                    <th *ngIf="action_mode ==''">Kiểu điểm</th>
                    <th *ngIf="action_mode ==''">Hệ số</th>
                    <th *ngIf="action_mode ==''" style="width: 60px; text-align: center;">HK I
                        <div style="font-size: small;">Tiết/tuần</div>
                    </th>
                    <th *ngIf="action_mode ==''" style="width: 60px; text-align: center;">HK II
                        <div style="font-size: small;">Tiết/tuần</div>
                    </th>
                    <th *ngIf="action_mode =='assignment_class'">Lớp được khai báo</th>

                    <th *ngIf="action_mode ==''" style="width: 90px;" class="text-center"></th>
                    <th *ngIf="action_mode =='assignment_class'" class="text-center" style="width: 90px;">
                        <label class="checkbox myHeader checkbox-warning check-single">
                            <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                            <span class="input-span"></span>
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <!-- <span style="position: absolute; left: 30px;
                line-height: 20px;">{{app.getUrlImage(item.image_url)}}</span> -->
                        <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)">
                            <!-- <i class="ti ti-folder font-20"></i> -->
                            <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
                        </a>
                    </td>
                    <td class="text-center">
                        <div style="font-size: 18px;">{{item.subject_cap}}</div>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div [ngClass]="{'text-muted': true}" style="font-size: 13px;">
                            {{item.code}}
                        </div>
                    </td>
                    <td *ngIf="action_mode ==''">
                        <select class="custom-select" data-width="200px" [(ngModel)]="item.subject_type" [name]="item.id + 'subject_type'" [id]="item.id + 'subject_type'">
                            <option [ngValue]="item.code" *ngFor="let item of appService.subject_type_list">
                                {{item.value}}</option>
                        </select>
                    </td>
                    <td *ngIf="action_mode ==''">
                        <select class="custom-select" data-width="200px" [(ngModel)]="item.subject_type_mark" [name]="item.id + 'subject_type_mark'" [id]="item.id + 'subject_type_mark'">
                            <option [ngValue]="item.code" *ngFor="let item of appService.subject_type_mark_list">
                                {{item.value}}</option>
                        </select>
                    </td>
                    <!-- hệ số -->
                    <td *ngIf="action_mode ==''">
                        <select class="custom-select" data-width="200px" [(ngModel)]="item.subject_factor" [name]="item.id + 'subject_factor'" [id]="item.id + 'subject_factor'">
                            <option [ngValue]="item.code" *ngFor="let item of appService.subject_factor_list">
                                {{item.value}}</option>
                        </select>
                    </td>
                    <th *ngIf="action_mode ==''" style="text-align: center;">{{item.subject_lesson_w_i}}</th>
                    <th *ngIf="action_mode ==''" style="text-align: center;">{{item.subject_lesson_w_ii}}</th>
                    <!-- // hiển thị chi tiết phân công -->
                    <td *ngIf="action_mode =='assignment_class'">

                        <span *ngFor="let jtem of item.assignment_class_names" class="mr-1 mb-1 badge badge-default ">
                            {{jtem}}
                        </span>
                    </td>
                    <!-- // hiển thị chi tiết phân công -->
                    <td *ngIf="action_mode ==''" style="width: 90px;" class="text-center">
                        <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
                    </td>
                    <td *ngIf="action_mode =='assignment_class'" class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox" [(ngModel)]="item.selected_temp">
                            <span class="input-span"></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới môn học</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>

                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Tên</mat-label>
                    <input matInput [(ngModel)]="cate.name" placeholder="Tên" name="name" required maxlength="100">
                </mat-form-field>


                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Mã môn</mat-label>
                    <input matInput [(ngModel)]="cate.code" placeholder="Mã" name="code" maxlength="30" (keydown.space)="$event.preventDefault();">
                </mat-form-field>

                <mat-form-field appearance="fill" class=" mb-3">
                    <mat-label>Kiểu điểm/đánh giá</mat-label>
                    <mat-select [(ngModel)]="cate.subject_type_mark" name="subject_type_mark" required>
                        <mat-option *ngFor="let item of appService.subject_type_mark_list" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="appService.currentUser.caps.length > 1" appearance="fill" class=" mb-3">
                    <mat-label>Cấp học</mat-label>
                    <mat-select [(ngModel)]="cate.subject_cap" name="subject_cap" required>
                        <mat-option *ngFor="let item of appService.currentUser.caps" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Hệ số</mat-label>
                    <input matInput [(ngModel)]="cate.subject_factor" placeholder="Hệ số" name="subject_factor" type="number" maxlength="2">
                </mat-form-field>
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>HK I Số tiết/tuần</mat-label>
                    <input matInput [(ngModel)]="cate.subject_lesson_w_i" placeholder="HK I Số tiết/tuần" name="subject_lesson_w_i" type="number" maxlength="2">
                </mat-form-field>
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>HK II Số tiết/tuần</mat-label>
                    <input matInput [(ngModel)]="cate.subject_lesson_w_ii" placeholder="HK II Số tiết/tuần" name="subject_lesson_w_ii" type="number" maxlength="2">
                </mat-form-field>



            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>

            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật môn học học</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+categoryselected_imageUrl+')'">
                            <!-- <div class="file-input" [style.background-image]="categoryselected_imageUrl"> -->
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile_update" name="uploadFile" (change)="changeImageUpdate($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>




                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Mã môn</mat-label>
                    <input matInput [(ngModel)]="categoryselected.code" placeholder="Mã" name="code" readonly>
                </mat-form-field>

                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Tên</mat-label>
                    <input matInput [(ngModel)]="categoryselected.name" placeholder="Tên" name="name" required maxlength="100">
                </mat-form-field>

                <mat-form-field appearance="fill" class=" mb-3">
                    <mat-label>Kiểu điểm/đánh giá</mat-label>
                    <mat-select [(ngModel)]="categoryselected.subject_type_mark" name="subject_type_mark" required>
                        <mat-option *ngFor="let item of appService.subject_type_mark_list" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="appService.currentUser.caps.length > 1" appearance="fill" class=" mb-3">
                    <mat-label>Cấp học</mat-label>
                    <mat-select [(ngModel)]="categoryselected.subject_cap" name="subject_cap" required>
                        <mat-option *ngFor="let item of appService.currentUser.caps" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>Hệ số</mat-label>
                    <input matInput [(ngModel)]="categoryselected.subject_factor" placeholder="Hệ số" name="subject_factor" type="number" maxlength="2">
                </mat-form-field>
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>HK I Số tiết/tuần</mat-label>
                    <input matInput [(ngModel)]="categoryselected.subject_lesson_w_i" placeholder="HK I Số tiết/tuần" name="subject_lesson_w_i" type="number" maxlength="2">
                </mat-form-field>
                <mat-form-field class="example-full-width mb-3" appearance="fill">
                    <mat-label>HK II Số tiết/tuần</mat-label>
                    <input matInput [(ngModel)]="categoryselected.subject_lesson_w_ii" placeholder="HK II Số tiết/tuần" name="subject_lesson_w_ii" type="number" maxlength="2">
                </mat-form-field>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>


                    <!-- <i class="las la-bell"></i> -->

                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon  switch-large">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
            </div>
        </form>
    </div>
</div>