import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-student-reset',
  templateUrl: './student-reset.component.html',
  styleUrls: ['./student-reset.component.css']
})
export class StudentResetComponent implements OnInit {
  @ViewChild('resetUserForm') resetUserForm: HTMLFormElement;
  userSelected : any;
  password ='';
  password_retype ='';
  isLoading = false;
  hide = true;// for password
  constructor(
    public appService: AppService,
    public userService: UserService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<StudentResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userSelected = data;
   }

  ngOnInit(): void {
  }
  updateUser(){
    if(this.password != this.password_retype){
      this.toastr.warning("", "Mật khẩu không khớp. Vui lòng nhập lại");
      return
    }
    let user = {
      user_phone: this.userSelected.phone,
      name: this.userSelected.name,
      email: "",// chưa dùng
      password : this.password,
      id: this.appService.currentUser.id, // chưa dùng
      login_role: 'phhs',
      isResset: 1
    }
    this.isLoading = true;
    this.userService.updateUser(user).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success("Cập nhật thông tin thành công", "Số học sinh " + response.affectedRows);
          this.dialogRef.close();
        }
        else {
          this.toastr.error("", "Đã có lỗi xảy ra, vui lòng thử lại hoặc liên hệ hỗ trợ");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }
}
