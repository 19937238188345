import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qbscurrency'
})
export class PipeCurrencyPipe implements PipeTransform {

  transform(num: number) {
    if(num == null) return 0;
    var int_temp = num.toString().split('.')[0];
    var dec_temp = num.toString().split('.')[1];
    var int = int_temp.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
    
    if(dec_temp !== undefined){
      return int + ',' + dec_temp;
    }else
      return int;
  }

}
