<div class="page-content fade-in-up">
    <!-- <div class="row mb-2 mt-2 justify-content-between">
        <div class="">
            <button class="btn btn-md btn-outline-dark ml-0">Lớp: </button>
            <button class="btn btn-md btn-secondary" *ngFor="let item of classs; let i = index" (click)="selectClass(item)" [ngClass]="{'btn-primary': item.class_code == this.classSelected.class_code}">{{item.class_code}}</button>
            <div class="btn btn-md align-items-center text-warning" *ngIf="classs.length == 0">
                Thầy/cô chưa được phân công giảng dạy
            </div>
        </div>
        <div class="">
        </div>
    </div> -->
    <table style="width: 100%;">
        <tr>
            <td>
                <div class="">
                    <button class="btn btn-md btn-outline-dark ml-0">Lớp: </button>
                    <button class="btn btn-md btn-secondary" *ngFor="let item of classs; let i = index" (click)="selectClass(item)" [ngClass]="{'btn-primary': item.class_code == this.classSelected.class_code}">{{item.class_code}}</button>
                    <div class="btn btn-md align-items-center text-warning" *ngIf="classs.length == 0">
                        Thầy/cô chưa được phân công giảng dạy
                    </div>
                </div>
            </td>
            <td style="text-align: right; min-width: 185px; vertical-align: top;">
                <div class="btn-group">
                    <div class="btn" *ngFor="let item of appService.hockys; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.hockySelected, 'btn-outline-primary': item.code != this.appService.hockySelected}" (click)="hocky_change(item.code)">{{item.value}}</div>
                </div>
            </td>
        </tr>
    </table>



    <div class="row">
        <div class="ibox" style="width: 100%;">
            <div class="ibox-head">
                <div class="ibox-tools" data-toggle="tooltip" data-placement="top" title="" data-original-title="Toàn màn hình">

                    <!-- <button class="btn btn-purple" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tooltip on top">Top</button> -->

                    <a class="fullscreen-link" id="elementID"><i class="ti ti-fullscreen"></i> <span
                            class="font-18 ml-2"></span></a>
                </div>
                <div *ngIf="subjectsSelected.subjects_code !== ''" class="ibox-title">Nhập điểm cho lớp
                    <b>{{classSelected.class_code}}</b> môn <b>{{subjectsSelected.subjects_name}}</b></div>
                <div>

                    <div class="btn-highlight " [ngClass]="{'active' : isHighlight }">
                        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.isLockSetting == 1" (click)="saveAssessmentStudent()">
                            <div *ngIf="this.isLockSetting == 1">
                                <span class="btn-label"><span class="material-icons font-18">lock</span></span>
                            </div>
                            <div *ngIf="this.isLockSetting == 0">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><span
                                        class="material-icons font-18">save</span></span>
                            </div>
                            Lưu nhận xét
                        </button>
                    </div>


                    <div ngbDropdown class="d-inline-block">
                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon  ml-2" ngbDropdownToggle>
                            <span class="btn-label"><span class="material-icons font-18">list</span></span>Hành động
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [disabled]="true" ngbDropdownItem (click)="popup_export()" class="text-primary"><span class="material-icons mr-2">file_download
                                </span>
                                Tải tải file Excel</button>
                            <button [disabled]="true" ngbDropdownItem (click)="popup_import()" class="text-primary">
                                <span class="material-icons mr-2">file_upload </span>
                                Nhập file Excel
                            </button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="updateVisibleAssessment(0)" class="text-primary">
                                <span class="material-icons mr-2">visibility_off
                                </span>
                                Khóa xem điểm</button>
                            <button ngbDropdownItem (click)="updateVisibleAssessment(1)" class="text-primary">
                                <span class="material-icons mr-2">visibility
                                </span>
                                Mở xem điểm</button>
                            <button ngbDropdownItem (click)="sendAssessmentNotification()" class="text-primary">
                                <span class="material-icons mr-2">send
                                </span>
                                Thông báo xem điểm</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="nhapNhanhNhanXet()" class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Nhập nhanh nhận xét</button>
                            <div *ngIf="thongtu == 'old'">
                                <button ngbDropdownItem (click)="nhapNhanhHanhKiem()" class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Nhập nhanh hạnh kiểm</button>
                                <button ngbDropdownItem class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Tính học lực</button>
                            </div>
                            <div *ngIf="thongtu == 'new'">
                                <button ngbDropdownItem (click)="nhapNhanhRenLuyen()" class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Nhập nhanh rèn luyện</button>
                                <button ngbDropdownItem class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Tính học học tập</button>
                            </div>
                            <button ngbDropdownItem (click)="deleteAssessmentStudent()" class="text-primary">
                                <span class="material-icons mr-2">clear
                                </span>
                                Xóa nhanh đánh giá</button>
                        </div>
                    </div>



                </div>
            </div>
            <div class="ibox-body">
                <div class="row">
                    <table class="table table-head-purple table-hover">
                        <thead class="table-primary">
                            <tr>
                                <th class="stt">TT</th>
                                <th class="name" style="width: 210px;">Học sinh</th>


                                <th *ngFor="let item of subjects; let i = index" class="readonly-color" style="width: 40px; font-size: 13px;">
                                    <span>{{item.name}}</span>
                                </th>



                                <th style="position: relative; overflow: hidden; width: 50px;">
                                    <span>Buổi vắng</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>
                                <th style="position: relative; overflow: hidden;">
                                    <div (click)="nhapNhanhNhanXet()" class="div-button">
                                        <span>Nhân xét</span>
                                        <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                            <span class="material-icons font-13 text-warning">lock</span>
                                        </a>
                                    </div>
                                </th>
                                <th *ngIf="thongtu == 'old'" class="readonly2-color">
                                    <div (click)="nhapNhanhHanhKiem()" class="div-button">
                                        <span>Hạnh kiểm</span>
                                        <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                            <span class="material-icons font-13 text-warning">lock</span>
                                        </a>
                                    </div>
                                </th>
                                <th *ngIf="thongtu == 'old'" class="readonly2-color">
                                    <!-- <div (click)="nhapNhanhHanhKiem()" class="divider-button"> -->
                                    <span>Học lực</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                    <!-- </div> -->
                                </th>
                                <th *ngIf="thongtu == 'new'" class="readonly2-color">
                                    <div (click)="nhapNhanhRenLuyen()" class="div-button">
                                        <span>Rèn luyện</span>
                                        <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                            <span class="material-icons font-13 text-warning">lock</span>
                                        </a>
                                    </div>
                                </th>
                                <th *ngIf="thongtu == 'new'" class="readonly2-color">
                                    <div>
                                        <span>Học tập</span>
                                        <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                            <span class="material-icons font-13 text-warning">lock</span>
                                        </a>
                                    </div>
                                </th>
                                <th style="width: 40px;">
                                    <label class="checkbox checkbox-primary check-single pt-1">
                                        <input type="checkbox" [(ngModel)]="checkboxAllButton"
                                            (change)="checkboxAll($event)">
                                        <span class="input-span"></span>
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of students; let i = index">
                                <td class="stt">{{i + 1}}</td>
                                <td class="name" style="text-align: left;">
                                    <div>
                                        <span style="font-size: 18px;">
                                            <span *ngIf="item.visible == 1" class="material-icons text-primary" style="font-size: 16px;">visibility
                                            </span>
                                        <span *ngIf="item.visible == 0" class="material-icons  text-default" style="font-size: 16px;">visibility_off
                                            </span>
                                        </span>
                                        <a (click)="openDetail(item)" class="ml-1">{{item.full_name}}</a>
                                    </div>
                                    <div class="font-11 text-muted">
                                        <span>{{item.code}}</span>
                                        <span class="badge-default badge-point mr-1 ml-1"></span>
                                        <span>{{item.birthday_str}}</span>
                                    </div>
                                </td>
                                <td *ngFor="let jtem of subjects; let j = index" style="text-align: center;">
                                    {{item.list_subject_tbm_list[j]}}
                                </td>
                                <!-- Số buổi vắng -->
                                <td>
                                    <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="item.id + '-vang'" [name]="item.id + '-vang'" maxlength="3" numberOnly [(ngModel)]="item.vang_p" (ngModelChange)="onInputChange()">
                                </td>
                                <!-- Nhận xét -->
                                <td>
                                    <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="item.id + '-nhanxet'" [name]="item.id + '-nhanxet'" maxlength="500" [(ngModel)]="item.nhanxet" (ngModelChange)="onInputChange()">
                                </td>
                                <!-- Hạnh kiểm -->
                                <td *ngIf="thongtu == 'old'">
                                    <select class="custom-select" [disabled]="this.isLockSetting == 1" [(ngModel)]="item.hanhkiem" (ngModelChange)="onInputChange()">
                                        <option [ngValue]="item.code" *ngFor="let item of appService.hanhkiemList">
                                            {{item.name}}</option>
                                    </select>
                                </td>
                                <!-- Học lực -->
                                <td *ngIf="thongtu == 'old'">
                                    <select class="custom-select" [disabled]="this.isLockSetting == 1" [(ngModel)]="item.hocluc" (ngModelChange)="onInputChange()">
                                        <option [ngValue]="item.code" *ngFor="let item of appService.hoclucList">
                                            {{item.name}}</option>
                                    </select>
                                </td>
                                <!-- Rèn luyện -->
                                <td *ngIf="thongtu == 'new'">
                                    <select class="custom-select" [disabled]="this.isLockSetting == 1" [(ngModel)]="item.renluyen" (ngModelChange)="onInputChange()">
                                        <option [ngValue]="item.code" *ngFor="let item of appService.renluyenList">
                                            {{item.name}}</option>
                                    </select>
                                </td>
                                <!-- Học tập -->
                                <td *ngIf="thongtu == 'new'">
                                    <select class="custom-select" [disabled]="this.isLockSetting == 1" [(ngModel)]="item.hoctap" (ngModelChange)="onInputChange()">
                                        <option [ngValue]="item.code" *ngFor="let item of appService.hanhkiemList">
                                            {{item.name}}</option>
                                    </select>
                                </td>
                                <td class="text-center">
                                    <label class="checkbox checkbox-primary check-single">
                                        <input class="mail-check" type="checkbox" [(ngModel)]="checkboxs[i]">
                                        <span class="input-span"></span>
                                    </label>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div *ngIf="students ==  undefined || students.length == 0" class="text-center text-warning m-5 p-5">
                    <img style="width: 80px;" src="assets\img\icons\finder.png" alt="">
                    <div>Thầy/Cô chọn lớp để nhập. Hoặc lớp chưa có học sinh</div>
                </div>
            </div>
            <!-- </div> -->

        </div>
    </div>



    <div class="modal fade" id="import-student-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content form-horizontal" id="form-import-student">
                <div class="modal-header p-4">
                    <h5 class="modal-title">Nhập Học sinh từ file Excel</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-4">

                    <div *ngIf="!import_result.result">
                        <div class="form-group mb-4">
                            <label class="btn btn-primary  mr-2" style="width: 100%;">
                                <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                                <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                    (change)="changeListener($event)">
                            </label>
                        </div>
                        <div class="form-group mb-4">
                            <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                            </a>
                        </div>
                        <div class="form-group mb-4">
                            <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                            </div>
                        </div>
                    </div>

                    <div *ngIf="import_result.result">
                        <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                        <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                    </div>

                </div>
                <div class="modal-footer justify-content-center bg-primary-50">

                    <div class="row align-items-center">
                        <div class="col text-center">

                            <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadStudentExcel()" [disabled]="isLoading">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                                Import</button>

                            <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>