import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-popup-gen-schdedule-detail',
  templateUrl: './popup-gen-schdedule-detail.component.html',
  styleUrls: ['./popup-gen-schdedule-detail.component.css']
})
export class PopupGenSchdeduleDetailComponent implements OnInit {
  checkin_start = ''
  checkin_end = ''
  duration = '';

  temp_weeks_update = []; //clone

  constructor(
    public appService: AppService,
    private utility: UtilityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<PopupGenSchdeduleDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public cate: any
  ) { 
    console.log(cate);
    let temp = cate.checkin_time.split("-");
    this.checkin_start = temp[0];
    this.checkin_end = temp[1];
    this.duration = this.utility.calculateDuration(this.checkin_start, this.checkin_end);
  }

  ngOnInit(): void {

    // Xử lý danh sách week
    this.temp_weeks_update = [];
    var temp_weeks = this.cate.checkin_weeks.split("w");
    console.log("temp_weeks",temp_weeks);
    console.log("appService",this.appService.weeks);
    console.log("temp_weeks_update",this.temp_weeks_update);
    this.appService.weeks.forEach((element,i) => {
        this.temp_weeks_update.push({ 'code': element.code, 'value': element.value,'checked':false },);//clone

        temp_weeks.forEach(element_w => {
            if(element_w == element.code){
              this.temp_weeks_update[i].checked = true;//clone and false default
            }
        });
    });

  }
  changeCheckbox(event) {
    console.log(event);
    console.log(this.appService.weeks);
  }
  checkinChanged(event){
    this.duration = this.utility.calculateDuration(this.checkin_start, this.checkin_end);
  }
  okClick(){
    let result = {
      code : '',
      checkin_start: '',
      checkin_end: '',
      checkin_weeks: ''
    }

    // let temp = this.cate.checkin_time.split("-");
    // console.log(temp[0], temp[1], this.checkin_start, this.checkin_end);
    // const check = this.utility.getTimeDiff(this.checkin_start, this.checkin_end);

    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.temp_weeks_update.forEach(element => {
        if(element.checked){
          temp_weeks.push(element.code);
        }
    });
    if(temp_weeks.length == 0){// lỗi thời gian
      this.toastr.warning("","Vui lòng chọn thứ ngày");
      return;
    }


    if(this.utility.getTimeDiff(this.checkin_start, this.checkin_end) <= 0){// lỗi thời gian
      this.toastr.warning("","Thời gian không hợp lệ");
      return;
    }
    
    result.code = 'ok';
    result.checkin_start =  this.checkin_start;
    result.checkin_end =  this.checkin_end;
    result.checkin_weeks = temp_weeks.join("w");
    this.dialogRef.close(result);
  }
  cancelClick() {
    this.dialogRef.close();
  }

}
