<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">
        <div style="font-size: large;">Soạn đơn nghỉ phép</div>

        <div class="row mb-2 mt-2 justify-content-between">
            <div class="col-6 p-0 d-flex justify-content-start align-items-center">

            </div>

            <div class="col-6  p-0 d-flex justify-content-end align-items-center">
                <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
                    <mat-label>Ngày báo cáo</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>

        </div>





        <div class="mb-2">Số ngày nghỉ: {{selectedDateList.length}}</div>
        <div class="cont-checkbox" *ngFor="let item of selectedDateList; index as i">
            <div class="info">{{ item }}</div>
            <button class="btn btn-sm btn-danger btn-icon-only btn-circle  btn-thick" (click)="removeDate(i)">
                <span class="material-icons"> close </span>
            </button>
        </div>

        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussion.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Tiêu đề</mat-label>
            <input matInput [(ngModel)]="data.title" name="title" maxlength="100" required placeholder="Tiêu đề">
        </mat-form-field>

        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Lý do nghỉ</mat-label>
            <textarea matInput [(ngModel)]="data.content" rows="5" name="messenger" required maxlength="1000" minlength="5" placeholder="Nội dung"></textarea>
        </mat-form-field>

        <!-- <div class="filettach-upload mb-3">
            Đính kèm file:
            <input type="file" accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
        </div> -->

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-between ">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.sendMessengerForm.invalid" (click)="confirmSend()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                        send
                        </span></span>
                    Gửi
                </button>
            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>