import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodata',
  templateUrl: './nodata.component.html',
  styleUrls: ['./nodata.component.css']
})
export class NodataComponent implements OnInit {
  @Input("msg") msg: string = "";
  @Input("img") img: string = "";
  @Input("myclass") myclass: string = "";
  constructor() {
    console.log(this.msg);
    if(this.msg == '') this.msg = "Không tìm thấy dữ liệu";
    if(this.img == '') this.img = "./assets/img/icons/finder.png";
    if(this.myclass == '') this.myclass = "";
   }

  ngOnInit(): void {
  }

}
