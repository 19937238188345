import { AuthenticationService } from './../../_services/authentication.service';
import { AppService } from './../../_services/app.service';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from './../../_services/user.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AssessmentService } from 'src/app/_services/assessment.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';

declare var $: any;

@Component({
  selector: 'app-login-3',
  templateUrl: './login-3.component.html',
})
export class Login3Component implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  hide = true;// for password
  //Form login
  user = {
    school_code: '',
    email: '',
    phone: '',
    password: '',
    login_role: 'cbgv'
  }
  // checkout-widget ="sdsdsd";
  constructor(
    public userService: UserService,
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    public assessmentService: AssessmentService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    $('body').removeClass('empty-layout');
  }


  handleChangeLoginRole(evt){
    console.log(this.user.login_role);
  }
  login() {
    if(this.user.login_role == ""){
      this.toastr.warning("", "Bạn chưa chọn vai trò người dùng");
      return;
    }

    this.isLoading = true;
    this.user.email = this.user.email.trim();
    this.user.phone = this.user.phone.trim();
    console.log(this.user);
    if (true) {
      this.authenticationService.login(this.user)
        .pipe(first())
        .subscribe({
          next: response => {
            this.isLoading = false;
            if (response.code == 'ok') {
              if(this.user.login_role == "admin"){ //
                response.data.school_name = response.data.name;// tên trường
                response.data.name = "Admin";// tên trường
              }
              this.appService.currentUser = response.data;
              this.appService.currentUser.isLoggedIn = true;
              this.appService.currentUser.khois = JSON.parse(response.data.khois); // chuyển khối về json
              if(response.data.caps !== undefined  && response.data.caps !== '')
              this.appService.currentUser.caps = JSON.parse(response.data.caps); // chuyển cấp về json
              // environment.currentUser = response.data.school_code; // mã trường
              // lưu vào storage
              localStorage.setItem(environment.currentUser, response.data.id);
              localStorage.setItem(response.data.id, JSON.stringify(response.data));

            
              this.loadScholastics_list();

              if(this.appService.currentUser.login_role == 'phhs'){
                this.router.navigate(['/student-overview']);
              }else{
                this.router.navigate(['/']);
              }
            } else {
              this.toastr.warning("", "tài khoản hoặc Mật khẩu không đúng");
            }
          },
          error: error => {
            this.isLoading = false;
            this.toastr.error("Có lỗi xảy ra khi đăng nhập", "Cảnh báo");
            console.error(error);
          }
        })
    }
  }
  async loadScholastics_list() {
    let filter = {
      name: "",
      type: environment.cateType_scholastic
    }
    let sorter = {
      sortStr: this.appService.sort_category[0].code,
    }
    let pagingObj = {
      viewNumsObj: 20,
      pageObj: 1
    };

    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          console.log('loadScholastics_list');
          console.log(response.data);
          this.appService.currentUser.scholastics_list = response.data
          // lưu vào storage
          localStorage.setItem(this.appService.currentUser.id.toString(), JSON.stringify(this.appService.currentUser));
        }
      },
      error => {
        console.error(error);
      })
  }

}
