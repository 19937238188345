export class CartService {
    id: number = 0;
    name : string = "";
    // price : number = 0;
    serviceEndTime : Date;
    serviceEndTimeNumberType : number = 0;
    serviceStartTime : Date;
    serviceStartTimeNumberType : number = 0;
    quantity : number = 0;
    quantityLable : string = "";
    amount : number = 0;
    isActive : number = 1;
    isEnable : number = 1;

    product_code : string = "";
    product_name : string = "";
    price : number = 0;
    shop_code : string = "";
    customer_code: string = "";
    customer_name: string = "";

    product: any;

    constructor(){

    }
}
