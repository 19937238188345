import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('SchoolService constructor');
  }
  updateSchoolSomeInfo(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('school/qbsedu/someinfo'), data,).map(data => { return data; });
  }
  
  getClassByTeacher(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getClassByTeacher'), data,).map(data => { return data; });
  }
  doCheckIn(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/checkin/doCheckIn'), data,).map(data => { return data; });
  }
  saveCheckInNote(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/checkin/saveCheckInNote'), data,).map(data => { return data; });
  }
  applicationCreate(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/application/create'), data,).map(data => { return data; });
  }
  applicationUpdate(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/application/update'), data,).map(data => { return data; });
  }
  applicationLoad(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('school/application/get'), data,).map(data => { return data; });
  }
  getAllClassByTeacher(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getAllClassByTeacher'), data,).map(data => { return data; });
  }
  getAllClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getAllClass'), data,).map(data => { return data; });
  }
  getSubjectsByClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getSubjectsByClass'), data,).map(data => { return data; });
  }
  getSubjectsAssignmentClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getSubjectsAssignmentClass'), data,).map(data => { return data; });
  }
  getAllSubjectsByClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getAllSubjectsByClass'), data,).map(data => { return data; });
  }
  
  reportAllSchool() {
    return this.http.get<any>(this.appService.getApiEndpoint('school/report/allschool'), {},).map(data => { return data; });
  }
  loadCloudStorage() {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/loadCloudStorage'), {},).map(data => { return data; });
  }
  reportClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/class'), data,).map(data => { return data; });
  }
  reportCheckinClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinClass'), data,).map(data => { return data; });
  }
  reportMealClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/reportMealClass'), data,).map(data => { return data; });
  }
  
  reportCheckinFaculty(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinFaculty'), data,).map(data => { return data; });
  }
  reportCheckinUnknown(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/reportcheckinUnknown'), data,).map(data => { return data; });
  }
  checkinStudentDayDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinStudentDayDetail'), data,).map(data => { return data; });
  }
  reportCheckinSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinSchool'), data,).map(data => { return data; });
  }
  reportMealSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/reportMealSchool'), data,).map(data => { return data; });
  }
  
  reportCheckinTeacherSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinTeacherSchool'), data,).map(data => { return data; });
  }
  reportCheckinClassDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinClassDetail'), data,).map(data => { return data; });
  }
  
  loadReportMealClassDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/mealClassDetail'), data,).map(data => { return data; });
  }
  loadMealMonthDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/mealMonthDetail'), data,).map(data => { return data; });
  }
  
  reportCheckinFacultyDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinFacultyDetail'), data,).map(data => { return data; });
  }
  loadReportCheckinTeacherByRange(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinTeacherByRange'), data,).map(data => { return data; });
  }
  checkinStudentTeacherDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinStudentTeacherDetail'), data,).map(data => { return data; });
  }
  reportMealMonthDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/mealMonthDetail'), data,).map(data => { return data; });
  }
  reportcheckinStudentDayDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/report/checkinStudentDayDetail'), data,).map(data => { return data; });
  }
  getMonthListScholastic(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/getMonthListScholastic'), data,).map(data => { return data; });
  }


  getListDevice(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('camera/hanet/getListDevice'), data,).map(data => { return data; });
  }
  getHistoryTransaction(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('camera/bidv/getHistoryTransaction'), data,).map(data => { return data; });
  }


}
