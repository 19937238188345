<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start">
                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm cán bộ giáo viên</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>
                <div class="m-3"></div>
                <div class="">
                    <mat-form-field appearance="fill" style="width: 150px;" class="">
                        <mat-label>Chức vụ</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.positions" (selectionChange)="category_change($event)">
                            <mat-option [value]="" [disabled]="false">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of appService.positions" [value]="item.code" [disabled]="false">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 100px;" class="">
                        <mat-label>Tổ</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                            <mat-option [value]="" [disabled]="false">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                                {{item.name}} ({{item.teacherSum}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>
                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 180px;" class="">
                        <mat-label>Sắp xếp</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)">

                            <mat-option *ngFor="let item of appService.sort_teachers" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col  p-0 d-flex justify-content-end  align-items-center">
            <div class="">
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_teacher_add()" [disabled]="!appService.checkFullPermission()">
                        <span class="btn-label"><i class="ti ti ti-plus"></i></span>Thêm mới
                    </button>
            </div>
            <div class="m-1"></div>
            <div class="">
                <div ngbDropdown class="d-inline-block">
                    <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Thêm</button> -->

                    <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
                        </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="popup_teacher_import()" class="text-primary"><span class="material-icons mr-2">
                            upload
                        </span>
                                Import</button>
                        <!-- <button ngbDropdownItem (click)="popup_teacher_export()" class="text-primary"><span class="material-icons mr-2">
                            download
                        </span>
                                Download</button> -->
                        <!-- <button ngbDropdownItem>Something else is here</button> -->
                        <button ngbDropdownItem (click)="popup_mode('working_time')" class="text-primary"><span class="material-icons mr-2">
                            date_range
                        </span>
                        Gán lịch làm việc</button>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div *ngIf="action_mode =='working_time'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-primary">
            Thực hiện gán lịch làm việc
        </div>
        <div>
            <div class="p-2">
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="processChangeWorking_time()">
                        <span class="btn-label"><span class="material-icons">fact_check</span></span>Gán khung giờ
                    </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>

    <div *ngIf="action_mode ==''" class="row align-items-center paging-cover mb-2">
        <div class="col-4  p-0">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8  p-0 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">TT</th>
                    <th>Họ và tên</th>
                    <th>Email/TK</th>
                    <th>Tổ</th>
                    <th *ngIf="action_mode ==''">Chủ nhiệm</th>
                    <th *ngIf="action_mode ==''">Giảng dạy</th>
                    <th *ngIf="action_mode =='working_time'">
                        Khung giờ
                    </th>

                    <th *ngIf="action_mode ==''"></th>
                    <th *ngIf="action_mode =='working_time'" class="text-center" style="width: 90px;">
                        <label class="checkbox myHeader checkbox-warning check-single">
                                <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                                <span class="input-span"></span>
                            </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.teachers; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                        <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                        <a class="avatar-img-list" style="width: 40px; border-radius: 50%;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageTeacher(item.image_url)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div style="font-size: 13px; font-weight: bold;">
                            <span>{{item.code}}</span>
                            <span class="material-icons font-13 text-success">
                                fiber_manual_record
                                </span>
                            <span>{{appService.getValueByCode(item.positions,appService.positions)}}</span>
                        </div>
                    </td>
                    <td>{{item.email}}</td>

                    <td>
                        <div>{{item.faculty_name}}</div>

                    </td>


                    <td *ngIf="action_mode ==''">{{item.class_name}}</td>
                    <td *ngIf="action_mode ==''">
                        <a *ngIf="item.class_codes != null && item.class_codes != ''" (click)="popup_teaching_assignment_list(item.class_codes)">Chi tiết</a>
                    </td>
                    <td *ngIf="action_mode =='working_time'">

                        <div *ngFor="let item_2 of item.checkin_time_ids; let j = index" class="my-badge-large">
                            {{item.checkin_time_names[j]}}
                            <span>{{item.checkin_times[j]}}</span>
                        </div>
                    </td>



                    <td *ngIf="action_mode ==''">
                        <button [disabled]=" !(appService.checkFullPermission() || item.code == appService.currentUser.code)" class="mr-2 btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="cập nhật giáo viên" (click)="popupUpdate(item)">
                            <span class="material-icons">
                                badge
                                </span>
                        </button>
                    </td>
                    <td *ngIf="action_mode =='working_time'" class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp">
                                <span class="input-span"></span>
                        </label>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4  p-0">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8  p-0 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>











<!-- <div class="modal fade" id="update-teacher-modal">
    <div class="modal-dialog" role="document">
      <form class="modal-content modal-content-lg form-horizontal" #teacherUpdateForm="ngForm" id="form-update-teacher"
        (ngSubmit)="updateteacher()">
        <div class="modal-header p-4">
          <h5 class="modal-title">Cập nhật cán bộ giáo viên</h5>
          <a data-dismiss="modal"><i class="ti ti-close"></i></a>
        </div>
        <div class="modal-body p-4">
          <div class="row">
            <div class="col-sm-9">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Tên</label>
                <div class="col-sm-10">
                  <input [(ngModel)]="teacherSelected.name" class="form-control" name="name" maxlength="150"
                    placeholder="Tên cán bộ giáo viên" type="text">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Mã</label>
                <div class="col-sm-10">
                  <input [(ngModel)]="teacherSelected.code" class="form-control" readonly minlength="3" maxlength="20"
                    name="code" type="text">
                </div>
              </div>
  
  
            </div>
            <div class="col">
              <div class="flexbox-b">
                <div class="image-upload">
                  <div class="file-input" [style.background-image]="'url('+teacherSelected_imageUrl+')'"> </div>
                  <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFileUpdate"
                    name="uploadFileUpdate" (change)="avatarChangeUpdateListener($event)">
                </div>
  
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="col-form-label">Giá bán</label>
                <div class="">
                  <input currencyMask [(ngModel)]="teacherSelected.price" class="form-control" name="price"
                    placeholder="Giá bán" type="text">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="col-form-label">Giá nhập</label>
                <div class="">
                  <input [(ngModel)]="teacherSelected.price_in" currencyMask class="form-control" numbersOnly
                    name="price-in" placeholder="Giá nhập" type="text">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group ">
                <label class="col-form-label">Danh mục</label>
                <div class="">
                  <select class="custom-select" [(ngModel)]="teacherSelected.category_code" name="category">
                    <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group ">
                <label class="col-form-label">Thương hiệu</label>
                <div class="">
                  <select class="custom-select" [(ngModel)]="teacherSelected.category_code_brand" name="categoryBrand">
                    <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="col-form-label">Đơn vị</label>
                <div class="">
                  <select class="custom-select" [(ngModel)]="teacherSelected.unit" name="unit">
                    <option [ngValue]="item.code" *ngFor="let item of appService.units">{{item.value}}</option>
                  </select>
                </div>
  
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class=" col-form-label">Quy cách</label>
                <input [(ngModel)]="teacherSelected.packing" class="form-control" name="packing" placeholder="Quy cách"
                  type="text">
              </div>
            </div>
          </div>
  
          <div class="form-group">
            <label class=" col-form-label">Mô tả</label>
            <div class="">
              <input [(ngModel)]="teacherSelected.description" class="form-control" name="description" placeholder="Mô tả"
                type="text">
           
            </div>
          </div>
          <div class="form-group text-center">
            <label class="checkbox checkbox-grey checkbox-primary">
              <input type="checkbox" [(ngModel)]="isDeleted" name="timeService">
              <span class="input-span"></span>Xóa
            </label>
          </div>
  
        </div>
        <div class="modal-footer justify-content-around bg-primary-50">
  
          <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon"
            [disabled]="isLoading || this.teacherUpdateForm.invalid">
            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
            Cập nhật
          </button>
  
          <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
        </div>
      </form>
    </div>
  </div> -->






<div class="modal fade" id="import-teacher-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-teacher">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập cán bộ giáo viên từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="upload()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="teaching-assignment-list">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-teacher">
            <div class="modal-header p-4">
                <h5 class="modal-title">Danh sách lớp được phân công giảng dạy</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <span class="badge badge-default
                 m-1" *ngFor="let item of teaching_assignment_list; let i = index" style="font-size: unset;">
                    {{item}}
                </span>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
        </form>
    </div>
</div>