    <div >
      <form  id="form-add-product">
        <h1 mat-dialog-title>Trả hàng</h1>
        <div class="mb-3" >
          <div class="chat-list">
            <div class="scroller">
              <div class="">
                <div class="row info-row">
                  <div class="col">
                    Mã đơn #{{saleSelectedItem.code}}
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.created_date.substring(11, 16)}} {{saleSelectedItem.dateStr}}
                  </div>
                </div>
                <div class="row info-row">
                  <div class="col">
                    Nhân viên:
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.user_name}}
                  </div>
                </div>
                <div class="row info-row" *ngIf="saleSelectedItem.status == 4">
                  <div class="col">
                    Nhân viên GH:
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.processing_user_name}}
                  </div>
                </div>
                <div class="row info-row">
                  <div class="col">
                    Khách hàng:
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.customer_name}}
                  </div>
                </div>
                <div class="row info-row">
                  <div class="col">
                    Tổng đơn:
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.price_totol | number}}
                  </div>
                </div>
                <div class="row info-row">
                  <div class="col">
                    Khuyến mãi: {{saleSelectedItem.promotion_promotion}}{{saleSelectedItem.promotion_type}}
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.price_promotion | number}}
                  </div>
                </div>
                <div class="row info-row">
                  <div class="col">
                    Tổng thanh toán:
                  </div>
                  <div class="col text-right">
                    {{saleSelectedItem.price_after_promotion | number}}
                  </div>
                </div>
                <div class="row info-row" style="background-color: lightgray;">
                  <div class="col font-weight-bold">
                    Số sản phẩm trả: {{this.saleSelectedItem.returnProduct}}
                  </div>
                  <div class="col font-weight-bold">
                    Số tiền trả lại: {{this.saleSelectedItem.returnProduct_price | number}}
                  </div>
                </div>
  
                
              
  
                <table style="width: 100%; margin-top: 10px;">
                           
                    <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                        <td style="width: 25px; text-align: center;">
                            <button
                                class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick"
                                (click)='removeProduct(i,item)'><i class="ti ti-back-right"></i></button>
                            <span class="lbl-stt">{{i+1}}</span>
                        </td>
                        <td style="width: 55%;">
                            <div style="line-height: 16px;">
                                {{item.product_name}} - <span
                                    style="color: gray; font-size: small;">{{item.price|
                                    number}}</span>
                            </div>
                        </td>
                        <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| number}}</span> </td> -->
                        <td style="text-align: center;">
                            <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                            <button
                                class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                                (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button>
                            x <span style="color: black;"> {{item.quantity}} </span>
                            <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                            <button
                                class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                                (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                        </td>
                        <td style="text-align: right; color: black;">
                            {{item.quantity * item.price | number:0}}</td>
                    </tr>
                    <tr style="background: lightgray;line-height: 35px;">
                        <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                        <td style="text-align: center;font-weight: bold;">{{saleSelectedItem.total_quantity}}
                        </td>
                        <td style="text-align: right; color: black; font-weight: bold;">
                            {{saleSelectedItem.price_totol | number}}</td>
                    </tr>
                </table>

              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-primary" (click)="onCancelClick()">Xác nhận</button>
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>
        </div>
      </form>
    </div>





