
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
@Component({
  selector: 'app-class-history-detail',
  templateUrl: './class-history-detail.component.html',
  styleUrls: ['./class-history-detail.component.css']
})
export class ClassHistoryDetailComponent implements OnInit {
  discussionSelected : any;
  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<ClassHistoryDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.discussionSelected = data;
   }

  ngOnInit(): void {
  }

}
