import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable()
export class CustomerService {

  constructor(public http: HttpClient,
    private appService : AppService) {
    console.log('UserService constructor');
  }

  insertCustomer(data){
    return this.http.post<any>(this.appService.getApiEndpoint('customer'),data,).map(data => { return data; });
  }
  countAllCustomer(type){
    return this.http.get<any>(this.appService.getApiEndpoint('customer/count/'+ type),).map(data => { return data; });
    // return this.http.get<any>(this.appService.getApiEndpoint('category/count/'+type), {},).map(data => { return data; });
  }
  loadCustomer(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('customer/get'), data,).map(data => { return data; });
  }
  checkCode(data) {
    return this.http.get<any>(this.appService.getApiEndpoint('customer/checkCode/'+data.code),).map(data => { return data; });
    // return this.http.get<any>(this.appService.getApiEndpoint('customer/checkCode'), filter,).map(data => { return data; });
  }
  // update(data){
  //   delete data["modal"];
  //   data.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('customer.php?action=UPDATE'),data,).map(data => { return data; });
  // }
  update(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('customer/code/'+data.code), data,).map(data => { return data; });
  }
  /////////////////
  
  loadCustomerLimit(filter,limit){
    filter.shop_code = this.appService.currentUser.shop_code;
    let data = {
      filter: filter,
      limit : limit
    };
    return this.http.post<any>(this.appService.getApiEndpoint('customer.php?action=GET_LIST_ACTION_LIMIT'),data,).map(data => { return data; });
  }
  
  
  
  loadItemSum(filter){
    filter.shop_code = this.appService.currentUser.shop_code;
    let data = {
      filter: filter,
    };
    return this.http.post<any>(this.appService.getApiEndpoint('customer.php?action=getItemSum'), data).map(data => { return data; });
  }

}




