<div class="m-2">
    <!-- <h4 mat-dialog-title>Install Angular</h4> -->
    <h4>Đơn vị chưa cài đặt máy in</h4>

</div>
<mat-dialog-content>

    <h4>{{data.length}}</h4>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
        <span class="btn-label"><span class="material-icons">print</span></span>In
    </button>
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="this.dialogRef.close();">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>