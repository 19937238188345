import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/checkCode'), filter,).map(data => { return data; });
  }

  loadStudent(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/get'), data,).map(data => { return data; });
  }
  loadStudentHealth(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/loadStudentHealth'), data,).map(data => { return data; });
  }
  loadStudentAssessmentPeriod(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/loadStudentAssessmentPeriod'), data,).map(data => { return data; });
  }
  loadDoCheckIn(filter, sorter, pagingObj, dateSelected) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj,
      dateSelected: dateSelected
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/loadDoCheckIn'), data,).map(data => { return data; });
  }
  loadDoCheckInMeal(filter, sorter, pagingObj, dateSelected) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj,
      dateSelected: dateSelected
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/loadDoCheckInMeal'), data,).map(data => { return data; });
  }
  loadTeachingAssignment(filter) {
    let data = {
      filter: filter,
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('student/teachingAssignment/get'), data,).map(data => { return data; });
  }
  insert(student) {
    return this.http.post<any>(this.appService.getApiEndpoint('student'), student,).map(data => { return data; });
  }
  update(student) {
    delete student["modal"];
    console.log("student.................")
    return this.http.put<any>(this.appService.getApiEndpoint('student/code/'+student.code), student,).map(data => { return data; });
  }


  saveArrange(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/saveArrange'), data).map(data => { return data; });
  }
  changeClass(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/changeClass'), data).map(data => { return data; });
  }
  saveRegisterCamera(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/saveRegisterCamera'), data).map(data => { return data; });
  }
  saveRegisterMeal(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/saveRegisterMeal'), data).map(data => { return data; });
  }
  saveReview(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/checkin/saveReview'), data).map(data => { return data; });
  }
  updateMealStart(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/updateMealStart'), data).map(data => { return data; });
  }
  updateTeachingAssignment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('student/updateTeachingAssignment'), data,).map(data => { return data; });
  }
  exportStudent(filter) {
    if(filter.class_code=='') filter.class_code = 'all';
    window.open(this.appService.getApiEndpoint('io/downloadStudent/'+this.appService.currentUser.school_code+"/"+filter.class_code) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  exportStudentAssessment(class_code,period_code,period_time) {
    period_time = period_time.split('/').join('_');
    window.open(this.appService.getApiEndpoint('io/downloadStudentAssessment/'+this.appService.currentUser.school_code+"/"+class_code+"/"+period_code+"/"+period_time) );
  }
  exportStudentHealth(class_code,healthRecordType,period_time) {
    period_time = period_time.split('/').join('_');
    window.open(this.appService.getApiEndpoint('io/downloadStudentHealth/'+this.appService.currentUser.school_code+"/"+class_code+"/"+healthRecordType+"/"+period_time) );
  }
  exportStudentInattention(cap_name,selectedDateStr) {
    if(cap_name=='') cap_name = 'all';
    selectedDateStr = selectedDateStr.split("/").join("_");
    window.open(this.appService.getApiEndpoint('io/downloadStudentInattention/'+this.appService.currentUser.school_code+"/"+selectedDateStr+"/"+cap_name) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  
  exportFinanceStudent(cap_name,finance_cate_list,status_text) {
    if(cap_name=='') cap_name = 'all';
    if(status_text=='') status_text = 'all';
    // finance_cate_list = finance_cate_list.join("_");
    if(finance_cate_list == '') finance_cate_list = 'all';
    window.open(this.appService.getApiEndpoint('io/downloadFinanceReport/'+this.appService.currentUser.school_code+"/"+cap_name+"/"+finance_cate_list+"/"+status_text) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  exportFinanceAssignment(cap_name,finance_cate_list,status_text) {
    if(cap_name=='') cap_name = 'all';
    if(status_text=='') status_text = 'all';
    window.open(this.appService.getApiEndpoint('io/downloadFinanceAssignment/'+this.appService.currentUser.school_code+"/"+cap_name+"/"+finance_cate_list+"/"+status_text) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  ///////////////////////////




  countAllStudent() {
    return this.http.post<any>(this.appService.getApiEndpoint('student/count'), {},).map(data => { return data; });
  }
  
  getTotalInfoShop(filter) {
    return this.http.get<any>(this.appService.getApiEndpoint('student/warehouse'), {},).map(data => { return data; });
  }
  
  
 
  
  importStudent(file) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_student");
    formData.append('count_import', "0");
    formData.append('school_code', this.appService.currentUser.shop_code);
    formData.append('user_id', this.appService.currentUser.id.toString());
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadStudentExcel'), formData).map(data => { return data; });
  }
  uploadAssessmentPeriod(file,class_code,cap_hoc,period_code,period_name,period_time) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_AssessmentPeriod");
    formData.append('count_import', "0");
    formData.append('class_code', class_code);
    formData.append('period_code', period_code);
    formData.append('period_name', period_name);
    formData.append('period_time', period_time);
    formData.append('cap_hoc', cap_hoc);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadAssessmentPeriodExcel'), formData).map(data => { return data; });
  }
  uploadHealthRecord(file,class_code,healthRecord_type, period_time) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_AssessmentPeriod");
    formData.append('count_import', "0");
    formData.append('class_code', class_code);
    formData.append('healthRecord_type', healthRecord_type);
    formData.append('period_time', period_time);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadHealthRecordExcel'), formData).map(data => { return data; });
  }
  uploadFinanceAssignment(file, selected_finance_cate) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_FinanceAssignment");
    formData.append('count_import', "0");
    formData.append('finance_cate_id', selected_finance_cate);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadFinanceAssignment'), formData).map(data => { return data; });
  }
  
  

}
