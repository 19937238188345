<div class="page-content fade-in-up">
    <div class="flexbox-b mb-5">
        <span class="mr-4 static-badge badge-pink"><i class="la la-pencil"></i></span>
        <div>
            <h5 class="font-strong">Article</h5>
            <div class="text-light">Adminca: Basic usage</div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 centered">
            <div class="ibox">
                <div class="ibox-body">
                    <div class="flexbox">
                        <div class="flexbox">
                            <div class="article-date mr-4 text-primary bg-primary-50">
                                <h5 class="font-strong mb-0">16</h5>
                                <span class="font-13">Feb</span>
                            </div>
                            <div class="d-inline-flex">
                                <span class="text-muted pl-3 pr-3 text-center" style="border-right:1px solid rgba(0,0,0,.1);"><i class="fa fa-heart-o d-block font-16 mb-2"></i>124</span>
                                <span class="text-muted pl-3 pr-3 text-center"><i class="fa fa-comment-o d-block font-16 mb-2"></i>56</span>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-outline-secondary btn-icon-only btn-circle btn-air mr-2"><i class="ti ti-facebook"></i></button>
                            <button class="btn btn-outline-secondary btn-icon-only btn-circle btn-air mr-2"><i class="fa fa-twitter"></i></button>
                            <button class="btn btn-outline-secondary btn-icon-only btn-circle btn-air mr-2"><i class="fa fa-dribbble"></i></button>
                            <button class="btn btn-outline-secondary btn-icon-only btn-circle btn-air"><i class="fa fa-linkedin"></i></button>
                        </div>
                    </div>
                    <h1 class="text-center my-5">Adminca: Basic usage</h1>
                    <p>Nulla vestibulum interdum imperdiet. Fusce sit amet erat quis erat rhoncus venenatis et non ipsum. Suspendisse sed velit consequat, pretium dolor vel, vestibulum massa. In tempor iaculis vehicula. <b>Duis pellentesque urna lectus</b>,
                        id lacinia eros lacinia non. Curabitur fermentum pretium erat, eu aliquet tortor tincidunt vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                    <p>
                        <span class="text-success">Fusce et quam</span> tristique, congue tellus sed, suscipit tellus. In quis placerat magna. Nunc blandit nulla id orci auctor vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                        mus. Maecenas luctus rutrum erat sit amet pulvinar. Integer sit amet mollis ante, feugiat laoreet neque. Maecenas tincidunt sed orci eu euismod. Integer a turpis vestibulum, venenatis mi id, suscipit ex. Nam iaculis
                        nulla a dictum dapibus. Vivamus volutpat convallis leo. Nam blandit justo sed sapien varius bibendum ut id nunc. In gravida, leo ac tristique fringilla, diam nunc consequat metus, eget faucibus lorem ligula quis erat.
                        Phasellus rutrum pharetra felis eget commodo.</p>
                    <div class="row">
                        <div class="col-lg-6 mb-4 mt-4">
                            <img src="./assets/img/blog/07.jpeg" alt="image" />
                        </div>
                    </div>
                    <h3 class="mb-3 mt-3">Nunc blandit nulla</h3>
                    <p><em>Donec dictum turpis nulla, ac venenatis nunc tincidunt vel. Etiam aliquam turpis sed orci varius tincidunt. Mauris quis molestie nibh. Mauris efficitur sollicitudin mauris eu lacinia.</em></p>
                    <p>Quisque efficitur ex felis, eu molestie turpis convallis nec. Cras at bibendum tortor, in dictum quam. Donec dictum turpis nulla, ac venenatis nunc tincidunt vel. Etiam aliquam turpis sed orci varius tincidunt. Mauris quis
                        molestie nibh. Mauris efficitur sollicitudin mauris eu lacinia.</p>
                    <p>Vestibulum et venenatis ligula, eu efficitur ante. Pellentesque erat nulla, efficitur dictum tempus non, commodo volutpat libero. Pellentesque sagittis metus eu enim lacinia, in tempor orci iaculis. Cras dictum aliquet
                        ex, ut placerat velit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pharetra dolor sapien, at ultrices enim interdum vitae. Sed lacinia, nisi vulputate tempor tincidunt, mauris dolor semper ligula, eu
                        commodo turpis odio nec velit. Mauris neque urna, vehicula ac nibh ut, efficitur malesuada ipsum. Nam id eros ultricies, laoreet sapien sed, lacinia mi.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                        <li>Facilisis in pretium nisl aliquet</li>
                    </ul>
                    <p>Ut pulvinar tellus mi, eget vehicula magna dictum ornare. In venenatis arcu non nibh porttitor pretium. Maecenas eu tortor ipsum. Donec posuere mattis enim at dictum. Mauris vitae lorem arcu. Pellentesque dapibus, nibh
                        vel mollis tempus, magna mauris imperdiet diam, a sagittis dolor eros sed nisl. Sed mollis convallis porta.</p>
                    <hr class="my-4">
                    <div class="flexbox">
                        <div>
                            <span class="badge badge-primary mr-2">HTML</span>
                            <span class="badge badge-pink mr-2">TRENDS</span>
                            <span class="badge badge-secondary">BOOTSTRAP</span>
                        </div>
                        <span>205 views</span>
                    </div>
                    <hr class="my-4">
                    <h5 class="font-strong mb-3"><i class="fa fa-comment-o mr-2"></i>5 comments</h5>
                    <ul class="media-list">
                        <li class="media">
                            <a class="media-img" href="javascript:;">
                                <img src="./assets/img/users/u10.jpg" alt="image" width="45" />
                            </a>
                            <div class="media-body">
                                <div class="media-heading">
                                    <a class="comment-author" href="javascript:;">Stella Obrien</a><small class="text-muted ml-2">5 days ago</small>
                                    <div class="pull-right font-13">
                                        <a class="text-muted mr-2" data-toggle="tooltip" data-original-title="Like"><i class="fa fa-heart"></i></a>
                                        <a class="text-muted" data-toggle="tooltip" data-original-title="Reply"><i class="fa fa-reply"></i></a>
                                    </div>
                                </div>
                                <p class="m-0">Aliquam nec dui fermentum, fermentum est sed, molestie ligula. Donec ullamcorper eget purus eget vehicula. Morbi risus est, rhoncus vel faucibus eget,</p>
                            </div>
                        </li>
                        <li class="media">
                            <a class="media-img" href="javascript:;">
                                <img src="./assets/img/users/u2.jpg" alt="image" width="45" />
                            </a>
                            <div class="media-body">
                                <div class="media-heading">
                                    <a class="comment-author" href="javascript:;">Becky Brooks</a><small class="text-muted ml-2">2 days ago</small>
                                    <div class="pull-right font-13">
                                        <a class="text-muted mr-2" data-toggle="tooltip" data-original-title="Like"><i class="fa fa-heart"></i></a>
                                        <a class="text-muted" data-toggle="tooltip" data-original-title="Reply"><i class="fa fa-reply"></i></a>
                                    </div>
                                </div>
                                <p>Aliquam nec dui fermentum, fermentum est sed, molestie ligula. Donec ullamcorper eget purus eget vehicula. Morbi risus est, rhoncus vel faucibus eget,</p>
                                <div class="media">
                                    <a class="media-img" href="javascript:;">
                                        <img src="./assets/img/users/u11.jpg" alt="image" width="45" />
                                    </a>
                                    <div class="media-body">
                                        <div class="media-heading">
                                            <a class="comment-author" href="javascript:;">Tyrone Carroll</a><small class="text-muted ml-2">Just now</small>
                                            <div class="pull-right font-13">
                                                <a class="text-muted mr-2" data-toggle="tooltip" data-original-title="Like"><i class="fa fa-heart"></i></a>
                                                <a class="text-muted" data-toggle="tooltip" data-original-title="Reply"><i class="fa fa-reply"></i></a>
                                            </div>
                                        </div>
                                        <p class="m-0">Aliquam nec dui fermentum, fermentum est sed, molestie ligula. Donec ullamcorper eget purus eget vehicula. Morbi risus est, rhoncus vel faucibus eget,</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="media">
                            <a class="media-img" href="javascript:;">
                                <img src="./assets/img/users/u6.jpg" alt="image" width="45" />
                            </a>
                            <div class="media-body">
                                <div class="media-heading">
                                    <a class="comment-author" href="javascript:;">Connor Perez</a><small class="text-muted ml-2">1 hour ago</small>
                                    <div class="pull-right font-13">
                                        <a class="text-muted mr-2" data-toggle="tooltip" data-original-title="Like"><i class="fa fa-heart"></i></a>
                                        <a class="text-muted" data-toggle="tooltip" data-original-title="Reply"><i class="fa fa-reply"></i></a>
                                    </div>
                                </div>
                                <p>Aliquam nec dui fermentum, fermentum est sed, molestie ligula. Donec ullamcorper eget purus eget vehicula. Morbi risus est, rhoncus vel faucibus eget,</p>
                            </div>
                        </li>
                    </ul>
                    <h5 class="font-strong mt-4 mb-3">Leave A Comment</h5>
                    <form>
                        <div class="form-group">
                            <textarea class="form-control" rows="5" placeholder="Comment here"></textarea>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary" type="button">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .article-date {
        height: 48px;
        width: 48px;
        text-align: center;
        color: #fff;
        padding: 6px 5px;
        border-radius: 50%;
        line-height: 1;
    }
</style>
