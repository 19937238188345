<div class="">
  <div style="padding: 30px;">
    <div style="text-align: center;">
      <h2 style="font-size: 25px; font-weight: bold;">Tổng nợ</h2>
    </div>
    <div style="text-align: center;">
      <img style="width: 80px;" src="../assets/img/icons/vnd.svg">
    </div>
    <div style="text-align: center;">
      <div style="font-size: 23px; font-weight: bold;">{{debtItem.debtTotal | number}}</div>
    </div>
  </div>
  <div *ngIf="display_type == 0" class="table-responsive">
    <table class="table table-head-purple table-hover" >
      <thead class="thead-default thead-lg">
        <tr>
          <th style="width: 50px;">#</th>
          <th>Khách hàng</th>
          <th>Tổng nợ</th>
          <th>Số đơn</th>
          <th class="no-sort"><span><i class="ti ti-control-stop"></i></span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of debtItem.data; let i = index" (click)="openDebtCustomerDetail(item,i)" class="h-row-item">
          <td>
            <span>{{i+1}}</span>
          </td>
          <td>
            <div style="font-size: 18px;"> {{item.customer_name}}</div>
          </td>
          <td>{{item.debt| number:0}}</td>
          <td><span>{{item.debtTime}}</span></td>
          <td><span><i class="ti ti-control-play"></i></span></td>
         
        </tr>
        <tr>
          <td colspan="5">

          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>