
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarHeader } from '@angular/material/datepicker';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker/calendar-body';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { HealthService } from 'src/app/_services/health.service';
import { SchoolService } from 'src/app/_services/school.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-health-student-detail',
  templateUrl: './health-student-detail.component.html',
  styleUrls: ['./health-student-detail.component.css']
})
export class HealthStudentDetailComponent implements OnInit {
  // selected: Date | null;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  renderer: any;//handle month change

  student :any;
  dateSelected = "";
  selectedDate_date : Date;
  checkinDate = [];
  checkInDetailList = [];
  isLoading = false;
  constructor(
    public appService : AppService,
    public utilityService : UtilityService,
    public healthService : HealthService,
    public schoolService : SchoolService,
    public dialogRef: MatDialogRef<HealthStudentDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.student = data.data;
    this.dateSelected = utilityService.dayStrToMonth(data.dateSelected);
    this.selectedDate_date = utilityService.getDateFromDDMMYYY(this.dateSelected);
    this.loadHealthDetail();
  }

  ngOnInit(): void {

  }
  ngAfterViewInit() {

  }


  loadHealthDetail(){
    
    var data ={
      student_code : this.student.code,
      period_time : this.dateSelected,
      month_num : 6
    }
    this.checkInDetailList = [];
    this.isLoading = true;
    this.healthService.reportStudent(data).subscribe(
      async response => {
        this.isLoading = false;
        this.checkInDetailList = response;
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });

  }




}



