<!-- <div class="text-center text-warning m-5 p-5">
    <img style="width: 80px;" src="assets\img\icons\finder.png" alt="">
    <div>{{msg}}</div>
</div> -->

<div class="d-flex align-items-center text-center justify-content-around" style="height: 100%;">
    <div class="p-4">
        <img class="" [src]="img" alt="image" width="100px" style="opacity: 0.8;" />
        <div [class]="myclass" class="mt-3">{{msg}}</div>
    </div>
</div>