import { CartObj } from './../model/cart-obj';
import { ProductService } from './product.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { User } from '../model/user';
import { UtilityService } from './utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare var $: any;
@Injectable()
export class AppService {
  //
  scholasticSelected = "2023-2024";
  hockySelected = "I";
  capSelected = "";
  page_title = "Home";
  tx_num_default = 4;
  // public currentUser = { id: 0, name: "", shop_code: "", shop_name: "", email: "", phone: "", shop_address: "", role: 0, isLoggedIn: false };
  public currentUser = new User();
  listCart = [];
  cartIndex = 0;
  cartObj = new CartObj();
  products = [];
  products_backup: any

  //for desk service
  isFromDeskCart = false;
  deskList = [];
  deskIndex = -1;
  deskRevenue = 0;
  deskActive = 0;
  deskListPrice = [];

  //for customer

  customers = [];
  //for paging
  viewNumsObj: any;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  public currentTime: Date = new Date();

  constructor(
    public httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {

    // try {
      console.log("appService");
      if (localStorage.getItem(localStorage.getItem(environment.currentUser))) {
        this.currentUser = JSON.parse(localStorage.getItem(localStorage.getItem(environment.currentUser)));
        // xửu lý cấp
        if(typeof this.currentUser.caps == 'undefined'){
            this.currentUser.caps = [
              {
                'name':'Cấp 1',
                'value':'khoi_1,khoi_2,khoi_3,khoi_4,khoi_5',
              },
              {
                'name':'Cấp 2',
                'value':'khoi_6,khoi_7,khoi_8,khoi_9',
              }
            ];
            // lưu lại vào storage
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.currentUser));
        }
      }else{
        // chưa đăng nhập
        this.router.navigate(['/login', { id: 1990 }]);
        return;
      }
      console.log(environment.currentUser);
      //load unit load khois

      //load localStorage.setItem(environment.scholasticSelected, this.appService.scholasticSelected);
      if (localStorage.getItem(environment.scholasticSelected)) {
        this.scholasticSelected = localStorage.getItem(environment.scholasticSelected);
      }
      //load hoc kỳ
      if (localStorage.getItem(environment.hockySelected)) {
        this.hockySelected = localStorage.getItem(environment.hockySelected);
      }


      this.khois = this.currentUser.khois;

   

      console.log(this.listCart);
      console.log(this.cartIndex);

      //for paging
      this.viewNumsObj = this.viewNums[1].Code;
      // for service price
      if (typeof this.currentUser.shop_service_prices !== 'undefined' && this.currentUser.shop_service_prices != '')
        this.deskListPrice = JSON.parse(this.currentUser.shop_service_prices);


    // } catch (error) {
    //   localStorage.setItem(environment.currentUser, JSON.stringify(new User()));
    //     this.router.navigate(['/login', { id: 1990 }]);
    // }
  }

  // private apiHost = "https://localhost";
  private apiHost = environment.apiUrl;
  private apiEndpoint = environment.apiEndpoint;
  private apiEndpoint_upload = environment.apiEndpoint_upload;
  public customer_code = environment.CUSTOMER_CODE_PREFIX;
  public customer_name = environment.CUSTOMER_NAME_PREFIX;
  public supplier_name = "Phiếu nhập kho ";

  public DAY_REPORT_DEFAULT = 15;

  public getApiEndpoint(service: string) {
    return this.apiHost + this.apiEndpoint + service;
  }
  public getUrlImage(imageURl: string) {
    // console.log(imageURl);
    return this.apiHost + this.apiEndpoint_upload + this.currentUser.school_code + "/" + imageURl;
  }
  public showImageProduct(image_url: string) {
    // console.log(imageURl);
    return (image_url == "" || image_url == null) ? 'url(' + environment.product_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }
  public showImageCustomer(image_url) {
    return (image_url == "" || image_url == null) ? 'url(' + environment.customer_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }

  public checkPermission(role_position: string){
    if(role_position.includes(this.currentUser.positions)){
      return true;
    }
    return false;
  }
  public checkFullPermission(){
    let role_position = "admin,hieutruong,phohieutruong";
    if(this.currentUser.positions != "" && role_position.includes(this.currentUser.positions)){
      return true;
    }
    return false;
  }
  public checkFullPermissionV2(teacher_code){
    let role_position = "admin,hieutruong,phohieutruong";
    if(this.currentUser.positions != "" && role_position.includes(this.currentUser.positions)){
      return true;
    }
    if(teacher_code == this.currentUser.code){
      return true;
    }
    return false;
  }
  public checkFinancePermission(){
    let role_position = "admin,hieutruong,phohieutruong,ketoan";
    if(this.currentUser.positions != "" && role_position.includes(this.currentUser.positions)){
      return true;
    }
    return false;
  }
  public checkClassChunhiem(teacher_code){
    if(teacher_code == this.currentUser.code){
      return true;
    }
    return false;
  }
  // public settingUnits(units: string) {
  //   if (units == undefined) return "";
  //   let temp = units.split(",");
  //   this.units = [];
  //   for (let i = 0; i < temp.length; i++) {
  //     this.units.push({ 'code': temp[i].trim(), 'value': temp[i].trim() });
  //   }
  // }


  openCheckout() {
    this.isFromDeskCart = false;
    if (!$('body').hasClass('has-backdrop')) {
      $('.checkout-widget').backdrop();
    }
    console.log(this.listCart[this.cartIndex]);

    this.cartObj = this.listCart[this.cartIndex];



    this.cartObj.price_after_promotion = this.cartObj.price_totol - this.cartObj.price_promotion;
    this.cartObj.customer_pay = this.cartObj.price_after_promotion;

    localStorage.setItem(environment.cartIndex, this.cartIndex.toString());
  }
  openCheckoutDesk() {
    // deskList = [];
    // deskIndex = -1;
    this.isFromDeskCart = true;
    console.log("openCheckoutDesk");
    if (!$('body').hasClass('has-backdrop')) {
      $('.checkout-widget').backdrop();
    }
    console.log(this.deskList[this.deskIndex]);
    this.cartObj = this.deskList[this.deskIndex];

    //tính toán giờ dịch vụ
    this.calculateServicePrice();

    this.cartObj.price_after_promotion = this.cartObj.price_totol - this.cartObj.price_promotion;
    this.cartObj.customer_pay = this.cartObj.price_after_promotion;

    localStorage.setItem(environment.deskIndex, this.deskIndex.toString());
  }
  private calculateServicePrice() {
    if (this.cartObj.is_time_service == 1) {
      var timeEnd_temp = new Date().getTime();

      var temp = 0;
      this.cartObj.cart_service.forEach(element => {
        if (element.isActive == 1 && element.isEnable == 1) {
          if (element.serviceEndTimeNumberType > 0) {
            temp = this.diff_minutes(element.serviceStartTimeNumberType, element.serviceEndTimeNumberType);
          } else {
            temp = this.diff_minutes(element.serviceStartTimeNumberType, timeEnd_temp);
          }
          element.quantity = (temp / 60).toFixed(3); // số lượng = phút chia ra giờ
          element.quantityLable = this.timeConvertMtoHM(temp);
          element.amount = Math.round(element.quantity * element.price);
          console.log(element.quantityLable);
          console.log(element.quantity * element.price);
          console.log(element.amount);
        }
      });
    }
  }

  public diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff)) + 1;
  }
  public timeConvertMtoHM(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + "g " + rminutes + "p";
  }

  preparePromotion(cartObj) {
    if (cartObj.promotion_type == 'đ') {
      cartObj.price_promotion = cartObj.promotion_promotion;
    } else if (cartObj.promotion_type == '%') {
      if (cartObj.promotion_promotion > 100) {
        cartObj.promotion_promotion = 100;
      }
      cartObj.price_promotion = Math.floor(cartObj.price_totol * (cartObj.promotion_promotion / 100));
    }
    cartObj.price_after_promotion = cartObj.price_totol - cartObj.price_promotion;
    cartObj.customer_pay = cartObj.price_after_promotion;
  }
  public updateDeskRevenue() {
    this.deskRevenue = 0;
    this.deskActive = 0;
    console.log('dssdsldjslk d');
    for (let item of this.deskList) {
      this.deskRevenue += item.price_totol;
      if (typeof item.cart !== 'undefined' && item.cart.length > 0 || typeof item.cart_service !== 'undefined' && item.cart_service.length > 0)
        this.deskActive++;
    }
  }

  public subject_type_list = [
    { 'code': 'batbuoc', 'value': 'Bắt buộc' },
    { 'code': 'tuchon', 'value': 'Tự chọn' },
  ];
  public subject_type_mark_list = [
    { 'code': 'd', 'value': 'Tính điểm' },
    { 'code': 'nx', 'value': 'Nhận xét' },
  ];
  public subject_factor_list = [
    { 'code': 1, 'value': '1' },
    { 'code': 2, 'value': '2' },
  ];
  public viewNums = [
    { 'Code': 10, 'Value': '10 dòng' },
    { 'Code': 20, 'Value': '20 dòng' },
    { 'Code': 30, 'Value': '30 dòng' },
    { 'Code': 50, 'Value': '50 dòng' },
    { 'Code': 100, 'Value': '100 dòng' },
    { 'Code': 200, 'Value': '200 dòng' },
  ];
  public parent_list = [
    { 'label': 'Họ tên mẹ', 'role': 'mother', 'code': '', 'name': '', 'phone': '' },
    { 'label': 'Họ tên bố', 'role': 'father','code': '', 'name': '', 'phone': '' },
    { 'label': 'Người giám hộ', 'role': 'guardian','code': '', 'name': '', 'phone': '' },
  ];
  public finance_frequencys = [
    { 'code': '', 'value': 'Bất kỳ' },
    { 'code': 'Tháng', 'value': 'Tháng' },
    { 'code': 'Năm', 'value': 'Năm' },
    // { 'code': 'Học kỳ', 'value': 'Học kỳ' },
    // { 'code': 'Ngày', 'value': 'Ngày' },
    // { 'code': 'Tuần', 'value': 'Tuần' },
  ];
  public finance_cate_type = [
    { 'code': '', 'value': 'khoản thu thường' },
    { 'code': 'dot', 'value': 'Khoản thu theo đợt excel' },
  ];
  public finance_units = [
    { 'code': 'Năm', 'value': 'Năm' },
    { 'code': 'Tháng', 'value': 'Tháng' },
    { 'code': 'Học kỳ', 'value': 'Học kỳ' },
    { 'code': 'Ngày', 'value': 'Ngày' },
    { 'code': 'Tuần', 'value': 'Tuần' },
  ];
  public sort_promotions = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'created_date ASC',
      name: 'Xếp theo thời gian tạo',
    },

  ];
  public sort_products = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },
    {
      code: 'stock DESC',
      name: 'Xếp theo tồn kho',
    },
    {
      code: 'price DESC',
      name: 'Xếp theo giá bán',
    },
  ];

  public sort_customer = [
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },
  ];
  public sort_reports = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tăng',
    },
    {
      code: 'created_date ASC',
      name: 'Xếp theo thời gian giảm',
    },
    {
      code: 'quantity DESC',
      name: 'Xếp theo số lượng giảm',
    },
    {
      code: 'quantity ASC',
      name: 'Xếp theo số lượng tăng',
    },
    {
      code: 'product_quantity DESC',
      name: 'Xếp theo sản phẩm giảm',
    },
    {
      code: 'product_quantity ASC',
      name: 'Xếp theo sản phẩm tăng',
    },
  ];
  public sort_category = [
    {
      code: 'od ASC',
      name: 'Xếp theo thứ tự',
    },
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    }
  ];
  public sale_statuss = [
    {
      code: -1,
      name: 'Trạng thái: Tất cả',
    },
    {
      code: 0,
      name: 'Đặt hàng',
    },
    {
      code: 1,
      name: 'Hoàn thành',
    },
    {
      code: 4,
      name: 'Đang xử lý',
    },
    {
      code: 3,
      name: 'Đã hủy',
    },

  ];
  public day_nums = [
    {
      code: 7,
      name: '7 ngày',
    },
    {
      code: 10,
      name: '10 ngày',
    },
    {
      code: 15,
      name: '15 ngày',
    },
    {
      code: 20,
      name: '20 ngày',
    },
    {
      code: 25,
      name: '25 ngày',
    },
    {
      code: 30,
      name: '30 ngày',
    },
  ];
  public month_nums = [
    {
      code: 3,
      name: '3 tháng',
    },
    {
      code: 5,
      name: '5 tháng',
    },
    {
      code: 7,
      name: '7 tháng',
    },
    {
      code: 12,
      name: '12  tháng',
    },
    {
      code: 24,
      name: '24 tháng',
    }
  ];
  public memberships = [
    {
      code: "admin",
      name: 'Chủ sở hữu',
      color: ""
    },
    {
      code: "ql",
      name: 'Quản lý/Kế toán',
      color: ""
    },
    {
      code: 'nvbh',
      name: 'NV bán hàng',
      color: ""
    },
    {
      code: 'nvgh',
      name: 'NV giao hàng',
      color: ""
    },
    {
      code: 'nvkt',
      name: 'NV kỹ thuật',
      color: ""
    },
  ];
  public units = [];
  public colors = [
    { 'code': 'sa', 'value': 'Mặc định' },
    { 'code': 'Đen', 'value': 'Đen' },
    { 'code': 'Trắng', 'value': 'Trắng' },
    { 'code': 'Ghi', 'value': 'Ghi' },
    { 'code': 'Vàng', 'value': 'Vàng' },
    { 'code': 'Cam', 'value': 'Cam' },
    { 'code': 'Xanh', 'value': 'Xanh' },
    { 'code': 'Hồng', 'value': 'Hồng' },
  ];
  public assignment_type_list = [
    { 'code': 'm', 'value': 'Môn' },
    { 'code': 'nlc', 'value': 'Năng lực chung' },
    { 'code': 'nldt', 'value': 'Năng lực đặc thù' },
    { 'code': 'pccy', 'value': 'Phẩm chất chủ yếu' }
  ];
  //////////////////
  public showImage(image_url: string, image_default: string) {
    return (image_url == "" || image_url == null) ? 'url(' + image_default + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }
  public showImageTeacher(image_url: string) {
    return (image_url == "" || image_url == null) ? 'url(' + environment.customer_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }
  public showImageStudent(image_url: string, gender) {
    if(gender == 1)
    return (image_url == "" || image_url == null) ? 'url(' + environment.student_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
    else
    return (image_url == "" || image_url == null) ? 'url(' + environment.student_girl_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }
  public showImageTeacher2(image_url: string) {
    var temp = (image_url == "" || image_url == null) ? environment.customer_avatar : this.getUrlImage(image_url);
    
    return this.sanitizer.bypassSecurityTrustUrl(temp);
  }
  public showImageAttribute(image_url: string) {
    var temp = (image_url == "" || image_url == null) ? environment.attribute_avatar : this.getUrlImage(image_url);
    
    return this.sanitizer.bypassSecurityTrustUrl(temp);
  }
  public showCheckinImage(image_url: string) {
    if(image_url == '' || image_url == null)
    return "./assets/img/icons/to-do-list.png";
    else
    return image_url;
  }
  public showFileAttach(file_url: string) {
    var temp = (file_url == "" || file_url == null) ? environment.message : this.getUrlImage(file_url);

    return this.sanitizer.bypassSecurityTrustUrl(temp);
  }
  public showGender(gender: number) {
    return (gender == 1) ? "Nam" : "Nữ";
  }
  public getValueByCode(code: string, lists) {
    if(code == undefined) code = "";
    // lists = { 'code': 'Hồng', 'value': 'Hồng' }
    for (let i = 0; i < lists.length; i++) {
      if (code == lists[i]["code"])
        return lists[i]["value"]
    }
    return "";
  }
  public getKhoiByClassCode(class_code: string, classs) {
    // lists = { 'code': 'Hồng', 'value': 'Hồng' }
    for (let i = 0; i < classs.length; i++) {
      if (class_code == classs[i]["code"])
        return classs[i]["khoi"]
    }
    return "";
  }
  public sort_students = [
    {
      code: 'od ASC',
      name: 'Xếp theo thứ tự',
    },
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },

  ];
  public sort_teachers = [
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },

  ];
  public statuss = [
    { 'code': 0, 'value': 'Hoạt động' },
    { 'code': 1, 'value': 'Ngưng hoạt động' },
  ];
  public camera_type = [
    { 'code': 0, 'name': 'Không sử dụng' },
    { 'code': 1, 'name': 'Mặc định' },
    { 'code': 2, 'name': 'BAE Camera AI' },
  ];
  public genders = [
    { 'code': 1, 'value': 'Nam' },
    { 'code': 0, 'value': 'Nữ' },
  ];
  public status_students = [
    { 'code': 0, 'value': 'Chính thức' },
    { 'code': 1, 'value': 'Trải nghiệm' },
  ];
  public dan_tocs = [
    { 'code': 'Kinh', 'value': 'Kinh' },
    { 'code': 'Mường', 'value': 'Mường' },
    { 'code': 'Tày', 'value': 'Tày' },
    { 'code': 'Thái', 'value': 'Thái' },
    { 'code': 'Khmer', 'value': 'Khmer' },
  ];
  public positions = [
    { 'code': 'giaovien', 'value': 'Giáo viên' },
    { 'code': 'nhanvien', 'value': 'Nhân viên' },
    { 'code': 'ketoan', 'value': 'Kế toán' },
    { 'code': 'phohieutruong', 'value': 'Phó hiệu trưởng' },
    { 'code': 'hieutruong', 'value': 'Hiệu trưởng' },
    // { 'code': '', 'value': 'Học sinh' },
  ];
  public scholastics = [
    {
      'code': '2022-2023',
      'name': '2022-2023',
      'hockyI_startTime': '15/08/2022',
      'hockyI_endTime': '15/12/2022',
      'hockyII_startTime': '15/12/2022',
      'hockyII_endTime': '15/08/2023',
    },
  ];
  public khois = [
    { 'code': 'Khối 1', 'value': 'Khối 1', 'tx_num': 4 },
    { 'code': 'Khối 2', 'value': 'Khối 2', 'tx_num': 4 },
    { 'code': 'Khối 3', 'value': 'Khối 3', 'tx_num': 4 },
    { 'code': 'Khối 4', 'value': 'Khối 4', 'tx_num': 4 },
    { 'code': 'Khối 5', 'value': 'Khối 5', 'tx_num': 4 },
    { 'code': 'Khối 6', 'value': 'Khối 6', 'tx_num': 4 },
    { 'code': 'Khối 7', 'value': 'Khối 7', 'tx_num': 4 },
    { 'code': 'Khối 8', 'value': 'Khối 8', 'tx_num': 4 },
    { 'code': 'Khối 9', 'value': 'Khối 9', 'tx_num': 4 },
    { 'code': 'Khối 10', 'value': 'Khối 10', 'tx_num': 4 },
    { 'code': 'Khối 11', 'value': 'Khối 11', 'tx_num': 4 },
    { 'code': 'Khối 12', 'value': 'Khối 12', 'tx_num': 4 },
  ];
  public hockys = [
    { 'code': 'I', 'value': 'Học kỳ I', },
    { 'code': 'II', 'value': 'Học Kỳ II', },
  ];
  public weeks = [
    { 'code': '1', 'value': 'Thứ 2','checked':true },
    { 'code': '2', 'value': 'Thứ 3','checked':true },
    { 'code': '3', 'value': 'Thứ 4','checked':true },
    { 'code': '4', 'value': 'Thứ 5' ,'checked':true },
    { 'code': '5', 'value': 'Thứ 6','checked':true },
    { 'code': '6', 'value': 'Thứ 7','checked':false },
    { 'code': '0', 'value': 'Chủ nhật' ,'checked':false },
  ];
  public hanhkiemList = [
    { 'code': '', 'name': '---','checked':true },
    { 'code': 't', 'name': 'Tốt','checked':true },
    { 'code': 'k', 'name': 'Khá','checked':true },
    { 'code': 'tb', 'name': 'Trung Bình','checked':true },
    { 'code': 'y', 'name': 'Yếu' ,'checked':true },
  ];
  public renluyenList = [
    { 'code': '', 'name': '---','checked':true },
    { 'code': 't', 'name': 'Tốt','checked':true },
    { 'code': 'k', 'name': 'Khá','checked':true },
    { 'code': 'd', 'name': 'Đạt','checked':true },
    { 'code': 'cd', 'name': 'Chưa đạt' ,'checked':true },
  ];
  public hoclucList = [
    { 'code': '', 'name': '---','checked':true },
    { 'code': 'g', 'name': 'Giỏi','checked':true },
    { 'code': 'k', 'name': 'Khá','checked':true },
    { 'code': 'tb', 'name': 'Trung Bình','checked':true },
    { 'code': 'y', 'name': 'Yếu' ,'checked':true },
    { 'code': 'km', 'name': 'Kém' ,'checked':true },
  ];

  public type_report_day = "Báo cáo ngày"; //danh muc thu chi
  public type_report_detail_week= "week"; //danh muc thu chi
  public type_report_detail_month= "month"; //danh muc thu chi
}
