


import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.css']
})
export class ApplicationDetailComponent implements OnInit {
  discussionSelected : any;
  constructor(
    public appService: AppService,
    public schoolService: SchoolService,
    public dialogRef: MatDialogRef<ApplicationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.discussionSelected = data;
   }

  ngOnInit(): void {
  }

  approve(){
    console.log(this.discussionSelected);
    this.discussionSelected.status = 1;
    this.schoolService.applicationUpdate(this.discussionSelected).subscribe(
      async response => {
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          
          // đóng diaglog
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })
  }

}
