import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.css']
})
export class SearchableSelectComponent implements OnInit {
  @Input() options: any = [];
  @Output() optionSelected = new EventEmitter();
  
  searchTerm: string = '';
  filteredOptions: any = [];

  ngOnInit() {
    // this.filteredOptions = this.options;
  }

  onSearch(searchTerm: string) {
    console.log(searchTerm);
    this.filteredOptions = this.options.filter(option =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  selectOption(option: any) {
    this.searchTerm = '';
    console.log(option);
    this.filteredOptions = [];
    this.optionSelected.emit(option);
  }

  clearSearch_add(){
    this.searchTerm = '';
    this.filteredOptions = [];
  }
}