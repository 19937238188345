
import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
declare var $: any;
@Component({
  selector: 'app-health-attribute',
  templateUrl: './health-attribute.component.html',
  styleUrls: ['./health-attribute.component.css']
})
export class HealthAttributeComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;


  isLoading = false;
  isSomethingChange = false; // để load lại ở trang parent
  mode_action = 'normal'; //normal, add
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;

  categorys: any;
  categorys_backup: any;

  cateType = environment.cateType_health_attribute;
  // for add
  cate = new Category(this.cateType);
  // for update
  categoryselected = new Category(this.cateType);
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;
  temp_weeks_update = []; //clone
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: 'od ASC',
  }

  filter = {
    name: "",
    code_parent: "",
    deleted: 0,
    type: this.cateType
  }



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HealthAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("selected: ", data);
    this.filter.code_parent = data.code;
    // this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onCancelClick(): void {
    this.dialogRef.close({ code: this.isSomethingChange });
  }

  onOkClick(): void {

    $("#confirm-gvcn-modal").modal('hide');
    let result = {
      code: "ok",
      // data: this.teacherSelected
    }
    this.dialogRef.close(result);
  }


  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {

  }
  clearSearch() {
    this.filter.name = '';
    this.loadCategory();
  }

  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar;
  }

  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {

          response.data.forEach(element => {
            element.checkin_weeks_name = this.utility.getWeekNameByWeekCode(element.checkin_weeks);
          });

          this.categorys = response.data;
          this.categorys_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

          console.log(this.categorys);
        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng tên ");
      return false;
    }

    this.cate.name = this.cate.name.trim();
    this.cate.code = 'HA' + Math.floor(Math.random() * 10000);
    this.cate.code_parent = this.data.code;

    if (this.file !== null) {
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }


    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isSomethingChange = true;
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }
  confirmDelete(item) {
    let data = {
      title: 'Xác nhận xóa thuộc tính',
      msg: 'Đồng ý để xóa thuộc tính.' + item.name,
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        var data = {
          id: item.id,
          deleted: true,
        }
        this.categoryeduService.updateByID(data).subscribe(
          async response => {
            this.isLoading = false;
            console.log(response);
            if (response.code == "ok") {
              this.loadCategory();
              this.toastr.success("", "Xóa thành công");
              this.isSomethingChange = true;
            }
          },
          error => {
            this.isLoading = false;
            console.error(error);
          })

      }
    });
  }
  countAllCategory() {
    this.categoryeduService.countAllCategory(this.cateType).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {

    this.resetCategory();
    this.countAllCategory();
    $("#add-category-modal").modal('show');

  }


  popupUpdate(item) {
    this.categoryselected = item;
    this.mode_action = 'update';
  }

  updateCategory() {
    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.categoryselected_file !== null) {
      this.categoryselected.image_url = this.cateType + "/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }

    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    console.log(this.categoryselected);

    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.temp_weeks_update.forEach(element => {
      if (element.checked) {
        temp_weeks.push(element.code);
      }
    });
    this.categoryselected.checkin_weeks = temp_weeks.join("w");


    this.isLoading = true;
    var data = {
      id: this.categoryselected.id,
      name: this.categoryselected.name,
      checkin_start: this.categoryselected.checkin_start,
      checkin_end: this.categoryselected.checkin_end,
      checkin_early: this.categoryselected.checkin_early,
      checkin_late: this.categoryselected.checkin_late,
      checkin_effective: this.categoryselected.checkin_effective,
      checkin_weeks: this.categoryselected.checkin_weeks,
      deleted: this.categoryselected.deleted,
    }
    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        this.isLoading = false;
        this.isSomethingChange = true;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if (this.file == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async changeImageUpdate($event): Promise<void> {
    this.categoryselected_file = $event.target.files[0];
    if (this.categoryselected_file == null) {
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
        this.categoryselected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.categoryselected_file);
        reader.onload = (_event) => {
          this.categoryselected_imageUrl = reader.result;
          console.log(this.categoryselected_imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }




  /////////////////
  saveArrange() {
    let data = [];
    this.categorys.forEach((item, i) => {
      data.push({
        id: item.id,
        od: i
      });
    });
    console.log(data);

    this.categoryeduService.saveArrange(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.dialogRef.close({ code: true });
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })


  }

  drop(event: CdkDragDrop<number[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      console.log(event.previousIndex, event.currentIndex);
      console.log(event.previousIndex, event.currentIndex);
    }
  }
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }

}
