
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { StudentService } from 'src/app/_services/student.service';
import { Student } from 'src/app/model/student';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { ArrangeDataComponent } from 'src/app/components/arrange-data/arrange-data.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';
import { StudentRegisterImageCameraComponent } from '../../student/student-register-image-camera/student-register-image-camera.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FinanceService } from 'src/app/_services/finance.service';
import { FinanceStudentDetailComponent } from 'src/app/components/finance/finance-student-detail/finance-student-detail.component';

declare var $: any;
@Component({
  selector: 'app-finance-assignment',
  templateUrl: './finance-assignment.component.html',
  styleUrls: ['./finance-assignment.component.css']
})
export class FinanceAssignmentComponent implements OnInit {
  @ViewChild('studentAddForm') studentAddForm: HTMLFormElement;
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  isLoading = false;
  isLoading_student = false;
  studentList_exist = [];
  dateRange = []
  // class checkbox
  checkboxAllButton = true; // mặc định chọn all
  class_codeSelected: string = '';
  student_selected = '';

  // countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

  //for checkbox
  checkboxs_changeClass = [];
  //  checkboxAllButton = false;

  selected_finance_cate = "";
  // selected_finance_cate_name ="";
  // for action
  action_mode = "";

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };

  //for data
  students = [];
  students_backup: any
  categorys: any
  facultys: any
  classs: any
  filter = {
    name: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }


  // for add
  student = new Student();
  fileteacherImg: File;
  imageUrl: any = environment.student_avatar;
  // for update
  studentSelected: any;
  isDeleted: boolean;
  studentSelected_imageUrl: any = environment.student_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  count_finance_cate = 0;
  sum_finance_value = 0;
  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public studentService: StudentService,
    public financeService: FinanceService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public schoolService: SchoolService,
    public ioService: IoService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Gán khoản thu học sinh";
    this.sorter.sortStr = this.appService.sort_students[0].code;
    this.studentSelected = this.student;


    this.dateRange = utility.getMonthListInRange();

    console.log(this.dateRange);
    //Xử lý cấp học.
    this.kt_filter.code_parent = appService.currentUser.name_alias;
  }




  ngOnInit() {
    // this.loadStudent();
    this.loadClasss();
    //khoản thu
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }
  getHistoryTransaction() {
    let data = {
      fromDate: 'class_code',
      toDate: 'students'
    }
    this.schoolService.getHistoryTransaction(data).subscribe(
      async response => {
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  

  openDetail(item) {

    const dialogRef = this.dialog.open(FinanceStudentDetailComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
      }
    });
  }

  openImport(selected_finance_cate, code_parent) {
    this.kt_filter.code_parent = code_parent;
    this.selected_finance_cate = selected_finance_cate;
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    console.log(this.kt_filter.code_parent);
    $("#import-finance-assignment").modal('show');
  }
  popup_export() {
    // let filter = {};
    if(this.selected_finance_cate == ''){
      this.toastr.warning("", "Vui lòng chọn khoản thu");
      return;
    }
    console.log(this.selected_finance_cate);
    // this.studentService.exportStudentHealth(this.filter.class_code, this.healthRecord_type, this.selectedDateStr);
    this.studentService.exportFinanceAssignment(this.kt_filter.code_parent, this.selected_finance_cate,"");
  }
  change_finance_cate(event) {
    console.log(event);
    // this.selected_finance_cate_name = this.categorys.find(item => item.id === event.value).name;
    // console.log(this.selected_finance_cate);
    // console.log(this.selected_finance_cate_name);
  }
  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  uploadExcel() {
    // uploadFinanceAssignment
    if(this.selected_finance_cate == ''){
      this.toastr.warning("", "Vui lòng chọn khoản thu");
      return;
    }
    if (this.file == null || typeof (this.file) == "undefined") {
      this.toastr.warning("Thầy cô chưa chọn file", "Vui lòng chọn file");
      return;
    }
    this.isLoading = true;
    this.studentService.uploadFinanceAssignment(this.file, this.selected_finance_cate).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.code == "ok") {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadStudent();
          this.loadCategory();
        } else {
          this.import_result.result = false;
          this.import_result.msg = response.msg;
        }

      },
      error => {
        this.isLoading = false;
        this.import_result.result = false;
        this.import_result.msg = 'Đã có lỗi xảy ra khi đọc file Excel';
        console.error(error);
      })
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadStudent();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  };
  click_student(item) {
    if (this.student_selected == item.code)
      this.student_selected = '';
    else
      this.student_selected = item.code;

  }
  classRadioCheck(class_code) {
    console.log(class_code);
    // this.class_codeSelected = class_code;
    this.student_selected = ''; // chọn lớp thì reset student selected
    this.filter.class_code = class_code;
    this.loadStudent();
  }

  popup_arrange() {
    if (this.filter.class_code == "") {
      this.toastr.warning("Thầy cô chọn lớp để thao tác");
      return;
    }
    const dialogRef = this.dialog.open(ArrangeDataComponent, {
      data: this.students
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
      }
    });
  }
  popupRegisterCameraImage(item) {

    const dialogRef = this.dialog.open(StudentRegisterImageCameraComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
      }
    });
  }




  loadStudent() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    this.checkboxs_changeClass = [];
    this.checkboxAllButton = false;
    this.count_finance_cate = 0;
    this.sum_finance_value = 0;
    this.isLoading_student = true;
    this.students = [];
    this.students_backup = [];
    this.financeService.loadStudent(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.isLoading_student = false;
        console.log(response);
        if (response) {
          this.students = response.data;
          this.students_backup = response.data;
          this.count_finance_cate = response.count_finance_cate;
          this.sum_finance_value = response.sum_finance_value;
          this.checkboxs_changeClass = new Array(this.students.length);

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.students.length;
          // for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;


        }
      },
      error => {
        this.isLoading_student = false;
      })
  }
  checkboxAll(evt) {
    this.students.forEach(element => {
      element.temp_checked = evt.target.checked;
    });
  }
  checkboxAll_finance(evt) {
    this.categorys.forEach(element => {
      element.temp_checked = evt.target.checked;
    });
  }





  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected,
      cap_hoc: this.kt_filter.code_parent
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  doAssignment(type, item) {
    console.log(this.categorys);
    // Chuẩn bị khoản thu
    var finance_cate_list = [];
    finance_cate_list.push({ id: item.id, finance_value: item.finance_value, period_time_str: item.period_time_str });
    // chuẩn bị học sinh
    var student_code_list = [];

    var _class = "";
    var _khoi = "";
    // popup confirm
    let tempdata = {
      title: 'Xác nhận gán khoản thu cho Học sinh ?',
      msg: "",
    }

    if (type == 'student') {
      this.students.forEach(element => {
        if (element.temp_checked == true) {
          student_code_list.push(element.code);
        }
      });
      if (student_code_list.length == 0) {
        this.toastr.warning("", "Vui lòng chọn Học sinh");
        return;
      }
      tempdata.msg = 'Tổng ' + student_code_list.length + ' Học sinh ( Lớp ' + this.filter.class_code + " )";
    } else if (type == 'class') {
      if (this.filter.class_code == '') {
        this.toastr.warning("", "Vui lòng chọn lớp");
        return;
      }
      _class = this.filter.class_code;
      tempdata.msg = 'Học sinh ( Lớp ' + this.filter.class_code + " )";
    } else if (type == 'khoi') {
      if (this.filter.khoi_code == '') {
        this.toastr.warning("", "Vui lòng chọn khối");
        return;
      }
      _khoi = this.filter.khoi_code;
      tempdata.msg = 'Học sinh ( Khối ' + this.filter.khoi_code + " )";
    } else if (type == 'school') {
      tempdata.msg = "Học sinh toàn trường";
    }




    // hiển thị confirm.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {

        let data = {
          status: 0,
          type: type, // "student", "class", "khoi", "school"
          student_code_list: student_code_list,
          finance_cate_list: finance_cate_list,
          class: _class,
          khoi: _khoi,
        }
        console.log(data);
        this.financeService.saveMulti(data).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok") {
              this.dialog.open(ConfirmDialogComponent, {
                data: {
                  title: "Gán khoản thu thành công",
                  msg: "Tổng số Học sinh " + response.affectedRows,
                  ok: "Ok"
                }
              });
              this.toastr.success("", "Gán khoản thu thành công");
              this.loadStudent();
            }
            else {
              this.dialog.open(ConfirmDialogComponent, {
                data: {
                  title: "Không có Học sinh nào thỏa mãn điều kiện",
                  msg: "",
                  ok: "Ok"
                }
              });
              // this.studentList_exist = response.data;
              // $("#student-list-modal").modal('show');
              // this.toastr.warning("","Đã có Học sinh được gán trước đó");
            }
          },
          error => {
            console.error("error:", error);
            this.toastr.warning("", "Đã có lỗi xảy ra, Vui lòng liên hệ CSKH");
          })
      }
    });
  }
  sendNoti(type, item){
    console.log(item);
    // chuẩn bị học sinh
    var student_code_list = [];

    var _class = "";
    var _khoi = "";
    // popup confirm
    let tempdata = {
      title: 'Xác nhận gửi thông báo khoản thu ?',
      msg: "",
      ok: "Xác nhận gửi"
    }

    if (type == 'student') {
      this.students.forEach(element => {
        if (element.temp_checked == true) {
          student_code_list.push(element.code);
        }
      });
      if (student_code_list.length == 0) {
        this.toastr.warning("", "Vui lòng chọn Học sinh");
        return;
      }
      tempdata.msg = 'Tổng ' + student_code_list.length + ' Học sinh ( Lớp ' + this.filter.class_code + " )";
    } else if (type == 'school') {
      tempdata.msg = "Học sinh toàn trường";
    }



    // hiển thị confirm.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        let data = {
          finance_cate_id: item.id,
          student_code_list: student_code_list,
        }
        console.log(data);
        this.financeService.sendNoti(data).subscribe(
          async response => {
            console.log(response);
            this.toastr.success("Học sinh: " + response.affectedRows, "Gửi thông báo thành công");
          },
          error => {
            console.error("error:", error);
            this.toastr.warning("", "Đã có lỗi xảy ra, Vui lòng liên hệ CSKH");
          });
      }
    });
  }




  category_khoi_change(event) {
    this.loadClasss();
    this.loadStudent();
  }
  category_change(event) {
    console.log(this.filter);
    this.loadStudent();
  }
  sort_change(event) {
    this.loadStudent();
  }





  popup_student_export() {
    // let filter = {};
    console.log(this.filter);
    this.studentService.exportStudent(this.filter);
  }
  clearSearch() {
    this.filter.name = '';
    this.loadStudent();
  }

  //for search
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.students = this.students_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.students = this.students.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }


  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      console.log(event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      console.log(event.previousIndex, event.currentIndex);
      console.log(event.previousIndex, event.currentIndex);
    }
  }
  /** Predicate function that only allows even numbers to be dropped into a list. */
  evenPredicate(item: CdkDrag<number>) {
    return item.data % 2 === 0;
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }




  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadStudent();
      }),
      map(term => term === '' ? [] : this.students.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
  }



  kt_viewNumsObjChange() {
    this.kt_curentPage = 1;
    console.log(this.kt_viewNumsObj);
    this.loadCategory();
  }
  kt_pageChange = function (page) {
    if (page === 'prev' && this.kt_curentPage > 1) {
      this.kt_curentPage = this.kt_curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.kt_curentPage < this.kt_listPage.length) {
      this.kt_curentPage = this.kt_curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.kt_curentPage) {
      this.kt_curentPage = page;
      this.loadCategory();
    }
  };

  kt_filter = {
    name: "",
    khoi_code: "",
    code_parent: "",
    deleted: 0,
    type: environment.cateType_finance
  }
  kt_sorter = {
    sortStr: this.appService.sort_category[2].code,
  }
  //for paging
  kt_viewNumsObj = this.appService.viewNums[1].Code;
  kt_curentPage = 1;
  kt_totalItemOnPage: any;
  kt_totalItems: any;
  kt_listPage: any = [];
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.kt_viewNumsObj,
      pageObj: this.kt_curentPage
    };

    this.categoryeduService.loadCategory(this.kt_filter, this.kt_sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          // lặp để thêm json tạm
          response.data.forEach(element => {
            // element.finance_content_json = (element.finance_content != "" && element.finance_content != null) ? JSON.parse(element.finance_content) : [] ;

            if (element.finance_frequency == 'Tháng') {
              element.period_time_str = "01/" + this.utility.getMonthStrFromDate(new Date());
            } else if (element.finance_frequency == 'Năm') {
              element.period_time_str = "01/01/" + new Date().getFullYear();
            }

          });

          this.categorys = response.data;

       
          this.kt_totalItems = response.totalItems;
          this.kt_totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.kt_listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.kt_listPage.push({ i: i });
          }
          // this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
}



