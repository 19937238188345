import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/model/category';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-schedules-static-add',
  templateUrl: './schedules-static-add.component.html',
  styleUrls: ['./schedules-static-add.component.css']
})
export class SchedulesStaticAddComponent implements OnInit {
  categorys = []; // lớp
  checkboxAllButton = false; // mặc định chọn all
  isLoading = false;

  // item;
  type = '';
  // for add
  cate = new Category(environment.cateType_schedules_static);

  // Xử lý danh sách week
  temp_weeks_update = [];
  constructor(
    public appService: AppService,
    public categoryeduService: CategoryEduService,
    public schoolService: SchoolService,
    private utility: UtilityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SchedulesStaticAddComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    if (data.data != null) {
      this.cate = data.data;
    }
    this.type = data.type;
    // gán dữ liệuk
    if (this.type == 'copy') {
      this.cate.name = 'Lớp ';
      //load hockyI_startTime và  hockyI_endTime
      this.loadCurrentScholastic();
    } else if (this.type == 'update') {
      this.cate.name = this.cate.name;
      this.cate.hockyI_startTime = this.utility.dateStrToDate(
        data.data.startTime_str
      );
      this.cate.hockyI_endTime = this.utility.dateStrToDate(
        data.data.endTime_str
      );
    } else if (this.type == 'create') {
      //load hockyI_startTime và  hockyI_endTime
      this.loadCurrentScholastic();
      this.cate.name = 'Lớp ';
      this.cate.checkin_weeks = '1w2w3w4w5';
    }

    this.loadCategoryClass();
  }

  ngOnInit(): void {
    // Xử lý danh sách week
    this.temp_weeks_update = [];
    var temp_weeks = this.cate.checkin_weeks.split('w');
    this.appService.weeks.forEach((element, i) => {
      this.temp_weeks_update.push({
        code: element.code,
        value: element.value,
        checked: false,
      }); //clone

      temp_weeks.forEach((element_w) => {
        if (element_w == element.code) {
          this.temp_weeks_update[i].checked = true; //clone and false default
        }
      });
    });
    // this.updateData();
  }
  checkinChanged(event) {
    // this.duration = this.utility.calculateDuration(this.cate.checkin_start, this.cate.checkin_end);
  }
  changeCheckbox(event) {}
  loadCategoryClass() {
    let filter = {
      type: environment.cateType_class,
    };
    this.schoolService.getAllClass(filter).subscribe(
      async (response) => {
        if (response) {
          this.categorys = response;
          // lặp qua dữ liệu item để check
          var temp_class = this.cate.khoi_code.split('#');

          this.categorys.forEach((itemA) => {
            if (this.type == 'create') {
              itemA.temp_checked = true;
            } else {
              // Kiểm tra nếu code trong A có tồn tại trong B
              const existsInB = temp_class.some(
                (itemB) => itemB === itemA.code
              );
              // Cập nhật giá trị checked của phần tử trong A
              itemA.temp_checked = existsInB;
            }
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  async loadCurrentScholastic() {
      let result = {
        hockyI_startTime_str: '',
        hockyII_endTime_str: '',
      };
    
      let pagingObj = {
        viewNumsObj: 10,
        pageObj: 1,
      };
    
      let filter = {
        code: "2024-2025",
        type: environment.cateType_scholastic,
      };
    
      try {
        const response = await this.categoryeduService.loadCategory(filter, {}, pagingObj).toPromise();
        if (response && response.data && response.data.length > 0) {
          this.cate.hockyI_startTime = this.utility.dateStrToDate(response.data[0].hockyI_startTime_str || '');
          this.cate.hockyI_endTime = this.utility.dateStrToDate(response.data[0].hockyII_endTime_str || '');
        } else {
          console.warn("Không tìm thấy dữ liệu phù hợp.");
        }
      } catch (error) {
        console.error("Lỗi khi tải dữ liệu:", error);
      }
    
      return result;
  }
  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
  }

  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == '') {
      this.toastr.warning('', 'Vui lòng tên');
      return false;
    }

    

    this.cate.name = this.cate.name.trim();
    this.cate.code = 'SS' + Math.floor(Math.random() * 10000);

    //xử lý danh sách ngày
    var temp_weeks = [];
    this.temp_weeks_update.forEach((element) => {
      if (element.checked) {
        temp_weeks.push(element.code);
      }
    });
    this.cate.checkin_weeks = temp_weeks.join('w');
    //xử lý danh sách lớp
    var temp_classs = [];
    this.categorys.forEach((element) => {
      if (element.temp_checked) {
        temp_classs.push(element.code);
      }
    });

    if (temp_classs.length == 0) {
      this.toastr.warning('', 'Vui lòng lớp');
      return false;
    }

    this.cate.khoi_code = temp_classs.join('#');
    //Xử lý ngày tháng
    this.cate.hockyI_startTime = this.utility.formatDateToStr(
      this.cate.hockyI_startTime
    );
    this.cate.hockyI_endTime = this.utility.formatDateToStr(
      this.cate.hockyI_endTime
    );

    var data = {
      code: this.cate.code,
      type: this.cate.type,
      name: this.cate.name,
      khoi: this.cate.khoi,
      checkin_weeks: this.cate.checkin_weeks,
      khoi_code: this.cate.khoi_code, // danh sách class dùng lưu vào khoi_code
      hockyI_startTime: this.cate.hockyI_startTime,
      hockyI_endTime: this.cate.hockyI_endTime,
    };

    this.isLoading = true;
    this.categoryeduService.insertCategory(data).subscribe(
      async (response) => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.toastr.success('', 'Thêm mới danh mục thành công');
          this.dialogRef.close({ code: 'ok', msg: '' });
        } else {
          this.toastr.error('', 'Mã đã tồn tại hoặc có lỗi xảy ra');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          'Có lỗi xảy ra',
          'Vui lòng thử lại hoặc liên hệ CSKH'
        );
        console.error(error);
      }
    );
  }
  updateCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == '') {
      this.toastr.warning('', 'Vui lòng tên');
      return false;
    }

    //xử lý danh sách ngày
    var temp_weeks = [];
    this.temp_weeks_update.forEach((element) => {
      if (element.checked) {
        temp_weeks.push(element.code);
      }
    });

    //xử lý danh sách lớp
    var temp_classs = [];
    this.categorys.forEach((element) => {
      if (element.temp_checked) {
        temp_classs.push(element.code);
      }
    });

    if (temp_classs.length == 0) {
      this.toastr.warning('', 'Vui lòng lớp');
      return false;
    }

    var data = {
      id: this.cate.id,
      name: this.cate.name,
      checkin_weeks: temp_weeks.join('w'),
      khoi_code: temp_classs.join('#'), // danh sách class dùng lưu vào khoi_code
      hockyI_startTime: this.utility.formatDateToStr(
        this.cate.hockyI_startTime
      ),
      hockyI_endTime: this.utility.formatDateToStr(this.cate.hockyI_endTime),
    };
    this.isLoading = true;
    this.categoryeduService.updateByID(data).subscribe(
      async (response) => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.toastr.success('', 'Cập nhật thành công');
          this.dialogRef.close({ code: 'ok', msg: '' });
        } else {
          this.toastr.error('', 'Mã đã tồn tại hoặc có lỗi xảy ra');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          'Có lỗi xảy ra',
          'Vui lòng thử lại hoặc liên hệ CSKH'
        );
        console.error(error);
      }
    );
  }
  onCheckboxChange(item){
    console.log(item);
    if(item.temp_checked){
        this.cate.name = "Lớp " + item.name;
    }else{
      this.cate.name = "";
    }
  }
  okClick() {
    // this.updateData();
    // if(this.temp_data.length == 0){
    //   this.toastr.warning("Không có dữ liệu copy");
    //   return;
    // }
    // console.log(this.temp_data);
    // this.teacherService.saveTeachingAssignment(this.temp_data).subscribe(
    //   async (response) => {
    //     console.log(response);
    //     if (response.code == 'ok') {
    //       this.toastr.success( `Cập nhật/Copy lịch dạy thành công ${response.value}`,'Thành công');
    //       this.dialogRef.close({code: 'ok', msg: ''});
    //     }else{
    //       this.toastr.warning(response.msg,'Dữ liệu không hợp lệ');
    //       console.error("có lỗi xảy ra");
    //     }
    //   },
    //   (error) => {
    //     console.error('error:');
    //     console.error(error);
    //   }
    // );
  }
  // private updateData() {
  //   this.temp_data = [];
  //   this.week_list.forEach(wtem => {
  //     this.cates.forEach(item => {
  //       if (wtem.checked) {
  //         const copiedObject = { ...item }; // chỉ copy cấp 1 cấp 2 vẫn tham chiếu
  //         copiedObject.checkin_date = wtem.weekDate;
  //         this.temp_data.push(copiedObject);
  //       }
  //     });
  //   });
  // }

  cancelClick() {
    this.dialogRef.close();
  }
}
