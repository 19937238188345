<div class="page-content fade-in-up">

    <table style="width: 100%;">
        <tr>
            <td>
                <div class="">
                    <button class="btn btn-md btn-outline-dark ml-0">Lớp: </button>
                    <button class="btn btn-md btn-secondary" *ngFor="let item of classs; let i = index" (click)="selectClass(item)" [ngClass]="{'btn-primary': item.class_code == this.classSelected.class_code}">{{item.class_code}}</button>
                    <div class="btn btn-md align-items-center text-warning" *ngIf="classs.length == 0">
                        Thầy/cô chưa được phân công giảng dạy
                    </div>
                </div>
            </td>
            <td style="text-align: right; min-width: 185px; vertical-align: top;">
                <div class="btn-group">
                    <div class="btn" *ngFor="let item of appService.hockys; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.hockySelected, 'btn-outline-primary': item.code != this.appService.hockySelected}" (click)="hocky_change(item.code)">{{item.value}}</div>
                </div>
            </td>
        </tr>
    </table>





    <div class="row mb-2 mt-2">
        <div class="col p-0">
            <div class="qbs-scroll" style="overflow-y: auto;">
                <div class="btn-group" style="width: max-content;">
                    <div mat-button [matMenuTriggerFor]="menu" class="btn btn-outline-dark">
                        {{appService.assignment_type_list[assignment_type_selected].value}} </div>
                    <mat-menu #menu="matMenu">
                        <button *ngFor="let item of appService.assignment_type_list; let i = index" mat-menu-item (click)="change_assignment_type(i)">{{item.value}}</button>
                    </mat-menu>
                    <div *ngIf="subjects.length == 0" class="align-items-center">
                        <div class="btn text-warning">Thầy/cô chọn lớp sau đó chọn môn để nhập đánh giá</div>
                    </div>
                    <div class="btn subject-cap-cover" *ngFor="let item of subjects; let i = index" [ngClass]="{'btn-primary': item.subjects_code == this.subjectsSelected.subjects_code, 'btn-outline-primary': item.subjects_code != this.subjectsSelected.subjects_code}" (click)="selectSubject(item)">
                        <span class="subject-name">{{item.subjects_name}}</span>
                        <span class="subject-cap">{{item.subject_cap}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="ibox" style="width: 100%;">
            <div class="ibox-head">
                <div class="ibox-tools" data-toggle="tooltip" data-placement="top" title="" data-original-title="Toàn màn hình">

                    <!-- <button class="btn btn-purple" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tooltip on top">Top</button> -->

                    <a class="fullscreen-link" id="elementID"><i class="ti ti-fullscreen"></i> <span
                            class="font-18 ml-2"></span></a>
                </div>
                <div *ngIf="subjectsSelected.subjects_code !== ''" class="ibox-title">Nhập điểm cho lớp
                    <b>{{classSelected.class_code}}</b> môn <b>{{subjectsSelected.subjects_name}}</b>
                </div>
                <div>


                    <div class="btn-highlight " [ngClass]="{'active' : isHighlight }">
                        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.isLockSetting == 1" (click)="saveAssessmentStudent()">
                            <div *ngIf="this.isLockSetting == 1">
                                <span class="btn-label"><span class="material-icons font-18">lock</span></span>
                            </div>
                            <div *ngIf="this.isLockSetting == 0">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><span
                                        class="material-icons font-18">save</span></span>
                            </div>
                            Lưu điểm
                        </button>
                    </div>


                    <div ngbDropdown class="d-inline-block">
                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon  ml-2" ngbDropdownToggle>
                            <span class="btn-label"><span class="material-icons font-18">list</span></span>Hành động
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [disabled]="true" ngbDropdownItem (click)="popup_export()" class="text-primary"><span class="material-icons mr-2">file_download
                                </span>
                                Tải tải file Excel</button>
                            <button [disabled]="true" ngbDropdownItem (click)="popup_import()" class="text-primary">
                                <span class="material-icons mr-2">file_upload </span>
                                Nhập file Excel
                            </button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="updateVisibleAssessment(0)" class="text-primary">
                                <span class="material-icons mr-2">visibility_off
                                </span>
                                Khóa xem điểm</button>
                            <button ngbDropdownItem (click)="updateVisibleAssessment(1)" class="text-primary">
                                <span class="material-icons mr-2">visibility
                                </span>
                                Mở xem điểm</button>
                            <button ngbDropdownItem (click)="sendAssessmentNotification()" class="text-primary">
                                <span class="material-icons mr-2">send
                                </span>
                                Thông báo xem điểm</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="deleteAssessmentStudent()" class="text-primary">
                                <span class="material-icons mr-2">clear
                                </span>
                                Xóa nhập lại</button>
                            <button ngbDropdownItem (click)="generateTBMHK()" class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Tính điểm TBM</button>

                            <button [disabled]="appService.hockySelected != 'II'" ngbDropdownItem (click)="generateTBMCN()" class="text-primary">
                                <span class="material-icons mr-2">add_task
                                </span>
                                Tính điểm TBM CN</button>

                        </div>
                    </div>



                </div>
            </div>
            <div class="ibox-body">
                <div class="row">
                    <table class="table table-hover">
                        <thead class="table-primary">
                            <tr>
                                <th class="stt">TT</th>
                                <th class="name">Học sinh</th>
                                <!-- <ng-container *ngIf="subject_type_mark == 'd'"> -->
                                <th *ngFor="let item of tx_numSetting; let i = index" style="width: 50px; position: relative; overflow: hidden;">
                                    <span>{{item.value}}</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>
                                <!-- </ng-container> -->
                                <!-- <ng-container *ngIf="subject_type_mark == 'nx'">
                                    <th *ngFor="let item of tx_numSetting; let i = index" style="width: 50px; position: relative; overflow: hidden;">
                                        <span>{{item.value}}</span>
                                        <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                            <span class="material-icons font-13 text-warning">lock</span>
                                        </a>
                                    </th>
                                </ng-container> -->
                                <th style="width: 50px; position: relative; overflow: hidden; background: #9ac4f3">
                                    <span>GK</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>
                                <th style="width: 50px; position: relative; overflow: hidden; background: #86b8f1">
                                    <span>CK</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>

                                <ng-container *ngIf="subject_type_mark == 'd'">
                                    <th style="width: 50px; position: relative; overflow: hidden; background: #71aef3; cursor: pointer;" (click)="popupCongThucTBM()">
                                        <span>TBM</span>
                                        <a class="lable-lock">
                                            <span class="material-icons font-11 text-info">info</span>
                                        </a>
                                    </th>
                                </ng-container>
                                <ng-container *ngIf="subject_type_mark == 'nx'">
                                    <th style="width: 50px; position: relative; overflow: hidden; background: #71aef3; cursor: pointer;">
                                        <span>HK</span>
                                        <!-- <a class="lable-lock">
                                            <span class="material-icons font-11 text-info">info</span>
                                        </a> -->
                                    </th>
                                </ng-container>

                                <th *ngIf="this.appService.hockySelected == 'II'" style="width: 75px; position: relative; cursor: pointer; overflow: hidden; background: #67a9f5" (click)="popupCongThucTBM()">

                                    <span>TBM CN</span>
                                    <a class="lable-lock">
                                        <span class="material-icons font-11 text-info">info</span>
                                    </a>
                                </th>
                                <th style="position: relative; overflow: hidden;">
                                    <span>Nhân xét</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>
                                <th *ngIf="this.appService.hockySelected == 'II'" style="position: relative; overflow: hidden;">
                                    <span>Nhân xét CN</span>
                                    <a *ngIf="this.isLockSetting == 1" class="lable-lock">
                                        <span class="material-icons font-13 text-warning">lock</span>
                                    </a>
                                </th>
                                <th style="width: 40px;">
                                    <label class="checkbox checkbox-primary check-single pt-1">
                                        <input type="checkbox" [(ngModel)]="checkboxAllButton"
                                            (change)="checkboxAll($event)">
                                        <span class="input-span"></span>
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of students; let i = index">
                                <td class="stt">{{i + 1}}</td>
                                <td class="name" style="text-align: left;">
                                    <div>
                                        <span style="font-size: 18px;">
                                            <span *ngIf="item.visible == 1" class="material-icons text-primary" style="font-size: 16px;">visibility
                                            </span>
                                        <span *ngIf="item.visible == 0" class="material-icons  text-default" style="font-size: 16px;">visibility_off
                                            </span>
                                        </span>
                                        {{item.full_name}}
                                    </div>
                                    <div class="font-11 text-muted">
                                        <span>{{item.code}}</span>
                                        <span class="badge-default badge-point mr-1 ml-1"></span>
                                        <span>{{item.birthday_str}}</span>
                                    </div>
                                </td>


                                <td *ngFor="let tx_item of tx_numSetting; let j = index">

                                    <!-- Điểm thường xuyên điểm -->
                                    <ng-container *ngIf="subject_type_mark == 'd'">
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+j" [name]="item.code + '-tx' + (j+1) " maxlength="3" (focus)="focusFunction('a'+ i +'-'+ j)" (focusout)="focusoutFunction(item['tx'+(j+1)],'a'+ i +'-'+ j)" (keypress)="numberOnly($event)"
                                            [(ngModel)]="item['tx'+(j+1)]" (ngModelChange)="onInputChange()">
                                    </ng-container>
                                    <!-- Điểm thường xuyên nhận xét -->
                                    <ng-container *ngIf="subject_type_mark == 'nx'">
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+j" [name]="item.code + 'tx'+(j+1)+'_nx' " maxlength="3" (focus)="focusFunction('a'+ i +'-'+ j)" (focusout)="focusoutNhanXetFunction('tx'+(j+1)+'_nx',i)" (keypress)="danhGiaNhanXet($event)"
                                            [(ngModel)]="item['tx'+(j+1)+'_nx']" (ngModelChange)="onInputChange()">
                                    </ng-container>


                                </td>


                                <!-- <ng-container *ngIf="subject_type_mark == 'd'">
                                    <td *ngFor="let tx_item of tx_numSetting; let j = index">
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1"
                                            [id]="'a'+ i +'-'+j" [name]="item.code + '-tx' + (j+1) " maxlength="3"
                                            (focus)="focusFunction('a'+ i +'-'+ j)"
                                            (focusout)="focusoutFunction(item['tx'+(j+1)],'a'+ i +'-'+ j)"
                                            (keypress)="numberOnly($event)" [(ngModel)]="item['tx'+(j+1)]"
                                            (ngModelChange)="onInputChange()">
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="subject_type_mark == 'nx'">
                                    <td *ngFor="let tx_item of tx_numSetting; let j = index">
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1"
                                            [id]="'a'+ i +'-'+j" [name]="item.code + 'tx'+(j+1)+'_nx' " maxlength="3"
                                            (focus)="focusFunction('a'+ i +'-'+ j)"
                                            (focusout)="focusoutNhanXetFunction('tx'+(j+1)+'_nx',i)"
                                            (keypress)="danhGiaNhanXet($event)" [(ngModel)]="item['tx'+(j+1)+'_nx']"
                                            (ngModelChange)="onInputChange()">
                                    </td>
                                </ng-container> -->


                                <!-- dành cho môn đánh giá điểm -->
                                <ng-container *ngIf="subject_type_mark == 'd'">
                                    <!-- Điểm giữa kỳ -->
                                    <td>
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+ (tx_numSetting.length)" [name]="item.code + '-gk'" maxlength="3" (focus)="focusFunction('a'+ i +'-'+ (tx_numSetting.length))" [(ngModel)]="item.gk" (focusout)="focusoutFunction(item.gk,'a'+ i +'-'+ (tx_numSetting.length))"
                                            (keypress)="numberOnly($event)" (ngModelChange)="onInputChange()">
                                    </td>
                                    <!-- Điểm cuối kỳ -->
                                    <td>
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+ (tx_numSetting.length + 1)" [name]="item.code + '-ck'" maxlength="3" (focus)="focusFunction('a'+ i +'-'+ (tx_numSetting.length + 1))" [(ngModel)]="item.ck" (focusout)="focusoutFunction(item.ck,'a'+ i +'-'+ (tx_numSetting.length+1))"
                                            (keypress)="numberOnly($event)" (ngModelChange)="onInputChange()">
                                    </td>
                                    <!-- Điểm TBM -->
                                    <td class="text-center col-tbmhk">
                                        <input class="mark_input" [id]="'a'+ i +'-tbm'" [name]="item.code + '-tbm'" [disabled]="true" [value]="item.tbm">
                                    </td>
                                </ng-container>
                                <!-- dành cho môn đánh giá nhận xét -->
                                <ng-container *ngIf="subject_type_mark == 'nx'">
                                    <!-- Điểm giữa kỳ -->
                                    <td>
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+ (tx_numSetting.length)" [name]="item.code + '-gk'" maxlength="3" (focus)="focusFunction('a'+ i +'-'+ (tx_numSetting.length))" [(ngModel)]="item.gk_nx" (focusout)="focusoutNhanXetFunction('gk_nx',i)"
                                            (keypress)="danhGiaNhanXet($event)" (ngModelChange)="onInputChange()">
                                    </td>
                                    <!-- Điểm cuối kỳ -->
                                    <td>
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-'+ (tx_numSetting.length + 1)" [name]="item.code + '-ck'" maxlength="3" (focus)="focusFunction('a'+ i +'-'+ (tx_numSetting.length + 1))" [(ngModel)]="item.ck_nx" (focusout)="focusoutNhanXetFunction('ck_nx',i)"
                                            (keypress)="danhGiaNhanXet($event)" (ngModelChange)="onInputChange()">
                                    </td>
                                    <!-- Điểm TBM -->
                                    <td class="text-center col-tbmhk">
                                        <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-tbm_nx'" [name]="item.code + '-tbm_nx'" (focusout)="focusoutNhanXetFunction('tbm_nx',i)" [(ngModel)]="item.tbm_nx" (keypress)="danhGiaNhanXet($event)" (ngModelChange)="onInputChange()">
                                    </td>
                                </ng-container>


                                <!-- Điểm TBM CN -->
                                <td *ngIf="this.appService.hockySelected == 'II'" class="text-center  col-tbmcn">
                                    <input class="mark_input" [id]="'a'+ i +'-tbm_cn'" [name]="item.code + '-tbm_cn'" [disabled]="true" [value]="item.tbm_cn">
                                </td>

                                <!-- Nhận xét -->
                                <td>
                                    <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-nx'" [name]="item.code + '-nx'" maxlength="500" (focus)="focusFunction('a'+ i +'-nx')" [(ngModel)]="item.nx" (ngModelChange)="onInputChange()">
                                </td>
                                <!-- Nhận xét CN-->
                                <td *ngIf="this.appService.hockySelected == 'II'">
                                    <input class="mark_input" [disabled]="this.isLockSetting == 1" [id]="'a'+ i +'-nx_cn'" [name]="item.code + '-nx_cn'" maxlength="500" (focus)="focusFunction('a'+ i +'-nx_cn')" [(ngModel)]="item.nx_cn" (ngModelChange)="onInputChange()">
                                </td>
                                <td class="text-center">
                                    <label class="checkbox checkbox-primary check-single">
                                        <input class="mail-check" type="checkbox" [(ngModel)]="checkboxs[i]">
                                        <span class="input-span"></span>
                                    </label>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div *ngIf="students ==  undefined || students.length == 0" class="text-center text-warning m-5 p-5">
                    <img style="width: 80px;" src="assets\img\icons\finder.png" alt="">
                    <div>Thầy/Cô chọn lớp và chọn môn để vào điểm </div>
                </div>
            </div>
            <!-- </div> -->

        </div>
    </div>



    <div class="modal fade" id="import-student-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content form-horizontal" id="form-import-student">
                <div class="modal-header p-4">
                    <h5 class="modal-title">Nhập Học sinh từ file Excel</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-4">

                    <div *ngIf="!import_result.result">
                        <div class="form-group mb-4">
                            <label class="btn btn-primary  mr-2" style="width: 100%;">
                                <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                                <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                    (change)="changeListener($event)">
                            </label>
                        </div>
                        <div class="form-group mb-4">
                            <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                            </a>
                        </div>
                        <div class="form-group mb-4">
                            <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                            </div>
                        </div>
                    </div>

                    <div *ngIf="import_result.result">
                        <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                        <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                    </div>

                </div>
                <div class="modal-footer justify-content-center bg-primary-50">

                    <div class="row align-items-center">
                        <div class="col text-center">

                            <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadStudentExcel()" [disabled]="isLoading">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                                Import</button>

                            <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>