<form class="">
    <div class="p-2">
        <div class="modal-header p-2">
            <h5 class="modal-title">{{type_name}}</h5>
        </div>
        <div class="modal-body p-2 mt-3 mb-3">
            <div class="row">
                <div class="col">

                    <mat-form-field class="" appearance="fill">
                        <mat-label>Ngày</mat-label>
                        <input matInput [matDatepicker]="picker_u_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="checkin_date" name="u_startTime" required>
                        <mat-datepicker-toggle matSuffix [for]="picker_u_startTime"></mat-datepicker-toggle>
                        <mat-datepicker #picker_u_startTime></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Giáo viên</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="item.teacher_code" (selectionChange)="changeTeacher()" name="teacher_code">
                            <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-1 classAddCover" [ngClass]="{'show' : classAddMode}">
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Khối/Cơ sở</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="classAddKhoiCoso" name="subject_code">
                            <mat-option *ngFor="let item of khoicosos" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                        <mat-form-field class="mr-2" appearance="fill">
                            <mat-label>Tên lớp</mat-label>
                            <input matInput type="text" [(ngModel)]="classAddName" name="classAddName" maxlength="15">
                        </mat-form-field>
                        <button *ngIf="appService.checkFullPermission()" (click)="addAndSelectClass()" class="btn btn-primary btn-icon-only mr-2" placement="top" ngbTooltip="Lưu và chọn" style="height: 45px;">
                            <span class="btn-label">
                                <span class="material-icons"> task_alt </span>
                            </span>
                        </button>
                        <button *ngIf="appService.checkFullPermission()" (click)="changeModeClassAdd(false)" class="btn btn-white btn-icon-only" style="height: 45px;">
                            <span class="btn-label">
                                <span class="material-icons"> close </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row  mt-3">
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3" placement="top" [ngbTooltip]="subject_list.length == 0 ? 'Môn được phân công ở menu PCGD':''">
                        <mat-label>Môn dạy</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="item.subject_code" name="subject_code">
                            <mat-option *ngFor="let item of subject_list" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                        <mat-form-field appearance="fill" class="mr-1" placement="top" [ngbTooltip]="class_list.length == 0 ? 'Lớp được phân công ở menu PCGD':''">
                            <mat-label>Lớp dạy</mat-label>
                            <mat-select [disabled]="false" [(ngModel)]="item.class_code" name="class_code">
                                <mat-option *ngFor="let item of class_list" [value]="item.code" [disabled]="false">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button *ngIf="appService.checkFullPermission()" (click)="changeModeClassAdd(true)" class="btn btn-icon-only" placement="top" ngbTooltip="Thêm mới Lớp học" style="height: 45px;">
                            <span class="btn-label">
                                <span class="material-icons"> add </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ bắt đầu</mat-label>
                        <input matInput type="time" [(ngModel)]="item.checkin_start" placeholder="Giờ vào" name="checkin_start" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ kết thúc</mat-label>
                        <input matInput type="time" [(ngModel)]="item.checkin_end" placeholder="Giờ ra" name="checkin_end" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>
                </div>
                <div class="ml-1" style="width: 100px;">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Thời lượng</mat-label>
                        <input matInput type="text" [(ngModel)]="duration" name="thoiluong" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col type-cover">
                    <button (click)="selectType(0)" [ngClass]="item.type == 0 ? 'btn-primary':'btn-secondary'" class="btn btn-labeled btn-labeled-left btn-icon mr-2" placement="top" ngbTooltip="Học trực tiếp">
                        <span class="btn-label">
                            <img class="m-1" src="./assets/img/icons/location.png" alt="image" width="20" />
                        </span>
                        <span>Học trực tiếp</span>
                    </button>
                    <button (click)="selectType(1)" [ngClass]="item.type == 1 ? 'btn-primary':'btn-secondary'" class="btn btn-labeled btn-labeled-left btn-icon mr-2" placement="top" ngbTooltip="Học Online">
                        <span class="btn-label">
                            <img class="m-1" src="./assets/img/icons/meet.png" alt="image" width="20" />
                        </span>
                        <span>Học qua Google Meet</span>
                    </button>
                    <button (click)="selectType(2)" [ngClass]="item.type == 2 ? 'btn-primary':'btn-secondary'" class="btn btn-labeled btn-labeled-left btn-icon" placement="top" ngbTooltip="Học Online">
                        <span class="btn-label">
                            <img class="m-1" src="./assets/img/icons/zoom.png" alt="image" width="20" />
                        </span>
                        <span>Học qua Zoom</span>
                    </button>
                </div>

                <!-- <div class="btn-group mb-3">
                    <div class="btn btn-primary">
                        <span class="btn-icon">
                            <img class="m-1" src="./assets/img/icons/meet.png" alt="image" width="20" />
                        </span> Học trực tiếp
                    </div>
                    <div class="btn btn-default">
                        <span class="btn-icon"><img class="m-1" src="./assets/img/icons/meet.png" alt="image" width="20" /></span> Học qua Google Meet
                    </div>
                    <div class="btn btn-default">
                        <span class="btn-icon"><img class="m-1" src="./assets/img/icons/zoom.png" alt="image" width="20" /></span> Học qua Zoom
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-around bg-primary-50">
        <button (click)="okClick()" class="btn btn-primary ">
            {{action=='edit'?'Cập nhật': action=='copy'?'Copy': 'Tạo mới'}}
        </button>
        <button (click)="cancelClick()" class="btn">
            Bỏ qua
        </button>
    </div>
</form>