
export class Promotion {
    name : string = "";
    code: string ="";
    type: string ="";
    promotion :number = 0;
    description:string ="";
    address:string ="";
    time_start:string ="";
    time_end:string ="";
    cate_join:string ="";
    cateCustomer_join:string ="";
    constructor(type) {
       this.type = type;
    }
}
