import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private subject = new Subject<any>();
  // private subject2 = new Subject<any>();
  
  constructor() { }

  sendClickEvent(type) {
    this.subject.next(type);
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  // sendClickEvent_loadCustomer(type) {
  //   this.subject2.next(type);
  // }

  // getClickEvent_loadCustomer(): Observable<any> {
  //   return this.subject2.asObservable();
  // }
  
}
