<!-- <div class="page-heading">
    <h1 class="page-title">Danh sách Học sinh</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="#"><span class="material-icons sidebar-item-icon-material">home</span></a>
        </li>
        <li class="breadcrumb-item">Danh sách Học sinh</li>
    </ol>
</div> -->

<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start align-items-center ">


                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm học sinh</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên học sinh" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>


                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Khối</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                            {{item.name}} - ({{item.count_class}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.class_code">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of classs" [value]="item.code" (click)="category_class_change(item)" [disabled]="!appService.checkFullPermission() && !appService.checkClassChunhiem(item.chunhiem_code) ">
                            {{item.name}} - ({{item.student_count}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Giới tính</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.gender" (selectionChange)="category_change($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.genders" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Xếp theo</mat-label>
                    <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)">

                        <mat-option *ngFor="let item of appService.sort_students" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.gender" (change)="category_change($event)" name="filter_gender" id="filter_gender" style="width: 92px;">
                        <option value="">Giới tính</option>
                        <option [ngValue]="item.code" *ngFor="let item of appService.genders">{{item.value}}</option>
                    </select>
                </div> -->
                <!-- <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.deleted" (change)="category_change($event)" name="filter_gender" id="filter_gender">
                        <option value="">Trạng thái</option>
                        <option [ngValue]="item.code" *ngFor="let item of appService.statuss">{{item.value}}</option>
                    </select>
                </div> -->
                <!-- <div class="p-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_students">{{item.name}}
                        </option>
                    </select>
                </div> -->
                <!-- <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.faculty_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Tổ</option>
                        <option [ngValue]="item.code" *ngFor="let item of facultys">{{item.name}}</option>
                    </select>
                </div>
                <div class="p-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_students">{{item.name}}
                        </option>
                    </select>
                </div> -->
            </div>
        </div>
        <div class="col p-0 d-flex justify-content-end  align-items-center ">

            <!-- <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
                <mat-label>Chu kỳ</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field> -->

            <mat-form-field appearance="fill" style="width: 180px; margin-bottom: 0px;" class="ml-2 select-highline">
                <mat-label>Chu kỳ</mat-label>
                <mat-select [(ngModel)]="selectedDateStr" (selectionChange)="selectMonth($event)">
                    <mat-option *ngFor="let item of monthList" [disabled]="!item.isAvailable" [value]="item.month_full">
                        Tháng {{item.month}}
                    </mat-option>
                </mat-select>
            </mat-form-field>



        </div>

    </div>



    <!-- <div class="class-section">

        <div class="cont-checkbox" *ngFor="let item of classs; index as i">
            <input type="radio" [id]="'myCheckbox-' + i" [name]="'myCheckbox'" [checked]="filter.class_code==item.code" (change)="classRadioCheck(item.code)" />
            <label [for]="'myCheckbox-' + i">
                   
                      <span class="cover-checkbox">
                        <svg viewBox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <div class="info">{{ item.name }}</div>
                      <div class="body text-center  font-13">
                  
                        <span><i class="fa fa-address-book-o text-primary mb-0 mr-2"></i><span >{{item.student_count == null ? 0: item.student_count}}</span></span>

                       </div>
                    </label>
        </div>
    </div> -->
    <div class="row align-items-center mb-2">
        <div class="col">
            <!-- <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span> -->
            <!-- <button class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                    <span class="btn-label"> <img style="width: 23px; opacity: 0.4;" src="assets\img\icons\check-list.png" alt=""></span>
                    Chưa nhập
                </button> -->
            <button class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                    <span class="btn-label"> <span class="material-icons text-warning "> check_circle </span></span>
                    Đã nhập
                </button>
            <button class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                    <span class="btn-label"> <span class="material-icons text-warning "> send </span></span>
                    Đã gửi
                </button>
            <button (click)="popupDetailClass()" class="btn btn-label btn-secondary  btn-labeled btn-labeled-left  mr-2">
                    <span class="btn-label"> <span class="material-icons text-warning "> comment </span></span>
                    PHHS phản hồi
                </button>
            <button class="btn btn-label btn-secondary  btn-labeled btn-labeled-left  mr-2">
                    <span class="btn-label"> <span class="material-icons"> attach_file </span></span>
                    Đính kèm
                </button>
        </div>
        <div class="col d-flex justify-content-end">


            <div ngbDropdown class="d-inline-block">
                <button class="blurButton btn btn-primary btn-icon" ngbDropdownToggle>
                    <span class="btn-label"><span class="material-icons"> menu </span></span>
                    {{period_name != '' ? period_name : 'Chu kỳ'}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                    <ng-container *ngIf="appService.currentUser.school_code == '44000808' && cap_hoc == 'THCS'" class="align-items-center">
                        <ng-container *ngFor="let item of dataService.period_thcs_list; let i = index">
                            <button ngbDropdownItem (click)="selectPeriod_time(item.code, item.name,'THCS')" class="text-primary" [ngClass]="{'bg-warning text-white' : this.period_name == item.name}">
                                <span *ngIf="this.period_name != item.name" class="material-icons mr-2"> date_range </span>
                                <span *ngIf="this.period_name == item.name" class="material-icons mr-2"> task_alt </span>
                                {{item.name}}
                            </button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="appService.currentUser.school_code == '44000808' && cap_hoc == 'TH'" class="align-items-center">

                        <ng-container *ngFor="let item of dataService.period_th_list; let i = index">
                            <button ngbDropdownItem (click)="selectPeriod_time(item.code, item.name, 'TH')" class="text-primary" [ngClass]="{'bg-warning text-white' : this.period_name == item.name}">
                                <span *ngIf="this.period_name != item.name" class="material-icons mr-2"> date_range </span>
                                <span *ngIf="this.period_name == item.name" class="material-icons mr-2"> task_alt </span>
                                {{item.name}}
                            </button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="appService.currentUser.school_code != '44000808'" class="align-items-center">
                        <ng-container *ngFor="let item of dataService.period_thcs_list; let i = index">
                            <button ngbDropdownItem (click)="selectPeriod_time(item.code, item.name,'THCS')" class="text-primary" [ngClass]="{'bg-warning text-white' : this.period_name == item.name}">
                                <span *ngIf="this.period_name != item.name" class="material-icons mr-2"> date_range </span>
                                <span *ngIf="this.period_name == item.name" class="material-icons mr-2"> task_alt </span>
                                {{item.name}}
                            </button>
                        </ng-container>
                    </ng-container>

                    <div class="dropdown-divider"></div>
                    <!-- <button ngbDropdownItem (click)="openNhapNhanhKetQua()" placement="top" ngbTooltip="Chọn lớp để thao tác" class="text-secondary">
                        <span class="material-icons mr-2">
                            toc
                        </span>
                        Nhập nhanh cả lớp</button> -->
                    <button ngbDropdownItem (click)="openImport()" [disabled]="cap_hoc == 'TH' || !(filter.class_code !='' && period_name !='')" class="text-primary">
                        <span class="material-icons mr-2">
                            file_upload
                        </span>
                        Upload file Excel</button>
                    <button ngbDropdownItem (click)="popup_student_assessment_export()" [disabled]=" cap_hoc == 'TH' || !(filter.class_code !='' && period_name !='')" class="text-primary">
                        <span class="material-icons mr-2">
                            file_download
                        </span>
                        Tải file Excel</button>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-student">

            <div class="row-header bg-primary d-flex justify-content-between align-items-center">
                <div>Học sinh</div>
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon mr-2" style="background-color: #0000003d;" (click)="confirmSend()">
                    <span class="btn-label"><span class="material-icons"> send </span></span>
                    Gửi PHHS
                </button>
            </div>
            <div class="student-item-cover qbs-scroll">
                <div *ngFor="let item of this.students; let i = index" class="student-item" [ngClass]="{'student-selected':  student_selected.code == item.code}" (click)="click_student(item)">
                    <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                    <div style="font-size: 18px;">
                        <span *ngIf="item.gender == 1" class="material-icons text-blue ">boy</span>
                        <span *ngIf="item.gender == 0" class="material-icons text-pink ">girl</span> {{item.full_name}}
                    </div>
                    <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                        {{item.code}} <span class="badge-point badge-point"></span> {{item.birthday_str}}
                    </div>
                    <div class="period_status">

                        <span *ngIf="item.period_attached_file != ''" class="mr-1 btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons "> attach_file </span>
                        </span>

                        <!-- <span *ngIf="item.period_ykien_ph == ''" style="opacity: 0.2;" class="mr-1 btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons "> comment </span>
                        </span> -->
                        <span *ngIf="item.period_ykien_ph != ''" class="mr-1 btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons text-warning"> comment </span>
                        </span>

                        <span *ngIf="item.period_status == -1" style="opacity: 0.2;" class="btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons "> check_circle </span>
                        </span>
                        <span *ngIf="item.period_status == 0" class="btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons text-warning"> check_circle </span>
                        </span>
                        <span *ngIf="item.period_status > 0" class="btn btn-xsm btn-white btn-icon-only btn-circle btn-thick"><span class="material-icons text-warning"> send </span>
                        </span>

                        <!-- <span *ngIf="item.period_ykien_ph == ''" style="opacity: 0.2;" class="material-icons text-warning"> chat </span>
                        <span *ngIf="item.period_ykien_ph != ''" class="material-icons text-warning"> chat </span> -->
                        <!-- <img *ngIf="item.period_status == -1" style="width: 20px; opacity: 0.4;" src="assets\img\icons\check-list.png" alt="">
                        <img *ngIf="item.period_status == 0" style="width: 20px;" src="assets\img\icons\check-list-mark.png" alt="">
                        <img *ngIf="item.period_status == 1" style="width: 20px;" src="assets\img\icons\check-list-send.png" alt=""> -->
                    </div>
                </div>
                <div *ngIf="!isLoading_student && students.length == 0" class="text-center text-warning mt-4">
                    <img style="width: 50px;" src="assets\img\icons\finder.png" alt="">
                    <div>Thầy/Cô chọn lớp ở trên</div>
                </div>

                <div *ngIf="isLoading_student && students.length == 0" class="spinner-loadClass mt-5 mb-5 p-2">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </div>

        </div>
        <div class="col">


            <app-assessment-period-input [student_code]="student_selected.code" [class_code]="filter.class_code" [subjects]="subjects" [subjects_origin]="subjects" [student_full_name]="student_selected.full_name" [selectedDateStr]="selectedDateStr" [cap_hoc]="cap_hoc"
                [period_code]="period_code" [period_name]="period_name" (studentChange)="doStudentChange($event)"></app-assessment-period-input>



        </div>
    </div>


</div>





<div class="modal fade" id="import-student-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập đánh giá học sinh từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4 text-primary">
                        Lớp <span class="badge badge-primary">{{filter.class_code}}</span> sổ điểm <span class="badge badge-primary">{{this.period_name}}</span> tháng <span class="badge badge-primary">{{this.selectedDateMMYYYYStr}}</span>
                    </div>
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4" style="font-size: large;">
                        <a class="text-warning" (click)="popup_student_assessment_export()"> <span class="material-icons">file_download</span> Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>
                <div *ngIf="!import_result.result && import_result.msg != ''" class="text-center">
                    <div class="text-danger">Có lỗi xảy ra: {{import_result.msg}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadAssessmentPeriodExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> file_upload </span></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>