<div class="p-4">

    <form class=" form-horizontal" #resetUserForm="ngForm" id="form-resetUserForm">
        <div style="font-size: larger;" class="mb-2">Đặt lại mật khẩu</div>
        <div class="mb-2">Phụ huynh: {{userSelected.name}} - {{userSelected.phone}}</div>
        <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Mật khẩu</mat-label>
            <input matInput [(ngModel)]="password" placeholder="Mật khẩu" name="pass" [type]="hide ? 'password' : 'text'" required maxlength="100">
            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
        </mat-form-field>
        <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Nhập lại mật khẩu</mat-label>
            <input matInput [(ngModel)]="password_retype" placeholder="Nhập lại mật khẩu" name="password_retype" [type]="hide ? 'password' : 'text'" required maxlength="100">
            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
        </mat-form-field>

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-between ">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.resetUserForm.invalid" (click)="updateUser()">
                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                    task_alt
                    </span></span>
                Cập nhật
            </button>
            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>