<div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start align-items-center">


        <div class="row lop-cover">
            <div (click)="selectCamera(-1)" class="card text-center card-air lop-item" [ngClass]="{'camera_selected' : -1 == camerasSelectedIndex}">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="mr-1">
                            <img src="./assets/img/icons/select-all.png" style="height: 28px;" alt="Avatar">
                        </div>
                        <div class="text-primary">Tất cả</div>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of cameras; let i = index" (click)="selectCamera(i)" class="card text-center card-air lop-item" [ngClass]="{'camera_selected' : i == camerasSelectedIndex}">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="card-avatar mr-1">
                            <img src="./assets/img/icons/camera.png" alt="Avatar">
                        </div>
                        <div class="text-primary">{{item.deviceName}}</div>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="col-4  p-0 d-flex justify-content-end align-items-center">
        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>






<div>
    <span class="font-18">
        Tổng số: {{this.personUnnkownList.length}}
    </span>
</div>

<div class="row lop-cover">
    <div *ngFor="let item of this.personUnnkownList; let i = index" (click)="selectPerson(item)" class="card text-center card-air  m-1 lop-item" [ngClass]="{'dbg-primary-100': i % 2 == 0 }">

        <div class="d-flex align-items-center justify-content-between">
            <span class="stt_td">
                {{i+1}}
            </span>

            <span class="badge badge-primary badge-pill">{{item.deviceNames[0]}}</span>
        </div>

        <div class="">
            <div style="width:130px; overflow-x:scroll; position: relative;" class="qbs-scroll">
                <table>
                    <tr>
                        <td *ngFor="let item2 of item.checkin_times; let j = index" style="vertical-align: top;">
                            <a style="min-width: 115px; display: block">
                                <img [src]="item.detected_image_urls[j]" />
                                <div>{{item2}}</div>
                            </a>
                        </td>
                    </tr>
                </table>
                <span class="image_count">
                    
                    <span class="badge badge-warning  badge-circle">{{item.image_count}}</span>
                </span>
            </div>

            <!-- <div style="max-height: 166px;  width:100px;overflow-x:scroll; " class="qbs-scroll">
                <div *ngFor="let item2 of item.checkin_times; let j = index" class="times-item-cover">
                    <a>
                        <img [src]="item.detected_image_urls[j]" />
                        <div>{{item2}}</div>
                    </a>
                </div>
            </div> -->
            <!-- <div style="max-height: 166px;  overflow: auto;" class="qbs-scroll">
                <div *ngFor="let item2 of item.checkin_times; let j = index" class="times-item-cover">
                    <a>
                        <img [src]="item.detected_image_urls[j]" />
                        <div>{{item2}}</div>
                    </a>
                </div>
            </div> -->

        </div>
    </div>
</div>


<!-- <div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
    <span color="primary">Tổng sỹ số: </span> {{allSchools.student.student_count}}
    <span color="primary">Vắng: </span> 5
    <span color="primary">Tỷ lệ: </span> 97%
</div> -->




<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<app-nodata *ngIf="!isLoading && this.personUnnkownList.length ==0" msg="Không tìm thấy dữ liệu"></app-nodata>