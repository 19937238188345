
import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from 'src/app/_services/app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-desk-change',
  templateUrl: './desk-change.component.html',
  styleUrls: ['./desk-change.component.css']
})
export class DeskChangeComponent implements OnInit {


  public deskToIndexSelected = -1;
  deskPriceIndexSelected = 1;

  public deskFromIndex;
  public is_time_service;

  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<DeskChangeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {

    // index bàn nguồn
    this.deskFromIndex = this.data.index;
    this.is_time_service = this.data.is_time_service;
    //chọn giá theo giờ.
    this.deskPriceIndexSelected = this.utilityService.getCurrentTimeService();
    console.log("this.deskPriceIndexSelected",this.deskPriceIndexSelected);

  
  }

  ngOnInit(): void {
  }
   


  onCancelClick(): void {
    this.dialogRef.close();
  }
  onOkClick(): void {

    if(this.deskToIndexSelected < 0){
        return;
    }
    let result = {
      code: "ok",
      data: {
        deskPrice : this.appService.deskListPrice[this.deskPriceIndexSelected],
        deskToIndex : this.deskToIndexSelected
      }
    }
    this.dialogRef.close(result);
  }

}
