import { UserService } from './../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { SaleService } from '../../_services/sale.service';
import { CartObj } from '../../model/cart-obj';
import { NgbDate, NgbDateParserFormatter, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentService } from 'src/app/_services/student.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MealService } from 'src/app/_services/meal.service';
declare var $: any;

@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.css']
})
export class MealComponent implements OnInit {
  isLoading = false;

  allSchools = {
    student_count: 0,
    student_checkin: 0,
    percent: 0,
  }

  //for data

  classs: []
  students = [];
  students_backup: any
  categorys: any
  facultys: any
  filter = {
    name: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  dateRange = [];
  curentMonday: Date;

  reportcheckins: any;
  khoiSelected = -1;


  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  //for data



  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[4].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: '',
  }
  //
  //for checkbox
  khoicosos = [];
  meal_count = 0;
  // checkboxAllButton = false;
  constructor(
    private schoolService: SchoolService,
    private mealService: MealService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private toastr: ToastrService,
    public userService: UserService,
    public studentService: StudentService,
    public formatter: NgbDateParserFormatter,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Quản lý ăn bán trú";
    // config.backdrop = 'static';
    // config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadClasss();
    this.loadCurrenMonday(new Date());
    this.loadKhoicoso();

  }
  onDateSelect($event) {

    // var temp = this.utilityService.getDateFromDDMMYYY(this.fromDateStr)
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
      this.loadCurrenMonday(this.fromDate);
    this.loadStudent();
  }
  loadKhoicoso() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let filter = {
        type : environment.cateType_khoicoso
    }
    let sorter = {
      sortStr: 'od'
    }
    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
            this.khoicosos = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }


  category_khoi_change(event) {
    this.loadClasss();
  }
  category_change(event) {
    this.loadStudent();
  }
  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          // lấy lớp đầu tiên để load student
          response.forEach(item => {
            if (item.code == item.class_code_assignment) {
              this.filter.class_code = item.code;
              return;
            }
          });
          this.classs = response;
          this.loadStudent();
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  loadStudent() {
    if (this.filter.class_code == '') {
      this.toastr.info("Thầy cô vui lòng chọn lớp");
      return;
    }
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      // this.checkboxs = [];
      // this.checkboxAllButton = false;
      this.isLoading = true;
      this.studentService.loadDoCheckInMeal(this.filter, this.sorter, pagingObj, this.selectedDateStr).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.meal_count = 0;
            response.data.forEach(element => {

              //temp for show note or image checkin
              element.temp_showNote = false;

              //meal
              if (element.meal == 1 && element.meal_id == 0) {// đăng ký meal
                element.meal_temp = true
              } else {
                element.meal_temp = false;
              }

              if(element.meal_temp) this.meal_count++;
            });
            this.students = response.data;
            this.students_backup = response.data;
            // this.checkboxs = new Array(this.students.length);
            // this.appService.students = response.data;
            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.students.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {
          this.isLoading = false;
        })
    }
  }




  ShowNote(item){
    console.log(item);
    item.temp_showNote = !item.temp_showNote;
    console.log(item);
  }

  loadCurrenMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if (curr.getDate() == first) first = first - 6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
        }
      );
    }
    console.log(this.dateRange);
  }

  ngAfterViewInit() {
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadStudent();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  }


  confirmDoCheckIn() {
    let data = {
      title: 'Xác nhận cập nhật ăn bán trú ngày ' + this.selectedDateStr,
      msg: '',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.doCheckInMeal();

      }
    });
  }

  saveNote(item){
    if(this.isLoading) return;
    if(item.meal_note == null || item.meal_note.trim() == ''){
      this.toastr.warning("Thầy cô chưa nhập ghi chú");
        return;
    }
    if(item.meal_id == 0 && item.meal_temp){
      this.toastr.warning("Chỉ nhập ghi chú khi học sinh báo vắng bán trú");
        return;
    }

    let param = {
      meal_id : item.meal_id,
      meal_status : item.meal_temp ? 0 : 1,
      student_code : item.code,
      meal_date : this.selectedDateStr,
      meal_note : item.meal_note,
    }
    this.isLoading  = true;
    this.mealService.updateMealStudent(param).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Cập nhật ghi chú thành công");
          this.loadStudent();
        }else{
          this.isLoading  = false;
        }
      },
      error => {
        this.isLoading  = false;
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  doCheckInMeal() {
    

    let data = [];
    this.students.forEach(element => {
      // Nếu có thay đổi so với origin
      if (element.meal == 1){ // chỉ áp dụng với học sinh bán trú
          data.push({
            student_code : element.code,
            meal_id : element.meal_id != undefined ? element.meal_id : 0, // student left join meal
            meal_note : "",
            status : element.meal_temp,
          });
      }
    });

    if(data.length == 0){
      this.toastr.warning("Không có học sinh đăng ký");
      return;
    }
    console.log(data);
    let param = {
      meal_date : this.selectedDateStr,
      data: data
    }
    this.isLoading = true;
    this.mealService.saveUpdate(param).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok' && (response.count_insert > 0 || response.count_update > 0)) {
          this.toastr.success("Cập nhật ăn bán trú theo ngày thành công");
          
        }else if(response.code != 'ok'){
          this.toastr.warning(response.msg);
        }
        this.loadStudent();
        
      },
      error => {
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      })
    console.log(data);
  }

  updateAttention_count() {
    this.meal_count = 0;
    this.students.forEach(element => {
      if (element.meal_temp) {
        this.meal_count++;
      }
    });
  }



  // popupSaleDetail(item) {
  //   $("#sale-detail-modal").modal('show');
  //   this.saleSelectedItem = item;
  //   try {
  //     this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);

  //   } catch (error) {
  //     this.saleSelectedItem.service_details = [];
  //     console.error(error);
  //   }
  //   this.loadCartDetail(this.saleSelectedItem.id);
  // }
  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadStudent();
    // this.loadSales(this.selectedDateStr);
  }




}

