<div class="page-content fade-in-up">
  <div class="row mb-1 ">
    <div class="col font-weight-bold">Doanh thu: {{this.appService.deskRevenue | number}} </div>
    <div class="col font-weight-bold text-right">Đang phục vụ {{this.appService.deskActive}} </div>
  </div>
  <div class="desk-boundary">
    <div  *ngFor="let item of this.appService.deskList; let i = index" class="desk-box" cdkDragBoundary=".desk-boundary"
      cdkDrag [(cdkDragFreeDragPosition)]="item.position" [cdkDragDisabled]="true"
      [ngClass]="{'desk-active': i == appService.deskIndex, 'desk-on-service': (item.cart.length > 0 || item.cart_service.length > 0 ) }">
      <div style="align-items: center; display: flex; height: 28px;">

        <span *ngIf="(item.cart.length == 0  && item.cart_service.length == 0 )" class="material-icons"
          style="color: gray;">
          circle_notifications
        </span>
        <span *ngIf="(item.cart.length > 0 || item.cart_service.length > 0 )" class="material-icons"
          style="color: #0388d1;">
          circle_notifications
        </span>
        <span> {{item.name}}</span>
        <a *ngIf="(item.cart.length > 0 || item.cart_service.length > 0 )" class="change-desk-icon"
          (click)="openSelectDeskChange(i, item.is_time_service)">
          <span style="font-size: 16px;" class="material-icons">
            sync_alt
          </span>
        </a>
      </div>
      <div class="pt-2 pb-2" style="font-weight: bold; text-align: right;">
        {{item.price_totol|number}}
      </div>
      <div>
        <mat-icon *ngIf="item.total_quantity > 0" [matBadge]="item.cart.length" matBadgeColor="accent">reorder
        </mat-icon>
        <!-- <mat-icon *ngIf="item.total_quantity == 0"  [matBadge]="0" matBadgeColor="secondary" matBadgeSize="small">reorder</mat-icon> -->
      </div>
      <div class="action-desk">
          <a *ngIf="item.is_time_service == 1" class="pr-2" (click)="openSelectTimePrice(selectTimePriceModel,i)">
            <span style="font-size: 36px;" class="material-icons">
              timer
            </span>
          </a>

          <a class="p-1" (click)="openSelectProductv2(selectProductModel,i)">
            <span style="font-size: 36px;" class="material-icons">
              playlist_add
            </span>
          </a>
          <a class="pl-2" (click)="opendCheckout(i)">
            <span style="font-size: 36px;" class="material-icons">
              request_quote
            </span>
          </a>
      </div>
    </div>
  </div>

</div>


<ng-template #selectProductModel let-c="close" let-d="dismiss">
  <form class="modal-content form-horizontal" id="openSelectProduct">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Chọn thực đơn</h5>
      <button type="button" class="btn btn-sm btn-primary"
        (click)="this.appService.updateDeskRevenue(); c('Save click')">Đóng</button>
    </div>
    <div class="modal-body">
      <app-desksale></app-desksale>
    </div>
    <div class="modal-footer">
      <!-- <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
        Cập nhật
      </button> -->
      <button type="button" class="btn btn-sm btn-primary"
        (click)="this.appService.updateDeskRevenue(); c('Save click')">Đóng</button>
    </div>
  </form>
</ng-template>
<ng-template #selectTimePriceModel let-c="close" let-d="dismiss">
  <form class="modal-content form-horizontal" id="openSelectProduct">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Chọn giá dịch vụ</h5>
    </div>
    <div class="modal-body">
      <app-servicedeskprice></app-servicedeskprice>
    </div>
    <div class="modal-footer">
      <!-- <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
        Cập nhật
      </button> -->
      <button type="button" class="btn btn-sm btn-primary" (click)="c('Save click')">Đóng</button>
    </div>
  </form>
</ng-template>