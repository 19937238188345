import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { Teacher } from 'src/app/model/teacher';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-add',
  templateUrl: './teacher-add.component.html',
  styleUrls: ['./teacher-add.component.css']
})
export class TeacherAddComponent implements OnInit {
  @ViewChild('teacherAddForm') teacherAddForm: HTMLFormElement;
  // for add
  teacher = new Teacher("");
  birthday_temp : Date;
  fileteacherImg: File;
  imageUrl: any = environment.teacher_avatar;
  isLoading = false;
   // scan code
   codeType = 'mts';
   codeScan = "";
   teacherCodeTemp = "";

   facultys: any
  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private ng2ImgMax: Ng2ImgMaxService,
    public ioService: IoService,
    public dialogRef: MatDialogRef<TeacherAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.facultys = data.facultys;
    this.countAllTeacher();
   }

  ngOnInit(): void {
  }
  countAllTeacher() {
    this.teacherService.countAllteacher().subscribe(
      response => {
        // this.countType1Product = response.CountType1Product;
         //kiểm tra đầu vào khi tạo product
        if(typeof this.data.code != "undefined" && this.data.code != ''){
          this.codeType = 'tn';
          this.teacher.code = this.data.code;
          this.teacherCodeTemp = this.data.code;
        }else{          
          this.teacher.code = response.code;
          this.teacherCodeTemp = response.code;
        }
      },
      error => {
        console.error(error);
      })
  }
  insertTeacher() {

    if (this.teacher.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.teacherAddForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.teacher.code.trim() == "") {
      this.toastr.error("", "Bạn nhập mã cán bộ");
      return;
    }

    if (this.teacher.birthday == null) this.teacher.birthday = '';

    if (this.teacher.price == null) {
      this.teacher.price = 0;
    }
    if (this.teacher.price_in == null) {
      this.teacher.price_in = 0;
    }

    if (this.fileteacherImg !== null && typeof this.fileteacherImg !== 'undefined') {
      this.teacher.image_url = environment.teacher + "/" + this.teacher.code + "." + this.utility.getExtensionFile(this.fileteacherImg);
      console.log("1111");
    } else {
      console.log("2222");
    }
    // thay thế , bằng - trong name
    this.teacher.name = this.teacher.name.replace(",","-");
    this.teacher.birthday = this.utility.getFullDateStrFromDate(this.birthday_temp);

    if (!this.teacherAddForm.invalid) {
      this.isLoading = true;
      this.teacherService.insert(this.teacher).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            // $("#add-teacher-modal").modal('hide');
            // this.toastr.success("", "Thêm mới hàng hóa thành công");
            // this.resetteacher();
            // this.loadTeacher();
            let result = {
              code: "ok",
              data: {}
            }
            this.dialogRef.close(result);
          } else {
            this.toastr.error("", "Mã đã tồn tại hoặc Có lỗi xảy ra.");
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
      this.uploadAvatar(this.fileteacherImg, this.teacher.code);
    }
  }
  teacherCodeTypeClick(type) {
    this.codeType = type;
    if (this.codeType == "mts") {
      this.teacher.code = this.teacherCodeTemp ;
    } else if (this.codeType == "tn" || this.codeType == "qmv") {
      this.teacher.code = "";
    }
  }
  focusOutCheckCode() {

    let filter = {
      name: 'code', //cột kiểm tra
      value: this.teacher.code,
    }
    console.log(filter);
    this.teacherService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.teacherAddForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  focusOutCheckEmail(email) {
    let filter = {
      name: 'email', //cột kiểm tra
      value: email, // giá trị kiểm tra
    }
    console.log(filter);
    this.teacherService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.teacherAddForm.controls.email.setErrors("Đã tồn tại email");
            this.toastr.error("Đã tồn tại Email","Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.teacher,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  async avatarChangeListener($event): Promise<void> {
    this.fileteacherImg = $event.target.files[0];
    if (this.fileteacherImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
        this.fileteacherImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileteacherImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

}
