import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { AssessmentService } from 'src/app/_services/assessment.service';
import { TeacherService } from 'src/app/_services/teacher.service';

@Component({
  selector: 'app-schedules-detail',
  templateUrl: './schedules-detail.component.html',
  styleUrls: ['./schedules-detail.component.css']
})
export class SchedulesDetailComponent implements OnInit {
  teacher_list = [];
  constructor(
    public teacherService: TeacherService,
    public dialogRef: MatDialogRef<SchedulesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadDetail(data.teacher_code,data.type);
  }

  ngOnInit(): void {

  }

  loadDetail(teacher_code,type) {

    let data = {
      teacher_code: teacher_code,
      type: type,
    }
    this.teacherService.loadAssignmentTeacherDetail(data).subscribe(
      async response => {
        if (response) {
          this.teacher_list = response;
        }
      },
      error => {
        console.error(error);
      })
  }
}
