import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-holiday-picker',
  templateUrl: './holiday-picker.component.html',
  styleUrls: ['./holiday-picker.component.css']
})
export class HolidayPickerComponent implements OnInit {
  dataList: any = [];
  indexSelected = -1;
  title = "";
  filter = {
    name: "",
    khoi: "",
    deleted: 0,
    type: ''
  }
  sorter = {
    sortStr: 'value_date ASC',
  }
  //for paging
  viewNumsObj = this.appService.viewNums[2].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<HolidayPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    console.log(data.title);
    console.log(data.data);
    this.filter.type = environment.cateType_holiday;
    this.title = data.title;
    this.loadClass();
  }

  ngOnInit(): void {

  }
  loadClass() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          this.dataList = response.data;
          //thêm selected_temp để cho action
          this.dataList.forEach(element => {
            // nếu được chọn là 1  thì hiển thị 
            if(this.data.data[0]['holiday_ids'] != null){
              if(this.data.data[0]['holiday_ids'].includes(element.id.toString())){
                element.selected_temp = true;
              }else
              element.selected_temp = false;
            }else
            element.selected_temp = false;
          
          });
        }
      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }
  saveChangeClass() {

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {

    var obj_ids = [];
    this.dataList.forEach(element => {
      if (element.selected_temp) {
        obj_ids.push(element.id);
      }
    });

    if (obj_ids.length == 0) {
      this.toastr.warning("", "Bạn chưa chọn ngày nghỉ");
      return;
    }
    this.dialogRef.close(obj_ids);
  }

}
