export class Membership {
 
    id: number = 0;
    name: string = "";
    email: string = "";
    role: string = "nvbh";
    code: string = "";
    phone: string = "";
    description: string = "";
    password: string = "";
    repassword: string = "";
    deleted: number = 0;
    isResset: number = 0;

    constructor(){

    }
}
