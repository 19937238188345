import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/checkCode'), filter,).map(data => { return data; });
  }
  updateScheduleDetailByID(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/updateScheduleDetailByID'), data,).map(data => { return data; });
  }
  updateSomeScheduleDetailByID(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/scheduleDetail/updateSomeByID'), data,).map(data => { return data; });
  }
  deleteScheduleDetailByIdAndTeacher(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/deleteScheduleDetailByIdAndTeacher'), data,).map(data => { return data; });
  }

  loadTeacher(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/get'), data,).map(data => { return data; });
  }
  loadTeachingAssignment_v1(filter, sorter) {
    let data = {
      filter: filter,
      sorter: sorter
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadTeachingAssignment_v1'), data,).map(data => { return data; });
  }
  loadTeachingAssignment_v2(filter, sorter) {
    let data = {
      filter: filter,
      sorter: sorter
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadTeachingAssignment_v2'), data,).map(data => { return data; });
  }
  saveTeachingAssignment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/saveTeachingAssignment'), data,).map(data => { return data; });
  }
  loadTeachingAssignment_report(filter, sorter, range) {
    let data = {
      filter: filter,
      sorter: sorter,
      range : range
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadTeachingAssignment_report'), data,).map(data => { return data; });
  }
  loadTeachingAssignmentScheduleDetail(type_range, dateSelected, filter) {
    let data = {
      type_range: type_range,
      dateSelected: dateSelected,
      filter: filter,
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadTeachingAssignmentScheduleDetail'), data,).map(data => { return data; });
  }
  downloadTeachingAssignmentDaily(type_range ,selectedDateStr, teacher_code) {
    if(teacher_code=='') teacher_code = 'all';
    selectedDateStr = selectedDateStr.split("/").join("_");
    window.open(this.appService.getApiEndpoint('teacher/excelTeachingAssignmentDaily/'+this.appService.currentUser.school_code+"/"+type_range+"/"+selectedDateStr+"/"+teacher_code) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  downloadTeachingAssignmentReport(type_report ,selectedDateStr, khoi_code, teacher_code ) {
    if(khoi_code=='') khoi_code = 'all';
    if(teacher_code=='') teacher_code = 'all';
    selectedDateStr = selectedDateStr.split("/").join("_");
    window.open(this.appService.getApiEndpoint('teacher/excelTeachingAssignmentReport/'+this.appService.currentUser.school_code+"/"+type_report+"/"+selectedDateStr+"/"+khoi_code+"/"+teacher_code) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  loadTeachingAssignment(filter) {
    let data = {
      filter: filter,
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/teachingAssignment/get'), data,).map(data => { return data; });
  }
  loadAssignmentBySome(filter, type) {
    let data = {
      filter: filter,
      type: type,
      // pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadAssignmentBySome'), data,).map(data => { return data; });
  }
  loadAssignmentClassBySubject(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadAssignmentClassBySubject'), data,).map(data => { return data; });
  }
  insert(teacher) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher'), teacher,).map(data => { return data; });
  }
  updateCheckinTeacher(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/updateCheckinTeacher'), data,).map(data => { return data; });
  }
  deleteAssignment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/deleteAssignment'), data,).map(data => { return data; });
  }
  loadAndSaveTeachingAssignmentDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadAndSaveTeachingAssignmentDetail'), data,).map(data => { return data; });
  }
  update(teacher) {
    delete teacher["modal"];
    console.log("teacher.................")
    console.log(teacher);
    return this.http.put<any>(this.appService.getApiEndpoint('teacher/code/'+teacher.code), teacher,).map(data => { return data; });
  }
  updateTeachingAssignment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/updateTeachingAssignment'), data,).map(data => { return data; });
  }
  updateTeachingAssignment_v2(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/updateTeachingAssignment_v2'), data,).map(data => { return data; });
  }
  loadCheckinTimeByTeacher(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadCheckinTimeByTeacher'), data,).map(data => { return data; });
  }
  loadCheckinTimeByTeaSubCla(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadCheckinTimeByTeaSubCla'), data,).map(data => { return data; });
  }
  loadAssignmentTeacherDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/loadAssignmentTeacherDetail'), data,).map(data => { return data; });
  }
  exportteacher(filter) {
    console.log(filter);
    window.open(this.appService.getApiEndpoint('io/downloadteacher/'+this.appService.currentUser.school_code) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadteacher'), filter,).map(data => { return data; });
  }
  uploadScheduleDetail(file, data) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    // formData.append('action', "IMPORT_FinanceAssignment");
    // formData.append('count_import', "0");
    // formData.append('finance_cate_id', selected_finance_cate);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadScheduleDetail'), formData).map(data => { return data; });
  }
  ///////////////////////////




  countAllteacher() {
    return this.http.post<any>(this.appService.getApiEndpoint('teacher/count'), {},).map(data => { return data; });
  }
  
  getTotalInfoShop(filter) {
    return this.http.get<any>(this.appService.getApiEndpoint('teacher/warehouse'), {},).map(data => { return data; });
  }
  
  
 
  
  importTeacher(file) {
    const formData = new FormData();
    formData.append('excelFile', file, file.name);
    formData.append('action', "IMPORT_teacher");
    formData.append('count_teacher', "0");
    formData.append('shop_code', this.appService.currentUser.shop_code);
    formData.append('user_id', this.appService.currentUser.id.toString());
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadTeacherExcel'), formData).map(data => { return data; });
  }
  
  

}
