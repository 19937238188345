<div class="page-header">
    <div class="ibox flex-1">
        <div class="ibox-body">
            <div class="flexbox">
                <div class="flexbox-b">
                    <div class="ml-5 mr-5">
                        <img class="img-circle" src="./assets/img/icons/person.svg" alt="image" width="110" />
                    </div>
                    <div>
                        <h4>{{appService.currentUser.name}}</h4>
                        <div class="text-muted font-13 mb-3">
                            <span><i class="ti ti-calendar mr-2"></i>{{appService.currentUser.created_date}}</span>
                            <span class="mr-3"><i
                                    class="ti ti-location-pin mr-2"></i>{{appService.currentUser.shop_address}}</span>
                        </div>
                        <div>
                            <span class="mr-3">
                                <span class="badge badge-primary badge-circle mr-2 font-14"
                                    style="height:30px;width:30px;"><i class="ti ti-briefcase"></i></span>Quản lý</span>
                            <!-- <span>
                                <span class="badge badge-pink badge-circle mr-2 font-14"
                                    style="height:30px;width:30px;"><i class="ti-cup"></i></span>Vip Status</span> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="d-inline-flex">
                    <div class="px-4 text-center">
                        <div class="text-muted font-13">ARTICLES</div>
                        <div class="h2 mt-2">134</div>
                    </div>
                    <div class="px-4 text-center">
                        <div class="text-muted font-13">FOLLOWERS</div>
                        <div class="h2 mt-2">540</div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</div>
<div class="page-content fade-in-up">
    <div class="row">

       

    </div>
    <div class="row">





        <div class="col-lg-5">
            <div class="ibox">
                <div class="ibox-body">
                    <h5 class="font-strong mb-4">Thông tin shop</h5>
                    <div class="row mb-2">
                        <div class="col-6 text-muted">Tên Shop:</div>
                        <div class="col-6">{{appService.currentUser.shop_name}}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6 text-muted">Mã Shop:</div>
                        <div class="col-6">{{appService.currentUser.shop_code}}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6 text-muted">Shop Email:</div>
                        <div class="col-6">{{appService.currentUser.shop_email}}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6 text-muted">Shop Phone:</div>
                        <div class="col-6">{{appService.currentUser.shop_phone}}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6 text-muted">Age:</div>
                        <div class="col-6">26</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-7">

            <div class="ibox ibox-fullheight">
                <div class="ibox-head">
                    <div class="ibox-title">Thông tin cá nhân</div>
                </div>
                <form class="form-horizontal" id="updateUserForm" #userProfileForm="ngForm"
                    (ngSubmit)="updateUserProfile()">
                    <div class="ibox-body">
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Họ và tên</label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.name"
                                    placeholder="Họ và tên" name="name" required maxlength="20">
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Phone</label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.phone"
                                    placeholder="Phone" name="Phone" required maxlength="10" minlength="10">
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Email</label>
                            <div class="col-sm-8">
                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.email"
                                    placeholder="Email" name="email">
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Đổi mật khẩu</label>
                            <div class="col-sm-8 d-flex align-items-center">
                                <label class="ui-switch switch-icon">
                                    <input type="checkbox" [(ngModel)]="isRessetPass" name="deleted">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Mật khẩu</label>
                            <div class="col-sm-8">
                                <input class="form-control" type="password" [(ngModel)]="password"
                                    [disabled]="!isRessetPass" placeholder="Mật khẩu" name="pass">
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <label class="col-sm-4 col-form-label text-right">Nhập lại mk</label>
                            <div class="col-sm-8 ">
                                <input class="form-control" type="password" [(ngModel)]="repassword"
                                    [disabled]="!isRessetPass" placeholder="Nhập lại mật khẩu" name="repasss">
                            </div>
                        </div>

                        <!-- <div class="form-group mb-4 row">
                                <label class="col-sm-4 col-form-label text-right">Gender</label>
                                <div class="col-sm-8 d-flex align-items-center">
                                    <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="b" checked="">
                                        <span class="input-span"></span>Nam</label>
                                    <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="b">
                                        <span class="input-span"></span>Nữ</label>
                                </div>
                            </div> -->
                    </div>
                    <div class="row justify-content-center">
                        <button class="btn btn-primary mr-2">Cập nhật</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>