
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinStudentDetailComponent } from 'src/app/components/checkin-sutdent-detail/checkin-student-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-meal-class-detail',
  templateUrl: './meal-class-detail.component.html',
  styleUrls: ['./meal-class-detail.component.css']
})
export class MealClassDetailComponent implements OnInit {
  students = [];
  attention_count = 0;
  isLoading = false;
  constructor(
    public appService: AppService,
    private schoolService: SchoolService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MealClassDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportMealClassDetail();
  }

  ngOnInit(): void {

  }

  loadReportMealClassDetail() {

    let data = {
      class_code: this.data.class_checkin.class_code,
      dateSelected: this.data.dateSelected,
    };
    this.isLoading = true;
    this.schoolService.loadReportMealClassDetail(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          this.attention_count = 0;
          response.forEach(element => {
              //meal
              if (element.meal == 1 && element.meal_id == 0) {// đăng ký meal
                element.meal_temp = true
              } else {
                element.meal_temp = false;
              }

          });
          this.students = response;
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  // open_student_detail(item){
  //   const dialogRef = this.dialog.open(CheckinStudentDetailComponent, {
  //     position: {
  //       // top: '0px',
  //       // left: '0px'
  //     },
  //     // disableClose : true,
  //     width: '90%',
  //     height: '90%',
  //     panelClass: 'full-screen-modal',
  //     data: { data: item, dateSelected: this.data.dateSelected }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (typeof result != "undefined" && result.code == "ok") {
  //       console.log("result", result);

  //     }
  //   });
  // }
}
