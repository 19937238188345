
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { FinanceService } from 'src/app/_services/finance.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finance-student-detail',
  templateUrl: './finance-student-detail.component.html',
  styleUrls: ['./finance-student-detail.component.css']
})
export class FinanceStudentDetailComponent implements OnInit {
  dataList: any = [];
  title = "";
  filter = {
    student_code: this.data.code,
    type: environment.cateType_finance
  }
  sorter = {
    sortStr: 'checkin_start ASC',
  }

  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    public financeService: FinanceService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<FinanceStudentDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    this.title = data.title;
    this.loadDetail();
  }

  ngOnInit(): void {

  }
  updateFinanceStudent(item){
    if(this.isLoading) return;
    let data = {
      id : item.finance_student_id,
      finance_cate_value : item.finance_cate_value,
      finance_content: item.finance_content_json,
      pri_debt: item.pri_debt,
      pri_credit: item.pri_credit,
      type: item.finance_type, // kiểu dữ liệu 'dot'
    }
    this.isLoading = true;
    this.financeService.updateFinanceStudent(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Cập nhật thành công");
          // tắt popup
          // tắt popup
          this.dialogRef.close({code:"ok"});
        }else{
          this.toastr.warning(response.msg,"Cập nhật không thành công");
        }
      },
      error => {
        this.toastr.warning("Đã có lỗi xảy ra ");
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      });
  }
  loadDetail() {
    this.isLoading = true;
    this.financeService.detailStudentCateFinance(this.filter).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          response.forEach(element => {
            // đoạn này 2 chỗ : 
            element.temp_checked = element.temp_checked_origin ;
          });
          this.dataList = response;
        }
      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  focusOutJtem(item){
    console.log(item);
    let temp = 0; // lưu giá trị tổng
    temp+= item.pri_debt; // Thiếu
    temp-= item.pri_credit; // Dư
    item.finance_content_json.forEach(element => {
      temp += element.value; // cộng các giá trị
    });
    item.finance_cate_value = temp;
  }
  click_select(){
    let data = [];
    this.dataList.forEach(element => {
      // Nếu có thay đổi so với origin
      if (element.temp_checked_origin != element.temp_checked){
        data.push({
          student_code : this.data.code,
          finance_cate_id : element.id,
          finance_student_id : element.finance_student_id,
          status : element.status,
          temp_checked : element.temp_checked,
          temp_checked_origin : element.temp_checked_origin,
        }); //
      }
    });
    console.log(data);

    console.log(data);
    this.isLoading = true;
    this.financeService.saveUpdate(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Cập nhật thành công");
          // tắt popup
          this.dialogRef.close(response);
        }
      },
      error => {
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      });

  }

}
