<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start align-items-center ">


                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm học sinh</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên học sinh" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                        <!-- <mat-option value="">
                            Tất cả
                        </mat-option> -->
                        <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFinancePermission()">
                            {{item.name}} - ({{item.student_count}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Giới tính</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.gender" (selectionChange)="category_change($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.genders" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Xếp theo</mat-label>
                    <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)">

                        <mat-option *ngFor="let item of appService.sort_students" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Cấp học</mat-label>
                    <mat-select [disabled]="!appService.checkFullPermission()" [(ngModel)]="kt_filter.code_parent" (selectionChange)="this.loadCategory();">
                        <mat-option [disabled]="!appService.checkFullPermission()" [value]="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of this.appService.currentUser.caps" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
        </div>
    </div>

    <div class="row">
        <div class="" style="width: 390px; border-right: 1px dashed gray; padding-left: 0px;">

            <div class="d-flex justify-content-between align-items-center row-header bg-primary">
                <div>Hoc sinh</div>

                <div class="d-flex justify-content-between ">
                    <button class="btn btn-white btn-labeled btn-labeled-right btn-icon" placement="top" ngbTooltip="Tổng giá trị và khoản thu" style=" margin: 7px 9px; ">
                        <span class="btn-label">{{count_finance_cate}}</span>
                        {{sum_finance_value | qbscurrency}}
                    </button>

                    <label class="checkbox myHeader checkbox-warning check-single" style="margin-bottom: 6px;">
                        <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                        <span class="input-span"></span>
                    </label>
                </div>
            </div>

            <div *ngFor="let item of this.students; let i = index" class="student-item" [ngClass]="{'student-selected':  student_selected == item.code}">
                <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                <div style="font-size: 18px;">
                    <span *ngIf="item.gender == 1" class="material-icons text-blue ">boy</span>
                    <span *ngIf="item.gender == 0" class="material-icons text-pink ">girl</span> {{item.full_name}}
                </div>
                <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                    {{item.code}} <span class="badge-point badge-point"></span> {{item.birthday_str}}
                </div>
                <div class="cover-checkbox d-flex justify-content-between align-items-center">

                    <button *ngIf="item.count_finance_cate != null" class="btn btn-white btn-labeled btn-labeled-right btn-icon mr-2" placement="top" ngbTooltip="Chi tiết khoản thu" (click)="openDetail(item)">
                        <span class="btn-label">{{item.count_finance_cate}}</span>
                        {{item.sum_finance_value | qbscurrency}}
                    </button>

                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox"  [(ngModel)]="item.temp_checked">
                        <span class="input-span"></span>
                    </label>
                </div>
            </div>
            <div *ngIf="!isLoading_student && students.length == 0" class="text-center text-warning mt-5 mb-5 p-2">
                <img style="width: 60px;" src="assets\img\icons\finder.png" alt="">
                <div>Chọn lớp để tải danh sách học sinh</div>
            </div>
            <div *ngIf="isLoading_student" class="spinner-loadClass  mt-5 mb-5 p-2">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row-header bg-primary">
                Danh sách khoản thu

            </div>
            <div class="khoanthu-body">


                <div class=" row">
                    <table class="table  table-hover" id="datatable">
                        <thead class="thead-lg" style="background: #0996ca38;">
                            <tr>
                                <th style="width: 50px; text-align: center;">Cấp</th>
                                <th>Khoản thu</th>
                                <th style="text-align: right">Giá trị</th>
                                <th style="text-align: right">Đã gán</th>
                                <th style="text-align: center">Thời gian</th>
                                <th style="text-align: center; width: 185px;">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of categorys; let i = index">
                                <td>
                                    <div style="font-size: small;">{{item.code_parent}}</div>
                                </td>

                                <td style="min-width: 200px;">
                                    <div style="font-size: 18px;">{{item.name}}</div>
                                    <div style="font-size: 18px;">{{item.id}} - {{item.name}}</div>
                                    <ng-container *ngIf="item.finance_type == 'dot'">
                                        <span class="badge badge-secondary mr-2 mb-1" *ngFor="let jtem of item.finance_content_json; let i = index"> {{jtem.label}}</span>
                                    </ng-container>
                                </td>

                                <td style="text-align: right">
                                    <div style="font-size: 18px;">{{item.finance_value| qbscurrency}}</div>
                                </td>
                                <td style="text-align: right; width: 115px; font-weight: bold;">{{ item.sum_finance_cate_value| qbscurrency}}</td>
                                <td style="text-align: center">
                                    <div *ngIf="item.finance_frequency == 'Tháng'">
                                        <select class="custom-select" [(ngModel)]="item['period_time_str']" [name]="item['code']">
                                                <option [ngValue]="jtem.month_full" *ngFor="let jtem of dateRange" >
                                                    {{jtem.month}}</option>
                                            </select>
                                    </div>
                                </td>

                                <td style="text-align: right">
                                    <div ngbDropdown class="d-inline-block mr-2">
                                        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle placement="top" ngbTooltip="Gán khoản thu cho học sinh">
                                                <span class="btn-label"><span class="material-icons">
                                                    paid
                                                    </span>
                                                    </span>Gán
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <ng-container *ngIf="item.finance_type =='dot'">
                                                <button ngbDropdownItem class="text-secondary" (click)="openImport(item.id, item.code_parent)">
                                                    <span class="mr-2 ml-1"><img class="" src="./assets/img/icons/excel.png" alt="image" width="20" /></span>
                                                    Gán bằng file excel
                                                </button>
                                            </ng-container>
                                            <button ngbDropdownItem class="text-secondary" (click)="doAssignment('student',item)"><span class="material-icons mr-2">
                                                paid
                                                    </span>
                                                    Gán cho HS đã chọn
                                            </button>
                                            <button ngbDropdownItem class="text-secondary" (click)="doAssignment('class',item)"><span class="material-icons mr-2">
                                                paid
                                                    </span>
                                                    Gán cho Lớp {{filter.class_code}}
                                            </button>
                                            <button ngbDropdownItem class="text-secondary" (click)="doAssignment('khoi',item)"><span class="material-icons mr-2">
                                                paid
                                                    </span>
                                                    Gán cho Khối {{filter.khoi_code}}
                                            </button>
                                            <button ngbDropdownItem class="text-secondary" (click)="doAssignment('school',item)"><span class="material-icons mr-2">
                                                paid
                                                    </span>
                                                    Gán cho toàn Trường
                                            </button>
                                        </div>
                                    </div>
                                    <div ngbDropdown class="d-inline-block mr-2">
                                        <button class="blurButton btn btn-info btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle placement="top" ngbTooltip="Gửi thông báo cho PHHS">
                                                <span class="btn-label"><span class="material-icons">
                                                    notifications
                                                    </span>
                                                    </span>Gửi
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <ng-container>
                                                <button ngbDropdownItem class="text-primary" (click)="sendNoti('school',item)">
                                                    <span class="material-icons mr-2"> notifications </span>
                                                    Gửi tất HS được gán
                                                </button>
                                            </ng-container>
                                            <button ngbDropdownItem class="text-primary" (click)="sendNoti('student',item)">
                                                <span class="material-icons mr-2"> notifications </span>
                                                Gửi cho HS đã chọn
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <button class="blurButton btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="sendNoti(item)" placement="top" ngbTooltip="Gửi thông báo cho PHHS">
                                        <span class="btn-label"><span class="material-icons">
                                            notifications
                                            </span>
                                            </span>Gửi
                                    </button> -->

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="categorys ==  undefined || categorys.length == 0" class="text-center text-warning mt-5 mb-5 p-2">
                    <img style="width: 60px;" src="assets\img\icons\finder.png" alt="">
                    <div>Chưa khai báo khoản thu. Vào menu "Khoản thu" để thêm mới</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                    {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}
                            </option>
                        </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                                <i class="ti ti-angle-left"></i>
                            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>



<div class="modal fade" id="student-list-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Danh sách học sinh đã được gán trước đó</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4" style="max-height: 90vh; overflow: scroll;">
                <ul style="list-style-type: decimal;">
                    <li *ngFor="let item of studentList_exist">
                        {{item.full_name}}
                    </li>
                </ul>
            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <button class="btn btn-warning " data-dismiss="modal">Đóng</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>





<div class="modal fade" id="import-finance-assignment">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Gán khoản thu cho Học sinh từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">

                    <div class="form-group mb-4 text-primary">
                        <ng-container *ngIf="this.kt_filter.code_parent != ''">
                            Cấp <span class="badge badge-primary">{{this.kt_filter.code_parent}}</span>
                        </ng-container>
                        <ng-container *ngIf="this.kt_filter.code_parent == ''">
                            Toàn trường
                        </ng-container>
                        <!-- <ng-container *ngIf="this.kt_filter.code_parent != ''">
                            Lớp <span class="badge badge-primary">{{this.kt_filter.code_parent}}</span>
                        </ng-container> -->
                        <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                            <mat-select [(ngModel)]="filter.class_code" name="dg5455" [disabled]="true">
                                <mat-option *ngFor="let item of classs" [value]="item.code">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <mat-form-field appearance="fill" class="mb-4 select-highline">
                        <mat-label>Khoản thu</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="selected_finance_cate" name="selected_finance_cate" (selectionChange)="change_finance_cate($event)">
                            <mat-option *ngFor="let item of categorys" [value]="item.id">
                                {{item.id}} - {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="form-group mb-4" style="font-size: large;">
                        <a class="text-warning" (click)="popup_export()"> <span class="material-icons">file_download</span> Tải mẫu nhập Excel
                        </a>
                    </div>

                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>

                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>
                <div *ngIf="!import_result.result && import_result.msg != ''" class="text-center">
                    <div class="text-danger">Có lỗi xảy ra: {{import_result.msg}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> file_upload </span></span>
                            Import</button>
                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>