import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-class-picker',
  templateUrl: './class-picker.component.html',
  styleUrls: ['./class-picker.component.css']
})
export class ClassPickerComponent implements OnInit {
  classList : any = [];
  indexSelected = -1;
  filter = {
    name: "",
    khoi: "",
    deleted: 0,
    type: environment.cateType_class
  }
  sorter = {
    sortStr: this.appService.sort_category[4].code,
  }
    //for paging
    viewNumsObj = this.appService.viewNums[5].Code;
    curentPage = 1;
    totalItemOnPage: any;
    totalItems: any;
    listPage: any = [];

  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    public dialogRef: MatDialogRef<ClassPickerComponent>,
      @Inject(MAT_DIALOG_DATA)
      public data: any
  ) {
      console.log(data);
      this.loadClass();
   }

  ngOnInit(): void {

  }
  loadClass() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.classList = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  saveChangeClass(){

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {
    if(this.indexSelected > -1){

      let result = {
        class_code: this.classList[this.indexSelected].code,
        code: 'ok',
      }
      this.dialogRef.close(result);
    }else{
      
    }
  }

}
