<form class="">
    <h5 class="mat-dialog-title">{{type_name}}</h5>
    <mat-dialog-content>

        <div class="modal-body p-2">
            <div class="row subtitle">
                Copy <b>{{items.length}}</b> lịch sang <b>{{temp_data.length}} </b>
            </div>
            <div class="text-muted">Nếu tuần đích đã tồn tại lịch, hệ thống sẽ bỏ qua và không copy</div>
            <div class="mt-3">
                <div *ngFor="let wtem of week_list" class="item-next" [ngClass]="{'item-next-checked' : wtem.checked, 'bg-primary-100' : wtem.checked} ">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox"  [(ngModel)]="wtem.checked" name="sdfgsdfg" (change)="updateData()" >
                        <span class="input-span"></span>
                        <div class="ml-3">
                            <div class="text-center">Tuần {{wtem.weekNumber}} ({{wtem.start}} - {{wtem.end}})</div>
                            <div class="d-flex justify-content-start align-items-center" >
                                 <span class="material-icons"> calendar_month </span>
                                 <div class="font-bold">{{wtem.weekDay}} - {{wtem.weekDate}} </div>
                            </div>
                            <div *ngFor="let item of items; let i = index">
                                    <table cellpadding="6">
                                        <tr>
                                            <td><span class="badge badge-default">{{i+1}}</span> </td>
                                            <td>{{item.checkin_time}}</td>
                                            <td>{{item.checkin_duration}}</td>
                                            <td>{{item.teacher_name}}</td>
                                            <td class="font-bold">{{item.class_name}}</td>
                                            <td>{{item.subject_name}}</td>
                                        </tr>
                                    </table>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-around">
        <button (click)="okClick()" class="btn btn-primary ">
        Copy
    </button>
        <button (click)="cancelClick()" class="btn">
        Bỏ qua
    </button>
    </mat-dialog-actions>
</form>