
export class AssessmentPeriod {
    id :number = 0;
    school_code :string ="";
    period_time :string ="";
    period_code :string ="";
    period_name :string ="";
    student_code :string ="";
    attached_file :string ="";
    status :number = 0;
    visible :number = 0; // hiển thị cho xem điểm hay không
    type :number = 0; //  chu kỳ tháng, chu kỳ học kỳ
    cap_hoc: string = ''; // th hoăc thcs hoặc thpt
    //cấp 1
    toan_1 : any;
    toan_2 : any;
    toan_3 : any;
    toan_4 : any;
    toan_5 : any;
    toan_6 : any;
    toan_7 : any;
    tiengviet_1 : any;
    tiengviet_2 : any;
    tiengviet_3 : any;
    tiengviet_4 : any;
    tiengviet_5 : any;
    tiengviet_6 : any;
    tiengviet_7 : any;
    tiengviet_8 : any;
    tienganh_1 : any;
    tienganh_2 : any;
    tienganh_3 : any;
    tienganh_4 : any;
    kns_1 : any;
    kns_2 : any;
    kns_3 : any;
    kns_4 : any;
    ctcs_1 : any;
    ctcs_2 : any;
    ctcs_3 : any;
    ythuc_1 : any;
    ythuc_2 : any;
    ythuc_3 : any;
    ythuc_4 : any;
    ykien_gvcn : string = '';
    ykien_ph : string = '';
    th_phamchatchuyeu : any; // cuối kỳ
    th_nanglucchung : any; // cuối kỳ
    th_nanglucdacthu : any; // cuối kỳ
    th_danhgiaketquagiaoduc = ''; // cuối kỳ
    th_nhungthanhtichnoibat = ''; // cuối kỳ
    th_khenthuongcuoinamhoc = ''; // cuối kỳ
    //Cấp 2
    ykien_gvcn_thcs : any;//dn,gk
    danhgia_gvcn_thcs_ck : any; // ck
    // đánh giá môn học
    th_ketquamonhoc : any; // ck
    thcs_ketquamonhoc : any; // ck
    constructor() {
    }
}


