<div class="p-4">
    <div class="d-flex align-items-center justify-content-between">
        <p>Chi tiết</p>
        <div class="text-center">
            <button class="btn btn-warning" (click)="this.dialogRef.close();">
                <span class="material-icons font-26">
                close
                </span>
            </button>
        </div>
    </div>
    <div class="row lop-cover">
        <div *ngFor="let item of data.detected_image_urls; let i = index" (click)="selectPerson(item)" class="card text-center card-air  m-1 lop-item">

            <div class="d-flex align-items-center  justify-content-between">
                <span>
                {{i+1}}.
                </span>
                <span class="badge badge-primary badge-pill">{{data.deviceNames[i]}}</span>
            </div>

            <div class="">
                <div style="min-width:130px; max-width: 370px; position: relative;">
                    <a style="min-width: 115px; display: block">
                        <img [src]="item" />
                        <div>{{data.checkin_times[i]}}</div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>