import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../_services/app.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  @ViewChild('userProfileForm') userProfileForm: HTMLFormElement;
  isRessetPass: boolean = false;
  loading = false;

  password: string ="";
  repassword: string ="";
  constructor(
    public appService: AppService,
    public userService: UserService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }


  updateUserProfile() {
    console.log(this.isRessetPass);
    console.log(this.userProfileForm);
    console.log(this.userProfileForm.invalid);
    console.log(this.appService.currentUser);

    if (this.appService.currentUser.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên thành viên");
      this.userProfileForm.controls.name.setErrors("");
      return false;
    }
    if (this.appService.currentUser.phone.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập số điện thoại");
      this.userProfileForm.controls.phone.setErrors("");
      return false;
    }

    if (this.isRessetPass) {
      if (this.password.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập mật khẩu");
        this.userProfileForm.controls.pass.setErrors("Vui lòng nhập mật khẩu");
        return false;
      } else if (this.password != this.repassword) {
        this.toastr.warning("", "Mật khẩu chưa trùng khớp");
        this.userProfileForm.controls.repasss.setErrors("Mật khẩu chưa trùng khớp");
        return false;
      }
      this.appService.currentUser.isResset = 1
      this.appService.currentUser.password = this.password;
    }


    // return;



    if (true) {
      this.loading = true;
      this.userService.updateUser(this.appService.currentUser).subscribe(
        response => {
          this.loading = false;
          if (response > 0) {
            this.appService.currentUser.password = "";
            localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
            this.toastr.success("Cập nhật thông tin thành công", "");
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
          }
        },
        error => {
          this.loading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })
    }

  }
}
