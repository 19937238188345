<div class="m-3">
    <h4>Cài đặt ngày nghỉ</h4>
    <div>
        <div *ngIf="isLoading" class="text-center">
            <span class="spinner-border spinner-lg"></span>
        </div>
        <div *ngIf="dataList.length == 0" class="text-center">
            <app-nodata msg="Nhà trường chưa khai báo các ngày nghỉ. Vào menu Ngày nghỉ để thêm mới"></app-nodata>
        </div>
        <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Ngày nghỉ</th>
                        <th>Tên ngày nghỉ</th>
                        <th style="width: 100px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="img-circle mt-1" src="./assets/img/icons/calendar.png" alt="image" width="30" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;" class="pr-3">{{item.value_date_str}}</div>
                        </td>
                        <td>{{item.name}}</td>
                        <td style="text-align: right;">
                            <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp">
                                <span class="input-span"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Xác nhận gán ngày nghỉ
            </button>
            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
            </button>
        </div>
    </div>
</div>