
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinStudentDetailComponent } from 'src/app/components/checkin-sutdent-detail/checkin-student-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-checkin-faculty-detail',
  templateUrl: './checkin-faculty-detail.component.html',
  styleUrls: ['./checkin-faculty-detail.component.css']
})
export class CheckinFacultyDetailComponent implements OnInit {
  teachers = [];
  attention_count = 0;
  constructor(
    public appService: AppService,
    private schoolService: SchoolService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CheckinFacultyDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportCheckinFacultyDetail();
  }

  ngOnInit(): void {

  }

  loadReportCheckinFacultyDetail() {

    let data = {
      faculty_code: this.data.faculty_checkin.code,
      dateSelected: this.data.dateSelected,
    };
    this.schoolService.reportCheckinFacultyDetail(data).subscribe(
      async response => {
        if (response) {
          console.log(response);
          this.attention_count = 0;
          response.forEach(element => {
            

            if(element.checkin_status_temp) this.attention_count++;

            // Đưa time và ảnh vào mảng
            if(element.checkin_times == null) element.checkin_times = [];
            else
            element.checkin_times = element.checkin_times.split(",");
            if(element.detected_image_urls == null) element.detected_image_urls = [];
            else
            element.detected_image_urls = element.detected_image_urls.split(",");

          });
          this.teachers = response;
        }
      },
      error => {
        console.error(error);
      })
  }
  open_student_detail(item){
    const dialogRef = this.dialog.open(CheckinStudentDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { data: item, dateSelected: this.data.dateSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }
}
