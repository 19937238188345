import { Router } from '@angular/router';
import { CartObj } from './../../../model/cart-obj';

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Cart } from '../../../model/cart';
import { AppService } from '../../../_services/app.service';
import { CategoryService } from '../../../_services/category.service';
import { ProductService } from '../../../_services/product.service';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { UtilityService } from '../../../_services/utility.service';
import { SaleService } from '../../../_services/sale.service';
import { Product } from 'src/app/model/product';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-stockin-cart',
  templateUrl: './stockin-cart.component.html',
  styleUrls: ['./stockin-cart.component.css']
})
export class StockinCartComponent implements OnInit {

  loading = false;
  display_type = 0;
  cartObj = new CartObj();

  // products = [];
  products_backup: any
  categorys: any
  filter = {
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }

  // for add product
  product: any = new Product("");
  // for update product
  productSelected: any = new Product("");
  isDeleted: boolean;

  //for cart
  productCart: any = new Product("");
  txt_quantity = 1;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  constructor(
    private router: Router,
    public appService: AppService,
    public saleService: SaleService,
    public productService: ProductService,
    public categoryService: CategoryService,
    private _script: ScriptLoaderService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
  ) {

    if (localStorage.getItem(environment.importCart)) {
      this.cartObj = JSON.parse(localStorage.getItem(environment.importCart));
    }
    this.cartObj.type = 1;

    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;

    // cách hiển thị
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    //load product

    this.loadProduct();


  }




  ngOnInit() {
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
  }

  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: this.appService.viewNumsObj,
      pageObj: this.curentPage
    };
    console.log("load product form sale");
    this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.appService.products = response.data;
            this.appService.products_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
        }
      },
      error => {

      })
  }
  async loadCategory() {

    // this.loading = await this.loadingCtrl.create({
    //   message: 'Đang xử lý...',
    // });
    // await this.loading.present();

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }
  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  insertProduct() {

    if (this.product.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      return;
    } else if (this.product.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }

    this.loading = true;
    this.productService.insert(this.product).subscribe(
      async response => {
        this.loading = false;
        console.log(response);
        if (response) {
          this.toastr.success("", "Thêm mới hàng hóa thành công");
          $("#add-product-modal").modal('hide');
          this.resetProduct();
          this.loadProduct();
        }
      },
      error => {
        this.loading = false;
        console.error(error);
      })


  }
  // updateProduct() {

  //   this.productSelected.deleted = this.isDeleted ? 1 : 0;
  //   this.loading = true;
  //   this.productService.update(this.productSelected).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Cập nhật hàng hóa thành công");
  //         $("#update-product-modal").modal('hide');
  //         this.loadProduct();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })

  // }
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    this.txt_quantity = 1;
    this.productSelected = item;
    $("#add-to-import-cart-modal").modal('show');
  }

  quantity_form_add() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity)) {
      this.txt_quantity = ++this.txt_quantity;
    }
  }
  quantity_form_sub() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity) && this.txt_quantity > 1) {
      this.txt_quantity = --this.txt_quantity;
    }
  }

  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity--;
      this.cartObj.total_quantity = this.cartObj.total_quantity - 1;
      this.cartObj.price_totol = this.cartObj.price_totol - parseInt(item.product.price);
      this.cartObj.customer_pay = this.cartObj.price_totol;

    }
  }
  quantity_add(item) {
    item.quantity++;
    this.cartObj.total_quantity = this.cartObj.total_quantity + 1;
    this.cartObj.price_totol = this.cartObj.price_totol + parseInt(item.product.price);
    this.cartObj.customer_pay = this.cartObj.price_totol;
    // this.customer_pay = this.cartObj.price_totol;

    console.log(this.cartObj);
  }

  addToCart() {

    console.log("--------------------------------------------");
    let cart = new Cart();
    cart.product = this.productSelected;
    cart.quantity = parseInt(this.txt_quantity.toString());
    //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
    if (this.cartObj.cart.length > 0) {
      if (this.utilityService.checkToAddCart(this.cartObj.cart, cart)) {
        // nếu có thì thêm luôn ở trong hàm check
      }
      else {
        this.cartObj.cart.push(cart);
      }
    } else {
      this.cartObj.cart.push(cart);
    }

    this.cartObj.total_quantity = this.cartObj.total_quantity + cart.quantity;
    this.cartObj.price_totol = this.cartObj.price_totol + (cart.quantity * this.productSelected.price);
    this.cartObj.customer_pay = this.cartObj.price_totol;
    // this.storage.set('cartList',this.cartList);
    localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));

    $("#add-to-import-cart-modal").modal('hide');
  }
  openCheckout() {
    this.addToCart();
    this.appService.openCheckout();
  }

  // popupPayment(item) {
  //   console.log("xacd");
  // }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }

  removeProduct(i, item) {
    console.log("item1", this.cartObj.cart);
    this.cartObj.cart.splice(i, 1); //delete index i

    this.cartObj.total_quantity = this.cartObj.total_quantity - item.quantity;
    this.cartObj.price_totol = this.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    this.cartObj.customer_pay = this.cartObj.price_totol;
    // this.customer_pay = this.cartObj.price_totol;

    console.log("item2", this.cartObj.cart);
  }
  doOrder() {
    this.toastr.success("", "Tính năng chưa được khai báo");
  }
  print() {
    this.toastr.success("", "Máy in chưa được thiết lập");
  }

  doPayment() {
   

    let param = this.prepareSaleObject();
    this.saleService.insertSale(param).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          this.loadProduct();
          this.cartObj = new CartObj();
          localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));
          this.toastr.success("", "Tạo phiếu thành công");
          $("#stockin-cart-modal").modal('hide');
          this.router.navigate(['/stockin']);
        }
      },
      error => {
        console.error(error);
      });

  }
  prepareSaleObject() {

    // for insert detail sale
    let product_codes = "";
    let product_names = "";
    let product_stocks = "";
    let prices = "";
    let quantitys = "";
    for (let i = 0; i < this.cartObj.cart.length; i++) {
      if (this.cartObj.cart.length == i + 1) {
        product_codes += this.cartObj.cart[i].product.code.toString();
        product_names += this.cartObj.cart[i].product.name.toString();
        product_stocks += this.cartObj.cart[i].product.stock.toString();
        prices += this.cartObj.cart[i].product.price.toString();
        quantitys += this.cartObj.cart[i].quantity.toString();
      } else {
        product_codes += this.cartObj.cart[i].product.code.toString() + ",";
        product_names += this.cartObj.cart[i].product.name.toString() + ",";
        product_stocks += this.cartObj.cart[i].product.stock.toString() + ",";
        prices += this.cartObj.cart[i].product.price.toString() + ",";
        quantitys += this.cartObj.cart[i].quantity.toString() + ",";
      }
    }


    // for insert  sale
    let sale = {
      code: "",
      type: this.cartObj.type, // 1 = nhập hàng, 0 = bán hàng, 0 is sale, 1 is imporrt
      status: 1,
      product_quantity: this.cartObj.cart.length,
      price_totol: this.cartObj.price_totol,
      payment_total: this.cartObj.customer_pay,
      price_after_promotion: this.cartObj.customer_pay,
      promotion_code: "",
      promotion_type: 0,
      promotion_promotion: 0,

      quantity: this.cartObj.total_quantity,
      customer_code: this.cartObj.customer_code,// supplier
      customer_name: this.cartObj.customer_name,// supplier
      customer_id : this.cartObj.customer_id,// supplier
      user_id : this.appService.currentUser.id,
      user_name: this.appService.currentUser.name,
      customer_pay: this.cartObj.customer_pay,

      product_codes: product_codes,// for insert detail sale
      product_names: product_names,// for insert detail sale
      product_stocks: product_stocks,// for insert detail sale
      prices: prices,// for insert detail sale
      quantitys: quantitys,// for insert detail sale
    };
    console.log("sale",sale);

    return sale;
  }
  // insertSaleDetail(sale_id) {
  //   let product_codes = "";
  //   let product_names = "";
  //   let prices = "";
  //   let quantitys = "";

  //   for (let i = 0; i < this.cartObj.cart.length; i++) {
  //     if (this.cartObj.cart.length == i + 1) {
  //       product_codes += this.cartObj.cart[i].product.code.toString();
  //       product_names += this.cartObj.cart[i].product.name.toString();
  //       prices += this.cartObj.cart[i].product.price.toString();
  //       quantitys += this.cartObj.cart[i].quantity.toString();
  //     } else {
  //       product_codes += this.cartObj.cart[i].product.code.toString() + ",";
  //       product_names += this.cartObj.cart[i].product.name.toString() + ",";
  //       prices += this.cartObj.cart[i].product.price.toString() + ",";
  //       quantitys += this.cartObj.cart[i].quantity.toString() + ",";
  //     }
  //   }

  //   let ItemDetailList = {
  //     sale_id: sale_id,
  //     product_codes: product_codes,
  //     product_names: product_names,
  //     prices: prices,
  //     quantitys: quantitys,
  //     type: this.cartObj.type // 0 is sale, 1 is imporrt
  //   }
  //   console.log("ItemDetailList",ItemDetailList);
  //   console.log("this.cartObj",this.cartObj);
  //   this.saleService.insertSaleDetail(ItemDetailList).subscribe(
  //     async result => {
  //       console.log('insertSaleDetail');
  //       console.log(result);
  //     },
  //     error => {
  //       console.error(error);
  //     });
  // }
  updateStockProduct() {
    let product_codes = "";
    let quantity_changes = "";


    for (let i = 0; i < this.cartObj.cart.length; i++) {
      if (this.cartObj.cart.length == i + 1) {
        quantity_changes += this.cartObj.cart[i].quantity.toString();
        product_codes += this.cartObj.cart[i].product.code;
      } else {
        quantity_changes += this.cartObj.cart[i].quantity.toString() + ",";
        product_codes += this.cartObj.cart[i].product.code + ",";
      }
    }

    let updateStockList = {
      product_codes: product_codes,
      quantity_changes: quantity_changes,
      type: this.cartObj.type // 0 is sale, 1 is import
    }

    this.productService.updateStockProduct(updateStockList).subscribe(
      async result => {
        console.log('insertSaleDetail');
        console.log(result);
        if (result > 0) {
          this.loadProduct();
          this.cartObj = new CartObj();
          localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));
          this.toastr.success("", "Thanh toán thành công");
          this.router.navigate(['/stockin']);
        }
      },
      error => {
        console.error(error);
      });

  }
  popup_cart() {
    $("#stockin-cart-modal").modal('show');
  }

  // paging
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupAddToCart(r);
    }
  
}

