<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm khoản thu" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Cấp học</mat-label>
                <mat-select [disabled]="!appService.checkFullPermission()" [(ngModel)]="filter.code_parent" (selectionChange)="this.loadCategory();">
                    <mat-option [disabled]="!appService.checkFullPermission()" [value]="">
                        Tất cả
                    </mat-option>
                    <mat-option *ngFor="let item of this.appService.currentUser.caps" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- 
            <div ngbDropdown class="d-inline-block">
                <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><img class="" src="./assets/img/icons/excel.png" alt="image" width="20" /></span>
                            Gán khoản thu bằng file Excel
                        </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                    <ng-container *ngIf="appService.checkFullPermission()">
                        <button ngbDropdownItem (click)=" ('')" class="text-primary">
                                    <span class="material-icons mr-2"> file_upload </span>
                                    Gán khoản thu toàn trường
                                </button>
                    </ng-container>
                    <button ngbDropdownItem *ngFor="let item of this.appService.currentUser.caps; let i = index" class="text-primary">
                                <span class="material-icons mr-2"> file_upload </span>
                                Gán khoản thu {{item.name}}
                            </button>

                </div>
            </div> -->




        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class=" row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th style="width: 50px; text-align: center;">Cấp</th>
                    <th>Mã - Khoản thu</th>
                    <th style="text-align: right">Giá trị</th>
                    <th style="text-align: right">Đã gán</th>
                    <th style="text-align: right"><span class="material-icons font-15 mr-2">done_outline</span>Đã thu</th>
                    <th style="text-align: right"><span class="material-icons font-15 mr-2">access_time</span>Đang thu</th>
                    <th style="text-align: center;"></th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <!-- <span style="position: absolute; left: 30px;
                line-height: 20px;">{{app.getUrlImage(item.image_url)}}</span> -->
                        <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)">
                            <!-- <i class="ti ti-folder font-20"></i> -->
                            <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
                        </a>
                    </td>
                    <td>
                        <div>{{item.code_parent}}</div>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.id}} - {{item.name}}</div>
                        <ng-container *ngIf="item.finance_type == 'dot'">
                            <span class="badge badge-secondary mr-2 mb-1" *ngFor="let jtem of item.finance_content_json; let i = index"> {{jtem.label}}</span>
                        </ng-container>
                    </td>



                    <td style="text-align: right; width: 115px;"> {{item.finance_type=='dot' ? '' : item.finance_value | qbscurrency}} </td>
                    <td style="text-align: right; width: 115px; font-weight: bold;">{{ item.sum_finance_cate_value| qbscurrency}}</td>
                    <td style="text-align: right; width: 115px;">{{item.sum_finance_value_done| qbscurrency}}</td>
                    <td style="text-align: right; width: 122px;">{{item.sum_finance_value_doing| qbscurrency}}</td>



                    <td style="text-align: center">
                        <span *ngIf="!item.deleted" class="badge-primary badge-point"></span>
                        <span *ngIf="item.deleted" class="badge-default badge-point"></span>
                    </td>

                    <td>
                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)' placement="top" ngbTooltip="Cập nhật">
                            <span class="material-icons">
                                settings
                            </span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới khoản thu</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tên khoản thu</mat-label>
                    <input matInput [(ngModel)]="cate.name" placeholder="Nhập khoản thu" name="calss_name" required maxlength="200">
                </mat-form-field>

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Giá trị</mat-label>
                    <input matInput [(ngModel)]="cate.finance_value" currencyMask placeholder="Giá trị" name="finance_value" required maxlength="9">
                </mat-form-field>

                <mat-form-field appearance="fill" class="mb-3">
                    <mat-label>Tần xuất thu</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="cate.finance_frequency" name="finance_finance_frequencyunit">
                        <mat-option *ngFor="let item of appService.finance_frequencys" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mb-3">
                    <mat-label>Loại thu</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="cate.finance_type" name="finance_finance_frequencyunit">
                        <mat-option *ngFor="let item of appService.finance_cate_type" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="cate.finance_type=='dot'">
                    <div class="" *ngFor="let item of finance_content_temp; let i = index">
                        <div class="row">

                            <div class="col">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Tên khoản thu {{i+1}}</mat-label>
                                    <input matInput [(ngModel)]="item.label" placeholder="Tên khoản thu" [name]="'aaaaname'+i" maxlength="200">
                                    <button mat-icon-button matSuffix *ngIf="i != 0" (click)="removeItem(i)" class="btn btn-sm btn-icon-only btn-circle">
                                        <span class="btn-label"><span class="material-icons"> clear </span></span>
                                      </button>
                                </mat-form-field>
                            </div>
                            <div class="col text-left">
                                <!-- <button *ngIf="i != 0" class="btn btn-outline-secondary btn-icon-only btn-circle " (click)="removeItem(i)">
                                    <span class="btn-label"><span class="material-icons"> clear </span></span>
                                </button> -->
                                <button *ngIf="i+1 == finance_content_temp.length && finance_content_temp.length < 10 " style="margin-top: 14px;" class="btn btn-sm btn-primary btn-icon-only btn-circle " (click)="addItem('')">
                                    <span class="btn-label"><span class="material-icons"> add </span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Tạo khoản thu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật khoản thu</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tên khoản thu</mat-label>
                    <input matInput [(ngModel)]="categoryselected.name" placeholder="Nhập khoản thu" name="calss_name" required maxlength="200">
                </mat-form-field>

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Giá trị</mat-label>
                    <input matInput [(ngModel)]="categoryselected.finance_value" currencyMask placeholder="Giá trị" name="finance_value" required maxlength="9">
                </mat-form-field>


                <mat-form-field appearance="fill" class="mb-3">
                    <mat-label>Tần xuất thu</mat-label>
                    <mat-select [disabled]="true" [(ngModel)]="categoryselected.finance_frequency" name="finance_finance_frequencyunit">
                        <mat-option *ngFor="let item of appService.finance_frequencys" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="mb-3">
                    <mat-label>Loại thu</mat-label>
                    <mat-select [disabled]="true" [(ngModel)]="categoryselected.finance_type" name="finance_type">
                        <mat-option *ngFor="let item of appService.finance_cate_type" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="categoryselected.finance_type=='dot'">
                    <div class="" *ngFor="let item of finance_content_temp; let i = index">
                        <div class="row">

                            <div class="col">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Tên khoản thu {{i+1}}</mat-label>
                                    <input matInput [(ngModel)]="item.label" placeholder="Tên khoản thu" [name]="'aaaaname'+i" maxlength="200">
                                    <button mat-icon-button matSuffix *ngIf="i != 0" (click)="removeItem(i)" class="btn btn-sm btn-icon-only btn-circle">
                                        <span class="btn-label"><span class="material-icons"> clear </span></span>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col text-left">
                                <!-- <button *ngIf="i != 0" class="btn btn-outline-secondary btn-icon-only btn-circle " (click)="removeItem(i)">
                                    <span class="btn-label"><span class="material-icons"> clear </span></span>
                                </button> -->
                                <button *ngIf="i+1 == finance_content_temp.length && finance_content_temp.length < 10 " style="margin-top: 14px;" class="btn btn-sm btn-primary btn-icon-only btn-circle " (click)="addItem('')">
                                    <span class="btn-label"><span class="material-icons"> add </span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <!-- <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'"> -->
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>