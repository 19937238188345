<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm khung giờ" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>


            <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class=" row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Lịch làm việc</th>
                    <th class="text-center">Ngày bắt đầu</th>
                    <th class="text-center">Ngày kết thúc</th>
                    <th class="text-center">Thời gian</th>
                    <th class="text-center" style="width: 115px;">Thời lượng</th>
                    <th class="text-center">Phút bắt đầu</th>
                    <th class="text-center">Phút tính sơm</th>
                    <th class="text-center">Phút tính muộn</th>

                    <th class="text-center">Ngày nghỉ</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <a class="avatar-img-list" href="javascript:;">
                            <img class="img-circle mt-1" src="./assets/img/icons/checkin-time.png" alt="image" width="30" />
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div style="font-size: small; margin-top: 3px;font-weight: bold;">{{item.checkin_weeks_name}}</div>
                    </td>
                    <td class="text-center">
                        <span>{{item.startTime_str}}</span>
                    </td>
                    <td class="text-center">
                        <span>{{item.endTime_str}}</span>
                    </td>
                    <td class="text-center">{{item.checkin_start}} - {{item.checkin_end }}</td>
                    <td class="text-center">{{item.time_duration}}</td>
                    <td class="text-center">{{item.checkin_effective}}</td>
                    <td class="text-center">{{item.checkin_early}}</td>
                    <td class="text-center">{{item.checkin_late}}</td>
                    <td>
                        <button [disabled]="!appService.checkFullPermission()" (click)='openHolidayPicker(item)' class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                            <span class="btn-label"><span class="material-icons text-primary font-18"> info </span></span>
                            {{item.holiday_count}}
                        </button>
                    </td>
                    <td>
                        <!-- <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button> -->
                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Chỉnh sửa" (click)='popupUpdate(item)'>
                            <span class="material-icons"> settings </span>
                        </button>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>


</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới lịch dạy / làm việc</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tên lịch làm việc</mat-label>
                    <input matInput [(ngModel)]="cate.name" placeholder="Nhập tên ca" name="name" required minlength="2" maxlength="100">
                </mat-form-field>
                <div class="row">
                    <div class="col">
                        <!-- <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.hockyI_startTime" placeholder="Bắt đầu" name="hockyI_startTime" required minlength="10" maxlength="10">
                        </mat-form-field> -->
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput [matDatepicker]="picker_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_startTime" name="hockyI_startTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_startTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_startTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <!-- <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết thúc</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.hockyI_endTime" placeholder="Kết thúc" name="hockyI_endTime" required minlength="10" maxlength="10">
                        </mat-form-field> -->

                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết thúc</mat-label>
                            <input matInput [matDatepicker]="picker_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_endTime" name="hockyI_endTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_endTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_endTime></mat-datepicker>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Giờ vào</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.checkin_start" placeholder="Giờ vào" name="checkin_start" required minlength="5" maxlength="5" (ngModelChange)="checkinChanged($event)">
                        </mat-form-field>

                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Giờ ra</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.checkin_end" placeholder="Giờ ra" name="checkin_end" required minlength="5" maxlength="5" (ngModelChange)="checkinChanged($event)">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Thời lượng</mat-label>
                            <input matInput type="text" [(ngModel)]="duration" name="thoiluong" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút tính sớm</mat-label>
                            <input matInput type="number" [(ngModel)]="cate.checkin_early" placeholder="Số phút tính sớm" name="checkin_early" required min="0" max="120">
                        </mat-form-field>

                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút tính muộn</mat-label>
                            <input matInput type="number" [(ngModel)]="cate.checkin_late" placeholder="Số phút tính muộn" name="checkin_late" required min="0" max="120">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút bắt đầu tính</mat-label>
                            <input matInput type="number" [(ngModel)]="cate.checkin_effective" placeholder="Số phút bắt đầu tính" name="checkin_effective" required min="0" max="120">
                        </mat-form-field>

                    </div>
                    <div class="col">

                    </div>
                </div>
                <div class="row p-2">
                    <section class="mt-1 mb-3 weeks-checkbox">
                        <div class="mb-1">Ngày áp dụng</div>
                        <mat-checkbox *ngFor="let item of appService.weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                    </section>
                </div>






                <!-- <section class="mt-4 mb-3 weeks-checkbox">
                    <div class="mb-1">Ngày học trong tuần</div>
                    <mat-checkbox *ngFor="let item of appService.weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                </section>
                <div class="mb-1">Ca học</div> -->


            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật lịch dạy / làm việc</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tên lịch làm việc</mat-label>
                    <input matInput [(ngModel)]="categoryselected.name" placeholder="Nhập tên ca" name="u_name" required minlength="2" maxlength="30">
                </mat-form-field>
                <div class="row">
                    <div class="col">


                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput [matDatepicker]="picker_u_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.hockyI_startTime" name="u_startTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_u_startTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_u_startTime></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col">


                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết đầu</mat-label>
                            <input matInput [matDatepicker]="picker_u_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.hockyI_endTime" name="u_endTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_u_endTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_u_endTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Giờ vào</mat-label>
                            <input matInput type="time" [(ngModel)]="categoryselected.checkin_start" placeholder="Giờ vào" name="u_checkin_start" required minlength="5" maxlength="5">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Giờ ra</mat-label>
                            <input matInput type="time" [(ngModel)]="categoryselected.checkin_end" placeholder="Giờ ra" name="u_checkin_end" required minlength="5" maxlength="5">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút tính sớm</mat-label>
                            <input matInput type="number" [(ngModel)]="categoryselected.checkin_early" placeholder="Số phút tính sớm" name="u_checkin_early" required max="120">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút tính muộn</mat-label>
                            <input matInput type="number" [(ngModel)]="categoryselected.checkin_late" placeholder="Số phút tính muộn" name="u_checkin_late" required max="120">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số phút bắt đầu tính</mat-label>
                            <input matInput type="number" [(ngModel)]="categoryselected.checkin_effective" placeholder="Số phút bắt đầu tính" name="u_checkin_effective" required max="120">
                        </mat-form-field>
                    </div>
                    <div class="col">

                    </div>
                </div>

                <div class="row p-2">
                    <section class="mt-1 mb-3 weeks-checkbox">
                        <div class="mb-1">Ngày áp dụng</div>
                        <mat-checkbox *ngFor="let item of this.temp_weeks_update; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                    </section>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <!-- <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'"> -->
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>