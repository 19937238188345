<div class="row mb-2 mt-2 justify-content-between">
  <div class="col">
    <div class="flexbox-b">
      <span class="mr-4 static-badge badge-primary"><i class="ti ti-gift"></i></span>
      <div>
        <div style="font-size: 20px;">Tạo Chương Trình Khuyến Mãi</div>
      </div>
    </div>

  </div>
  <div class="col">
    <div class="d-flex justify-content-end">
      <div class="p-2">
        <!-- <button class="btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal"
          data-target="#add-customer-modal" (click)="insertPromotion()">
          <span class="btn-label"><i class="ti ti-save"></i></span>Lưu
        </button> -->


        <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon"
          [disabled]="isLoading || this.promotionAddForm.invalid"  (click)="insertPromotion()">
          <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
          <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-save"></i></span>
          Lưu
        </button>

      </div>
      <div class="p-2">
        <button class="btn btn-sm btn-labeled btn-labeled-left btn-icon" data-toggle="modal"
          data-target="#add-customer-modal" (click)="backToList()">
          <span class="btn-label"><i class="ti ti-close"></i></span>Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <form #promotionAddForm="ngForm">
    <div class="row">

      <div class="col-sm">
        <div class="p-4">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Mã</label>
            <div class="col-sm-9">
              <input [(ngModel)]="promotion.code" class="form-control" required name="code" type="text" placeholder="Mã"
                (ngModelChange)="promotion.code = $event.toUpperCase()" (focusout)="focusOutCheckCode()"
                (keydown.space)="$event.preventDefault();">
             
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Kiểu</label>
            <div class="col-sm-9 d-flex align-items-center">
              <label class="radio radio-grey radio-primary radio-inline">
                <input type="radio" name="b" [checked]="'%' == promotion.type" (click)="SelectkieuKhuyenMai('%')">
                <span class="input-span"></span>Khuyến mãi %</label>
              <label class="radio radio-grey radio-primary radio-inline">
                <input type="radio" name="b" [checked]="'đ' == promotion.type" (click)="SelectkieuKhuyenMai('đ')">
                <span class="input-span"></span>Khuyến mãi tiền</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Nội dung</label>
            <div class="col-sm-9">
              <textarea [(ngModel)]="promotion.description" class="form-control" name="phone"
                placeholder="Nội dung chương trình khuyến mãi" type="text" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="p-4">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Tên</label>
            <div class="col-sm-9">
              <input [(ngModel)]="promotion.name" class="form-control" required maxlength="100" name="name" id="ssss"
                placeholder="Tên chương trình" type="text">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Khuyến mãi</label>
            <div class="col-sm-9">
              <div class="input-group-icon input-group-icon-right">
                <span class="input-icon input-icon-right">{{promotion.type}}</span>
                <input class="form-control" type="text"  [(ngModel)]="promotion.promotion" name="promotion"  currencyMask required placeholder="Giá trị">
              </div>

            </div>
          </div>
          <div class="form-group row" id="date_5">
            <label class="col-sm-3 col-form-label">Thời gian</label>
            <div class="col-sm-9">


              <div class="form-inline">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
                <div class="form-group col-6 pl-0">
                  <div class="input-group">
                    <input #dpFromDate class="form-control" placeholder="dd/mm/yyyy" name="dpFromDate"
                      [value]="formatter.format(fromDate)"
                      (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()"
                        type="button"></button>
                    </div>
                  </div>
                </div>
                <div class="form-group  col-6">
                  <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="dd/mm/yyyy" name="dpToDate"
                      [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()"
                        type="button"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="container">
  <div class="row">
    <div class="ibox" style="width: 100%">
      <div class="ibox-head">
        <div class="ibox-title">Đối tượng tham gia</div>
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link active" href="#tab-nhom-san-pham" data-toggle="tab" aria-expanded="false">Nhóm sản
              phẩm</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " href="#tab-nhom-khach-hang" data-toggle="tab" aria-expanded="true">Nhóm khách
              hàng</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#tab-demo" data-toggle="tab" aria-expanded="false">Demo</a>
          </li> -->
        </ul>
      </div>
      <div class="ibox-body">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tab-nhom-san-pham" aria-expanded="true">
            <div>
              <ng-multiselect-dropdown [placeholder]="'Danh mục sản phẩm'" [data]="CategoryList"
                [(ngModel)]="CategorySelectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
            <div class="mb-2 mt-2">Nhưng sản phẩm thuộc danh mục đã chọn sẽ được tham gia chương trình</div>
          </div>
          <div class="tab-pane fade" id="tab-nhom-khach-hang" aria-expanded="false">
            <div>
              <ng-multiselect-dropdown [placeholder]="'Nhóm khách hàng'" [data]="categorys_customer"
                [(ngModel)]="categorys_customer_selectedItems" [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
            <div class="mb-2 mt-2">Nhưng khách hàng thuộc nhóm đã chọn sẽ được tham gia chương trình</div>
          </div>
          <div class="tab-pane fade text-center" id="tab-demo" aria-expanded="false">
            <div class="h1 mt-5 mb-3">TAB 3</div>
            <div class="row">




              <div class="col-xs-12 col-12 col-md-4 form-group">
                tessdfdf
              </div>
              <div class="col-xs-12 col-12 col-md-4 form-group">


              </div>
            </div>
            <p class="mb-5">Laborum dolore consectetur occaecat in ex sunt et dolore dolore aliqua aute qui cupidatat et
              ullamco in pariatur dolor quis laboris ut.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>