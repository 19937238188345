<div class="page-header">
    <div class="ibox flex-1">
        <div class="mt-2 pt-2">
            <div class="flexbox">
                <div class="flexbox-b">
                    <div class="ml-5 mr-5">
                        <img class="img-circle" src="./assets/img/icons/person.svg" alt="image" width="110" />
                    </div>
                    <div>
                        <h4>{{appService.currentUser.name}}</h4>
                        <div class="text-muted font-13 mb-3">
                            <span><i class="ti ti-calendar mr-2"></i>{{appService.currentUser.created_date}}</span>
                            <span class="mr-3"><i class="ti ti-location-pin mr-2"></i>{{appService.currentUser.shop_address}}</span>
                        </div>
                        <div>
                            <span class="mr-3">
                  <span class="badge badge-primary badge-circle mr-2 font-14" style="height:30px;width:30px;"><i
                      class="ti ti-briefcase"></i></span>Quản lý</span>
                            <!-- <span>
                                <span class="badge badge-pink badge-circle mr-2 font-14"
                                    style="height:30px;width:30px;"><i class="ti-cup"></i></span>Vip Status</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-head">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="#tab-shop-setting" data-toggle="tab" aria-expanded="false">Thông tin Shop</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" href="#tab-3-3" data-toggle="tab" aria-expanded="true">Thông tin cá nhân</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-3-2" data-toggle="tab" aria-expanded="false">Máy in</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-desk" data-toggle="tab" aria-expanded="false" (click)="loadDesk()">Bàn/Phòng dịch vụ</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#tab-desk-price" data-toggle="tab" aria-expanded="false" (click)="loadDeskPrice()">Cap</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="page-content fade-in-up">
    <div class="row">
        <div class="ibox" style="width:100%">
            <!-- <div class="ibox-head">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link" href="#tab-3-1" data-toggle="tab" aria-expanded="false">Active</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-3-2" data-toggle="tab" aria-expanded="false">Second</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" href="#tab-3-3" data-toggle="tab" aria-expanded="true">Third</a>
            </li>
          </ul>
        </div> -->
            <div class="">
                <div class="tab-content">

                    <div class="tab-pane fade text-center" id="tab-3-2" aria-expanded="false">

                        <div class="row">
                            <div class="col">
                                <h4 class="font-strong mb-4">Cài đặt máy in</h4>
                                <form class="form-horizontal">
                                    <div class="ibox-body">


                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Chiều rộng</label>
                                            <div class="col-sm-8">
                                                <div class="mb-4 input-group-icon input-group-icon-right">
                                                    <input type="text" required="" placeholder="Chiều rộng" class="form-control" style="text-align: right;">
                                                    <span class="input-icon input-icon-right">cm</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Chiều Y</label>
                                            <div class="col-sm-8">
                                                <div class="mb-4 input-group-icon input-group-icon-right">
                                                    <input type="text" required="" placeholder="Chiều Y" class="form-control" style="text-align: right;">
                                                    <span class="input-icon input-icon-right">cm</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Chiều Z</label>
                                            <div class="col-sm-8">
                                                <div class="mb-4 input-group-icon input-group-icon-right">
                                                    <input type="text" required="" placeholder="Chiều Z" class="form-control" style="text-align: right;">
                                                    <span class="input-icon input-icon-right">cm</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-primary mr-2">Lưu</button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">
                                <app-printer></app-printer>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center" id="tab-desk-price" aria-expanded="false">
                        <div class="row ml-5">
                            Cài đặt cấp học
                        </div>
                        <div class="row">
                            <div class="col">
                                <form class="form-horizontal" id="deskPriceForm" #deskPriceForm="ngForm" (ngSubmit)="updateDeskPrice()">
                                    <div class="ibox-body">
                                        <div class="row divider-bottom" *ngFor="let item of this.appService.deskListPrice; let i = index">

                                            <div class="col">
                                                <div class="form-group ow">
                                                    <div class="">
                                                        <input [(ngModel)]="item.name" class="form-control" [name]="'name'+i" required maxlength="30" placeholder="Tên giá" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group ow">
                                                    <div class="">
                                                        <input [(ngModel)]="item.price" currencyMask class="form-control" [name]="'price'+i" required maxlength="30" placeholder="Giá dịch vụ" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group ow">
                                                    <div class="">
                                                        <input [(ngModel)]="item.timeStart" class="form-control" [name]="'timeStart'+i" required maxlength="30" placeholder="Giờ bắt đầu" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group ow">
                                                    <div class="">
                                                        <input [(ngModel)]="item.timeEnd" class="form-control" [name]="'timeEnd'+i" required maxlength="30" placeholder="Giờ kết thúc" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <button *ngIf="i != 0" class="btn btn-outline-secondary btn-icon-only btn-circle btn-sm" (click)="removeMoreDeskPrice(i)">
                            <span class="btn-label"><i style="font-size: 15px;" class="ti ti-close"></i></span>
                          </button>
                                                <button *ngIf="i+1 == this.appService.deskListPrice.length && this.appService.deskListPrice.length < 4 " class="btn btn-outline-primary btn-icon-only btn-circle btn-sm" (click)="addMoreDeskPrice(i+2)">
                            <span class="btn-label"><i style="font-size: 15px;" class="ti ti-plus"></i></span>
                          </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row justify-content-center">

                                        <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.deskPriceForm.invalid">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                        Cập nhật
                      </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade text-center" id="tab-shop-setting" aria-expanded="false">
                        <div class="row">
                            <div class="col">
                                <form class="form-horizontal" id="shopProfileForm" #shopProfileForm="ngForm" (ngSubmit)="updateShopProfile()">
                                    <div class="ibox-body">
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Tên Shop</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.shop_name" placeholder="Tên Shop" name="shop_name" required maxlength="20">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Tên viết tắt</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.shop_name2" placeholder="Tên viết tắt" name="shop_name2" required maxlength="10">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Mã Shop</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" readonly [(ngModel)]="appService.currentUser.shop_code" name="shop_code">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Số bàn phục vụ</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" numbersOnly [(ngModel)]="appService.currentUser.desk_number" name="desk_number">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Email Shop</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.shop_email" name="shop_email" placeholder="Email Shop">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">SĐT Shop</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" numbersOnly placeholder="SĐT Shop" [(ngModel)]="appService.currentUser.shop_phone" name="shop_phone">
                                            </div>
                                        </div>
                                        <div class="form-group mb-4 row">
                                            <label class="col-sm-4 col-form-label text-right">Địa chỉ Shop</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="text" [(ngModel)]="appService.currentUser.shop_address" name="shop_address" placeholder="Địa chỉ Shop">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row justify-content-center">

                                        <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.shopProfileForm.invalid">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                        Cập nhật
                      </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center active show" id="tab-3-3" aria-expanded="true">
                        <div class="">
                            <!-- <div class="ibox-head">
                  <div class="ibox-title">Thông tin cá nhân</div>
                </div> -->
                            <form class="form-horizontal" id="updateUserForm" #userProfileForm="ngForm" (ngSubmit)="updateUserProfile()">
                                <div class="ibox-body">
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Họ và tên</label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" [(ngModel)]="appService.currentUser.name" placeholder="Họ và tên" name="name" required maxlength="20">
                                        </div>
                                    </div>
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Phone</label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" [(ngModel)]="appService.currentUser.phone" placeholder="Phone" name="Phone" required maxlength="10" minlength="10">
                                        </div>
                                    </div>
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Email</label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" [(ngModel)]="appService.currentUser.email" placeholder="Email" name="email">
                                        </div>
                                    </div>
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Đổi mật khẩu</label>
                                        <div class="col-sm-8 d-flex align-items-center">
                                            <label class="ui-switch switch-icon">
                          <input type="checkbox" [(ngModel)]="isRessetPass" name="deleted">
                          <span></span>
                        </label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Mật khẩu</label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="password" [(ngModel)]="password" [disabled]="!isRessetPass" placeholder="Mật khẩu" name="pass">
                                        </div>
                                    </div>
                                    <div class="form-group mb-4 row">
                                        <label class="col-sm-4 col-form-label text-right">Nhập lại mk</label>
                                        <div class="col-sm-8 ">
                                            <input class="form-control" type="password" [(ngModel)]="repassword" [disabled]="!isRessetPass" placeholder="Nhập lại mật khẩu" name="repasss">
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">

                                    <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.userProfileForm.invalid">
                      <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                      <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                      Cập nhật
                    </button>

                                    <!-- <button class="btn btn-primary mr-2">Cập nhật</button> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="tab-pane fade text-center  show" id="tab-desk" aria-expanded="true">

                        <div class="row justify-content-between">
                            <div class="col">
                                <div class="d-flex justify-content-start">
                                    <div class="p-2">
                                        Tổng bàn/phòng dịch vụ: {{this.appService.deskList.length}} / {{appService.currentUser.desk_number}}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex justify-content-end">
                                    <div class="p-2">
                                        <button class="btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openModal(deskAdd)" *ngIf="this.appService.deskList.length < this.appService.currentUser.desk_number">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                      </button>
                                        <button class="btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon" ngbTooltip="Đã đến giới hạn số lượng bàn phục vụ" triggers="click:blur" *ngIf="this.appService.deskList.length >= this.appService.currentUser.desk_number">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                      </button>
                                    </div>
                                    <div class="p-2">
                                        <div ngbDropdown class="d-inline-block">
                                            <button class="btn btn-sm  btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                          <span class="btn-label">
                            <i class="ti ti-layout-grid2"></i>
                          </span>
                          Lưu vị trí
                        </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <!-- <button ngbDropdownItem (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng lưới</button> -->
                                                <button ngbDropdownItem (click)="saveArrangeDesk()" class="">
                            <i class="ti ti-save"></i> Lưu vị trí
                          </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="desk-boundary">
                            <div *ngFor="let item of this.appService.deskList let i = index" class="desk-box" cdkDragBoundary=".desk-boundary" cdkDrag [(cdkDragFreeDragPosition)]="item.position" (cdkDragEnded)="dragEnd(item,$event)">

                                <div class=" d-flex justify-content-between pt-1 mr-5">
                                    <!-- <button class="pr-2" [disabled] = "true" (click)="openSelectProduct(selectProductModel,i)">
                      <span class="material-icons"> timer </span>
                    </button> -->
                                    <button *ngIf="item.is_time_service == 0" style="visibility: hidden" class="btn btn-outline-secondary btn-icon-only btn-circle btn-sm">
                      <i style="font-size: 18px;" class="ti ti-timer"></i>
                    </button>

                                    <i *ngIf="item.is_time_service == 1" style="font-size: 18px; color: #0388d1;" class="ti ti-timer"></i>

                                    <button style="height: 25px; width: 25px;" (click)="openModalUpdate(deskEdit, item,i)" class="btn btn-outline-secondary btn-icon-only btn-circle btn-sm">
                      <i style="font-size: 18px;" class="ti ti-pencil-alt"></i>
                    </button>
                                    <!-- <a class="pr-2" (click)="openModalUpdate(deskEdit, item,i)">
                      <span class="material-icons">edit</span>
                    </a> -->

                                    <button style="height: 25px; width: 25px;" (click)="openModalDelete(deskDeleteConfirm, item,i)" class="btn btn-outline-secondary btn-icon-only btn-circle btn-sm">
                      <i style="font-size: 18px;" class="ti ti-close"></i>
                    </button>

                                    <!-- 
                    <a class="pr-2" (click)="openModalDelete(deskDeleteConfirm, item,i)">
                      <span class="material-icons">clear</span>
                    </a> -->
                                    <!-- <a ><span class="material-icons">face</span></a> -->
                                </div>


                                <div class="mt-2">{{item.name}}</div>

                                <div class="desk-move-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                      </path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                                </div>


                            </div>
                        </div>

                        <ng-template #deskAdd let-c="close" let-d="dismiss">
                            <form class="modal-content form-horizontal" #addDeskForm="ngForm" id="form-add-desk" (ngSubmit)="insertDesk()">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal-basic-title">Thêm vị trí phục vụ</h5>
                                </div>
                                <div class="modal-body">
                                    <div class="row" *ngFor="let item of deskAddList; let i = index">
                                        <div class="col">
                                            <div class="form-group ow">
                                                <label class="col-form-label">Tên bàn hoặc phòng</label>
                                                <div class="">
                                                    <input [(ngModel)]="item.name" class="form-control" [name]="item.name+i" required maxlength="30" placeholder="Tên bàn / phòng" type="text">
                                                </div>
                                            </div>
                                            <div class="row text-right">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label class="checkbox checkbox-grey checkbox-primary">
                                  <input type="checkbox" [(ngModel)]="item.is_time_service" name="timeService">
                                  <span class="input-span"></span>có tính giờ phục vụ
                                </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!-- <div class="col-3">
                        <button *ngIf="i+1 != deskAddList.length" class="btn btn-sm btn-icon" (click)="removeMoreDesk(i)">
                          <span class="btn-label"><i class="ti ti-close"></i></span>
                        </button>
                        <button
                          *ngIf="i+1 == deskAddList.length && (deskAddList.length + this.appService.deskList.length) < this.appService.currentUser.desk_number"
                          class="btn btn-primary btn-sm btn-icon" (click)="addMoreDesk()">
                          <span class="btn-label"><i class="ti ti-plus"></i></span>
                        </button>
                      </div> -->
                                    </div>
                                    <!-- *ngIf="i == deskList.length"  -->
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addDeskForm.invalid">
                      <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                      <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                      Thêm mới
                    </button>
                                    <button type="button" class="btn btn-sm" (click)="c('Save click')">Đóng</button>
                                </div>
                            </form>
                        </ng-template>

                        <ng-template #deskEdit let-c="close" let-d="dismiss">
                            <form class="modal-content form-horizontal" #updateDeskForm="ngForm" id="form-update-desk" (ngSubmit)="updateDesk()">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal-basic-title">Chỉnh sửa</h5>
                                </div>
                                <div class="modal-body">
                                    <label class="col-form-label">Tên bàn hoặc phòng</label>
                                    <div class="row" *ngFor="let item of deskAddList; let i = index">
                                        <div class="col">
                                            <div class="form-group ow">
                                                <div class="">
                                                    <input [(ngModel)]="deskSelected.name" class="form-control" name="sdsdf" required maxlength="30" placeholder="Tên bàn / phòng" type="text">
                                                </div>
                                            </div>
                                            <div class="row text-right">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label class="checkbox checkbox-grey checkbox-primary">
                                <input type="checkbox" [(ngModel)]="deskSelected.is_time_service" name="timeService">
                                <span class="input-span"></span>có tính giờ phục vụ
                              </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- *ngIf="i == deskList.length"  -->
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateDeskForm.invalid">
                      <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                      <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                      Cập nhật
                    </button>
                                    <button type="button" class="btn btn-sm" (click)="c('Save click')">Đóng</button>
                                </div>
                            </form>
                        </ng-template>

                        <ng-template #deskDeleteConfirm let-c="close" let-d="dismiss">
                            <form class="modal-content form-horizontal" #updateDeskForm="ngForm" id="form-delete-confirm-desk" (ngSubmit)="deleteDesk()">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal-basic-title">Xác nhận xóa bàn phục vụ</h5>
                                </div>
                                <div class="modal-body">
                                    <label class="col-form-label">Tên bàn hoặc phòng</label>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group ow">
                                                <div class="">
                                                    <input [(ngModel)]="deskSelected.name" class="form-control" name="deskSelected" readonly maxlength="30" placeholder="Tên bàn / phòng" type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- *ngIf="i == deskList.length"  -->
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
                      <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                      <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                      Xóa
                    </button>
                                    <button type="button" class="btn btn-sm" (click)="c('Close click')">Đóng</button>
                                </div>
                            </form>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>