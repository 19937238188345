<div class="row">
    <div class="col d-flex">
        <div class="card">
            <div class="card-body flexbox">
                <img [src]="appService.showImageTeacher2(data.class_checkin.image_url)" alt="Avatar" style="height: 55px; border-radius: 50%;">
                <div class="text-right ml-3">
                    <h3 class="mb-1">{{data.class_checkin.teacher_name}}</h3>
                    <div class="text-muted">Giáo viên chủ nhiệm</div>
                </div>
            </div>
        </div>
        <div class="card ml-3">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.class_checkin.class_code}}</h2>
                    <div class="">Lớp</div>
                </div>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center  mr-5">
                    <h2 class="mb-1">{{data.class_checkin.student_count}}</h2>
                    <div class="">Sỹ số lớp</div>
                </div>
                <div class="text-center text-primary mr-5">
                    <h2 class="mb-1">{{data.class_checkin.student_register}}</h2>
                    <div class="">Số đăng ký</div>
                </div>
                <div class="text-center text-danger mr-5">
                    <h2 class="mb-1">{{data.class_checkin.student_inattention}}</h2>
                    <div class="">Báo vắng</div>
                </div>
                <div class="text-center mr-5">
                    <h2 class="mb-1">{{data.class_checkin.percent}}%</h2>
                    <div class="">Tỷ lệ</div>
                </div>
                <div class="text-center">
                    <button class="btn btn-warning" (click)="this.dialogRef.close();">
                        <span class="material-icons font-26">
                        close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 45px; text-align: center;">#</th>
                <th>Họ tên</th>
                <th>Tên</th>

                <th class="text-center">Dịch vụ</th>
                <th class="text-center">Báo ăn</th>
                <th>Ghi chú</th>
                <!-- <th>Có phép</th> -->

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.students; let i = index" [ngClass]="{'bg-primary-100': false }">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">


                    <a class="avatar-img-list" style="width: 30px;  height: 30px; position: relative;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)">
                        <span class="stt_span"> <span class="badge badge-default badge-circle">{{i+1}}</span></span>
                    </a>
                </td>
                <td>
                    <a class="d-flex justify-content-start align-items-center">
                        <div>
                            <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                        </div>
                        <div>
                            <div style="font-size: 18px;">
                                {{item.full_name}}
                            </div>
                            <div class="text-muted">
                                {{item.code}}
                            </div>
                        </div>
                    </a>

                </td>
                <td>
                    <div style="font-size: 18px;">{{item.name}}</div>
                </td>

                <td class="text-center">

                    <button [ngClass]="{'btn-primary' : item.meal == 1}" class="mr-2 btn btn-sm  btn-icon-only btn-circle  btn-thick" placement="top">
                        <img [ngClass]="{'img-disable' : item.meal == 0}" src="./assets/img/icons/meal.png" width="22px" />
                    </button>
                </td>
                <td class="text-center">
                    <label *ngIf="item.meal == 1 " class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" [(ngModel)]="item.meal_temp" disabled="true">
                        <span class="input-span"></span>
                    </label>
                    <label *ngIf="item.meal == 0 " class="checkbox checkbox-primary check-single" style="opacity: 0.2;">
                        <input class="mail-check" type="checkbox" disabled="true" >
                        <span class="input-span"></span>
                    </label>
                    <div *ngIf="item.meal_time != ''" class="text-center">
                        <span class="text-warning font-14" style="background: white; padding: 0px 8px;
                    border-radius: 6px;">{{item.meal_time}}</span>
                    </div>
                </td>
                <td class="">
                    <div>
                        {{item.meal_note}}
                    </div>
                    <!-- <div>
                        {{item.meal_time}}
                    </div> -->
                </td>
                <!-- <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" disabled="true" [ngModel]="!item.checkin_status_temp" >
                        <span class="input-span"></span>
                    </label>
                </td> -->

            </tr>
        </tbody>
    </table>
    <div *ngIf="isLoading" class="spinner-loadClass">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>