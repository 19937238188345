<div class="m-2">
    <!-- <h4 mat-dialog-title>Install Angular</h4> -->
    <h4>Danh sách Học sinh thanh toán Khoản thu <strong> {{data.finance_cate_name}}</strong></h4>
    <div>Tổng <strong>{{temp_student_count}}</strong> Học sinh, tổng thanh toán <strong>{{temp_data_total |
            qbscurrency}}</strong></div>
</div>
<mat-dialog-content>

    <div *ngIf="dataList.length == 0" class="text-center">
        <app-nodata msg="Chưa có khoản thu nào được gán"></app-nodata>
    </div>
    <div *ngIf="dataList.length > 0" class="qbs-scroll">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 40px; text-align: center;">#</th>
                    <th style="width: 50px; text-align: center;"></th>
                    <th>Code</th>
                    <th>Học sinh</th>
                    <th class="text-center">Tần xuất</th>
                    <th style="text-align: center">Kỳ thu</th>
                    <th>Giá trị</th>
                    <!-- <th class="text-right">Đã thanh toán</th>
                        <th class="text-center">Thời gian</th>
                        <th class="text-center">Hình thức</th> -->
                    <th class="no-sort">
                        <label class="checkbox myHeader checkbox-warning check-single" style="margin-bottom: 6px;">
                            <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                            <span class="input-span"></span>
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataList; let i = index" [ngClass]="{'row-disabled': item.temp_checked_origin == 0, 'row-checked' : item.temp_checked, 'row-paid' : item.status == 2}">
                    <td class="text-center">
                        {{i+1}}
                    </td>
                    <td class="text-center">
                        <a class="avatar-img-list" href="javascript:;">
                            <img *ngIf="item.status < 2" class="img-circle mt-1" src="./assets/img/icons/finance.png" alt="image" width="30" />
                            <img *ngIf="item.status == 2" class="img-circle mt-1" src="./assets/img/icons/check-mark.png" alt="image" width="30" />
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">
                            {{appService.currentUser.bidv_merchantId}}{{item.finance_student_id}}</div>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.full_name}}</div>
                    </td>
                    <td style="text-align: center">{{item.finance_frequency}}</td>
                    <td style="text-align: center">
                        <div *ngIf="item.finance_frequency == 'Tháng'">
                            {{item.period_time_str}}
                        </div>
                    </td>
                    <td class="text-right">
                        <div style="font-size: 18px; font-weight: bold;">{{item.finance_value| qbscurrency}}</div>
                    </td>
                    <!-- <td class="text-right " [ngClass]="{'text-primary': item.payment_value > 0}">
                            <div style="font-size: 18px; font-weight: bold;">{{item.payment_value| qbscurrency}}</div>
                        </td>
                        <td class="text-center">
                            <div style="max-width: 90px; font-size: small;">{{item.payment_time_str}}</div>
                        </td>
                        <td class="text-center">
                            <div style="font-size: 18px; font-weight: bold;">{{item.payment_type}}</div>
                        </td> -->
                    <td class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox" [(ngModel)]="item.temp_checked"
                                (input)="onChange($event.target.value)">
                            <span class="input-span"></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="isLoading" class="text-center m-2">
        <span class="spinner-border spinner-lg"></span>
    </div>
    <!-- <div class="mt-4 d-flex justify-content-end">
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Thanh toán
            </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
            </button>
            </div> -->




</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
        <span class="btn-label"><span class="material-icons">done</span></span>Thanh toán
    </button>
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>