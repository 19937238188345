import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { environment } from 'src/environments/environment';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';

@Component({
  selector: 'app-popup-copy-next-schedule-detail',
  templateUrl: './popup-copy-next-schedule-detail.component.html',
  styleUrls: ['./popup-copy-next-schedule-detail.component.css']
})
export class PopupCopyNextScheduleDetailComponent implements OnInit {
  items = [];
  num_w = 0;
  dateSelected = '';
  type_name = '';

  week_list = [];
  temp_data = [];
  isLoading = false;
  constructor(
    public appService: AppService,
    public categoryeduService: CategoryEduService,
    private teacherService: TeacherService,
    private utility: UtilityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<PopupCopyNextScheduleDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    this.num_w = data.num_w;
    this.items = data.data; 
    this.dateSelected = data.dateSelected;
    this.type_name = 'Copy lịch tuần';
    if(this.num_w > 0){
      this.week_list = this.utility.getWeeksOfNext(this.dateSelected, this.num_w);
    }else if(this.num_w == 0){ // = -1 hoặc = 0
      this.week_list = this.utility.getWeeksOfNextMonth(this.dateSelected, this.num_w);
    }else if(this.num_w == -1){ // = -1 hoặc = 0
      this.week_list = this.utility.getWeeksOfNextMonthType(this.dateSelected, this.num_w);
    }
    

    console.log("week_list", this.week_list);
  }

  ngOnInit(): void {
    this.updateData();
  }
  

  okClick(){
    this.updateData();
    
    if(this.temp_data.length == 0){
      this.toastr.warning("Không có dữ liệu copy");
      return;
    }
    console.log(this.temp_data);
    this.teacherService.saveTeachingAssignment(this.temp_data).subscribe(
      async (response) => {
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success( `Cập nhật/Copy lịch dạy thành công ${response.value}`,'Thành công');
          this.dialogRef.close({code: 'ok', msg: ''});
        }else{
          this.toastr.warning(response.msg,'Dữ liệu không hợp lệ');
          console.error("có lỗi xảy ra");
        }
      },
      (error) => {
        console.error('error:');
        console.error(error);
      }
    );

  }
  private updateData() {
    this.temp_data = [];
    this.week_list.forEach(wtem => {
      this.items.forEach(item => {
        if (wtem.checked) {
          const copiedObject = { ...item }; // chỉ copy cấp 1 cấp 2 vẫn tham chiếu
          copiedObject.checkin_date = wtem.weekDate;
          this.temp_data.push(copiedObject);
        }
      });
    });
  }

  cancelClick() {
    this.dialogRef.close();
  }

 
}
