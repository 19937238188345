import { Component, OnInit } from '@angular/core';
import { ToastrService }  from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { FinanceService } from 'src/app/_services/finance.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-finance-banking',
  templateUrl: './finance-banking.component.html',
  styleUrls: ['./finance-banking.component.css']
})
export class FinanceBankingComponent implements OnInit {
  datas = [];
  datas_bu = [];
  data = {
    "count": "0",
    "amount": "0",
    "khongbiet": "0"
  };
  filter = {
    "name": "",
    "status": "",
  };
  msg = '';
  selectedDateStr: string = "";
  fromDate : Date;
  constructor(
    public appService: AppService,
    public financeService: FinanceService,
    private toastr: ToastrService,
    public utilityService: UtilityService,
  ) { 
    this.appService.page_title = "Đối soát giao dịch ngân hàng";
  }

  ngOnInit(): void {

  }
  doSearch(){
    console.log(this.filter);
    this.datas = [];
    if(this.filter.status == ""){ //tất cả
      if(this.filter.name == ''){
        this.datas = this.datas_bu;
      }else{
        this.datas_bu.forEach(obj => {
            if(obj.customer_id.includes(this.filter.name) || obj.content.includes(this.filter.name)){
              this.datas.push(obj);
            }
      });
      }
    }else{ // lọc theo status
      this.datas_bu.forEach(obj => {
        if(this.filter.status == obj.status){
          if(this.filter.name == "" || obj.customer_id.includes(this.filter.name) || obj.content.includes(this.filter.name)){
            this.datas.push(obj);
          }
        }
      });
    }


    console.log(this.datas);

  }
  changeStatus(){
    console.log(this.filter);
    this.doSearch();
  }
  resetData(){
    this.datas = [];
    this.data.count = "0";
    this.data.amount = "0";
    this.data.khongbiet = "0";
  }
  onDateSelect($event) {
    if(this.fromDate.getDate() >= new Date().getDate()){
      this.toastr.warning("","Chỉ được đối soát từ ngày hôm qua");
      return;
    }
    // cộng thêm 1 ngày vì đối soát sẽ trừ đi 1 ngày

    const nextDay = new Date(this.fromDate); // Tạo một bản sao của ngày hiện tại
    nextDay.setDate(this.fromDate.getDate() + 1); // Cộng thêm 1 ngày

    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(nextDay),
    console.log(this.selectedDateStr);
    this.loadReconciliation();
  }


  async loadReconciliation() {
    this.msg = '';
    let data = {
      fromDate: this.selectedDateStr,
      toDate: this.selectedDateStr,
    };
    this.filter.name = '';
    this.filter.status = '';
    this.financeService.reconciliation(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response.code == 'ok') {
          this.datas = response.datas;
          this.datas_bu = response.datas;
          this.data = response.data;
          // $('#category').selectpicker();
        }else {
          this.resetData();
          this.msg = response.msg;
          this.toastr.warning(this.msg,"Đã có lỗi xảy ra");
          // reset ngày
          this.selectedDateStr = "";
          this.fromDate = null;
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

}
