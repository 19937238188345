<div class="component">
    <div class="component-avatar">
        <img style="width: 55px;" src="assets\img\icons\medical-report.png" alt="">
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <div class="" style="width: 400px; margin-left: 12px;">
            <h4>Sổ sức khỏe - {{student_full_name}}</h4>
            <span style="font-weight: bold;">Kỳ tháng: {{healthRecord.period_time}}</span>
        </div>
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <button *ngIf="this.healthRecord.id == 0" class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon mr-2" (click)="save()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> post_add </span></span>
                    Lưu kết quả
                </button>
                <button *ngIf="this.healthRecord.id > 0" class="blurButton btn btn-warning btn-labeled btn-labeled-left btn-icon mr-2" (click)="update()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> beenhere </span></span>
                    Cập nhật kết quả
                </button>


                <!-- <div ngbDropdown class="d-inline-block">

                    <button class="blurButton btn btn-secondary btn-icon" ngbDropdownToggle>
                        <span class="btn-label"><span class="material-icons">  menu </span></span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                        <button ngbDropdownItem (click)="toggleType(0)" class="text-primary" [ngClass]="{'text-warning' : healthRecord.type == 0}">
                            <span *ngIf="healthRecord.type == 1" class="material-icons mr-2"> date_range </span>
                            <span *ngIf="healthRecord.type == 0" class="material-icons mr-2"> task_alt </span>
                            Nhập kết quả tháng
                        </button>
                        <button ngbDropdownItem (click)="toggleType(1)" class="text-primary" [ngClass]="{'text-warning' : healthRecord.type == 1}">
                            <span *ngIf="healthRecord.type == 0" class="material-icons mr-2"> calendar_month </span>
                            <span *ngIf="healthRecord.type == 1" class="material-icons mr-2"> task_alt </span>
                            Nhập kết quả học kỳ
                        </button>
                        <button ngbDropdownItem (click)="openNhapNhanhKetQua()" class="text-primary">
                            <span class="material-icons mr-2">
                                toc
                        </span>
                        Nhập nhanh cả lớp</button>
                        <button ngbDropdownItem (click)="openDoctorManager()" class="text-primary">
                            <span class="material-icons mr-2"> badge </span>
                            Cấu hình bác sĩ
                        </button>
                    </div>
                </div> -->


            </div>
        </div>
    </div>

    <div *ngIf="student_code ==  undefined || student_code == ''" class="text-center m-5 p-5">
        <img style="width: 80px;" src="assets\img\icons\cate-health.png" alt="">
        <div>Thầy/Cô chưa chọn học sinh. Vui lòng chọn lớp sau đó chọn học sinh</div>
    </div>
    <div *ngIf="student_code != ''" class="health-input-cover qbs-scroll">
        <div class="panel panel-tl">
            <div class="title">
                1. Theo dõi thể lực
            </div>
            <div class="body">
                <table>
                    <tr>
                        <td>1. Thể lực:</td>
                        <td style="text-align: right">Cân nặng: </td>
                        <td> <input type="text" [(ngModel)]="healthRecord.tl_cannang" class="input1" maxlength="4" (focusout)="focusoutFunction(healthRecord)" (keypress)="numberOnly($event)" /> kg</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">Chiều cao: </td>
                        <td><input type="text" [(ngModel)]="healthRecord.tl_chieucao" class="input1" maxlength="4" (focusout)="focusoutFunction(healthRecord)" (keypress)="numberOnly($event)" /> m</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">Chỉ số BMI: </td>
                        <td><input type="text" [(ngModel)]="healthRecord.tl_chisobmi" class="input1" readonly /> (kg/m2)</td>
                    </tr>
                    <tr>
                        <td>2. Huyết áp:</td>
                        <td style="text-align: right">Tầm thu: </td>
                        <td><input type="text" [(ngModel)]="healthRecord.tl_tamthu" class="input1" /> /mmHg</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">Tầm trương: </td>
                        <td><input type="text" [(ngModel)]="healthRecord.tl_tamtruong" class="input1" /> /mmHg</td>
                    </tr>
                    <tr>
                        <td>3. Nhịp tim:</td>
                        <td style="text-align: right"></td>
                        <td><input type="text" [(ngModel)]="healthRecord.tl_nhiptim" class="input1" /> lần/phút
                        </td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 1">
                    <!-- <span class="chucdanh">{{categorys_doctor[0].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.tl_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-nk" *ngIf="healthRecord.type == 1">
            <div class="title">
                2. Nhi khoa
            </div>
            <div class="body">
                <table class="cover-input2">
                    <tr>
                        <td>1. Tuần hoàn:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_tuanhoan" class="input2" /></td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td>2. Hô hấp:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_hohap" class="input2" /></td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td>3. Tiêu hóa:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_tieuhoa" class="input2" /></td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td>4. Thận - Tiết niệu:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_thantietnieu" class="input2" /></td>
                    </tr>
                </table>

                <table class="cover-input2">
                    <tr>
                        <td>5. Thần kinh:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_thankinh" class="input2" /></td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td>5. Khác:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.nk_khac" class="input2" /></td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 2">
                    <!-- <span class="chucdanh">{{categorys_doctor[1].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.nk_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-m" *ngIf="healthRecord.type == 1">
            <div class="title">
                3. Mắt
            </div>
            <div class="body">
                <table class="cover-input2">
                    <tr>
                        <td>1. Kết quả khám thị lực</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width1">- Có kính: mắt phải:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.m_kinhmatphai" class="input1" /> /10</td>
                    </tr>
                    <tr>
                        <td><span style="width: 69px; display: inline-block;"></span> Mắt trái:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.m_kinhmattrai" class="input1" /> /10</td>
                    </tr>
                    <tr>
                        <td>- Không kính: mắt phải:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.m_kkinhmatphai" class="input1" /> /10</td>
                    </tr>
                    <tr>
                        <td><span style="width: 98px; display: inline-block;"></span> Mắt trái:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.m_kkinhmattrai" class="input1" /> /10</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width-khac">2. Khác:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.m_khac" class="input2" /></td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 3">
                    <!-- <span class="chucdanh">{{categorys_doctor[2].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.m_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-tmh" *ngIf="healthRecord.type == 1">
            <div class="title">
                4. Tai Mũi Họng
            </div>
            <div class="body">
                <table class="cover-input2">
                    <tr>
                        <td>1. Kết quả khám thính lực</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width1">- Trái: Nói thường:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.tmh_trainoithuong" class="input1" /> m</td>
                    </tr>
                    <tr>
                        <td> <span style="width: 44px; display: inline-block;"></span> Nói thầm:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.tmh_trainoitham" class="input1" /> m</td>
                    </tr>
                    <tr>
                        <td>- Phải: Nói thường:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.tmh_phainoithuong" class="input1" /> m</td>
                    </tr>
                    <tr>
                        <td> <span style="width: 45px; display: inline-block;"></span> Nói thầm:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.tmh_phainoitham" class="input1" /> m</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width-khac">2. Khác:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.tmh_khac" class="input2" /></td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 4">
                    <!-- <span class="chucdanh">{{categorys_doctor[3].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.tmh_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-rhm" *ngIf="healthRecord.type == 1">
            <div class="title">
                5. Răng Hàm Mặt
            </div>
            <div class="body">
                <table class="cover-input2">
                    <tr>
                        <td>1. Kết quả khám</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="">- Hàm trên:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.rhm_hamtren" class="input2" /> </td>
                    </tr>
                    <tr>
                        <td>- Hàm dưới:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.rhm_hamduoi" class="input2" /> </td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width-khac">2. Khác:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.rhm_khac" class="input2" /></td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 5">
                    <!-- <span class="chucdanh">{{categorys_doctor[4].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.rhm_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-cxk" *ngIf="healthRecord.type == 1">
            <div class="title">
                6. Cơ Xương Khớp
            </div>
            <div class="body">
                <table class="cover-input2">
                    <tr>
                        <td>1. Kết quả khám</td>
                    </tr>
                </table>
                <table class="cover-input2">
                    <tr>
                        <td style="width: 163px;">- Bình thường:</td>
                        <td>
                            <label class="checkbox checkbox-primary">
                                <input type="radio" name="cxk" value="bt" [(ngModel)]="healthRecord.cxk_isbt"  (click)="toggle_cxk('cxk_bt',$event,healthRecord.cxk_isbt)">
                                <span class="input-span"></span>
                            </label>
                            <input type="radio" name="cxk" value="" [(ngModel)]="healthRecord.cxk_isbt" style="visibility: hidden;">
                        </td>
                    </tr>
                    <tr>
                        <td class="">- Cong cột sống:</td>
                        <td>
                            <!-- <input type="checkbox" [(ngModel)]="healthRecord.cxk_ketqua" class="input2" /> -->
                            <label class="checkbox checkbox-primary" style="width: 80px;">
                                Gù
                                <input type="radio" name="cxk_isccs"  value="g" [(ngModel)]="healthRecord.cxk_isccs"  (click)="toggle_cxk('cxk_ccs',$event,healthRecord.cxk_isccs)" >
                                <span class="input-span"></span></label>
                            <label class="checkbox checkbox-primary">
                                Ưỡn
                                <input type="radio" name="cxk_isccs"  value="u" [(ngModel)]="healthRecord.cxk_isccs"  (click)="toggle_cxk('cxk_ccs',$event,healthRecord.cxk_isccs)" >
                                <span class="input-span"></span></label>

                            <input type="radio" name="cxk_isccs" value="" [(ngModel)]="healthRecord.cxk_isccs" style="visibility: hidden;">
                        </td>
                    </tr>
                    <tr>
                        <td class="">- Vẹo cột sống hình:</td>
                        <td>

                            <label class="checkbox checkbox-primary" style="width: 80px;">

                                <input type="radio" name="cxk_isvcs"  value="s" [(ngModel)]="healthRecord.cxk_isvcs"  (click)="toggle_cxk('cxk_vcs',$event,healthRecord.cxk_isvcs)" >
                                <span class="input-span"></span>
                                Chữ S</label>
                            <label class="checkbox checkbox-primary">

                                <input type="radio" name="cxk_isvcs"  value="c" [(ngModel)]="healthRecord.cxk_isvcs"   (click)="toggle_cxk('cxk_vcs',$event,healthRecord.cxk_isvcs)" >
                                <span class="input-span"></span>
                                Chữ C</label>

                            <input type="radio" name="cxk_isvcs" value="" [(ngModel)]="healthRecord.cxk_isvcs" style="visibility: hidden;">
                        </td>
                    </tr>

                </table>
                <table class="cover-input2">
                    <tr>
                        <td class="width-khac">2. Khác:</td>
                        <td><input type="text" [(ngModel)]="healthRecord.cxk_khac" class="input2" /></td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 6">
                    <!-- <span class="chucdanh">{{categorys_doctor[5].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.cxk_staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-ketluan" *ngIf="healthRecord.type == 1">
            <div class="title">
                Kết luận
            </div>
            <div class="body">
                <input type="text" [(ngModel)]="healthRecord.conclude" class="input2" />
            </div>
            <div class="footer">
                <div *ngIf="categorys_doctor.length >= 6">
                    <!-- <span class="chucdanh">{{categorys_doctor[6].subject_type}}</span> -->
                    <img style="width: 20px;" src="assets\img\icons\check.png" alt="">
                    <span class="bacsi">{{healthRecord.staff}}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-dinhkem">
            <div class="filettach-upload mb-3">
                File đính kèm
                <input type="file" #myInput accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
            </div>
        </div>

    </div>
</div>



<div class="modal fade" id="import-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập kết quả khám từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>