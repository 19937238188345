import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { FinanceService } from 'src/app/_services/finance.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { FinancePrintPreviewComponent } from '../finance-print-preview/finance-print-preview.component';

@Component({
  selector: 'app-finance-print-invoice',
  templateUrl: './finance-print-invoice.component.html',
  styleUrls: ['./finance-print-invoice.component.css']
})
export class FinancePrintInvoiceComponent implements OnInit {
  dataList: any = [];
  title = "";
  filter = {
    student_code: this.data.code,
    type: environment.cateType_finance
  }
  sorter = {
    sortStr: 'checkin_start ASC',
  }
  temp_data_total = 0;
  temp_student_count = 0;
  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public financeService: FinanceService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FinancePrintInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    this.title = data.title;
    this.loadDetail();
  }

  ngOnInit(): void {

  }
  loadDetail() {
    this.isLoading = true;
    var data = this.data;
      this.financeService.getByStudentsAndCateIds(data).subscribe(
      async response => {
        this.isLoading = false;
        this.dataList = response;
        this.updateTotal();
      },
      error => {
        this.isLoading = false;
      });
  }
  checkboxAll(evt) {
    this.dataList.forEach(element => {
      element.temp_checked = evt.target.checked;
    });
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  onChange(value){
      this.updateTotal();
  }
  updateTotal(){
    this.temp_data_total = 0;
    this.temp_student_count = 0;
    this.dataList.forEach(element => {
      if (element.temp_checked){
        this.temp_data_total +=  element.finance_value;
        this.temp_student_count++;
      }
    });
  }
  click_select(){
    console.log(this.dataList);


  }
  doPayment(data){
      console.log("doPayment.........",data);
    this.isLoading = true;
    this.financeService.saveUpdate(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Cập nhật thành công");
          // tắt popup
          this.dialogRef.close(response);
        }
      },
      error => {
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      });
  }
  printMulti(){
    const dialogRef = this.dialog.open(FinancePrintPreviewComponent, {
      data: this.dataList
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        
      }
    });
  }
}
