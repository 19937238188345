import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assignment-class-picker',
  templateUrl: './assignment-class-picker.component.html',
  styleUrls: ['./assignment-class-picker.component.css']
})
export class AssignmentClassPickerComponent implements OnInit {
  
  dataList: any = []; // danh sách lớp
  indexSelected = -1;
  filter = {
    name: "",
    khoi: "",
    deleted: 0,
    type: environment.cateType_class
  }
  sorter = {
    sortStr: this.appService.sort_category[4].code, // theo od
  }
  //for paging
  viewNumsObj = this.appService.viewNums[2].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<AssignmentClassPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    this.loadClass();
  }

  ngOnInit(): void {

  }

  


  loadClass() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          //thêm selected_temp để cho action
          response.data.forEach(element => {
            element.checkin_weeks_name =  this.utility.getWeekNameByWeekCode(element.checkin_weeks);
            // nếu được chọn là 1 giáo viên thì hiển thị khung giờ của giáo viên
            if(this.data.length == 1){
                if(this.data[0]['assignment_class_codes'].includes(element.code.toString())){
                  element.selected_temp = true;
                }else
                element.selected_temp = false;
            }else
            element.selected_temp = false;
          });

          this.dataList = response.data;
        }
      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }

  checkboxAll(evt) {

    this.dataList.forEach(element => {
      element.selected_temp = evt.target.checked;
    });

  }


  saveChangeClass() {

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {

    var obj_codes = [];
    this.dataList.forEach(element => {
      if (element.selected_temp) {
        obj_codes.push(element.code);
      }
    });

    if (obj_codes.length == 0) {
      this.toastr.warning("", "Bạn chưa chọn lớp");
      return;
    }

    let result = {
      // class_code: this.dataList[this.indexSelected].code,
      code: 'ok',
      data: obj_codes
    }
    this.dialogRef.close(result);


    // let data = {
    //   type: environment.cateType_assignment_class_subject,
    //   obj_codes: obj_codes,
    //   obj_code2s: this.data
    // }
    // console.log(data);
    // this.categoryeduService.saveMultiCategorys(data).subscribe(
    //   async response => {
    //     console.log(response);
    //     if (response.code == "ok" && response.affectedRows > 0) {
    //       let result = {
    //         // class_code: this.dataList[this.indexSelected].code,
    //         code: 'ok',
    //       }
    //       this.dialogRef.close(result);
    //     }
    //   },
    //   error => {
    //     console.error("error:");
    //     console.error(error);
    //   });


  }

}
