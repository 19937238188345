import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { HolidayPickerComponent } from 'src/app/components/holiday-picker/holiday-picker.component';
import { environment } from 'src/environments/environment';
import { TeacherService } from 'src/app/_services/teacher.service';
import { TeacherPickerMultiComponent } from '../teacher/teacher-picker-multi/teacher-picker-multi.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
declare var $: any;
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;
  @ViewChild('resultForm') resultForm: HTMLFormElement;




  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  fileAttach: File = null;
  fileAttachUrl: any;
  // imageUrl: any = environment.product_avatar;

  teachers = [];

  categorys = [];
  categorys_backup: any;

  cateType = environment.cateType_task;
  cateTypeResult = environment.cateType_task_result; // dành cho result
  // for add
  cate = new Category(this.cateType);
  // for update
  categoryselected: any;
  tResult = {
    code : '',
    teacher_code: '',
    teacher_name: '',
    status : -1
  };
  rd_khoi = '';
  rd_fileAttach: File = null;
  rd_fileAttachUrl : any;

  fileAttach_u: File = null;

  isDeleted: boolean;
  temp_weeks_update = []; //clone
  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: 'created_date DESC',
  }

  filter = {
    name: "",
    teacher_code: '',
    deleted: 0,
    status: -1,
    type: this.cateType
  }
  songay = 0;


  currentFruit = "";

  constructor(
    public categoryeduService: CategoryEduService,
    public teacherService: TeacherService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Danh sách giao việc";
    this.cate.hockyI_startTime = new Date();
    this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.loadTeacher();

  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }

  loadTeacher() {
    let filter = {
      name: "",
    }
    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let sorter = {
      sortStr: '',
    }
    // this.loadTotalPage();
    if (true) {
      this.teacherService.loadTeacher(filter, sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.teachers = response.data;
          }
        },
        error => {

        })
    }
  }
  status_change(status) {
    this.filter.status = status;
    this.loadCategory();
  }
  openSelectTeacherMore(item, index) {
    // check quyền
    if(item.user_code != this.appService.currentUser.code){
      this.toastr.warning("","Bạn không có quyền thao tác với nội dung này");
      return;
    }

    let data = [];
    data.push({
      code: item.code,
      teacher_codes: item.teachers.map(item => item.code), // danh sách giáo viên để chọn.
      chunhiem_code: '' // giáo viên chu nhiệm chính.
    });

    console.log(data);

    const dialogRef = this.dialog.open(TeacherPickerMultiComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: { title: "Chưa dùng", data: data, classSelected: item, type_select: 'multi' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (result != undefined && result.length > 0) {
        console.log("result: ", result);
        console.log("classSelected: ", item);
        // load lại dữ liệu
        let param = {
          type: environment.cateType_task,
          obj_codes: data, // Lich học:  mảng json : code
          obj_code2s: result // mảng teacher
        }
        this.categoryeduService.saveMultiCategorys(param).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("", "Gán giáo viên thành công");
              $("#update-category-modal").modal('hide');
              this.loadCategory();
            } else {
              this.toastr.warning("", "Có lỗi xảy ra");
            }
          },
          error => {
            console.error("error:");
            console.error(error);
          });
      }
    });
  }
  teacherChange(event) {
    console.log(event);
  }
  clearSearch() {
    this.filter.name = '';
    this.loadCategory();
  }
  clearSearch_add() {
    this.filter.name = '';
  }
  changeCheckbox(event) {
    console.log(event);
    console.log(this.appService.weeks);
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.cate.hockyI_startTime = new Date();
    this.songay = 0;
    this.teacherSelecteds = [];
    this.fileAttach = null;
    // this.imageUrl = environment.product_avatar;
  }
  category_change(event) {
    this.loadCategory();
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          this.categorys_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

          console.log(this.categorys);
        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng tên ");
      return false;
    }
    if (this.teacherSelecteds.length == 0) {
      this.toastr.warning("", "Vui lòng chọn người thực hiện");
      return false;
    }
    if (this.cate.hockyI_startTime == null || this.cate.hockyI_endTime == null) {
      this.toastr.warning("", "Vui lòng chọn thời gian thực hiện");
      return false;
    }

    if (this.cate.hockyI_startTime >= this.cate.hockyI_endTime) {
      this.toastr.warning("", "Ngày bắt đầu không được lớn hơn ngày kết thúc");
      return false;
    }


    this.cate.name = this.cate.name.trim();
    this.cate.code = 'T' + Math.floor(Math.random() * 10000);

    if (this.fileAttach !== null) {
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.fileAttach);
    }

    this.cate.hockyI_startTime = this.utility.formatDateToStr(this.cate.hockyI_startTime);
    this.cate.hockyI_endTime = this.utility.formatDateToStr(this.cate.hockyI_endTime);

    // gửi tạm để thêm giáo viên
    this.cate['teacherList'] = this.teacherSelecteds;

    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.uploadAvatar(this.fileAttach, this.cateType, this.cate.code);
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }
  updateCategory() {
    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.categoryselected.hockyI_startTime == null || this.categoryselected.hockyI_endTime == null) {
      this.toastr.warning("", "Vui lòng chọn thời gian thực hiện");
      return false;
    }

    if (this.categoryselected.hockyI_startTime >= this.categoryselected.hockyI_endTime) {
      this.toastr.warning("", "Ngày bắt đầu không được lớn hơn ngày kết thúc");
      return false;
    }

    if (this.fileAttach_u !== null) {
      this.categoryselected.image_url = this.cateType + "/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.fileAttach_u);
      console.log("1111");
    }

    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    console.log(this.categoryselected);

    if (this.utility.isValidDate(this.categoryselected.hockyI_startTime)) {
      this.categoryselected.hockyI_startTime = this.utility.formatDateToStr(this.categoryselected.hockyI_startTime);
    } else {
      this.categoryselected.hockyI_startTime = "";
    }

    if (this.utility.isValidDate(this.categoryselected.hockyI_endTime)) {
      this.categoryselected.hockyI_endTime = this.utility.formatDateToStr(this.categoryselected.hockyI_endTime);
    } else {
      this.categoryselected.hockyI_endTime = "";
    }




    this.isLoading = true;
    var data = {
      id: this.categoryselected.id,
      name: this.categoryselected.name,
      khoi: this.categoryselected.khoi,
      hockyI_startTime: this.categoryselected.hockyI_startTime,
      hockyI_endTime: this.categoryselected.hockyI_endTime,
      deleted: this.categoryselected.deleted,
    }
    if (this.fileAttach_u !== null) { // cập nhật file url
      data["image_url"] = this.categoryselected.image_url;
    }

    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật thành công");
          if (this.fileAttach_u !== null) { // upload file
            this.uploadAvatar(this.fileAttach_u, this.cateType, this.categoryselected.code);
          }
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });
  }

  popupResult(item, tResult) { // item là công việc, tResult là kết quả
    console.log(tResult);
    // check quyền
    if(item.user_code == this.appService.currentUser.code){
        // Nếu chủ công việc và công việc đã nộp thì cho phép tiếp tục
        if( tResult.status == 1){
          // Nếu chủ công việc và công việc đã nộp thì cho phép tiếp tục
        }else{
          this.toastr.warning("Chưa có kết quả","Không có quyền xem");
          return;
        }
    }
    else if(tResult.teacher_code != this.appService.currentUser.code){
      this.toastr.warning("Vui lòng chọn đúng người để thao tác","");
      return;
    }

    // load dữ liệu result
    if(tResult.code != ''){ // đã nhận việc rồi thì load dữ liệu
        let filter = {
          code_parent : item.code,
          type: this.cateTypeResult,
        }
        this.rd_khoi = '';
        this.rd_fileAttachUrl = '';
        this.categoryeduService.getCategoryByCode(tResult.code, filter ).subscribe(
          async response => {
            if (response) {
              this.rd_khoi = response.khoi;
              this.rd_fileAttachUrl = response.file_url;
            }
          },
          error => {
            console.error(error);
          })
    }
    this.tResult = tResult;
    this.categoryselected = item;
    $("#result-category-modal").modal('show');
  }
  updateResult(status) {
   
    console.log("updateResult");
 
    if (this.rd_khoi == null || this.rd_khoi == '') {
      this.toastr.warning("", "Nhập nội dung kết quả thực hiện");
      return false;
    }

    if (this.rd_fileAttach !== null) {
      this.rd_fileAttachUrl = this.cateTypeResult + "/" + this.tResult.code + "." + this.utility.getExtensionFile(this.rd_fileAttach);
      console.log("1111");
    }

    this.isLoading = true;
    var data = {
      khoi: this.rd_khoi,
      status: status,
    }
    if (this.rd_fileAttach !== null) { // cập nhật file url
      data["image_url"] =  this.rd_fileAttachUrl;
    }

    this.categoryeduService.updateByCode(this.tResult.code, data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#result-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật thành công");
          if (this.rd_fileAttach !== null) { // upload file
            this.uploadAvatar(this.rd_fileAttach, this.cateTypeResult, this.tResult.code);
          }
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });
  }
  createResult(item) {


    this.cate.code = 'TR' + Math.floor(Math.random() * 10000);
    let cate ={
      code_parent : item.code,
      code: 'TR' + Math.floor(Math.random() * 10000),
      type: this.cateTypeResult
    }
    
    this.isLoading = true;
    this.categoryeduService.insertCategory(cate).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.toastr.success("", "Nhận việc thành công");
          $("#result-category-modal").modal('hide');
          this.loadCategory();
        }
        else {
          this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      });
  }
  uploadAvatar(fileUpload: File, des: string, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  onDateSelect($event) {
    this.songay = this.utility.getDayDiff(this.cate.hockyI_startTime, this.cate.hockyI_endTime);
  }
  countAllCategory() {
    this.categoryeduService.countAllCategory(this.cateType).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  popupStatus(item, status, title) {
    // check quyền
    if(item.user_code != this.appService.currentUser.code){
      this.toastr.warning("","Bạn không có quyền thao tác với nội dung này");
      return;
    }
    // check nghiệm vụ
    if(status == 0){
      if(item.status == 0){// đã 0 rồi thì thôi
        return;
      }else if(item.status != 1){
        this.toastr.warning("","Chuyển trạng thái không hợp lệ");
        return;
      }
    }else if(status == 1){
      if(item.status == 1){// đã 1 rồi thì thôi
        return;
      }else if(item.status != 0){
        this.toastr.warning("","Chuyển trạng thái không hợp lệ");
        return;
      }
    }else if(status == 3){
      if(item.status == 4){// đã 1 rồi thì thôi
        return;
      }else if(item.status != 2){
        this.toastr.warning("","Chuyển trạng thái không hợp lệ");
        return;
      }
    }

    // popup confirm
    let tempdata = {
      title: 'Cập nhật trạng thái. ?',
      msg: 'Công việc "' + item.name + '" chuyển trạng thái "' + title + '"',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        var data = {
          id: item.id,
          status: status
        }
        this.categoryeduService.updateByID(data).subscribe(
          async response => {
            this.isLoading = false;
            console.log(response);
            if (response.code == "ok") {
              this.loadCategory();
              $("#result-category-modal").modal('hide');
              this.toastr.success("", "Cập nhật thành công");
            }
          },
          error => {
            this.isLoading = false;
            console.error(error);
          });
      }
    });
  }
  popupUpdate(item) {
    // check quyền
    if(item.user_code != this.appService.currentUser.code){
      this.toastr.warning("","Bạn không có quyền thao tác với nội dung này");
      return;
    }

    this.categoryselected = item;
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;
    $("#update-category-modal").modal('show');
  }
  updateTask(status){
    this.popupStatus(this.categoryselected, status, 'Hoàn thành');
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {
    // kiểm tra quyền
    if(!this.appService.checkFullPermission()){
      this.toastr.warning("","Bạn không có quyền thao tác");
      return;
    }
    this.resetCategory();
    this.countAllCategory();
    $("#add-category-modal").modal('show');

  }



  openHolidayPicker(item) {
    if (item != null) {
      this.categoryselected = item;
    }
    let data = [];
    data.push({
      code: this.categoryselected.code,
      holiday_ids: this.categoryselected.holiday_ids
    });

    console.log(data);

    // mở popup
    const dialogRef = this.dialog.open(HolidayPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: { title: "", data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.length > 0) {
        console.log("class_code", result);

        let param = {
          type: environment.cateType_checkin_time_holiday,
          obj_codes: data,
          obj_id2s: result
        }
        this.categoryeduService.saveMultiCategorys(param).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("", "Cài đặt ngày nghỉ thành công");
              $("#update-category-modal").modal('hide');
              this.loadCategory();
            } else {
              this.toastr.warning("", "Có lỗi xảy ra");
            }
          },
          error => {
            console.error("error:");
            console.error(error);
          })



      }
    });
  }


  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
    var reader = new FileReader();
    reader.readAsDataURL(this.fileAttach);
    reader.onload = (_event) => {
      this.fileAttachUrl = reader.result;
      console.log("this.fileAttachUrl:: ", this.fileAttachUrl);
    }

  }
  async u_filePickerListener($event): Promise<void> {
    this.fileAttach_u = $event.target.files[0];
    if (this.fileAttach_u == null) {
      return;
    }
    console.log('originalFile u_fileAttach Blob', this.fileAttach_u instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach_u.size / 1024} KB`);
    var reader = new FileReader();
    reader.readAsDataURL(this.fileAttach_u);
    reader.onload = (_event) => {
      this.fileAttachUrl = reader.result;
      console.log("this.fileAttachUrl:: ", this.fileAttachUrl);
    }
  }
  async rd_filePickerListener($event): Promise<void> {
    this.rd_fileAttach = $event.target.files[0];
    if (this.rd_fileAttach == null) {
      return;
    }
    console.log('originalFile u_fileAttach Blob', this.rd_fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.rd_fileAttach.size / 1024} KB`);
    var reader = new FileReader();
    reader.readAsDataURL(this.rd_fileAttach);
    reader.onload = (_event) => {
      this.rd_fileAttachUrl = reader.result;
      console.log("this.fileAttachUrl:: ", this.rd_fileAttachUrl);
    }
  }


  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  teacherSelecteds = [];
  onClick_selectTeacher(event) {
    const exists = this.teacherSelecteds.some(element => element.code === event.code);

    if (!exists) {
      this.teacherSelecteds.push(event);
    } else {

    }

  }
  deleteTeacher(i) {
    this.teacherSelecteds.splice(i, 1);
  }

  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }

  /////////////////
  openSelectTeacher(classSelected, index) {
    // this.appService.deskIndex = index;
    // this.modalService.open(content, { size: 'xl' });

    console.log("this.appService.deskIndex", this.appService.deskList[this.appService.deskIndex]);
    this.appService.deskIndex = index;
    const dialogRef = this.dialog.open(TeacherPickerComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: { deskIndex: index, classSelected: classSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
        // load lại dữ liệu
        this.loadCategory();
      }
    });
  }

}
