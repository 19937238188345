import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.css']
})
export class CategorylistComponent implements OnInit {
  @Input("datas") categories: any[] = [];
  @Input("selected") selected: string = "";
  @Output("click") clickEvent = new EventEmitter<any>();
  

  filter = {
    code: "",
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }
  
  constructor(
    public categoryService: CategoryService,
  ) { 

  }

  ngOnInit(): void {
      
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      this.filter.category_code = this.selected;
      console.log("selected",this.selected);
    }
  }


  select_category(code) {
    console.log(this.filter.category_code);
    if(this.filter.category_code != code){
      this.filter.category_code = code;
    }else{
      if(this.filter.category_code == ""){
        this.filter.category_code = code;
      }else{
        this.filter.category_code = "";
      }
    }
    
    this.clickEvent.emit(this.filter);
  }


 

}
