<div class="page-content fade-in-up">
    <div class="row m-2 justify-content-between ">
        <div class="col p-0">
            <div class="d-flex justify-content-start align-items-center ">


                <!-- <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm học sinh</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên học sinh" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field> -->

                <!-- 
                <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Khối</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.currentUser.khois" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                        <mat-option value="" *ngIf="appService.checkFullPermission()">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                            {{item.name}} - ({{item.student_count}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- <div class="col-4 p-0 d-flex justify-content-end align-items-center ">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon " (click)="popup_compose() ">
                <span class="btn-label "><i class="ti ti ti-plus "></i></span>Thêm mới
            </button>
        </div> -->
    </div>
    <div class="row justify-content-center ">

        <div class="col ">
            <div class=" ">
                <table class="table table-head-purple table-hover " id="datatable ">
                    <thead class="thead-default thead-lg ">
                        <tr>
                            <th style="width: 50px; text-align: center; ">
                                <div class="my-stt "></div>
                            </th>
                            <!-- <th>Tiêu đề</th> -->
                            <th>Đơn nghỉ</th>
                            <th>Người nghỉ</th>
                            <th>Lớp</th>
                            <th class="text-center">Số ngày</th>
                            <th class="text-center">Thời gian</th>
                            <th style="min-width: 140px;">Người gửi</th>
                            <th class="text-center">Trạng thái</th>
                            <!-- <th class="text-center"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.messengers; let i=index ">
                            <td class="stt_td " style=" text-align: center; padding: 0.5rem; ">
                                <span class="stt_span "><span class="badge badge-default badge-circle ">{{i+1}}</span> </span>
                                <img class="img-circle" src="./assets/img/icons/contract.png" alt="image" width="40" />
                                <!-- <a class="avatar-img-list " style="width: 35px; height: 35px; " href="javascript:; " [style.background-image]="'./assets/img/icons/discussion.png'"></a> -->
                            </td>
                            <!-- <td class=" ">

                            </td> -->
                            <td>
                                <div class="font-weight-bold">{{item.title}}</div>
                                <div>{{item.content}}</div>
                            </td>
                            <td>
                                <div>{{item.full_name}} </div>
                            </td>
                            <td>
                                <div>{{item.class_code}}</div>
                            </td>

                            <td class="text-center">
                                {{item.date_total}}
                            </td>
                            <!-- <td>{{item.recipient}}</td> -->

                            <td class="text-center">
                                <div>
                                    {{item.date_list}}
                                </div>
                            </td>
                            <td>
                                <div>{{item.parent_name}}</div>
                                <div>{{item.parent_phone}}</div>
                            </td>
                            <td class="text-center">
                                <button *ngIf="item.status == 0" class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupDetail(item)'>
                                    <span class="material-icons"> pending_actions </span>
                                </button>
                                <button *ngIf="item.status == 1" class="btn-adjust btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" (click)='popupDetail(item)'>
                                    <span class="material-icons"> done </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row align-items-center mb-2 ">
                <div class="col-4 ">
                    <span class=" ">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                            {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
                </div>
                <div class="col-8 d-flex justify-content-end ">
                    <ul class="pagination p-1 " style="margin-bottom: 0px; ">
                        <li>
                            <select class="form-control-sm " name="viewNums " id="viewNums" [(ngModel)]="viewNumsObj " (change)="viewNumsObjChange() ">
                                    <option *ngFor="let item of appService.viewNums " [ngValue]="item.Code ">{{item.Value}}
                                    </option>
                                </select>
                        </li>
                        <li class="page-item ">
                            <a class="page-link page-link-solid " (click)="pageChange( 'prev') " aria-label="Previous ">
                                <span aria-hidden="true ">
                                        <i class="ti ti-angle-left "></i>
                                    </span>
                            </a>
                        </li>
                        <li *ngFor="let item of listPage " class="page-item active " (click)="pageChange(item.i) " [ngClass]="{ 'active' : item.i==curentPage} ">
                            <a class="page-link " href="javascript:; ">{{item.i}}</a>
                        </li>
                        <li class="page-item ">
                            <a class="page-link page-link-solid " (click)="pageChange( 'next') " aria-label="Next ">
                                <i class="ti ti-angle-right "></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>