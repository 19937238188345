<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <img src="assets\img\icons\medical-report.png" alt="Avatar" style="height: 55px; border-radius: 50%;">
                <div class="text-right">
                    <h3 class="mb-1">{{data.class.teacher_name}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.class.class_code}}</h2>
                    <div class="">Lớp</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.class.student_count}}</h2>
                    <div class="">Sỹ số</div>
                </div>
                <div class="text-center">
                    <h2 class="mb-1">{{data.class.health_inputted}}</h2>
                    <div class="">Đã nhập</div>
                </div>

                <div class="text-center">
                    <button class="btn btn-warning" (click)="this.dialogRef.close();">
                        <span class="material-icons font-26">
                        close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 45px; text-align: center;">#</th>
                <th>Họ tên</th>
                <th>Tên</th>
                <!-- <th>Ngày sinh</th> -->
                <!-- <th class="text-right"></th> -->
                <th>Phụ huynh</th>
                <th class="text-center">Đính kèm</th>
                <th class="text-center">Đã nhập</th>
                <th class="text-center">Đã gửi</th>
                <!-- <th>Có phép</th> -->

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.students; let i = index" [ngClass]="{'bg-primary-100': item.checkin_times != '' }">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">


                    <a class="avatar-img-list" style="width: 30px;  height: 30px; position: relative;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)">
                        <span class="stt_span"> <span class="badge badge-default badge-circle">{{i+1}}</span></span>
                    </a>
                </td>
                <td>
                    <a class="d-flex justify-content-start align-items-center" (click)="open_student_detail(item)">
                        <div>
                            <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                        </div>
                        <div>
                            <div style="font-size: 18px;">
                                {{item.full_name}}
                            </div>
                            <div class="text-muted">
                                {{item.code}}
                            </div>
                        </div>
                    </a>

                </td>
                <td>
                    <div style="font-size: 18px;">{{item.name}}</div>
                </td>
                <td>
                    <div>{{item.parents_name}}</div>
                    <div>{{item.parents_phone}}</div>
                </td>

                <td class="text-center">
                    <span *ngIf="item.health_attached_file != ''" class="material-icons text-black-50"> attach_file </span>
                </td>
                <td class="text-center">
                    <img *ngIf="item.health_status >= 0" style="width: 25px;" src="assets\img\icons\check-list-mark.png" alt="">
                </td>
                <td class="text-center">
                    <img *ngIf="item.health_status == 1" style="width: 25px;" src="assets\img\icons\check-list-send.png" alt="">
                </td>


            </tr>
        </tbody>
    </table>
</div>