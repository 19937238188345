


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MealService } from 'src/app/_services/meal.service';

@Component({
  selector: 'app-meal-qrcode-preview',
  templateUrl: './meal-qrcode-preview.component.html',
  styleUrls: ['./meal-qrcode-preview.component.css']
})
export class MealQrcodePreviewComponent implements OnInit {
  isLoading = false;
  paymentMethod = {
    content: "",
  }
  constructor(    
    public mealService: MealService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MealQrcodePreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
        //data có định dạng như sau:
        // data: {
        //   'student' : ,
        //   'finance' : 
        // }
    }

  ngOnInit(): void {
    this.loadData();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  loadData(){
    let data = {
      date_month : this.data.period_time_str,
      tamthu_total_amount : this.data.tamthu_total_amount,
      id : this.data.payment_id,
      student_code : this.data.code,
    }
    this.isLoading = true;
    this.mealService.loadQRCode(data).subscribe(
      async response => {
        this.isLoading = false;
        this.paymentMethod = response;
      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }
}
