
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinStudentDetailComponent } from 'src/app/components/checkin-sutdent-detail/checkin-student-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { StudentService } from 'src/app/_services/student.service';
import { HealthStudentDetailComponent } from 'src/app/components/health-student-detail/health-student-detail.component';
import { ToastrService } from 'ngx-toastr';
import { HealthService } from 'src/app/_services/health.service';

@Component({
  selector: 'app-health-input-class',
  templateUrl: './health-input-class.component.html',
  styleUrls: ['./health-input-class.component.css']
})
export class HealthInputClassComponent implements OnInit {
  students = [];
  attention_count = 0;

  isLoading  = false;
  constructor(
    public appService: AppService,
    private studentService: StudentService,
    private healthService: HealthService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<HealthInputClassComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportHealthClassDetail();
  }

  ngOnInit(): void {

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode == 44 || charCode == 46 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;

  }

  inputField: HTMLInputElement;// cho việc input
  focusFunction(id) {
    this.inputField = document.querySelector('input[id=' + id + ']');
    this.inputField.select();
  }
  focusoutFunction(item){
    console.log(item.tl_cannang);
    if(item.tl_cannang != null && typeof item.tl_cannang === "string")
    item.tl_cannang = item.tl_cannang.replace(",",".");
    if(item.tl_chieucao != null &&  typeof item.tl_chieucao === "string")
    item.tl_chieucao = item.tl_chieucao.replace(",",".");
    console.log(item.tl_cannang);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
    console.log(event.keyCode);
    var temp ; // ví dụ 'tl_cannang-1'
    if (this.inputField === undefined) {
      return;
    }

    if (event.key === 'không có gì cả') {
  
    } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
      temp = this.inputField.id.split("-"); // ví dụ 'tl_cannang-1'
      if (parseInt(temp[1]) < this.students.length - 1) { // kiểm tra khi xuống dưới
        var nextCellText = temp[0]+ "-" + (parseInt(temp[1]) + 1);
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }

    } else if (event.key === 'ArrowUp') {
      temp = this.inputField.id.split("-");
      if (parseInt(temp[1]) > 0) {
        var nextCellText = temp[0]+ "-" + (parseInt(temp[1]) - 1);
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }
    } else if (event.key === 'ArrowRight') {
      temp = this.inputField.id.split("-");

      if (temp[0] == 'tl_cannang') {  
        var nextCellText = "tl_chieucao-" + temp[1];
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }
     

    } else if (event.key === 'ArrowLeft') {
      temp = this.inputField.id.split("-");
      if (temp[0] == 'tl_chieucao') {  
        var nextCellText = "tl_cannang-" + temp[1];
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }
    }
  }
  

  saveHealthClassDetail() {

    this.isLoading = true;
    let data = [];
    this.students.forEach(element => {
      // nếu có dữ liệu thì cập nhật hoặc thêm mới
      if(element.tl_cannang != '' || element.tl_chieucao != ''){
        if(element.health_id == 0 ){// chưa có nhập health
          // element.period_time = this.data.dateSelected;
        }else{
  
        }
        data.push(element);
      }

    });
    console.log(data);
    let param = {
      data: data,
      period_time: this.data.dateSelected
    }
    this.healthService.saveHealthStudents(param).subscribe(
      response => {
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          //đóng popup
          this.toastr.success("Lưu kết quả thành công","");
          this.dialogRef.close({code : "ok"});
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "Có lỗi xảy ra");
        console.error(error);
      });
  }


  loadReportHealthClassDetail() {

    let filter = {
      class_code: this.data.class_code,
      period_time: this.data.dateSelected,
    };
    let sorter = {
      sortStr: "od ASC",
    }
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1
    };
    this.studentService.loadStudentHealth(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          console.log(response);
          response.data.forEach(element => {
            element.parents_name = element.mother_name != '' ? element.mother_name : element.father_name;
            element.parents_phone = element.mother_phone != '' ? element.mother_phone : element.father_phone;
          })

          this.students = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  open_student_detail(item){
    // const dialogRef = this.dialog.open(HealthStudentDetailComponent, {
    //   position: {
    //     // top: '0px',
    //     // left: '0px'
    //   },
    //   // disableClose : true,
    //   width: '90%',
    //   height: '90%',
    //   panelClass: 'full-screen-modal',
    //   data: { data: item, dateSelected: this.data.dateSelected }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (typeof result != "undefined" && result.code == "ok") {
    //     console.log("result", result);

    //   }
    // });
  }
}
