import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
declare var $: any;
@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;
  
  categorys : any;
  categorys_backup: any
  
  scheduleDetailList : any;

  cateType = environment.cateType_schedules;
  // for add
  cate = new Category(this.cateType);
  // for update
  categoryselected: any;
  isDeleted: boolean;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search

  //for checkbox
  checkboxs = [];
  checkboxsSelectedIndex = 0;

  sorter = {
    sortStr: this.appService.sort_category[0].code,
  }
  
  filter = {
    name: "",
    type: this.cateType
  }



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
  ) {
    this.appService.page_title = "Tổ bộ môn";
    this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {

  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar; 
  }
  selectIndex(i){
    console.log(i);
    this.checkboxsSelectedIndex = i;
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          this.categorys_backup = response.data;
          
          this.checkboxs = new Array(this.categorys.length);
          
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
        }
      },
      error => {
        console.error(error);
      })
  }
  loadScheduleDetail() {

    let data = {
      
    };

    this.categoryeduService.loadScheduleDetail(data).subscribe(
      async response => {
        if (response) {
          this.scheduleDetailList = response;
          
        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập mã");
      return false;
    }

    this.cate.code = 'TKB' + Math.floor(Math.random()*1000);
  

    if(this.file !== null){
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }
   
    
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }

  // countAllCategory() {
  //   this.categoryeduService.countAllCategory(this.cateType).subscribe(
  //     response => {
  //       this.cate.code = environment.TKB_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
  //       this.CodeTemp = environment.TKB_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
  //       // console.log("this.cate.code",this.cate.code);
  //     },
  //     error => {

  //       console.error(error);
  //     })

  // }

  popup_category_add() {
    this.resetCategory();
    $("#add-category-modal").modal('show');
  }
  popupUpdate(item) {
    this.categoryselected = item;
    this.isDeleted = this.categoryselected.deleted == 0 ? true : false;
    $("#update-category-modal").modal('show');
  }
  
  updateCategory() {

    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }

    this.categoryselected.deleted = this.isDeleted ? 0 : 1;
    console.log(this.categoryselected);

    this.isLoading = true;
    this.categoryeduService.update(this.categoryselected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code=="ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if(this.file == null){
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }


  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };



}
