<div class="page-content fade-in-up">
    <div class="d-flex justify-content-between align-items-center">
        <div class="btn-group">
            <div class="btn btn-outline-dark">Cấu hình: </div>
            <div class="btn" *ngFor="let item of typeSettings; let i = index" [ngClass]="{'btn-primary': item.code == this.typeSettingsSelected.code, 'btn-outline-primary':item.code != this.typeSettingsSelected.code}" (click)="selectTypeSetting(item)">{{item.name}}</div>
        </div>
        <div class="btn-group">
            <div class="btn" *ngFor="let item of appService.hockys; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.hockySelected, 'btn-outline-primary': item.code != this.appService.hockySelected}" (click)="hocky_change(item.code)">{{item.value}}</div>
        </div>
    </div>
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="">
            <button class="btn btn-md btn-secondary ml-0" (click)="khois_change(-1)" [ngClass]="{'btn-primary': -1 == this.khoisIndexSelected}">Toàn khối</button>
            <button class="btn btn-md btn-secondary" *ngFor="let item of appService.currentUser.khois; let i = index" (click)="khois_change(i)" [ngClass]="{'btn-primary': i == this.khoisIndexSelected}">{{item.value}}</button>
        </div>
        <div class=" mr-1">


            <button class="blurButton btn  btn-primary btn-labeled btn-labeled-left btn-icon " (click)="save()" [disabled]="isLoading">
                <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><span
                        class="material-icons font-18">done</span></span>Lưu cấu hình
            </button>
        </div>
    </div>




    <div *ngIf="this.khoisIndexSelected == -1" class="row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">TT</th>
                    <th>Môn/Khối</th>
                    <th *ngFor="let khoi of appService.currentUser.khois; let i = index">
                        <div *ngIf="this.typeSettingsSelected.code == 'lockMark'" class="btn btn-labeled btn-labeled-right" (click)="toggleKhoiIsLock(khoi.code)">
                            {{khoi.value}}
                            <span class="btn-label">
                            <span class="material-icons font-25">done</span></span>
                        </div>
                        <div *ngIf="this.typeSettingsSelected.code == 'tx_num'">
                            {{khoi.value}}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let subject of subjectss_clone; let i = index">
                    <td style="text-align: center;">
                        {{i+1}}
                    </td>
                    <td style="text-align: left;">
                        <div style="font-size: 18px;">{{subject.name}}</div>
                        <div style="font-size: small" class="text-muted">{{subject.subject_cap}}</div>

                    </td>
                    <td *ngFor="let khoi of appService.currentUser.khois; let j = index">
                        <select *ngIf="this.typeSettingsSelected.code == 'tx_num'" class="custom-select" [(ngModel)]="subjectss[i]['khoi'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code]" [name]="subjectss[i]['khoi'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code]"
                            style="width: 50px; font-size: 18px;">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>

                        <a *ngIf="this.typeSettingsSelected.code == 'lockMark'" (click)="toggleIsLock(subjectss[i],'lockMark'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code,subjectss[i]['lockMark'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code])">
                            <span *ngIf="subjectss[i]['lockMark'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code] == 0" class="material-icons font-25">lock_open</span>
                            <span *ngIf="subjectss[i]['lockMark'+'__'+this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all) +'__'+khoi.code+'__'+subject.code] == 1" class="material-icons font-25 text-warning">lock</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="this.khoisIndexSelected > -1" class="row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">TT</th>
                    <th>Môn</th>
                    <th>Số điểm thường xuyên</th>
                    <th>Số điểm giữa kỳ</th>
                    <th>Số điểm học kỳ</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of subjectss_khoi; let i = index">
                    <td style="text-align: center;">
                        {{i+1}}
                    </td>
                    <td style="text-align: left;">
                        <div style="font-size: 18px;">{{item.subjects_name}}</div>
                    </td>
                    <td>
                        <select *ngIf="this.typeSettingsSelected.code == 'tx_num'" class="custom-select" [(ngModel)]="item.tx_num" [name]="item.value" style="width: 50px; font-size: 18px;">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>

                        <a *ngIf="this.typeSettingsSelected.code == 'lockMark'" (click)="item.isLock = item.isLock == 1 ? 0:1">
                            <span *ngIf="item.isLock == 0" class="material-icons font-25">lock_open</span>
                            <span *ngIf="item.isLock == 1" class="material-icons font-25 text-warning">lock</span>
                        </a>

                    </td>
                    <td>
                        1
                    </td>
                    <td>
                        1
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>