import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class MealService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  
  saveUpdate(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/saveUpdate'), data,).map(data => { return data; });
  }
  getMonthList(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/getMonthList'), data,).map(data => { return data; });
  }
  loadMealStudentMonthDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/mealStudentMonthDetail'), data,).map(data => { return data; });
  }
  chotthangn1(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/chotthangn1'), data,).map(data => { return data; });
  }
  chottamthu(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/chottamthu'), data,).map(data => { return data; });
  }
  payment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/payment'), data,).map(data => { return data; });
  }
  updateMealStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/updateMealStudent'), data,).map(data => { return data; });
  }

  loadReportMealClassDetailMonth(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/report/mealClassDetailMonth'), data,).map(data => { return data; });
  }
  reportMealClassMonth(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/report/reportMealClassMonth'), data,).map(data => { return data; });
  }
  reportMealSchoolMonth(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/report/reportMealSchoolMonth'), data,).map(data => { return data; });
  }
  loadMonthListForSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('meal/loadMonthListForSchool'), data,).map(data => { return data; });
  }
  loadQRCode(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('school/loadqrcode'), data,).map(data => { return data; });
  }
  downloadMealReport(class_codes,selectedDateStr) {
    selectedDateStr = selectedDateStr.split("/").join("_");
    class_codes = class_codes == "" ? 'all' : class_codes;
    window.open(this.appService.getApiEndpoint('io/downloadMealReport/'+this.appService.currentUser.school_code+"/"+class_codes+"/"+selectedDateStr) );
  }
  downloadMealReportDetail(class_codes,selectedDateStr) {
    selectedDateStr = selectedDateStr.split("/").join("_");
    class_codes = class_codes == "" ? 'all' : class_codes;
    window.open(this.appService.getApiEndpoint('io/downloadMealReportDetail/'+this.appService.currentUser.school_code+"/"+class_codes+"/"+selectedDateStr) );
  }
}
