<div>
    <!-- <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="onSearch($event)" placeholder="Tìm kiếm..." /> -->

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Người nhận việc</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [(ngModel)]="searchTerm" (ngModelChange)="onSearch($event)" placeholder="Tìm kiếm nhân viên" name="search_add" maxlength="200">
        <a *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch_add()">
            <mat-icon>close</mat-icon>
        </a>
    </mat-form-field>



    <ul *ngIf="filteredOptions.length > 0">
        <li *ngFor="let option of filteredOptions" (click)="selectOption(option)">
            {{ option.name }}
        </li>
    </ul>

</div>