<div class="row mb-2 mt-2 justify-content-between">
    <div class="p-0 d-flex justify-content-start align-items-center">


        <mat-button-toggle-group [value]="type_report" #group="matButtonToggleGroup" (change)="onValChange(group.value)" appearance="" name="fontStyle" aria-label="Font Style">


            <mat-button-toggle value="day">
                <span class="material-icons mr-1"> event </span> Lịch ngày</mat-button-toggle>
            <mat-button-toggle [value]="appService.type_report_detail_week">
                <span class="material-icons mr-2"> event </span> Báo cáo tuần</mat-button-toggle>
            <mat-button-toggle [value]="appService.type_report_detail_month">
                <span class="material-icons mr-2"> calendar_month </span> Báo cáo tháng</mat-button-toggle>
        </mat-button-toggle-group>

    </div>

    <div class="p-0 d-flex justify-content-end align-items-center">

        <mat-form-field appearance="fill" style="width: 120px;" class="mr-3">
            <mat-label>Tổ</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 170px;" class="mr-3">
            <mat-label>Giáo viên</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.teacher_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>
<!-- 
<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill" *ngIf="type_report == appService.type_report_day">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
        </a>
    </li>

</ul> -->


<div>
    <div class="row mb-2">

        <div class="col-12 d-flex justify-content-between">
            <div>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-primary"> check_circle </span>
                    </span>
                    Đúng giờ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-warning"> report </span>
                    </span>
                    Muộn/trễ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-danger"> report </span>
                    </span>
                    Vắng
                </button>
            </div>

            <button (click)="confirmDownloadExcel()" class="btn btn-secondary btn-labeled btn-labeled-left btn-icon">
                <span class="btn-label"><img src="./assets/img/icons/excel.png" alt="image" width="20" /></span>
                Tải xuống
            </button>
        </div>
    </div>
    <div class="qbs_scroll" [style.width.px]="screenWidth - 240">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th class="headcol" style="text-align: center;">
                        Cán bộ giáo viên
                        <div style="font-size: small;">
                            Chi tiết điểm danh
                        </div>
                    </th>

                    <td class="headrow" *ngFor="let item of dateRange; let i = index">
                        <div class="text-center" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': (item.day == 0 || item.day == 6) }" style="min-width: 60px;">
                            <div style="font-size: small;">{{item.dayOfWeek}}</div>
                            <div>{{item.ddmm}}</div>
                        </div>
                    </td>
                </tr>
            </thead>
            <!-- <tbody> -->
            <tr *ngFor="let item of this.teachers; let i = index" [ngClass]="{'bg-primary-100': false }">
                <th class="headcol">
                    <a>
                        <div>
                            <div placement="top" [ngbTooltip]="item.teacher_code" style="margin-top: -1px;">
                                {{i+1}}. {{item.name}}
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="badge-cover">
                                    <span placement="top" ngbTooltip="Đúng giờ" class="badge badge-primary mr-1">{{item.datas_sum.attention}}</span>
                                    <span placement="top" ngbTooltip="Muộn" class="badge badge-warning mr-1">{{item.datas_sum.late}}</span>
                                    <span placement="top" ngbTooltip="Vắng" class="badge badge-danger mr-1">{{item.datas_sum.total - (item.datas_sum.attention + item.datas_sum.late)}}</span>
                                    <span placement="top" ngbTooltip="Tổng" class="badge badge-default">{{item.datas_sum.total}}</span>

                                </div>
                                <div class="badge-cover">
                                    <span placement="top" [ngbTooltip]="'Tổng: ' + item.datas_sum.total_duration" class="badge badge-primary mr-1">{{item.datas_sum.attention_duration}}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </th>


                <td *ngFor="let item_datas of item.datas; let j = index" class="long">
                    <!-- tooltip -->

                    <!-- <div *ngIf="item_datas.checkin_times.length > 0" class="datas-tooltip">
                        <span *ngFor="let item3 of item_datas.checkin_times; let k = index" class="badge badge-primary badge-pill" style="font-size: unset;
                        margin: 3px;
                        font-weight: normal;">
                            {{item3.checkin_time}}
                        </span>
                    </div> -->
                    <!-- Danh sách khung giờ -->

                    <div class="w_t_btn">
                        <div *ngFor="let item_cate_checkin of item_datas.cate_checkins; let m = index">


                            <div placement="top" [ngbTooltip]="item_cate_checkin.checkin_class +' - '+ item_cate_checkin.checkin_subject +' - '+ ' | Sớm ' + item_cate_checkin.checkin_effective + 'p' + ' | Muộn ' + item_cate_checkin.checkin_late + 'p'" class="btn-group mb-1 mr-2 ml-2">
                                <button class="btn btn-secondary btn-icon-only btn-sm" data-toggle="dropdown" style="width: 35px;">
                                    <span class="btn-label">
                                        <span *ngIf="item_cate_checkin.status == 1" class="material-icons text-primary">
                                            check_circle </span>
                                        <span *ngIf="item_cate_checkin.status == 2" class="material-icons text-warning">
                                            report </span>
                                        <span *ngIf="item_cate_checkin.status == 3" class="material-icons text-danger">
                                            report </span>
                                        <!-- hiển thị số lượng giờ -->
                                        <span *ngIf="item_datas.checkin_times.length > 0" class="bag_num_checkin">
                                            {{item_datas.checkin_times.length}}
                                        </span>
                                    </span>
                                </button>
                                <div *ngIf="item_cate_checkin.status > 0" class="btn-full dropdown-menu dropdown-menu-right" x-placement="bottom-end" style="position: absolute; transform: translate3d(157px, 30px, 0px); top: 0px; left: 0px; will-change: transform;">
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.teacher_code, item.name, 'one')" [disabled]="!(item_cate_checkin.status == 3)">Điểm danh</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.teacher_code, item.name, 'all')">Điểm danh tất cả</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="showCheckinDetail(item_datas.checkin_times,item_datas.date)" [disabled]="!(item_datas.checkin_times.length > 0)">Xem ảnh điểm danh</button>
                                </div>
                                <button class="btn btn-secondary" (click)="showCheckinDetail(item_datas.checkin_times,item_datas.date)">
                                    <!-- <span>{{item_cate_checkin.checkin_class}} - {{item_cate_checkin.checkin_subject}} | {{item_cate_checkin.checkin_start}} - {{item_cate_checkin.checkin_end}}</span> -->
                                    <span>{{item_cate_checkin.checkin_class}} | {{item_cate_checkin.checkin_start}} - {{item_cate_checkin.checkin_end}}</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </td>


            </tr>
            <!-- </tbody> -->
        </table>
    </div>
    <div *ngIf="!isLoading && teachers.length == 0" class="text-center">
        <app-nodata msg="Không tìm thấy lịch dạy"></app-nodata>
    </div>
</div>
<div *ngIf="type_report == 'Báo cáo chi tiết tháng'">
    Báo cáo chi tiết tuần
</div>

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>