import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { FinanceService } from 'src/app/_services/finance.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { FinancePrintPreviewComponent } from '../finance-print-preview/finance-print-preview.component';
import { FinanceQrcodePreviewComponent } from '../finance-qrcode-preview/finance-qrcode-preview.component';

@Component({
  selector: 'app-finance-student-search',
  templateUrl: './finance-student-search.component.html',
  styleUrls: ['./finance-student-search.component.css']
})
export class FinanceStudentSearchComponent implements OnInit {
  dataList: any = [];
  title = "";
  filter = {
    student_code: this.data.code,
    type: environment.cateType_finance
  }
  sorter = {
    sortStr: 'checkin_start ASC',
  }
  isReload = false;
  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public financeService: FinanceService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FinanceStudentSearchComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(this.data);
    
    this.loadDetail();
  }

  ngOnInit(): void {

  }
  loadDetail() {
    this.data.data.forEach(element => {
      element.temp_checked = false; // mặc định là không chọn
    });
    this.dataList = this.data.data;
  }

  onCancelClick(): void {
    this.dialogRef.close({ 'isReload': this.isReload });
  }
  mergeFinance() {
    let data = [];
    let data_total = 0;
    this.dataList.forEach(element => {
      if (element.temp_checked) {
        data.push({
          id: element.finance_student_id,
          student_code: this.data.code,
        }); //
        data_total += element.finance_value;
      }
    });
    console.log(data);

    if (data.length < 2) {
      this.toastr.warning("", "Vui lòng chọn từ 2 khoản thu cần gộp");
      return
    }
    // popup confirm
    let tempdata = {
      title: 'Xác nhận gộp khoản thu  ?',
      msg: '' + data.length + ' khoản thu. Tổng giá trị ' + this.utilityService.formatCurrency(data_total) + "",
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        this.doMergeFinance(data);
      }
    });
  }
  unMergeFinance(item) {

    // popup confirm
    let tempdata = {
      title: 'Xác nhận hủy gộp khoản thu  ?',
      msg: 'Các khoản : ' + item.merge_name + '. Tổng giá trị ' + this.utilityService.formatCurrency(item.merge_value) + "",
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {

        let data = {
          'merge_code': item.merge_code,
          'student_code': this.data.code
        }
        this.financeService.unMergeFinance(data).subscribe(
          async response => {
            // this.isLoading.dismiss();
            this.isLoading = false;
            console.log(response);
            if (response.code == 'ok') {
              this.toastr.success("Hủy gộp khoản thu thành công");
              this.loadDetail();
            }
          },
          error => {
            this.isLoading = false;
            // this.isLoading.dismiss();
            console.error(error);
          });
      }
    });
  }
  doMergeFinance(data) {
    console.log("doMergeFinance.........", data);
    this.isLoading = true;
    this.financeService.mergeFinance(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Gộp khoản thu thành công");
          this.loadDetail();
        }


      },
      error => {
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      });
  }
  click_select() {
    let data = [];
    let data_total = 0;
    this.dataList.forEach(element => {
      if (element.temp_checked) {
        data.push({
          student_code: this.data.code,
          finance_cate_id: element.id,
          finance_title: element.name,
          finance_student_id: element.finance_student_id,
          payment_type: 2,
          status: 2,
          payment_value: element.finance_value,
          // payment_time : 2,
          temp_checked: element.temp_checked,
          temp_checked_origin: element.temp_checked_origin,
        }); //
        data_total += element.finance_value;
      }
    });
    console.log(data);

    if (data.length == 0) {
      this.toastr.warning("", "Vui lòng chọn khoản thu cần thanh toán");
      return
    }

    // popup confirm
    let tempdata = {
      title: 'Xác nhận thanh toán ?',
      msg: '' + data.length + ' khoản thu. Tổng giá trị ' + this.utilityService.formatCurrency(data_total) + "",
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        this.doPayment(data);
      }
    });
  }

  doPayment(data) {
    console.log("doPayment.........", data);
    this.isLoading = true;
    this.financeService.saveUpdate(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        this.isLoading = false;
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success("Cập nhật thành công");
          // tắt popup
          this.dialogRef.close(response);
        }
      },
      error => {
        this.isLoading = false;
        // this.isLoading.dismiss();
        console.error(error);
      });
  }
  refund(item) {
    // popup confirm
    let tempdata = {
      title: 'Xác nhận hủy giao dịch ' + this.utilityService.formatCurrency(item.finance_cate_value) + " ?",
      msg: 'Học sinh: ' + this.data.full_name + '. Khoản thu ' + item.name,
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        let data = {
          id: item.finance_student_id
        }
        this.financeService.updateRefund(data).subscribe(
          async response => {
            // this.isLoading.dismiss();
            this.isLoading = false;
            console.log(response);
            if (response.code == 'ok') {
              this.toastr.success("Cập nhật thành công");
              this.isReload = true;
              // tắt popup
              this.loadDetail();
            }
          },
          error => {
            this.toastr.warning("Đã có lỗi xảy ra ");
            this.isLoading = false;
            // this.isLoading.dismiss();
            console.error(error);
          });
      }
    });
  }

  print(item) {

    const dialogRef = this.dialog.open(FinancePrintPreviewComponent, {
      data: [item]
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {

      }
    });
  }
  showQRcode(item) {
    const dialogRef = this.dialog.open(FinanceQrcodePreviewComponent, {
      data: {
        'student': this.data,
        'finance': item,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {

      }
    });
  }

}
