<div style="overflow-y: scroll;
overflow-x: hidden;
height: calc(100vh);" class="qbs-scroll">
    <table class="table table-head-purple table-hover mb-0 " id="datatable" style="height: 42px;">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 40px; text-align: center;">
                    <button (click)="this.dialogRef.close();" class="btn btn-sm btn-warning btn-icon-only btn-circle btn-thick"><span class="material-icons "> close </span>
                            </button>
                </th>
                <th>Họ và tên</th>
                <th>Môn</th>
                <th>Lớp</th>
                <th>Lịch dạy</th>
                <th class="text-center">Thời gian</th>
                <th class="text-center">Thời lượng</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.teacher_list; let i = index">
                <td class="stt_td" style="text-align: center;  width: 40px;">
                    <span class=""><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                </td>
                <td style="width: 180px; border-right: 1px solid #0996ca24;">
                    {{item.techter_name}}
                </td>
                <td>
                    {{item.subject_name}}
                </td>
                <td>
                    {{item.class_name}}
                </td>
                <td>
                    {{item.checkin_name}}
                </td>
                <td class="center" style="width: 125px;">
                    {{item.checkin_start}} - {{item.checkin_end}}
                </td>
                <td>
                    {{item.checkin_duration_2}}
                </td>
            </tr>
        </tbody>

    </table>
    <!-- <div style="overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 42px);" class="qbs-scroll">
        <table class="table table-head-purple table-hover" id="datatable">

            <tbody>
                

            </tbody>
        </table>
    </div> -->
</div>