import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }
  // đanh giá student học kỳ
  saveAssessmentStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/saveAssessmentStudent'), data,).map(data => { return data; });
  }
  saveAssessmentSetting(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/saveSetting'), data,).map(data => { return data; });
  }
  saveAssessmentSettingLockMark(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/saveSettingLockMark'), data,).map(data => { return data; });
  }
  updateAssessmentSetting(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('assessment/saveSetting'), data,).map(data => { return data; });
  }
  updateAssessmentSettingLockMark(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('assessment/saveSettingLockMark'), data,).map(data => { return data; });
  }
  loadAssessmentSetting(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadSetting'), data,).map(data => { return data; });
  }
  saveAssessment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/saveAssessment'), data,).map(data => { return data; });
  }
  updateAssessment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/updateAssessment'), data,).map(data => { return data; });
  }
  updateAndSendAssessmentNotification(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/updateAndSendAssessmentNotification'), data,).map(data => { return data; });
  }
  deleteAssessment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/deleteAssessment'), data,).map(data => { return data; });
  }
  deleteAssessmentStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/deleteAssessmentStudent'), data,).map(data => { return data; });
  }
  updateAssessmentStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/updateAssessmentStudent'), data,).map(data => { return data; });
  }
  loadKhois(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadKhois'), data,).map(data => { return data; });
  }
  loadStudentAssessment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadStudent'), data,).map(data => { return data; });
  }
  loadAssessment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadAssessment'), data,).map(data => { return data; });
  }
  loadAssessmentStudent(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadAssessmentStudent'), data,).map(data => { return data; });
  }
  loadAssessmentStudentDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/loadAssessmentStudentDetail'), data,).map(data => { return data; });
  }
  generateTBMHK(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/generateTBMHK'), data,).map(data => { return data; });
  }
  generateTBMCN(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/generateTBMCN'), data,).map(data => { return data; });
  }
  // Assessment Period
  saveAssessmentPeriod(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/saveAssessmentPeriod'), data,).map(data => { return data; });
  }
  updateAssessmentPeriod(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/updateAssessmentPeriod'), data,).map(data => { return data; });
  }
  findAssessmentPeriod(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/findAssessmentPeriod'), data,).map(data => { return data; });
  }
  sendNotificationAssessmentPeriod(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('assessment/sendNotificationAssessmentPeriod'), data,).map(data => { return data; });
  }
}
