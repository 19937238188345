<form class="">
    <div class="p-2">
        <div class="modal-header p-2">
            <h5 class="modal-title">Xác nhận tạo lịch chi tiết từ <b>{{cate.checkin_from}}</b> đến <b>{{cate.checkin_to}}</b></h5>
        </div>
        <div class="modal-body p-2 mt-3 mb-3">

            <div class="row p-2">
                <section class="mt-1 mb-3 weeks-checkbox">
                    <div class="mb-1">Ngày áp dụng</div>
                    <mat-checkbox *ngFor="let item of this.temp_weeks_update; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                </section>
            </div>

            <div class="ml-2">Cho phép chỉnh sửa thời gian. Lịch <b>{{cate.checkin_time}}</b>. Thời lượng: <b>{{cate.checkin_duration}}</b></div>
            <div class="row p-0 mt-2">
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ bắt đầu</mat-label>
                        <input matInput type="time" [(ngModel)]="checkin_start" placeholder="Giờ vào" name="checkin_start" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ kết thúc</mat-label>
                        <input matInput type="time" [(ngModel)]="checkin_end" placeholder="Giờ ra" name="checkin_end" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Thời lượng</mat-label>
                        <input matInput type="text" [(ngModel)]="duration" name="thoiluong" readonly>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </div>
    <!-- <div class="modal-footer justify-content-around bg-primary-50">
        <button (click)="okClick()" class="btn btn-primary ">
            {{data.ok != null ? data.ok : 'Xác nhận' }}
        </button>
        <button (click)="cancelClick()" class="btn">
            Bỏ qua
        </button>
    </div> -->
</form>
<mat-dialog-actions align="end">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="okClick()">
        <span class="btn-label"><span class="material-icons">done</span></span>Xác nhận
    </button>
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="cancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>