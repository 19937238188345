<div class="page-content fade-in-up">
    <div class="row m-2 justify-content-end ">
        <!-- <div>Danh sách thông báo</div> -->
        <!-- <div class="col-4 p-0 d-flex justify-content-end align-items-center "> -->
        <!-- <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon " (click)="popup_compose() ">
                <span class="btn-label "><i class="ti ti ti-plus "></i></span>Thêm mới
            </button> -->

        <div ngbDropdown class="d-inline-block">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon  ml-2" ngbDropdownToggle>
                    <span class="btn-label"><span class="material-icons">add</span></span>Thêm mới
                </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="popup_compose()" class="text-primary"><span class="material-icons mr-2">sms
                        </span>
                        Tin nhắn</button>
                <!-- <button ngbDropdownItem (click)="popup_compose_assessment()" class="text-primary">
                        <span class="material-icons mr-2">notifications </span>
                        Kết quả học tập
                    </button> -->


            </div>
        </div>

        <!-- </div> -->
    </div>
    <div class="row justify-content-center ">

        <div class="col ">
            <div class=" ">
                <table class="table table-head-purple table-hover " id="datatable ">
                    <thead class="thead-default thead-lg ">
                        <tr>
                            <th style="width: 50px; text-align: center; ">
                                <div class="my-stt "></div>
                            </th>
                            <!-- <th>Tiêu đề</th> -->
                            <th>Thông báo</th>
                            <th class="text-center">File đính kèm</th>
                            <th class="text-center">Bình luận</th>
                            <th class="text-center">Trạng thái</th>
                            <th class="text-center">Thời gian</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.messengers; let i=index ">
                            <td class="stt_td " style=" text-align: center; padding: 0.5rem; ">
                                <span class="stt_span "><span class="badge badge-default badge-circle ">{{i+1}}</span> </span>
                                <img class="img-circle" src="./assets/img/icons/discussion.png" alt="image" width="40" />
                                <!-- <a class="avatar-img-list " style="width: 35px; height: 35px; " href="javascript:; " [style.background-image]="'./assets/img/icons/discussion.png'"></a> -->
                            </td>
                            <!-- <td class=" ">

                            </td> -->
                            <td style="max-width: 300px;">
                                <div class="font-weight-bold">{{item.title}}</div>
                                <div style="max-height: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;">{{item.message}}</div>
                                <div class="text-secondary font-13" style="white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">{{item.recipient}}</div>
                            </td>
                            <td class="text-center">
                                <!-- <div *ngIf="item.file_url == ''" class="text-muted">
                                    <span class="material-icons text-secondary">attach_file</span> Không có
                                </div>
                                <div *ngIf="item.file_url != ''" class="text-muted">
                                    <button class="btn-adjust btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" (click)='popupDetail(item)'>
                                        <span class="material-icons"> attach_file </span>
                                    </button>
                                </div> -->

                                <span *ngIf="item.file_url != ''" class="material-icons text-primary">attach_file</span>
                                <span *ngIf="item.file_url == ''" class="material-icons text-secondary">attach_file</span>


                            </td>
                            <!-- <td>{{item.recipient}}</td> -->
                            <td class="text-center">
                                <span *ngIf="item.comment_allowed == 1" class="material-icons text-primary">chat</span>
                                <span *ngIf="item.comment_allowed == 0" class="material-icons text-danger">speaker_notes_off</span> {{item.count_comment}}
                            </td>
                            <td class="text-center">
                                <span *ngIf="item.status == 0" class="material-icons text-secondary">schedule</span>
                                <span *ngIf="item.status == 1" class="material-icons text-primary">done</span>
                                <span *ngIf="item.status == 2" class="material-icons text-dangger">do_disturb_alt</span>
                            </td>
                            <td class="text-center">{{item.created_date_str}}</td>
                            <td class="text-center">
                                <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupDetail(item)'>
                                    <span class="material-icons"> info </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row align-items-center mb-2 ">
                <div class="col-4 ">
                    <span class=" ">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                            {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
                </div>
                <div class="col-8 d-flex justify-content-end ">
                    <ul class="pagination p-1 " style="margin-bottom: 0px; ">
                        <li>
                            <select class="form-control-sm " name="viewNums " id="viewNums" [(ngModel)]="viewNumsObj " (change)="viewNumsObjChange() ">
                                    <option *ngFor="let item of appService.viewNums " [ngValue]="item.Code ">{{item.Value}}
                                    </option>
                                </select>
                        </li>
                        <li class="page-item ">
                            <a class="page-link page-link-solid " (click)="pageChange( 'prev') " aria-label="Previous ">
                                <span aria-hidden="true ">
                                        <i class="ti ti-angle-left "></i>
                                    </span>
                            </a>
                        </li>
                        <li *ngFor="let item of listPage " class="page-item active " (click)="pageChange(item.i) " [ngClass]="{ 'active' : item.i==curentPage} ">
                            <a class="page-link " href="javascript:; ">{{item.i}}</a>
                        </li>
                        <li class="page-item ">
                            <a class="page-link page-link-solid " (click)="pageChange( 'next') " aria-label="Next ">
                                <i class="ti ti-angle-right "></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>