<div class="row mt-2">
    <div class="col d-flex">
        <div class="card">
            <div class="card-body flexbox">
                <img [src]="appService.showImageTeacher2(data.data.image_url)" alt="Avatar" style="height: 55px; border-radius: 50%;">
                <div class="text-right ml-3">
                    <h3 class="mb-1">Tháng <span class="badge badge-primary" style="font-size: x-large;">{{monthStr}}</span></h3>
                    <div class="">{{data.data.teacher_name}}</div>
                </div>
            </div>
        </div>
        <div class="card ml-3">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.data.class_code}}</h2>
                    <div class="">Lớp</div>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <div class="card">
            <div class="card-body flexbox">

                <div class="text-center mr-5">
                    <h3 class="mb-1">{{data.data.tamtinh_total_amount | qbscurrency}}</h3>
                    <div class="">Tạm tính</div>

                </div>
                <div class="text-center text-blue mr-5">
                    <h3 class="mb-1">{{data.data.tamthu_total_amount | qbscurrency}}</h3>
                    <div class="">Tạm thu</div>
                </div>
                <div class="text-center text-primary mr-5">
                    <h3 class="mb-1">{{data.data.month_total_paid | qbscurrency}}</h3>
                    <div class="">Đã thanh toán</div>
                </div>
                <div class="text-center text-danger mr-5">
                    <h3 class="mb-1">{{(data.data.tamthu_total_amount - data.data.month_total_paid) | qbscurrency}}</h3>
                    <div class="">Chưa thanh toán</div>
                </div>
                <!-- <div class="text-center mr-5">
                    <h2 class="mb-1">{{data.data.percent}}%</h2>
                    <div class="">Tỷ lệ</div>
                </div> -->
                <div class="text-center">
                    <button class="btn btn-warning" (click)="this.dialogRef.close();">
                        <span class="material-icons font-26">
                        close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-between align-items-center m-2">

    <mat-button-toggle-group name="favoriteColor" color="primary" aria-label="Favorite Color" [(ngModel)]="status" (click)="search()">
        <mat-button-toggle value="">Tất cả</mat-button-toggle>
        <mat-button-toggle value="dathanhtoan">Đã thanh toán</mat-button-toggle>
        <mat-button-toggle value="chuathanhtoan">Chưa thanh toán</mat-button-toggle>
    </mat-button-toggle-group>


    <button *ngIf="data.data.chotthu == ''" class="btn btn-default btn-labeled btn-labeled-left mr-1">
        <span class="btn-label"><span class="material-icons">
            remove_circle_outline
            </span></span>Lớp chưa chốt chu
    </button>
    <button *ngIf="data.data.chotthu != ''" class="blurButton btn btn-blue btn-labeled btn-labeled-left btn-icon mr-1" (click)="confirmPayment('payment',null)">
        <span class="btn-label"><span class="material-icons">
            paid
            </span></span>Thanh toán
    </button>
</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>

                <th>Học sinh</th>


                <th>Dư N-1</th>
                <th class="text-center" style="width: 136px;">Chốt N-1</th>
                <th>Thực tế</th>
                <th class="text-center">Tạm tính</th>
                <th class="text-center">Tạm thu</th>
                <th class="text-center">Thanh toán</th>
                <th class="text-center" style="width: 80px;">Chi tiết</th>

                <th class="text-center" style="width: 172px;">Thao tác</th>
                <th class="text-center" style="width: 172px;">Code TT</th>
                <th class="text-center" style="width: 60px;">
                    <label class="checkbox myHeader checkbox-warning check-single" style="margin-left: 3px;">
                        <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                        <span class="input-span"></span>
                    </label>
                </th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.students; let i = index" [ngClass]="{'bg-primary-100': false }">

                <td>
                    <div style="font-size: 18px;">
                        {{i+1}}.{{item.full_name}}
                    </div>
                    <div class="text-muted" style="font-size: small;">
                        {{item.code}}
                    </div>
                </td>


                <td>
                    <div class="text-center font-bold">{{item.n_1_diff_amount | qbscurrency}}</div>
                    <div class="text-center">
                        <div class="meal_count" [ngClass]="{'text-danger': item.n_1_diff_count > 0 }" placement="top" ngbTooltip="Ngày dư tháng trước">
                            <span>{{item.n_1_diff_count | qbscurrency}}</span>
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <span *ngIf="item.payment_status_n1 == 1" class="badge badge-success">Đã chốt N-1</span>
                    <button *ngIf="item.payment_status_n1 != 1" class=" btn btn-primary btn-labeled btn-labeled-left " (click)="chotthangn1(item)">
                        <span class="btn-label">
                            <span  *ngIf="!isLoading_chotthu" class="material-icons"> pending_actions </span>
                            <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                        </span>
                        Chốt N-1
                    </button>
                </td>
                <td>
                    <!-- {{item.student_register | qbscurrency}} -->
                    <div class="text-center font-bold">{{item.total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày: Đăng ký/ Vắng / Có" style="min-width: 80px;">
                            <span style="color: #227d3ae3;">{{item.student_register | qbscurrency}}</span> /
                            <span [ngClass]="{'text-danger': item.student_inattention > 0 }">
                            {{item.student_inattention | qbscurrency}}
                            </span> /
                            <span class="text-info">{{item.student_attention | qbscurrency}}</span>
                        </div>
                    </div>

                </td>
                <td>

                    <div class="text-center font-bold">{{item.tamtinh_total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày tạm tính cả tháng">
                            <span>{{item.tamtinh_register | qbscurrency}}</span>

                        </div>
                    </div>

                </td>
                <td style="background: #0099cc3d;">
                    <div class="text-center font-bold">{{item.tamthu_total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày tạm thu = Tạm tính - Dự N1">
                            <span>{{item.tamthu_register | qbscurrency}}</span>

                        </div>
                    </div>
                </td>
                <td class="text-center">
                    {{item.month_total_paid | qbscurrency}}
                </td>
                <td class="text-center">
                    <!-- btn-outline-primary -->
                    <button (click)="openMealStudentMonth(item)" class="mr-2 btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Chi tiết học sinh">
                        <span class="material-icons">
                            calendar_month
                            </span>
                    </button>
                </td>
                <td class="text-center ">
                    <button *ngIf="item.status_sent != 1" class=" btn btn-primary btn-labeled btn-labeled-left " (click)="chottamthu(item)">
                            <span class="btn-label">
                                <span  *ngIf="!isLoading_chotthu" class="material-icons"> send </span>
                                <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                            </span>
                            Chốt gửi
                        </button>

                    <button *ngIf="item.status_sent == 1 && !item.paid" class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon mr-1" (click)="confirmPayment('payment',item)">
                            <span class="btn-label"><span class="material-icons">
                                paid
                                </span></span>Thanh toán
                        </button>

                    <button *ngIf="item.status_sent == 1 && item.paid" class=" btn btn-sm btn-info btn-icon-only mr-1" placement="top" ngbTooltip="Trường chưa cấu hình phiếu thu">
                            <span class="btn-label"><span class="material-icons">
                                print
                                </span></span>
                    </button>
                    <button *ngIf="item.status_sent == 1 && item.paid" class=" btn btn-sm  btn-icon-only mr-1" (click)="confirmPayment('refund',item)" placement="top" ngbTooltip="Hủy thanh toán">
                            <span class="btn-label"><span class="material-icons">
                                do_not_disturb
                                </span></span>
                    </button>
                    <!-- </ng-container> -->


                </td>
                <td class="text-center font-bold">
                    <span *ngIf="item.payment_id == null" placement="top" ngbTooltip="Chưa chốt thu" style="min-width: 80px;">Chưa chốt</span>

                    <button *ngIf="item.payment_id != null && item.status < 2" (click)="showQRcode(item)" class="blurButton btn btn-white btn-labeled btn-labeled-left btn-icon mr-1" placement="top" ngbTooltip="Quét mã QRcode hoặc chuyển khoản vào TK BIDV">
                        <span class="btn-label"><span class="material-icons">
                            qr_code_2
                            </span>
                        </span>
                        {{item.paid_qrcode}}
                    </button>

                    <span *ngIf="item.payment_id != null && item.status == 2" placement="top" ngbTooltip="Đã thanh toán" style="min-width: 80px;">Đã thanh toán</span>


                </td>
                <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" [disabled]="item.paid"  [(ngModel)]="item.selected_temp">
                        <span class="input-span"></span>
                    </label>
                </td>

            </tr>
        </tbody>
    </table>
    <div *ngIf="isLoading" class="spinner-loadClass">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>