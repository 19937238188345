<div style="height: 100%;">
    <div class="p-2 d-flex justify-content-between align-items-center">
        <h4>Sổ sức khỏe kỳ <span class="badge-lg badge-primary badge-pill">{{dateSelected}}</span> </h4>
        <button class="btn btn-warning" (click)="this.dialogRef.close();">
            <span class="material-icons font-26">
                close
            </span>
        </button>
    </div>
    <div class="row" style="height: calc(100% - 47px);">


    </div>
</div>