import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-checkin-unknown-detail',
  templateUrl: './checkin-unknown-detail.component.html',
  styleUrls: ['./checkin-unknown-detail.component.css']
})
export class CheckinUnknownDetailComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CheckinUnknownDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
  }

  ngOnInit(): void {
  }

}
