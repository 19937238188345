import { UserService } from './../../_services/user.service';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { UtilityService } from './../../_services/utility.service';
import { Membership } from './../../model/membership';
import { AppService } from './../../_services/app.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;
  isLoading = false;
  memberships = [];
  memberships_backup: any;

  // for add
  membership = new Membership();
  // for update
  membershipSelected = new Membership();
  isDeleted: boolean = false;
  isResset: boolean = false;

  constructor(
    public userService: UserService,
    private toastr: ToastrService,
    public appService: AppService,
    public utilityService: UtilityService,
    private _script: ScriptLoaderService,
  ) {
    // this.membershipSelected = this.membership;
  }

  ngOnInit() {
    this.loadMembership();
  }
  ngAfterViewInit() {
    // $("#form-add-membership").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('.qbs-select').selectpicker();

  }

  async loadMembership() {
    let filter = {};

    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.memberships = response;
          this.memberships_backup = response;
          console.log(this.memberships);
        }
      },
      error => {
        console.error(error);
      })

  }

  insertMembership() {


    if (this.membership.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên thành viên");
      return false;
    }
    if (this.membership.phone.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập số điện thoại");
      return false;
    }
    if (this.membership.password.trim() == "") {
      this.addForm.controls.password.setErrors("Vui lòng nhập mật khẩu");
      this.toastr.warning("", "Vui lòng nhập mật khẩu");

      return false;
    } else if (this.membership.password != this.membership.repassword) {
      this.addForm.controls.repassword.setErrors("Mật khẩu chưa trùng khớp");
      this.toastr.warning("", "Mật khẩu chưa trùng khớp");
      return false;
    }



    // return;

    this.isLoading = true;
    console.log(this.membership);
    if (!this.addForm.invalid) {
      this.userService.insertUser(this.membership).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.loadMembership();
            this.membership = new Membership();
            $("#add-membership-modal").modal('hide');
          } else if (response.status == 409) {
            this.toastr.error(response.message, "Lỗi");
          }
        },
        error => {
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          this.isLoading = false;
          console.error(error);
        })
    } else {
      this.toastr.error("", "Lỗi form");
    }
  }
  popupCreate() {
    this.membership = new Membership();
    this.isLoading = false;
    $("#add-membership-modal").modal('show');


  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.memberships = this.memberships_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.memberships = this.memberships.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }

  popupUpdate(item) {
    this.membershipSelected = item;
    this.isDeleted = this.membershipSelected.deleted == 0 ? false : true;
    this.membershipSelected.isResset = 0;
    this.isResset = false;
    this.membershipSelected.password = "";
    this.membershipSelected.repassword = "";
    $("#update-membership-modal").modal('show');
  }


  update() {

    if (this.membershipSelected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên thành viên");
      return false;
    }
    if (this.membershipSelected.phone.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập số điện thoại");
      return false;
    }

    if (this.isResset) {
      if (this.membershipSelected.password.trim() == "") {
        this.updateForm.controls.password.setErrors("Vui lòng nhập mật khẩu");
        this.toastr.warning("", "Vui lòng nhập mật khẩu");
        return false;
      } else if (this.membershipSelected.password != this.membershipSelected.repassword) {
        this.updateForm.controls.password.setErrors("Mật khẩu chưa trùng khớp");
        this.toastr.warning("", "Mật khẩu chưa trùng khớp");
        return false;
      }
      this.membershipSelected.isResset = 1;
    }
    if (this.isDeleted) {
      this.membershipSelected.deleted = 1;
    } else {
      this.membershipSelected.deleted = 0;
    }

    // return;

    this.isLoading = true;
    console.log(this.membershipSelected);

    this.userService.updateMember(this.membershipSelected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.loadMembership();

          $("#update-membership-modal").modal('hide');
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })


  }



}
