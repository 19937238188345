 import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-finance-category',
  templateUrl: './finance-category.component.html',
  styleUrls: ['./finance-category.component.css']
})
export class FinanceCategoryComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;
  
  categorys : any;
  categorys_backup: any
  
  cateType = environment.cateType_finance;
  // for add
  cate = new Category(environment.cateType_class);
  finance_content_temp = [{ 'code': 0, 'label': '', 'value': '' }];
  // for update
  categoryselected: any;
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;

  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: this.appService.sort_category[2].code,
  }
  
  filter = {
    name: "",
    khoi_code: "",
    code_parent: "",
    deleted: 0,
    type: this.cateType
  }



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Khai báo khoản thu";
    this.categoryselected = this.cate;
    //Xử lý cấp học.
    this.filter.code_parent = appService.currentUser.name_alias;
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  change_caphoc(caphoc){
      this.filter.code_parent = caphoc;// code_parent được dùng để lưu cấp học
      // this.loadCategory();
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {
    // $("#form-add-category").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
  }
  clearSearch(){
    this.filter.name='';
    this.loadCategory();
  }
  changeCheckbox(event){
    console.log(event);
    console.log(this.appService.weeks);
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar; 
  }
  category_change(event) {
    this.loadCategory();
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {

          // lặp để thêm json tạm
          // response.data.forEach(element => {
          //   element.finance_content_json = (element.finance_content != "" && element.finance_content != null) ? JSON.parse(element.finance_content) : [] ;
          // });

          this.categorys = response.data;
          this.categorys_backup = response.data;
          
          

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }

  // 
  removeItem(index: number) {
    this.finance_content_temp.splice(index, 1);
    console.log(this.finance_content_temp);
  }
  addItem(i) {
      let  temp = { 'code': i, 'label': '' , 'value': ''}
      this.finance_content_temp.push(temp);
  }
  prepareFinance_content(finance_content_temp) : boolean{
    var result = true;
    finance_content_temp.forEach((element,i) => {
      element.code = i+1;
      if(element.label == undefined || element.label == "") result = false;
    });
    return result
  }
  insertCategory() {
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng tên lớp");
      return false;
    }


    this.cate.name = this.cate.name.trim();
    this.cate.code = Math.floor(Math.random() * Date.now()).toString(36).substring(2, 10);
    this.cate.code_parent = this.filter.code_parent; // code_parent được dùng để lưu cấp học

    if(this.cate.finance_type=='dot'){
      console.log(this.finance_content_temp);
      if(!this.prepareFinance_content(this.finance_content_temp)){ // chưa check vừa kiểm tra dữ liệu
        this.toastr.warning("", "Vui lòng nhập chi tiết khoản thu");
        return;
      }
      this.cate.finance_content = JSON.stringify(this.finance_content_temp);
    }

    console.log(this.cate);
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == 'ok') {
            this.toastr.success("", "Thêm mới khoản thu thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }else if(response.code == 'nok'){
            this.toastr.warning("", response.msg);
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }
  

  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {
    this.resetCategory();
    $("#add-category-modal").modal('show');
  }
  popupUpdate(item) {
    this.categoryselected = item;
    this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;

    this.finance_content_temp = (this.categoryselected.finance_content != "" && this.categoryselected.finance_content != null) ? JSON.parse(this.categoryselected.finance_content) : [{ 'code': 0, 'value': '' }] ;
    console.log(this.finance_content_temp);
    console.log(this.categoryselected);
    $("#update-category-modal").modal('show');
  }
  
  updateCategory() {

    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if(this.categoryselected_file !== null){
      this.categoryselected.image_url = this.cateType +"/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }
    this.categoryselected.deleted = this.isDeleted ? 1 : 0;

    this.categoryselected.khoi = this.utility.getNameByCode(this.categoryselected.khoi_code,this.appService.currentUser.khois);
    
    console.log(this.categoryselected);
    //lấy khối name
    this.categoryselected.khoi = this.utility.checkAndGetKhoi_name(this.categoryselected.khoi_code, this.appService.currentUser.khois);

    if(this.categoryselected.finance_type=='dot'){
      if(!this.prepareFinance_content(this.finance_content_temp)){ // chưa check vừa kiểm tra dữ liệu
        this.toastr.warning("", "Vui lòng nhập chi tiết khoản thu");
        return false;
      }
      this.categoryselected.finance_content = JSON.stringify(this.finance_content_temp);
    }

    this.isLoading = true;
    this.categoryeduService.update(this.categoryselected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code=="ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  
  
  
  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupUpdate(r);
    }

    /////////////////
    openSelectTeacher(classSelected, index) {
      // this.appService.deskIndex = index;
      // this.modalService.open(content, { size: 'xl' });
  
      console.log("this.appService.deskIndex", this.appService.deskList[this.appService.deskIndex]);
      this.appService.deskIndex = index;
      const dialogRef = this.dialog.open(TeacherPickerComponent, {
        position: {
          // left: '10px',
          // right: '10px'
        },
        width: '95%',
        height: '95%',
        panelClass: 'full-screen-modal',
        data: { deskIndex: index, classSelected : classSelected}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("close");
        this.appService.updateDeskRevenue();
        if (typeof result != "undefined" && result.code == "ok") {
          console.log("result.data", result.data);
          // load lại dữ liệu
          this.loadCategory();
        }
      });
  
  
    }
}
