<div class="page-heading">
    <h1 class="page-title">Peity Charts</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="index.html"><i class="la la-home font-20"></i></a>
        </li>
        <li class="breadcrumb-item">Charts</li>
        <li class="breadcrumb-item">Peity Charts</li>
    </ol>
</div>
<div class="page-content fade-in-up">
    <div class="alert alert-success alert-bordered">
        <p>Peity (sounds like deity) is a jQuery plugin that converts an element's content into a &lt;svg> mini pie donut line or bar chart.</p>
        <p>
            <a class="btn btn-success" href="https://github.com/benpickles/peity" target="_blank">Official Website</a>
        </p>
    </div>
    <div class="ibox">
        <div class="ibox-head">
            <div class="ibox-title">Peity Charts</div>
        </div>
        <div class="ibox-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Graph</th>
                        <th>Code</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span class="pie">2/8</span>
                        </td>
                        <td><code>&lt;span class="pie">2/8&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="pie">120/200</span>
                        </td>
                        <td><code>&lt;span class="pie">120/200&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="pie">0.5/1.5</span>
                        </td>
                        <td><code>&lt;span class="pie">0.5/1.5&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="pie" [attr.data-peity]="{ &quot;radius&quot;: 10 }" >1/3</span>
                        </td>
                        <td><code>&lt;span class="pie" data-peity={{ '{' }} radius: 10 {{ '}' }} >1/3&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="pie" [attr.data-peity]="{ radius: 15 }" >1,2</span>
                        </td>
                        <td><code>&lt;span class="pie" data-peity={{ '{' }} radius: 15 {{ '}' }} >1,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="donut">1/5</span>
                        </td>
                        <td><code>&lt;span class="donut">1/5&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="donut">226/360</span>
                        </td>
                        <td><code>&lt;span class="donut">226/360&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="updating-chart">5,3,9,6,5,9,7,3,5,2,5,3,9,6,5,9,7,3,5,2</span>
                        </td>
                        <td><code>&lt;span class="updating-chart">5,3,9,6,5,9,7,3,5,2,5,3,9,6,5,9,7,3,5,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="line">5,3,9,6,5,9,7,3,5,2</span>
                        </td>
                        <td><code>&lt;span class="line">5,3,9,6,5,9,7,3,5,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="line">5,3,2,-1,-3,-2,2,3,5,2</span>
                        </td>
                        <td><code>&lt;span class="line">5,3,2,-1,-3,-2,2,3,5,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="line">0,-3,-6,-4,-5,-4,-7,-3,-5,-2</span>
                        </td>
                        <td><code>&lt;span class="line">0,-3,-6,-4,-5,-4,-7,-3,-5,-2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="bar">5,3,9,6,5,9,7,3,5,2</span>
                        </td>
                        <td><code>&lt;span class="bar">5,3,9,6,5,9,7,3,5,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="bar">5,3,2,-1,-3,-2,2,3,5,2</span>
                        </td>
                        <td><code>&lt;span class="bar">5,3,2,-1,-3,-2,2,3,5,2&lt;/span></code></td>
                    </tr>
                    <tr>
                        <td>
                            <span class="bar">0,-3,-6,-4,-5,-4,-7,-3,-5,-2</span>
                        </td>
                        <td><code>&lt;span class="bar">0,-3,-6,-4,-5,-4,-7,-3,-5,-2&lt;/span></code></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
