import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent implements OnInit {
  fileUrl: string = '';
  fileType: string = '';
  constructor(public dialogRef: MatDialogRef<FileViewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
      this.fileUrl = data.file_url
     }

  ngOnInit(): void {
    this.detectFileType();
  }
  detectFileType() {
    if (this.fileUrl) {
      const extension = this.fileUrl.split('.').pop()?.toLowerCase();
      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          this.fileType = 'image';
          break;
        case 'pdf':
          this.fileType = 'pdf';
          break;
        case 'doc':
        case 'docx':
        case 'xls':
        case 'xlsx':
          this.fileType = 'document';
          break;
        default:
          this.fileType = 'unsupported';
      }
    }
  }
}
