<div id="print-section">
    <div class="abc" style="
   
   
   font-family: Tahoma;
   font-size: 14px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
        <div style="text-align: right">{{dateTimeStr}}</div>
        <table style="font-size: 14px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">Khách lẻ</td>
            </tr>
        </table>
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 14px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td></td>
                    <td>Tên</td>
                    <td>SL</td>
                    <td>ĐG</td>
                    <td>T.Tiền</td>
                </tr>
                <tr *ngFor="let item of appService.cartObj.cart; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.product.name}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{item.product.price| number}}</td>
                    <td>{{item.quantity * item.product.price | number:0}}</td>
                </tr>
                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{appService.cartObj.price_totol |
                        number}}</td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Chúc quý khách vui vẻ, hẹn gặp lại</div>
    </div>
</div>