<div style="height: 100%;">
    <div class="p-2 d-flex justify-content-between align-items-center">
        <h4>Chi tiết điểm danh của học sinh ngày <span class="badge-lg badge-primary badge-pill">{{dateSelected}}</span> </h4>
        <button class="btn btn-warning" (click)="this.dialogRef.close();">
            <span class="material-icons font-26">
                close
            </span>
        </button>
    </div>
    <div class="row" style="height: calc(100% - 47px);">
        <div class="col">
            <mat-calendar class="calendar-student-detail" [startAt]="selectedDate_date" [dateClass]="dateClass()" (selectedChange)="onSelect($event)" (monthSelected)="handleMonthSelected($event)"></mat-calendar>
        </div>
        <div class="col" style="height: 100%; overflow: auto;">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                    <a class="avatar-img-list" style="display: inline-block; width: 70px;  height: 70px;" href="javascript:;" [style.background-image]="appService.showImageStudent(student.image_url,student.gender)"></a>
                    <div class="ml-3">
                        <div>{{student.full_name}}</div>
                        <div class="text-muted">{{student.code}}</div>
                    </div>
                    <div>
                        <span *ngIf="student.gender == 1" class="material-icons text-blue">boy</span>
                        <span *ngIf="student.gender == 0" class="material-icons text-pink">girl</span>
                    </div>
                </div>

                <div class="mt-4">
                    <h5>Phụ huynh</h5>
                    <div class="mb-2">{{student.parents_name}}</div>
                    <div class="mb-2">{{student.parents_phone}}</div>
                </div>
                <div class="text-center">
                    <h4>

                    </h4>

                    <span class="badge-lg badge-default badge-pill">Số ảnh {{checkInDetailList.length}}</span>

                </div>
            </div>
            <div>
                <div *ngIf="student.checkin_type_status == 1">
                    <app-nodata *ngIf="checkInDetailList.length == 0" msg="Thầy/Cô chọn ngày bên trái để xem chi tiết hình ảnh"></app-nodata>
                    <app-nodata *ngIf="!isLoading && checkInDetailList.length == 0" img="./assets/img/icons/camera-student.png"></app-nodata>
                    <div *ngIf="isLoading" class="text-center">
                        <span class="spinner-border"> </span>
                    </div>
                </div>

                <app-nodata *ngIf="student.checkin_type_status == 0" msg="Học sinh chưa đăng ký điểm danh tự động">
                </app-nodata>



                <div *ngFor="let item of checkInDetailList; let i = index" class="checkInDetailList_item">
                    <img [src]="item.detected_image_url" alt="">
                    <div>{{item.checkin_time_hhmm}}</div>
                </div>
            </div>
        </div>

    </div>
</div>