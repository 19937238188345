<form class="">
    <h5 *ngIf="type=='create'" class="mat-dialog-title">Thêm mới thời khóa biểu</h5>
    <h5 *ngIf="type=='copy'" class="mat-dialog-title">Copy thời khóa biểu</h5>
    <h5 *ngIf="type=='update'" class="mat-dialog-title">Cập nhật thời khóa biểu</h5>
    <mat-dialog-content>
        <div class="p-2">
            <div class="row subtitle">
                <!-- {{cate.name}} -->
            </div>
            <div class="mt-3">
                <div class="row">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Tên TKB</mat-label>
                        <input matInput [(ngModel)]="cate.name" placeholder="Tên TKB" name="name" required minlength="2" maxlength="100">
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col">
                        <!-- <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput type="time" [(ngModel)]="cate.hockyI_startTime" placeholder="Bắt đầu" name="hockyI_startTime" required minlength="10" maxlength="10">
                        </mat-form-field> -->
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput [matDatepicker]="picker_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_startTime" name="hockyI_startTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_startTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_startTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết thúc</mat-label>
                            <input matInput [matDatepicker]="picker_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_endTime" name="hockyI_endTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_endTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_endTime></mat-datepicker>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row p-2">
                    <section class="mt-1 mb-3 weeks-checkbox">
                        <div class="mb-1">Ngày áp dụng</div>
                        <mat-checkbox *ngFor="let item of temp_weeks_update; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                    </section>
                </div>
                <div class="mb-2">Danh sách lớp áp dụng</div>
                <div>
                    <div *ngIf="categorys.length > 0" class="cont-checkbox">
                        <input type="checkbox" [id]="'myCheckbox-'" [name]="'myCheckbox-'" [(ngModel)]="checkboxAllButton" (change)="checkboxAll($event)" />
                        <label [for]="'myCheckbox-'">
                          <div class="info">Chọn lớp</div>
                          <div class="body text-center font-13">
                            Tất cả 
                           </div>
                        </label>
                    </div>
                    <div class="cont-checkbox" *ngFor="let item of categorys; index as i">
                        <input type="checkbox" [id]="'myCheckbox-' + i" [name]="'myCheckbox-' + i" [(ngModel)]="item.temp_checked" (ngModelChange)="onCheckboxChange(item)" />
                        <label [for]="'myCheckbox-' + i">
                                  <span class="cover-checkbox">
                                    <svg viewBox="0 0 12 10">
                                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                  </span>
                                  <div class="info">{{ item.name }}</div>
                                  <div class="body text-center  font-13">
                                    <span><i class="fa fa-address-book-o text-primary mb-0 mr-2"></i><span >{{item.student_count == null ? 0: item.student_count}}</span></span>
            
                                   </div>
                                </label>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-around">
        <button *ngIf="type=='copy' || type=='create'" (click)="insertCategory()" class="btn btn-primary btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label">
                <span class="material-icons">
                    save
                </span>
            </span>
            Lưu
        </button>
        <button *ngIf="type=='update'" (click)="updateCategory()" class="btn btn-primary btn-labeled btn-labeled-left btn-icon">
            <span class="btn-label">
                <span class="material-icons">
                    save
                </span>
            </span>
            Cập nhật
        </button>
        <button (click)="cancelClick()" class="btn">
            Đóng
        </button>
    </mat-dialog-actions>
</form>