
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { StudentService } from 'src/app/_services/student.service';
import { Student } from 'src/app/model/student';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { ArrangeDataComponent } from 'src/app/components/arrange-data/arrange-data.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';
import { StudentRegisterImageCameraComponent } from '../../student/student-register-image-camera/student-register-image-camera.component';
import { FinanceService } from 'src/app/_services/finance.service';
import { FinanceStudentReportComponent } from 'src/app/components/finance/finance-student-report/finance-student-report.component';
import { FinanceStudentPaymentComponent } from 'src/app/components/finance/finance-student-payment/finance-student-payment.component';
import { FinancePrintInvoiceComponent } from 'src/app/components/finance/finance-print-invoice/finance-print-invoice.component';
import { FinanceStudentSearchComponent } from 'src/app/components/finance/finance-student-search/finance-student-search.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

declare var $: any;
@Component({
  selector: 'app-finance-report',
  templateUrl: './finance-report.component.html',
  styleUrls: ['./finance-report.component.css']
})
export class FinanceReportComponent implements OnInit {

  isLoading = false;
  isLoadingSearch = false;
  studentList_exist = [];
  dateRange = []
  selected_finance_cate = "";
  selected_finance_cate_name = "";
  selected_finance_content_json = [];
  // class checkbox
  checkboxAllButton = true; // mặc định chọn all
  class_codeSelected: string = '';
  student_selected = '';

  // countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

  //for checkbox
  checkboxs_changeClass = [];
  //  checkboxAllButton = false;

  // for action
  action_mode = "";


  //for data
  classList = [];
  students = [];
  categorys: any
  facultys: any
  classs: any
  filter = {
    name: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    finance_cate_list: [],
    deleted: 0,
    category_code: "",
    status_text: ""
  }


  // for add
  student = new Student();
  fileteacherImg: File;
  imageUrl: any = environment.student_avatar;
  // for update
  studentSelected: any;
  isDeleted: boolean;
  studentSelected_imageUrl: any = environment.student_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  count_finance_cate = 0;
  sum_finance_value = 0;
  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;
  //status
  category_avatar = environment.category_avatar;


  kt_filter = {
    name: "",
    khoi_code: "",
    code_parent: "",
    deleted: 0,
    type: environment.cateType_finance
  }
  kt_sorter = {
    sortStr: this.appService.sort_category[2].code,
  }
  //for paging
  kt_viewNumsObj = this.appService.viewNums[1].Code;
  kt_curentPage = 1;
  kt_totalItemOnPage: any;
  kt_totalItems: any;
  kt_listPage: any = [];

  allSchools = {
    count_finance_cate: 0,
    sum_finance_value: 0,
    count_finance_cate_doing: 0,
    sum_finance_value_doing: 0,
    count_finance_cate_done: 0,
    sum_finance_value_done: 0
  }
  constructor(
    public appService: AppService,
    public studentService: StudentService,
    public financeService: FinanceService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public schoolService: SchoolService,
    public ioService: IoService,
    private toastr: ToastrService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Báo cáo khoản thu";
    //Xử lý cấp học.
    this.kt_filter.code_parent = appService.currentUser.name_alias;


    this.studentSelected = this.student;
    this.loadReportClassList();
    this.loadReportSchool();
    this.dateRange = utility.getMonthListInRange();
    
  }




  ngOnInit() {
    // this.loadStudent();
    this.loadClasss();
    //khoản thu
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  filter_change(item){
    console.log(item);
    this.filter.status_text = item
    if(this.filter.class_code !="" ){ //nếu đã chọn lớp thì mới load
      this.loadStudent();
    }
  }
  getHistoryTransaction() {
    let data = {
      fromDate: 'class_code',
      toDate: 'students'
    }
    this.schoolService.getHistoryTransaction(data).subscribe(
      async response => {
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  selectClass(item) {
    console.log(item);
    this.filter.class_code = item.code;
    this.loadStudent();
  }
  openDetail(item) {

    const dialogRef = this.dialog.open(FinanceStudentReportComponent, {
      disableClose: true,
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok" || result.isReload) {
        this.loadReportSchool();
        this.loadReportClassList();
        this.loadStudent();
      }
    });
  }
  paymentMulti(item){
    let student_code_list = [];
    // let data_total = 0;
    this.students.forEach(element => {
      if (element.temp_checked){
        student_code_list.push(element.code); //
        // data_total +=  element.finance_value;
      }
    });
    

    if(student_code_list.length == 0){
      this.toastr.warning("","Vui lòng chọn học sinh để thanh toán");
      return
    }

    var data = {
      student_code_list : student_code_list,
      finance_cate_id : item.id,
      finance_cate_name : item.name,
    }

    const dialogRef = this.dialog.open(FinanceStudentPaymentComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadReportSchool();
        this.loadReportClassList();
        this.loadStudent();
      }
    });
  }
  paymentRemind(){
    let student_code_list = [];
    // let data_total = 0;
    this.students.forEach(element => {
      if (element.temp_checked){
        student_code_list.push(element.code); //
        // data_total +=  element.finance_value;
      }
    });
    
    if(student_code_list.length == 0){
      this.toastr.warning("","Vui lòng chọn học sinh để gửi nhắc thanh toán");
      return
    }
    if(this.selected_finance_cate == ""){
      this.toastr.warning("","Vui lòng chọn khoản thu");
      return
    }
    

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Xác nhận gửi thông báo nhắc thanh toán  ?',
        msg: 'Học sinh đã chọn: ' + student_code_list.length,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result == "ok") {
        var data = {
          student_code_list : student_code_list,
          finance_cate_id : this.selected_finance_cate,
        }
        console.log(data);
        this.financeService.sendNotiPaymentRemind(data).subscribe(
          async response => {
            console.log(response.code);
            this.toastr.success("Học sinh: " + response.student + " PHHS: " + response.phhs, "Gửi thông báo thành công");
            if(response.code == 'ok'){
              this.loadReportSchool();
              this.loadReportClassList();
              this.loadStudent();
            }
          },
          error => {
            console.error("error:", error);
            this.toastr.warning("", "Đã có lỗi xảy ra, Vui lòng liên hệ CSKH");
          });
        
      }
    });

  }
  printMulti(){
    let student_code_list = [];
    this.students.forEach(element => {
      if (element.temp_checked){
        student_code_list.push(element.code); //
      }
    });
    if(student_code_list.length == 0){
      this.toastr.warning("","Vui lòng chọn học sinh");
      return
    }
    if(this.selected_finance_cate == ""){
      this.toastr.warning("","Vui lòng chọn khoản thu");
      return
    }

    var data = {
      student_code_list : student_code_list,
      finance_cate_list : [this.selected_finance_cate],
    }
    console.log(data);
    const dialogRef = this.dialog.open(FinancePrintInvoiceComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        
      }
    });
  }
  

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadReportClassList();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  };
  click_student(item) {
    if (this.student_selected == item.code)
      this.student_selected = '';
    else
      this.student_selected = item.code;

  }
  classRadioCheck(class_code) {
    console.log(class_code);
    // this.class_codeSelected = class_code;
    this.student_selected = ''; // chọn lớp thì reset student selected
    this.filter.class_code = class_code;
    this.loadReportClassList();
  }







  loadReportClassList() {
    this.isLoading = true
    let data = {
      khoi_code: this.filter.khoi_code,
      finance_cate_list: [this.selected_finance_cate],// đưa vào mảng vì có thể truyền nhiều id hơn
      cap_hoc: this.kt_filter.code_parent // cấp học
    }
    this.financeService.reportClassList(data).subscribe(
      async response => {
        this.classList = response;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      });
  }
  loadReportSchool() {
    this.isLoading = true

    let data = {
      khoi_code: this.filter.khoi_code,
      cap_hoc: this.kt_filter.code_parent,
      finance_cate_list: [this.selected_finance_cate] // đưa vào mảng vì có thể truyền nhiều id hơn
    }
    this.financeService.reportSchool(data).subscribe(
      async response => {
        if (response.length == 1)
          this.allSchools = response[0];
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      });
  }
  loadStudent() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // if(this.selected_finance_cate.length == 0){ // không có khoản thu nào được chọn,
    //   this.students = [];
    //   return;
    // }
    this.filter.finance_cate_list = [this.selected_finance_cate];
    // this.loadTotalPage();
    this.checkboxs_changeClass = [];
    this.checkboxAllButton = false;
    this.count_finance_cate = 0;
    this.sum_finance_value = 0;
    this.students = [];
    this.financeService.loadStudent(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.students = response.data;
          console.log(this.students);
          this.count_finance_cate = response.count_finance_cate;
          this.sum_finance_value = response.sum_finance_value;
          this.checkboxs_changeClass = new Array(this.students.length);

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.students.length;
          // for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;


        }
      },
      error => {

      })
  }

  checkboxAll(evt) {
    this.students.forEach(element => {
      element.temp_checked = evt.target.checked;
    });
  }
  checkboxAll_finance(evt) {
    this.categorys.forEach(element => {
      element.temp_checked = evt.target.checked;
    });
  }





  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected,
      cap_hoc: this.kt_filter.code_parent
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }




  change_finance_cate(event) {
    
    this.selected_finance_content_json = [];
    this.selected_finance_cate_name = ''

    let temp = this.categorys.find(item => item.id === this.selected_finance_cate);   //finance_content_json
    if(temp != undefined){
      this.selected_finance_content_json = temp.finance_content_json;
      this.selected_finance_cate_name = temp.name;
    } 
    
    console.log(this.selected_finance_cate);
    console.log(this.selected_finance_cate_name);
    console.log(this.selected_finance_content_json);
    this.filter.class_code = '';
    this.students=[];
    this.loadReportClassList();
    this.loadReportSchool()
  }

  category_khoi_change(event) {
    this.students=[];
    this.loadClasss();
    this.loadReportClassList();
  }
  category_change(event) {
    this.students=[];
    console.log(this.filter);
    this.loadReportClassList();

    this.loadStudent();
  }
  change_caphoc(event) {
    this.loadCategory();
    this.loadReportClassList();
    this.loadReportSchool();
  }
  sort_change(event) {
    this.loadReportClassList();
  }






  clearSearch() {
    this.filter.name = '';
    this.loadReportClassList();
  }
  
  confirmDownloadExcel(cap_code) {
    let data = {
      title: 'Tải báo cáo khoản thu',
      msg: 'Xác nhận tải.',
      ok : 'Tải xuống'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(cap_code);
        console.log("selected_finance_cate:",this.selected_finance_cate);
        
        if(this.selected_finance_cate == ''){ // Không chọn khoản thu thì xuất tất cả theo mẫu mặc định
          this.studentService.exportFinanceStudent(cap_code, this.selected_finance_cate, this.filter.status_text);
        }else{ // Chọn khoản thu thì xuất theo mẫu khoản thu
          this.studentService.exportFinanceAssignment(cap_code, this.selected_finance_cate, this.filter.status_text);
        }
      }
    });
  }

  search(){
    if(this.isLoadingSearch) return;
    console.log(this.filter.name);
    this.isLoadingSearch = true
    let data = {
      name: this.filter.name,
    }
    this.financeService.search(data).subscribe(
      async response => {
        console.log(response);
        this.isLoadingSearch = false;
        let data ={
          name: this.filter.name,
          data: response,
        }
        const dialogRef = this.dialog.open(FinanceStudentSearchComponent, {
          data: data
        });
      },
      error => {
        this.isLoadingSearch = false;
      });


  }








  kt_viewNumsObjChange() {
    this.kt_curentPage = 1;
    console.log(this.kt_viewNumsObj);
    this.loadCategory();
  }
  kt_pageChange = function (page) {
    if (page === 'prev' && this.kt_curentPage > 1) {
      this.kt_curentPage = this.kt_curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.kt_curentPage < this.kt_listPage.length) {
      this.kt_curentPage = this.kt_curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.kt_curentPage) {
      this.kt_curentPage = page;
      this.loadCategory();
    }
  };


  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.kt_viewNumsObj,
      pageObj: this.kt_curentPage
    };

    this.categoryeduService.loadCategory(this.kt_filter, this.kt_sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;

        // lặp để thêm json tạm
          // this.categorys.forEach(element => {
          //   element.finance_content_json = (element.finance_content != "" && element.finance_content != null) ? JSON.parse(element.finance_content) : [] ;
          // });

          // lặp để gán period_time
          response.data.forEach(element => {
            if (element.finance_frequency == 'Tháng') {
              element.period_time_str = "01/" + this.utility.getMonthStrFromDate(new Date());
            } else if (element.finance_frequency == 'Năm') {
              element.period_time_str = "01/01/" + new Date().getFullYear();
            }
          });


          this.kt_totalItems = response.totalItems;
          this.kt_totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.kt_listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.kt_listPage.push({ i: i });
          }
          // this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
}



