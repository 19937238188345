<div class="m-2">
    <h4 style="width: 350px;font-weight: bold;">Hướng dẫn thanh toán</h4>
    <div class="text-center"><strong>{{data.full_name}}</strong></div>
</div>
<mat-dialog-content>

    <div class="d-flex justify-content-between align-items-center finance-item">
        <div>Tháng {{data.period_time_str}}</div>
        <div><span style="font-weight: bold;">{{data.tamthu_total_amount | qbscurrency}}</span></div>
    </div>

    <div class="mt-3">
        <div [innerHTML]="paymentMethod.content" style="min-width: 300px; min-height: 300px;"></div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>