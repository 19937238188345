<div class="page-content fade-in-up">
    <ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">
        <li class="nav-item">
            <a class="nav-link active" href="#tab-schedule-list" data-toggle="tab" aria-expanded="true">
                <span class="badge badge-primary">1</span> <span class="ml-2 mr-2">Danh sách TKB</span>
                <!-- <span class="material-icons">calendar_month</span> -->
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-11-2" data-toggle="tab" aria-expanded="false">
                <span class="badge badge-primary">2</span><span class="ml-2 mr-2">Số tiết học</span>
                <!-- <span class="material-icons">123</span> -->
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-11-3" data-toggle="tab" aria-expanded="false">
                <span class="badge badge-primary">3</span><span class="ml-2 mr-2">Tạo ràng buộc</span>
                <!-- <span class="material-icons">fact_check</span> -->
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-xep-tkb" data-toggle="tab" aria-expanded="false" (click)="loadScheduleDetail()">
                <span class="badge badge-primary">4</span><span class="ml-2 mr-2">Sắp TKB</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#tab-11-5" data-toggle="tab" aria-expanded="false">
                <span class="badge badge-primary">5</span><span class="ml-2 mr-2">Xem TKB</span>
            </a>
        </li>
    </ul>
    <div class="col text-left">

        <div>TKB: <span *ngIf="checkboxs.length > 0 " class="font-weight-bold">{{categorys[checkboxsSelectedIndex].name}}</span></div>

    </div>
    <div class="tab-content">
        <div class="tab-pane fade text-center active show" id="tab-schedule-list" aria-expanded="true">
            <div class="row mb-2 justify-content-between">
                <div class="col">
                    <div class="d-flex justify-content-end">
                        <div class="p-2">
                            <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.loadCategory();" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_category">{{item.name}}</option>
                      </select>
                        </div>
                        <div class="p-2"> <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                      <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                        </div>
                    </div>
                </div>
            </div>






            <div class="table-responsive row">
                <table class="table table-head-purple table-hover" id="datatable">
                    <thead class="thead-default thead-lg">
                        <tr>
                            <th style="width: 50px; text-align: center;">#</th>
                            <th>Chọn cài đặt</th>
                            <th>Tên Thời khóa biểu</th>
                            <!-- <th>Ngày áp dụng</th> -->
                            <th>Hiệu lực</th>
                            <th class="no-sort">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of categorys; let i = index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                <!-- <label class="checkbox checkbox-primary check-single">
                                    <input class="mail-check" type="checkbox" [(ngModel)]="checkboxs[i]">
                                    <span class="input-span"></span>
                                </label> -->

                                <label class="radio radio-primary">
                                    <input type="radio" name="b" [value]="i"  [checked]="i == checkboxsSelectedIndex"  (change)="selectIndex(i)">
                                    <span class="input-span"></span>
                                </label>
                            </td>
                            <td>
                                <div style="font-size: 18px;">{{item.name}}</div>
                                <!-- <span>Mã: {{item.id}}</span> -->
                            </td>
                            <!-- <td>{{item.created_date}}</td> -->
                            <td>


                                <span *ngIf="!item.deleted" class="badge-primary badge-point" (click)='popupUpdate(item)'></span>
                                <span *ngIf="item.deleted" class="badge-default badge-point" (click)='popupUpdate(item)'></span>


                            </td>
                            <td>
                                <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    edit_calendar
                                    </span></button>
                                <!-- <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    clear
                                    </span></button> -->
                                <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    content_copy
                                    </span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row align-items-center mb-2">
                <div class="col-4 text-left">
                    <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                  {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
                </div>
                <div class="col-8 d-flex justify-content-end">
                    <ul class="pagination p-1" style="margin-bottom: 0px;">
                        <li>
                            <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                      <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                        </li>
                        <li class="page-item">
                            <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                                <span aria-hidden="true">
                        <i class="ti ti-angle-left"></i>
                      </span>
                            </a>
                        </li>
                        <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                            <a class="page-link" href="javascript:;">{{item.i}}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                                <i class="ti ti-angle-right"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="tab-pane fade text-center" id="tab-xep-tkb" aria-expanded="false">
            <div class="table-responsive row">
                <table class="table table-head-purple table-hover" id="datatable">
                    <thead class="thead-default thead-lg">
                        <tr>
                            <th style="width: 50px; text-align: center;">#</th>
                            <th>Chọn cài đặt</th>
                            <th>Tên Thời khóa biểu</th>
                            <!-- <th>Ngày áp dụng</th> -->
                            <th>Hiệu lực</th>
                            <th class="no-sort">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of scheduleDetailList; let i = index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>

                            </td>
                            <td>
                                <div style="font-size: 18px;">{{item.nameOfWeek}}</div>
                                <!-- <span>Mã: {{item.id}}</span> -->
                            </td>
                            <!-- <td>{{item.created_date}}</td> -->
                            <td>


                                <span *ngIf="!item.deleted" class="badge-primary badge-point" (click)='popupUpdate(item)'></span>
                                <span *ngIf="item.deleted" class="badge-default badge-point" (click)='popupUpdate(item)'></span>


                            </td>
                            <td>
                                <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    edit_calendar
                                    </span></button>
                                <!-- <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    clear
                                    </span></button> -->
                                <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><span class="material-icons">
                                    content_copy
                                    </span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane fade text-center" id="tab-11-3" aria-expanded="false">
            <!-- <div class="h1 mt-5 mb-3">TAB 3</div> -->
            <app-nodata msg="Tính năng bị giới hạn"></app-nodata>
        </div>
    </div>
</div>



<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới Thời Khóa Biểu</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col">
                        <div class="form-group ow">
                            <label class="col-form-label">Tên TKB</label>
                            <div class="">
                                <input [(ngModel)]="cate.name" class="form-control" name="name" required maxlength="30" placeholder="Tên tổ bộ môn" type="text">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <div class="form-group">
                            <label class="col-form-label">Ngày áp dụng</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="dd/mm/yyyy" name="d4" #c2="ngModel" [(ngModel)]="cate.created_date" ngbDatepicker #d4="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary ti ti ti-calendar" (click)="d4.toggle()" type="button">
                                        </button>
                                </div>
                            </div>

                        </div>
                    </div> -->
                </div>
            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid">
              <span class="btn-label" *ngIf="isLoading" ><span class="spinner-border"></span></span>
              <span class="btn-label" *ngIf="!isLoading" ><i class="ti ti-plus"></i></span>
                Thêm mới
            </button>

            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật Thời Khóa Biểu</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col">
                        <div class="form-group ow">
                            <label class="col-form-label">Tên</label>
                            <div class="">
                                <input [(ngModel)]="categoryselected.name" class="form-control" name="name" maxlength="30" placeholder="Tên tổ bộ môn" type="text">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Hiệu lực</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                            <input type="checkbox" [(ngModel)]="isDeleted" name="deleted">
                            <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
            <span class="btn-label" *ngIf="isLoading" ><span class="spinner-border"></span></span>
            <span class="btn-label" *ngIf="!isLoading" ><i class="ti ti-check"></i></span>
            Lưu
          </button>
            </div>
        </form>
    </div>
</div>