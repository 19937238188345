import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { AppService } from 'src/app/_services/app.service';
import { MealService } from 'src/app/_services/meal.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-meal-student-detail-month',
  templateUrl: './meal-student-detail-month.component.html',
  styleUrls: ['./meal-student-detail-month.component.css']
})
export class MealStudentDetailMonthComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  
  checkinDate = [];
  attention_count = 0;
  isLoading = false;

  dateSelected: Date | null;
  constructor(
    public appService: AppService,
    private mealService: MealService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MealStudentDetailMonthComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportMealClassDetailMonth();
    this.dateSelected = this.utilityService.getDateFromDDMMYYY(this.data.dateSelected);
    console.log(this.dateSelected);
  }

  ngOnInit(): void {

  }

  loadReportMealClassDetailMonth() {

    let data = {
      student_code: this.data.data.code,
      dateSelected: this.data.dateSelected,
    };
    this.isLoading = true;
    this.mealService.loadMealStudentMonthDetail(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          this.checkinDate = response;
          //update calender9
        this.calendar.updateTodaysDate();
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {

      let date_temp = this.utilityService.getFullDateStrFromDate(date);

      let result="";
      this.checkinDate.forEach(element => {
          if(date_temp == element.dateStr){
              if(element.student_register == 1){
                if(element.student_attention == 1){
                    result = 'date-meal-attention';
                }else{
                    result = 'date-meal-inattention';
                }
                return;
              }
          }
      });
      return result;
    };
  }

}
