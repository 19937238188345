import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { SchoolService } from 'src/app/_services/school.service';
import { AssessmentService } from 'src/app/_services/assessment.service';

declare var $: any;
@Component({
  selector: 'app-assessment-setting',
  templateUrl: './assessment-setting.component.html',
  styleUrls: ['./assessment-setting.component.css']
})
export class AssessmentSettingComponent implements OnInit {

  isLoading = false;

  khoisIndexSelected = -1;

  // môn cho toàn khối
  subjectss: any; // để lắp trong ts
  subjectss_clone: any; // để lặp tring html
  //môn cho từng khối setting
  subjectss_khoi = [];
  //môn cho toàn khối setting
  subjectss_khoi_all: any;

  //for paging
  viewNumsObj = this.appService.viewNums[4].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  searching = false;

  sorter = {
    sortStr: this.appService.sort_category[4].code,
  }

  filter = {
    name: "",
    khoi: "",
    type: environment.cateType_subjects
  }

  // load setting
  typeSettings = [ { 'code': 'tx_num', 'name': 'Số điểm thường xuyên' },{ 'code': 'lockMark', 'name': 'Khoá/Mở nhập điểm' }];
  typeSettingsSelected = this.typeSettings[0];
  constructor(
    public assessmentService: AssessmentService,
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    public utilityService: UtilityService,
    private schoolService: SchoolService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Cấu hình số điểm TX";
    // this.countAllCategory();
    this.loadAllKhoiSetting();
    this.loadSubjects([]);
    console.log("constructor");
  }

  ngOnInit() {
    console.log("ngOnInit");

  }


  hocky_change(hocky) {
    if(this.appService.hockySelected != hocky){
      this.appService.hockySelected = hocky;
      // lưu vào storage
      localStorage.setItem(environment.hockySelected, this.appService.hockySelected);
      // xủ lý với lớp
      this.khoisIndexSelected = -1;
      this.loadAllKhoiSetting();
      this.loadSubjects([]);
    }
  }


  khois_change(i) {
    //có tác dụng khi đang ở tx_num
      if (this.khoisIndexSelected != i) {
        this.khoisIndexSelected = i;
        if (this.khoisIndexSelected > -1) {// khối lẻ
          this.loadKhoiSetting();
        } else { // toàn khối
          this.loadAllKhoiSetting();
          this.loadSubjects([]);
        }
      }
  }
  selectTypeSetting(item) {
    this.typeSettingsSelected = item;
    this.khoisIndexSelected = -1;
  }
  toggleIsLock(item_subjectss, item_name, item_value) {
    console.log(item_subjectss);
    if (item_value == 0) item_subjectss[item_name] = 1;
    else item_subjectss[item_name] = 0;
    console.log(item_subjectss);
  }
  toggleKhoiIsLock(khoi_code) {
    console.log(khoi_code);
    //lặp để lấy giá trị đầu tiên để toggle
    let flag_temp = 1;
    this.subjectss_khoi_all.forEach(subject => {
      if (subject.khoi == khoi_code) {
        subject.isLock = subject.isLock == 0 ? 1 : 0;
        flag_temp = subject.isLock;
      }
    });
    console.log("flag_temp", flag_temp);
    //lap qua
    this.subjectss.forEach(element => {
      //lap qua cac mon
      this.subjectss_clone.forEach(subject => {
        element['lockMark'+'__'+this.utilityService.checkAndGetIDSetting(khoi_code, subject.code, this.subjectss_khoi_all) + '__' + khoi_code + '__' + subject.code] = flag_temp;
        //cập nhật cho subjectss_clone
        if (subject.khoi == khoi_code) {
          subject.isLock = flag_temp;
        }

      });
    });
  }
  //load toàn bộ môn học
  loadSubjects(tx_numSettings) {
    console.log("tx_numSettings");
    console.log(tx_numSettings);
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          if (response.data !== undefined) {
            this.subjectss = [];
            this.subjectss_clone = response.data; //dùng để lặp trên html

            // Cài đặt số liệu tx_num (va lockMark) cho khối và môn
            response.data.forEach((subject, i) => {
              let subject_temp = {};
              subject_temp['subjects_code'] = subject.code;
              subject_temp['subjects_name'] = subject.name;

              this.appService.currentUser.khois.forEach(khoi => {
                let id_temp = this.utilityService.checkAndGetIDSetting(khoi.code, subject.code, this.subjectss_khoi_all); // lấy id
                subject_temp['khoi' + '__' + id_temp +'__'+khoi.code + '__' + subject.code] = this.utilityService.checkAndGetTx_numSetting(khoi.code, subject.code, this.subjectss_khoi_all); // 4 là mặc định;
                subject_temp['lockMark' + '__' + id_temp +'__'+khoi.code + '__' + subject.code] = this.utilityService.checkAndGetisLockSetting(khoi.code, subject.code, this.subjectss_khoi_all); // 4 là mặc định;
              });

              this.subjectss.push(subject_temp);
            });
            console.log("this.subjectss");
            console.log(this.subjectss);
          }
        }
      },
      error => {
        console.error(error);
      })
  }
  loadAllKhoiSetting() {
    let data = {
      "hocky": this.appService.hockySelected,
      "khoi": '', // truyền '' là lấy tất cả khối
    };
    this.isLoading = true;
    this.assessmentService.loadAssessmentSetting(data).subscribe(
      response => {
        this.isLoading = false;
        console.log("subjectss_khoi_all", response);
        this.subjectss_khoi_all = response;
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })

  }

  save() {
    if (this.typeSettingsSelected.code == 'lockMark') {
        if (this.khoisIndexSelected == -1) { // toàn khối
          console.log(this.subjectss);
          let data = {}
          data['hocky'] = this.appService.hockySelected;
          data['subjectss'] = this.subjectss;
          console.log(data);
          this.isLoading = true;
          this.assessmentService.saveAssessmentSettingLockMark(data).subscribe(
            response => {
              this.isLoading = false;
              if (response.code == "ok" && response.affectedRows > 0) {
                this.toastr.success("", "Cập nhật thông tin thành công");
              }
            },
            error => {
              this.isLoading = false;
              this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
              console.error(error);
            });
        }else{// Riêng lẽ từng khối
          console.log(this.subjectss_khoi);

          this.isLoading = true;
          this.assessmentService.updateAssessmentSettingLockMark(this.subjectss_khoi).subscribe(
            response => {
              this.isLoading = false;
              if (response.code == "ok" && response.affectedRows > 0) {
                this.toastr.success("", "Cập nhật thông tin thành công");
              }
            },
            error => {
              this.isLoading = false;
              this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
              console.error(error);
            });
        }

    } else if (this.typeSettingsSelected.code == 'tx_num') {
      // toàn khối
      if (this.khoisIndexSelected == -1) {
        console.log(this.subjectss);
        let data = {}
        data['hocky'] = this.appService.hockySelected;
        data['subjectss'] = this.subjectss;

        this.isLoading = true;
        this.assessmentService.saveAssessmentSetting(data).subscribe(
          response => {
            this.isLoading = false;
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("", "Cập nhật thông tin thành công");
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
            console.error(error);
          });
      }
      //riêng từng khối
      else {
        console.log(this.subjectss_khoi);

        this.isLoading = true;
        this.assessmentService.updateAssessmentSetting(this.subjectss_khoi).subscribe(
          response => {
            this.isLoading = false;
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("", "Cập nhật thông tin thành công");
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
            console.error(error);
          });
      }
    }
    // if (this.khoisIndexSelected == -1) { // Toàn khối
    //   var temp = JSON.stringify(this.appService.currentUser.khois);// chuyển sjson sang string để đẩy lên server

    //   this.isLoading = true;
    //   this.schoolService.updateSchoolSomeInfo({ "khois": temp }).subscribe(
    //     response => {
    //       this.isLoading = false;
    //       if (response.code == "ok" && response.affectedRows > 0) {
    //         // this.appService.currentUser.khois = temp;
    //         localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
    //         console.log(this.appService.currentUser);
    //         this.toastr.success("Cập nhật thông tin thành công",);
    //       }
    //       else {
    //         this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
    //       }
    //     },
    //     error => {
    //       this.isLoading = false;
    //       this.toastr.error("Có lỗi khi tải dữ liệu cài đặt đành giá", "");
    //       console.error(error);
    //     })
    // }
    // else { // khối riêng lẻ
    //   let data = {
    //     "hocky": this.appService.hockySelected,
    //     "khoi": this.appService.currentUser.khois[this.khoisIndexSelected].code,
    //     "tx_num": this.appService.currentUser.khois[this.khoisIndexSelected].tx_num,
    //     "subjecttx_num": JSON.stringify(this.subjectss), // chuyển sjson sang string để đẩy lên server
    //   };
    //   console.log(data);
    //   this.isLoading = true;
    //   this.assessmentService.saveAssessmentSetting(data).subscribe(
    //     response => {
    //       this.isLoading = false;
    //       if (response.code == "ok" && response.affectedRows > 0) {

    //         this.toastr.success("Cập nhật thông tin thành công",);
    //       }
    //       else {
    //         this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
    //       }
    //     },
    //     error => {
    //       this.isLoading = false;
    //       this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
    //       console.error(error);
    //     });
    // }
  }
  loadKhoiSetting() {

    if (this.khoisIndexSelected == -1) { // Toàn khối

    }
    else { // khối riêng lẻ
      let data = {
        "hocky": this.appService.hockySelected,
        "khoi": this.appService.currentUser.khois[this.khoisIndexSelected].code,
      };
      this.isLoading = true;
      this.assessmentService.loadAssessmentSetting(data).subscribe(
        response => {
          this.isLoading = false;
          console.log(response);
          this.subjectss_khoi = response
        
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })
    }
  }

  ngAfterViewInit() {

  }
}
