<div class="page-content fade-in-up">

  <div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
      <div class="d-flex justify-content-start">
        <div class=" p-2 input-group-icon input-group-icon-left mr-3">
          <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
          <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
          <input id="typeahead-template" class="form-control input-lg" [(ngModel)]="filter.name" [ngbTypeahead]="search"
            type="text" placeholder="Tìm sản phẩm" [resultTemplate]="rt" [inputFormatter]="formatter">
        </div>
        <ng-template #rt let-r="result" let-t="term">
          <div class="row" (click)="onClick(r)" style="width: 450px;">
            <div class="img-product-search ml-1"
              [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
            <div class="col"> #{{ r['code'] }}</div>
            <div class="col">
              <ngb-highlight [result]="r.name" [term]="t">
              </ngb-highlight>
            </div>
            <div class="col">Kho: {{ r['stock'] == null ? 0: r['stock'] }}</div>
            <div class="col">DM: {{ r['category_name'] }}</div>
            <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
          </div>
        </ng-template>

        <div class="p-2">
          <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)"
            name="category" id="category">
            <option value="">Danh mục</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
          </select>
        </div>
        <div class="p-2">
          <select class="custom-select" [(ngModel)]="filter.category_code_brand" (change)="category_change($event)"
            name="category" id="category">
            <option value="">Thương hiệu</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
          </select>
        </div>
        <div class="p-2">
          <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)"
            name="sorter" id="sorter">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-end">
        <div class="p-2">
          <button class="blurButton btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon"
            (click)="popup_product_add()">
            <span class="btn-label"><i class="ti ti ti-plus"></i></span>Thêm mới
          </button>
        </div>
        <div class="p-2">
          <!-- <div class="dropdown">
            <button class="btn btn-warning btn-labeled btn-labeled-left btn-sm btn-icon"
              data-toggle="dropdown">
              <span class="btn-label"><i class="ti ti-trash"></i></span>
              Thêm
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" style="font-size: larger;">
                <i class="ti ti-trash"></i>Xác nhận hủy đơn hàng</a>
            </div>
          </div> -->
          <div ngbDropdown class="d-inline-block">
            <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Thêm</button> -->

            <button class="blurButton btn btn-sm btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
            <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem  (click)="popup_product_import()"><i class="ti ti-upload"></i> Import</button>
              <button ngbDropdownItem (click)="popup_product_export()"><i class="ti ti-download"></i> Download</button>
              <!-- <button ngbDropdownItem>Something else is here</button> -->
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="row align-items-center mb-2">
    <div class="col-4">
      <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
      <ul class="pagination p-1" style="margin-bottom: 0px;">
        <li>
          <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
            (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
            <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
          </a>
        </li>
        <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
          [ngClass]="{'active' : item.i == curentPage}">
          <a class="page-link" href="javascript:;">{{item.i}}</a>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
            <i class="ti ti-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="table-responsive row">
    <table class="table table-head-purple table-hover" id="datatable">
      <thead class="thead-default thead-lg">
        <tr>
          <th style="width: 50px; text-align: center;">#</th>
          <th>Tên hàng</th>
          <th>Danh mục</th>
          <th>Tồn kho</th>
          <th>Đơn vị</th>
          <th class="text-right">Giá</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of appService.products; let i = index">
          <td style=" text-align: center; padding: 0.5rem;">
            <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;"
              [style.background-image]="appService.showImageProduct(item.image_url)"></a>
          </td>
          <td>
            <div style="font-size: 18px;">{{item.name}}</div>
            <div  [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
              {{item.code}}
            </div>
          </td>
          <td>{{item.category_name}}</td>
          <td><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
          <td>{{item.unit}}</td>
          <td class="text-right">{{item.price| number:0}}</td>

          <td>
            <!-- btn-outline-primary -->
            <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick"
              (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
          </td>
        </tr>
        <tr>
          <td colspan="6">

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row align-items-center mb-2 paging-cover">
    <div class="col-4">
      <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
      <ul class="pagination p-1" style="margin-bottom: 0px;">
        <li>
          <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
            (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
            <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
          </a>
        </li>
        <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
          [ngClass]="{'active' : item.i == curentPage}">
          <a class="page-link" href="javascript:;">{{item.i}}</a>
        </li>
        <li class="page-item">
          <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
            <i class="ti ti-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="modal fade " id="add-product-modal">
  <div class="modal-dialog " role="document">
    <!-- (ngSubmit)="insertProduct()" -->
    <form class="modal-content modal-content-lg form-horizontal" #productAddForm="ngForm" id="form-add-product" 
      >
      <div class="modal-header p-4">
        <h5 class="modal-title">Thêm mới hàng hóa</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-sm-9">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Tên</label>
              <div class="col-sm-10">
                <input [(ngModel)]="product.name" class="form-control" name="name" required maxlength="100"
                  placeholder="Tên hàng hóa" type="text">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Mã</label>
              <div class="col-sm-10">
                <input [(ngModel)]="product.code" class="form-control" readonly minlength="3" maxlength="20"
                  [readonly]="'mts' === productCodeType || 'qmv' === productCodeType" name="code" type="text"
                  (ngModelChange)="product.code = $event.toUpperCase()" (focusout)="focusOutCheckCode()"
                  (keydown.space)="$event.preventDefault();">
                <span class="help-block">
                  <div class="mt-2">
                    <label class="radio radio-grey radio-primary radio-inline">
                      <input type="radio" name="b" [checked]="'mts' === productCodeType"
                        (click)="productCodeTypeClick('mts');">
                      <span class="input-span"></span>Mã tự sinh</label>
                    <label class="radio radio-grey radio-primary radio-inline">
                      <input type="radio" name="b" [checked]="'qmv' === productCodeType"
                        (click)="productCodeTypeClick('qmv');">
                      <span class="input-span"></span>Quét mã vạch</label>
                    <label class="radio radio-grey radio-primary radio-inline">
                      <input type="radio" name="b" [checked]="'tn' === productCodeType"
                        (click)="productCodeTypeClick('tn');">
                      <span class="input-span"></span>Tự nhập</label>
                  </div>
                </span>
              </div>
            </div>


          </div>
          <div class="col">
            <div class="flexbox-b">

              <div class="image-upload">
                <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                </div>
                <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File"
                  (change)="avatarChangeListener($event)">
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Giá bán</label>
              <div class="">
                <input currencyMask [(ngModel)]="product.price" class="form-control" name="price" placeholder="Giá bán"
                  type="text">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Giá nhập</label>
              <div class="">
                <input [(ngModel)]="product.price_in" currencyMask class="form-control" numbersOnly name="price-in"
                  placeholder="Giá nhập" type="text">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group ">
              <label class="col-form-label">Danh mục</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="product.category_code" name="category">
                  <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group ">
              <label class="col-form-label">Thương hiệu</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="product.category_code_brand" name="categoryBrand">
                  <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Đơn vị</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="product.unit" name="unit">
                  <option [ngValue]="item.code" *ngFor="let item of appService.units">{{item.value}}</option>
                </select>
              </div>

            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Quy cách</label>
              <input [(ngModel)]="product.packing" class="form-control" name="description" placeholder="Quy cách"
                type="text">
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class=" col-form-label">Mô tả</label>
          <div class="">
            <input [(ngModel)]="product.description" class="form-control" name="description" placeholder="Mô tả"
              type="text">
            <!-- <textarea class="form-control" [(ngModel)]="product.description" rows="3" placeholder="Mô tả"></textarea> -->
          </div>
        </div>
      </div>

      <div class="modal-footer justify-content-around bg-primary-50">
        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon"
          [disabled]="isLoading || this.productAddForm.invalid" (click)="insertProduct()">
          <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
          <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
          Thêm mới
        </button>


        <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
      </div>
    </form>
  </div>
</div>








<div class="modal fade" id="update-product-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content modal-content-lg form-horizontal" #productUpdateForm="ngForm" id="form-update-product"
      (ngSubmit)="updateProduct()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Cập nhật hàng hóa</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-sm-9">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Tên</label>
              <div class="col-sm-10">
                <input [(ngModel)]="productSelected.name" class="form-control" name="name" maxlength="150"
                  placeholder="Tên hàng hóa" type="text">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Mã</label>
              <div class="col-sm-10">
                <input [(ngModel)]="productSelected.code" class="form-control" readonly minlength="3" maxlength="20"
                  name="code" type="text">
              </div>
            </div>


          </div>
          <div class="col">
            <div class="flexbox-b">
              <div class="image-upload">
                <div class="file-input" [style.background-image]="'url('+productSelected_imageUrl+')'"> </div>
                <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFileUpdate"
                  name="uploadFileUpdate" (change)="avatarChangeUpdateListener($event)">
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Giá bán</label>
              <div class="">
                <input currencyMask [(ngModel)]="productSelected.price" class="form-control" name="price"
                  placeholder="Giá bán" type="text">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Giá nhập</label>
              <div class="">
                <input [(ngModel)]="productSelected.price_in" currencyMask class="form-control" numbersOnly
                  name="price-in" placeholder="Giá nhập" type="text">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group ">
              <label class="col-form-label">Danh mục</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="productSelected.category_code" name="category">
                  <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group ">
              <label class="col-form-label">Thương hiệu</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="productSelected.category_code_brand" name="categoryBrand">
                  <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="col-form-label">Đơn vị</label>
              <div class="">
                <select class="custom-select" [(ngModel)]="productSelected.unit" name="unit">
                  <option [ngValue]="item.code" *ngFor="let item of appService.units">{{item.value}}</option>
                </select>
              </div>

            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class=" col-form-label">Quy cách</label>
              <input [(ngModel)]="productSelected.packing" class="form-control" name="packing" placeholder="Quy cách"
                type="text">
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class=" col-form-label">Mô tả</label>
          <div class="">
            <input [(ngModel)]="productSelected.description" class="form-control" name="description" placeholder="Mô tả"
              type="text">
            <!-- <textarea class="form-control" [(ngModel)]="product.description" rows="3" placeholder="Mô tả"></textarea> -->
          </div>
        </div>
        <div class="form-group text-center">
          <label class="checkbox checkbox-grey checkbox-primary">
            <input type="checkbox" [(ngModel)]="isDeleted" name="timeService">
            <span class="input-span"></span>Xóa
          </label>
        </div>

      </div>
      <div class="modal-footer justify-content-around bg-primary-50">
        <!-- <button class="btn btn-primary" [disabled]="isLoading || this.productUpdateForm.invalid">Cập nhật</button> -->

        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon"
          [disabled]="isLoading || this.productUpdateForm.invalid">
          <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
          <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
          Cập nhật
        </button>

        <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
      </div>
    </form>
  </div>
</div>






<div class="modal fade" id="import-product-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" id="form-import-product">
      <div class="modal-header p-4">
        <h5 class="modal-title">Nhập hàng hóa từ file Excel</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">

        <div *ngIf="!import_result.result">
          <div class="form-group mb-4">
            <label class="btn btn-primary  mr-2" style="width: 100%;">
              <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
              <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                (change)="changeListener($event)">
            </label>
          </div>
          <div class="form-group mb-4">
            <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel</a>
          </div>
          <div class="form-group mb-4">
            <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống</div>
          </div>
        </div>

        <div *ngIf="import_result.result">
          <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
          <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
        </div>

      </div>
      <div class="modal-footer justify-content-center bg-primary-50">

        <div class="row align-items-center">
          <div class="col text-center">

            <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon"
             (click)="upload()" [disabled]="isLoading">
              <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
              <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
            Import</button>

            <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>