<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">
        <div style="font-size: x-large;">Soạn nội dung</div>
        <div class="mb-2">Thầy cô chọn lớp để tạo nhật ký</div>
        <app-nodata *ngIf="categorys.length == 0" msg="Tính năng nhật ký lớp học chỉ dành cho GVCN" img="./assets/img/icons/puzzle-game.png"></app-nodata>
        <div *ngFor="let item of categorys; index as i" class="cont-checkbox-cover">
            <div class="cont-checkbox">
                <input type="radio" [id]="'myCheckbox-' + i" [name]="'myCheckbox-' + i" [value]="item.code" [(ngModel)]="class_selected" />
                <label [for]="'myCheckbox-' + i">
                      <!-- <img [src]="car.img" /> -->
                      <span class="cover-checkbox">
                        <svg viewBox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <div class="info">{{ item.name }}</div>
                      <div class="body text-center  font-13">
                        <!-- {{item.student_count == null ? 0: item.student_count}} 
                        <span class="font-15 text-primary material-icons">people</span> -->

                        <span><i class="fa fa-address-book-o text-primary mb-0 mr-2"></i><span >{{item.student_count == null ? 0: item.student_count}}</span></span>

                       </div>
                    </label>
            </div>
        </div>

        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussion.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Tiêu đề</mat-label>
            <input matInput [(ngModel)]="discussion.title" name="title" maxlength="100" required placeholder="Tiêu đề">
        </mat-form-field>

        <mat-form-field class="example-full-width mb-2" appearance="fill">
            <mat-label>Nội dung</mat-label>
            <textarea matInput [(ngModel)]="discussion.message" rows="5" name="messenger" required maxlength="1000" placeholder="Nội dung"></textarea>
        </mat-form-field>

        <!-- <div class="file-input" [style.background-image]="'url('+imageUrl+')'">

        </div> -->
        <div class="mt-3 mb-3 text-center"><img class="img-circle2" [src]="imageUrl" alt="image" style="max-height: 200px;" /></div>


        <div class="filettach-upload mb-3">
            Đính kèm file:
            <input type="file" accept="image/jpeg, image/png, video/mp4,video/x-m4v,video/* " id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
        </div>

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-between ">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.sendMessengerForm.invalid" (click)="confirmSendMessenger()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">
                        send
                        </span></span>
                    Đăng nhật ký
                </button>
            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>