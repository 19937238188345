<div class="m-3">
    <h4>Kết quả tìm kiếm <strong>{{data.name}}</strong></h4>
    <div>

        <div *ngIf="dataList.length == 0" class="text-center">
            <app-nodata msg="Không tìm thấy kết quả"></app-nodata>
        </div>
        <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Code</th>
                        <th>Khoản thu</th>
                        <th class="text-center">Tần xuất</th>
                        <th style="text-align: center">Kỳ thu</th>
                        <th>Giá trị</th>
                        <th class="text-right">Đã thanh toán</th>
                        <th class="text-center">Thời gian</th>
                        <th class="text-center">Hình thức</th>
                        <!-- <th class="text-center">Phút tính sơm</th>
                        <th class="text-center">Phút tính muộn</th> -->
                        <th style="width: 86px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of dataList; let i = index">
                        <tr *ngIf="item.code_bidv_merge == ''" [ngClass]="{'row-disabled': item.temp_checked_origin == 0, 'row-checked' : item.temp_checked, 'row-paid' : item.status == 2}">
                            <td>
                                <a *ngIf="item.temp_checked_origin == 1 && item.status < 2" class="avatar-img-list" (click)="showQRcode(item)" href="javascript:;">
                                    <span style="font-size: 32px;" class="material-icons"> qr_code_2  </span>
                                </a>
                                <img *ngIf="item.temp_checked_origin == 1 && item.status == 2" class="img-circle mt-1" src="./assets/img/icons/check-mark.png" alt="image" width="30" />
                            </td>
                            <td>
                                <div style="font-size: 18px; font-weight: bold;">{{item.code_bidv}}</div>
                            </td>

                            <td>
                                <div style="font-size: 18px;">{{item.name}}</div>


                            </td>
                            <td style="text-align: center">{{item.finance_frequency}}</td>
                            <td style="text-align: center">
                                <div *ngIf="item.finance_frequency == 'Tháng'">
                                    {{item.period_time_str}}
                                </div>
                            </td>
                            <td class="text-right">
                                <div style="font-size: 18px; font-weight: bold;">{{item.finance_value| qbscurrency}}</div>
                            </td>
                            <td class="text-right " [ngClass]="{'text-primary': item.payment_value > 0}">
                                <div style="font-size: 18px; font-weight: bold;">{{item.payment_value| qbscurrency}}</div>
                            </td>
                            <td class="text-center">
                                <div style="max-width: 90px; font-size: small;">{{item.payment_time_str}}</div>
                            </td>
                            <td class="text-center">
                                {{item.payment_type_str}}
                            </td>
                            <td class="text-center">
                                <label *ngIf="item.temp_checked_origin == 1 && item.status < 2" class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.temp_checked">
                                <span class="input-span"></span>
                            </label>
                                <ng-container *ngIf="item.temp_checked_origin == 1 && item.status == 2">
                                    <button (click)="refund(item)" class="btn btn-outline-secondary btn-icon-only btn-sm mr-2" placement="top" ngbTooltip="Hoàn thu"><span class="material-icons"> restore </span></button>
                                    <button (click)="print(item)" class="btn btn-outline-primary btn-icon-only btn-sm" placement="top" ngbTooltip="In phiếu thu"><span class="material-icons"> print </span></button>
                                </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="item.code_bidv_merge != ''" [ngClass]="{'row-disabled': item.temp_checked_origin == 0, 'row-checked' : item.temp_checked, 'row-paid' : item.status == 2}">
                            <td>
                                <a *ngIf="item.temp_checked_origin == 1 && item.status < 2" class="avatar-img-list" (click)="showQRcode(item)" href="javascript:;">
                                    <span style="font-size: 32px;" class="material-icons"> qr_code_2  </span>
                                </a>
                                <img *ngIf="item.temp_checked_origin == 1 && item.status == 2" class="img-circle mt-1" src="./assets/img/icons/check-mark.png" alt="image" width="30" />
                            </td>
                            <td>
                                <div style="font-size: 18px; font-weight: bold;" class="text-info">{{item.code_bidv_merge}}</div>
                            </td>

                            <td>
                                <div style="font-size: 18px;">{{item.merge_name}}</div>
                            </td>
                            <td style="text-align: center"></td>
                            <td style="text-align: center">
                            </td>
                            <td class="text-right">
                                <div style="font-size: 18px; font-weight: bold;">{{item.merge_value| qbscurrency}}</div>
                            </td>
                            <td colspan="3" class="text-center text-info">
                                Khoản thu đã gộp
                            </td>

                            <td class="text-center">
                                <button (click)="unMergeFinance(item)" class="btn btn-outline-info btn-icon-only btn-sm" placement="top" ngbTooltip="Hủy gộp"><span class="material-icons"> link </span></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="isLoading" class="text-center m-2">
            <span class="spinner-border spinner-lg"></span>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="mergeFinance()">
                <span class="btn-label"><span class="material-icons">link</span></span>Gộp khoản thu
            </button>
            <button class="ml-2 blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Thanh toán
            </button>
            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
            </button>
        </div>
    </div>
</div>