<form class="">
    <div class="p-2">
        <div class="modal-header p-2">
            <h5 class="modal-title">{{data.title}}</h5>
        </div>
        <div class="modal-body p-2 mt-3 mb-3">

            <div *ngIf="datas.length > 0">
                <div *ngFor="let item of datas; let i = index" class="item-card" (click)="indexSelected =i" [ngClass]="{'classSelected' : indexSelected == i}">
                    <img class="img-circle" src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                    <div>{{item.name}}</div>
                </div>
            </div>
            <div *ngIf="datas.length == 0">
                <mat-form-field style="width: 450px;" class="example-full-width mb-2" appearance="fill">
                    <mat-label>Nhận xét</mat-label>
                    <input matInput [(ngModel)]="data_input" placeholder="Nhập nhận xét" name="name" required maxlength="500">
                </mat-form-field>
            </div>

        </div>
    </div>
    <div class="modal-footer justify-content-around bg-primary-50">


        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
            <span class="btn-label"><span class="material-icons">done</span></span>{{data.ok != null ? data.ok : 'Xác nhận' }}
        </button>
        <button class="ml-2 blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
        </button>


    </div>
</form>