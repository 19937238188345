import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarHeader } from '@angular/material/datepicker';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker/calendar-body';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-checkin-student-detail',
  templateUrl: './checkin-student-detail.component.html',
  styleUrls: ['./checkin-student-detail.component.css']
})
export class CheckinStudentDetailComponent implements OnInit {
  // selected: Date | null;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  renderer: any;//handle month change

  student :any;
  dateSelected = "";
  selectedDate_date : Date;
  checkinDate = [];
  checkInDetailList = [];
  isLoading = false;
  constructor(
    public appService : AppService,
    public utilityService : UtilityService,
    public schoolService : SchoolService,
    public dialogRef: MatDialogRef<CheckinStudentDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.student = data.data;
    this.dateSelected = data.dateSelected;
    this.selectedDate_date = utilityService.getDateFromDDMMYYY(this.dateSelected);
    console.log(this.student);
    this.reportcheckinStudentDetail();
    this.loadCheckInDetail();
  }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    setTimeout(() => {
      const prev = document.querySelector('.mat-calendar-previous-button');
      const next = document.querySelector('.mat-calendar-next-button');
      prev.addEventListener('click', (event) => { // Previous Button
        console.log('Prev',this.selectedDate_date);
        console.log('event',event);
        this.prevMonthSelected();
      });
      next.addEventListener('click', (event) => { // Next Button
        console.log('next',this.selectedDate_date);
        console.log('event',event);
        this.nextMonthSelected();
      });
    }, 150);
  }

  onSelect(event){
    console.log(event);
    this.dateSelected = this.utilityService.getFullDateStrFromDate(event);
    console.log(this.dateSelected);
    this.loadCheckInDetail();
  }
  loadCheckInDetail(){
    
    var data ={
      student_code : this.student.code,
      dateSelected : this.dateSelected
    }
    this.checkInDetailList = [];
    this.isLoading = true;
    this.schoolService.checkinStudentDayDetail(data).subscribe(
      async response => {
        this.isLoading = false;
        this.checkInDetailList = response;
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });

  }
  nextMonthSelected() {
    this.selectedDate_date= new Date(this.selectedDate_date.setMonth(this.selectedDate_date.getMonth()+1));
    this.reportcheckinStudentDetail();
    this.loadCheckInDetail();
    //handler logic
  }
  prevMonthSelected() {
    this.selectedDate_date= new Date(this.selectedDate_date.setMonth(this.selectedDate_date.getMonth()-1));
    this.reportcheckinStudentDetail();
    this.loadCheckInDetail();
    //handler logic
  }
  handleMonthSelected(event) {
    console.log("Month changed", event);
    this.selectedDate_date= event;
    this.reportcheckinStudentDetail();
    //handler logic
  }
  reportcheckinStudentDetail() {
    this.dateSelected = this.utilityService.getFullDateStrFromDate(this.selectedDate_date);
    let data = {
      student_code: this.student.code,
      dateSelected: this.dateSelected,
      user_type: 'phhs'
    };
    this.schoolService.checkinStudentTeacherDetail(data).subscribe(
      async response => {
        this.checkinDate = response;
        //update calender
        this.calendar.updateTodaysDate();
      },
      error => {
        console.error(error);
      });
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      let date_temp = this.utilityService.getFullDateStrFromDate(date);

      let result="";
      this.checkinDate.forEach(element => {
          if(date_temp == element.date){
              if(element.isCheckin == 1){
                result = 'special-date';
              }
              return;
          }
      });
      return result;
      // if (date.getDate() > 10) {
      //   return 'special-date';
      // } else {
      //   return;
      // }
    };
  }
}



