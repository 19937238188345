<div class="page-content fade-in-up">

  <div class="row mb-2 mt-2">
    <div class="col">
      <div class="flexbox-b">
        <span class="mr-4 static-badge badge-primary"><i class="ti ti-receipt"></i></span>
        <div>
          <div style="font-size: 20px;">Tạo phiếu nhập kho</div>
        </div>
      </div>
    </div>
    <div class="col text-right">
      <button class="blurButton mt-2 btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon"
        (click)="popup_cart()">
        <span class="btn-label">{{cartObj.cart.length}}</span>Phiếu nhập
      </button>
    </div>
  </div>
  <div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
      <div class=" p-2 input-group-icon input-group-icon-left mr-3">
        <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
        <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
        <input id="typeahead-template" class="form-control input-lg" [(ngModel)]="filter.name" [ngbTypeahead]="search"
          type="text" placeholder="Tìm sản phẩm" [resultTemplate]="rt" [inputFormatter]="formatter">
      </div>
      <ng-template #rt let-r="result" let-t="term">
        <div class="row" (click)="onClick(r)" style="width: 450px;">
          <div class="img-product-search ml-1"
            [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
          <div class="col"> #{{ r['code'] }}</div>
          <div class="col">
            <ngb-highlight [result]="r.name" [term]="t">
            </ngb-highlight>
          </div>
          <div class="col">Kho: {{ r['stock'] == null ? 0: r['stock'] }}</div>
          <div class="col">DM: {{ r['category_name'] }}</div>
          <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
        </div>
      </ng-template>
    </div>
    <div class="col">
      <div class="d-flex justify-content-end">
        <div class="p-2">
          <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)"
            name="category" id="category">
            <option value="">Danh mục</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
          </select>
        </div>
        <div class="p-2">
          <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)"
            name="sorter" id="sorter">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
          </select>
        </div>
        <div class="p-2">

            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-sm btn-icon" ngbDropdownToggle>
              <span class="btn-label">
                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
              </span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem  (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng lưới</button>
                <button ngbDropdownItem (click)="changeViewType(1)"><i class="ti ti-align-justify"></i> Dạng danh sách</button>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>

  <div>

    <div class="row align-items-center mb-2 paging-cover">
      <div class="col-4">
        <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
          {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
      </div>
      <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
          <li>
            <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
              (change)="viewNumsObjChange()">
              <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
            </select>
          </li>
          <li class="page-item">
            <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
              <span aria-hidden="true">
                <i class="ti ti-angle-left"></i>
              </span>
            </a>
          </li>
          <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
            [ngClass]="{'active' : item.i == curentPage}">
            <a class="page-link" href="javascript:;">{{item.i}}</a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
              <i class="ti ti-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="display_type ==1" class="table-responsive row">
      <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
          <tr>
            <th style="width: 50px;">#</th>
            <th>Tên hàng</th>
            <th>Danh mục</th>
            <th>Tồn kho</th>
            <th style="text-align: right;">Giá</th>
            <th class="no-sort"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of appService.products; let i = index" (click)="popupAddToCart(item)" class="h-row-item">
            <td>
              <!-- <a class="media-img" href="javascript:;">
                      <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
                    </a> -->
              <a (click)="popupAddToCart(item)">
                <img style="width: 25px;" src="./assets/img/icons/add_cart.svg" alt="image" />
              </a>
            </td>
            <td>
              <div style="font-size: 18px;"> {{i+1}} {{item.name}}
                <i *ngIf="item.stock < 1" class="la la-question-circle font-18 text-danger"
                  title="Hết hàng trong kho"></i>
              </div>
            </td>
            <td>{{item.category_name}}</td>
            <td><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
            <td style="text-align: right;">{{item.price| number:0}}</td>

            <!-- <td>
                    <a (click)="popupPayment(item)">
                      <img style="width: 20px;" src="./assets/img/icons/payment.svg" alt="image" />
                    </a>
                  </td> -->
          </tr>
          <tr>
            <td colspan="5">

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="display_type ==0">
      <div class="d-flex flex-wrap mb-5">
        <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index"
          (click)="popupAddToCart(item)">
          <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">
            <!-- <img class="card-img-top" src="./assets/img/picture.svg" alt="image" style="height: 80px;" /> -->
            <div class="card-img-overlay text-white">
              <a class="card-abs-left-bottom">
                <span *ngIf="item.stock > 0" class="badge-primary badge-point"></span>
                <span *ngIf="item.stock < 1" class="badge-danger badge-point"></span>
                <!-- <img class="img-circle img-bordered" src="./assets/img/users/u6.jpg" alt="image" width="30" /> -->
              </a>
            </div>
          </div>
          <div class="card-body">
            <p style=" max-height: 37px; overflow: hidden;">{{item.name}}</p>
            <div class="d-flex align-items-center justify-content-between mt-2">
              <div class="text-center">
                <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</div>
                <div class="text-muted font-11">Kho</div>
              </div>
              <div class="text-center">
                <div style="font-weight: bold;">{{item.price | number}}</div>
                <div class="text-muted font-11">VNĐ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row align-items-center mb-2 paging-cover">
      <div class="col-4">
        <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
          {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
      </div>
      <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
          <li>
            <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
              (change)="viewNumsObjChange()">
              <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
            </select>
          </li>
          <li class="page-item">
            <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
              <span aria-hidden="true">
                <i class="ti ti-angle-left"></i>
              </span>
            </a>
          </li>
          <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
            [ngClass]="{'active' : item.i == curentPage}">
            <a class="page-link" href="javascript:;">{{item.i}}</a>
          </li>
          <li class="page-item">
            <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
              <i class="ti ti-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>


  </div>

  <!-- <td style="width: 315px; vertical-align: top; display: none;">
          <div class="container">
            <div class="row ">
              <h3 class="col mt-3 mb-3 text-center">
                Phiếu nhập hàng
              </h3>
            </div>
            <div class="row info-row">
              <div class="col">
                Tổng đơn:
              </div>
              <div class="col text-right">
                {{cartObj.price_totol | number}}
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Chiết khấu:
              </div>
              <div class="col text-right">
                0
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Trả NCC:
              </div>
              <div class="col text-right">
                <input style="width: 120px; text-align: right;" [(ngModel)]="cartObj.customer_pay" class="customer_pay"
                  placeholder="Nhà nhà cung cấp" type="text">
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Tiền thừa:
              </div>
              <div class="col text-right">
                {{cartObj.customer_pay - cartObj.price_totol | number}}
              </div>
            </div>
            <table style="width: 100%; margin-top: 20px;">
              <tr *ngFor="let item of cartObj.cart; let i = index" class="item-row">
                <td style="width: 25px; text-align: center;">
                  <button
                    class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                  <span class="lbl-stt">{{i+1}}</span>
                </td>
                <td style="width: 55%;">
                  <div style="line-height: 16px;">
                    {{item.product.name}} <span style="color: gray; font-size: 12px;">{{item.product.price|
                      number}}</span>
                  </div>
                </td>
                <td style="text-align: center;">
                  <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button>
                  x {{item.quantity}}
                  <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                </td>
                <td style="text-align: right; color: black;">
                  {{item.quantity * item.product.price | number:0}}</td>
              </tr>
              <tr style="background: lightgray;line-height: 35px;">
                <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                <td style="text-align: center;font-weight: bold;">{{cartObj.total_quantity}}
                </td>
                <td style="text-align: right; color: black; font-weight: bold;">
                  {{cartObj.price_totol | number}}</td>
              </tr>
            </table>

            <div class="container" style="position: fixed;bottom: 0;width: 490px;margin: 0 auto;">
              <div class="row m-2 justify-content-between">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="doPayment()"
                  [disabled]="cartObj.total_quantity == 0">
                  <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                </button>

                <button class="btn m-1 btn-outline-primary btn-icon-only btn-sm btn-thick" (click)="print()"><i
                    class="ti ti-printer"></i></button>
              </div>
            </div>
          </div>
        </td> -->



  <div class="modal fade" id="add-to-import-cart-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content form-horizontal" id="form-add-product">
        <div class="modal-header p-4">
          <h5 class="modal-title">Nhập số lượng hàng hóa <i class="ti ti-receipt"></i>
          </h5>
          <a data-dismiss="modal"><i class="ti ti-close"></i></a>
        </div>
        <div class="modal-body p-4 mb-3">
          <div style="text-align: center; padding: 25px 0px;">
            <h2 style="font-weight: bold;">{{productSelected.name}}</h2>
            <h4>{{productSelected.price | number}} x {{txt_quantity}} =
              {{txt_quantity * productSelected.price | number}}</h4>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick"
                (click)="quantity_form_sub()"><i class="ti ti-minus"></i></button>
            </div>
            <div class="col">
              <input [(ngModel)]="txt_quantity" numbersOnly class="form-control" type="number"
                style="font-size: 24px; padding: 4px; text-align: center;" />
            </div>
            <div class="col ">
              <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick"
                (click)="quantity_form_add()"><i class="ti ti-plus"></i></button>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-end bg-primary-50">
          <!-- <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openCheckout()">
          <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
        </button> -->
          <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="addToCart()">
            <span class="btn-label"><i class="ti ti-shopping-cart"></i></span>Thêm phiếu nhập
          </button>

          <!-- <button class="btn btn-primary" [disabled]="loading" (click)="addToCart()">Thêm vào hóa đơn</button> -->
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="stockin-cart-modal">
    <div class="modal-dialog" role="document">
      <form class="modal-content modal-content-lg form-horizontal" #productUpdateForm="ngForm" id="form-update-product">
        <div class="modal-header p-4">
          <h5 class="modal-title">Phiếu nhập hàng</h5>
          <a data-dismiss="modal"><i class="ti ti-close"></i></a>
        </div>
        <div class="modal-body p-4">
          <div class="container">
            <div class="row info-row">
              <div class="col">
                Tổng đơn:
              </div>
              <div class="col text-right">
                {{cartObj.price_totol | number}}
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Chiết khấu:
              </div>
              <div class="col text-right">
                0
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Trả NCC:
              </div>
              <div class="col text-right">
                <input [(ngModel)]="cartObj.customer_pay" currencyMask class="form-control form-control-custom1"
                  numbersOnly name="customer_pay" placeholder="Giá nhập" type="text">
              </div>
            </div>
            <div class="row info-row">
              <div class="col">
                Tiền thừa:
              </div>
              <div class="col text-right">
                {{cartObj.customer_pay - cartObj.price_totol | number}}
              </div>
            </div>
            <table style="width: 100%; margin-top: 20px;">
              <tr *ngFor="let item of cartObj.cart; let i = index" class="item-row">
                <td style="width: 25px; text-align: center;">
                  <button
                    class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                  <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                  <span class="lbl-stt">{{i+1}}</span>
                </td>
                <td style="width: 55%;">
                  <div style="line-height: 16px;">
                    {{item.product.name}} <span style="color: gray; font-size: 12px;">{{item.product.price|
                      number}}</span>
                  </div>
                </td>
                <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| number}}</span> </td> -->
                <td style="text-align: center;">
                  <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                  <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button>
                  x {{item.quantity}}
                  <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                  <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick"
                    (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                </td>
                <td style="text-align: right; color: black;">
                  {{item.quantity * item.product.price | number:0}}</td>
              </tr>
              <tr style="background: lightgray;line-height: 35px;">
                <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                <td style="text-align: center;font-weight: bold;">{{cartObj.total_quantity}}
                </td>
                <td style="text-align: right; color: black; font-weight: bold;">
                  {{cartObj.price_totol | number}}</td>
              </tr>
            </table>

            <!-- <div class="container" style="position: fixed;bottom: 0;width: 490px;margin: 0 auto;">
              <div class="row m-2 justify-content-between">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="doPayment()"
                  [disabled]="cartObj.total_quantity == 0">
                  <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                </button>

                <button class="btn m-1 btn-outline-primary btn-icon-only btn-sm btn-thick" (click)="print()"><i
                    class="ti ti-printer"></i></button>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer justify-content-around bg-primary-50">
          <!-- <button class="btn btn-primary " [disabled]="loading" (click)="insertProduct()">Thêm mới</button> -->
          <button class="btn btn-primary" (click)="doPayment()" [disabled]="cartObj.total_quantity == 0">Thanh
            toán</button>

          <button class="btn m-1 btn-outline-primary btn-icon-only btn-sm btn-thick" (click)="print()"><i
              class="ti ti-printer"></i></button>
          <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
        </div>
      </form>
    </div>
  </div>