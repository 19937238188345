<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm lớp</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm lớp" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>


            <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Khối</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_change($event)">
                    <mat-option value="">
                        Tất cả
                    </mat-option>
                    <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 200px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
                <div class="p-2">
                    <div ngbDropdown class="d-inline-block">
                        <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Thêm</button> -->

                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                                <span class="btn-label"><span class="material-icons"> menu </span></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="popup_mode('class_time')" class="text-primary"><span class="material-icons mr-2">
                                calendar_month
                            </span>
                            Gán lịch học</button>
                            <button ngbDropdownItem (click)="popup_mode('meal_time')" class="text-primary"><span class="material-icons mr-2">
                                calendar_month
                            </span>
                            Gán lịch bán trú</button>
                            <div class="dropdown-divider"></div>
                            <button ngbDropdownItem (click)="openSaveArrange()" class="text-primary"><span
                                class="material-icons mr-2">
                                sort_by_alpha
                            </span>
                            Sắp xếp thứ tự</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div *ngIf="action_mode ==''" class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="action_mode =='meal_time'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-primary">
            Gán lịch bán trú cho lớp
        </div>
        <div>
            <div class="p-2">
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="processChangeMeal_time()">
                        <span class="btn-label"><span class="material-icons">calendar_month</span></span>Gán lịch bán trú
                    </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>
    <div *ngIf="action_mode =='class_time'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-primary">
            Gán lịch học cho lớp
        </div>
        <div>
            <div class="p-2">
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="processChangeClass_time()">
                        <span class="btn-label"><span class="material-icons">calendar_month</span></span>Gán lịch học
                    </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>

    <div class=" row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Khối/Cơ sở</th>
                    <th>Tên lớp</th>
                    <th>Học sinh</th>
                    <th>Lịch học</th>
                    <th>Lịch bán trú</th>
                    <th>Tài liệu</th>
                    <th>Chủ nhiệm</th>
                    <!-- <th *ngIf="action_mode =='meal_time'">
                        Lịch bán trú
                    </th> -->
                    <th *ngIf="action_mode ==''" class="no-sort"></th>
                    <th *ngIf="action_mode =='meal_time' || action_mode =='class_time'" class="no-sort">
                        <label class="checkbox myHeader checkbox-warning check-single">
                            <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                            <span class="input-span"></span>
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)">
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.khoi_name}}</div>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>

                    </td>
                    <td>{{item.student_count == null ? 0: item.student_count}}</td>
                    <td>
                        <span *ngFor="let jtem of item.time_data" placement="top" ngbTooltip="{{jtem.class_time}}" class="badge badge-default  mr-2">{{jtem.class_time_name}}</span>
                    </td>
                    <td>
                        <span *ngFor="let jtem of item.meal_data" placement="top" ngbTooltip="{{jtem.meal_time}}" class="badge badge-default  mr-2">{{jtem.meal_time_name}}</span>
                    </td>
                    <td>
                        <button placement="top" ngbTooltip="Tài liệu" (click)="uploadDocument(item)" [ngClass]="(item.document_count == null || item.document_count == 0) ? 'btn-default' : 'btn-primary'" class=" btn btn-labeled btn-labeled-left mr-2">
                            <span class="btn-label">
                                <span class="material-icons"> source </span>
                            </span>
                            <span>{{(item.document_count == null || item.document_count == 0) ? "Chưa upload" : 'Tài liệu ' + item.document_count }}</span>
                        </button>
                    </td>
                    <td>

                        <!-- <a class="" (click)='openSelectTeacher(item,i)'>
                            <i *ngIf="item.chunhiem_code == ''" class="ti ti-pencil-alt"></i>
                            <span *ngIf="item.chunhiem_code != ''">{{item.name_teacher}}</span>
                        </a> -->

                        <button (click)="openSelectTeacher(item,i)" placement="top" ngbTooltip="Giáo viên chủ nhiệm" [ngClass]="{'btn-primary' : item.name_teacher != null, 'btn-secondary' : item.name_teacher == null}" class=" btn btn-labeled btn-labeled-left mr-2">
                            <span class="btn-label">
                                <span class="material-icons"> folder_shared </span>
                            </span>
                            <span>{{item.name_teacher == null ? "Chưa gán" : item.name_teacher}}</span>
                        </button>
                        <button [disabled]="!appService.checkFullPermission()" (click)='openSelectTeacherMore(item,i)' placement="top" ngbTooltip="Giáo viên hỗ trợ" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                            <span class="btn-label"><span class="material-icons text-primary font-18"> group_add </span></span>
                            {{item.teacher_count == null ? "0" : item.teacher_count}}
                        </button>

                    </td>
                    <td class="text-right" *ngIf="action_mode ==''">
                        <!-- <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button> -->
                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Cập nhật" (click)="popupUpdate(item)">
                            <span class="material-icons"> settings </span>
                        </button>
                    </td>
                    <th class="text-right" *ngIf="action_mode =='meal_time' || action_mode =='class_time'">
                        <label class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp">
                            <span class="input-span"></span>
                        </label>
                    </th>

                </tr>
            </tbody>
        </table>
    </div>


</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới lớp</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>


                <mat-form-field appearance="fill" class="mb-3">
                    <mat-label>Khối / Cơ sở</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="cate.khoi_code" name="khoi_code">
                        <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="" appearance="fill">
                    <mat-label>Tên lớp</mat-label>
                    <input matInput [(ngModel)]="cate.name" placeholder="Nhập tên lớp" name="calss_name" required minlength="2" maxlength="10">
                </mat-form-field>


                <!-- <section class="mt-4 mb-3 weeks-checkbox">
                    <div class="mb-1">Ngày học trong tuần</div>
                    <mat-checkbox *ngFor="let item of appService.weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                </section> -->
                <!-- <section class="mt-4 mb-3 weeks-checkbox">
                    <div class="mb-1">Ngày bán trú</div>
                    <mat-checkbox *ngFor="let item of appService.weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="item.code" (change)="changeCheckbox($event)">{{item.value}}</mat-checkbox>
                </section> -->
                <!-- <div class="mb-1">Ca học</div> -->
                <!-- <div class="form-group has-validation">
                    <label class="col-form-label">Tên lớp</label>
                    <div class="">
                        <input [(ngModel)]="cate.name" class="form-control" required minlength="2" maxlength="10" (focusout)="focusOutCheckCode()" [readonly]="'mts' === CodeType" name="code" type="text" (keydown.space)="$event.preventDefault();">
                        <span class="help-block">
                        </span>
                    </div>
                </div> -->

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật lớp</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+categoryselected_imageUrl+')'">
                            <!-- <div class="file-input" [style.background-image]="categoryselected_imageUrl"> -->
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile_update" name="uploadFile" (change)="changeImageUpdate($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>

                <mat-form-field appearance="fill" class="mb-5">
                    <mat-label>Khối lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="categoryselected.khoi_code" name="khoi_code_update">
                        <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="" appearance="fill">
                    <mat-label>Tên lớp</mat-label>
                    <input matInput [(ngModel)]="categoryselected.name" placeholder="Nhập tên lớp" name="class_name_update" required minlength="2" maxlength="10">
                </mat-form-field>

                <section class="mt-4 mb-3 weeks-checkbox">
                    <div class="mb-1">Lịch học</div>
                    <mat-checkbox *ngFor="let item of temp_class_weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="'temp_class_weeks'+i">{{item.value}}</mat-checkbox>
                </section>
                <section class="mt-4 mb-3 weeks-checkbox">
                    <div class="mb-1">Lịch bán trú</div>
                    <mat-checkbox *ngFor="let item of temp_meal_weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="'temp_meal_weeks'+i">{{item.value}}</mat-checkbox>
                </section>



                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <!-- <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'"> -->
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>