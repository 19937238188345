<div class="p-1">
    <div class="page-heading">
        <h1 class="page-title">Chọn giáo viên cho : <span class="text"> {{classSelected.name}}</span></h1>
    </div>

    <button class="btn btn-sm btn-gradient-peach btn-icon-only btn-circle " style="position: absolute;
    right: 41px;
    top: 29px;" (click)="onCancelClick()">
        <i class="ti ti-close"></i>
    </button>
    <!-- <div class="">
        <div class="ml-2 select_category" (click)="select_category('')" [ngClass]="{'selected_category' : '' == this.filter.category_code}">
            <div>
                Tất cả
            </div>
        </div>
        <div class="select_category" *ngFor="let item of categories" (click)="select_category(item.code)" [ngClass]="{'selected_category' : item.code == this.filter.category_code}">
            <div>
                {{item.name}}
            </div>
        </div>
    </div> -->

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col-4">

            <div class=" p-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-templates" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm giáo viên" [resultTemplate]="rt" [inputFormatter]="formatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <div class="img-product-search" [style.background-image]="this.appService.showImageProduct(r['image_url'])">
                        </div>
                    </div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">{{ ' Kho:' + r['stock']}}</div>
                    <div class="col"> - {{ (r['price'] | number )}} Đ</div>
                </div>
            </ng-template>

        </div>

        <div class="col-8">
            <div class="d-flex justify-content-end">

                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.positions" (change)="category_change($event)" name="positions" id="positions">
                        <option value="">Chức vụ</option>
                        <option [ngValue]="item.code" *ngFor="let item of appService.positions">{{item.value}}</option>
                    </select>
                </div>
                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.faculty_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Tổ</option>
                        <option [ngValue]="item.code" *ngFor="let item of facultys">{{item.name}}</option>
                    </select>
                </div>


                <div class="p-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="table-responsive row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Giáo viên</th>
                    <th>Email/TK</th>
                    <th>Tổ bộ môn</th>

                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.teacher_list; let i = index" class="h-row-item">
                    <td>
                        <!-- <a class="media-img" href="javascript:;">
                  <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
                </a> -->
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;"> {{i+1}} {{item.name}}

                        </div>
                        <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
                            {{item.code}}
                        </div>
                    </td>
                    <td>{{item.email}}</td>
                    <td>{{item.faculty_name}}</td>
                    <td style="text-align: right;">
                        <label class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox"  [(ngModel)]="item.selected_temp" [disabled]="item.temp_disable">
                            <span class="input-span"></span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mt-4 d-flex justify-content-end" style="position: absolute;
    right: 66px;
    bottom: 33px;">
        <button class="mr-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
        </button>
        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
            <span class="btn-label"><span class="material-icons">done</span></span>Xác nhận gán giáo viên
        </button>
    </div>
</div>