<div class="m-3">
    <h5>Chi tiết checkin ngày: {{checkin_date}}</h5>
    <div>
        <div *ngIf="dataList.length > 0" style="
            max-height: calc(100vh - 110px);" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">

                <tbody>
                    <tr *ngFor="let item of dataList; let k = index" class="row-items-checkin">
                        <td class="timeline-box-left">
                            {{item.checkin_time}}
                            <span></span>
                        </td>
                        <td class="timeline-box-right">
                            <div class="content-item">
                                <img [src]="item.checkin_img" style="max-height: 250px;
                                    border-radius: 5px;" />
                            </div>
                        </td>
                    </tr>


                    <!-- <tr *ngFor="let item of dataList; let i = index">
                        <td>
                            <div style="font-size: 18px;">{{item.checkin_time}}</div>
                        </td>
                        <td style="padding: 0;">
                            <span class="material-icons text-primary">
                                label_important
                                </span>
                        </td>
                        <td>
                            <div style="font-size: 18px;" class="pr-3"><img [src]="item.checkin_img" class="img-md"></div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
    <div class="mt-4 d-flex justify-content-end">
        <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
        </button>
    </div>
</div>