<div class="container">
  <div style="padding: 30px;">
    <div style="text-align: center;">
      <h2 style="font-size: 25px; font-weight: bold;">Tổng doanh thu tồn kho</h2>
    </div>
    <div style="text-align: center;">
      <img style="width: 80px;" src="../assets/img/icons/vnd.svg">
    </div>
    <div style="text-align: center;">
      <div style="font-size: 23px; font-weight: bold;">{{sumShopInfo.total | number:0}}</div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-lg-4 col-md-6">
      <div class="card mb-4">
        <div class="card-body flexbox-b">
          <div class="easypie mr-4" data-line-width="10">
            <span class="flexbox text-success"><i class="ti ti-folder mr-3 font-40"></i></span>
          </div>
          <div>
            <h3 class="font-strong text-success">{{sumShopInfo.category_shop}}</h3>
            <h3>Nhóm hàng</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6">
      <div class="card mb-4">
        <div class="card-body flexbox-b">
          <div class="easypie mr-4" data-percent="42" data-bar-color="#5c6bc0" data-size="80" data-line-width="8">
            <span class="flexbox text-primary"><i class="la la-chrome mr-3 font-40"></i></span>
          </div>
          <div>
            <h3 class="font-strong text-primary">{{sumShopInfo.product_shop}}</h3>
            <h3>Hàng Hóa</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6">
      <div class="card mb-4">
        <div class="card-body flexbox-b">
          <div class="easypie mr-4" data-percent="70" data-bar-color="#ff4081" data-size="80" data-line-width="8">
            <span class="flexbox text-pink"><i class="la la-chrome mr-3 font-40"></i></span>
          </div>
          <div>
            <h3 class="font-strong text-pink">{{sumShopInfo.product_quantity_shop}}</h3>
            <h3>Số Lượng</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ul class="media-list media-list-divider">
    <li class="media" *ngFor="let item of sumInfoCategorys; let i = index">
      <a class="avatar-img-list mr-2" href="javascript:;" [style.background-image]="this.appService.showImageProduct(item.image_url)">
        <!-- <i class="ti ti-folder font-20"></i> -->
        <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
      </a>
      <!-- <a class="media-img" href="javascript:;">
        [style.background-image]="showImage(item.image_url)"
        <img src="./assets/img/icons/cate.svg" alt="image" width="40" />
      </a> -->
      <div class="media-body d-flex">
        <div class="flex-1">
          <h4 class="media-heading">{{item.name}}</h4>
          <p class="font-15 m-0 ">Sản phầm: <span style="color: black;">{{item.products}}</span> Tồn kho: <span
              style="color: black;">{{item.stock_price | number:0}}</span> VNĐ</p>
        </div>
        <div class="text-right ml-2">
          <h4 class="mb-1 font-strong">{{item.code}}</h4>
          <!-- <div class="text-muted">{{item.stock_price | number:0}}</div> -->
        </div>
      </div>
    </li>
  </ul>
  <!-- 
  <div>
    <div *ngFor="let item of sumInfoCategorys; let i = index">
      <a class="media-img" href="javascript:;">
        <img src="./assets/img/devicons/bootstrap.svg" alt="image" width="40" />
      </a>
      <div class="media-body d-flex">
        <div class="flex-1">
          <div class="media-heading">{{item.name}}</div>
          <p class="font-13 m-0 text-light">{{item.code}}</p>
        </div>
        <div class="text-right ml-2">
          <h4 class="mb-1 font-strong">Sản phẩm: <label color="secondary"><a>{{item.products}}</a></label></h4>
          <div class="text-muted">Doanh thu tồn: <label color="secondary"><a>{{item.stock_price | number:0}}
                VNĐ</a></label></div>
        </div>
      </div>
    </div>
  </div> -->

</div>