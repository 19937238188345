<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">

        <div class="col p-0">



            <mat-form-field appearance="fill" style="width: 200px;" class="mr-2">
                <mat-label>Xếp theo</mat-label>
                <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="loadCategory()" name="sorter">

                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="table-responsive row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th rowspan="2" style="width: 50px; text-align: center;">#</th>
                    <th rowspan="2">Năm học</th>
                    <th colspan="2" style="text-align: center; ">Học kỳ I</th>
                    <th colspan="2" style="text-align: center; ">Học kỳ II</th>
                    <th class="no-sort"></th>
                </tr>
                <tr>
                    <th style="text-align: center;background: #0099ccd1;">Bắt đầu</th>
                    <th style="text-align: center;background: #0099ccd1;">Kết thúc</th>
                    <th style="text-align: center;background: #0099ccd1;">Bắt đầu</th>
                    <th style="text-align: center;background: #0099ccd1;">Kết thúc</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.appService.currentUser.scholastics_list; let i = index">
                    <td>
                        <!-- <span style="position: absolute; left: 30px;
                line-height: 20px;">{{app.getUrlImage(item.image_url)}}</span> -->
                        <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)">
                            <!-- <i class="ti ti-folder font-20"></i> -->
                            <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <!-- <span>Mã: {{item.code}}</span> -->
                    </td>
                    <td style="text-align: center;">{{item.hockyI_startTime_str}}</td>
                    <td style="text-align: center;">{{item.hockyI_endTime_str}}</td>
                    <td style="text-align: center;">{{item.hockyII_startTime_str}}</td>
                    <td style="text-align: center;">{{item.hockyII_endTime_str}}</td>

                    <td style="text-align: center;">
                        <button [disabled]="!appService.checkFullPermission()" (click)='popupUpdate(item)' class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Chỉnh sửa">
                            <span class="material-icons">
                                settings
                                </span>
                        </button>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>


</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới năm học</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="d-flex justify-content-around align-items-center mb-4">
                    <mat-form-field class="example-full-width  mb-3" appearance="fill">
                        <mat-label>Tên năm học</mat-label>
                        <input matInput [(ngModel)]="cate.name" placeholder="Tên năm học" name="name" required maxlength="30">
                    </mat-form-field>
                </div>

                <div class="d-flex justify-content-around align-items-center mb-4">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Bắt đầu học kỳ I</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Bắt đầu học kỳ I" [(ngModel)]="cate.hockyI_startTime" name="hockyI_startTime">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div class="m-3"></div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Kết thúc học kỳ II</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="Kết thúc học kỳ I" [(ngModel)]="cate.hockyI_endTime" name="hockyI_endTime">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-around align-items-center">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Bắt đầu học kỳ II</mat-label>
                        <input matInput [matDatepicker]="picker3" placeholder="Bắt đầu học kỳ I" [(ngModel)]="cate.hockyII_startTime" name="hockyII_startTime">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                    <div class="m-3"></div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Kết thúc học kỳ II</mat-label>
                        <input matInput [matDatepicker]="picker4" placeholder="Kết thúc học kỳ II" [(ngModel)]="cate.hockyII_endTime" name="hockyII_endTime">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>
                </div>


            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>

            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật năm học học</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="d-flex justify-content-around align-items-center mb-4">
                    <mat-form-field class="example-full-width  mb-3" appearance="fill">
                        <mat-label>Tên năm học</mat-label>
                        <input matInput [(ngModel)]="categoryselected.name" placeholder="Tên năm học" name="name" required maxlength="30">
                    </mat-form-field>
                </div>

                <div class="d-flex justify-content-around align-items-center mb-4">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Bắt đầu học kỳ I</mat-label>
                        <input matInput [matDatepicker]="picker5" placeholder="Bắt đầu học kỳ I" [(ngModel)]="categoryselected.hockyI_startTime" name="hockyI_startTime">
                        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                        <mat-datepicker #picker5></mat-datepicker>
                    </mat-form-field>
                    <div class="m-3"></div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Kết thúc học kỳ I</mat-label>
                        <input matInput [matDatepicker]="picker62" placeholder="Kết thúc học kỳ I" [(ngModel)]="categoryselected.hockyI_endTime" name="hockyI_endTime">
                        <mat-datepicker-toggle matSuffix [for]="picker62"></mat-datepicker-toggle>
                        <mat-datepicker #picker62></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-around align-items-center">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Bắt đầu học kỳ II</mat-label>
                        <input matInput [matDatepicker]="picker73" placeholder="Bắt đầu học kỳ I" [(ngModel)]="categoryselected.hockyII_startTime" name="hockyII_startTime">
                        <mat-datepicker-toggle matSuffix [for]="picker73"></mat-datepicker-toggle>
                        <mat-datepicker #picker73></mat-datepicker>
                    </mat-form-field>
                    <div class="m-3"></div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Kết thúc học kỳ II</mat-label>
                        <input matInput [matDatepicker]="picker84" placeholder="Kết thúc học kỳ II" [(ngModel)]="categoryselected.hockyII_endTime" name="hockyII_endTime">
                        <mat-datepicker-toggle matSuffix [for]="picker84"></mat-datepicker-toggle>
                        <mat-datepicker #picker84></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="form-group row m-3 pr-5 pl-5">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
            </div>
        </form>
    </div>
</div>