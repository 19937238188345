<div class="row mb-2 mt-2 justify-content-between">
    <div class="col p-0 d-flex justify-content-start">

        <mat-form-field class="example-full-width mr-3" appearance="fill" style="width: 320px;">
            <mat-label>Tìm kiếm nội dung hoặc mã chuyển tiền</mat-label>
            <!-- <mat-icon matPrefix>search</mat-icon> -->
            <input matInput [(ngModel)]="filter.name" placeholder="Nhập nội dung hoặc mã chuyển tiền" name="search" maxlength="200">
            <a matSuffix mat-icon-button aria-label="Clear" (click)="doSearch()">
                <mat-icon>search</mat-icon>
            </a>
        </mat-form-field>

        <mat-button-toggle-group name="favoriteColor" color="primary" aria-label="Favorite Color" [(ngModel)]="filter.status" (click)="changeStatus()">
            <mat-button-toggle value="">Tất cả</mat-button-toggle>
            <mat-button-toggle value="1">Đã gán</mat-button-toggle>
            <mat-button-toggle value="0">Chưa gán</mat-button-toggle>
        </mat-button-toggle-group>

    </div>

    <div class="col p-0 d-flex justify-content-end">
        <div class="btn-group mr-3" placement="top" ngbTooltip="Chi tiết khoản đã thu">
            <span class="qbs-middle btn-label-out btn-label-out-left btn-label-out-secondary pointing"><span class="text-black">SL giao dịch</span></span>
            <button class="btn btn-outline-secondary">
                <span class="btn-icon text-black">{{this.data.count | qbscurrency}}</span>
            </button>
        </div>

        <div class="btn-group mr-3" placement="top" ngbTooltip="Chi tiết khoản đã thu">
            <span class="qbs-middle btn-label-out btn-label-out-left btn-label-out-secondary pointing"><span class="text-black">Số tiền</span></span>
            <button class="btn btn-outline-secondary">
                <span class="btn-icon text-black" >{{this.data.amount | qbscurrency}}</span>
            </button>
        </div>

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày giao dịch</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>




<div>


    <div class="row">

        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Lớp</th>
                    <th>Học sinh</th>
                    <!-- <th>Tên</th> -->
                    <th>Mã QRcode</th>
                    <th class="text-right">Số tiền</th>
                    <th>Tài khoản nguồn</th>
                    <th>
                        Tài khoản đích

                    </th>
                    <th>
                        Nội dung chuyển
                    </th>
                    <th class="text-center">
                        Thao tác
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.datas; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                        {{i + 1}}
                    </td>
                    <td>
                        <div *ngIf="item.class_code != undefined">{{item.class_code}}</div>
                    </td>
                    <td>
                        <div *ngIf="item.full_name != undefined">{{item.full_name}}</div>
                    </td>

                    <td>{{item.customer_id}}</td>
                    <td class="text-right">{{item.amount | qbscurrency}}</td>
                    <td>
                        {{item.tk_nguon}}
                    </td>
                    <td>
                        {{item.tk_dich}}
                    </td>
                    <td>
                        {{item.content}}
                    </td>
                    <td class="text-center">

                        <button *ngIf="item.status == 1" class="btn btn-primary btn-sm  btn-white btn-icon-only" placement="top" ngbTooltip="Giao dịch đã được gán">
                            <span class="material-icons"> task_alt </span>
                        </button>
                        <button *ngIf="item.status == 0" class="btn btn-default btn-sm  btn-white btn-icon-only" placement="top" ngbTooltip="Giao dịch chưa được gán">
                            <span class="material-icons"> schedule </span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <app-nodata *ngIf="this.datas.length == 0 && msg == ''" myclass="text-warning" msg="Vui lòng chọn ngày để đối soát. Hệ thống chỉ được đối soát theo ngày và từ ngày hôm qua trở về trước"></app-nodata>
    <div *ngIf="msg != ''">
        <app-nodata img="./assets/img/icons/puzzle-game.png" [msg]="msg" myclass="text-danger"></app-nodata>
    </div>

</div>