<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">
        <div style="font-size: larger;" class="mb-2">Chi tiết thông báo</div>



        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussionSelected.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <div class="font-weight-bold">
            {{discussionSelected.title}}
        </div>


        <div class="mt-3 mb-3">{{discussionSelected.message}}</div>

        <div class="font-bold">
            {{discussionSelected.recipient}}
        </div>
        <div class="font-italic text-right">
            {{discussionSelected.created_date_str}}
        </div>

        <div *ngIf="discussionSelected.file_url !=''" class="filettach-upload mb-3 mt-3">
            <a [href]="appService.showFileAttach(discussionSelected.file_url)" target="_self">
                Tải file
            </a>
        </div>


        <div class="row mb-3">
            <label class="col-sm-9 col-form-label">File đính kèm</label>
            <div class="col-sm-3 d-flex align-items-start">
                <div class="col text-right">
                    <div *ngIf="discussionSelected.file_url !=''" class="filettach-upload">
                        <a [href]="appService.showFileAttach(discussionSelected.file_url)" target="_self">
                            Tải file
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label class="col-sm-9 col-form-label">Cho phép bình luận</label>
            <div class="col-sm-3 d-flex align-items-end">
                <div class="col text-right">
                    <label class="ui-switch switch-icon">
                        <input type="checkbox" [(ngModel)]="comment_allowed" name="comment_allowed">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-9 col-form-label">Thu hồi thông báo</label>
            <div class="col-sm-3 d-flex align-items-end">
                <div class="col text-right">
                    <label class="ui-switch switch-icon">
                        <input type="checkbox" [(ngModel)]="deleted" name="deleted">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-between ">


            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="!appService.checkFullPermission()" (click)="eviction()">
                <span class="btn-label"><span class="material-icons">
                    save
                    </span></span>
                Cập nhật
            </button>
            <button class="btn btn-secondary  btn-labeled btn-labeled-left btn-icon" (click)="this.dialogRef.close();">
                <span class="btn-label"><span class="material-icons">
                    close
                    </span></span>
                Đóng
            </button>

        </div>
    </form>
</div>