<div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start">

        <!-- <div class="p-2">
            <select class="custom-select" [(ngModel)]="filter.khoi" (change)="category_khoi_change($event)" name="khoi" id="khoi">
                <option value="">Khối</option>
                <option [ngValue]="item.code" *ngFor="let item of appService.currentUser.khois">{{item.value}}</option>
            </select>
        </div> -->

        <mat-form-field appearance="fill" style="max-width: 200px;" class="">
            <mat-label>Khối</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of appService.currentUser.khois" [value]="item.code">
                    {{item.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- 
        <mat-form-field appearance="fill" style="" class="ml-2">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

    </div>

    <div class="col-4  p-0 d-flex justify-content-end align-items-center">


        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>






    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>




<div class="tab-content">

</div>


<!-- <div style="padding: 10px;"></div> -->
<div class="d-flex justify-content-between">
    <div class="col p-0 d-flex">
        <div class="d-flex box-school" style="background-color: #ff9c25ad;">
            <div><img src="assets\img\icons\students.png" alt=""></div>
            <div class="ml-3">
                <div class="name">Tổng học sinh</div>
                <div class="value">{{allSchools.student_count}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #0996ca; color: white;">
            <div><img src="assets\img\icons\check-list-mark.png" alt=""></div>
            <div class="ml-3">
                <div class="name">Điểm danh</div>
                <div class="value">{{allSchools.student_count - allSchools.student_inattention}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #dc3545c9; color: white; width: 150px;">
            <div><img src="assets\img\icons\person-unknown.png" alt=""></div>
            <div class="ml-3">
                <div class="name">Vắng</div>
                <div class="value">{{allSchools.student_inattention}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #8fdbc5; width: 170px;">
            <div><img src="assets\img\icons\check-list-mark.png" alt=""></div>
            <div class="ml-3">
                <div class="name">Có phép</div>
                <div class="value">{{allSchools.student_inattention}}</div>
            </div>
        </div>
    </div>
    <div class="">
        <div ngbDropdown class="d-inline-block">

            <!-- <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                    <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
            </button> -->

            <button class="btn m-2" ngbDropdownToggle>
                <div class="visible-content btn-icon">
                    <img class="" src="./assets/img/icons/excel.png" alt="image" width="40" />
                    <div>HS vắng</div>
                </div>
            </button>



            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="confirmDownloadExcel('')" class="text-primary">
                    <span class="material-icons mr-2"> file_download </span>
                    Học sinh vắng toàn trường
                </button>
                <button ngbDropdownItem *ngFor="let item of this.appService.currentUser.caps; let i = index" (click)="confirmDownloadExcel(item.name)" class="text-primary">
                    <span class="material-icons mr-2"> file_download </span>
                    Học sinh vắng {{item.name}}
                </button>
            </div>

        </div>

    </div>
</div>

<div class="row" style="background: white;padding: 10px;margin: 10px;border-radius: 7px;">
    Tỷ lệ
    <div class="progress mt-2" style="width: 100%;">
        <div class="progress-bar bg-primary" role="progressbar" [style.width.%]="allSchools.percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            {{ allSchools.percent }}%
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col  p-0">
        <div class="ibox">
            <div class="pt-4 pr-4 pl-4 pb-2">
                <div class="row text-center">
                    <div class="col d-flex ">
                        <div> <img style="width: 50px;" src="assets\img\icons\students.png" alt=""> </div>
                        <div class="ml-3">
                            <div class="">Tổng số</div>
                            <h2 class="mb-1">{{allSchools.student_count}}</h2>
                        </div>
                    </div>
                    <div class="col  d-flex" [ngClass]="{'text-info': true }">
                        <div><img style="width: 39px;" src="assets\img\icons\check-list-mark.png" alt=""></div>
                        <div class="ml-3">
                            <div class="">Điểm danh</div>
                            <h2 class="mb-1">{{allSchools.student_count - allSchools.student_inattention}}</h2>
                        </div>
                    </div>
                    <div class="col d-flex" [ngClass]="{'text-danger': allSchools.student_inattention >0 }">
                        <div><img style="width: 52px;" src="assets\img\icons\person-unknown.png" alt=""></div>
                        <div class="ml-3">
                            <div class="">Vắng</div>
                            <h2 class="mb-1">{{allSchools.student_inattention}}</h2>
                        </div>
                    </div>
                </div>
                <div class="progress mt-2">
                    <div class="progress-bar bg-primary" role="progressbar" [style.width.%]="allSchools.percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        {{ allSchools.percent }}%
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <span>So với hôm qua</span>
                </div>
            </div>
        </div>
    </div>

</div> -->


<!-- <div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
    <span color="primary">Tổng sỹ số: </span> {{allSchools.student.student_count}}
    <span color="primary">Vắng: </span> 5
    <span color="primary">Tỷ lệ: </span> 97%
</div> -->

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>


<div class="row lop-cover">
    <div *ngFor="let item of reportcheckins; let i = index" (click)="openCheckinClass(item)" class="card text-center card-air  m-1 lop-item">
        <div class="card-body">

            <span *ngIf="item.isAvailable == 0" class="material-icons font-18 disableView">visibility_off</span>

            <div class="card-avatar mt-2 mb-2" style="background-position: center; height: 35px; background-size: 35px;
            background-repeat: no-repeat;">
                <img [src]="appService.showImageTeacher2(item.image_url)" alt="Avatar">
            </div>
            <h4 class="card-title text-primary mb-1">Lớp {{item.name}}</h4>
            <div class="" style="white-space: nowrap;
            overflow: hidden !important; height: 20px;
            text-overflow: ellipsis; width: 140px;">{{item.teacher_name}}</div>
            <div class="mb-2 mt-2 text-center row align-items-center justify-content-center">
                <span class="font-18 font-bold mr-3">
                    <i class="fa fa-address-book-o mb-0 mr-1"></i>
                    <span>{{item.student_count}}</span>
                </span>
                <span style=" display: inline-block;">
                    <span class="font-18 font-bold  align-items-center"
                        [ngClass]="{'text-success' : item.student_attention > 0}" style="display: flex;
                flex-wrap: wrap;">
                        <span class="material-icons font-18">description</span>
                <span class="">{{item.student_attention}}</span>
                </span>
                </span>
                <span style=" display: inline-block;">
                    <span class="font-18 font-bold ml-3  align-items-center"
                        [ngClass]="{'text-danger' : item.student_inattention > 0}" style="display: flex;
                flex-wrap: wrap;">
                        <span class="material-icons font-18">group_off</span>
                <span class="">{{item.student_inattention}}</span>
                </span>
                </span>
            </div>
            <div class="">
                <div *ngIf="item.student_inattention > 0" class="progress my-danger mb-2">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
                <div *ngIf="item.student_inattention == 0" class="progress my-primary mb-2">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>