import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable()
export class DebtService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('DebtService constructor');
  }

  getDebtReport(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('debt/report'),data).map(data => { return data; });
  }
  getSaleDebt(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('debt/sale'),data).map(data => { return data; });
  }
  updateSaleDebts(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('debt/sale'),data).map(data => { return data; });
  }
  

}
