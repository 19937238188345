import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-class-history-compose',
  templateUrl: './class-history-compose.component.html',
  styleUrls: ['./class-history-compose.component.css']
})
export class ClassHistoryComposeComponent implements OnInit {
  categorys = []; // lớp
  class_selected : string= ""; // select lớp
  checkboxAllButton = true; // mặc định chọn all
  discussion ={
    recipient:'',
    title:'',
    message: '',
    file_url: '',
    type: environment.message_type_class_history,
  };
  isLoading = false;

  fileAttach: File;

  imageUrl: any = environment.image_frame;
  
  constructor(
    public schoolService: SchoolService,
    public categoryService: CategoryEduService,
    public studentService: StudentService,
    private toastr: ToastrService,
    public messengerService: MessengerService,
    public dialogRef: MatDialogRef<ClassHistoryComposeComponent>,
    public appService: AppService,
    public dialog: MatDialog,
    public ioService: IoService,
  ) {
    // this.discussion.title = this.appService.currentUser.school_name ;
    // this.discussion.message = this.appService.currentUser.school_name ;
    this.appService.page_title = "Nhật ký lớp học";
    this.loadCategory();
  
  }

  ngOnInit(): void {
  }

  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
    
  }
  updateDiscussionRecipient(){
    this.discussion.recipient = '';
    this.categorys.forEach(item => {
      if(item.temp_checked == 1){
        this.discussion.recipient += item.code + ",";
      }
    });
   
  }
  confirmSendMessenger(){
    let data = {
      title : 'Xác nhận gửi nội dung',
      msg : 'Đồng ý đề bắt đầu gửi nhật ký lớp học .',
    }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          // close hoặc không close
          this.send();
      
        }
      });
  }
  send(){
    // cập nhật danh sách lớp cho discussion.recipient
    // this.updateDiscussionRecipient();
    this.discussion.recipient = this.class_selected;
    
    if(this.discussion.recipient == ""){
      this.toastr.warning("Thầy cô vui lòng chọn lớp","");
      return;
    }
    // xử lý file đính kèm
    let filename = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.appService.currentUser.code + "_" + Date.now() + "."+ this.fileAttach.name.split('.').pop();
      this.discussion.file_url = environment.class_history + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.messengerService.sendMessenger(this.discussion).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          // nếu có file đính kèm
          if(filename != '')
          this.uploadFileAttach(this.fileAttach, environment.class_history, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm

          // đóng diaglog
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })
    console.log(this.discussion);
  }
  loadCategory() {


    let filter = {
      khoi_code: '',
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.categoryService.getClassByGVCNCode(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          response.forEach(element => {
            // gán class_selected mặc định
            if(this.class_selected == ''){
                this.class_selected = element.code;
            }
          });

          this.categorys = response;
          

        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })



    // let pagingObj = {
    //   viewNumsObj: 50,
    //   pageObj: 1
    // };

    // let filter = {
    //   name: "",
    //   khoi_code: "",
    //   deleted: 0,
    //   type: environment.cateType_class
    // }
    // let sorter = {
    //   sortStr: this.appService.sort_category[0].code,
    // }
    // this.categoryService.loadCategory(filter, sorter , pagingObj).subscribe(
    //   async response => {
    //     if (response) {
    //       this.categorys = response.data;
    //       // this.updateDiscussionRecipient();
    //     }
    //   },
    //   error => {
    //     console.error(error);
    //   })
  }

  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    
    var reader = new FileReader();
    reader.readAsDataURL(this.fileAttach);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
    }

    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
    
  }
  uploadFileAttach(fileUpload: File, des: string, name: string) {
    
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  
}
