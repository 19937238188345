<div class="" role="document">
    <!-- <form class="form-horizontal" #studentAddForm="ngForm" id="form-add-student"> -->
    <div class="modal-header">
        <div class="modal-title">Sổ đánh giá môn học</div>
    </div>
    <div class="modal-body p-3">

        <div class="card-body text-center">
            <a class="avatar-img-list" style="width: 60px;  height: 60px; margin: 0 auto;" href="javascript:;" [style.background-image]="appService.showImageStudent(student.image_url,student.gender)"></a>
            <!-- <img class="img-circle img-bordered card-abs-top-center" [src]="appService.showImageStudent(student.image_url,student.gender)" alt="image" width="60"> -->
            <h4 class="mb-1">
                {{student.full_name}}
            </h4>
            <div class="text-muted font-13">
                <span>{{student.code}}</span>
                <span class="badge-default badge-point mr-1 ml-1"></span>
                <span>{{student.birthday_str}}</span>
            </div>
            <div *ngIf="assessment != undefined" class="assessment-student-cover mt-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">Học tập</span>
                    <span>{{assessment.student.hoctap == '' ? assessment.student.hocluc : assessment.student.hoctap}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">Rèn luyện</span>
                    <span>{{assessment.student.renluyen == '' ? assessment.student.hanhkiem : assessment.student.renluyen}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">TB môn</span>
                    <span>{{assessment.student.tbm}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">Nhận xét</span>
                    <span class="ml-3" style="max-width: 165px;text-align: right;">{{assessment.student.nhanxet == '' ? '...' : assessment.student.nhanxet}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">Ngày vắng</span>
                    <span>{{assessment.student.vang_p}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-bold">Xếp hạng</span>
                    <span>{{assessment.student.rank}}</span>
                </div>
            </div>
            <div *ngIf="assessment != undefined" class="assessment-subject-cover mt-3">
                <div *ngFor="let item of assessment.subjects; let j = index" class="item-subject mb-2">
                    <div style="text-align: left;" class="font-bold">{{item.subject_name}}</div>
                    <div class="d-flex justify-content-between align-items-center ">
                        <div class="mark">
                            <div class="mark_name">TX1</div>
                            <div>{{item.tx1}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">TX2</div>
                            <div>{{item.tx2}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">TX3</div>
                            <div>{{item.tx3}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">TX4</div>
                            <div>{{item.tx4}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">GK</div>
                            <div>{{item.gk}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">CK</div>
                            <div>{{item.ck}}</div>
                        </div>
                        <div class="mark">
                            <div class="mark_name">TBM</div>
                            <div>{{item.tbm}}</div>
                        </div>
                    </div>
                    <div style="text-align: left;"><span>Nhận xét: </span>{{item.nx}}</div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-footer justify-content-around bg-primary-50">


        <button class="btn btn-primary " (click)="onCancelClick()">Đóng</button>
    </div>
    <!-- </form> -->
</div>