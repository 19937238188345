<div class="row mb-2 mt-2 justify-content-between">

  <div class="col-4">
    <div class=" p-2 input-group-icon input-group-icon-left mr-3">
      <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
      <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
      <input id="typeahead-templates" class="form-control" [(ngModel)]="code_filter" [ngbTypeahead]="search" type="text"
        placeholder="Tìm hóa đơn" [resultTemplate]="rt" [inputFormatter]="inputFormatter">
    </div>
    <ng-template #rt let-r="result" let-t="term">
      <div class="row" (click)="onClick(r)" style="width: 450px;">
        <div class="col"> #{{ r['code'] }}</div>
        <div class="col">
          <ngb-highlight [result]="r.name" [term]="t">
          </ngb-highlight>
        </div>
        <div class="col">{{r['price_totol'] | number}} VNĐ</div>
        <div class="col">{{r['customer_name']}}</div>
        <!-- <div class="col"> - {{ (r['created_date']  )}}</div> -->
      </div>
    </ng-template>
  </div>


  <div class="col">
    <div class="d-flex justify-content-end">
      <div class="p-2">
        <select class="custom-select" [(ngModel)]="userNvbhSelected" (change)="userSelected_change()">
          <option value="">Nhân viên : All</option>
          <option [ngValue]="item.id" *ngFor="let item of userNvbhList">{{item.name}}</option>
        </select>
      </div>
      <div class="p-2">
        <select class="custom-select" [(ngModel)]="day_num" (ngModelChange)="day_num_change()">
          <option [ngValue]="item.code" *ngFor="let item of appService.day_nums">{{item.name}}</option>
        </select>
      </div>
      <div class="p-2">
        <select class="custom-select" [(ngModel)]="sale_status" (ngModelChange)="sale_status_change()">
          <option [ngValue]="item.code" *ngFor="let item of appService.sale_statuss">{{item.name}}</option>
        </select>
      </div>

      <div class="d-flex justify-content-end p-2">
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" placeholder="dd/mm/yyyy" name="d2" #c2="ngModel" [(ngModel)]="fromDateStr"
                ngbDatepicker #d2="ngbDatepicker" (dateSelect)="onDateSelect($event)">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary ti ti ti-calendar" (click)="d2.toggle()" type="button">
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>


</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">
  <li class="nav-item" *ngFor="let item of salesTitleByday_result; let i = index">
    <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.dateStr == this.selectedDateStr}"
      (click)="selectDate(item.dateStr)">
      <div style="font-size: large;">{{item.day_month}}</div>
      <div *ngIf="item.sum_price>0">{{item.sum_price | number:0}}<img style="width: 15px;"
          src="./assets/img/icons/vnd.svg"></div>
      <div *ngIf="item.sum_price==0">---</div>
    </a>
  </li>
</ul>
<div class="tab-content">
</div>

<div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
  <span color="primary">Hóa đơn</span> : {{sales.length}}
  <!-- <ion-label color="primary">-</ion-label> : {{sales_sum}} VNĐ -->

  <!-- <div class="btn-group">
    <button class="btn btn-secondary">
        <span class="btn-icon"><i class="ti-heart"></i>Hóa đơn</span>
    </button>
    <span class="btn-label-out btn-label-out-right">{{sales.length}}</span>
</div> -->

</div>


<div class="d-flex flex-wrap mb-5">
  <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of sales; let i = index" (click)="popupSaleDetail(item)"
    [ngClass]="{'cart-done': item.status == 1,'cart-ordering': item.status == 0,'cart-cancel': item.status == 3,'cart-processing': item.status == 4}">
    <div class="rel" style="height: 50px;">
      <!-- <img class="card-img-top" src="./assets/img/invoice.svg" alt="image" style="height: 100px;" /> -->
      <div class="card-img-overlay">
        <div class="row align-items-center">
          <div class="col">
            <img style="width: 16px;" src="./assets/img/icons/vnd.svg">
          </div>
          <div class="col">
            <span style=" font-weight: bold;">{{item.price_totol | number:0}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p style="max-height: 37px; overflow: hidden; text-align: center;">{{item.customer_name}}</p>
      <div class="d-flex align-items-center justify-content-between mt-2">
        <div class="text-center">
          <div style="font-weight: bold;">
            {{item.product_quantity}}</div>
          <div class="text-muted font-11">Mặt hàng</div>
        </div>
        <div class="text-center">
          <div style="font-weight: bold;">
            {{item.quantity}}</div>
          <div class="text-muted font-11">SL</div>
        </div>
      </div>
      <div class="text-right mt-2">
        <div>
          <!-- <span *ngIf="item.status == 1" class="badge-primary badge-point"></span>
          <span *ngIf="item.status == 0" class="badge-warning badge-point"></span> -->

          <span *ngIf="item.customer_pay == item.price_totol" class="badge-primary badge-point"></span>
          <span *ngIf="item.customer_pay != item.price_totol" class="badge-danger badge-point"></span>
          {{item.created_date.substring(11, 16)}}<i class="ti ti-time" style="font-size: 13px;"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row align-items-center mb-2  paging-cover">
  <div class="col-4">
    <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
      {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
  </div>
  <div class="col-8 d-flex justify-content-end">
    <ul class="pagination p-1" style="margin-bottom: 0px;">
      <li>
        <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj"
          (change)="viewNumsObjChange()">
          <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
        </select>
      </li>
      <li class="page-item">
        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
          <span aria-hidden="true">
            <i class="ti ti-angle-left"></i>
          </span>
        </a>
      </li>
      <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)"
        [ngClass]="{'active' : item.i == curentPage}">
        <a class="page-link" href="javascript:;">{{item.i}}</a>
      </li>
      <li class="page-item">
        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
          <i class="ti ti-angle-right"></i>
        </a>
      </li>
    </ul>
  </div>
</div>



<div class="modal fade" id="sale-detail-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" id="form-add-product">
      <div class="modal-header p-3">
        <h5 class="modal-title"> <b>Mã đơn #{{saleSelectedItem.code}}</b></h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-3">
        <div class="chat-list">
          <div class="scroller">
            <div class="">


              <div class="text-right font-13">
                {{saleSelectedItem.created_date.substring(11, 16)}} {{saleSelectedItem.dateStr}}
              </div>

              <div class="row info-row">
                <div class="col">
                  Nhân viên:
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.user_name}}
                </div>
              </div>
              <div class="row info-row" *ngIf="saleSelectedItem.status == 4">
                <div class="col">
                  Nhân viên GH:
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.processing_user_name}}
                </div>
              </div>
              <div class="row info-row">
                <div class="col">
                  Khách hàng:
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.customer_name}}
                </div>
              </div>
              <div class="row info-row">
                <div class="col">
                  Tổng đơn:
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.price_totol | number}}
                </div>
              </div>
              <div class="row info-row">
                <div class="col">
                  Khuyến mãi: {{saleSelectedItem.promotion_promotion}}{{saleSelectedItem.promotion_type}}
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.price_promotion | number}}
                </div>
              </div>
              <div class="row info-row">
                <div class="col">
                  Tổng thanh toán:
                </div>
                <div class="col text-right">
                  {{saleSelectedItem.price_after_promotion | number}}
                </div>
              </div>

              <div class="container">
                <div class="row m-2  justify-content-between">
                  <div *ngIf="saleSelectedItem.status == 0">
                    <button class="btn btn-info btn-labeled btn-labeled-left btn-sm btn-icon"
                      (click)="popupSelectNvgh()">
                      <span class="btn-label"><i class="ti ti-truck"></i></span>
                      Giao hàng
                    </button>
                  </div>
                  <div *ngIf="saleSelectedItem.status == 0">
                    <button class="btn btn-primary btn-labeled btn-labeled-left btn-sm btn-icon" (click)="doPayment()">
                      <span class="btn-label"><i class="ti ti-money"></i></span>
                      Thanh toán
                    </button>
                  </div>
                  <div *ngIf="saleSelectedItem.status == 0">
                    <button class="btn btn-labeled btn-labeled-left btn-sm btn-icon"
                      (click)="openDeleteSale(confirmDelete)">
                      <span class="btn-label"><i class="ti ti-trash"></i></span>
                      Hủy đơn
                    </button>
                    <!-- <div ngbDropdown class="d-inline-block">
                      <button class="btn btn-sm btn-icon" ngbDropdownToggle>
                        <span class="btn-label"><i class="ti ti-trash"></i></span>
                        Hủy đơn
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem  (click)="openDeleteSale(confirmDelete)"><i class="ti ti-trash"></i>Xác nhận hủy đơn hàng</button>
                      </div>
                    </div> -->

                  </div>
                  <div *ngIf="saleSelectedItem.status == 1">
                    <button class="btn btn-labeled btn-labeled-left btn-sm btn-icon"
                      (click)="openReturnsPopup(saleSelectedItem)">
                      <span class="btn-label"><i class="ti ti-back-right"></i></span>
                      Trả hàng
                    </button>
                  </div>
                  <button (click)="print()" class="btn btn-outline-primary btn-icon-only btn-sm"><i
                      class="ti ti-printer"></i>
                  </button>
                </div>
              </div>


              <div *ngIf=" (saleSelectedItem.service_details.length > 0)" style="padding-top: 10px">
                <div *ngFor="let item of saleSelectedItem.service_details; let i = index"
                    style="border-bottom: 1px dashed gray; padding: 10px 0px;">
                    <div class="row"> 
                        <div class="ml-3">
                            <a >
                                <img class="img-circle" src="./assets/img/icons/clock-spinner.png" alt="image" width="35px"/>
                            </a>
                        </div>  
                        <div class="col">
                            <div> Tiền giờ: {{item.price | number}}   x  {{item.quantityLable}}</div>
                            <div class="row">
                                <div class="col">
                                    <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                        <span><i class="ti ti-control-play"></i></span>
                                        <span>: {{item.serviceStartTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                        <span><i class="ti ti-control-pause"></i></span>
                                        <span>: {{item.serviceEndTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mr-3 text-right">
                            {{item.amount  | number}}
                        </div>
                    </div>
                </div>
            </div>

              <table style="width: 100%; margin-top: 20px;">
                <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                  <td style="width: 25px; text-align: center;">
                    <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                    <span class="lbl-stt">{{i+1}}</span>
                  </td>
                  <td style="width: 55%;">
                    <div style="line-height: 16px;">
                      {{item.product_name}} - <span style="color: gray;">{{item.price| number}}</span>
                    </div>
                  </td>
                  <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| number}}</span> </td> -->
                  <td style="text-align: center;">
                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->

                    x {{item.quantity}}
                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                  </td>
                  <td style="text-align: right; color: black;">
                    {{item.quantity * item.price | number:0}}</td>
                </tr>
                <tr style="background: lightgray;line-height: 30px;">
                  <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                  <td style="text-align: center;font-weight: bold;">{{saleSelectedItem.quantity | number}}</td>
                  <td style="text-align: right; color: black; font-weight: bold;">
                    {{saleSelectedItem.price_totol | number}}</td>
                </tr>

              </table>
            </div>
          </div>
        </div>



      </div>

    </form>
  </div>
</div>



<div class="modal fade" id="select-nvgh-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal modal-content-lg" id="select-nvgh-form">
      <div class="modal-header pb-4">
        <h5 class="modal-title">Chọn nhân viên giao hàng</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-3">
        <div class=" p-2 input-group-icon input-group-icon-left mr-3">
          <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
          <input class="form-control" id="key-search" (keydown)="getTypingSearchNvgh($event)" type="text"
            placeholder="Tìm nhân viên hàng">
        </div>



        <div class="mb-2 mt-2">
          <span class=" item-nvbh" *ngFor="let item of memberships; let i = index">
            <label class="radio radio-inline radio-primary">
              <input type="radio" name="e" [value]="item.id" (click)="nvbhSelected = item;"
                [checked]="item === nvbhSelected">
              <span class="input-span"></span>{{item.name}}
            </label>
          </span>
        </div>

      </div>
      <div class="modal-footer bg-primary-50">

        <div class="row ">
          <div class="col ">
            <button class="btn btn-primary btn-labeled btn-labeled-left btn-sm btn-icon" (click)="doShipping()">
              <span class="btn-label"><i class="ti ti-truck"></i></span>
              Giao hàng
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

<ng-template #confirmDelete let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nhập lý do hủy đơn</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class=" col-form-label">Mô tả</label>
      <div class="">
        <input [(ngModel)]="cancel_reason" required class="form-control" name="description" placeholder="Lý do"
          type="text">
        <!-- <textarea class="form-control" [(ngModel)]="product.description" rows="3" placeholder="Mô tả"></textarea> -->
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-around bg-primary-50">
    <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading"
      (click)="confirmDeleteSale()">
      <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
      <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
      Hủy đơn
    </button>

    <button class="btn" (click)="c('Save click')">Đóng</button>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
  </div> -->
</ng-template>

<div id="print-section-on-order" style="display: none;">
  <div style=" font-family: Tahoma; font-size: 14px;">
    <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
    <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
    <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
    <div style="text-align: right">{{saleSelectedItem.created_date.substring(11, 16)}} {{saleSelectedItem.dateStr}}
    </div>
    <table style="font-size: 13px; width: 100%;">
      <tr style="text-align: center; font-weight: bold; ">
        <td style="text-align: left;">Khách hàng: </td>
        <td style="text-align: right;">{{saleSelectedItem.customer_name}}</td>
      </tr>
      <tr style="text-align: center; font-weight: bold; ">
        <td style="text-align: left;">Tổng hóa đơn: </td>
        <td style="text-align: right;">{{saleSelectedItem.price_totol | number}}</td>
      </tr>
      <tr style="text-align: center; font-weight: bold; ">
        <td style="text-align: left;">Khuyến mãi:
          {{saleSelectedItem.promotion_promotion}}{{saleSelectedItem.promotion_type}}</td>
        <td style="text-align: right;">{{saleSelectedItem.price_promotion | number}}</td>
      </tr>
      <tr style="text-align: center; font-weight: bold; ">
        <td style="text-align: left;">Tổng thanh toán: </td>
        <td style="text-align: right;"> {{saleSelectedItem.price_after_promotion | number}} </td>
      </tr>
    </table>

    <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
      <table style="font-size: 12px; width: 100%;">
        <tr style="text-align: center; font-weight: bold; ">
          <td style="vertical-align: middle;"></td>
          <td style="vertical-align: middle;">Tên</td>
          <td style="text-align: right;vertical-align: middle;">ĐG</td>
          <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
          <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
        </tr>
        <tr *ngFor="let item of cartDetail; let i = index">

          <td style="vertical-align: middle;">{{i+1}}</td>
          <td style="vertical-align: middle;">{{item.product_name}}</td>
          <td style="text-align: right;; vertical-align: middle;">{{item.price| number}}</td>
          <td style="text-align: left;; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;">
            </span></td>
          <!-- <td style="text-align: left;; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;">
              {{item.product.unit}}</span></td> -->
          <td style="text-align: right;; vertical-align: middle;">{{item.quantity * item.price | number:0}}</td>

        </tr>
        <tr style="font-weight: bold;">
          <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
          <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
          <td style="border-top: 1px dashed gray;padding-top: 5px;">{{saleSelectedItem.price_totol |
            number}}</td>
        </tr>
      </table>
    </div>
    <div style="text-align: center;">Chúc quý khách vui vẻ, hẹn gặp lại</div>
    <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
  </div>
</div>