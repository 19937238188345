<!-- <div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start">
        <div class="btn-group">
            <div class="btn btn-outline-primary" routerLink="/meal-report">Báo cáo ngày</div>
            <div class="btn btn-primary">Tạm thu bán trú</div>
        </div>
    </div>
    <div class="col-4  p-0 d-flex justify-content-end align-items-center">
    </div>
</div> -->

<div class="month-list scroll-container qbs-scroll" id="scroll-container-month">

    <div class="item" *ngFor="let item of dateRange; let i = index" [ngClass]="{'active': item.selected }" (click)="selectMonth(i, item)">
        <a class="">
            <div class="lable-month">{{item.date_month}}</div>
            <div *ngIf="item.sum_attention != '0'" class="d-flex justify-content-between align-items-center">
                <div><span placement="top" ngbTooltip="Tổng ngày bán trú" class="badge badge-info  mr-2">{{item.sum_tamthu_register | qbscurrency }}</span> </div>
                <div>{{item.sum_tamthu_total_amount | qbscurrency}}</div>
            </div>
            <div *ngIf="item.sum_attention == '0'" class="d-flex justify-content-center align-items-center">
                <span placement="top" ngbTooltip="Chưa có học sinh chốt tạm thu" class="badge badge-default">Chưa chốt</span>
            </div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </div>

</div>


<!-- <div style="padding: 10px;"></div> -->

<div class="row mb-3">
    <div class="col p-0 d-flex">
        <div class="d-flex box-school" style="background-color: #227d3ae3; color: white;">
            <div class="d-flex justify-content-between align-items-center">
                <div class="text-center" style="border-right: 1px solid;
                padding-right: 9px;">
                    <div>{{allSchools.student_register | qbscurrency}}</div>
                    <div class="badge badge-danger">{{allSchools.student_inattention | qbscurrency}}</div>
                </div>
            </div>
            <div class="ml-3">
                <div class="name">Thực tế <span class="num_day2">{{allSchools.student_attention | qbscurrency}}</span></div>
                <div class="value">{{allSchools.total_amount | qbscurrency}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #b12431; color: white ">
            <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.n_1_diff_count | qbscurrency}}</span></div>
            <div class="ml-3">
                <div class="name">Dư N-1</div>
                <div class="value">{{allSchools.n_1_diff_amount | qbscurrency}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color:  #0099cc; color: white;">
            <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.tamtinh_register | qbscurrency}}</span></div>
            <div class="ml-3">
                <div class="name">Tạm tính</div>
                <div class="value">{{allSchools.tamtinh_total_amount | qbscurrency}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #056e91; color: white;">
            <div class="d-flex justify-content-between align-items-center"><span class="num_day">{{allSchools.tamthu_register | qbscurrency}}</span></div>
            <div class="ml-3">
                <div class="name">Tạm thu</div>
                <div class="value">{{allSchools.tamthu_total_amount | qbscurrency}}</div>
            </div>
        </div>
    </div>
    <div class=" d-flex justify-content-end">
        <div class="d-flex box-school" style="background-color: #4CAF50; color: white;">
            <div><span class="material-icons" style="font-size: 51px;"> paid </span></div>
            <div class="ml-3">
                <div class="name">Đã thanh toán</div>
                <div class="value">{{allSchools.month_total_paid | qbscurrency}}</div>
            </div>
        </div>
        <div class="d-flex box-school" style="background-color: #dc3545; color: white;">
            <div><span class="material-icons" style="font-size: 51px;"> paid </span></div>
            <div class="ml-3">
                <div class="name">Chưa thanh toán</div>
                <div class="value">{{(allSchools.tamthu_total_amount - allSchools.month_total_paid) | qbscurrency}}</div>
            </div>
        </div>


    </div>
</div>


<!-- <div class="row m-2 d-flex justify-content-between align-items-center">
    <mat-form-field appearance="fill" style="max-width: 200px;" class="">
        <mat-label>Khối</mat-label>
        <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
            <mat-option value="">
                Tất cả
            </mat-option>
            <mat-option *ngFor="let item of appService.currentUser.khois" [value]="item.code">
                {{item.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="pr-3">
        <div class="btn-highlight " [ngClass]="{'active' : true }">
            <button class="blurButton btn btn-lg btn-primary btn-labeled btn-labeled-left btn-icon">
                <span class="btn-label"><span class="material-icons">
                    task_alt
                </span></span>Đã chốt
            </button>
        </div>
        <div class="btn-highlight " [ngClass]="{'active' : true }">
            <button class="blurButton btn btn-lg btn-primary btn-labeled btn-labeled-left btn-icon">
                <span class="btn-label"><span class="material-icons">
                    task_alt
                </span></span>Đã thu
            </button>
        </div>
    </div>
</div> -->




<div class="row chothu-cover">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 50px; text-align: center;">
                    <div class="my-stt"></div>
                </th>
                <th>Lớp</th>

                <th>Dư N-1</th>
                <th class="text-center" style="width: 150px">
                    <button class="btn btn-gradient-peach btn-labeled btn-labeled-left" (click)="chotthangn1(null)">
                        <span class="btn-label">
                            <span  *ngIf="!isLoading_chotthu" class="material-icons"> task_alt </span>
                            <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                        </span>
                        Chốt N-1
                    </button>
                </th>
                <th class="text-center">Thực tế</th>
                <th class="text-center">Tạm tính</th>
                <th class="text-center">Tạm thu</th>
                <th class="text-center">Thanh toán</th>
                <th class="text-center">

                    <div ngbDropdown class="d-inline-block">

                        <button class="btn btn-white btn-labeled btn-labeled-left" ngbDropdownToggle>
                            <span class="btn-label">
                                <img class="" src="./assets/img/icons/excel.png" alt="image" width="20" />
                            </span>
                            Tải Excel
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="downloadExcel(1)" class="text-primary"><span class="material-icons mr-2">
                                download
                                    </span>
                                    Tổng hợp tháng</button>
                            <button ngbDropdownItem (click)="downloadExcel(2)" class="text-primary"><span class="material-icons mr-2">
                                download
                                    </span>
                                    Chi tiết tháng</button>
                        </div>
                    </div>

                </th>

                <th class="text-right" style="width: 145px;">
                    <button class="btn btn-gradient-peach btn-labeled btn-labeled-left" (click)="chottamthu(null)">
                        <span class="btn-label">
                            <span  *ngIf="!isLoading_chotthu" class="material-icons"> task_alt </span>
                            <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                        </span>
                        Chốt gửi
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.reportcheckins; let i = index" [ngClass]="{'active': item.chotthu != '' }">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                    <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>

                    <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImage(item.image_url, category_avatar)"></a>
                </td>
                <td>
                    <div class="font-bold" style="font-size: 20px;">{{item.class_code}}</div>
                    <div style="font-size: small;">{{item.teacher_name}}</div>
                </td>


                <td>
                    <div class="text-center font-bold">{{item.n_1_diff_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" [ngClass]="{'text-danger': item.n_1_diff_count > 0 }" placement="top" ngbTooltip="Ngày dư tháng trước">
                            <span>{{item.n_1_diff_count | qbscurrency}}</span>
                        </div>
                    </div>
                </td>
                <td class="width_133">
                    <button *ngIf="item.count_dachotn1 == 0 || item.count_dachotn1 == null" class=" btn btn-primary btn-labeled btn-labeled-left " (click)="chotthangn1(item)">
                        <span class="btn-label">
                            <span  *ngIf="!isLoading_chotthu" class="material-icons"> pending_actions </span>
                            <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                        </span>
                        Chốt N-1
                    </button>
                    <button *ngIf="item.count_dachotn1 > 0" style="opacity: 0.9 ;" (click)="chotthangn1(item)" class=" btn btn-success  btn-labeled btn-labeled-left ">
                        <span class="btn-label">
                            <span class="material-icons"> lock </span>
                        </span>
                        Đã chốt {{item.count_dachotn1}}
                    </button>
                </td>
                <td>
                    <div class="text-center ">{{item.total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày: Đăng ký/ Vắng / Có" style="min-width: 100px;">
                            <span style="color: #227d3ae3;">{{item.student_register | qbscurrency}}</span> /
                            <span [ngClass]="{'text-danger': item.student_inattention > 0 }">
                            {{item.student_inattention | qbscurrency}}
                            </span> /
                            <span class="text-primary">{{item.student_attention | qbscurrency}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="text-center ">{{item.tamtinh_total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày tạm tính cả tháng">
                            <span>{{item.tamtinh_register | qbscurrency}}</span>

                        </div>
                    </div>
                </td>

                <td>
                    <div class="text-center font-bold">{{item.tamthu_total_amount | qbscurrency}}</div>

                    <div class="text-center">
                        <div class="meal_count" placement="top" ngbTooltip="Ngày tạm thu = Tạm tính - Dự N1">
                            <span>{{item.tamthu_register | qbscurrency}}</span>

                        </div>
                    </div>
                </td>
                <td class="text-center">
                    {{item.month_total_paid | qbscurrency}}
                    <div class="text-center" *ngIf="item.tamthu_total_amount > 0">
                        <div class="meal_count" placement="top" ngbTooltip="Học sinh: chưa thanh toán/ đã thanh toán">
                            <span [ngClass]="{'text-danger': true }">
                            {{item.count_student - item.count_paid}}
                            </span> /
                            <span class="text-primary">{{item.count_paid}}</span>
                        </div>
                    </div>
                </td>

                <td class="text-center">
                    <button class="blurButton btn btn-white btn-labeled btn-labeled-right btn-icon" (click)="openMealClassDetailMonth(item)">
                        <span class="btn-label">{{item.count_student}}</span>Chi tiết
                    </button>
                </td>

                <td class="text-right width_115">
                    <!-- <div [ngClass]="{'active' : true }" class="btn-highlight"> -->
                    <button class=" btn btn-primary btn-labeled btn-labeled-left " *ngIf="item.count_dagui == 0 || item.count_dagui == null" (click)="chottamthu(item)">
                            <span class="btn-label">
                                <span  *ngIf="!isLoading_chotthu" class="material-icons"> send </span>
                                <span  *ngIf="isLoading_chotthu" class="spinner-border"></span>
                            </span>
                            Chốt gửi
                        </button>
                    <!-- </div> -->
                    <button style="opacity: 0.9 ;" class=" btn btn-success  btn-labeled btn-labeled-right " *ngIf="item.count_dagui > 0" (click)="chottamthu(item)">
                        <span class="btn-label">
                            {{item.count_dagui}}
                        </span>
                        Đã gửi 
                    </button>
                </td>
            </tr>

        </tbody>
    </table>
</div>

<div *ngIf="!isLoading && reportcheckins.length == 0" class="text-center">
    <app-nodata msg="Không có dữ liệu bán trú"></app-nodata>

</div>

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!-- <div class="row lop-cover">
    <div *ngFor="let item of reportcheckins; let i = index" (click)="openMealClassDetail(item)" class="card text-center card-air  m-1 lop-item">
        <div class="card-body">

            <span *ngIf="item.isAvailable == 0" class="material-icons font-18 disableView">visibility_off</span>

            <div class="card-avatar mt-2 mb-2" style="background-position: center; height: 35px; background-size: 35px;
            background-repeat: no-repeat;">
                <img [src]="appService.showImageTeacher2(item.image_url)" alt="Avatar">
            </div>
            <h5 class="card-title text-primary mb-1">Lớp {{item.class_code}}</h5>
            <div class="" style="min-width: 140px; font-size: 18px;">{{item.total_amount | qbscurrency}}</div>
            <div class="mb-2 mt-2 text-center row align-items-center justify-content-center">
             
                <span style=" display: inline-block;">
                    <span class="font-18 font-bold  align-items-center"
                        [ngClass]="{'text-success' : item.student_attention > 0}" style="display: flex;
                flex-wrap: wrap;">
                        <span class="material-icons font-18">description</span>
                <span class="">{{item.student_register}}</span>
                </span>
                </span>
                <span style=" display: inline-block;">
                    <span class="font-18 font-bold ml-3  align-items-center"
                        [ngClass]="{'text-danger' : item.student_inattention > 0}" style="display: flex;
                flex-wrap: wrap;">
                        <span class="material-icons font-18">group_off</span>
                <span class="">{{item.student_inattention}}</span>
                </span>
                </span>
            </div>
            <div class="">
                <div *ngIf="item.student_inattention > 0" class="progress my-danger mb-2">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
                <div *ngIf="item.student_inattention == 0" class="progress my-primary mb-2">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.percent">
                        {{ item.percent }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->