<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">
        <div class="row">
            <div class="col">
                <div style="font-size: larger;" class="mb-2">Nhật ký lớp {{discussionSelected.recipient}} </div>
            </div>
            <div class="col text-right">
                <div class="mb-2">{{discussionSelected.created_date_str}} </div>
            </div>
        </div>



        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussionSelected.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <div class="font-weight-bold">
            {{discussionSelected.title}}
        </div>


        <div class="mt-3 mb-3">{{discussionSelected.message}}</div>
        <div class="mt-3 mb-3 text-center"><img class="img-circle2" [src]="appService.showImageTeacher2(discussionSelected.file_url)" alt="image" /></div>


        <div class="font-italic text-right">
            {{discussionSelected.created_date_str}}
        </div>

        <div *ngIf="discussionSelected.file_url !=''" class="filettach-upload mb-3 mt-3">
            Đính kèm file:
            <a [href]="appService.showFileAttach(discussionSelected.file_url)">
                Tải file
            </a>

            <!-- https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf -->

            <!-- <pdf-viewer [src]="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'" [render-text]="true" [original-size]="false" style="width: 400px; height: 500px"></pdf-viewer> -->
        </div>

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-end">

            <button class="btn btn-primary" (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>