<div class="col d-flex justify-content-between align-items-center p ">
    <div class="card">
        <div class="card-body flexbox">
            <img [src]="appService.showImageTeacher2(data.data.image_url)" alt="Avatar" style="height: 55px; border-radius: 50%;">
            <div class="text-right ml-3">
                <h3 class="mb-1">{{data.data.full_name}}</h3>
                <div class="text-muted">Học sinh</div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-warning" (click)="this.dialogRef.close();">
            <span class="material-icons font-26">
            close
            </span>
        </button>
    </div>
</div>
<div>

    <mat-calendar #myCalendar matCalendarStartDayOfWeek="1" class="calendar-student-detail" [dateClass]="dateClass()" [startAt]="dateSelected" style="margin: 0 auto;"></mat-calendar>

    <div *ngIf="isLoading" class="spinner-loadClass">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>