<div class="p-4">

    <form class=" form-horizontal" #sendMessengerForm="ngForm" id="form-sendMessengerForm">
        <div style="font-size: larger;" class="mb-2">Chi tiết</div>



        <!-- <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Người nhận</mat-label>
                    <input matInput [(ngModel)]="discussionSelected.recipient" name="recipient" maxlength="200" minlength="2" placeholder="Người nhận">
                </mat-form-field> -->
        <div class="font-weight-bold">
            {{discussionSelected.title}} #{{discussionSelected.date_total}} ngày
        </div>


        <div class="mt-3 mb-3">{{discussionSelected.content}}</div>

        <div class="font-bold">
            {{discussionSelected.full_name}} - {{discussionSelected.class_code}}
        </div>

        <div class="font-bold">

        </div>
        <div class="font-italic text-right">
            {{discussionSelected.date_list}}
        </div>


        <div class="text-center mt-2">
            <button *ngIf="discussionSelected.status == 0" class="btn-adjust btn btn-sm btn-warning  btn-circle  btn-thick" (click)='approve()'>
                <span class="material-icons"> pending_actions </span>
                Phê duyệt
            </button>
            <button *ngIf="discussionSelected.status == 1" class="btn-adjust btn btn-sm btn-primary  btn-circle  btn-thick">
                <span class="material-icons"> done </span>
                Đã phê duyệt
            </button>
        </div>

        <div class="m-3" style="display: inline-block;"></div>
        <div class="row justify-content-end ">

            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>