import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { AssessmentService } from 'src/app/_services/assessment.service';
import { SchoolService } from 'src/app/_services/school.service';
import { StudentService } from 'src/app/_services/student.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {
  isLoading = false;
  isHighlight = false;
  classs: any = [];
  classSelected = {
    class_code: "",
    khoi: "",
  };
  subjects = [];
  subjectsSelected = {
    subjects_code: "",
    subjects_name: "",
  };
  students = []; // thông tin học sinh và điểm
  students_temp: any; //
  subject_type_mark = '' // phân biệt môn điểm hay nhận xét
  assignment_type_selected = 0; //Năng lực chung, Năng lực đặc thù, phẩm chất chủ yếu
  // tx_numSetting_nx = [
  //   {
  //     "code": "tx_nx1",
  //     "value": "TX1"
  //   },
  //   {
  //     "code": "tx_nx2",
  //     "value": "TX2"
  //   }
  // ];

  filter = {
    khoi: "",
    hocky: this.appService.hockySelected,
    class_code: ""
  }
  sorter = {
    sortStr: "",
  }
  // cấu hình số điểm TX
  // tx_numSettings = []; //của cả khối
  tx_numSetting = []; //của môn subject
  isLockSetting = 0; //khoá điểm của môn subject
  subject_cap = ''; //khoá điểm của môn subject

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };

  //for checkbox
  checkboxs = [];
  checkboxAllButton = false;
  //for paging
  viewNumsObj = this.appService.viewNums[5].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  constructor(
    public schoolService: SchoolService,
    public appService: AppService,
    public utility: UtilityService,
    private toastr: ToastrService,
    public studentService: StudentService,
    public assessmentService: AssessmentService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Sổ đánh giá môn học";
    this.sorter.sortStr = this.appService.sort_students[0].code;
  }

  ngOnInit(): void {
    this.getClassByTeacher();
  }

  temptField: HTMLInputElement;// cho việc save
  inputField: HTMLInputElement;// cho việc input
  inputField_tenmp: HTMLInputElement;// cho việc focusoutFunction
  focusFunction(id) {
    this.inputField = document.querySelector('input[id=' + id + ']');
    this.inputField.select();
  }
  focusoutFunction(mark, id) {
    mark = this.utility.validMark(mark);
    this.inputField_tenmp = document.querySelector('input[id=' + id + ']')
    this.inputField_tenmp.value = mark;
    console.log(mark);
  }
  focusoutNhanXetFunction(element,i) {
    this.students[i][element] = this.utility.validMarkNhanXet(this.students[i][element]);
    // this.inputField_tenmp = document.querySelector('input[id=' + id + ']')
    // this.inputField_tenmp.value = mark;

    // this.students[6][element] = 'ÔP'
    // console.log(mark);
  }
  // validMark(mark){
  //   mark = this.utility.validMark(mark);
  // }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
  

    if (this.inputField === undefined) {
      return;
    }

    if (event.key === 'không có gì cả') {
      // this.inputField = document.querySelector('input[id=a2-3]');
      // console.log(this.inputField.value);
      // this.inputField.focus();
    } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
      var temp = this.inputField.id.substring(1).split("-");
      if (parseInt(temp[0]) < this.students.length - 1) { // kiểm tra khi xuống dưới
        var nextCellText = "a" + (parseInt(temp[0]) + 1) + "-" + temp[1];
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }

    } else if (event.key === 'ArrowUp') {
      var temp = this.inputField.id.substring(1).split("-");
      if (parseInt(temp[0]) > 0) {
        var nextCellText = "a" + (parseInt(temp[0]) - 1) + "-" + temp[1];
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }

    } else if (event.key === 'ArrowRight') {
      var temp = this.inputField.id.substring(1).split("-");

      if (parseInt(temp[1]) < (this.tx_numSetting.length + 1)) {  // đã cộng thêm 2 cột gk và ck
        var nextCellText = "a" + temp[0] + "-" + (parseInt(temp[1]) + 1);
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }
      else if (parseInt(temp[1]) == (this.tx_numSetting.length + 1)) { // cột cuối thì sang chôt NX nhận xét
        var nextCellText = "a" + temp[0] + "-" + 'nx';
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      } else if (temp[1] == "nx") { // nếu là cột NX nhận xét thì do not thing

      }

    } else if (event.key === 'ArrowLeft') {
      var temp = this.inputField.id.substring(1).split("-");
      if (temp[1] == "nx") { // đang ở cột nhân xét nx
        var nextCellText = "a" + temp[0] + "-" + (this.tx_numSetting.length + 1); // đã cộng thêm 2 cột đề về cột ck
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
      }
      else if (parseInt(temp[1]) > 0) { // nếu chưa phải cột đầu tiên
        var nextCellText = "a" + temp[0] + "-" + (parseInt(temp[1]) - 1);
        console.log(nextCellText);
        this.inputField = document.querySelector('input[id=' + nextCellText + ']');
        this.inputField.focus();
        this.inputField.select();
        // console.log(this.inputField.value);
        // console.log(this.inputField.id);
        // console.log(this.inputField.name);
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode == 44 || charCode == 46 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;
  }
  danhGiaNhanXet(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    // D:68 C:67 Đ:272   d:100  c:99 đ:273
    if (charCode == 44 || charCode == 46 || charCode == 100 || charCode == 99|| charCode == 273
      || charCode == 67 || charCode == 68|| charCode == 70
      || charCode == 273 //Đ
      ) {
      return true;
    }
    return false;
  }

  generateTBMHK() { //Tính TBMHK hoặc TBMCN
    // kiểm tra đã chọn môn học chưa
    if (this.subjectsSelected.subjects_code == '' || this.classSelected.class_code == '') {
      this.toastr.warning('', 'Thầy cô chưa chọn lớp hoặc môn học');
      return;
    }

    let data = {
      hocky: this.appService.hockySelected,
      class_code: this.classSelected.class_code,
      subjects_code: this.subjectsSelected.subjects_code,
    }

    this.assessmentService.generateTBMHK(data).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success(response.affectedRows + "Học sinh", "Thành công");
          this.loadAssessment();
        }
        // this.checkboxs = new Array(this.students.length);
        console.log(response);

      },
      error => {
        this.students = [];
      })
  }
  generateTBMCN() { //Tính TBMHK hoặc TBMCN
    // kiểm tra đã chọn môn học chưa
    if (this.appService.hockySelected != 'II' || this.subjectsSelected.subjects_code == '' || this.classSelected.class_code == '') {
      this.toastr.warning('', 'Thầy cô chưa chọn lớp hoặc môn học');
      return;
    }

    let data = {
      hocky: this.appService.hockySelected,
      class_code: this.classSelected.class_code,
      subjects_code: this.subjectsSelected.subjects_code,
    }

    this.assessmentService.generateTBMCN(data).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success(response.affectedRows + "Học sinh", "Thành công");
          this.loadAssessment();
        }
        // this.checkboxs = new Array(this.students.length);
        console.log(response);

      },
      error => {
        this.students = [];
      })
  }

  hocky_change(hocky) {
    if (this.appService.hockySelected != hocky) {
      this.appService.hockySelected = hocky;
      // lưu vào storage
      localStorage.setItem(environment.hockySelected, this.appService.hockySelected);
      // xủ lý với lớp
      this.classSelected.class_code = '';
      this.getClassByTeacher();
      // xử lý với môn
      this.subjects = [];
      this.subjectsSelected.subjects_code = '';
      this.subjectsSelected.subjects_name = '';
      // xủ lý với học sinh
      this.students = [];
    }
  }
  async getClassByTeacher() {
    let data = {
      // khoi: this.filter.khoi,
      // type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getClassByTeacher(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async getSubjectsByClass() {
    if (this.classSelected == undefined || this.classSelected.class_code == "") {
      this.toastr.warning("", "Vui lòng chọn lớp trước");
      return
    }
    let data = {
      // khoi: this.filter.khoi,
      // type: environment.cateType_class,
      hocky: this.appService.hockySelected,
      class_code: this.classSelected.class_code
    };
    this.schoolService.getSubjectsByClass(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.subjects = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  selectClass(item) {
    this.classSelected = item;
    this.students = [];
    this.subjectsSelected = {
      subjects_code: "",
      subjects_name: "",
    };
    this.getSubjectsByClass();
  }
  selectSubject(item) {
    this.subjectsSelected = item;

    this.loadAssessmentSetting();
    // this.loadStudent();
    this.loadAssessment();
    // this.getSubjectsByClass();
  }
  loadAssessmentSetting() {
    let data = {
      hocky: this.appService.hockySelected,
      khoi: this.utility.checkAndGetKhoi_code(this.classSelected.khoi, this.appService.currentUser.khois),
      subjects_code: this.subjectsSelected.subjects_code,
    };


    this.isLockSetting = 0;

    this.assessmentService.loadAssessmentSetting(data).subscribe(
      response => {
        if (response.length > 0) {
          let tx_num = response[0].tx_num;
          this.tx_numSetting = [];
          this.isLockSetting = response[0].isLock;
          this.subject_cap = response[0].subject_cap;
          for (let i = 0; i < tx_num; i++) {
            this.tx_numSetting.push({
              "code": "tx" + (i + 1),
              "value": "TX" + (i + 1)
            });
          }

        } else {
          this.tx_numSetting = [];
          this.toastr.warning("Nhà trường chưa cấu hình cột điểm TX", "");
        }
      },
      error => {
        this.tx_numSetting = [];
        this.toastr.error("Có lỗi khi tải dữ liệu cài đặt đành giá", "");
        console.error(error);
      });

  }


  sort_change($event) {
    // this.loadStudent();
    this.loadAssessment();
  }
  loadStudent_khong_dung() {

    let data = {
      hocky: this.appService.hockySelected,
      class_code: this.classSelected.class_code,
      subjects_code: this.subjectsSelected.subjects_code
    }

    if (true) {
      this.checkboxs = [];
      this.assessmentService.loadStudentAssessment(data).subscribe(
        async response => {
          console.log(response);
          if (response != null && response.length > 0) {

            response.forEach(markObject => {
              this.checkboxs.push(false);
              if (markObject.mark_columns != null && markObject.mark_columns != "") {
                var marks = markObject.marks.split(",");
                var mark_columns = markObject.mark_columns.split(",");
                var teacher_codes = markObject.teacher_codes.split(",");
                var modified_dates = markObject.modified_dates.split(",");
                var markObjects = [];
                marks.forEach((item, i) => {
                  markObjects.push({
                    "mark": item,
                    "mark_column": mark_columns[i],
                    "teacher_code": teacher_codes[i],
                    "modified_date": modified_dates[i],
                  });
                });
                markObject.markObjects = markObjects;

              }
            });
          }
          // this.students = response;
          console.log(this.students);
        },
        error => {

        })
    }
  }
  loadAssessment() {
    let data = {
      hocky: this.appService.hockySelected,
      class_code: this.classSelected.class_code,
      subjects_code: this.subjectsSelected.subjects_code
    }

    this.checkboxs = [];
    this.checkboxAllButton = false;
    this.isHighlight = false;
    this.assessmentService.loadAssessment(data).subscribe(
      async response => {
        this.students = response;
        // lấy kiểu điểm của môn
        this.subject_type_mark = this.students.length > 0 ? this.students[0].subject_type_mark : '';
        this.students_temp = response;
        this.checkboxs = new Array(this.students.length);
        console.log(response);

      },
      error => {
        this.students = [];
        this.subject_type_mark = '';
      })

  }


  // temptF: HTMLInputElement;// cho việc save
  saveAssessmentStudent() {
    // if (this.appService.currentUser.positions == 'admin') {
    //   this.toastr.warning('', 'Chỉ giáo viên mới được nhập điểm');
    //   return;
    // }
    if (this.students.length == 0) {
      this.toastr.warning('Không có dữ liệu', 'Thầy cô chọn lớp và môn để nhập điểm');
      return;
    }
    this.isLoading = true;
    this.students.forEach(element => {
      if (element.id == null || element.id == "") {
        ;
        element.school_code = this.appService.currentUser.school_code;
        element.scholastic = this.appService.currentUser.scholastic;
        element.teacher_code = this.appService.currentUser.code;
        element.hocky = this.appService.hockySelected;
        element.student_code = element.code;
        element.subjects_code = this.subjectsSelected.subjects_code;
      }
    });
    console.log(this.students);
    this.assessmentService.saveAssessment(this.students).subscribe(
      response => {
        this.isHighlight = false;
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          this.toastr.success("Cập nhật điểm thành công", response.affectedRows);
          this.loadAssessment();
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      });
  }
  deleteAssessmentStudent() {
    let data = [];
    this.checkboxs.forEach((item, i) => {
      if (item == true) {
        data.push({
          id: this.students[i].id
        });
      }
    });
    if (data.length == 0) {
      this.toastr.warning("Thầy cô chưa chọn học sinh để xóa điểm");
      return;
    }

    // popup confirm
    let tempdata = {
      title: 'Xác nhận xóa đánh giá ?',
      msg: 'lớp ' + this.classSelected.class_code + ' môn ' + this.subjectsSelected.subjects_name,
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.assessmentService.deleteAssessment(data).subscribe(
          response => {
            this.isLoading = false;
            console.log(response);
            if (typeof response != "undefined" && response.code == "ok") {
              this.toastr.success("Xóa điểm thành công", response.affectedRows);
              this.loadAssessment();
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
            console.error(error);
          });
      }
    });
  }
  updateVisibleAssessment(visible) {
    let data = [];
    this.checkboxs.forEach((item, i) => {
      if (item == true) {
        data.push({
          id: this.students[i].id,
          visible: visible
        });
      }
    });
    if (data.length == 0) {
      this.toastr.warning("Thầy cô chưa chọn học sinh để thao táo");
      return;
    }

    // popup confirm
    let tempdata = {
      title: visible == 1 ? 'Xác nhận mở xem điểm ?':'Xác nhận khóa xem điểm',
      msg: 'lớp ' + this.classSelected.class_code + ' môn ' + this.subjectsSelected.subjects_name,
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.assessmentService.updateAssessment(data).subscribe(
          response => {
            this.isLoading = false;
            console.log(response);
            if (typeof response != "undefined" && response.code == "ok") {
              this.toastr.success("Cập nhật thành công", response.affectedRows);
              this.loadAssessment();
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "Đã có lỗi xảy ra");
            console.error(error);
          });
      }
    });
  }

  checkboxAll(evt) {
    for (let index = 0; index < this.checkboxs.length; index++) {
      this.checkboxs[index] = evt.target.checked;
    }
  }

  sendAssessmentNotification(){
    let data = [];
    this.checkboxs.forEach((item, i) => {
      if (item == true) {
        data.push({
          id: this.students[i].id,
          visible: 1 // chuyển về true và gửi
        });
      }
    });
    if (data.length == 0) {
      this.toastr.warning("Thầy cô chưa chọn học sinh để thao táo");
      return;
    }

    // popup confirm
    let tempdata = {
      title: 'Xác nhận mở xem điểm và gửi thông báo đến Phụ huynh học sinh ?',
      msg: 'lớp ' + this.classSelected.class_code + ' môn ' + this.subjectsSelected.subjects_name,
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });

    let param = {
      data: data,
      class_code: this.classSelected.class_code,
      subjects_code: this.subjectsSelected.subjects_code,
      subjects_name: this.subjectsSelected.subjects_name,
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.assessmentService.updateAndSendAssessmentNotification(param).subscribe(
          response => {
            this.isLoading = false;
            console.log(response);
            if (typeof response != "undefined" && response.code == "ok") {
              this.toastr.success("Gửi thông báo thành công", response.affectedRows);
              this.loadAssessment();
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "Đã có lỗi xảy ra");
            console.error(error);
          });
      }
    });
  }
  popupCongThucTBM() {
    let data = {
      title: 'Cách tính điểm trung bình môn học kì',
      msg: 'Đối với cách tính điểm trung bình môn học kì thì tại khoản 1 Điều 9 Thông tư 22/2021/TT-BGDĐT quy định cụ thể như sau:',
      img: './assets/img/assessment/qbsedu-tbmcn.jpg',
      ok: 'Ok'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
      }
    });
  }
  popupCongThucTBM_NX() {
    let data = {
      title: 'Cách tính điểm trung bình môn học kì',
      msg: 'Đối với cách tính điểm trung bình môn học kì thì tại khoản 1 Điều 9 Thông tư 22/2021/TT-BGDĐT quy định cụ thể như sau:',
      img: './assets/img/assessment/qbsedu-tbmcn.jpg',
      ok: 'Ok'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
      }
    });
  }
  change_assignment_type(i){
      this.assignment_type_selected = i;
  }
  onInputChange() {
    this.isHighlight = true;
    // Thực hiện các xử lý khác ở đây nếu cần
  }
  popup_export() {

  }
  popup_import() {
    console.log(this.checkboxs);
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    $("#import-student-modal").modal('show');
  }


}
