import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-student-home',
  templateUrl: './student-home.component.html',
  styleUrls: ['./student-home.component.css']
})
export class StudentHomeComponent implements OnInit {
  // selected: Date | null;
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  renderer: any;//handle month change

  dateSelected = "";
  selectedDate_date : Date;
  checkinDate = [];// hiển thị những ngày checkin trên calender

  checkinDayDetail = []; // chi tiết checkin trong ngày được chọn

  constructor(
    public appService : AppService,
    public utilityService : UtilityService,
    public schoolService : SchoolService,
  ) { 
    this.appService.page_title = "Phân hệ Phụ huynh học sinh";
    this.selectedDate_date = new Date();
    this.dateSelected = utilityService.getFullDateStrFromDate(this.selectedDate_date);
    this.reportcheckinStudentDetail();
    this.reportcheckinStudentDayDetail()
    
  }

  ngOnInit(): void {
  }

  reportcheckinStudentDetail() {
    this.dateSelected = this.utilityService.getFullDateStrFromDate(this.selectedDate_date);
    let data = {
      student_code: this.appService.currentUser.code,
      dateSelected: this.dateSelected,
      user_type: 'phhs'
    };
    this.schoolService.checkinStudentTeacherDetail(data).subscribe(
      async response => {
        this.checkinDate = response;
        //update calender9
        this.calendar.updateTodaysDate();
      },
      error => {
        console.error(error);
      })
  }
  reportcheckinStudentDayDetail() {
 
    let data = {
      student_code: this.appService.currentUser.code,
      dateSelected: this.dateSelected,
    };
    this.schoolService.reportcheckinStudentDayDetail(data).subscribe(
      async response => {
        this.checkinDayDetail = response;
        //update calender
      },
      error => {
        console.error(error);
      })
  }
  onSelect(event){
    console.log(event);
    this.dateSelected = this.utilityService.getFullDateStrFromDate(event);
    this.reportcheckinStudentDayDetail();
  }
  handleMonthSelected(event) {
    console.log("Month changed", event);
    this.selectedDate_date= event;
    this.reportcheckinStudentDetail();
    //handler logic
  }
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      let date_temp = this.utilityService.getFullDateStrFromDate(date);

      let result="";
      this.checkinDate.forEach(element => {
          if(date_temp == element.date){
              if(element.isCheckin == 1){
                result = 'special-date';
              }
              return;
          }
      });
      return result;
    };
  }

}
