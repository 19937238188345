import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { PopupCopyNextScheduleDetailComponent } from '../../teaching-schedules/popup-copy-next-schedule-detail/popup-copy-next-schedule-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { SchedulesMealAddComponent } from '../schedules-meal-add/schedules-meal-add.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

// export interface Meal {
//   name: string;
//   items: string[];
// }

// export interface DailyMenu {
//   day: string;
//   meals: {
//     breakfast: Meal;
//     lunch: Meal;
//     snack: Meal;
//     dinner: Meal;
//   };
// }

@Component({
  selector: 'app-schedules-meal',
  templateUrl: './schedules-meal.component.html',
  styleUrls: ['./schedules-meal.component.css']
})
export class SchedulesMealComponent implements OnInit {
  meal_color = ['#17a2b829','#ff980057','#28a74545','#ff408142','#12323']
  MENUd = [];
  meal_schedules = [];
  ms_selected_index = -1;
  constructor(
    public dialog: MatDialog,
    public appService: AppService,
    public categoryeduService: CategoryEduService,
    private utility: UtilityService,
    private toastr: ToastrService,
  ) { 
    this.appService.page_title = "Thực đơn bán trú";
    this.loadCategoryMealSchedule();
  }

  ngOnInit(): void {
  }
  editingItem: string = ''; // Giá trị tạm thời cho món ăn đang sửa
  editState: { [key: string]: boolean } = {}; // Trạng thái đang chỉnh sửa

  
  deleteMeal(items, index: number) {
    items.splice(index, 1);
  }

  // Hàm xử lý kéo-thả
  drop(event: CdkDragDrop<string[]>, items) {
    moveItemInArray(items, event.previousIndex, event.currentIndex);
  }
  newMeal = {
    day: '',
    mealType: '',
    mealItem: ''
  };
  addNew(items){
    items.push({code:'', value :''});
  }
  selectCate(i){
    this.ms_selected_index = i;
    if(this.meal_schedules[this.ms_selected_index].khoi != "")
    this.MENUd = JSON.parse(this.meal_schedules[this.ms_selected_index].khoi); 
    
    // Xửu lý ngày trong tuần
    let temp_weeks = this.meal_schedules[this.ms_selected_index].checkin_weeks.split("w");
    console.log(temp_weeks);
    this.MENUd.forEach(itemA => {
        // Kiểm tra nếu code trong A có tồn tại trong B
        const existsInB = this.meal_schedules[this.ms_selected_index].checkin_weeks.includes(itemA.week);
        // const existsInB = temp_weeks.some(itemB => itemB == itemA.week);
  
        // Cập nhật giá trị checked của phần tử trong A
        itemA.checked = existsInB;
    });
    // lưu index vào storage
    localStorage.setItem(environment.cateType_meal_schedule, this.ms_selected_index + "");
  }
  loadCategoryMealSchedule() {

    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1
    };
    let filter = {
      type: environment.cateType_meal_schedule
    }
    let sorter = {
      sortStr: 'hockyI_startTime',
    }

    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.meal_schedules = response.data;
          if(this.meal_schedules.length > 0){
            // Thực hiện lấy selected mặc định
            let temp  = localStorage.getItem(environment.cateType_meal_schedule);
            console.log("localStorage.getItem:",temp);
            if (temp != null && temp != "" &&  Number(temp) <= this.meal_schedules.length -1) {
              this.ms_selected_index = parseInt(localStorage.getItem(environment.cateType_meal_schedule));
            }else{
              this.ms_selected_index = this.utility.getIndexCurrentDate(this.meal_schedules);
            }
            
            this.selectCate(this.ms_selected_index);
          }
          else{
            // this.meal_schedules.push({
            //   name: 'tuần đầu tiên',
            //   hockyI_startTime: '',
            //   hockyI_endTime: '',
            // });
          }
        

          
        }
      },
      error => {
        console.error(error);
      })
  }
  changeCheckbox(event) {
    console.log(event);
    console.log(this.appService.weeks);
  }
  deleteMealSchedule(){
    if(this.ms_selected_index == -1){
      this.toastr.warning("Lịch không hợp lệ để xóa");
      return;
    }
     // popup confirm
     let tempdata = {
      title: 'Xác nhận xóa lịch thực đơn ?',
      msg: '',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        var data = {
          id: this.meal_schedules[this.ms_selected_index].id,
          deleted: 1,
        }
        this.categoryeduService.updateByID(data).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok") {
              this.toastr.success("", "Xóa thành công");
              this.meal_schedules.splice(this.ms_selected_index, 1);
            }
          },
          error => {
            console.error(error);
          });
      }
    });


  }
  copyUpdateMealSchedule(type){
    if(this.meal_schedules.length == 0){
      this.toastr.warning("Không có dữ liệu để copy");
      return;
    }
  
    const dialogRef = this.dialog.open(SchedulesMealAddComponent , {
      data: {data: this.meal_schedules[this.ms_selected_index],
        type: type
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.code == 'ok') {
          this.loadCategoryMealSchedule();
      }
    });
  }
  createMealSchedule(){
    const dialogRef = this.dialog.open(SchedulesMealAddComponent , {
      data: {data: null,
        type: 'create'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.code == 'ok') {
          this.loadCategoryMealSchedule();
      }
    });
  }
  verifiedMealSchedule(index, status){
    this.meal_schedules[index].khoi = JSON.stringify(this.MENUd);
    var data = {
      id: this.meal_schedules[index].id,
      khoi: this.meal_schedules[index].khoi,
      status: status,
    }
    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật thực đơn thành công");
          this.meal_schedules[index].status = status;
        }else{
          this.toastr.warning("Rất tiếc", "Đã có lỗi xảy ra");
        }
      },
      error => {
        console.error(error);
      });
  }
  saveMealSchedule(index){
    if(this.meal_schedules.length == 0){
      this.toastr.warning("Chưa có lịch được chọn");
      return;
    }

    this.meal_schedules[index].khoi = JSON.stringify(this.MENUd);
    var data = {
      id: this.meal_schedules[index].id,
      khoi: this.meal_schedules[index].khoi
    }
    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật thực đơn thành công");
        }
      },
      error => {
        console.error(error);
      });
  }
  addCategory(){
    var data = {
      name:'Tuần 1',
      hockyI_startTime:'07/10/2024',
      hockyI_endTime:'13/10/2024'
    }
    const dialogRef = this.dialog.open(SchedulesMealAddComponent , {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.code == 'ok') {

      }
    });
  }
}
