import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';
import { PopupGenSchdeduleDetailComponent } from '../popup-gen-schdedule-detail/popup-gen-schdedule-detail.component';
import { TeacherService } from 'src/app/_services/teacher.service';
import { environment } from 'src/environments/environment';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';

@Component({
  selector: 'app-popup-edit-schedule-detail',
  templateUrl: './popup-edit-schedule-detail.component.html',
  styleUrls: ['./popup-edit-schedule-detail.component.css'],
})
export class PopupEditScheduleDetailComponent implements OnInit {
  item : any;
  action = '';
  type_name = '';
  duration = '';
  teacher_list = [];
  checkin_date : any;
  checkin_time_teacher: any // danh sách lịch dạy  theo giáo viên
  class_list = [];
  subject_list = [];
  khoicosos = [];
  classAddMode = false;
  classAddName = '';
  classAddKhoiCoso = '';
  isLoading = false;
  constructor(
    public appService: AppService,
    public categoryeduService: CategoryEduService,
    private teacherService: TeacherService,
    private utility: UtilityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<PopupEditScheduleDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.action = data.action;
    
    this.type_name = this.action=='edit'?'Cập nhật lịch dạy': this.action=='copy'?'Copy lịch dạy': 'Tạo lịch dạy';
    this.item = data.data;
    this.checkin_date = utility.dateStrToDate(this.item.checkin_date);
    if(this.action == 'create') this.item.type=0; //mặc định là 0 học trực tiếp
    this.duration = this.utility.calculateDuration(this.item.checkin_start, this.item.checkin_end);
    this.loadTeacher();
  }

  ngOnInit(): void {
    this.loadClassAndSubject(this.item.teacher_code);
    this.loadKhoicoso();
  }
  checkinChanged(event){
    this.duration = this.utility.calculateDuration(this.item.checkin_start, this.item.checkin_end);
  }
  loadTeacher() {
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1,
    };
    let filter = {};
    let sorter = {
      sortStr: 'name ASC',
    };

    if(!this.appService.checkFullPermission()){ // nếu không phải là admin.
      this.teacher_list.push({
          code: this.appService.currentUser.code,
          name: this.appService.currentUser.name,
      });
      this.item.teacher_code = this.appService.currentUser.code; // mặc định lựa chọn.
    }else{
      this.teacherService.loadTeacher(filter, sorter, pagingObj).subscribe(
        async (response) => {
          // this.isLoading.dismiss();
          console.log(response);
          if (response) {
            this.teacher_list = response.data;
            // $('#category').selectpicker();
          }
        },
        (error) => {
          // this.isLoading.dismiss();
          console.error(error);
        }
      );
    }
  }
  loadClassAndSubject(teacher_code) {
    if(teacher_code == undefined || teacher_code == '') return;
    let filter = {
      teacher_code: teacher_code,
    };
    this.class_list = [];
    this.subject_list = [];
    this.categoryeduService.loadSomeDataTeacherAssignment(filter).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {

          this.class_list = response.class_list;
          this.subject_list = response.subject_list;
          if(this.class_list.length == 1){
              this.item.class_code = this.class_list[0].code
          }
          if(this.subject_list.length == 1){
            this.item.subject_code = this.subject_list[0].code
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  loadKhoicoso() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let filter = {
        type : environment.cateType_khoicoso
    }
    let sorter = {
      sortStr: 'od'
    }
    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
            this.khoicosos = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  selectType(type){
    this.item.type = type;
  }

  okClick(){
    
    if(this.item.teacher_code == undefined || this.item.teacher_code == ''){
      this.toastr.warning("Vui lòng chọn giáo viên");
      return;
    }
    if(this.item.class_code == undefined || this.item.class_code == ''){
      this.toastr.warning("Vui lòng chọn Lớp");
      return;
    }
    if(this.item.subject_code == undefined || this.item.subject_code == ''){
      this.toastr.warning("Vui lòng chọn Môn");
      return;
    }
    if(this.item.checkin_start == undefined || this.item.checkin_start == ''){
      this.toastr.warning("Vui lòng chọn giờ bắt đầu");
      return;
    }
    if(this.item.checkin_end == undefined || this.item.checkin_end == ''){
      this.toastr.warning("Vui lòng chọn giờ kết thúc");
      return;
    }

    let data = {
      id : this.item.id,
      code_parent : this.item.ass_code,
      checkin_date : this.utility.formatDateToStr(this.checkin_date),
      checkin_start : this.item.checkin_start,
      checkin_end : this.item.checkin_end,
      teacher_code : this.item.teacher_code,
      class_code : this.item.class_code,
      subject_code : this.item.subject_code,
      type : this.item.type,
      action : this.action,
    }
    console.log(data);
    this.teacherService.updateSomeScheduleDetailByID(data).subscribe(
      async (response) => {
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success( 'Cập nhật/Copy lịch dạy thành công','Thành công');
          this.dialogRef.close({code: 'ok', msg: ''});
        }else{
          this.toastr.warning(response.msg,'Có lỗi xảy ra');
          console.error("có lỗi xảy ra");
        }
      },
      (error) => {
        console.error('error:');
        console.error(error);
      }
    );
  
    
  }
  cancelClick() {
    this.dialogRef.close();
  }
  changeTeacher(){
    this.loadClassAndSubject(this.item.teacher_code);
  }
  changeModeClassAdd(value){
    this.classAddMode = value;
    console.log(this.classAddMode);
  }
  addAndSelectClass() {
    if (this.classAddName.trim() == "") {
      this.toastr.warning("", "Vui lòng tên lớp");
      return false;
    }
    if (this.classAddKhoiCoso == "") {
      this.toastr.warning("", "Vui chọn khối/Cơ sở");
      return false;
    }

    let cate = {
      type: environment.cateType_class,
      name: this.classAddName,
      // code: environment.cateType_class,
    }


 
      this.isLoading = true;
      this.categoryeduService.insertCategory(cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response && response.code == 'ok') {
            this.toastr.success("", "Thêm mới thành công");
            this.classAddMode = false;
            // thêm thủ công Lớp vào danh sách
            this.class_list.push({
              name: this.classAddName,
              code: response.insertedCode
            })
            this.item.class_code = response.insertedCode;//gán lại code vừa thêm mới xong.
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    
  }
}
