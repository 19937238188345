import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-update',
  templateUrl: './teacher-update.component.html',
  styleUrls: ['./teacher-update.component.css']
})
export class TeacherUpdateComponent implements OnInit {
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  // for update
  teacherSelected: any;
  isDeleted: boolean;
  teacherSelected_imageUrl: any = environment.teacher_avatar;
  birthday_temp : Date;
  fileteacherImg: File;
  isLoading = false;
  facultys: any;

  isRessetPass: boolean = false;
  hide = true;// for password
  password: string = "";
  repassword: string = "";

  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private ng2ImgMax: Ng2ImgMaxService,
    public ioService: IoService,
    public dialogRef: MatDialogRef<TeacherUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.teacherSelected = data.teacherSelected;
    this.birthday_temp = this.utilityService.getDateFromStr(this.teacherSelected.birthday_str);
    this.facultys = data.facultys;
    if(this.teacherSelected.image_url != ""){
      this.teacherSelected_imageUrl = this.appService.getUrlImage(this.teacherSelected.image_url);
    }else{
      this.teacherSelected_imageUrl = environment.teacher_avatar;
    }
    this.isDeleted = this.teacherSelected.deleted == 0 ? false : true;

   }

  ngOnInit(): void {
  }
  updateTeacher() {

    if (this.teacherSelected.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên");
      this.teacherUpdateForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } 

    if (this.isRessetPass) {
      if (this.password.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập mật khẩu");
        this.teacherUpdateForm.controls.pass.setErrors("Vui lòng nhập mật khẩu");
        return false;
      } else if (this.password != this.repassword) {
        this.toastr.warning("", "Mật khẩu chưa trùng khớp");
        this.teacherUpdateForm.controls.repasss.setErrors("Mật khẩu chưa trùng khớp");
        return false;
      }
      this.teacherSelected.isResset = 1
      this.teacherSelected.password = this.password;
    }




    if (this.fileteacherImg !== null && typeof(this.fileteacherImg) !== "undefined") {
      this.teacherSelected.image_url = environment.teacher + "/" + this.teacherSelected.code + "." + this.utilityService.getExtensionFile(this.fileteacherImg);
      console.log("1111");
    } else {
      console.log("2222");
    }

    // xử lý birthday 
    this.teacherSelected.birthday = this.utilityService.getFullDateStrFromDate(this.birthday_temp);

    if (!this.teacherUpdateForm.invalid) {
      console.log("3333");
      this.teacherSelected.deleted = this.isDeleted ? 1 : 0;
      // thay thế , bằng - trong name
    this.teacherSelected.name = this.teacherSelected.name.replace(",","-");
      this.isLoading = true;
      
      this.teacherService.update(this.teacherSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            // this.toastr.success("", "Cập nhật hàng hóa thành công");
            // $("#update-teacher-modal").modal('hide');
            // this.loadTeacher();
            let result = {
              code: "ok",
              data: {}
            }
            this.dialogRef.close(result);
          }
        },
        error => {
          this.isLoading = false;
          console.error("error:");
          console.error(error);
        })
        this.uploadAvatar(this.fileteacherImg, this.teacherSelected.code);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.teacher,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  async avatarChangeListener($event): Promise<void> {
    this.fileteacherImg = $event.target.files[0];
    if (this.fileteacherImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
        this.fileteacherImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileteacherImg);
        reader.onload = (_event) => {
          this.teacherSelected_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  focusOutCheckEmail(email) {
    let filter = {
      name: 'email', //cột kiểm tra
      value: email, // giá trị kiểm tra
    }
    console.log(filter);
    this.teacherService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.teacherUpdateForm.controls.email.setErrors("Đã tồn tại email");
            this.toastr.error("Đã tồn tại Email","Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
}
