<div class="d-flex justify-content-start ">
    <div style="padding-top: 7px; padding-right: 10px;">
        Tháng
    </div>

    <select class="custom-select" style="width: 80px;" [(ngModel)]="months_selected" name="months_selected" id="months_selected"
        (change)="monthYearSelected_change()">
        <option [ngValue]="item" *ngFor="let item of months">
            {{item}} 
        </option>
    </select>


    <select class="custom-select" style="width: 100px;" [(ngModel)]="years_selected" name="years_selected" id="years_selected"
        (change)="monthYearSelected_change()">
        <option [ngValue]="item" *ngFor="let item of years">{{item}}</option>
    </select>
</div>
