import { environment } from './../../environments/environment';

export class Product {
    name : string = "";
    code: string ="";
    price: number;
    price_in: number;
    category_code ="DM";
    category_code_brand = "BRAND";
    image_url:string = "";
    description:string ="";
    unit ="";
    packing ="";
    color ="sa";
    constructor(unit) {
        this.category_code = environment.CATEGORY_CODE_PREFIX;
        this.unit = unit;
    }
}
