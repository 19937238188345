import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employee-picker',
  templateUrl: './employee-picker.component.html',
  styleUrls: ['./employee-picker.component.css']
})
export class EmployeePickerComponent implements OnInit {
  public selected: string = "";
  // user selected

  userNvbhList = [];
  constructor(
      public userService: UserService,
      public dialogRef: MatDialogRef<EmployeePickerComponent>,
      @Inject(MAT_DIALOG_DATA)
      public data: any
      ) 
  { 
    this.loadMembershipVNBH();
    console.log(data);
    this.selected = this.data.selected;
  }

  ngOnInit(): void {
  }

  async loadMembershipVNBH() {
    let filter = {
      // role: "nvbh,admin"
    };
    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.userNvbhList = response;
        }
      },
      error => {
        console.error(error);
      })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(item): void {

    let result = {
      code: "ok",
      id: item.id,
      name: item.name
    }
    this.dialogRef.close(result);
  }

}
