import { Component,Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { StudentService } from 'src/app/_services/student.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-register-image-camera',
  templateUrl: './student-register-image-camera.component.html',
  styleUrls: ['./student-register-image-camera.component.css']
})
export class StudentRegisterImageCameraComponent implements OnInit {
  @ViewChild('registerImageForm') registerImageForm: HTMLFormElement;
  studentSelected: any;
  studentSelected_imageUrl: any = environment.student_avatar;
  studentSelected_takeaphoto_imageUrl: any = environment.take_a_photo_avatar;
  fileTakeAPhotoImg: File;
  isLoading = false;
  constructor(
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private toastr: ToastrService,
    public studentService: StudentService,
    private utility: UtilityService,
    public ioService: IoService,
    public dialogRef: MatDialogRef<StudentRegisterImageCameraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.studentSelected = data;
    if (this.studentSelected.image_url != "") {
      this.studentSelected_imageUrl = this.appService.getUrlImage(this.studentSelected.image_url);
    } else {
      this.studentSelected_imageUrl = environment.student_avatar;
    }
   }

  ngOnInit(): void {

  }
  uploadImageAndRegisterChecIn() {
 

    if (this.fileTakeAPhotoImg == null || typeof(this.fileTakeAPhotoImg) == "undefined") {
      this.toastr.warning("Thầy cô chưa chọn ảnh để đăng ký");
      return;
    } else {
      console.log("2222");
    }


    let param = {
      name: this.studentSelected.full_name,
      title: 'Học Sinh',
      // ABE type_camera == 2
      student_code : this.studentSelected.code, 
      class_code : this.studentSelected.class_code,  
      // name: this.studentSelected.full_name,
      type_user: 'phhs',
      checkin_type_status: this.studentSelected.checkin_type_status,

    }
    
    this.uploadAndRegisterFaceId(this.fileTakeAPhotoImg, param);
    // }else{
    //   // đây là bản cũ theo Hanet
    //   this.uploadAndRegisterFaceIdHanet(this.fileTakeAPhotoImg, param, this.studentSelected.checkin_status);
    // }
    
  }
  private uploadAndRegisterFaceId(fileTakeAPhotoImg, param) {
    this.isLoading = true;
    this.ioService.uploadAndRegisterFaceId(fileTakeAPhotoImg, param).subscribe(
      async (response) => {
        this.isLoading = false;
        if(response.returnCode == "ok" || response.returnCode == 1){
          this.toastr.success("Thành công", response.returnMessage);
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }else{
          this.toastr.warning("Đã có lỗi xảy ra", response.returnMessage);
        }
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
        
      });
  }
  private uploadAndRegisterFaceIdHanet(fileTakeAPhotoImg, param, checkin_status) {
    this.isLoading = true;
    this.ioService.uploadImageAndRegisterChecIn(fileTakeAPhotoImg, param, checkin_status).subscribe(
      async (response) => {
        this.isLoading = false;
        console.log('Đăng ký mới');
        if (response.returnCode == -9007) {
          this.toastr.warning("Hình ảnh học sinh đã được đăng ký", 'Thầy/Cô thử lại ảnh khác rõ hơn', {
            timeOut: 7000,
          });
        } else if (response.returnCode == -9005) {
          this.toastr.warning("Học sinh đã được đăng ký", 'Hoặc Thầy/Cô chọn cập nhật ảnh', {
            timeOut: 7000,
          });
          // cập nhật là học sinh này đã đăng ký ảnh.
          this.updateStudentCheckin_status(2, response.data.personID);

        } else if (response.returnCode == -9006) {
          this.toastr.warning("Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào máy ảnh, không đội mũ, đeo khẩu trang", 'Hình ảnh không hợp lệ', {
            timeOut: 7000,
          });
        } else if (response.returnCode == 1) { // đăng ký hoặc cập nhật
          this.toastr.success("Đăng ký thành công", 'Đăng ký ảnh điểm danh thành công');
          // cập nhật là học sinh này đã đăng ký ảnh. và cập nhật ảnh đại diện
          this.updateStudentCheckin_status(2, response.data.personID);
          //upload ảnh làm ảnh đại diện
          this.uploadAvatar(fileTakeAPhotoImg, this.studentSelected.code);
        }
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
        var response = error.error;
        if (response.returnCode == -9007) {
          this.toastr.warning("Hình ảnh học sinh đã được đăng ký", 'Thầy/Cô thử lại ảnh khác rõ hơn', {
            timeOut: 7000,
          });
        } else if (response.returnCode == -9005) {
          this.toastr.warning("Học sinh đã được đăng ký", 'Hoặc Thầy/Cô chọn cập nhật ảnh', {
            timeOut: 7000,
          });
          // cập nhật là học sinh này đã đăng ký ảnh.
          this.updateStudentCheckin_status(2, null);

        } else if (response.returnCode == -101) {
          this.toastr.warning("Các Camera đang offline", 'Vui lòng kiểm tra lại camera', {
            timeOut: 7000,
          });
        } else if (response.returnCode == -9006) {
          this.toastr.warning("Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào máy ảnh, không đội mũ, đeo khẩu trang", 'Hình ảnh không hợp lệ', {
            timeOut: 7000,
          });
        }
      });
  }

  updateStudentCheckin_status(status, personID) {
    if(personID != null && personID != "")
    this.studentSelected.personID = personID; // đã đăng ký ảnh
    this.studentSelected.checkin_type_status = status; // đã đăng ký ảnh
    this.studentSelected.image_url = environment.student + "/" + this.studentSelected.code + "." + this.utility.getExtensionFile(this.fileTakeAPhotoImg);
    this.studentSelected.birthday = ''; // đặt '' để không phải cập nhật ngày sinh
    console.log("Này nè");
    this.studentService.update(this.studentSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
              let result = {
                code: "ok",
                data: {}
              }
              this.dialogRef.close(result);
          }
        },
        error => {
          this.isLoading = false;
          console.error("error:");
          console.error(error);
        })
  }

  async takeaphotoChange($event): Promise<void> {
    this.fileTakeAPhotoImg = $event.target.files[0];
    if (this.fileTakeAPhotoImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileTakeAPhotoImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileTakeAPhotoImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileTakeAPhotoImg, 1280, 738).subscribe(result => {
        this.fileTakeAPhotoImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileTakeAPhotoImg);
        reader.onload = (_event) => {
          this.studentSelected_takeaphoto_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.student,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

}
