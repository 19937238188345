import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/_services/utility.service';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-application-compose',
  templateUrl: './application-compose.component.html',
  styleUrls: ['./application-compose.component.css']
})
export class ApplicationComposeComponent implements OnInit {
  selectedDateStr: string;
  selectedDateList = [];
  fromDate: Date | null;

  categorys : any; // lớp
  checkboxAllButton = true; // mặc định chọn all
  data ={
    title: 'Giấy nghỉ phép',
    content: '',
    // teacher_code: this.appService.currentUser.code,
    user_type: 'cbgv',
  };
  isLoading = false;

  fileAttach: File;
 
  constructor(
    public categoryeduService: CategoryEduService,
    public studentService: StudentService,
    public schoolService: SchoolService,
    public dialogRef: MatDialogRef<ApplicationComposeComponent>,
    public appService: AppService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public ioService: IoService,
    public utilityService: UtilityService,
  ) {
    this.appService.page_title = "Liên lạc, thông báo";
    this.loadCategory();
  
    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);
  }

  ngOnInit(): void {
  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
    console.log(this.selectedDateStr);

    if(this.selectedDateList.indexOf(this.selectedDateStr) > -1){
      this.toastr.warning("", "Ngày đã tồn tại");
    }else{
      this.selectedDateList.push(this.selectedDateStr);
    }
  }

  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
    
  }
  // updateDiscussionRecipient(){
  //   this.discussion.recipient = '';
  //   this.categorys.forEach(item => {
  //     if(item.temp_checked == 1){
  //       this.discussion.recipient += item.code + ",";
  //     }
  //   });
   
  // }
  removeDate(index){
    console.log(index);
    this.selectedDateList.splice(index, 1);
  }
  confirmSend(){
    if(this.selectedDateList.length == 0){
      this.toastr.warning("Thầy cô vui lòng chọn ngày nghỉ","");
      return;
    }
    let data = {
      title : 'Xác nhận gửi giấy phép',
      msg : 'Nhấn gửi để xác nhận.',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        // close hoặc không close
        this.send();
    
      }
    });
  }
  send(){
    // cập nhật danh sách lớp cho discussion.recipient
    // this.updateDiscussionRecipient();

    this.data['date_list'] = this.selectedDateList;
    this.data['date_total'] = this.selectedDateList.length;

    this.schoolService.applicationCreate(this.data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          
          // đóng diaglog
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })

  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: 50,
      pageObj: 1
    };

    let filter = {
      name: "",
      khoi_code: "",
      deleted: 0,
      type: environment.cateType_class
    }
    let sorter = {
      sortStr: this.appService.sort_category[0].code,
    }
    this.categoryeduService.loadCategory(filter, sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          // this.updateDiscussionRecipient();
        }
      },
      error => {
        console.error(error);
      })
  }

  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
    
  }
  uploadFileAttach(fileUpload: File, des: string, name: string) {
    
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  
}

