<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <img [src]="appService.showImageTeacher2(data.class_checkin.image_url)" alt="Avatar" style="height: 55px; border-radius: 50%;">
                <div class="text-right">
                    <h3 class="mb-1">{{data.class_checkin.teacher_name}}</h3>
                    <div class="text-muted">Giáo viên chủ nhiệm</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.class_checkin.code}}</h2>
                    <div class="">Lớp</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.class_checkin.student_count}}</h2>
                    <div class="">Sỹ số</div>
                </div>
                <div class="text-center">
                    <h2 class="mb-1">{{data.class_checkin.student_inattention}}</h2>
                    <div class="">Vắng</div>
                </div>
                <!-- <div class="text-center">
                    <h2 class="mb-1">{{data.class_checkin.student_inattention / data.class_checkin.student_count}}</h2>
                    <div class="">Tỷ lệ</div>
                </div> -->
                <div class="text-center">
                    <button class="btn btn-warning" (click)="this.dialogRef.close();">
                        <span class="material-icons font-26">
                        close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 45px; text-align: center;">#</th>
                <th>Họ tên</th>
                <th>Tên</th>
                <!-- <th>Ngày sinh</th> -->
                <!-- <th class="text-right"></th> -->
                <th>Phụ huynh</th>
                <th style="width: 40px;">Kiểu</th>
                <th>Điểm danh</th>
                <th>Thời gian/Lý do vắng</th>
                <!-- <th>Có phép</th> -->

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.students; let i = index" [ngClass]="{'bg-primary-100': item.checkin_times != '' }">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">


                    <a class="avatar-img-list" style="width: 30px;  height: 30px; position: relative;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)">
                        <span class="stt_span"> <span class="badge badge-default badge-circle">{{i+1}}</span></span>
                    </a>
                </td>
                <td>
                    <a class="d-flex justify-content-start align-items-center" (click)="open_student_detail(item)">
                        <div>
                            <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                        </div>
                        <div>
                            <div style="font-size: 18px;">
                                {{item.full_name}}
                            </div>
                            <div class="text-muted">
                                {{item.code}}
                            </div>
                        </div>
                    </a>

                </td>
                <td>
                    <div style="font-size: 18px;">{{item.name}}</div>
                </td>
                <!-- <td>{{item.birthday}}</td> -->
                <td>
                    <div>{{item.parents_name}}</div>
                    <div>{{item.parents_phone}}</div>
                </td>
                <td class="text-center">

                    <div style="position: relative;">
                        <img *ngIf="item.checkin_type_status >0 " style="width: 30px;" src="./assets/img/icons/camera-checkin.png" />
                        <img *ngIf="item.checkin_type_status == 0" style="width: 30px;" src="./assets/img/icons/to-do-list.png" />
                        <!-- <span *ngIf="item.image_count > 0" style="position: absolute;
                        right: -7px;
                        font-size: small;
                        background: white;
                        border-radius: 50%;
                        padding: 2px;">{{item.image_count}}</span> -->
                    </div>
                    <!-- 
                    <img *ngIf="item.type==1" class="img-circle" src="./assets/img/icons/camera-checkin.png" alt="image" width="30" /> -->
                    <!-- <img *ngIf="item.type!=1" class="img-circle" src="./assets/img/icons/teacher2.png" alt="image" width="30" /> -->
                </td>
                <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" disabled="true"  [ngModel]="item.checkin_status_temp" >
                        <span class="input-span"></span>
                    </label>
                </td>
                <td class="">
                    <div>
                        {{item.checkin_note_note}}
                    </div>
                    <div style="max-height: 166px;  overflow: auto;" class="qbs-scroll">
                        <div *ngFor="let item2 of item.checkin_times; let j = index" class="times-item-cover">
                            <!-- <a>
                                <img [src]="item.detected_image_urls[j]" *ngIf="j < 3 || j >= item.detected_image_urls.length - 3" />
                                <div>{{item2}}</div>
                            </a> -->

                            <ng-template #tipContent>Hello<img *ngIf="item.detected_image_urls[j] != ''" [src]="appService.showCheckinImage(item.detected_image_urls[j])" /></ng-template>
                            <div class="content" [ngbTooltip]="tipContent" tooltipClass="my-tooltip-class">
                                <img [src]="appService.showCheckinImage(item.detected_image_urls[j])" />
                                <div>{{item2}}</div>
                            </div>


                        </div>
                    </div>

                </td>
                <!-- <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" disabled="true" [ngModel]="!item.checkin_status_temp" >
                        <span class="input-span"></span>
                    </label>
                </td> -->

            </tr>
        </tbody>
    </table>
    <div *ngIf="isLoading" class="spinner-loadClass">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>