import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckinStudentDetailComponent } from 'src/app/components/checkin-sutdent-detail/checkin-student-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-checkin-class-detail',
  templateUrl: './checkin-class-detail.component.html',
  styleUrls: ['./checkin-class-detail.component.css']
})
export class CheckinClassDetailComponent implements OnInit {
  students = [];
  attention_count = 0;
  isLoading = false;
  constructor(
    public appService: AppService,
    private schoolService: SchoolService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CheckinClassDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
    this.loadReportCheckinClassDetail();
  }

  ngOnInit(): void {

  }

  loadReportCheckinClassDetail() {

    let data = {
      class_code: this.data.class_checkin.code,
      dateSelected: this.data.dateSelected,
    };
    this.isLoading = true;
    this.schoolService.reportCheckinClassDetail(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          this.attention_count = 0;
          response.forEach(element => {
            element.parents_name = element.mother_name != '' ? element.mother_name : element.father_name;
            element.parents_phone = element.mother_phone != '' ? element.mother_phone : element.father_phone;

            // if( element.checkin_type_status == 0){ // điểm danh thủ công
            //   element.checkin_status_temp = (element.checkin_status == null || element.checkin_status == 0) ? true : false
            // }else if(element.checkin_type_status > 0){// điểm danh camera AI, 1: đã đăng ký, 2 đã cập nhật ảnh
            //   element.checkin_status_temp = (element.checkin_status == 1 )  ? false : true
            // }else{
            //   element.checkin_status_temp = false;
            // }

            if(element.checkin_status_temp) this.attention_count++;

            // Đưa time và ảnh vào mảng
            if(element.checkin_times == null) element.checkin_times = [];
            else
            element.checkin_times = element.checkin_times.split(",");
            if(element.detected_image_urls == null) element.detected_image_urls = [];
            else
            element.detected_image_urls = element.detected_image_urls.split(",");

          });
          this.students = response;
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  open_student_detail(item){
    const dialogRef = this.dialog.open(CheckinStudentDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { data: item, dateSelected: this.data.dateSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }
}
