<!-- <div class="page-heading">
    <h1 class="page-title">Danh sách Học sinh</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="#"><span class="material-icons sidebar-item-icon-material">home</span></a>
        </li>
        <li class="breadcrumb-item">Danh sách Học sinh</li>
    </ol>
</div> -->

<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2">
        <div [ngClass]="{'cameraSelected' : -1 == this.cameraSelected}" class="ibox shadow-wide camera-item">
            <div class="ibox-body text-center">
                <img src="./assets/img/icons/camera.png" alt="Avatar" style="height: 30px;">
                <h5 class="font-strong text-muted">Lớp :</h5>
            </div>
        </div>
        <div *ngFor="let item of cameras; let i = index" [ngClass]="{'cameraSelected' : i == this.cameraSelected}" (click)="selectCamera(i,item)" class="ibox shadow-wide camera-item">
            <div class="ibox-body text-center">
                <img src="./assets/img/icons/camera.png" alt="Avatar" style="height: 30px;">
                <h5 class="font-strong">Lớp {{item.deviceName}}</h5>
            </div>
        </div>
        <div *ngIf="cameras.length == 0" class="align-items-center ibox shadow-wide camera-item">
            <div class="ibox-body text-center">
                <h5 class="font-strong text-warning">Chưa có lớp nào đăng ký sử dụng Camera điểm danh</h5>
            </div>
        </div>

    </div>

    <div>
        <div class="row align-items-center mb-2">
            <div class="col-4">
                <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Họ tên</th>
                        <th>Tên</th>
                        <th>Giới tính</th>
                        <th>Ngày sinh</th>
                        <th>Lớp</th>
                        <!-- <th class="text-right"></th> -->
                        <th class="text-center" style="width: 165px;"><img style="width: 35px; " src="./assets/img/icons/camera-student.png" /></th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.students; let i = index">
                        <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                            <span class="stt_span">{{i+1}}</span>
                            <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.full_name}}</div>
                            <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                                {{item.code}}
                            </div>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.name}}</div>
                        </td>
                        <td>{{appService.showGender(item.gender)}}</td>
                        <td>{{item.birthday}}</td>
                        <td>{{item.class_code}}</td>
                        <!-- <td class="text-right">{{item.price| number:0}}</td> -->

                        <td>
                            <!-- btn-outline-primary -->
                            <button *ngIf="item.checkin_type_status == 1" class="btn btn-primary btn-fix btn-rounded" (click)='popupUpdate(item)'>
                                <span class="btn-icon">
                                    <i class="ti ti-plus"></i>
                                    Đăng ký ảnh
                                </span>
                            </button>
                            <button *ngIf="item.checkin_type_status == 2" class="btn btn-info btn-fix btn-rounded" (click)='popupUpdate(item)'>
                                <span class="btn-icon">
                                    <i class="ti ti-pencil-alt"></i>
                                    Cập nhật ảnh
                                </span>
                            </button>
                            <div *ngIf="item.checkin_type_status == 0" class="text-muted">
                                Chưa đăng ký
                            </div>

                        </td>

                    </tr>
                    <tr>
                        <td colspan="6">

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="this.students.length == 0" class="text-center text-warning m-5 p-5">
            <img style="width: 80px;" src="assets\img\icons\finder.png" alt="">
            <div>Thầy/Cô vui lòng chọn Camera</div>
        </div>

    </div>



</div>

<div class="modal fade " id="update-student-modal">
    <div class="modal-dialog " role="document">
        <!-- (ngSubmit)="insertStudent()" -->
        <form class="modal-content modal-content-lg-2 form-horizontal" #teacherUpdateForm="ngForm" id="form-update-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Tải ảnh Học Sinh để đăng ký điểm danh ???</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">



                <div class="row">
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Họ và tên</label>
                            <div class="col-sm-9">
                                <input [(ngModel)]="studentSelected.full_name" class="form-control" name="full_name" readonly placeholder="Họ và tên" type="text">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ngày sinh</label>
                            <div class="col-sm-9">
                                <input [(ngModel)]="studentSelected.birthday" class="form-control" name="birthday_update" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="flexbox-b">
                            <div class="image-upload">
                                <div class="img-avatar" [style.background-image]="'url('+studentSelected_imageUrl+')'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="tab-content">
                    <div class="tab-pane fade nav-link active show" id="tab-update-coban" aria-expanded="true">
                        <div class="row">
                            <div class="col">
                                <div class="text-center font-weight-bold mb-2">Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào máy ảnh, không đội mũ, đeo khẩu trang. Không dùng app chỉnh sửa làm đẹp</div>
                                <div class="form-group text-center">
                                    <div class="image-upload">
                                        <div class="camera-student" [style.background-image]="'url('+studentSelected_takeaphoto_imageUrl+')'">
                                        </div>
                                        <input *ngIf="!isLoading" type="file" accept="image/gif, image/jpeg, image/png" id="camera-student" name="upload2File" (change)="takeaphotoChange($event)">
                                        <div *ngIf="isLoading" class="row align-items-center justify-content-center" style="position: absolute; top: 0; margin: 0px; height: 288px; width: 100%;background: #0d12149c; color: white;
                                        font-size: xxx-large;">
                                            <span class="spinner-border"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center font-14 ">Thời gian đăng ký ảnh điểm danh sẽ lâu hơn so với tháo tác bình thường khác</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button *ngIf="studentSelected.checkin_type_status == 1" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.teacherUpdateForm.invalid" (click)="uploadImageAndRegisterChecIn()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Đăng ký ảnh điểm danh
                </button>
                <button *ngIf="studentSelected.checkin_type_status == 2" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.teacherUpdateForm.invalid" (click)="uploadImageAndRegisterChecIn()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Cập nhật ảnh điểm danh
                </button>


                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
        </form>
    </div>
</div>