import { Ng2ImgMaxService } from 'ng2-img-max';
import { Product } from './../../model/product';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  @ViewChild('productAddForm') productAddForm: HTMLFormElement;
  @ViewChild('productUpdateForm') productUpdateForm: HTMLFormElement;

  isLoading = false;

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  countProduct = 0;
  // scan code
  productCodeType = 'mts';
  codeScan = "";
  productCodeTemp = "";

  //for color
  colorList = [{ code: "black" }, { code: "white" }, { code: "gray" }, { code: "red" }, { code: "green" }, { code: "blue" }, { code: "yellow" }];




  products = [];
  products_backup: any
  categorys: any
  categorys_brand: any
  filter = {
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  

  // for add
  product = new Product("");
  fileProductImg: File;
  imageUrl: any = environment.product_avatar;
  // for update
  productSelected: any;
  isDeleted: boolean;
  productSelected_imageUrl: any = environment.product_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public productService: ProductService,
    public categoryService: CategoryService,
    public ioService: IoService,
    private toastr: ToastrService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
  ) {
    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);
  }


  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event);
    if (this.productCodeType == 'qmv') {
      if (event.key === 'Enter') {  // enter from key broad
        console.log(this.codeScan);
        this.product.code = this.codeScan;
        this.codeScan = "";
      } else {
        if (this.codeScan === null) {
          this.codeScan = event.key;
        } else {
          this.codeScan = this.codeScan + event.key;
        }
      }
    }
  }




  ngOnInit() {
    this.loadProduct();
    this.loadCategory();
    this.loadCategoryBrand();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadProduct();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  };

  // loadTotalPage() {
  //   // request to server
  //   this.productService.loadItemSum(this.filter)
  //     .subscribe(
  //       response => {
  //         console.log('ItemListComponent load Item');
  //         console.log(response);
  //         this.totalItems = response[0].NumberOfItems;
  //         let pages_temp = Math.ceil(this.totalItems / this.viewNumsObj);
  //         this.listPage = [];
  //         for (let i = 1; i <= pages_temp; i++) {
  //           this.listPage.push({ i: i });
  //         }
  //         console.log(pages_temp);
  //         console.log(this.listPage);
  //       },
  //       error => {
  //         console.error(error);
  //       });
  // };

  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
    this.imageUrl = environment.product_avatar;
    this.fileProductImg = null;
    // this.product.category_code = "DM";
    // this.product.category_code_brand = "BRAND";

    console.log(this.product.unit);
  }

  loadProduct() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            // this.products = response.data;
            // this.products_backup = response.data;
            this.appService.products = response.data;
            this.appService.products_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
            
          }
        },
        error => {

        })
    }
  }
  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  popup_product_add() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter

    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    this.countAllProduct();
    $("#import-product-modal").modal('show');
  }

  popupUpdate(item) {
    this.productSelected = item;
    if(this.productSelected.image_url != ""){
      this.productSelected_imageUrl = this.appService.getUrlImage(this.productSelected.image_url);
    }else{
      this.productSelected_imageUrl = environment.product_avatar;
    }
    this.isDeleted = this.productSelected.deleted == 0 ? false : true;
    $("#update-product-modal").modal('show');
  }

  category_change(event) {
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.products = this.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.products = this.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  insertProduct() {

    if (this.product.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.productAddForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.product.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }


    if (this.product.price == null) {
      this.product.price = 0;
    }
    if (this.product.price_in == null) {
      this.product.price_in = 0;
    }

    if (this.fileProductImg !== null) {
      this.product.image_url = environment.product + "/" + this.product.code + "." + this.utility.getExtensionFile(this.fileProductImg);
      console.log("1111");
    } else {
      console.log("2222");
    }
    // thay thế , bằng - trong name
    this.product.name = this.product.name.replace(",","-");

    if (!this.productAddForm.invalid) {
      this.isLoading = true;
      this.productService.insert(this.product).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            $("#add-product-modal").modal('hide');
            this.toastr.success("", "Thêm mới hàng hóa thành công");
            this.resetProduct();
            this.loadProduct();
          } else {
            this.toastr.error("", "Mã sản phẩm đã tồn tại hoặc Có lỗi xảy ra.");
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
      this.uploadAvatar(this.fileProductImg, this.product.code);
    }
  }

  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof(fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.product,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  // closePopup(){
  //   console.log("closePopup");
  //   $("#add-product-modal").modal('hide');
  // }
  updateProduct() {

    if (this.productSelected.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.productUpdateForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.productSelected.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }


    if (this.productSelected.price == null) {
      this.productSelected.price = 0;
    }
    if (this.productSelected.price_in == null) {
      this.productSelected.price_in = 0;
    }

    if (this.fileProductImg !== null && typeof(this.fileProductImg) !== "undefined") {
      this.productSelected.image_url = environment.product + "/" + this.productSelected.code + "." + this.utility.getExtensionFile(this.fileProductImg);
      console.log("1111");
    } else {
      console.log("2222");
    }

    if (!this.productAddForm.invalid) {
      this.productSelected.deleted = this.isDeleted ? 1 : 0;
      // thay thế , bằng - trong name
    this.productSelected.name = this.productSelected.name.replace(",","-");
      this.isLoading = true;
      this.productService.update(this.productSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Cập nhật hàng hóa thành công");
            $("#update-product-modal").modal('hide');
            this.loadProduct();
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
        this.uploadAvatar(this.fileProductImg, this.productSelected.code);
    }
  }
  countAllProduct() {
    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response.CountAllProduct) + 1).toString();
        this.productCodeTemp = this.appService.currentUser.shop_code + (parseInt(response.CountAllProduct) + 1).toString();
        this.countProduct = response.CountAllProduct;
      },
      error => {
        console.error(error);
      })
  }
  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  upload() {
    

    if (this.file == null || typeof(this.file) == "undefined") {
      this.toastr.warning("", "Vui lòng chọn file");
      return;
    } 
    
    this.isLoading = true;
    console.log(this.file);
    this.productService.importProduct(this.file, this.countProduct).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.value > 0) {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadProduct();
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  popup_product_export() {
    // let filter = {};
    this.productService.exportProduct(this.filter);
  }
  productCodeTypeClick(type) {
    this.productCodeType = type;
    if (this.productCodeType == "mts") {
      this.product.code = this.productCodeTemp;
    } else if (this.productCodeType == "tn" || this.productCodeType == "qmv") {
      this.product.code = "";
    }
  }
  focusOutCheckCode() {

    let filter = {
      code: this.product.code,
    }
    console.log(filter);
    this.productService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.productAddForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  async avatarChangeListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async avatarChangeUpdateListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.productSelected_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupUpdate(r);
    }
}


