import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { AssignmentClassPickerComponent } from 'src/app/components/assignment-class-picker/assignment-class-picker.component';
import { ArrangeCategoryComponent } from 'src/app/components/arrange-category/arrange-category.component';
declare var $: any;

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;

  categorys: any;
  categorys_backup: any

  cateType = environment.cateType_subjects;
  // for add
  cate = new Category(environment.cateType_subjects);
  // for update
  categoryselected = new Category(environment.cateType_subjects);
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;

  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  // for action
  action_mode = "";

  sorter = {
    sortStr: this.appService.sort_category[0].code,
  }

  filter = {
    type: this.cateType,
    name: "",
    subject_cap: ''
  }



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Môn học";
    this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onSubmit() {
    console.log(this.formGroup);
  }
  clearSearch() {
    this.filter.name = '';
    this.loadCategory();
  }
  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {
    // $("#form-add-category").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar;
  }

  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {

          //thêm selected_temp để cho action
          response.data.forEach(element => {
            element.selected_temp = false;
            // chuyển danh sách lớp sang array
            element.assignment_class_codes = element.assignment_class_codes != null ? element.assignment_class_codes.split(",") : [];
            element.assignment_class_names = element.assignment_class_names != null ? element.assignment_class_names.split(",") : [];

          });

          this.categorys = response.data;
          this.categorys_backup = response.data;


          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {

    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập mã");
      return false;
    }
    
    var temp_code = ''
    if(this.cate.code == ''){
      temp_code = this.cate.subject_cap == '' ? this.cate.name : this.cate.subject_cap + "_" + this.cate.name;
    }else{
      temp_code = this.cate.subject_cap == '' ? this.cate.code : this.cate.subject_cap + "_" + this.cate.code;
    }
    this.cate.code = this.utility.convertViToEn(temp_code);


    if (this.file !== null) {
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }

    this.cate.subject_type = 'batbuoc';

    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          this.cate.code = '';
          console.error(error);
        })
      this.uploadAvatar(this.file, this.cate.code);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null) {
      return;
    }
    let param = {
      des: this.cateType,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);

      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }



  // countAllCategory() {
  //   this.categoryeduService.countAllCategory(this.cateType).subscribe(
  //     response => {
  //       this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
  //       this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
  //       // console.log("this.cate.code",this.cate.code);
  //     },
  //     error => {

  //       console.error(error);
  //     })

  // }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {
    this.resetCategory();
    // this.countAllCategory();
    $("#add-category-modal").modal('show');
  }
  popupUpdate(item) {
    this.categoryselected = item;
    this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;
    $("#update-category-modal").modal('show');
  }

  updateCategory() {

    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.categoryselected_file !== null) {
      this.categoryselected.image_url = this.cateType + "/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }
    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    let data={
      code : this.categoryselected.code,
      name : this.categoryselected.name,
      image_url : this.categoryselected.image_url,
      subject_type : this.categoryselected.subject_type,
      subject_type_mark : this.categoryselected.subject_type_mark,
      subject_cap : this.categoryselected.subject_cap,
      subject_factor : this.categoryselected.subject_factor,
      subject_lesson_w_i : this.categoryselected.subject_lesson_w_i,
      subject_lesson_w_ii : this.categoryselected.subject_lesson_w_ii,
      deleted : this.categoryselected.deleted,
    }
    this.isLoading = true;
    this.categoryeduService.updateAny(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
    this.uploadAvatar(this.categoryselected_file, this.categoryselected.code);
  }

  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if (this.file == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async changeImageUpdate($event): Promise<void> {
    this.categoryselected_file = $event.target.files[0];
    if (this.categoryselected_file == null) {
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
        this.categoryselected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.categoryselected_file);
        reader.onload = (_event) => {
          this.categoryselected_imageUrl = reader.result;
          console.log(this.categoryselected_imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  popup_mode(mode) {

    this.action_mode = mode;
    if (this.action_mode == 'camera') {// thích thế

    } else if (this.action_mode == 'changeClass') {

    }
  }
  checkboxAll(evt) {

    this.categorys.forEach(element => {
      element.selected_temp = evt.target.checked;
    });

  }
  processChangeAssignment_class() {
    let data = [];
    this.categorys.forEach(element => {
      if (element.selected_temp) {
        data.push({
          code: element.code,
          assignment_class_codes: element.assignment_class_codes
        });
      }
    });
    console.log(data);
    if (data.length == 0) {
      this.toastr.warning("", "Thầy cô chưa chọn môn để gán");
      return;
    }
    // mở popup
    const dialogRef = this.dialog.open(AssignmentClassPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("class_code", result);
        console.log("students", data);
        // this.toastr.success("", "Gán môn học cho lớp thành công");
        // this.loadCategory();
        let param = {
          type: environment.cateType_assignment_class_subject,
          obj_codes: result.data, // class list
          obj_code2s: data
        }
        console.log(data);
        this.categoryeduService.saveMultiCategorys(param).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("", "Gán môn học cho lớp thành công");
              this.loadCategory();
            }
          },
          error => {
            console.error("error:");
            console.error(error);
          });

      }
    });
  }


  /** sort */
  openSaveArrange(){

      const dialogRef = this.dialog.open(ArrangeCategoryComponent, {
        data: this.categorys
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (typeof result != "undefined" && result.code == "ok") {          
          this.loadCategory();
        }
      });
  }

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }

}
