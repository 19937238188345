<style>
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./assets/img/blog/17.jpeg');
    }

    .cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(117, 54, 230, .1);
    }

    .login-content {
        max-width: 400px;
        margin: 100px auto 50px;
    }

    .auth-head-icon {
        position: relative;
        height: 60px;
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        background-color: #fff;
        color: #5c6bc0;
        box-shadow: 0 5px 20px #d6dee4;
        border-radius: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
</style>

<div class="overlay"></div>
<div class="cover"></div>
<div class="ibox login-content">
    <div class="text-center">
        <span class="auth-head-icon"><i class="ti ti-user"></i></span>
    </div>
    <form class="ibox-body" id="login-form" action="javascript:;" method="POST">
        <h4 class="font-strong text-center mb-5">LOG IN</h4>
        <div class="form-group mb-4">
            <input class="form-control form-control-line" type="text" name="email" placeholder="Email">
        </div>
        <div class="form-group mb-4">
            <input class="form-control form-control-line" type="password" name="password" placeholder="Password">
        </div>
        <div class="flexbox mb-5">
            <span>
                <label class="ui-switch switch-icon mr-2 mb-0">
                    <input type="checkbox" checked="">
                    <span></span>
                </label>Remember</span>
            <a class="text-primary" routerLink="/forgot_password">Forgot password?</a>
        </div>
        <div class="text-center mb-4">
            <button class="btn btn-primary btn-rounded btn-block">LOGIN</button>
        </div>
    </form>
</div>
