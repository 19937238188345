
export class HealthRecord {
    id :number = 0;
    school_code :string ="";
    period_time :string ="";
    student_code :string ="";
    conclude :string ="";
    staff :string ="";
    attached_file :string ="";
    status :number = 0;
    type :number = 0;
    donvi :string ="";
 
    tl_cannang :string ="";
    tl_chieucao :string ="";
    tl_chisobmi :string ="";
    tl_tamthu :string ="";
    tl_tamtruong :string ="";
    tl_nhiptim :string ="";
    tl_staff :string ="";
    nk_tuanhoan :string ="Bình thường";
    nk_hohap :string ="Bình thường";
    nk_tieuhoa :string ="Bình thường";
    nk_thantietnieu :string ="Bình thường";
    nk_thankinh :string ="Bình thường";
    nk_khac :string ="";
    nk_staff :string ="";
    m_kinhmatphai :string ="";
    m_kinhmattrai :string ="";
    m_kkinhmatphai :string ="";
    m_kkinhmattrai :string ="";
    m_khac :string ="";
    m_staff :string ="";
    tmh_trainoithuong :string ="";
    tmh_trainoitham :string ="";
    tmh_phainoithuong :string ="";
    tmh_phainoitham :string ="";
    tmh_khac :string ="";
    tmh_staff :string ="";
    rhm_hamtren :string ="";
    rhm_hamduoi :string ="";
    rhm_khac :string ="";
    rhm_staff :string ="";
    cxk_isbt :string ="";
    cxk_isccs :string ="";
    cxk_isvcs :string ="";
    cxk_khac :string ="";
    cxk_staff :string =""
    constructor() {
    }
}


