import { Router } from '@angular/router';
import { UtilityService } from './../../_services/utility.service';
import { SaleService } from './../../_services/sale.service';
import { AppService } from './../../_services/app.service';
import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-stockin',
  templateUrl: './stockin.component.html',
  styleUrls: ['./stockin.component.css']
})
export class StockinComponent implements OnInit {
  // dateSelected: Date;
  // dateStrSelected : String;
  imports = [];
  sum_prices = 0;

  //for detail
  saleItem: any = [];
  cartDetail: any;
  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
 
  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }


  constructor(
    private router: Router,
    public appService: AppService,
    public utilityService: UtilityService,
    public saleService: SaleService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    // this.dateSelected = new Date();
    // this.dateStrSelected = this.utilityService.getFullDateStrFromDate(this.dateSelected);

    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 7);
    this.toDate = calendar.getToday();

  }

  ngOnInit() {
    this.getSalesBydays();
  }

  getSalesBydays() {

    let filter = {
      type: environment.SALES_IMPORT_TYPE,
      day_num: 0, // số ngày bao cáo, 0 : tất cả
      fromDate: this.utilityService.getFullDateStrFromNgbDate(this.fromDate),
      toDate: this.utilityService.getFullDateStrFromNgbDate(this.toDate),
      code: this.code_filter
    }
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.saleService.getSalesBydays(filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.imports = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.imports.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;
        }
      },
      error => {
        console.error(error);
      });
  }
  loadCartDetail(saleID) {

    
    this.saleService.loadCartDetail(saleID).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.cartDetail = response;
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })
  }
  goToDetail(item) {
    this.saleItem = item;
    $("#sale-import-detail-modal").modal('show');
    this.loadCartDetail(this.saleItem.id);
  }
  goToAddNew() {
    this.router.navigate(['/stockincart']);
  }


  ///dateTime
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    // check if date valid
    if (this.fromDate !== null && this.toDate !== null) {
      console.log(this.fromDate, this.toDate);
      this.getSalesBydays();
    }

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  // for search 
  searchFormatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        this.getSalesBydays();
      }),
      map(term => term === '' ? [] : this.imports.filter(v => v.code.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.code_filter = "";
    this.goToDetail(r);
  }

  // for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.getSalesBydays();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.getSalesBydays();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.getSalesBydays();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.getSalesBydays();
    }
  };
}



