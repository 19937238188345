<div class="p-1 m-2">
    <div class="row" style="min-width: 600px">
        <div class="col">
            <h3 class="page-title">Danh sách thuộc tính của: <span class="text"> {{data.name}}</span></h3>
        </div>
        <div class="col-1"><button class="btn btn-sm btn-gradient-peach btn-icon-only btn-circle " (click)="onCancelClick()">
                <i class="ti ti-close"></i>
            </button></div>
    </div>




    <div class="page-content fade-in-up">


        <div *ngIf="mode_action == 'normal'" class="row mb-2 mt-2 justify-content-between">

            <div class="col d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="mode_action = 'add'">
                            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                        </button>
                </div>
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="saveArrange()">
                            <span class="btn-label"><span class="material-icons">done</span></span>Lưu sắp xếp
                        </button>
                </div>
            </div>
        </div>
        <form *ngIf="mode_action == 'add'" class="form-horizontal panel-add" #addForm="ngForm" id="form-add-category">
            <div class="row mb-2 mt-2 justify-content-between">
                <div class="col">

                    <mat-form-field appearance="fill">
                        <mat-label>Thuộc tính</mat-label>
                        <input matInput [(ngModel)]="cate.name" placeholder="Tên thuộc tính" name="name" required minlength="2" maxlength="100">
                    </mat-form-field>

                </div>
                <div class="col-4 d-flex justify-content-between align-items-center">
                    <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                        Thêm
                    </button>
                    <button (click)="mode_action = 'normal'" class="btn btn-default  btn-icon">
                            <span class="btn-label"><span class="material-icons"> undo </span></span>
                        </button>
                </div>
            </div>
        </form>
        <form *ngIf="mode_action == 'update'" class="form-horizontal panel-add" #addForm="ngForm" id="form-update-category">
            <div class="row mb-2 mt-2 justify-content-between">
                <div class="col">

                    <mat-form-field appearance="fill">
                        <mat-label>Thuộc tính</mat-label>
                        <input matInput [(ngModel)]="categoryselected.name" placeholder="Tên thuộc tính" name="name" required minlength="2" maxlength="100">
                    </mat-form-field>

                </div>
                <div class="col-4 d-flex justify-content-between align-items-center">
                    <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="updateCategory()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                        Cập nhật
                    </button>

                    <button (click)="mode_action = 'normal'" class="btn btn-default  btn-icon">
                            <span class="btn-label"><span class="material-icons"> undo </span></span>
                        </button>
                </div>
            </div>
        </form>




        <div class="row align-items-center mb-2">
            <div class="col-4">
                <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                    {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}
                            </option>
                        </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                                <i class="ti ti-angle-left"></i>
                            </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class=" row">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Tên thuộc tính</th>
                        <th class="no-sort"></th>
                    </tr>
                </thead>
                <tbody cdkDropList [cdkDropListData]="categorys" class="example-list" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="noReturnPredicate">
                    <tr *ngFor="let item of categorys; let i = index" [cdkDragData]="item" cdkDrag class="item">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="mt-2" src="./assets/img/icons/tag.png" alt="image" width="25" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.name}}</div>
                        </td>

                        <td class="text-right">
                            <button class="btn-adjust btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick mr-2" (click)="popupUpdate(item)">
                                <span class="material-icons"> border_color </span>
                            </button>
                            <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)="confirmDelete(item)">
                                <span class="material-icons"> delete </span>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>














</div>