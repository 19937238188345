
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-schedules-report-update',
  templateUrl: './schedules-report-update.component.html',
  styleUrls: ['./schedules-report-update.component.css']
})
export class SchedulesReportUpdateComponent implements OnInit {
  cate_checkins: any = [];
  

  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<SchedulesReportUpdateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    data.cate_checkins.forEach(element => {
      if(element.status > 0){
        if(element.status == 3){
          element.selected_temp = false;
        }else{
          element.selected_temp = true;
        }
        this.cate_checkins.push(element);
      }
    });
  }

  ngOnInit(): void {

  }
  loadClass() {

   
  }
  saveChangeClass() {

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {

    var obj_ids = [];
    this.cate_checkins.forEach(element => {
      if (element.selected_temp) {
        obj_ids.push(element.id);
      }
    });

    if (obj_ids.length == 0) {
      this.toastr.warning("", "Bạn chưa chọn lịch cần cập nhật checkin");
      return;
    }

    let data = {
      teacher_code: this.data.teacher_code,
      teacher_name:this.data.teacher_name,
      dateTime: this.data.date + this.data.cate_checkin_time.split("-")[0],
      obj_id2s: obj_ids
    }
    console.log(this.data);
    // this.categoryeduService.saveMultiCategorys(data).subscribe(
    //   async response => {
    //     console.log(response);
    //     if (response.code == "ok" && response.affectedRows > 0) {
    //       let result = {
    //         // class_code: this.dataList[this.indexSelected].code,
    //         code: 'ok',
    //       }
    //       this.dialogRef.close(result);
    //     }
    //   },
    //   error => {
    //     console.error("error:");
    //     console.error(error);
    //   })


  }

}
