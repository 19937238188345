<div style="width: 100%;">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body flexbox">
                    <img src="assets\img\icons\medical-report.png" alt="Avatar" style="height: 55px; border-radius: 50%;">
                    <div class="text-right">
                        <h3 class="mb-1"> {{data.dateSelected_mmyyy}}</h3>
                        <div>Nhập nhanh chu kỳ</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body flexbox">
                    <div class="text-center">
                        <h2 class="mb-1">{{data.class_code}}</h2>
                        <div class="">Lớp</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body flexbox">
                    <div class="text-center">
                        <h2 class="mb-1">{{this.students.length}}</h2>
                        <div class="">Sỹ số</div>
                    </div>
                    <div class="text-center">
                        <h2 class="mb-1"> 0 </h2>
                        <div class="">Đã nhập</div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-warning" (click)="this.dialogRef.close();">
                            <span class="material-icons font-26">
                            close
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 45px; text-align: center;">#</th>
                <th>Họ tên</th>
                <th class="text-center" style="width: 110px;">Cân nặng</th>
                <th class="text-center" style="width: 110px;">Chiều cao</th>
                <th class="text-center"></th>
                <!-- <th>Có phép</th> -->

            </tr>
        </thead>
        <tbody>
            <!-- [ngClass]="{'bg-primary-100': item.checkin_times != '' }" -->
            <tr *ngFor="let item of this.students; let i = index">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                    <span class="badge badge-default badge-circle">{{i+1}}</span>
                </td>
                <td>
                    <a class="d-flex justify-content-start align-items-center" (click)="open_student_detail(item)">
                        <div>
                            <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                        </div>
                        <div>
                            <div style="font-size: 18px;">
                                {{item.full_name}}
                            </div>
                            <!-- <div class="text-muted">
                                {{item.code}}
                            </div> -->
                        </div>
                    </a>

                </td>

                <td class="text-center">
                    <div class="input-group-icon input-group-icon-right">
                        <input class="tl" [id]="'tl_cannang-'+ i" [name]="item.code + '-cannang'" maxlength="4" [(ngModel)]="item.tl_cannang" (focus)="focusFunction('tl_cannang-'+ i)" (focusout)="focusoutFunction(item)" (keypress)="numberOnly($event)">
                        <span class="input-icon input-icon-right">kg</span>
                    </div>
                </td>
                <td class="text-center">
                    <div class="input-group-icon input-group-icon-right">
                        <input class="tl" [id]="'tl_chieucao-'+ i" [name]="item.code + '-cannang'" maxlength="4" [(ngModel)]="item.tl_chieucao" (focus)="focusFunction('tl_chieucao-'+ i)" (focusout)="focusoutFunction(item)" (keypress)="numberOnly($event)">
                        <span class="input-icon input-icon-right">m</span>
                    </div>
                </td>
                <td class="text-center" style="width: 100px;">
                    <div style="position: absolute;
                    right: 74px; padding: 3px; background-color: white;
                    top: 45%;">
                        <button class="btn btn-primary btn-labeled btn-labeled-left" (click)="saveHealthClassDetail()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> save </span></span>
                        Lưu
                    </button>
                    </div>
                </td>


            </tr>
        </tbody>
    </table>
</div>