
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { HolidayPickerComponent } from 'src/app/components/holiday-picker/holiday-picker.component';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-online-add',
  templateUrl: './online-add.component.html',
  styleUrls: ['./online-add.component.css']
})
export class OnlineAddComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  isLoading = false;
  categorys: any;
  categorys_backup: any;

  cateType = environment.cateType_online_schudule;
  // for add
  cate = new Category(this.cateType);



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private utility: UtilityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OnlineAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
    // this.countAllCategory();
  }

  ngOnInit() {
    
  }
  onSubmit() {
    console.log();
  }

  onReset() {
   
  }
  ngAfterViewInit() {
   
  }

  changeCheckbox(event) {
   
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
  }
  category_change(event) {
  }
  cancelClick() {
    this.dialogRef.close();
  }
  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng tên ");
      return false;
    }

    this.cate.name = this.cate.name.trim();
    this.cate.code = 'OC' + Math.floor(Math.random()*10000);

    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.appService.weeks.forEach(element => {
        if(element.checked){
          temp_weeks.push(element.code);
        }
    });
    this.cate.checkin_weeks = temp_weeks.join("w");

    this.cate.hockyI_startTime = this.utility.formatDateToStr(this.cate.hockyI_startTime);
    this.cate.hockyI_endTime = this.utility.formatDateToStr(this.cate.hockyI_endTime);
    
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            this.dialogRef.close({code:"ok"});
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }



  /////////////////
  openSelectTeacher(classSelected, index) {
    // this.appService.deskIndex = index;
    // this.modalService.open(content, { size: 'xl' });

    console.log("this.appService.deskIndex", this.appService.deskList[this.appService.deskIndex]);
    this.appService.deskIndex = index;
    const dialogRef = this.dialog.open(TeacherPickerComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: { deskIndex: index, classSelected: classSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
        // load lại dữ liệu
       
      }
    });
  }

}
