
import { ProductService } from '../../../_services/product.service';
import { Membership } from '../../../model/membership';
import { UserService } from '../../../_services/user.service';

import { environment } from '../../../../environments/environment';
import { UtilityService } from '../../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../_services/app.service';
import { SaleService } from '../../../_services/sale.service';
import { CartObj } from '../../../model/cart-obj';
import { NgbDate,  NgbDateParserFormatter, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentService } from 'src/app/_services/student.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MealService } from 'src/app/_services/meal.service';
import { MealClassDetailMonthComponent } from '../meal-class-detail-month/meal-class-detail-month.component';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-meal-report-month',
  templateUrl: './meal-report-month.component.html',
  styleUrls: ['./meal-report-month.component.css']
})
export class MealReportMonthComponent implements OnInit {
  isLoading = false;
  isLoading_chotthu = false;

  allSchools = {
    student_count: 0,
    day_serve: 0,
    student_register: 0,
    student_attention: 0,
    student_inattention: 0,
    percent: 0,
    total_amount: 0,
    month_total_paid: 0,
    tamthu_total_amount: 0,
    n_1_diff_amount: 0,
    n_1_diff_count: 0,
    tamtinh_total_amount: 0,
    tamtinh_register : 0,
    tamthu_register : 0
  }

  //for data

  classs: any
  filter = {
    khoi_code: "",
    class_code: ""
  }
  dateRange = [];
  curentMonday: Date;

  reportcheckins: any = [];
  khoiSelected = -1;

  ///////////////
  sales = [];

  


  //status
  category_avatar = environment.category_avatar;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;


  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    private schoolService: SchoolService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    public formatter: NgbDateParserFormatter,
    public studentService: StudentService,
    public mealService: MealService,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Quản lý thu bán trú";
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadClasss();
    this.reportMealSchoolMonth();
    this.loadReportMealClassMonth();
    this.getMonthList();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    this.scrollListent();
  }
  Add
  category_khoi_change(event) {
    this.loadReportMealClassMonth();
  }
  category_change(event) {
    this.loadReportMealClassMonth();
  }
  async loadClasss() {
    let filter = {
      khoi: this.filter.khoi_code,
      type: environment.cateType_class
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async getMonthList() {
    let param = {
        month_selected : this.selectedDateStr
    };
    this.mealService.loadMonthListForSchool(param).subscribe(
      async response => {
        this.dateRange = response;
        // this.isLoading.dismiss();
        console.log(this.dateRange);
        
        this.scrollToLeff();
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  loadReportMealClassMonth() {
     
    // if(!this.appService.checkFullPermission()){
    //   return;
    // }

    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.isLoading = true;
    this.reportcheckins =[]
    this.mealService.reportMealClassMonth(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          // response.forEach(element => {
          //   if(element.student_register == null || element.student_register == 0){
          //     element.percent = 0;
          //   }else{
          //     element.percent = element.student_register ==0 ? 100 : Math.round(((element.student_attention) / element.student_register) * 100);
          //   }
          // });
          this.reportcheckins = response;
          console.log(this.reportcheckins);
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  reportMealSchoolMonth() {
    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.mealService.reportMealSchoolMonth(data).subscribe(
      async response => {
        if (response) {
          response.percent = Math.round((response.student_attention / response.student_register) * 100);
          this.allSchools = response;
          console.log(this.allSchools);
        }
      },
      error => {
        console.error(error);
      })
  }
  selectKhoi(i, khoi_name) {
    if (this.khoiSelected != i) {
      this.khoiSelected = i;
      this.filter.khoi_code = khoi_name;
      this.reportcheckins();
    }

  }
  popup_export_excel(){

  }


  selectMonth(i, item) {
    // xóa hết selected
    this.dateRange.forEach(element => {
      element.selected = false;
    });
    // gán selected
    item.selected = true;


    this.selectedDateStr = '01/'+ item.date_month;
    this.loadReportMealClassMonth();
    this.reportMealSchoolMonth();
    // this.loadSales(this.selectedDateStr);
  }


  // loadDateRange(date, days) {
  //   this.dateRange = [];
  //   for (let i = 0; i < days; i++) {
  //     var temp = new Date(date);
  //     temp.setDate(temp.getDate() + i);
  //     this.dateRange.push(
  //       {
  //         dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
  //         ddmm: this.utilityService.getDDMMStrFromDate(temp),
  //         ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
  //       }
  //     );
  //   }
  //   console.log(this.dateRange);
  // }




  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }



  openMealClassDetailMonth(item) {
    if(item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(MealClassDetailMonthComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { data: item, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }

  
  chotthangn1(item) {
    
    // this.scrollToLeff();
    let data = {
      title : 'Xác nhận chốt số liệu tháng N-1',
      msg : 'Nhấn xác nhận để chốt.',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        // close hoặc không close
        // let filter = {};
        this.isLoading_chotthu = true;
        console.log('Chốt thu');
        let data = {
          month: this.utilityService.dayStrToMonth(this.selectedDateStr),
          class_list: item == null ? [] : [item.class_code]
        };
        this.mealService.chotthangn1(data).subscribe(
          async response => {
            this.isLoading_chotthu = false;
            console.log(response);
            if(response.code == 'ok'){
              this.reportMealSchoolMonth();
              this.loadReportMealClassMonth();
              this.getMonthList();
              this.toastr.success("", response.msg);
            }else if(response.code == 'nook'){
              this.toastr.warning("", response.msg);
            }
            
          },
          error => {
            this.toastr.error("Có lỗi xảy ra", "Lớp và tháng này đã chốt thu trước đó rồi.");
            this.isLoading_chotthu = false;
            console.error(error);
          });
      }
    });
  }
  chottamthu(item) {
    
    // this.scrollToLeff();
    let data = {
      title : 'Xác nhận chốt tạm thu và gửi thông báo đến PHHS',
      msg : 'Nhấn Xác nhận để gửi.',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        // close hoặc không close
        // let filter = {};
        this.isLoading_chotthu = true;
        console.log('Chốt thu');
        let data = {
          month: this.utilityService.dayStrToMonth(this.selectedDateStr),
          class_list: item == null ? [] : [item.class_code]
        };
        this.mealService.chottamthu(data).subscribe(
          async response => {
            this.isLoading_chotthu = false;
            console.log(response);
            if(response.code == 'ok'){
              this.reportMealSchoolMonth();
              this.loadReportMealClassMonth();
              this.getMonthList();
              this.toastr.success("", response.msg);
            }else if(response.code == 'nook'){
              this.toastr.warning("", response.msg);
            }
            
          },
          error => {
            this.toastr.error("Có lỗi xảy ra", "Lớp và tháng này đã chốt thu trước đó rồi.");
            this.isLoading_chotthu = false;
            console.error(error);
          });
      }
    });
  }
  downloadExcel(type) {
    
    if(type == 1){
      let data = {
        title : 'Xác nhận tải Excel tổng hợp tháng ' + this.utilityService.dayStrToMonth(this.selectedDateStr),
        msg : 'Nhấn Xác nhận để tải.',
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          this.mealService.downloadMealReport('',this.selectedDateStr);
        }
      });
    }else if(type == 2){
        let data = {
          title : 'Xác nhận tải Excel chi tiết tháng ' + this.utilityService.dayStrToMonth(this.selectedDateStr),
          msg : 'Nhấn Xác nhận để tải.',
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result == 'ok'){
            this.mealService.downloadMealReportDetail('',this.selectedDateStr);
          }
        });
    }
    
  }

  private scrollListent() {
    const scrollContainer = document.getElementById('scroll-container-month');
    // Set the step for each mouse wheel scroll
    const scrollStep = 0.5;

    // Add an event listener for the wheel event
    scrollContainer.addEventListener('wheel', handleWheel);

    // Function to handle the wheel event
    function handleWheel(event) {
      // Calculate the new scroll position based on the wheel delta
      const newScrollLeft = scrollContainer.scrollLeft + event.deltaY * scrollStep;

      // Apply the new scroll position
      scrollContainer.scrollLeft = newScrollLeft;

      // Prevent the default behavior of the wheel event (e.g., page scrolling)
      event.preventDefault();
    }
  }
  private scrollToLeff() {
    let scrollContainer = document.getElementById('scroll-container-month');
    console.log(scrollContainer);
    // Tự động cuộn đến cuối khi trang được tải
    // Set the target scroll position (end of the content)
    const targetScroll = scrollContainer.scrollWidth;

    // Animation duration in milliseconds
    const duration = 1000; // 1 second


    // Get the current timestamp
    const startTime = performance.now();

    // Animation loop
    function animateScroll() {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;

      // Calculate the new scroll position using easing function (e.g., easeInOutQuad)
      const easedScroll = easeInOutQuad(elapsed, 0, targetScroll, duration);

      // Apply the new scroll position
      scrollContainer.scrollLeft = easedScroll;

      // Continue the animation until the duration is reached
      if (elapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    }

    // Start the animation
    animateScroll();

    // Easing function for smooth animation
    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }
  }

  confirmDownloadExcel(cap_name) {
    let data = {
      title: 'Tải danh sách học sinh vắng',
      msg: 'Xác nhận tải.',
      ok : 'Tải xuống'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(cap_name);
        // console.log(this.filter);
        this.studentService.exportStudentInattention(cap_name,this.selectedDateStr);

      }
    });
  }

}

