import { environment } from './../../environments/environment';

export class Teacher {
    name : string = "";
    code: string ="";
    price: number;
    price_in: number;
    // category_code ="DM";
    // category_code_brand = "BRAND";
    image_url:string = "";
    description:string ="";
    unit ="";
    packing ="";
    color ="sa";
    /////
    name_alias ="";
    positions ="giaovien";
    faculty_code ="default";
    password ="Ais@123";
    email = "";
    phone = "";
    gender = 0;
    birthday = "";
    constructor(unit) {
        // this.category_code = environment.CATEGORY_CODE_PREFIX;
        this.unit = unit;
    }
}
