<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <img [src]="appService.showImageTeacher2(data.faculty_checkin.image_url)" alt="Avatar" style="height: 55px; border-radius: 50%;">
                <div class="text-right">
                    <h3 class="mb-1">{{data.faculty_checkin.teacher_name}}</h3>
                    <div class="text-muted">Tổ trưởng</div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.faculty_checkin.class_code}}</h2>
                    <div class="">Lớp</div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col">
        <div class="card">
            <div class="card-body flexbox">
                <div class="text-center">
                    <h2 class="mb-1">{{data.faculty_checkin.teacher_count}}</h2>
                    <div class="">Tổng số</div>
                </div>
                <div class="text-center">
                    <h2 class="mb-1">{{data.faculty_checkin.teacher_inattention}}</h2>
                    <div class="">Vắng</div>
                </div>
                <!-- <div class="text-center">
                    <h2 class="mb-1">{{data.faculty_checkin.student_inattention / data.faculty_checkin.student_count}}</h2>
                    <div class="">Tỷ lệ</div>
                </div> -->
                <div class="text-center">
                    <button class="btn btn-warning" (click)="this.dialogRef.close();">
                        <span class="material-icons font-26">
                        close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th>Cán bộ giáo viên</th>
                <th style="width: 40px;">Kiểu</th>
                <th>Điểm danh</th>
                <th>Thời gian</th>


            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.teachers; let i = index" [ngClass]="{'bg-primary-100': item.checkin_times != '' }">
                <td class="stt_td" style=" padding: 0.5rem;">
                    <span class="stt_span"> <span class="badge badge-default badge-circle">{{i+1}}</span></span>
                    <a class="d-flex justify-content-start align-items-center" (click)="open_student_detail(item)">
                        <div>
                            <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                        </div>
                        <div>
                            <div style="font-size: 18px;">
                                {{item.name}}
                            </div>
                            <div class="text-muted" style="font-size: 13px;">
                                {{item.code}} <span class="badge-primary badge-point"></span> {{item.phone}}
                            </div>
                        </div>
                    </a>
                </td>


                <td class="text-center">

                    <div style="position: relative;">
                        <img *ngIf="item.checkin_type_status >0 " style="width: 30px;" src="./assets/img/icons/camera-checkin.png" />
                        <img *ngIf="item.checkin_type_status == 0" style="width: 30px;" src="./assets/img/icons/to-do-list.png" />
                    </div>
                </td>
                <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" disabled="true"  [ngModel]="item.checkin_status_temp" >
                        <span class="input-span"></span>
                    </label>
                </td>
                <td class="">
                    <div style="max-height: 166px;  overflow: auto;" class="qbs-scroll">
                        <div *ngFor="let item2 of item.checkin_times; let j = index" class="times-item-cover">
                            <a>
                                <!-- Chỉ hiển thị ảnh đầu tiên và cuối cùng -->
                                <ng-template #tipContent>Hello<img *ngIf="item.detected_image_urls[j] != ''" [src]="appService.showCheckinImage(item.detected_image_urls[j])" /></ng-template>
                                <!-- <img [src]="item.detected_image_urls[j]" />
                                <div>{{item2}}</div> -->

                                <div class="content" [ngbTooltip]="tipContent" tooltipClass="my-tooltip-class">
                                    <img [src]="appService.showCheckinImage(item.detected_image_urls[j])" />
                                    <div>{{item2}}</div>
                                </div>

                            </a>
                        </div>
                    </div>

                </td>
                <!-- <td class="text-center">
                    <label class="checkbox checkbox-primary check-single">
                        <input class="mail-check" type="checkbox" disabled="true" [ngModel]="!item.checkin_status_temp" >
                        <span class="input-span"></span>
                    </label>
                </td> -->

            </tr>
        </tbody>
    </table>
</div>