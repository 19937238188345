import { CategoryService } from './../../_services/category.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'mts';
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;
  
  categorys : any;
  categorys_backup: any
  
  type = environment.cate_type;
  // for add
  cate = new Category(environment.cate_type);
  // for update
  categoryselected: any;
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: this.appService.sort_category[0].code,
  }
  
  filter = {
    name: "",
    type: environment.cate_type
  }



  constructor(
    public categoryService: CategoryService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
  ) {
    this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {
    // $("#form-add-category").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
  }
  resetCategory() {
    this.cate = new Category(environment.cate_type);
    this.file == null;
    this.imageUrl = environment.product_avatar; 
  }

  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          this.categorys_backup = response.data;
          
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.cate.code.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập mã");
      return false;
    }

    if(this.file !== null){
      this.cate.image_url = environment.cate_type + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }
   
    
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
      this.uploadAvatar(this.file, this.cate.code);
    }
  }
  uploadAvatar(fileUpload : File, name : string) {
    console.log(fileUpload);
    if(fileUpload == null){
      return;
    }
    let param = {
      des: environment.cate_type,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  countAllCategory() {
    this.categoryService.countAllCategory(environment.cate_type).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {
    this.resetCategory();
    this.countAllCategory();
    $("#add-category-modal").modal('show');
  }
  popupUpdate(item) {
    this.categoryselected = item;
    this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;
    $("#update-category-modal").modal('show');
  }
  
  updateCategory() {

    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if(this.categoryselected_file !== null){
      this.categoryselected.image_url = environment.cate_type +"/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }
    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    console.log(this.categoryselected);

    this.isLoading = true;
    this.categoryService.update(this.categoryselected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code=="ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
      this.uploadAvatar(this.categoryselected_file, this.categoryselected.code);
  }

  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if(this.file == null){
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async changeImageUpdate($event): Promise<void> {
    this.categoryselected_file = $event.target.files[0];
    if(this.categoryselected_file == null){
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
        this.categoryselected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.categoryselected_file);
        reader.onload = (_event) => {
          this.categoryselected_imageUrl = reader.result;
          console.log(this.categoryselected_imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  
  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.type
    }
    this.categoryService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupUpdate(r);
    }

}
