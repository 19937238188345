import { ProductService } from '../../../_services/product.service';
import { Membership } from '../../../model/membership';
import { UserService } from '../../../_services/user.service';

import { environment } from '../../../../environments/environment';
import { UtilityService } from '../../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../_services/app.service';
import { SaleService } from '../../../_services/sale.service';
import { CartObj } from '../../../model/cart-obj';
import { NgbDate,  NgbDateParserFormatter, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentService } from 'src/app/_services/student.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CheckinClassDetailComponent } from '../../checkin-report/checkin-class-detail/checkin-class-detail.component';
import { MealClassDetailComponent } from '../meal-class-detail/meal-class-detail.component';
import { MealReportMonthComponent } from '../meal-report-month/meal-report-month.component';
declare var $: any;

@Component({
  selector: 'app-meal-report',
  templateUrl: './meal-report.component.html',
  styleUrls: ['./meal-report.component.css']
})
export class MealReportComponent implements OnInit {
  isLoading = false;

  allSchools = {
    student_count: 0,
    student_register: 0,
    student_attention: 0,
    student_inattention: 0,
    percent: 0,
    sum_price: 0,
  }

  //for data

  classs: any
  filter = {
    khoi_code: "",
    class_code: ""
  }
  dateRange = [];
  curentMonday: Date;

  reportcheckins: any;
  khoiSelected = -1;

  ///////////////
  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;


  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    private schoolService: SchoolService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    public userService: UserService,
    public productService: ProductService,
    public formatter: NgbDateParserFormatter,
    public studentService: StudentService,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Báo cáo ăn bán trú";
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadClasss();
    this.loadReportMealClass();
    this.loadCurrenMonday(new Date);
    this.loadReportMealSchool();
  }

  category_khoi_change(event) {
    this.loadReportMealClass();
  }
  category_change(event) {
    this.loadReportMealClass();
  }
  async loadClasss() {
    let filter = {
      khoi: this.filter.khoi_code,
      type: environment.cateType_class
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  loadReportMealClass() {
     
    // if(!this.appService.checkFullPermission()){
    //   return;
    // }

    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.isLoading = true;
    this.schoolService.reportMealClass(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          response.forEach(element => {
            if(element.student_register == null || element.student_register == 0){
              element.percent = 0;
            }else{
              element.percent = element.student_register ==0 ? 100 : Math.round(((element.student_attention) / element.student_register) * 100);
            }
          });
          this.reportcheckins = response;
          console.log(this.reportcheckins);
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  loadReportMealSchool() {
    let data = {
      filter: this.filter,
      dateSelected: this.selectedDateStr
      // sorter: sorter,
      // pagingObj: pagingObj
    };
    this.schoolService.reportMealSchool(data).subscribe(
      async response => {
        if (response) {
          response.percent = Math.round((response.student_attention / response.student_register) * 100);
          this.allSchools = response;
          console.log(this.allSchools);
        }
      },
      error => {
        console.error(error);
      })
  }
  selectKhoi(i, khoi_name) {
    if (this.khoiSelected != i) {
      this.khoiSelected = i;
      this.filter.khoi_code = khoi_name;
      this.reportcheckins();
    }

  }
  popup_export_excel(){

  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
    this.loadCurrenMonday(this.fromDate);
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadReportMealClass();
    this.loadReportMealSchool();
    // this.loadSales(this.selectedDateStr);
  }
  

  loadCurrenMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if(curr.getDate() == first) first = first-6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
        }
      );
    }
    console.log(this.dateRange);
  }

  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }


  // popupSaleDetail(item) {
  //   $("#sale-detail-modal").modal('show');
  //   this.saleSelectedItem = item;
  //   try {
  //     this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);

  //   } catch (error) {
  //     this.saleSelectedItem.service_details = [];
  //     console.error(error);
  //   }
  //   this.loadCartDetail(this.saleSelectedItem.id);
  // }

  openMealClassDetail(item) {
    if(item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(MealClassDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { class_checkin: item, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }
  openMonthReport() {
    const dialogRef = this.dialog.open(MealReportMonthComponent, {
      // position: {
      //   // top: '0px',
      //   // left: '0px'
      // },
      disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      // data: 
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
  
  popup_student_export() {
    // let filter = {};
    console.log(this.filter);
    this.studentService.exportStudentInattention(this.filter.khoi_code,this.selectedDateStr);
  }

  confirmDownloadExcel(cap_name) {
    let data = {
      title: 'Tải danh sách học sinh vắng',
      msg: 'Xác nhận tải.',
      ok : 'Tải xuống'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(cap_name);
        // console.log(this.filter);
        this.studentService.exportStudentInattention(cap_name,this.selectedDateStr);

      }
    });
  }

}

