import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';

@Component({
  selector: 'app-assessment-period-view',
  templateUrl: './assessment-period-view.component.html',
  styleUrls: ['./assessment-period-view.component.css']
})
export class AssessmentPeriodViewComponent implements OnInit {
  //for data
  students = [];
  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<AssessmentPeriodViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
    this.students = data.data;
    console.log(this.students);
   }

  ngOnInit(): void {
  }

}
