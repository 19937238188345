import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { Student } from 'src/app/model/student';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { SchoolService } from 'src/app/_services/school.service';
import { StudentService } from 'src/app/_services/student.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { TeacherAddComponent } from '../../teacher/teacher-add/teacher-add.component';

@Component({
  selector: 'app-student-add',
  templateUrl: './student-add.component.html',
  styleUrls: ['./student-add.component.css']
})
export class StudentAddComponent implements OnInit {
  @ViewChild('studentAddForm') studentAddForm: HTMLFormElement;

  // for add
  student = new Student();
  birthday_temp: Date;
  fileteacherImg: File;
  imageUrl: any = environment.student_avatar;
  classs: any
  // scan code
  codeType = 'mts';
  codeScan = "";
  studentCodeTemp = "";

  filter = {
    khoi_code: "",
  }
  isLoading = false;
  constructor(
    public appService: AppService,
    public schoolService: SchoolService,
    public studentService: StudentService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private ng2ImgMax: Ng2ImgMaxService,
    public ioService: IoService,
    public dialogRef: MatDialogRef<TeacherAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.student.code = environment.STUDENT_CODE_PREFIX + this.utility.mathRound(Date.now()/2000) ;
    this.filter.khoi_code = data.khoi_code;
    this.loadClasss();
    this.countAllStudent();
  }

  ngOnInit(): void {

  }
  countAllStudent() {
    this.studentService.countAllStudent().subscribe(
      response => {
        // this.countType1Product = response.CountType1Product;
        //kiểm tra đầu vào khi tạo product
        if (typeof this.data.code != "undefined" && this.data.code != '') {
          this.codeType = 'tn';
          this.student.code = this.data.code;
          this.studentCodeTemp = this.data.code;
        } else {
          this.student.code = response.code;
          this.studentCodeTemp = response.code;
        }
      },
      error => {
        console.error(error);
      })
  }
  insertStudent() {

    if (this.student.full_name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên");
      this.studentAddForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.student.class_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn lớp");
      return;
    }

    // xưu lý name
    this.student.full_name = this.student.full_name.trim();
    var temp = this.student.full_name.split(" ");
    this.student.name = temp[temp.length - 1];

    this.student.khoi = this.appService.getKhoiByClassCode(this.student.class_code, this.classs);

    if (this.fileteacherImg !== null && typeof this.fileteacherImg !== 'undefined') {
      this.student.image_url = environment.student + "/" + this.student.code + "." + this.utility.getExtensionFile(this.fileteacherImg);
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.student.birthday = this.utility.getFullDateStrFromDate(this.birthday_temp);

    if (!this.studentAddForm.invalid) {
      this.isLoading = true;
      this.studentService.insert(this.student).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            if (response.code == "ok") {
              // $("#add-teacher-modal").modal('hide');
              // this.toastr.success("", "Thêm mới hàng hóa thành công");
              // this.resetteacher();
              // this.loadTeacher();
              let result = {
                code: "ok",
                data: {}
              }
              this.dialogRef.close(result);
            } else {
              this.toastr.error("", "Mã đã tồn tại hoặc Có lỗi xảy ra.");
            }
          } else {
            this.toastr.error("", "Có lỗi xảy ra.");
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
      this.uploadAvatar(this.fileteacherImg, this.student.code);
    }
  }
  teacherCodeTypeClick(type) {
    this.codeType = type;
    if (this.codeType == "mts") {
      this.student.code = this.studentCodeTemp;
    } else if (this.codeType == "tn" || this.codeType == "qmv") {
      this.student.code = "";
    }
  }
  focusOutCheckCode() {

    let filter = {
      code: this.student.code,
    }
    console.log(filter);
    this.studentService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.studentAddForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.student,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async avatarChangeListener($event): Promise<void> {
    this.fileteacherImg = $event.target.files[0];
    if (this.fileteacherImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
        this.fileteacherImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileteacherImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
}
