



import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HealthService } from 'src/app/_services/health.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { HealthDoctorComponent } from '../../../components/health-doctor/health-doctor.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/_services/app.service';
import { IoService } from 'src/app/_services/io.service';
import { HealthInputClassComponent } from 'src/app/pages/health/health-input-class/health-input-class.component';
import { DataService } from 'src/app/model/data.service';
import { AssessmentPeriod } from 'src/app/model/assessment_period';
import { SchoolService } from 'src/app/_services/school.service';
import { AssessmentService } from 'src/app/_services/assessment.service';
declare var $: any;

@Component({
  selector: 'app-assessment-period-input',
  templateUrl: './assessment-period-input.component.html',
  styleUrls: ['./assessment-period-input.component.css']
})
export class AssessmentPeriodInputComponent implements OnInit {
  @Input() selectedDateStr: string;
  @Input() student_code: string;
  @Input() class_code: string;
  @Input() cap_hoc: string;
  @Input() student_full_name: string;
  @Input() period_code: string;
  @Input() period_name: string;
  @Input() subjects: any;
  @Input() subjects_origin: any;
  @Output() studentChange = new EventEmitter();

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;

  data = new AssessmentPeriod();
  isLoading = false;
  type_temp = 0;


  //Môn học cho thông báo theo đợt THCS

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // upload
  fileAttach: File;
  constructor(
    public schoolService: SchoolService,
    private ioService: IoService,
    private appService: AppService,
    public dataService: DataService,
    private assessmentService: AssessmentService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private utility: UtilityService,
  ) {
    // console.log(this.selectedDateStr);
    // console.log(this.student_code);
    // console.log(this.student_full_name);
    // this.data.student_code = this.student_code;
    // this.data.period_time = this.selectedDateStr;
    // if (localStorage.getItem(environment.health_type)) {
    //   this.type_temp = parseInt(localStorage.getItem(environment.health_type));
    //   this.data.type = this.type_temp;
    // }
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);

  }

  ngOnInit(): void {

  }
  ngOnChanges(): void {
    console.log("ngOnChanges", this.selectedDateStr);
    console.log("ngOnChanges", this.student_code);
    console.log("ngOnChanges", this.class_code);
    console.log("ngOnChanges_cap_hoc", this.cap_hoc);
    console.log("ngOnChanges", this.student_full_name);
    console.log("subjects", this.subjects);
    console.log("subjects_origin", this.subjects_origin);
    this.data.student_code = this.student_code;
    this.data.period_time = this.utilityService.dayStrToMonth(this.selectedDateStr);
    this.fileAttach = null; // đặt lại file
    if (this.myInputVariable != undefined)
      this.myInputVariable.nativeElement.value = ""; // đặt lại tag input file
    // Load mặc định
    this.resetData();
    // Load mặc định 
    // this.getSubjectsByClass(this.class_code);


    // Load assessment period data để nếu có thì sẽ update
    if (this.data.student_code != '') {
      this.findData();
    }

  }

  findData() {

    if(this.period_code == null || this.period_code == ''){
      this.toastr.warning("Vui lòng chọn chu kỳ");
        return;
    }

    let data = {
      'student_code': this.data.student_code,
      'period_code': this.period_code,
      'period_time': this.data.period_time,
      // 'padded_rank': 0 // 1 và 0 để lấy thêm cột rank
    }
    // reset data - start

    // reset data - end
    this.assessmentService.findAssessmentPeriod(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.length > 0) {
          console.log("Có dữ liệu");
          response[0].period_time = this.utilityService.dateStrToMonthStr(response[0].period_time);
          this.data = response[0];
          if(this.data.thcs_ketquamonhoc == null)
          this.data.thcs_ketquamonhoc = []
          if(this.data.th_ketquamonhoc == null)
          this.data.th_ketquamonhoc = []
          // gán dữ liệu kết quả môn học, dành cho kỳ học kỳ và cả năm học\
          for (let i = 0; i < this.subjects.length; i++) {
            // const element = this.subjects[index];
            if (this.data.cap_hoc == 'THCS') {
                  var index = this.checkAndGetIndex(this.data.thcs_ketquamonhoc,  this.subjects[i]);
                  if(index > -1){
                    this.subjects[i] = this.data.thcs_ketquamonhoc[index];
                  }
            } else if (this.data.cap_hoc == 'TH') {
                  var index = this.checkAndGetIndex(this.data.th_ketquamonhoc,  this.subjects[i]);
                  if(index > -1){
                    this.subjects[i] = this.data.th_ketquamonhoc[index];
                  }
            }

            
          }
    
          // if (this.data.cap_hoc == 'THCS') {
          //   if (this.data.thcs_ketquamonhoc != null && this.data.thcs_ketquamonhoc.length > 0) {
          //     this.subjects = this.data.thcs_ketquamonhoc;
          //   }
          // } else if (this.data.cap_hoc == 'TH') {
          //   if (this.data.th_ketquamonhoc != null && this.data.th_ketquamonhoc.length > 0) {
          //     this.subjects = this.data.th_ketquamonhoc;
          //   }
          // }


        }
        else {
          this.subjects = JSON.parse(JSON.stringify(this.subjects_origin));
          console.log("Không Có dữ liệu");

        }

      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      })


  }
  private checkAndGetIndex(ketquamonhoc, element: any) {
    let result = -1;
    ketquamonhoc.forEach((ktem, k) => {
        //kiểm tra môn học có dữ liệu hay chưa
        if (ktem.subjects_code == element.subjects_code) {
            result = k;
            return result;
        }
    });
    return result;
  }

  resetData() {
    this.data = new AssessmentPeriod();
    //kiểm tra cấp
    this.data.cap_hoc = this.cap_hoc;
    this.data.period_code = this.period_code;
    this.data.period_name = this.period_name;
    this.data.student_code = this.student_code;
    this.data.period_time = this.utilityService.dayStrToMonth(this.selectedDateStr);


    // if (this.data.cap_hoc == 'THCS') {
    //   if (localStorage.getItem(environment.assessment_priod_type_thcs_code)) {
    //     this.data.period_name = localStorage.getItem(environment.assessment_priod_type_thcs_name);
    //     this.data.period_code = localStorage.getItem(environment.assessment_priod_type_thcs_code);
    //   } else {
    //     this.data.period_name = this.dataService.period_thcs_list[0].name;
    //     this.data.period_code = this.dataService.period_thcs_list[0].code;
    //   }
    // } else if (this.data.cap_hoc == 'TH') {
    //   if (localStorage.getItem(environment.assessment_priod_type_th_code)) {
    //     this.data.period_name = localStorage.getItem(environment.assessment_priod_type_th_name);
    //     this.data.period_code = localStorage.getItem(environment.assessment_priod_type_th_code);
    //   } else {
    //     this.data.period_name = this.dataService.period_th_list[0].name;
    //     this.data.period_code = this.dataService.period_th_list[0].code;
    //   }
    // }



    this.data.toan_1 = JSON.parse(JSON.stringify(this.dataService.th_toan_1_list));
    this.data.toan_2 = JSON.parse(JSON.stringify(this.dataService.th_toan_2_list));
    this.data.toan_3 = JSON.parse(JSON.stringify(this.dataService.th_toan_3_list));
    this.data.toan_4 = JSON.parse(JSON.stringify(this.dataService.th_toan_4_list));
    this.data.toan_5 = JSON.parse(JSON.stringify(this.dataService.th_toan_5_list));
    this.data.toan_6 = JSON.parse(JSON.stringify(this.dataService.th_toan_6_list));
    this.data.toan_7 = JSON.parse(JSON.stringify(this.dataService.th_toan_7_list));
    this.data.tiengviet_1 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_1_list));
    this.data.tiengviet_2 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_2_list));
    this.data.tiengviet_3 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_3_list));
    this.data.tiengviet_4 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_4_list));
    this.data.tiengviet_5 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_5_list));
    this.data.tiengviet_6 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_6_list));
    this.data.tiengviet_7 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_7_list));
    this.data.tiengviet_8 = JSON.parse(JSON.stringify(this.dataService.th_tiengviet_8_list));
    this.data.tienganh_1 = JSON.parse(JSON.stringify(this.dataService.th_tienganh_1_list));
    this.data.tienganh_2 = JSON.parse(JSON.stringify(this.dataService.th_tienganh_2_list));
    this.data.tienganh_3 = JSON.parse(JSON.stringify(this.dataService.th_tienganh_3_list));
    this.data.tienganh_4 = JSON.parse(JSON.stringify(this.dataService.th_tienganh_4_list));
    this.data.kns_1 = '';
    this.data.kns_2 = JSON.parse(JSON.stringify(this.dataService.th_kns_2_list));
    this.data.kns_3 = JSON.parse(JSON.stringify(this.dataService.th_kns_3_list));
    this.data.kns_4 = JSON.parse(JSON.stringify(this.dataService.th_kns_4_list));
    this.data.ctcs_1 = JSON.parse(JSON.stringify(this.dataService.th_ctcs_1_list));
    this.data.ctcs_2 = JSON.parse(JSON.stringify(this.dataService.th_ctcs_2_list));
    this.data.ctcs_3 = JSON.parse(JSON.stringify(this.dataService.th_ctcs_3_list));
    this.data.ythuc_1 = JSON.parse(JSON.stringify(this.dataService.th_ythuc_1_list));
    this.data.ythuc_2 = JSON.parse(JSON.stringify(this.dataService.th_ythuc_2_list));
    this.data.ythuc_3 = JSON.parse(JSON.stringify(this.dataService.th_ythuc_3_list));
    this.data.ythuc_4 = JSON.parse(JSON.stringify(this.dataService.th_ythuc_4_list));
    // TH cuối kỳ
    this.data.th_phamchatchuyeu = JSON.parse(JSON.stringify(this.dataService.th_phamchatchuyeu_list));
    this.data.th_nanglucchung = JSON.parse(JSON.stringify(this.dataService.th_nanglucchung_list));
    this.data.th_nanglucdacthu = JSON.parse(JSON.stringify(this.dataService.th_nanglucdacthu_list));
    this.data.th_danhgiaketquagiaoduc = "";
    //cấp THCS
    this.data.ykien_gvcn_thcs = JSON.parse(JSON.stringify(this.dataService.ykien_gvcn_thcs));
    this.data.danhgia_gvcn_thcs_ck = JSON.parse(JSON.stringify(this.dataService.danhgia_gvcn_thcs_ck));

  }
  popup_import() {
    $("#import-modal").modal('show');
  }
  uploadExcel() {

  }
  toggleType(type) {
    // lưu vào storage
    localStorage.setItem(environment.health_type, type);
    this.data.type = type;
  }
  selectPeriod_time(code, name, cap_hoc) {
    this.data.period_code = code;
    this.data.period_name = name;
    if (cap_hoc == 'THCS') {
      localStorage.setItem(environment.assessment_priod_type_thcs_name, name);
      localStorage.setItem(environment.assessment_priod_type_thcs_code, code);
    } else if (cap_hoc == 'TH') {
      localStorage.setItem(environment.assessment_priod_type_th_name, name);
      localStorage.setItem(environment.assessment_priod_type_th_code, code);
    }
    // Load mặc định
    this.resetData();
    // Load mặc định 
    // this.getSubjectsByClass(this.class_code);
    // Load assessment period data để nếu có thì sẽ update
    if (this.data.student_code != '') {
      this.findData();
    }
  }
  // load môn học cho thông báo điểm theo mẫu của THCS
  // async getSubjectsByClass(class_code) {

  //   let data = {
  //     // hocky: this.appService.hockySelected,
  //     class_code: class_code
  //   };
  //   this.schoolService.getSubjectsAssignmentClass(data).subscribe(
  //     async response => {
  //       // this.isLoading.dismiss();
  //       console.log(response);
  //       if (response) {
  //         this.subjects = response;
  //       }
  //     },
  //     error => {
  //       // this.isLoading.dismiss();
  //       console.error(error);
  //     })
  // }
  mark_keypress(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    // điểm
    if (charCode == 44 || charCode == 46 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    // đánh giá
    if (charCode == 44 || charCode == 46 || charCode == 100 || charCode == 99 || charCode == 273
      || charCode == 67 || charCode == 68
      || charCode == 273 || charCode == 272//Đ 
    ) {
      return true;
    }

    return false;
  }
  markFocusoutFunction(i, name, item) {
    console.log(item);
    if (item != null && typeof item === "string")
      item = item.replace(",", ".");
    // validMark điểm
    this.subjects[i][name] = this.utility.validMark(item);
    // validMark đánh giá
    if (this.subjects[i][name] == '') {
      this.subjects[i][name] = this.utility.validMarkNhanXet(item);
    }
  }
  save() {
    console.log(this.data);
    console.log(this.subjects);
    // return;
    if (this.data.student_code == "") {
      this.toastr.error("", "Vui lòng chọn học sinh");
      return;
    }
    if (this.data.period_time == "") {
      this.toastr.error("", "Vui lòng chọn thời gian");
      return;
    }
    if(this.period_code == null || this.period_code == ''){
      this.toastr.warning("", "Vui lòng chọn chu kỳ");
        return;
    }
    // xửu lý chỉ lấy tháng năm
    // this.data.period_time = this.utilityService.dayStrToMonthFull(this.selectedDateStr);



    // file đính kèm
    let filename = '';
    let filepath = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.data.student_code + "." + this.fileAttach.name.split('.').pop();
      filepath = environment.cateType_assessment_period + "/" + this.utilityService.dayStrToMonth(this.selectedDateStr)+ "/" + this.data.period_code;
      this.data.attached_file = filepath + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    if (this.data.cap_hoc == 'THCS') {
      this.data.thcs_ketquamonhoc = this.subjects;
    } else if (this.data.cap_hoc == 'TH') {
      this.data.th_ketquamonhoc = this.subjects;
    }

    this.isLoading = true;
    this.assessmentService.saveAssessmentPeriod(this.data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.data.id = response.value;
          console.log(this.data.id);

          // nếu có file đính kèm
          if (filename != '')
            this.uploadFileAttach(this.fileAttach, filepath, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm

          this.toastr.success("", "Lưu hô sơ thành công");
          // cập nhật status ở health record  
          this.studentChange.emit({ 'student_code': this.student_code, 'status': 0, 'period_attached_file': filepath + filename });
        }
        else {
          this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
      })
  }
  update() {
    console.log(this.data);
    if (this.data.student_code == "") {
      this.toastr.error("", "Vui lòng chọn học sinh");
      return;
    }
    if (this.data.period_time == "") {
      this.toastr.error("", "Vui lòng chọn thời gian");
      return;
    }
    if (this.data.period_code == "") {
      this.toastr.error("", "Vui lòng chọn kỳ đánh giá");
      return;
    }
    // xửu lý chỉ lấy tháng năm
    // this.data.period_time = this.utilityService.dayStrToMonthFull(this.selectedDateStr);

    // file đính kèm
    let filename = '';
    let filepath = '';
    if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
      filename = this.data.student_code + "." + this.fileAttach.name.split('.').pop();
      filepath = environment.cateType_assessment_period + "/" + this.utilityService.dayStrToMonth(this.selectedDateStr)+ "/" + this.data.period_code;
      this.data.attached_file = filepath + "/" + filename;
      console.log("1111");
    } else {
      console.log("2222");
    }

    this.isLoading = true;
    this.assessmentService.updateAssessmentPeriod(this.data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {

          // nếu có file đính kèm
          if (filename != '')
            this.uploadFileAttach(this.fileAttach, filepath, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm


          this.toastr.success("", "Cập nhật thành công");

          // cập nhật status ở health record  
          this.studentChange.emit({ 'student_code': this.student_code, 'status': this.data.status, 'period_attached_file': filepath + filename });
        }
        else {
          this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
        console.error(error);
        this.findData();
      })
  }

  /////////////////
  
  openNhapNhanhKetQua() {
    if (this.class_code == '') {
      this.toastr.warning("", "Thầy cô vui lòng chọn lớp để nhập nhanh");
      return;
    }
    const dialogRef = this.dialog.open(HealthInputClassComponent, {
      disableClose: true,
      position: {
      },
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { class_code: this.class_code, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);
        //load lại health record
        this.studentChange.emit({ 'reload': true });
      }
    });
  }
  resetMark(item){
    item.mark = "";
    item.mark_mdd = "";
    item.value = "";
  }


  // upload file
  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
  }
  uploadFileAttach(fileUpload: File, des: string, name: string) {

    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: des,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }



}
