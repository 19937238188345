import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-working-time-picker',
  templateUrl: './working-time-picker.component.html',
  styleUrls: ['./working-time-picker.component.css']
})
export class WorkingTimePickerComponent implements OnInit {
  dataList: any = [];
  indexSelected = -1;
  filter = {
    name: "",
    khoi: "",
    deleted: 0,
    type: environment.cateType_checkin_time
  }
  sorter = {
    sortStr: 'checkin_start ASC',
  }
  //for paging
  viewNumsObj = this.appService.viewNums[2].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  isLoading = false;
  constructor(
    public categoryeduService: CategoryEduService,
    public appService: AppService,
    private toastr: ToastrService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<WorkingTimePickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log(data);
    this.loadClass();
  }

  ngOnInit(): void {

  }
  loadClass() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          this.dataList = response.data;
          //thêm selected_temp để cho action
          this.dataList.forEach(element => {
            element.checkin_weeks_name =  this.utility.getWeekNameByWeekCode(element.checkin_weeks);
            // nếu được chọn là 1 giáo viên thì hiển thị khung giờ của giáo viên
            if(this.data.length == 1){
                if(this.data[0]['checkin_time_ids'].includes(element.id.toString())){
                  element.selected_temp = true;
                }else
                element.selected_temp = false;
            }else
            element.selected_temp = false;
          });
        }
      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }
  saveChangeClass() {

  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {

    var obj_ids = [];
    this.dataList.forEach(element => {
      if (element.selected_temp) {
        obj_ids.push(element.id);
      }
    });

    if (obj_ids.length == 0) {
      this.toastr.warning("", "Bạn chưa chọn khung giờ");
      return;
    }

    let data = {
      type: environment.cateType_checkin_time,
      obj_ids: obj_ids,
      user_codes: this.data
    }
    console.log(this.data);
    this.categoryeduService.saveMultiCategorys(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok" && response.affectedRows > 0) {
          let result = {
            // class_code: this.dataList[this.indexSelected].code,
            code: 'ok',
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })


    // if(this.indexSelected > -1){

    //   let result = {
    //     class_code: this.dataList[this.indexSelected].code,
    //     code: 'ok',
    //   }
    //   this.dialogRef.close(result);
    // }else{

    // }
  }

}
