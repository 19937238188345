import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  // @Input() progress: number = 0;
  @Input() doing: number = 0;
  @Input() left: number = 0;

  progress = 0;
  progress_left = 0;

  
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.progress = (this.doing / (this.doing+ this.left)) * 100;
    this.progress_left = 100 - this.progress;

    if (this.progress < 0) {
      this.progress = 0;
    } else if (this.progress > 100) {
      this.progress = 100;
    }
  }
}
