import { Router } from '@angular/router';

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { PromotionService } from '../../_services/promotion.service';
import { Promotion } from '../../model/promotion';
import { AppService } from 'src/app/_services/app.service';
declare var $: any;
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit {
  isLoading = false;
  promotions = [];
  promotions_backup: any;

  // for add
  promotion = new Promotion("");
  // for update
  promotionSelected: any;
  isDeleted: boolean;

  //khuyến mãi
  kieuKhuyenMai = "%";
  khuyenMai = 0;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: this.appService.sort_promotions[0].code,
  }
  // filter
  filter = {
    name: "",
    code: "",
    type: "",
    deleted: "",
  }
  //for search
  searching = false;

  constructor(
    public promotionService: PromotionService,
    private router: Router,
    private toastr: ToastrService,
    public appService: AppService,

  ) {
    this.promotionSelected = this.promotion;
    this.countAllpromotiongory();
  }

  ngOnInit() {
    this.loadPromotion();
  }
  ngAfterViewInit() {

  }

  deleteStatus_change(event) {
    this.loadPromotion();
  }
  sort_change(event) {
    this.loadPromotion();
  }

  async loadPromotion() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.promotionService.loadPromotion(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          // this.promotions = response;
          // this.promotions_backup = response;
          // console.log(this.promotions);

          this.promotions = response.data;
          this.promotions_backup = response.data;
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.promotions.length;
          // for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;


        }
      },
      error => {
        console.error(error);
      })

  }

  popup_promotion_add(event) {
    console.log(event);
    console.log(event.target.id);
    $("#" + event.target.id).trigger("blur");
    $("#add-promotion-modal").modal('show');
    this.countAllpromotiongory();
  }
  countAllpromotiongory() {

    // $(".blurButton").trigger("blur"); // bỏ focus để tránh enter


    let filter = {};
    this.promotionService.countAllCustomer(filter).subscribe(
      response => {

        this.promotion.code = environment.CUSTOMER_CODE_PREFIX + (parseInt(response[0].CountAllCustomer) + 1).toString();

      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.promotions = this.promotions_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.promotions = this.promotions.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }

  popupUpdate(item) {
    this.promotionSelected = item;
    this.isDeleted = this.promotionSelected.deleted == 0 ? false : true;
    $("#update-promotion-modal").modal('show');
  }
  update() {

    this.isLoading = true;

    this.promotionSelected.deleted = this.isDeleted ? 1 : 0;

    console.log(this.promotionSelected);
    this.promotionService.update(this.promotionSelected).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response) {
          this.loadPromotion();
          $("#update-promotion-modal").modal('hide');
          this.toastr.success("Thành công", "Cập nhật khách hàng");
        }
      },
      error => {

        console.error(error);
      })


  }

  SelectkieuKhuyenMai(type) {
    this.kieuKhuyenMai = type;
    console.log("type", type);
  }
  gotoDetail() {
    this.router.navigate(['/promotion-detail', { id: 1990 }]);
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadPromotion();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadPromotion();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadPromotion();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadPromotion();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadPromotion();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    
    
}
