
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messenger-detail',
  templateUrl: './messenger-detail.component.html',
  styleUrls: ['./messenger-detail.component.css']
})
export class MessengerDetailComponent implements OnInit {
  categorys : any; // lớp
  checkboxAllButton = true; // mặc định chọn all
  discussion ={
    recipient:'',
    title:'',
    message: '',
  };
  isLoading = false;

 
  constructor(
    public categoryeduService: CategoryEduService,
    public studentService: StudentService,
    public messengerService: MessengerService,
    public dialogRef: MatDialogRef<MessengerDetailComponent>,
    public appService: AppService,
    public dialog: MatDialog,
  ) {
    this.discussion.message = this.appService.currentUser.school_name ;
    this.appService.page_title = "Liên lạc, thông báo";
    this.loadCategory();
  
  }

  ngOnInit(): void {
  }

  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
    
  }
  updateDiscussionRecipient(){
    this.discussion.recipient = '';
    this.categorys.forEach(item => {
      if(item.temp_checked == 1){
        this.discussion.recipient += item.code + ",";
      }
    });
   
  }
  confirmSendMessenger(){
    let data = {
      title : 'Xác nhận gửi thông báo',
      msg : 'Đồng ý đề bắt đầu gửi thông báo.',
    }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          // close hoặc không close
          this.send();
      
        }
      });
  }
  send(){
    // cập nhật danh sách lớp cho discussion.recipient
    this.updateDiscussionRecipient();
    
    this.messengerService.sendMessenger(this.discussion).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.affected > 0) {
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })
    console.log(this.discussion);
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: 50,
      pageObj: 1
    };

    let filter = {
      name: "",
      khoi_code: "",
      deleted: 0,
      type: environment.cateType_class
    }
    let sorter = {
      sortStr: this.appService.sort_category[0].code,
    }
    this.categoryeduService.loadCategory(filter, sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          // this.updateDiscussionRecipient();
        }
      },
      error => {
        console.error(error);
      })
  }
  
}
