<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col d-flex justify-content-start align-items-center">

            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập tên" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 150px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Xếp theo</mat-label>
                <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="loadCategory()" name="sorter">

                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>



        <div class="col-4 d-flex justify-content-end align-items-center">
            <div class="mr-2">
                <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
            </button>
            </div>
            <div>
                <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="saveArrange()">
            <span class="btn-label"><span class="material-icons">done</span></span>Lưu sắp xếp
        </button>
            </div>
        </div>
    </div>




    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="table-responsive row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Tên danh mục (khoa khám)</th>
                    <th>Thuộc tính</th>
                    <th class="text-right" style="width: 50px;"></th>
                </tr>
            </thead>
            <tbody cdkDropList [cdkDropListData]="categorys" class="example-list" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="noReturnPredicate">
                <tr *ngFor="let item of categorys; let i = index" [cdkDragData]="item" cdkDrag>
                    <td>
                        <!-- <a class="avatar-img-list" href="javascript:;" [style.background-image]="appService.showImageTeacher(item.image_url)">
                        </a> -->
                        <img src="./assets/img/icons/cate-health.png" alt="Avatar" class="avatar-img-list">
                    </td>
                    <td style="cursor: move;">
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                            {{item.code}}
                        </div>
                    </td>
                    <td>
                        <!-- <a class="" (click)='openCateHealthSub(item,i)'>
                            <span class="material-icons"> info  </span>
                        </a> -->

                        <a class="avatar-img-list" (click)='openCateHealthSub(item,i)' style="display: inline;">
                            <img src="./assets/img/icons/tag.png" alt="image" width="25" />
                        </a>


                        {{item.attributes}}
                    </td>

                    <td>
                        <button class="btn-adjust btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'>
                            <span class="material-icons"> border_color </span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới danh mục sức khỏe</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>
                <!-- <div class="form-group has-validation">
            <label class="col-form-label">Tên tổ bộ môn</label>
            <div class="">
              <input [(ngModel)]="cate.code" class="form-control" minlength="2" maxlength="50"
                (focusout)="focusOutCheckCode()" [readonly]="'mts' === CodeType" name="code" type="text">
              <span class="help-block">
                <div class="col-sm-10 d-flex align-items-center">
                  <label class="radio radio-grey radio-primary radio-inline">
                    <input type="radio" name="b" [checked]="'mts' === CodeType" (click)="codeTypeClick('mts');">
                    <span class="input-span"></span>Mã tự sinh</label>
                  <label class="radio radio-grey radio-primary radio-inline">
                    <input type="radio" name="b" [checked]="'tn' === CodeType" (click)="codeTypeClick('tn');">
                    <span class="input-span"></span>tự nhập</label>
                </div>
              </span>
  
            </div>
          </div> -->
                <div class="form-group ow">
                    <label class="col-form-label">Tên </label>
                    <div class="">
                        <input [(ngModel)]="cate.name" class="form-control" name="name" required maxlength="30" placeholder="Tên danh mục" type="text">
                    </div>
                </div>


            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>

            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateCategory()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật danh mục</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+categoryselected_imageUrl+')'">
                            <!-- <div class="file-input" [style.background-image]="categoryselected_imageUrl"> -->
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile_update" name="uploadFile" (change)="changeImageUpdate($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>
                <div class="form-group has-validation">
                    <label class="col-form-label">Mã</label>
                    <div class="">
                        <input [(ngModel)]="categoryselected.code" class="form-control" [readonly]="true" name="code" type="text">
                    </div>
                </div>
                <div class="form-group ow">
                    <label class="col-form-label">Tên</label>
                    <div class="">
                        <input [(ngModel)]="categoryselected.name" class="form-control" name="name" maxlength="30" placeholder="Tên tổ bộ môn" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
            </div>
        </form>
    </div>
</div>