<div class="page-content fade-in-up">
    <div class="row">
        <div class="col">
            <div class="qbs-box">
                <div class="qbs-box-title">
                    Thông tin cá nhân
                </div>
                <div class="qbs-box-body">
                    <div><img src="../../../assets/img/image.png" alt="" style="width: 60px;"></div>
                    <div><span class="text-info">Họ và tên :</span> </div>
                    <div><span class="text-info">Giới tính :</span> </div>
                    <div><span class="text-info">Ngày sinh :</span> </div>
                    <div><span class="text-info">Mẹ:</span> 20</div>
                    <div><span class="text-info">Điện thoại:</span> 20</div>
                    <div><span class="text-info">Bố:</span> Trương Sỹ Chương</div>
                    <div><span class="text-info">Điện thoại:</span> 20</div>
                    <div><span class="text-info">Người bảo hộ:</span> Hoàng Văn Toàn</div>
                    <div><span class="text-info">Điện thoại:</span> 20</div>
                </div>
            </div>
            <div class="m-3"></div>
            <div class="qbs-box">
                <div class="qbs-box-title">
                    Thông tin lớp học Lớp
                </div>
                <div class="qbs-box-body">
                    <div><img src="../../../assets/img/image.png" alt="" style="width: 60px;"></div>
                    <div><span class="text-info">Lớp :</span> </div>
                    <div><span class="text-info">Sỹ Số:</span> 20</div>
                    <div><span class="text-info">Giáo viên chủ nhiệm:</span> Trương Sỹ Chương</div>
                    <div><span class="text-info">Lớp trưởng:</span> Hoàng Văn Toàn</div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="qbs-box">
                <div class="qbs-box-title">
                    Thông tin chuyên cần ngày <span class="text-primary">{{dateSelected}}</span>
                </div>
                <div class="qbs-box-body">
                    <div><img src="../../../assets/img/image.png" alt="" style="width: 60px;"></div>
                    <div><span class="text-info">Lớp :</span> 5C</div>
                    <div>
                        <app-nodata *ngIf="this.checkinDayDetail.length == 0" msg="Chọn ngày để xem chi tiết điểm danh trong ngày" img="./assets/img/icons/camera-student.png"></app-nodata>
                        <table>
                            <tr *ngFor="let item of this.checkinDayDetail; let i = index">
                                <td>
                                    {{item.checkin_time}}
                                </td>
                                <td>
                                    <img [src]="item.detected_image_url" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col">
            <mat-calendar class="calendar-student-detail" [startAt]="selectedDate_date" [dateClass]="dateClass()" (selectedChange)="onSelect($event)" (monthSelected)="handleMonthSelected($event)"></mat-calendar>
        </div>
    </div>
</div>