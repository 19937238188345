<!-- <div class="page-heading">
    <h1 class="page-title">Danh sách cán bộ giáo viên</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="#"><span class="material-icons sidebar-item-icon-material">home</span></a>
        </li>
        <li class="breadcrumb-item">Danh sách cán bộ giáo viên</li>
    </ol>
</div> -->

<div class="page-content fade-in-up">

    <div style="height: 48px;" class="row justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start">

                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm cán bộ giáo viên</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>


                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 120px;" class="">
                        <mat-label>Tổ</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                            <mat-option [value]="" [disabled]="false">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>

                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 150px;" class="">
                        <mat-label>Sắp xếp</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)">

                            <mat-option *ngFor="let item of appService.sort_teachers" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="col-4 p-0">
            <div class="d-flex justify-content-end">

                <div class="p-2">
                    <div class="btn-highlight " [ngClass]="{'active' : isHighlight }">
                        <button [disabled]="isLoading" class="blurButton btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="updateTeachingAssignment()">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">save</span></span>
                            Lưu phân công
                        </button>
                    </div>
                </div>
                <div class="p-2">


                    <div ngbDropdown class="d-inline-block">

                        <button class="blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [disabled]="this.appService.hockySelected != 'I'" ngbDropdownItem><span
                                    class="material-icons">looks_one
                                </span>
                                Học kỳ I</button>
                            <button [disabled]="this.appService.hockySelected != 'II'" ngbDropdownItem><span
                                    class="material-icons">looks_two
                                </span>
                                Học kỳ II</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col" style="padding: 0;">
            <div class="pcgd-cover">
                <div>
                    <div class="row  row-header bg-primary">
                        <div class="col-4">
                            Giáo viên
                        </div>
                        <div class="col-8">
                            Kết quả
                        </div>
                    </div>
                </div>
                <div class="panel-body">

                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let item of this.teachers; let i = index" [expanded]="teacherIndexSelected == i">
                            <mat-expansion-panel-header (click)="clickTeacher(i)" class="hoverClick" [ngClass]="{'indexSelected' : i == teacherIndexSelected,'hasData' : item.checkin_list.length > 0 }">
                                <mat-panel-title> {{i+1}}. {{item.name}} </mat-panel-title>
                                <mat-panel-description *ngIf="item.checkin_list.length>0"> {{item.checkin_list.length}} lịch
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div>
                                <table class="table table-hover mb-0 " style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Lớp</th>
                                            <th>Môn</th>
                                            <th class="text-center">Thời gian</th>
                                            <th>T Lượng</th>
                                            <th>S lượng</th>
                                            <th>Lịch</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ktem of item.checkin_list; let k = index" class="item-action-cover">
                                            <td class="text-center"> {{k+1}}.</td>
                                            <td>{{ktem.class_name}}</td>
                                            <td>{{ktem.subject_name}}</td>
                                            <td>
                                                <div>{{ktem.checkin_time}} | {{ktem.checkin_weeks_name}}</div>
                                                <div style="font-size: small; color: gray;">
                                                    {{ktem.checkin_from}} - {{ktem.checkin_to}}
                                                </div>
                                            </td>
                                            <td>{{ktem.checkin_duration}}</td>
                                            <td>
                                                <!-- <button *ngIf="ktem.detail_count == 0" class=" btn btn-sm btn-default btn-icon-only btn-circle  btn-thick mr-1" placement="top" ngbTooltip="Số lượng lịch chi tiết">
                                                    {{ktem.detail_count}}
                                                </button>
                                                <button *ngIf="ktem.detail_count > 0" class=" btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick mr-1" placement="top" ngbTooltip="Số lượng lịch chi tiết">
                                                    {{ktem.detail_count}}
                                                </button> -->

                                                <button (click)='openScheduleDetail(ktem)' placement="top" ngbTooltip="Số lượng chi tiết" class="btn btn-secondary  btn-labeled btn-labeled-left btn-icon">
                                                    <span class="btn-label"><span
                                                            class="material-icons text-primary font-18"> calendar_month
                                                        </span></span>
                                                    {{ktem.detail_count}}
                                                </button>

                                            </td>
                                            <td>{{ktem.checkin_name}}
                                                <div *ngIf="true" class="item-action">
                                                    <button class=" btn btn-sm btn-white text-info btn-icon-only btn-circle  btn-thick mr-1" placement="top" [ngbTooltip]="ktem.detail_count == 0 ? 'Tạo lịch chi tiết' : 'Xóa và tạo lại'" (click)="loadAndSaveTeachingAssignmentDetail(ktem)">
                                                        <span class="material-icons">
                                                            auto_awesome
                                                        </span>
                                                    </button>
                                                    <button class=" btn btn-sm btn-white text-danger btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Xóa lịch chi tiết và lịch" (click)="popupDelete(k, item.checkin_list)">
                                                        <span class="material-icons">
                                                            remove_circle
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="item.checkin_list.length == 0" style="text-align: center; padding-top: 10px; font-size: small;">
                                    Giáo viên chưa được phân công lịch dạy
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- <div class="row row-body hoverClick hasImage" *ngFor="let item of this.teachers; let i = index" [style.background-image]="appService.showImageTeacher(item.image_url)" [ngClass]="{'indexSelected' : i == teacherIndexSelected,'hasData' : item.checkin_list.length > 0, 'disable-cbgv': !(appService.checkFullPermission() || item.code == appService.currentUser.code) }"
                        (click)="clickTeacher(i)">
                        <div class="col-4">
                            <div style="white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;">{{i+1}}. {{item.name}} </div>
                        </div>
                        <div class="col-8 teachingAssignment">
                            <span>{{item.checkin_list.length}} </span>
                        </div>
                        <div *ngIf="true" class="item-action">
                            <button class="mr-2 btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Chi tiết phân công" (click)="popupDetail(item)">
                                <span class="material-icons">
                                    assignment
                                </span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
        <div class="p-0" style="width: 150px;">
            <div class="pcgd-cover">
                <div>
                    <div class="row row-header bg-primary">
                        <div class="col">
                            Môn dạy
                        </div>

                    </div>
                </div>
                <div class="panel-body">
                    <div *ngIf="appService.currentUser.caps.length > 1" class="text-center">
                        <div class="btn-group btn-group-sm" style="margin-top: -2px">
                            <div class="btn " *ngFor="let item of appService.currentUser.caps; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.capSelected, 'btn-outline-primary': item.code != this.appService.capSelected}" (click)="caphoc_change(item.code)">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="row row-body" *ngFor="let item of this.categorys_subject; let i = index">
                        <div class="col hoverClick" [ngClass]="{'indexSelected' : i == subjectIndexSelected,'hasData' : (item.temp_checked == 1 || item.temp_checked)}" (click)="clickSubjects(i)">
                            <span class="text-info">{{item.subject_cap}}</span> {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-0" style="width: 150px;">
            <div class="pcgd-cover">
                <div>
                    <div class="row row-header bg-primary">
                        <div class="col">
                            Lớp
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row  row-body" *ngFor="let item of this.categorys_class; let i = index">

                        <div class="col hoverClick" (click)="selectClass($event,i)" [ngClass]="{'indexSelected' : i == classIndexSelected,'hasData' : (item.temp_checked == 1 || item.temp_checked)}">
                            <label class="checkbox checkbox-inline checkbox-primary" style="line-height: 21px; width: 100%;">
                                <input type="checkbox" disabled [(ngModel)]="item.temp_checked">
                                <span class="input-span"></span>{{item.name}}</label>
                        </div>

                    </div>
                    <div *ngIf="categorys_class ==  undefined || categorys_class.length == 0" class="text-center mt-5" style="font-size: small;">
                        <img style="width: 40px;" src="assets\img\icons\file-and-folder.png" alt="">
                        <div class="mb-2">Thầy/Cô chưa chọn môn học</div>
                        <div>hoặc môn học chưa được gán vào lớp ( Vào menu môn học chọn Gán môn học cho lớp)</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-0" style="width: 220px;">
            <div class="pcgd-cover">
                <div>
                    <div class="row row-header bg-primary">
                        <div class="col">
                            Lịch dạy
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row  row-body" *ngFor="let item of this.checkin_time_teacher; let i = index">
                        <div class="col hoverClick" (click)="selectCheckinTime($event,i)" [ngClass]="{'indexSelected' : i == checkinTimeIndexSelected,'hasData' : (item.temp_checked == 1 || item.temp_checked)}">
                            <label class="checkbox checkbox-inline checkbox-primary" style="line-height: 21px; width: 100%;">
                                <input type="checkbox" [value]="item.code" [name]="item.id" disabled
                                    [(ngModel)]="item.temp_checked">
                                <span class="input-span"></span>
                                <div>{{item.name}}</div>
                                <div style="font-size: small;">{{item.checkin_start}} - {{item.checkin_end}} |
                                    {{item.checkin_weeks_name}}</div>
                            </label>
                        </div>

                    </div>
                    <div *ngIf="checkin_time_teacher ==  undefined || checkin_time_teacher.length == 0" class="text-center mt-5" style="font-size: small;">
                        <img style="width: 40px;" src="assets\img\icons\file-and-folder.png" alt="">
                        <div class="mb-2">Thầy/Cô chưa chọn lớp</div>
                        <div>hoặc giáo viên chưa được khai báo lịch học ( Vào menu giáo viên và chọn gán lịch làm việc)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div> -->
</div>