import { environment } from './../../../environments/environment';
import { CartObj } from './../../model/cart-obj';
import { AppService } from './../../_services/app.service';
import { Component, AfterViewInit } from '@angular/core';
import { SaleService } from '../../_services/sale.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../_services/customer.service';
import { Router } from '@angular/router';
import { User } from '../../model/user';
import { UtilityService } from 'src/app/_services/utility.service';
import { Customer } from 'src/app/model/customer';
import { SharedService } from 'src/app/_services/shared.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Promotion } from 'src/app/model/promotion';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUploadImgComponent } from 'src/app/components/profile-upload-img/profile-upload-img.component';
declare var $: any;
@Component({
  selector: '[app-header]',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
})
export class AppHeader implements AfterViewInit {
  dateTimeStr: string

  cartObj = new CartObj();
  // customer_pay = 0;
  customers: any;
  customers_backup: any;

  //for customer
  filter = {
    deleted: "0",
    type: environment.customer_type,
    name: "",
    category_code: "",
  }

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  sorter = {
    sortStr: this.appService.sort_customer[0].code,
  }
  // promotion
  promotion = new Promotion("%");
  price_after_promotion = 0;
  price_promotion = 0;

  // for VNKTList
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Chọn tất cả',
    unSelectAllText: 'Bỏ chọn tất cả',
    allowSearchFilter: true,
    searchPlaceholderText: "Tìm kiếm"
  };
  VNKTList = [];

  

  constructor(
    private router: Router,
    public appService: AppService,
    public saleService: SaleService,
    public productService: ProductService,
    public customerService: CustomerService,
    private toastr: ToastrService,
    public utilityService: UtilityService,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
    // console.log(this.appService.currentUser);

    

    this.cartObj.type = 0; // 1 = nhập hàng, 0 = bán hàng, 0 is sale, 1 is imporrt

  }

  ngAfterViewInit() {
    // this.loadCustomer();
  }
  scholasticSelected_change(){
    localStorage.setItem(environment.scholasticSelected, this.appService.scholasticSelected);
    window.location.reload();
  }
  addNewCart() {
    this.appService.cartObj = new CartObj();
    this.appService.listCart.push(this.appService.cartObj);
    this.appService.cartIndex = this.appService.listCart.length - 1;
  }
  selectCart(i) {
    this.appService.cartIndex = i;
    this.appService.openCheckout();
  }
  selectCartDesk(i) {
    this.appService.deskIndex = i;
    this.appService.openCheckout();
  }
  removeProduct(i, item) {
    console.log("item1", this.appService.cartObj.cart);
    this.appService.cartObj.cart.splice(i, 1); //delete index i

    this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity - item.quantity;
    this.appService.cartObj.price_totol = this.appService.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;
    // this.customer_pay = this.appService.cartObj.price_totol;
    // cập nhật tổng giá trị của Desk
    if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
    console.log("item2", this.appService.cartObj.cart);
  }
  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity--;
      this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity - 1;
      this.appService.cartObj.price_totol = this.appService.cartObj.price_totol - parseInt(item.product.price);
      // this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;
      this.appService.preparePromotion(this.appService.cartObj);
    }
    // cập nhật tổng giá trị của Desk
    if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  quantity_add(item) {
    item.quantity++;
    this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity + 1;
    this.appService.cartObj.price_totol = this.appService.cartObj.price_totol + parseInt(item.product.price);
    // this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;
    this.appService.preparePromotion(this.appService.cartObj);
    // cập nhật tổng giá trị của Desk
    if(this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }

  openProfileUploadImg(){
    // mở popup
    const dialogRef = this.dialog.open(ProfileUploadImgComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {'teacherSelected': this.appService.currentUser,
              'facultys': []
              }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("openProfileUploadImg: ", result);
        
      }
    });
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    // this.loadCustomer();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCustomer();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCustomer();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCustomer();
    }
  };

  formatter = (x: { name: string }) => x.name;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log(this.filter.name);
        // this.loadCustomer();
      }),
      map(
        term => term === '' ? [] : this.appService.customers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)
      ),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.selectCustomer(r);
  }
  // end for customer
  doPayment() {
    if(this.appService.cartObj.is_time_service == 1 && this.appService.cartObj.service_price == 0){
      this.toastr.warning("Nhấn vào biểu tượng đồng hồ để dừng", "Bạn chưa dừng thời gian tính tiền");
      return
    }

    if(this.appService.cartObj.customer_pay < this.appService.cartObj.price_after_promotion && this.appService.cartObj.customer_id == 0){
      this.toastr.warning("Bạn vui lòng chọn khách quen", "Khách lẻ phải thanh toán đủ tiền");
      return
    }

    this.sendNotification();
    // return;
    let cartObj = this.prepareSaleObject({ sale_status: 1 });//1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
    this.saleService.insertSale(cartObj).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          // this.sharedService.sendClickEvent("from app-header");// load Product from Sale component
          // $('.checkout-widget').backdrop();
          console.log("isFromDeskCart: ", this.appService.isFromDeskCart);
          if(this.appService.isFromDeskCart){
            
            // let tempp = new CartObj();
            // tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
            // tempp.x = this.appService.deskList[this.appService.deskIndex].x;
            // tempp.y = this.appService.deskList[this.appService.deskIndex].y;
            // tempp.id = this.appService.deskList[this.appService.deskIndex].id;
            // tempp.name = this.appService.deskList[this.appService.deskIndex].name;
            // tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;
            
            // this.appService.deskList[this.appService.deskIndex] = tempp;
            // localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
            // localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
            this.deleteCart();

            this.appService.updateDeskRevenue(); 
          }else{
            if (this.appService.cartIndex > 0) {
              this.appService.listCart.splice(this.appService.cartIndex, 1); //delete cart
              this.appService.cartIndex = 0;
            } else {
              this.appService.cartIndex = 0;
              this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
            }
            localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
            localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
            $('.checkout-widget').backdrop();
          }
          this.toastr.success("", "Thanh toán thành công");
          
          
          
        }
      },
      error => {
        console.error(error);
      });
  }
  sendNotification() {

  }



  prepareSaleObject = function({ sale_status }) {
    // for insert detail sale
    let product_codes = "";
    let product_names = "";
    let product_stocks = "";
    let prices = "";
    let quantitys = "";
    for (let i = 0; i < this.appService.cartObj.cart.length; i++) {
      if (this.appService.cartObj.cart.length == i + 1) {
        product_codes += this.appService.cartObj.cart[i].product.code.toString();
        product_names += this.appService.cartObj.cart[i].product.name.toString();
        product_stocks += this.appService.cartObj.cart[i].product.stock.toString();
        prices += this.appService.cartObj.cart[i].product.price.toString();
        quantitys += this.appService.cartObj.cart[i].quantity.toString();
      } else {
        product_codes += this.appService.cartObj.cart[i].product.code.toString() + ",";
        product_names += this.appService.cartObj.cart[i].product.name.toString() + ",";
        product_stocks += this.appService.cartObj.cart[i].product.stock.toString() + ",";
        prices += this.appService.cartObj.cart[i].product.price.toString() + ",";
        quantitys += this.appService.cartObj.cart[i].quantity.toString() + ",";
      }
    }

    let cartObj = new CartObj();
    // for insert  sale
    // cartObj.code: "",
    cartObj.type = this.cartObj.type; // 1 = nhập hàng, 0 = bán hàng, 0 is sale, 1 is imporrt
    cartObj.status = sale_status; //1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
    cartObj.product_quantity = this.appService.cartObj.cart.length;
    cartObj.price_totol = this.appService.cartObj.price_totol;
    cartObj.price_after_promotion = this.appService.cartObj.price_after_promotion;
    cartObj.customer_pay = this.appService.cartObj.customer_pay == null ? 0 : this.appService.cartObj.customer_pay; // Tiền khách đưa
    cartObj.payment_total = cartObj.customer_pay > this.appService.cartObj.price_after_promotion ? this.appService.cartObj.price_after_promotion :cartObj.customer_pay; // Tiền khách phải trả

    cartObj.price_promotion = this.appService.cartObj.price_promotion;
    cartObj.promotion_code = this.promotion.code;
    cartObj.promotion_type = this.promotion.type;
    cartObj.promotion_promotion = this.promotion.promotion;


    cartObj.quantity = this.appService.cartObj.total_quantity;
    cartObj.total_quantity = this.appService.cartObj.total_quantity;
    cartObj.customer_id= this.appService.cartObj.customer_id;
    cartObj.customer_code = this.appService.cartObj.customer_code;
    cartObj.customer_name = this.appService.cartObj.customer_name;
    cartObj.user_id = this.appService.currentUser.id;
    cartObj.user_name = this.appService.currentUser.name;
    cartObj.note = "";

    //for service price
    cartObj.service_price = this.appService.cartObj.service_price;
    cartObj.service_price_temp = 0; 
    cartObj.service_detail_str = JSON.stringify(this.appService.cartObj.cart_service); 


    cartObj.product_codes = product_codes;// for insert detail sale
    cartObj.product_names = product_names;// for insert detail sale
    cartObj.product_stocks = product_stocks;// for insert detail sale
    cartObj.prices = prices;// for insert detail sale
    cartObj.quantitys = quantitys;// for insert detail sale
    return cartObj;
  }

  doOrder() {
    if(this.appService.cartObj.is_time_service == 1){
      this.toastr.warning("", "Dịch vụ không được đặt hàng");
      return
    }

    if (this.appService.cartObj.customer_code == environment.CUSTOMER_CODE_PREFIX) {
      this.toastr.warning("", "Vui lòng chọn khách hàng khi dặt hàng");
      this.popupSelectCustomer();
    } else {
      let param = this.prepareSaleObject({ sale_status: 0 });//1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
      this.saleService.insertSale(param).subscribe(
        async response => {
          if (response.code == "ok" && response.affectedRows > 0) {
            this.sharedService.sendClickEvent("from app-header");// load Product from Sale component
            console.log("sendClickEventsendClickEventsendClickEventsendClickEvent");
            $('.checkout-widget').backdrop();

            if(this.appService.isFromDeskCart){
            
              let tempp = new CartObj();
              tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
              tempp.x = this.appService.deskList[this.appService.deskIndex].x;
              tempp.y = this.appService.deskList[this.appService.deskIndex].y;
              tempp.id = this.appService.deskList[this.appService.deskIndex].id;
              tempp.name = this.appService.deskList[this.appService.deskIndex].name;
              tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;
              
              this.appService.deskList[this.appService.deskIndex] = tempp;
              localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
              localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
              this.appService.updateDeskRevenue(); 
            }else{
              if (this.appService.cartIndex > 0) {
                this.appService.listCart.splice(this.appService.cartIndex, 1); //delete cart
                this.appService.cartIndex = 0;
              } else {
                this.appService.cartIndex = 0;
                this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
  
              }
              localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
              localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
              this.toastr.success("", "Đặt hàng thành công");
            }

          }
        },
        error => {
          console.error(error);
        });
    }
  }
  print() {
    if(this.appService.cartObj.is_time_service == 1 && this.appService.cartObj.service_price == 0){
      this.toastr.warning("Nhấn vào biểu tượng đồng hồ để dừng", "Bạn chưa dừng thời gian tính tiền");
      return
    }

    this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Hóa Đơn</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();

  }
  //<body onload="window.print();window.close()">${printContents}</body>
  deleteCart() {
    

    if(this.appService.isFromDeskCart){
            
      let tempp = new CartObj();
      tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
      tempp.x = this.appService.deskList[this.appService.deskIndex].x;
      tempp.y = this.appService.deskList[this.appService.deskIndex].y;
      tempp.id = this.appService.deskList[this.appService.deskIndex].id;
      tempp.name = this.appService.deskList[this.appService.deskIndex].name;
      tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;
      tempp.is_time_service = this.appService.deskList[this.appService.deskIndex].is_time_service;
      
      this.appService.deskList[this.appService.deskIndex] = tempp;
      localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    }else{
      if (this.appService.cartIndex > 0) {
        this.appService.listCart.splice(this.appService.cartIndex, 1); //delete cart
        this.appService.cartIndex = 0;
      } else {
        this.appService.cartIndex = 0;
        this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
      }
      localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
      localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
    }
    this.appService.updateDeskRevenue();
    $('.checkout-widget').backdrop();
    $("#delete-cart-modal").modal('hide');
  }
  cancelDeleteCart(){
    $("#delete-cart-modal").modal('hide');
  }

  popupDeleteCart() {
    $("#delete-cart-modal").modal('show');
  }
  popupSelectCustomer() {
    $("#select-customer-modal").modal('show');
  }
  selectCustomer(item) {
    this.appService.cartObj.customer_id = item.id;
    this.appService.cartObj.customer_name = item.name;
    this.appService.cartObj.customer_code = item.code;

    $("#select-customer-modal").modal('hide');
  }
  changeIsActive(item){
    // tính tiền giờ.
    if(item.isActive == 1){// tính tiền giờ
      item.isActive = 0;
      this.utilityService.checkOutService("");

      // đặt giờ kết thúc
      let date = new Date();
      item.serviceEndTime = date;
      item.serviceEndTimeNumberType = date.getTime();
    }else{
      item.isActive = 1;
      this.utilityService.checkOutService("reset");
      //bỏ giờ kết thúc
      item.serviceEndTime = null;
      item.serviceEndTimeNumberType = 0;
    }

    this.appService.updateDeskRevenue();
  }
  selectScholastic(){
    console.log(this.appService.currentUser.scholastic);
    // lưu vào storage
    localStorage.setItem(this.appService.currentUser.id.toString(), JSON.stringify(this.appService.currentUser));
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    
  }
  logout() {
    this.appService.currentUser = new User();
    localStorage.setItem(localStorage.getItem(environment.currentUser), JSON.stringify(this.appService.currentUser));
    localStorage.removeItem(environment.listCart);
    localStorage.removeItem(environment.cartIndex);
    localStorage.removeItem(environment.deskList);
    localStorage.removeItem(environment.deskIndex);
 
    this.router.navigate(['/login', { id: 1990 }]);

    this.appService.listCart = [new CartObj()];

  }

  //for add customer
  isAddingCustomer = false;
  isLoading = false;
  customerObj = new Customer(environment.customer_type);
  customer_add() {
    this.filter.name = "";
    this.customerObj = new Customer(environment.customer_type);
    this.isAddingCustomer = true;
  }
  customer_save() {

    console.log(this.customerObj);
    if (this.customerObj.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên KH");
      return false;
    } else if (this.customerObj.phone.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập số điện thoại KH");
      return false;
    }


    if (true) {
      this.isLoading = true;
      this.customerService.countAllCustomer(environment.customer_type).subscribe(
        response => {

          let customer_code = environment.CUSTOMER_CODE_PREFIX + (parseInt(response.CountAllCustomer) + 1).toString();
          this.customerObj.code = customer_code;
          this.customerService.insertCustomer(this.customerObj).subscribe(
            async response => {
              this.isLoading = false;
              this.isAddingCustomer = false;
              console.log(response);
              if (response) {
                // this.loadCustomer();
                this.selectCustomer(this.customerObj);
                // $("#add-customer-modal").modal('hide');
              }
            },
            error => {
              this.isLoading = false;
              console.error(error);
            })
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })



    }
  }
  SelectkieuKhuyenMai(type) {
    this.appService.cartObj.promotion_type = type;
    this.appService.cartObj.promotion_promotion = 0;
    console.log("this", this.appService.cartObj.promotion_type);
  }
  focusOutPromotion() {
    console.log("type", this.appService.cartObj.promotion_type);
    console.log("promotion", this.appService.cartObj.promotion_promotion);
    this.appService.preparePromotion(this.appService.cartObj);
    // if(this.appService.cartObj.promotion_type == 'đ'){
    //   this.appService.cartObj.price_promotion = this.appService.cartObj.promotion_promotion;
    // }else if(this.appService.cartObj.promotion_type == '%'){
    //   if(this.appService.cartObj.promotion_promotion > 100){
    //     this.appService.cartObj.promotion_promotion = 100;
    //   }
    //   this.appService.cartObj.price_promotion = Math.floor(this.appService.cartObj.price_totol*(this.appService.cartObj.promotion_promotion/100));
    // }
    // // this.price_promotion
    // this.appService.cartObj.price_after_promotion = this.appService.cartObj.price_totol - this.appService.cartObj.price_promotion ;
  }
}



