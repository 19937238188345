<div class="row mb-2 mt-2 justify-content-between">
    <div class="col-8 p-0 d-flex justify-content-start">

        <!-- <div class="p-2">
            <select class="custom-select" [(ngModel)]="filter.khoi" (change)="category_khoi_change($event)" name="khoi" id="khoi">
                <option value="">Khối</option>
                <option [ngValue]="item.code" *ngFor="let item of appService.currentUser.khois">{{item.value}}</option>
            </select>
        </div> -->

        <mat-form-field appearance="fill" class="mr-3" style="width: 150px">
            <mat-label>Khối</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                    {{item.name}} - ({{item.count_class}})
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill" class="mr-3" style="width: 150px">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)">
                <mat-option value="">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                    {{item.name}} - ({{item.student_count}})
                </mat-option>
            </mat-select>
        </mat-form-field>


    </div>

    <div class="col-4 p-0 d-flex justify-content-end">

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày điểm danh</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>



<div>
    <div class="row align-items-center mb-2">
        <div class="col justify-content-end">
            <img style="width: 25px;" src="./assets/img/icons/camera-checkin.png" /> Điểm danh bằng camera AI
            <img style="width: 25px;" src="./assets/img/icons/to-do-list.png" /> Điểm danh từ giáo viên
        </div>
        <div class="col d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">

        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default">
                <tr>
                    <th style="width: 50px; text-align: center;" rowspan="2">#</th>
                    <th rowspan="2" style="width: 230px;">Họ tên</th>
                    <!-- <th>Lớp</th> -->
                    <th colspan="3" class="text-center">Điểm danh {{attention_count}}/{{this.students.length}}</th>
                    <th rowspan="3" [ngClass]="{'isExpand': this.isExpand,'isNotExpand': !this.isExpand}">
                        <div class="d-flex ">
                            <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick mr-2" placement="top" ngbTooltip="Mở rộng / thu nhỏ" (click)="this.isExpand = !this.isExpand">
                                <span *ngIf="!this.isExpand" class="material-icons"> navigate_before </span>
                                <span *ngIf="this.isExpand" class="material-icons"> navigate_next </span>
                            </button>

                            <div class="flex-1 text-center align-items-center">
                                <span class="mr-2">Nhận xét ngày</span>
                                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="confirmSaveCheckInReview()">
                                    <span class="btn-label" *ngIf="isLoading_review"><span class="spinner-border"></span></span>
                                    <span class="btn-label" *ngIf="!isLoading_review"><span class="material-icons"> task_alt </span></span>
                                    Lưu và gửi
                                </button>
                            </div>
                        </div>
                    </th>

                </tr>
                <tr>

                    <th class="text-center" style="width: 55px;">Kiểu</th>
                    <th class="text-center" style="width:91px ;">

                        <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="confirmDoCheckIn()">
                                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> task_alt </span></span>
                                Lưu
                        </button>

                    </th>
                    <th class="text-center">
                        Lý do vắng
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.students; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                        <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                    </td>
                    <td>
                        <div class="d-flex justify-content-start align-items-center">
                            <div>
                                <span *ngIf="item.gender == 1" class="material-icons text-blue">boy</span>
                                <span *ngIf="item.gender == 0" class="material-icons text-pink">girl</span>
                            </div>
                            <div>
                                <div style="font-size: 18px;">
                                    {{item.full_name}}
                                </div>
                                <div class="text-muted" style="font-size: smaller;">
                                    <span>{{item.code}}</span>
                                    <span class="material-icons mb-1 mr-2 ml-2" style="font-size: 8px;">
                                        circle
                                        </span>
                                    <span>{{item.birthday_str}}</span>
                                </div>
                            </div>
                        </div>
                    </td>

                    <td>
                        <img *ngIf="item.checkin_type_status >0 " style="width: 30px;" placement="top" ngbTooltip="Camera" src="./assets/img/icons/camera-checkin.png" />
                        <img *ngIf="item.checkin_type_status == 0" style="width: 30px;" placement="top" ngbTooltip="Thủ công" src="./assets/img/icons/to-do-list.png" />
                    </td>
                    <td class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                            <input class="mail-check" type="checkbox"  [(ngModel)]="item.checkin_status_temp" (change)="updateAttention_count()">
                            <span class="input-span"></span>
                        </label>
                    </td>
                    <td class="action_col">
                        <ng-container *ngIf="!item.checkin_status_temp">
                            <button *ngIf="!item.temp_showNote" class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Nhập lý do vắng" (click)="ShowNote(item)">
                            <span  class="material-icons"> edit_note </span>
                        </button>
                        </ng-container>
                        <div *ngIf="item.temp_showNote" class="reason-cover">
                            <input type="text" [(ngModel)]="item.checkin_note_note" [name]="'reason'+i" class="reason-input" maxlength="300">
                            <button class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Lưu ghi chú note" (click)="saveNote(item)">
                                <span *ngIf="!isLoading" class="material-icons"> done </span>
                                <span *ngIf="isLoading" class="spinner-border"></span>
                                <!-- <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                                <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-pencil-alt"></i></span> -->

                            </button>
                            <button class="btn btn-sm btn-secondary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Đóng" (click)="ShowNote(item)">
                                <span  class="material-icons"> close </span>
                            </button>
                        </div>
                        <div *ngIf="!item.temp_showNote"> {{item.checkin_note_note}}</div>

                        <div class="qbs-scroll" style="max-height: 95px; overflow: auto;">
                            <div *ngFor="let item2 of item.checkin_times; let j = index" class="times-item-cover">
                                <div>
                                    <ng-template #tipContent>Hello<img *ngIf="item.detected_image_urls[j] != ''" [src]="appService.showCheckinImage(item.detected_image_urls[j])" /></ng-template>
                                    <div class="content" [ngbTooltip]="tipContent" tooltipClass="my-tooltip-class">
                                        <img [src]="appService.showCheckinImage(item.detected_image_urls[j])" />
                                        <div>{{item2}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="item.checkin_status_temp">
                            <div class="review-cover">
                                <input type="text" [(ngModel)]="item.checkin_review_review" [name]="'review_'+i" class="review-input">
                                <button class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Lưu nhận xét" (click)="saveCheckInReviewOne(item)">
                                    <span *ngIf="!isLoading" class="material-icons"> done </span>
                                    <span *ngIf="isLoading" class="spinner-border"></span>
                                </button>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <app-nodata *ngIf="this.students.length ==0" msg="Thầy/Cô chọn lớp để điểm danh hoặc nhận xét ngày"></app-nodata>
</div>