

import { ProductService } from './../../_services/product.service';
import { Membership } from './../../model/membership';
import { UserService } from './../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { SaleService } from '../../_services/sale.service';
import { CartObj } from '../../model/cart-obj';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckinClassDetailComponent } from '../checkin-report/checkin-class-detail/checkin-class-detail.component';
import { CheckinUnknownDetailComponent } from './checkin-unknown-detail/checkin-unknown-detail.component';
declare var $: any;

@Component({
  selector: 'app-checkin-unknown',
  templateUrl: './checkin-unknown.component.html',
  styleUrls: ['./checkin-unknown.component.css']
})
export class CheckinUnknownComponent implements OnInit {
  isLoading = false;

  cameras = [] // danh sách camera
  camerasSelectedIndex = -1;
  allSchools = {
    student_count: 0,
    student_inattention: 0,
    percent: 0,
  }

  //for data

  classs: any
  filter = {
    khoi_code: "",
    class_code: ""
  }
  dateRange = [];
  curentMonday: Date;

  personUnnkownList = [];
  khoiSelected = -1;

  ///////////////
  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;


  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    private schoolService: SchoolService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private _script: ScriptLoaderService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    private calendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Danh sách người lạ";
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadClasss();
    this.getListDevice()
    this.loadReportCheckinUnknown();
    this.loadCurrenMonday(new Date);
  }
  selectCamera(i) {
    this.camerasSelectedIndex = i;
    this.loadReportCheckinUnknown();
  }
  getListDevice() {
    let data = {

    };
    this.schoolService.getListDevice(data).subscribe(
      response => {
        this.cameras = response.data
        console.log(this.cameras);
      },
      error => {
        this.toastr.error("Có lỗi xảy ra khi lấy danh sách camera", "");
        console.error(error);
      });

  }
  category_khoi_change(event) {
    this.loadReportCheckinUnknown();
  }
  category_change(event) {
    this.loadReportCheckinUnknown();
  }
  async loadClasss() {
    let filter = {
      khoi: this.filter.khoi_code,
      type: environment.cateType_class
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  // reportAllSchool() {

  //   this.schoolService.reportAllSchool().subscribe(
  //     async response => {
  //       if (response) {
  //         console.log(response);
  //         this.allSchools = response;
  //         console.log(this.allSchools);
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  loadReportCheckinUnknown() {

    let data = {
      dateSelected: this.selectedDateStr,
      deviceName: this.camerasSelectedIndex > -1 ? this.cameras[this.camerasSelectedIndex].deviceName : ''
      // sorter: soter,
    };
    this.isLoading = true;
    this.schoolService.reportCheckinUnknown(data).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          console.log(response);
          response.forEach(element => {// Đưa time và ảnh vào mảng
            if (element.checkin_times == null || element.checkin_times == '') element.checkin_times = [];
            else
              element.checkin_times = element.checkin_times.split(",");
            if (element.detected_image_urls == null || element.detected_image_urls == '') element.detected_image_urls = [];
            else
              element.detected_image_urls = element.detected_image_urls.split(",");
            if (element.deviceNames == null || element.deviceNames == '') element.deviceNames = [];
            else
              element.deviceNames = element.deviceNames.split(",");
          });
          this.personUnnkownList = response;

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }


  popup_export_excel() {

  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
      this.loadCurrenMonday(this.fromDate);
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadReportCheckinUnknown();
    // this.loadSales(this.selectedDateStr);
  }


  loadCurrenMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if (curr.getDate() == first) first = first - 6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
        }
      );
    }
    console.log(this.dateRange);
  }

  selectPerson(item) {
    const dialogRef = this.dialog.open(CheckinUnknownDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }

  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }


  // popupSaleDetail(item) {
  //   $("#sale-detail-modal").modal('show');
  //   this.saleSelectedItem = item;
  //   try {
  //     this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);

  //   } catch (error) {
  //     this.saleSelectedItem.service_details = [];
  //     console.error(error);
  //   }
  //   this.loadCartDetail(this.saleSelectedItem.id);
  // }

  openCheckinClass(item) {
    if (item.isAvailable == 0) return;
    const dialogRef = this.dialog.open(CheckinClassDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-modal',
      data: { class_checkin: item, dateSelected: this.selectedDateStr }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }

}

