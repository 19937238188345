import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  
  sendMessenger(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('messenger/sendMessenger'), data,).map(data => { return data; });
  }
  updateMessenger(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('messenger/updateMessenger'), data,).map(data => { return data; });
  }

  getMessenger(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('messenger/get'), data,).map(data => { return data; });
  }

  
  

}
