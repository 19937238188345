import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-discussion-detail',
  templateUrl: './discussion-detail.component.html',
  styleUrls: ['./discussion-detail.component.css']
})
export class DiscussionDetailComponent implements OnInit {
  discussionSelected : any;
  comment_allowed = false;
  deleted = false;
  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    public messengerService: MessengerService,
    public dialogRef: MatDialogRef<DiscussionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.discussionSelected = data;
      this.comment_allowed = this.discussionSelected.comment_allowed == 1 ?  true : false
      this.deleted = this.discussionSelected.deleted == 1 ?  true : false
   }

  ngOnInit(): void {
  }

  eviction(){
    let data = {
      title : 'Xác nhận thu thồi thông báo',
      msg : 'Đồng ý đề bắt đầu thu hồi.',
    }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          // bắt đầu pá
          this.updateMessenger();
      
        }
      });
  }
  updateMessenger(){
    let data = {
      id : this.discussionSelected.id,
      deleted : this.deleted ? 1: 0,
      comment_allowed: this.comment_allowed ? 1: 0
    }
    this.messengerService.updateMessenger(data).subscribe(
      async response => {
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          // nếu có file đính kèm
          
          // đóng diaglog
          let result = {
            code: "ok",
            msg: 'Cập nhật thông báo thành công',
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
     
        console.error("error:");
        console.error(error);
      })
  }
}
